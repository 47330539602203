import GetValueFromToken from 'hooks/userInfoHook';
import moment from 'moment';

const { Client } = require('elasticsearch-browser');

const APP_FIELD = 'Application';
const ENV_FIELD = 'Environment';
const VERSION_FIELD = 'Version';
const USER_NAME_FIELD = 'UserName';
const ELAPSED_MS_FIELD = 'ElapsedMilliseconds';
const REQUEST_PATH_FIELD = 'RequestPath';
const URL_FIELD = 'Url';
const APP_STATE_FIELD = 'AppState';
const APP_ACTION_EVENT = 'ActionEvent';
const LOG_LEVEL = 1;
const LOG_INDEX = 'frontend-development';

enum LogType {
	INFOMATION,
	WARNING,
	ERROR,
}

const connectToELK = () => {
	const client = new Client({
		host: process.env.REACT_APP_ELK_HOST,
	});

	client.ping({
		requestTimeout: Infinity,
		body: 'hello basato!',
	});

	return client;
};

const addToIndex = (value: any) => {
	const client = connectToELK();
	return client.create(value);
};

const getMessageTemplate = () => {
	const fields: string[] = [
		APP_FIELD,
		ENV_FIELD,
		VERSION_FIELD,
		USER_NAME_FIELD,
		ELAPSED_MS_FIELD,
		REQUEST_PATH_FIELD,
		URL_FIELD,
		APP_STATE_FIELD,
		APP_ACTION_EVENT,
	];
	const template = fields.map(field => `{${field}}`).join(' - ');

	return template;
};

const getFields = (data: any) => {
	return {
		[APP_FIELD]: data.appName,
		[ENV_FIELD]: data.environment,
		[VERSION_FIELD]: data.appVersion,
		[USER_NAME_FIELD]: data.userId,
		[ELAPSED_MS_FIELD]: data.elapsedTime,
		[REQUEST_PATH_FIELD]: data.requestPath,
		[URL_FIELD]: data.url,
		[APP_ACTION_EVENT]: data.actionType,
	};
};

const buildBodyChunk = (entry: any) => {
	const { type, message, data } = entry;
	const level =
		type === LogType.INFOMATION
			? 'Information'
			: type === LogType.WARNING
			? 'Warning'
			: type === LogType.ERROR
			? 'Error'
			: '';
	const date = moment();
	const messageTemplate = getMessageTemplate();
	const fields = getFields(data);
	const body = {
		'@timestamp': `${date.toISOString()}`,
		level,
		messageTemplate,
		message,
		fields,
	};

	return JSON.stringify(body);
};

const flushBuffer = (type: LogType, message: any, data: any) => {
	const logData = { type, message, data };
	if (logData.type >= LOG_LEVEL) {
		const date = moment();
		const body = buildBodyChunk(logData);
		addToIndex({
			index: LOG_INDEX,
			type: '_doc',
			id: `${LOG_INDEX}_${date.toISOString()}`,
			body: body,
		}).then(
			() => {},
			(error: any) => {
				// console.error(error);
			},
		);
	}
};

const logError = (error: any) => {
	const url = window.location.href;
	const userName = GetValueFromToken.useGetUserName;

	const logFields = {
		environment: process.env.NODE_ENV,
		userId: userName,
		url,
	};

	flushBuffer(LogType.ERROR, error, logFields);
};

export { logError };

