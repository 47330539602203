import { CancelToken } from 'axios';
import { LatLngTuple } from 'leaflet';
import ApiService, { BUSINESS_BASE_URL } from 'services/api/index';

export const prefixMap = '/Map';

interface LatLng {
	Lat: number;
	Lng: number;
}

export interface BAMarkerResult {
	State: boolean;
	Errorcode: number;
	Data?: BAMarker[];
}

export interface BAMarker {
	Shapeid: number;
	Kindname: string;
	Name: string;
	Address: string;
	Coords: LatLng[];
	Location: {
		CityId: string | null;
		DistrictId: string | null;
		WardId: string | null;
	};
}

const _reverseGeocode = (lat: number, lng: number): Promise<BAMarkerResult> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixMap}/searchByCoordinate`, {
			lngstr: lng?.toString(),
			latstr: lat?.toString(),
		})
		.then(({ data }: { data: BAMarkerResult }) => Promise.resolve(data));

interface SearchByKeyWordParams {
	Keys: string;
	Location: LatLng;
}

const _searchByKeyWord = (input: SearchByKeyWordParams): Promise<BAMarkerResult> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixMap}/searchByKeyWord`, {
			...input,
			Size: 15,
			Distance: 1000,
			Searchstr: '',
		})
		.then(({ data }: { data: BAMarkerResult }) => Promise.resolve(data));

const _searchByRoad = (roadName: string): Promise<BAMarkerResult> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixMap}/searchByName`, { Keys: roadName })
		.then(({ data }: { data: BAMarkerResult }) => Promise.resolve(data));

export interface ProvinceDetail {
	CityId: string;
	CityName: string;
	DeleteFlag: boolean;
	DistrictId: string | null;
	DistrictName: string | null;
	HeatLevel: number;
	HeatRate: any;
	Id: string;
	Point: any;
	Point3List: LatLngTuple[];
	Point4List: LatLngTuple[];
	Bounds: any;
}

const _getProvinceDetail = (tinhId: string): Promise<ProvinceDetail> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixMap}/${tinhId}`)
		.then(({ data }: { data: ProvinceDetail }) => Promise.resolve(_mapProvinceDetail(data)));

const _mapProvinceDetail = (data: ProvinceDetail): ProvinceDetail => {
	return {
		...data,
		Point: data.Point.map((r: any) => [r.Lng, r.Lat]),
	};
};

const _getHeatMap = (input: any, token?: CancelToken): Promise<ProvinceDetail[]> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixMap}/getHeatMap`, input, { cancelToken: token })
		.then(({ data }: { data: ProvinceDetail[] }) => Promise.resolve(data));

export interface FindRouteParams {
	From: LatLng;
	To: LatLng;
}

interface FindRouteResult {
	State: boolean;
	Errorcode: number;
	Data: {
		Distance: number;
		From?: LatLng;
		Points?: LatLng[];
		Segments: {
			Name: string;
			Start: LatLng;
			Distance: number;
		}[];
		To?: LatLng;
	};
}

const _findRoute = (input: FindRouteParams): Promise<FindRouteResult> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixMap}/findRoute`, input)
		.then(({ data }: { data: FindRouteResult }) => Promise.resolve(data));

const mapService = {
	findRoute: _findRoute,
	reverseGeocode: _reverseGeocode,
	searchByKeyWord: _searchByKeyWord,
	searchByName: _searchByRoad,
	getProvinceDetail: _getProvinceDetail,
	getHeatMap: _getHeatMap,
};

export default mapService;
