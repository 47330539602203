import { Button, Form, Input } from 'antd';
import { blockInvalidChar } from 'components/page/CommonControl/BlockInvalidChar';
import UploadFileInput from 'components/shared/UploadFileInput/UploadFileInput';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import errorManagementService, { CreateErrorParams } from 'services/api/factService/errorManagementService';

interface QuanLyLoiDialogProps {
	errorCode?: string;
	Id?: string;
	callback?: () => void;
}

const QuangLyLoiDialog: FunctionComponent<QuanLyLoiDialogProps> = (props: QuanLyLoiDialogProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	const executeCallback = () => {
		if (props.callback) {
			props.callback();
		}
	};

	useEffect(() => {
		if (props.Id) {
			errorManagementService.getbyId(props.Id).then((result: any) => {
				const files =
					result.fileUpload &&
					result.fileUpload.map((file: any, index: number) => {
						return {
							uid: index,
							name: file.FileName,
							status: 'done',
							response: { FileUrl: file.FileUrl, FileName: file.FileName },
							url: file.FileUrl,
						};
					});
				form.setFieldsValue({ ...result, fileUpload: files });
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props.errorCode]);

	const finishCreate = (formValues: CreateErrorParams) => {
		formValues.fileUpload = formValues.fileUpload
			? formValues.fileUpload.map((file: any) => file.response).filter((item: any) => Boolean(item))
			: [];
		const requestBody = {
			ErrorCode: formValues.ErrorCode,
			fileUpload: formValues.fileUpload,
			...(formValues.Description && { Description: formValues.Description }),
			...(formValues.PassFrom && { PassFrom: formValues.PassFrom }),
			...(formValues.PassTo && { PassTo: formValues.PassTo }),
		};
		errorManagementService
			.createErrorInfo(requestBody)
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_create_desc', {
						item: form.getFieldValue('ErrorCode'),
					}),
				);
				dialog.close();
				executeCallback();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const finishUpdate = (formValues: CreateErrorParams) => {
		formValues.Id = props.Id;
		formValues.fileUpload = formValues.fileUpload
			? formValues.fileUpload.map((file: any) => file.response).filter((item: any) => Boolean(item))
			: [];
		const requestBody = {
			Id: formValues.Id,
			ErrorCode: formValues.ErrorCode,
			fileUpload: formValues.fileUpload,
			...(formValues.Description && { Description: formValues.Description }),
			...(formValues.PassFrom && { PassFrom: formValues.PassFrom }),
			...(formValues.PassTo && { PassTo: formValues.PassTo }),
		};
		// update
		errorManagementService
			.updateErrorInfo(requestBody)
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_update_desc', {
						item: form.getFieldValue('ErrorCode'),
					}),
				);
				dialog.close();
				executeCallback();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	return (
		<Form
			className={'common-dialog'}
			form={form}
			layout={'vertical'}
			onFinish={props.errorCode ? finishUpdate : finishCreate}>
			<Form.Item
				label="Mã lỗi"
				name="ErrorCode"
				rules={[
					{
						whitespace: true,
						required: true,
						message: 'Mã lỗi không được để trống',
					},
				]}>
				<Input placeholder={t('placeholder.nhapMaLoi')} />
			</Form.Item>

			<div className="absolute-space-cont">
				<label>Dải cho phép:</label>
				<Form.Item label="Từ" name={'PassFrom'}>
					<Input type={'number'} onKeyDown={blockInvalidChar} min={0} placeholder={t('placeholder.nhapDai')} />
				</Form.Item>

				<Form.Item
					label="Đến"
					name={'PassTo'}
					dependencies={['PassFrom']}
					rules={[
						({ getFieldValue }) => ({
							validator: (_, value) => {
								if (!value || +getFieldValue('PassFrom') <= +value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Dải cho phép kết thúc phải lớn hơn dải cho phép bắt đầu'));
							},
						}),
					]}>
					<Input type={'number'} onKeyDown={blockInvalidChar} min={0} placeholder={t('placeholder.nhapDai')} />
				</Form.Item>
			</div>

			<Form.Item name="Description" label="Mô tả">
				<Input.TextArea placeholder="Nhập mô tả" />
			</Form.Item>

			<div>
				<UploadFileInput />
			</div>
			<div className="absolute-end-cont mt20">
				<Button type={'primary'} htmlType={'submit'}>
					{props.Id ? t('label.update') : t('label.create')}
				</Button>

				<Button
					className="ml10"
					type="default"
					onClick={() => {
						dialog.close();
					}}>
					{t('label.cancel')}
				</Button>
			</div>
		</Form>
	);
};

export default withTranslation()(QuangLyLoiDialog);
