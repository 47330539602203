import { Button, Divider, Row, Transfer } from 'antd';
import { TransferDirection } from 'antd/lib/transfer';
import permissionEnum, { PermissionEnum } from 'enum/permissionEnum';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import PermissionSelector from 'redux/Permission/permission.selector';
import UserService, { UserInfo } from 'services/api/authenService/usersService';

interface ChiTietPhanQuyenDialogProps {
	RoleName?: string;
	Id?: string;
	callback?: (data: any) => any;
	datachosen?: any;
	userInfo?: UserInfo;
}

interface RecordType {
	key: string;
	title: string;
	description: string;
	chosen: boolean;
}

const ChiTietPhanQuyenDialog: FunctionComponent<ChiTietPhanQuyenDialogProps> = (props: ChiTietPhanQuyenDialogProps) => {
	const dialog = useDialog();

	const [dataQuyen, setDataQuyen] = useState<RecordType[]>([]);
	const [targetKeys, setTargetKeys] = useState<string[]>([]);
	const [dataTable, setDataTable] = useState<any>();

	const getQuyen = () => {
		
		if (props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)) {
			const permisstion = permissionEnum.getAll();
			MockData(permisstion);
		} else {
			UserService.getPermission().then((data: any) => {
				const permisstions = permissionEnum.getForAPI(data);
				MockData(permisstions);
			});
		}
	};

	const MockData = (permisstions: any) => {
		const tempTargetKeys: any = [];
		let tempMockData: any = [];
		const dataTable = [];
		for (let i = 0; i < permisstions.length; i++) {
			const data = {
				key: permisstions[i].value,
				title: permisstions[i].label,
				description: permisstions[i].label,
				chosen: props.datachosen?.some((element: any) => {
					if (element.value === permisstions[i].value) {
						return true;
					}
					return false;
				}),
			};
			if (data.chosen) {
				tempTargetKeys.push(data.key);
				dataTable.push(data);
			}
			tempMockData.push(data);
		}

		setDataTable(props.datachosen);
		setDataQuyen(tempMockData);
		setTargetKeys(tempTargetKeys);
	};

	useEffect(() => {
		getQuyen();
	}, [props.datachosen]);

	const handleChange = (newTargetKeys: string[], direction: TransferDirection, moveKeys: string[]) => {
		const permisstions: any = [];
		// setPermissionTable(newTargetKeys);
		newTargetKeys.forEach(permisstion => {
			permisstions.push({ label: permissionEnum.fromEnum(+permisstion).label, value: permisstion });
		});
		if (permisstions.length > 0) {
			setDataTable(permisstions);
		} else {
			setDataTable([]);
		}
		setTargetKeys(newTargetKeys);
	};

	const renderItem = (item: RecordType) => {
		const customLabel = <span className="custom-item">{item.title}</span>;

		return {
			label: customLabel, // for displayed item
			value: item.title, // for title and filter matching
		};
	};

	const themMoiPTPCCC = () => {
		if (props.callback) {
			props.callback(dataTable);
			dialog.close();
		}
	};

	const handleSearch = (dir: TransferDirection, value: string) => {
		value = value.trim();
	};

	const filterOption = (inputValue: string, option: RecordType) => {
		// return option.description.indexOf(inputValue) > -1;
		return option.description.toLocaleLowerCase().includes((inputValue.trim() || '').toLocaleLowerCase());
	};

	return (
		<div>
			<Row>
				<Transfer
					locale={{
						searchPlaceholder: 'Tìm kiếm',
						itemsUnit: 'Quyền',
						selectInvert: 'Đảo ngược trang hiện tại',
						selectAll: 'Chọn tất cả giá trị',
					}}
					className="p20"
					dataSource={dataQuyen}
					listStyle={{
						width: 300,
						height: 430,
					}}
					showSearch
					targetKeys={targetKeys}
					filterOption={filterOption}
					onChange={handleChange}
					render={renderItem}
					onSearch={handleSearch}
				/>
			</Row>
			<Divider />
			<Row justify="end" className={'mt10 chi-tiet-csct-action mr20'}>
				<Button
					type="default"
					onClick={() => {
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button className="ml10" htmlType={'submit'} type="primary" onClick={() => themMoiPTPCCC()}>
					Thêm
				</Button>
			</Row>
		</div>
	);
};

export default withTranslation()(ChiTietPhanQuyenDialog);
