import { t } from 'i18next';

export enum ProcessStatusEnum {
	DANG_XU_LY,
	DANG_CHAY,
	KET_THUC_XU_LY, // Đã kết thúc vụ cháy
	CANH_BAO_SAI, // Cán bộ trực xác nhận
	CANH_BAO_GIA, // Thiết bị tự kết thúc cảnh bảo
	DANG_CHO_XAC_MINH, // Thiết bị đang chờ xác minh
}

interface ProcessStatus {
	value: ProcessStatusEnum;
	label: string;
	disabled: Boolean;
}

const _fromEnum = (value: ProcessStatusEnum, mode?: string): ProcessStatus => {
	let label: string = '';
	let disabled: Boolean = false;
	switch (value) {
		case ProcessStatusEnum.DANG_XU_LY:
			label = t('enum.processStatus.DANG_XU_LY');
			disabled = mode ? true : false;
			break;
		case ProcessStatusEnum.DANG_CHAY:
			label = t('enum.processStatus.DANG_CHAY');
			disabled = mode ? true : false;
			break;
		case ProcessStatusEnum.KET_THUC_XU_LY:
			label = t('enum.processStatus.KET_THUC_XU_LY');
			disabled = mode ? true : false;
			break;
		case ProcessStatusEnum.CANH_BAO_SAI:
			label = t('enum.processStatus.CANH_BAO_SAI');
			disabled = false;
			break;
		case ProcessStatusEnum.CANH_BAO_GIA:
			label = t('enum.processStatus.CANH_BAO_GIA');
			disabled = false;
			break;
		case ProcessStatusEnum.DANG_CHO_XAC_MINH:
			label = t('enum.processStatus.DANG_CHO_XAC_MINH');
			disabled = false;
			break;
	}

	return {
		label: label,
		value: value,
		disabled: disabled,
	};
};

const _getAll = (mode?: string): ProcessStatus[] => {
	return Object.values(ProcessStatusEnum)
		.filter(r => !isNaN(parseFloat(r + '')))
		.map(r => _fromEnum(r as ProcessStatusEnum, mode));
};

const processStatusEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default processStatusEnum;
