import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import CommonControl from 'components/page/CommonControl';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import ConstructionLabel from 'components/shared/ConstructionLabel/ConstructionLabel';
import UploadFileInput from 'components/shared/UploadFileInput/UploadFileInput';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import approvalService from 'services/api/businessService/approvalService';
import ConstructionService, { Construction } from 'services/api/inventoryService/constructionService';
import provinceService, { District } from 'services/api/provinceService';
import utils from 'services/api/utils';

const dateFormatList = ['DD/MM/YYYY'];
const { Option } = Select;

interface IDialogCongTacThamDuyetParams {
	record?: {
		tinhthanh: any;
		tinhthanhID: any;
		quanhuyen: any;
		quanhuyenId: any;
		cosocongtrinh: any;
		cosocongtrinhID: any;
		ngaycap: any;
		tenvanban: any;
		sovanban: any;
		coquancap: any;
		ghichu: any;
		Id: any;
	};
	mode?: string;
	callback?: () => void;
	userInfo?: UserInfo;
}

const DialogCongTacThamDuyet: FunctionComponent<IDialogCongTacThamDuyetParams> = (
	props: IDialogCongTacThamDuyetParams,
) => {
	const dialog = useDialog();
	const [form] = Form.useForm();

	const [constructionItems, setConstructionItems] = useState<JSX.Element[]>([]);
	const [districtItems, setDistrictItems] = useState<JSX.Element[]>([]);
	const [constructionId, setConstructionId] = useState<string>('');

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const getDistricts = async (provinceId: string) => {
		return await provinceService.findDistrictsByProvince(provinceId);
	};

	const getConstructions = async (provinceId: string = '', districtId: string = '') => {
		const param = {
			Location: {
				TinhThanh: provinceId,
				QuanHuyen: districtId,
			},
		};
		return await ConstructionService.selectBoxForContruction(param);
	};

	const handleChangeProvince = async (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'ConstructionId']);
		setDistrictItems([]);
		setConstructionItems([]);
		if (provinceId) {
			const districts = await getDistricts(provinceId);
			let quanHuyenList: any[] = [];
			if (
				props.userInfo &&
				props.userInfo.Locations &&
				props.userInfo.Locations.length > 0 &&
				!props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
			) {
				props.userInfo.Locations.forEach((location: any) => {
					if (location.DistrictId) {
						districts.forEach(quanHuyen => {
							if (quanHuyen.Id === location.DistrictId) {
								quanHuyenList.push(quanHuyen);
							}
						});
					} else {
						quanHuyenList = districts;
					}
				});
			} else {
				quanHuyenList = districts;
			}
			generateDistrictItem(quanHuyenList);
		}
	};

	const handelChangeDistrict = async (districtId: string) => {
		form.resetFields(['ConstructionId']);
		if (districtId) {
			const constructions = await getConstructions(form.getFieldValue('TinhThanh'), districtId);
			generateConstructionItems(constructions);
		} else {
			setConstructionItems([]);
		}
	};

	const generateConstructionItems = (constructions: Construction[]) => {
		if (constructions && constructions.length > 0) {
			const options = constructions.map(construction => (
				<Option key={construction.Id} value={construction.Id}>
					{construction.Name}
				</Option>
			));
			setConstructionItems(options);
		}
	};

	const generateDistrictItem = (districts: District[]) => {
		if (districts && districts.length > 0) {
			const options = districts.map(district => (
				<Option key={district.Id} value={district.Id}>
					{district.Name}
				</Option>
			));
			setDistrictItems(options);
		}
	};

	const handleFinishForm = async (formValues: any) => {
		const requestBody: any = {
			Location: {
				TinhThanh: formValues.TinhThanh,
				QuanHuyen: formValues.QuanHuyen,
			},
			ConstructionId: formValues.ConstructionId,
			Date: moment(formValues.ThoiGian),
			Name: formValues.Name,
			DocumentNumber: formValues.DocumentNumber,
			OrganName: formValues.OrganName,
			Note: formValues.Note,
			FileNameUrl: formValues.fileUpload
				? formValues.fileUpload.map((file: any) => file.response).filter((item: any) => Boolean(item))
				: [],
		};

		const id = props.record?.Id;
		try {
			if (id) {
				requestBody.Id = id;
				await approvalService.updateApproval(requestBody);
				if (props.callback) props.callback();
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_update_desc', {
						item: form.getFieldValue('Name'),
					}),
				);
				dialog.close();
			} else {
				await approvalService.createApproval(requestBody);
				if (props.callback) props.callback();
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_create_desc', {
						item: form.getFieldValue('Name'),
					}),
				);
				dialog.close();
			}
		} catch (errors: any) {
			showNotification('error', t('notification.error'), errors.response?.data || errors.message);
		}
	};

	useEffect(() => {
		if (props.record) {
			// Get Data Công tác thẩm định và cấp giấy phép chứng nhận đủ điều kiện về PCCC
			approvalService.getApproval(props.record?.Id).then((result: any) => {
				if (!result) {
					return;
				}
				setConstructionId(result.ConstructionId);
				let promises: PromiseLike<any>[] = [];
				const files =
					result.FileNameUrl &&
					result.FileNameUrl.map((file: any, index: number) => {
						return {
							uid: index,
							name: file?.FileName,
							status: 'done',
							response: { FileUrl: file?.FileUrl, FileName: file?.FileName },
							url: file?.FileUrl,
						};
					});
				promises.push(
					provinceService
						.findDistrictsByProvince(result.Location.TinhThanh)
						.then((res: any) => generateDistrictItem(res)),
				);
				promises.push(
					ConstructionService.selectBoxForContruction({
						Location: {
							TinhThanh: result.Location.TinhThanh,
						},
					}).then((res: any) => generateConstructionItems(res)),
				);
				Promise.all(promises).then(() => {
					form.setFieldsValue(result);
					form.setFieldsValue({
						TinhThanh: result.Location.TinhThanh,
						QuanHuyen: result.Location.QuanHuyen,
						ThoiGian: moment(result.Date),
					});
					form.setFieldsValue({ fileUpload: files });
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="common-dialog">
			<Form layout="vertical" name="normal_login" className="content-container" form={form} onFinish={handleFinishForm}>
				<Row gutter={32} className="mb15">
					<Col span={12}>
						<ColumnTitle columnTitle="I. Thông tin chung" />
						<div>
							<Form.Item
								name="TinhThanh"
								label="Tỉnh thành"
								rules={[
									{
										required: true,
										message: 'Tỉnh thành không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={t('placeholder.tinhThanh')}
									onChange={handleChangeProvince}
									allowClear
									disabled={props.mode === 'view'}>
									{props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
								</Select>
							</Form.Item>

							<Form.Item
								name="QuanHuyen"
								label="Quận huyện"
								rules={[
									{
										required: true,
										message: 'Quận huyện không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={t('placeholder.quanHuyen')}
									allowClear
									onChange={handelChangeDistrict}
									disabled={props.mode === 'view'}>
									{districtItems}
								</Select>
							</Form.Item>

							<Form.Item
								label={<ConstructionLabel constructionId={constructionId} dialog={dialog} />}
								name="ConstructionId"
								rules={[
									{
										required: true,
										message: 'Cơ sở công trình không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={t('placeholder.construction')}
									allowClear
									disabled={props.mode === 'view'}
									onChange={(value: string) => setConstructionId(value)}>
									{constructionItems}
								</Select>
							</Form.Item>

							<Form.Item
								label="Thời gian"
								name="ThoiGian"
								rules={[
									{
										required: true,
										message: 'Thời gian không được bỏ trống',
									},
								]}>
								<DatePicker
									locale={CommonControl.localeVi}
									placeholder="Chọn thời gian"
									format={dateFormatList}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<div>
								<UploadFileInput disabled={props.mode === 'view'} form={form} />
							</div>
						</div>
					</Col>

					<Col span={12}>
						<ColumnTitle columnTitle="II. Thông tin chi tiết" />
						<div>
							<Form.Item
								id="addnew"
								label="Tên văn bản"
								name="Name"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên văn bản không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên văn bản" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								id="addnew"
								label="Số văn bản"
								name="DocumentNumber"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Số văn bản không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập số văn bản" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								id="addnew"
								label="Cơ quan cấp"
								name="OrganName"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên cơ quan cấp giấy phép không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên cơ qua cấp giấy phép" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item label="Ghi chú" name="Note">
								<Input.TextArea rows={5} placeholder="Nhập nội dung ghi chú" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Divider />

				<div className="dialog__buttons mt20">
					{props.mode === 'view' && (
						<Button
							type="default"
							onClick={() => {
								dialog.close();
							}}>
							OK
						</Button>
					)}

					{props.mode !== 'view' && (
						<Button
							type="default"
							onClick={() => {
								dialog.close();
							}}>
							Hủy
						</Button>
					)}

					{props.mode !== 'view' && (
						<Button type="primary" htmlType="submit">
							{props.record ? 'Cập nhật' : 'Thêm mới'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogCongTacThamDuyet;
