import { LocationSelectedState } from './location-selected.reducer';
export const ACTION_TYPE = {
	SELECT_LOCATION: 'SELECT_LOCATION',
	RESET_LOCATION: 'RESET_LOCATION',
};

export const selectLocation = (checkers: LocationSelectedState) => {
	return {
		type: ACTION_TYPE.SELECT_LOCATION,
		payload: checkers,
	};
};

export const resetLocation = () => {
	return {
		type: ACTION_TYPE.RESET_LOCATION,
	};
};
