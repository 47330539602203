import { PlusOutlined, TableOutlined, UnorderedListOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import SoCuKiemTraGridType from 'components/page/QuanLyCongTacPhongChay/Tabs/SoCuKiemTra/SoCuKiemTraGridType/SoCuKiemTraGridType';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { formatDate } from 'helpers';
import { SoCuKiemTraModel } from 'models/SoCuKiemTra/SoCuKiemTra.model';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import ConstructionCheckingPlanService from 'services/api/businessService/constructionCheckingPlanService';
import ProvinceService from 'services/api/provinceService';
import { PermissionProps } from '../../QuanLyCongTacPhongChay';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const SoCuKiemTra: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const getDanhSachSoCuKiemTra = useRef<any>(null);
	const navigate = useNavigate();

	const columns: ColumnsType<any> = [
		{
			title: 'STT',
			dataIndex: 'Id',
			key: 'Id',
			align: 'center' as const,
			width: 45,
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{ title: 'Tên cơ sở pháp lý', dataIndex: 'Name', key: 'Name' },
		{
			title: 'Ngày bắt đầu',
			dataIndex: 'FromDate',
			key: 'FromDate',
			render: (date: string) => (date ? formatDate(date) : null),
		},
		{
			title: 'Ngày kết thúc',
			dataIndex: 'ToDate',
			key: 'ToDate',
			render: (date: string) => (date ? formatDate(date) : null),
		},
		{ title: 'Tỉnh thành', dataIndex: 'TinhThanh', key: 'TinhThanh' },
		{ title: 'Quận huyện', dataIndex: 'QuanHuyen', key: 'QuanHuyen' },
		{
			title: 'Thao tác',
			dataIndex: 'Action',
			key: 'Action',
			width: 120,
			align: 'center' as const,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_EDIT) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_DELETE)
			),
			render: (_: any, record: SoCuKiemTraModel) => {
				return (
					<>
						<Button
							style={{
								display:
									props.permissionList.includes(PermissionEnum.ADMIN) ||
									props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_EDIT)
										? ''
										: 'none',
							}}
							type="text"
							onClick={e => e.stopPropagation()}>
							<Link
								to={{
									pathname: '/chiTietSoCuKiemTra',
									search: `?id=${record.Id}&mode=edit`,
								}}>
								<img src="icon/Pen.png" alt="edit" />
							</Link>
						</Button>

						<Button
							style={{
								display:
									props.permissionList.includes(PermissionEnum.ADMIN) ||
									props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_DELETE)
										? ''
										: 'none',
							}}
							type="text"
							onClick={e => handleShowDeleteDialog(record.Id, record.Name, e)}>
							<img src="icon/Delete.png" alt="delete" />
						</Button>
					</>
				);
			},
		},
	].filter(item => !item.hidden);

	// state of component
	const [provinceId, setProvinceId] = useState('');
	const [dataSources, setDataSources] = useState<SoCuKiemTraModel[]>([]);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [displayType, setDisplayType] = useState<'grid' | 'table'>('table');

	const handleShowDeleteDialog = async (id: string, name: string, e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		await CustomDialog(
			<DeleteDialog
				name={name}
				id={id}
				deleteService={ConstructionCheckingPlanService.deleteConstructionCheckingPlan}
				callback={getDanhSachSoCuKiemTra.current}
			/>,
			{
				showCloseIcon: true,
				isCanClose: true,
			},
		);
	};

	const convertDataSources = async (soCuKiemTra: SoCuKiemTraModel) => {
		const location = soCuKiemTra.Location ? soCuKiemTra.Location : null;
		if (location) {
			const province = await ProvinceService.getProvince(location.TinhThanh);
			const district = await ProvinceService.getDistrict({
				cityId: location.TinhThanh,
				districtId: location.QuanHuyen,
			});

			return {
				...soCuKiemTra,
				TinhThanh: province?.Name,
				QuanHuyen: district?.Name,
			};
		}

		return soCuKiemTra;
	};

	const getSearchParam = () => {
		return {
			Name: form.getFieldValue('Name'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			},
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
	};

	getDanhSachSoCuKiemTra.current = async (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const params = getSearchParam();
		try {
			const { Data, TotalItems } = await ConstructionCheckingPlanService.getDanhSachSoCuKiemTra(params, pageOption);
			if (Data && Data.length > 0) {
				const newData = await Promise.all(Data.map(async item => await convertDataSources(item)));
				setDataSources(newData);
			} else {
				setDataSources([]);
			}
			setTotalItems(TotalItems!);
		} catch (error) {
		}
	};

	useEffect(() => {
		document.title = 'Cơ sở pháp lý';
		getDanhSachSoCuKiemTra.current();
	}, []);

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
		}
		getDanhSachSoCuKiemTra.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = () => {
		getDanhSachSoCuKiemTra.current(CURRENT_PAGE);
	};

	const handleChangeDatePicker = () => {
		getDanhSachSoCuKiemTra.current(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['Name']);
		}
		getDanhSachSoCuKiemTra.current(CURRENT_PAGE);
	};

	const handleRefreshPage = () => {
		form.resetFields();
		setProvinceId('');
		getDanhSachSoCuKiemTra.current(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, pageSize: number) => {
		getDanhSachSoCuKiemTra.current(page, pageSize);
	};

	const handleChangeDisplayType = (type: 'grid' | 'table') => {
		setDisplayType(type);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietSoCuKiemTra',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	const formTable = (
		<>
			<TableComponent
				containerClass="socukiemtra-table"
				dataSource={dataSources}
				columns={columns}
				paginator={{
					defaultPageSize: pageSizeDefault,
					showSizeChanger: true,
					onChange: handleChangePage,
					total: totalItems,
					current: currentPage,
					pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
				}}
				onRow={handleShowViewDetail}
			/>
		</>
	);

	const formGrid = (
		<>
			<SoCuKiemTraGridType
				data={dataSources}
				currentPage={currentPage}
				pageSizeDefault={pageSizeDefault}
				totalItem={totalItems}
				onChangePage={handleChangePage}
				onShowDeleteDialog={handleShowDeleteDialog}
			/>
		</>
	);

	const formAction = (
		<>
			<div className={'flex--row'}>
				<ProvinceSelect onChangeProvince={handleChangeProvince} form={form} selectClassName="withMinSelect" />

				<DistrictSelect
					onChangeDistrict={handleChangeDistrict}
					provinceId={provinceId}
					form={form}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
				/>

				<RangePickerInput onChangeDatePicker={handleChangeDatePicker} form={form} className="ml10" />

				<Form.Item name="Name" className="ml10">
					<Input.Search placeholder="Nhập tên cơ sở pháp lý" allowClear onSearch={(value: string) => handleSearchName(value)} />
				</Form.Item>
			</div>

			<div>
				{displayType === 'table' ? (
					<Button type="default" icon={<TableOutlined />} onClick={() => handleChangeDisplayType('grid')} />
				) : (
					<Button type="default" icon={<UnorderedListOutlined />} onClick={() => handleChangeDisplayType('table')} />
				)}

				<Button type="default" className="ml10" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>

				{props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button className="ml10" type="primary">
						<Link
							to={{
								pathname: '/chiTietSoCuKiemTra',
							}}>
							<PlusOutlined /> Thêm mới
						</Link>
					</Button>
				) : null}
			</div>
		</>
	);
	return (
		<PageWrapper
			titlePage="Danh sách cơ sở pháp lý"
			formAction={formAction}
			formTable={displayType === 'table' ? formTable : formGrid}
			form={form}
		/>
	);
};

export default SoCuKiemTra;
