import { RESET_NAVIGATE, SET_NAVIGATE } from 'redux/navigate-from-dashboard/navigate-from-dashboard.type';
import { ReduxAction } from 'redux/store';

export const setNavigate = (input: boolean): ReduxAction => {
	return {
		type: SET_NAVIGATE,
		payload: input,
	};
};

export const resetNavigate = (): ReduxAction => {
	return {
		type: RESET_NAVIGATE,
		payload: null
	};
};
