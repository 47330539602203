import { t } from 'i18next';

export enum LoaiDiemLayNuocEnum {
	AO_HO = 'AO_HO',
	TRU_NUOC = 'TRU_NUOC',
	BEN_NUOC = 'BEN_NUOC',
	SONG_SUOI = 'SONG_SUOI',
}

interface LoaiDiemLayNuoc {
	label: string;
	value: LoaiDiemLayNuocEnum;
}

const _fromEnum = (loaiDiemLayNuocEnum: LoaiDiemLayNuocEnum): LoaiDiemLayNuoc => {
	switch (loaiDiemLayNuocEnum) {
		case LoaiDiemLayNuocEnum.AO_HO:
			return {
				label: t('enum.loaiDiemLayNuoc.AO_HO'),
				value: LoaiDiemLayNuocEnum.AO_HO,
			};
		case LoaiDiemLayNuocEnum.TRU_NUOC:
			return {
				label: t('enum.loaiDiemLayNuoc.TRU_NUOC'),
				value: LoaiDiemLayNuocEnum.TRU_NUOC,
			};
		case LoaiDiemLayNuocEnum.BEN_NUOC:
			return {
				label: t('enum.loaiDiemLayNuoc.BEN_NUOC'),
				value: LoaiDiemLayNuocEnum.BEN_NUOC,
			};
		case LoaiDiemLayNuocEnum.SONG_SUOI:
			return {
				label: t('enum.loaiDiemLayNuoc.SONG_SUOI'),
				value: LoaiDiemLayNuocEnum.SONG_SUOI,
			};
	}
};

// Get loại Điểm lấy nước
const _enumFromLabel = (loaiDiemLayNuocLabel: string): LoaiDiemLayNuocEnum | undefined => {
	switch (loaiDiemLayNuocLabel.toUpperCase()) {
		case t('enum.loaiDiemLayNuoc.AO_HO').toUpperCase():
			return LoaiDiemLayNuocEnum.AO_HO;
		case t('enum.loaiDiemLayNuoc.TRU_NUOC').toUpperCase():
			return LoaiDiemLayNuocEnum.TRU_NUOC;
		case t('enum.loaiDiemLayNuoc.BEN_NUOC').toUpperCase():
			return LoaiDiemLayNuocEnum.BEN_NUOC;
		case t('enum.loaiDiemLayNuoc.SONG_SUOI').toUpperCase():
			return LoaiDiemLayNuocEnum.SONG_SUOI;
	}
};

const _getAll = (): LoaiDiemLayNuoc[] => {
	return Object.values(LoaiDiemLayNuocEnum).map(r => _fromEnum(r as LoaiDiemLayNuocEnum));
};

const loaiDiemLayNuocEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default loaiDiemLayNuocEnum;
