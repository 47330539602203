import React, { FunctionComponent, useEffect, useState } from 'react';

import { Button, Collapse, Empty, Form } from 'antd';
import 'moment/locale/vi';
import moment from 'moment';
import ConstructionService, { HistoryPayload } from 'services/api/inventoryService/constructionService';
import dialogDispatcher from 'redux/dialog/dialog.dispatcher';
import { useDialog } from 'react-st-modal';

interface LoaiThietBiDialogProps {
	Id: string;
}

const { Panel } = Collapse;

const DialogHistoryConstruction: FunctionComponent<LoaiThietBiDialogProps> = (props: LoaiThietBiDialogProps) => {
	const dialog = useDialog();
	const [form] = Form.useForm();
	const [dataHistory, setDataHistory] = useState<any[]>([]);
	useEffect(() => {
		if (props.Id) {
			const param: HistoryPayload = { Id: props.Id };
			ConstructionService.searchHistory(param).then((result: any) => {
				setDataHistory(result);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props.Id]);

	const genExtra = (name: string, date: string) => (
		<div className="flex--row">
			<span style={{ color: 'black' }}> {'Người cập nhật : ' + name}</span>
			<span style={{ color: 'black', marginLeft: '460px' }}>{moment(date).format('HH:mm:ss DD/MM/YYYY')}</span>
		</div>
	);

	return (
		<div className="common-dialog">
			{dataHistory.length > 0 ? (
				<Collapse>
					{dataHistory.map((record, index) => (
						<Panel header={genExtra(record.ChangeBy, record.ChangeTime)} key={index}>
							<p className="ml20 mt10">{record.LogChange}</p>
						</Panel>
					))}
				</Collapse>
			) : (
				<Empty description={'Không có lịch sử thay đổi'} />
			)}
			<div className={'dialog__buttons mt20'}>
				<Button type="primary" onClick={async () => dialog.close()}>
					Đồng ý
				</Button>
			</div>
		</div>
	);
};

export default DialogHistoryConstruction;
