import { t } from 'i18next';

export enum LoaiHinhDonViEnum {
	QUAN_HUYEN = 'QUAN_HUYEN',
	PC07 = 'PC07'
}

interface LoaiHinhDonVi {
	value: LoaiHinhDonViEnum,
	label: string
}

function _fromEnum(loaiHinhDonViEnum: LoaiHinhDonViEnum): LoaiHinhDonVi {
	switch (loaiHinhDonViEnum) {
		case LoaiHinhDonViEnum.QUAN_HUYEN:
			return {
				label: t('enum.loaiHinhDonVi.QUAN_HUYEN'),
				value: LoaiHinhDonViEnum.QUAN_HUYEN
			};
		case LoaiHinhDonViEnum.PC07:
			return {
				label: t('enum.loaiHinhDonVi.PC07'),
				value: LoaiHinhDonViEnum.PC07
			};
	}
}

const _getAll = (): LoaiHinhDonVi[] => {
	return Object.values(LoaiHinhDonViEnum).map(r => _fromEnum(r as LoaiHinhDonViEnum));
};

const loaiHinhDonViEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll
};

export default loaiHinhDonViEnum;
