import { PermissionEnum } from 'enum/permissionEnum';
import store from 'redux/store';
import { UserInfo } from 'services/api/authenService/usersService';

const PermissionSelector = {
	getPermissionList: (): PermissionEnum[] => {
		return store.getState().permission.permissionList;
	},
	getUserInfo: (): UserInfo => {
		return store.getState().permission.userInfo!;
	},
};

export default PermissionSelector;
