import React, { memo } from 'react';
interface PercentComponentProps {
	count: number;
	percent: number;
}
const PercentComponent = (props: PercentComponentProps) => {
	return (
		<>
			<p className="mb0">{props.count}</p>
			<p className="mb0" style={{ color: '#BDBDBD' }}>
				{props.percent ? props.percent.toFixed(2) : 0}%
			</p>
		</>
	);
};

export default memo(PercentComponent);
