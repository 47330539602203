import { history } from 'helpers';
import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import store from 'redux/store';
import App from './App';
import './i18n';
import './index.scss';

const dotenv = require('dotenv');
dotenv.config({ path: '.env' });

ReactDOM.render(
	<Provider store={store}>
		<HistoryRouter history={history}>
			<App />,
		</HistoryRouter>
	</Provider>,
	document.getElementById('root'),
);
