import KeHoachKiemTra from 'components/page/QuanLyCongTacPhongChay/Tabs/KeHoachKiemTra/KeHoachKiemTra';
import QuanLyCoSoVaCongTrinh from 'components/page/QuanLyCongTacPhongChay/Tabs/QuanLyCoSoVaCongTrinh';
import SoCuKiemTra from 'components/page/QuanLyCongTacPhongChay/Tabs/SoCuKiemTra/SoCuKiemTra';
import ThamDuyetChungNhan from 'components/page/QuanLyCongTacPhongChay/Tabs/ThamDuyetChungNhan';
import TuyenTruyenHuanLuyen from 'components/page/QuanLyCongTacPhongChay/Tabs/TuyenTruyenHuanLuyen';
import XuLyViPham from 'components/page/QuanLyCongTacPhongChay/Tabs/XuLyViPham';
import { PermissionEnum } from 'enum/permissionEnum';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';

export enum QuanLyCongTacPhongChayTab {
	QUAN_LY_CO_SO_VA_CONG_TRINH = 'QuanLyCoSoVaCongTrinh',
	THAM_DUYET_VA_CHUNG_NHAN = 'ThamDuyetChungNhan',
	SO_CU_KIEM_TRA = 'SoCuKiemTra',
	KE_HOACH_KIEM_TRA = 'KeHoachKiemTra',
	QUAN_LY_CHU_CO_SO_CONG_TRINH = 'QuanLyDanCuChuCoSoCongTrinh',
	TUYEN_TRUYEN_HUAN_LUYEN_PCCC = 'TuyenTruyenHuanLuyen',
	XU_LY_VI_PHAM_PCCC = 'XuLyViPham',
}

export interface PermissionProps {
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
}

const mapStateToProps = (): PermissionProps => ({
	permissionList: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const QuanLyCongTacPhongChay: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const [selectedTab, setSelectedTab] = useState<QuanLyCongTacPhongChayTab | null>(null);

	useEffect(() => {
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(QuanLyCongTacPhongChayTab).includes(_tabParam as QuanLyCongTacPhongChayTab)) {
			navigate(
				{
					pathname: '/quanLyCongTacPhongChay',
					search: `?tab=${QuanLyCongTacPhongChayTab.QUAN_LY_CO_SO_VA_CONG_TRINH}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as QuanLyCongTacPhongChayTab);
		}
	}, [navigate, searchParams]);

	const renderSelectedTab = () => {
		switch (selectedTab) {
			case QuanLyCongTacPhongChayTab.QUAN_LY_CO_SO_VA_CONG_TRINH:
				return <QuanLyCoSoVaCongTrinh permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacPhongChayTab.THAM_DUYET_VA_CHUNG_NHAN:
				return <ThamDuyetChungNhan permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacPhongChayTab.SO_CU_KIEM_TRA:
				return <SoCuKiemTra permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacPhongChayTab.KE_HOACH_KIEM_TRA:
				return <KeHoachKiemTra permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacPhongChayTab.TUYEN_TRUYEN_HUAN_LUYEN_PCCC:
				return <TuyenTruyenHuanLuyen permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacPhongChayTab.XU_LY_VI_PHAM_PCCC:
				return <XuLyViPham permissionList={props.permissionList} userInfo={props.userInfo} />;
			default:
				return null;
		}
	};

	return renderSelectedTab();
};

export default connect(mapStateToProps)(QuanLyCongTacPhongChay);
