import CongTacChuaChay from 'components/page/CongTacPhongChayVaCHCN/Tab/CongTacChuaChay';
import CongTacCuuHoCuuNan from 'components/page/CongTacPhongChayVaCHCN/Tab/CongTacCuuHoCuuNan';
import XDThucTapPhuongAnChuaChay from 'components/page/CongTacPhongChayVaCHCN/Tab/XDThucTapPhuongAnChuaChay';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';

export enum QuanLyCongTacCHCNTab {
	CONG_TAC_CHUA_CHAY = 'CongTacChuaChay',
	XAY_DUNG_TTPA_CHUA_CHAY = 'XayDungTTPAChuaChay',
	CONG_TAC_CHCN = 'CongTacCNCH',
}
// Dialog xóa
export interface PermissionProps {
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
}

const mapStateToProps = (): PermissionProps => ({
	permissionList: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const CongTacPhongChayVaCHCN: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [selectedTab, setSelectedTab] = useState<QuanLyCongTacCHCNTab | null>(null);

	// Khởi tạo data table cho bảng Danh sách quản lý trực
	useEffect(() => {
		NavBarDispatcher.setTitle(`${t('congTacPhongChayVaCHCN.title')}`);
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(QuanLyCongTacCHCNTab).includes(_tabParam as QuanLyCongTacCHCNTab)) {
			navigate(
				{
					pathname: '/congTacChuaChayVaCNCH',
					search: `?tab=${QuanLyCongTacCHCNTab.CONG_TAC_CHUA_CHAY}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as QuanLyCongTacCHCNTab);
		}
	}, [navigate, searchParams]);

	const renderSelectedTab = (): JSX.Element | null => {
		switch (selectedTab) {
			case QuanLyCongTacCHCNTab.CONG_TAC_CHUA_CHAY:
				return <CongTacChuaChay permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacCHCNTab.XAY_DUNG_TTPA_CHUA_CHAY:
				return <XDThucTapPhuongAnChuaChay permissionList={props.permissionList} userInfo={props.userInfo} />;
			case QuanLyCongTacCHCNTab.CONG_TAC_CHCN:
				return <CongTacCuuHoCuuNan permissionList={props.permissionList} userInfo={props.userInfo} />;
			default:
				return null;
		}
	};
	return renderSelectedTab();
};

export default connect(mapStateToProps)(CongTacPhongChayVaCHCN);
