import { AutoComplete, Col, Form, FormInstance, Input, Row } from 'antd';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { debounce } from 'lodash';
import React, { FunctionComponent, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { LayersControl, MapContainer, TileLayer } from 'react-leaflet';
import mapService, { BAMarkerResult } from 'services/api/businessService/mapService';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import ToaDoMarker, { MarkerType } from '../Map/ToaDoMarker';
import './CustomMap.scss';

interface CustomMapProps {
	containerClass?: string;
	data?: CustomMapIn;
	form: FormInstance;
	markerType?: MarkerType;

	changeDataCallback(newVal: CustomMapOut, isReload: boolean): void;
}

export interface CustomMapIn {
	Latitude: number;
	Longitude: number;
}

export interface CustomMapOut extends CustomMapIn {
	Location: LocationInfo;
	LocationDetail?: string;
}

interface ToaDoOption {
	value: number[];
	label: string;
	Location: any;
}

const CustomMap: FunctionComponent<CustomMapProps> = (props: CustomMapProps) => {
	const [toaDoList, setToaDoList] = useState<ToaDoOption[]>([]);
	const [map, setMap] = useState<L.Map>();
	const [toaDo, setToaDo] = useState<any>();
	const clickCallback = (result: BAMarkerResult) => {
		if (
			result.Data &&
			result.Data[0].Location?.CityId &&
			(!props.form.getFieldValue('QuanHuyen') ||
				(props.markerType !== MarkerType.DIEM_LAY_NUOC && props.markerType !== MarkerType.DON_VI_HO_TRO) ||
				props.form.getFieldValue('QuanHuyen') === result.Data[0].Location?.DistrictId)
		) {
			props.form.setFieldsValue({
				LocationDetail: result.Data[0].Address,
				QuanHuyen: result.Data[0].Location.DistrictId,
				Longitude: result.Data[0].Coords[0].Lng,
				XaPhuong: result.Data[0].Location.WardId,
				TinhThanh: result.Data[0].Location.CityId,
				Latitude: result.Data[0].Coords[0].Lat,
			});
			setFormDataCallBack();
		} else {
			showNotification('error', t('notification.error'), 'Bạn không có thẩm quyền quản lý tại khu vực này!');
		}
	};

	const setFormDataCallBack = () => {
		props.changeDataCallback({
			Latitude: props.form.getFieldValue('Latitude'),
			Location: {
				TinhThanh: props.form.getFieldValue('TinhThanh'),
				QuanHuyen: props.form.getFieldValue('QuanHuyen'),
				XaPhuong: props.form.getFieldValue('XaPhuong'),
			},
			LocationDetail: props.form.getFieldValue('LocationDetail'),
			Longitude: props.form.getFieldValue('Longitude'),
		}, true);
	};

	const onSearchTenDiemChay = (input: string) => {
		if (!props) {
			return;
		}

		mapService
			.searchByKeyWord({
				Keys: input,
				Location: {
					Lat: props.data?.Latitude || 21.03098406760035,
					Lng: props.data?.Longitude || 105.78447780462093,
				},
			})
			.then(res =>
				setToaDoList(
					res.Data?.map(r => {
						return {
							label: r.Name,
							value: [r.Coords[0].Lat, r.Coords[0].Lng],
							Location: r.Location,
						};
					}) || [],
				),
			);
	};

	const onchangeviDo = (latitude: string) => {
		if (props.form.getFieldValue('Longitude')) {
			setValueToForm(+latitude, +props.form.getFieldValue('Longitude'));
		}
	};
	const onchangeKinhDo = (longitude: string) => {
		if (props.form.getFieldValue('Latitude')) {
			setValueToForm(+props.form.getFieldValue('Latitude'), +longitude);
		}
	};

	const setValueToForm = (lat: number, long: number) => {
		setToaDo({ kinhDo: lat, viDo: long });
		map && map.panTo(new L.LatLng(lat, long));
	};

	const getTinhThanh = (option: ToaDoOption) => {
		mapService.reverseGeocode(option.value[0], option.value[1]).then((data: any) => {
			props.form.setFieldsValue({
				LocationDetail: option.label,
				search: option.label,
				Latitude: option.value[0],
				Longitude: option.value[1],
				TinhThanh: data?.Data[0]?.Location?.CityId,
				QuanHuyen: data?.Data[0]?.Location?.DistrictId,
				XaPhuong: data?.Data[0]?.Location?.WardId,
			});
			props.changeDataCallback({
				Latitude: props.form.getFieldValue('Latitude'),
				Location: {
					TinhThanh: props.form.getFieldValue('TinhThanh'),
					QuanHuyen: props.form.getFieldValue('QuanHuyen'),
					XaPhuong: props.form.getFieldValue('XaPhuong'),
				},
				LocationDetail: option.label,
				Longitude: props.form.getFieldValue('Longitude'),
			}, true);
		});
	};

	return (
		<div className={'input-map-container ' + props.containerClass}>
			<Form.Item
				name={'LocationDetail'}
				label={t('label.diaChi')}
				rules={[
					{
						required: true,
						message: t('validation.error.address_empty'),
					},
				]}>
				<Input
					onChange={e =>
						props.changeDataCallback({
							Latitude: props.form.getFieldValue('Latitude'),
							Location: {
								TinhThanh: props.form.getFieldValue('TinhThanh') || props.form.getFieldValue('Location')?.TinhThanh,
								QuanHuyen: props.form.getFieldValue('QuanHuyen') || props.form.getFieldValue('Location')?.QuanHuyen,
								XaPhuong: props.form.getFieldValue('XaPhuong') || props.form.getFieldValue('Location')?.XaPhuong,
							},
							LocationDetail: e.target.value,
							Longitude: props.form.getFieldValue('Longitude'),
						}, false)
					}
					placeholder="Nhập địa chỉ"
				/>
			</Form.Item>
			<div className={'common-map__container'}>
				{props.data && (
					<MapContainer
						className={'common-map'}
						center={[props.data?.Latitude || 21.03098406760035, props.data?.Longitude || 105.78447780462093]}
						zoom={15}
						whenCreated={setMap}
						scrollWheelZoom={true}>
						{/* <TileLayer url={'https://maps.googleapis.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}&hl=vi'} /> */}
						<LayersControl position={'bottomright'}>
							<LayersControl.BaseLayer checked name="Hình ảnh basato">
								<TileLayer url={'https://map.binhanh.vn/titservice.ashx?typ=ba&lvl={z}&top={y}&left={x}'} />
							</LayersControl.BaseLayer>

							<LayersControl.BaseLayer name="Hình ảnh google">
								<TileLayer url={'https://maps.googleapis.com/maps/vt/lyrs=m&x={x}&y={y}&z={z}&hl=vi'} />
							</LayersControl.BaseLayer>

							<LayersControl.BaseLayer name="Hình ảnh vệ tinh google">
								<TileLayer url={'https://maps.googleapis.com/maps/vt/lyrs=s&x={x}&y={y}&z={z}&hl=vi'} />
							</LayersControl.BaseLayer>
						</LayersControl>
						<ToaDoMarker
							center={{
								lat: props.data?.Latitude || 21.03098406760035,
								lng: props.data?.Longitude || 105.78447780462093,
							}}
							toado={{ kinhDo: toaDo?.kinhDo, viDo: toaDo?.viDo }}
							markerType={props.markerType}
							clickCallback={clickCallback}
						/>
					</MapContainer>
				)}
			</div>

			<Row className="flex--row justify-content--between mt20">
				<Form.Item name={'search'} label={'Tìm kiếm trên bản đồ'} style={{ flexBasis: '30%' }}>
					<AutoComplete
						placeholder={'Tìm kiếm'}
						onSearch={debounce(onSearchTenDiemChay, 300)}
						options={toaDoList}
						onSelect={(_: any, option: ToaDoOption) => {
							getTinhThanh(option);
						}}
					/>
				</Form.Item>
				<Form.Item
					style={{ flexBasis: '30%' }}
					label={t('label.kinhDo')}
					name={'Longitude'}
					rules={[
						{
							required: true,
							message: t('validation.error.kinhDo_empty'),
						},
					]}>
					{/* <Input placeholder={t('placeholder.chonTrenBanDo')} /> */}
					<AutoComplete
						placeholder={t('placeholder.chonTrenBanDo')}
						options={toaDoList}
						onSearch={debounce(onchangeKinhDo, 300)}
					/>
				</Form.Item>

				<Form.Item
					style={{ flexBasis: '30%' }}
					label={t('label.viDo')}
					name={'Latitude'}
					rules={[
						{
							required: true,
							message: t('validation.error.viDo_empty'),
						},
					]}>
					{/* <Input placeholder={t('placeholder.chonTrenBanDo')} onChange={onchangeviDo} /> */}
					<AutoComplete
						placeholder={t('placeholder.chonTrenBanDo')}
						options={toaDoList}
						onSearch={debounce(onchangeviDo, 300)}
					/>
				</Form.Item>
			</Row>
			<Row hidden>
				<Col span={8}>
					<Form.Item label={t('label.tinhThanh')} name={'TinhThanh'}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name={'QuanHuyen'} label={t('label.quanHuyen')}>
						<Input />
					</Form.Item>
				</Col>
				<Col span={8}>
					<Form.Item name={'XaPhuong'} label={t('label.xaPhuong')}>
						<Input />
					</Form.Item>
				</Col>
			</Row>
		</div>
	);
};

export default withTranslation()(CustomMap);
