import AvailabilityStatusEnum from 'enum/availabilityStatusEnum';
import { LoaiPhuongTienEnum } from 'enum/loaiPhuongTienEnum';
import { TrangThaiHoatDongEnum } from 'enum/trangThaiHoatDongEnum';
import { TrucThuocEnum } from 'enum/trucThuocEnum';
import ApiService, { PageOption, REPORT_BASE_URL } from '../../index';

export const prefixreportFireTruck = '/reportFireTruck';

export interface SearchReportFireTruckParams {
	Location: {
		TinhThanh: string;
		QuanHuyen: string;
		XaPhuong: string;
	};
	PcccUnitId: string;
	Type: 0;
	Status: 0;
}

interface Location {
	CityId: string;
	DistrictId: string;
	WardId: string | null;
}

export interface FireTruckStatus {
	Location: Location | null;
	PcccUnitName: string | null;
	Xe3m3Count: number;
	Xe5m3Count: number;
	XeBomCount: number;
	XeBotCount: number;
	XeMayCount: number;
	XeThangCount: number;
	GoodPercent: number;
	BadPercent: number;
	NeedRepairPercent: number;
	NeedReplacePercent: number;
	Children: FireTruckStatus[];
}

export interface FireTruckDetail {
	Id: string;
	Location: {
		TinhThanh: string;
		QuanHuyen: string;
		XaPhuong: string | null;
	};
	Available: AvailabilityStatusEnum;
	BienSo: string;
	DonVi: string;
	FireProtectionId: string;
	Imei: string;
	Latitude: number;
	Longitude: number;
	LoaiPhuongTien: LoaiPhuongTienEnum;
	LocationDetail: string;
	SoDienThoai: string;
	SoHieuPhuongTien: string;
	TenPhuongTien: string;
	TinhTrangHoatDong: TrangThaiHoatDongEnum;
	TrucThuoc: TrucThuocEnum;
	Users: string[];
}

const searchCountFireTruckStatus = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportFireTruck}/countFireTruckStatus`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const searchDataFireTruckDetail = (requestBody = {}, pageOption = {}): Promise<PageOption<FireTruckDetail>> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportFireTruck}/getDataFireTruckDetail`, requestBody, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: PageOption<FireTruckDetail> }) => Promise.resolve(data));

const searchDataFireTruckStatus = (requestBody = {}, pageOption = {}): Promise<PageOption<FireTruckStatus>> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportFireTruck}/getDataFireTruckStatus`, requestBody, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: PageOption<FireTruckStatus> }) => Promise.resolve(data));

const exportFileExcel = (requestBody = {}, url: string): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportFireTruck}/${url}`, requestBody, {
			responseType: 'blob',
		})
		.then(({ data }: { data: PageOption<FireTruckStatus> }) => Promise.resolve(data));

const ReportFireTruckService = {
	searchCountFireTruckStatus,
	searchDataFireTruckDetail,
	searchDataFireTruckStatus,
	exportFileExcel,
};

export default ReportFireTruckService;
