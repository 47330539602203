import React, { FunctionComponent } from 'react';
import { t } from 'i18next';
import { Button, Col, Row, Space } from 'antd';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import AuthService from '../../../../services/api/authenService/authService';
import { NavigateFunction } from 'react-router/lib/hooks';
import './LogoutDialog.scss';

interface LogoutDialogProps {
	navigate: NavigateFunction;
}

const LogoutDialog: FunctionComponent<LogoutDialogProps> = (props) => {
	const dialog = useDialog();

	return (
		<Space direction={'vertical'} size={16} className={'logoutDialog-container'}>
			<Row>
				<Col span={24}>
					{t('logout.message')}
				</Col>
			</Row>
			<Row gutter={16}>
				<Col span={12}>
					<Button type={'primary'} ghost block onClick={dialog.close}>{t('label.cancel')}</Button>
				</Col>
				<Col span={12}>
					<Button type={'primary'} block onClick={() => {
						dialog.close();
						AuthService.logout(() => props.navigate(0))
					}}>{t('label.dongY')}</Button>
				</Col>
			</Row>
		</Space>
	)
}

export default withTranslation()(LogoutDialog);