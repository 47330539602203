import { RESET_lOCATION, SET_LOCATION } from 'redux/location/location.type';
import { ReduxAction } from 'redux/store';

export interface LocationState {
	provinceId: string;
	districtId: string;
	isResetMapLayer: boolean;
}

const PROVINCE_ID_DEFAULT = localStorage.getItem('LocationInfo')
	? JSON.parse(localStorage.getItem('LocationInfo') as string)[0].CityId
	: '01';

const INITIAL_STATE: LocationState = { provinceId: PROVINCE_ID_DEFAULT, districtId: '', isResetMapLayer: false };

const locationReducer = (state = INITIAL_STATE, action: ReduxAction): LocationState => {
	switch (action.type) {
		case SET_LOCATION:
			return {
				...state,
				...action.payload,
			};
		case RESET_lOCATION:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default locationReducer;
