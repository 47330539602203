import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import AddDeviceDialog from 'components/page/QuanLyThietBi/DeviceCenter/AddDeviceDialog/AddDeviceDialog';
import CenterDeviceStatusSelect from 'components/shared/form-select/CenterDeviceStatus/CenterDeviceStatus';
import CenterDeviceTypeSelect from 'components/shared/form-select/CenterDeviceType/CenterDeviceType';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import FilterDropdownLayout from 'components/shared/layouts/FilterDropdownLayout/FilterDropdownLayout';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import ShowForPermission from 'components/shared/ShowForPermission/ShowForPermission';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { DeviceStatusMap } from 'enum/device-status/device-status.enum';
import { PermissionEnum } from 'enum/permissionEnum';
import { formatDate, openDialog } from 'helpers';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DeviceService from 'services/api/inventoryService/deviceService';
import { systemProperties } from 'systemProperties';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DeviceCenter = () => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [filteredInfo, setFilteredInfo] = useState<any>({});

	const columns = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) =>
				(paginateOption.CurrentPage - 1) * paginateOption.PageSize + index + 1,
		},
		{
			title: 'Tên thiết bị',
			dataIndex: 'Name',
			key: 'Name',
			width: 200,
			filteredValue: filteredInfo.Name || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="Name" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập tên thiết bị"
								allowClear
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('Name', confirm, setSelectedKeys);
									}
								}}
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ Name: '' });
									}
									handleSearchColumn('Name', confirm, setSelectedKeys);
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		},
		{
			title: 'Loại thiết bị',
			dataIndex: 'LoaiThietBi',
			key: 'LoaiThietBi',
			filteredValue: filteredInfo.LoaiThietBi || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<CenterDeviceTypeSelect
							form={form}
							style={{ marginBottom: 0 }}
							onChangeCenterDeviceType={value => {
								setSelectedKeys(value ? [value] : []);
								setFilteredInfo((filteredInfo: any) => ({
									...filteredInfo,
									...(value ? { LoaiThietBi: value } : { LoaiThietBi: null }),
								}));
								confirm();
							}}
						/>
					</FilterDropdownLayout>
				);
			},
		},
		{
			title: 'IMEI thiết bị',
			dataIndex: 'Imei',
			key: 'Imei',
			filteredValue: filteredInfo.IMEI || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="IMEI" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập imei thiết bị"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ IMEI: '' });
									}
									handleSearchColumn('IMEI', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('IMEI', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		},
		{
			title: 'Cơ sở công trình',
			dataIndex: 'Construction',
			key: 'Construction',
			filteredValue: filteredInfo.ConstructionName || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="ConstructionName" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập tên cơ sở công trình"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ ConstructionName: '' });
									}
									handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			render: (construction: { TenCoSoCongTrinh: string; Id: string }) => {
				return (
					<NavigateButton
						url={`/chiTietCoSoCongTrinh?id=${construction?.Id}&mode=view`}
						name={construction?.TenCoSoCongTrinh}
					/>
				);
			},
		},
		{
			title: 'Thời gian hoạt động',
			dataIndex: 'ActiveTime',
			key: 'ActiveTime',
			filteredValue: filteredInfo.ActiveTime || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<RangePickerInput
							style={{ marginBottom: 0 }}
							onChangeDatePicker={date => {
								setSelectedKeys(date && date.length > 0 ? date : []);
								setFilteredInfo((filteredInfo: any) => ({
									...filteredInfo,
									...(date ? { ActiveTime: date } : { ActiveTime: null }),
								}));
								confirm();
							}}
						/>
					</FilterDropdownLayout>
				);
			},
		},
		{
			title: 'Kết nối',
			children: [
				{
					align: 'center' as const,
					title: 'Trạng thái',
					dataIndex: 'IsConnected',
					key: 'IsConnected',
					filteredValue: filteredInfo.IsOnline || null,
					filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
						return (
							<FilterDropdownLayout>
								<CenterDeviceStatusSelect
									form={form}
									style={{ marginBottom: 0 }}
									onChangeCenterDeviceStatus={value => {
										setSelectedKeys(value !== undefined ? [value] : []);
										setFilteredInfo((filteredInfo: any) => ({
											...filteredInfo,
											...(value !== undefined ? { IsOnline: 'IsConnected' } : { IsOnline: null }),
										}));
										confirm();
									}}
								/>
							</FilterDropdownLayout>
						);
					},
					render: (IsOnline: boolean | null) => {
						const deviceStatus = IsOnline ? DeviceStatusMap.get(+IsOnline) : DeviceStatusMap.get(0);
						return deviceStatus && <span style={{ color: deviceStatus.color }}>{deviceStatus.label}</span>;
					},
				},
				{
					title: 'Cập nhật lần cuối',
					dataIndex: 'UpdateTime',
					key: 'UpdateTime',
					filteredValue: filteredInfo.UpdateTime || null,
					filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
						return (
							<FilterDropdownLayout>
								<RangePickerInput
									name="updateDateRange"
									style={{ marginBottom: 0 }}
									onChangeDatePicker={date => {
										setSelectedKeys(date && date.length > 0 ? date : []);
										setFilteredInfo((filteredInfo: any) => ({
											...filteredInfo,
											...(date ? { UpdateTime: date } : { UpdateTime: null }),
										}));
										confirm();
									}}
								/>
							</FilterDropdownLayout>
						);
					},
				},
			],
		},

		{
			align: 'center' as const,
			className: 'min-col',
			title: 'Thao tác',
			key: 'edit',
			render: (_: any, record: any) => (
				<ShowForPermission allowedPermissions={[PermissionEnum.DEVICE_EDIT, PermissionEnum.ADMIN]}>
					<Button type="text" onClick={e => handleEditCenterDevice(e, record.Imei, record.Id, 'edit')}>
						<img src="icon/Pen.png" alt="edit" />
					</Button>
				</ShowForPermission>
			),
		},
	];

	// states
	const [provinceId, setProvinceId] = useState('');
	const [responseApi, setResponseApi] = useState({
		dataSources: [],
		totalItems: 0,
	});
	const [paginateOption, setPaginateOption] = useState({
		CurrentPage: CURRENT_PAGE,
		PageSize: PAGE_SIZE,
		Paging: true,
	});

	const handleSearchColumn = (
		column: string,
		confirm: () => void,
		setSelectedKeys: (selectedKeys: React.Key[]) => void,
	) => {
		const value = form.getFieldValue(column);
		setSelectedKeys(value ? [value] : []);
		if (column === 'Name') {
			let updatedValue = value ? { Name: value } : { Name: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		} else if (column === 'IMEI') {
			let updatedValue = value ? { IMEI: value } : { IMEI: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		} else if (column === 'ConstructionName') {
			let updatedValue = value ? { ConstructionName: value } : { ConstructionName: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		}
		confirm();
	};

	const handleEditCenterDevice = (e: any, imei: string, id: string, mode: 'view' | 'edit') => {
		e.stopPropagation();
		navigate({ pathname: '/chiTietThietBiTrungTam', search: `?mode=${mode}&imei=${imei}&id=${id}` });
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
		}

		getCenterDeviceList(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, size: number) => {
		setPaginateOption({
			CurrentPage: page,
			PageSize: size,
			Paging: true,
		});

		getCenterDeviceList(page, size);
	};

	const handleOpenAddDeviceDialog = async () => {
		await openDialog(
			<AddDeviceDialog callback={getCenterDeviceList} />,
			'Thêm mới thiết bị trung tâm',
			'addsensor-dialog',
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietThietBiTrungTam',
					search: `?mode=view&imei=${record.Imei}`,
				});
			},
		};
	};

	const getRequestBody = useCallback(() => {
		const provinceId = form.getFieldValue('TinhThanh');
		const districtId = form.getFieldValue('QuanHuyen');
		const deviceName = form.getFieldValue('Name');
		const deviceType = form.getFieldValue('DeviceType');
		const deviceStatus = form.getFieldValue('DeviceStatus');
		const imei = form.getFieldValue('IMEI');
		const constructionName = form.getFieldValue('ConstructionName');
		const dateRange = form.getFieldValue('dateRange');
		const updateDateRange = form.getFieldValue('updateDateRange');
		return {
			Name: deviceName,
			Imei: imei,
			DeviceType: deviceType,
			ConstructionName: constructionName,
			ActivityFromTime: dateRange?.length > 0 ? dateRange[0] : null,
			ActivityToTime: dateRange?.length > 0 ? dateRange[1] : null,
			IsOnline: deviceStatus !== undefined ? Boolean(deviceStatus) : null,
			LastUpdateFromTime: updateDateRange?.length > 0 ? updateDateRange[0] : null,
			LastUpdateToTime: updateDateRange?.length > 0 ? updateDateRange[1] : null,
			Location: {
				TinhThanh: provinceId,
				QuanHuyen: districtId,
			},
		};
	}, [form]);

	const transformData = (data: any) => {
		return data.map((item: any) => {
			const activeTime =
				item?.ActiveTime && item?.ExpiredTime && `${formatDate(item?.ActiveTime)} - ${formatDate(item?.ExpiredTime)}`;
			return {
				...item,
				ActiveTime: activeTime,
				UpdateTime: formatDate(item?.UpdateTime, systemProperties.dateTimeFormat),
			};
		});
	};

	const getCenterDeviceList = useCallback(
		(currentPage = CURRENT_PAGE, pageSize = PAGE_SIZE) => {
			const pagination = {
				CurrentPage: currentPage,
				PageSize: pageSize,
				Paging: true,
			};
			const requestBody = getRequestBody();
			DeviceService.searchDevice(requestBody, pagination).then((res: any) => {
				const { Data, TotalItems } = res;
				const newData = Data ? transformData(Data) : [];
				setResponseApi({
					dataSources: newData,
					totalItems: TotalItems,
				});
			});
		},
		[getRequestBody],
	);

	useEffect(() => {
		document.title = t('quanLyThietBi.quanLyThietBiTrungTam');
	}, []);

	useEffect(() => {
		getCenterDeviceList();
	}, [getCenterDeviceList]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={() => getCenterDeviceList(CURRENT_PAGE)}
				/>
			</div>
			<div>
				<Button
					className="ml10"
					type={'default'}
					onClick={() => {
						form.resetFields();
						setFilteredInfo({});
						getCenterDeviceList(CURRENT_PAGE);
					}}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				<ShowForPermission allowedPermissions={[PermissionEnum.DEVICE_CREATE, PermissionEnum.ADMIN]}>
					<Button className="ml10" type="primary" icon={<PlusOutlined />} onClick={() => handleOpenAddDeviceDialog()}>
						Thêm mới
					</Button>
				</ShowForPermission>
			</div>
		</>
	);

	const formTable = (
		<TableComponent
			dataSource={responseApi.dataSources}
			columns={columns}
			onRow={handleShowViewDetail}
			paginator={{
				onChange: handleChangePage,
				defaultPageSize: paginateOption.PageSize,
				showSizeChanger: true,
				total: responseApi.totalItems,
				current: paginateOption.CurrentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);

	return (
		<PageWrapper titlePage="Danh sách thiết bị trung tâm" formAction={formAction} formTable={formTable} form={form} />
	);
};

export default DeviceCenter;
