import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DialogAddPTPCCC from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiPhuongTienPCCC';
import { QuanLyDonViPhongChayTabProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import { QuanLyDonViPhongChayTab } from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import ExportExcelComponent from 'components/shared/ExportExcelComponent/ExportExcelComponent';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import phuongTienChuaChayCoGioiEnum, { PhuongTienChuaChayCoGioiEnum } from 'enum/phuongTienChuaChayCoGioiEnum';
import vehicleEnum, { VehicleEnum } from 'enum/vehicleEnum';
import { downloadExcelFile, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import fireTruckService from 'services/api/inventoryService/fireTruckService';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DanhSachPhuongTien: FunctionComponent<QuanLyDonViPhongChayTabProps> = props => {
	const columnsDSPTPCCC = [
		{
			title: 'STT',
			dataIndex: 'key',
			align: 'center' as const,
			key: 'key',
			width: 45,
			ellipsis: {
				showTitle: false,
			},
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeChange + index + 1,
		},
		{
			title: 'Tên phương tiện',
			dataIndex: 'TenPhuongTien',
			key: 'TenPhuongTien',
			ellipsis: {
				showTitle: false,
			},
			render: (TenPhuongTien: string) => (
				<Tooltip placement="topLeft" title={TenPhuongTien}>
					{TenPhuongTien}
				</Tooltip>
			),
		},
		{
			title: 'Biển số',
			dataIndex: 'BienSo',
			key: 'BienSo',
			ellipsis: {
				showTitle: false,
			},
			render: (BienSo: string) => (
				<Tooltip placement="topLeft" title={BienSo}>
					{BienSo}
				</Tooltip>
			),
		},
		{
			title: 'Số điện thoại',
			dataIndex: 'SoDienThoai',
			width: 120,
			key: 'SoDienThoai',
			ellipsis: {
				showTitle: false,
			},
			render: (SoDienThoai: string) => (
				<Tooltip placement="topLeft" title={SoDienThoai}>
					{SoDienThoai}
				</Tooltip>
			),
		},
		{
			title: 'Phương tiện chữa cháy cơ giới',
			dataIndex: 'LoaiPhuongTien',
			width: 240,
			key: 'LoaiPhuongTien',
			ellipsis: {
				showTitle: false,
			},
			render: (LoaiPhuongTien: PhuongTienChuaChayCoGioiEnum) => {
				const label = phuongTienChuaChayCoGioiEnum.fromEnum(LoaiPhuongTien)?.label;
				return (
					<Tooltip placement="topLeft" title={label}>
						{label}
					</Tooltip>
				);
			},
		},
		{
			title: 'Loại phương tiện PCCC & CNCH',
			dataIndex: 'Category',
			width: 240,
			key: 'Category',
			ellipsis: {
				showTitle: false,
			},
			render: (Category: VehicleEnum) => {
				const label = vehicleEnum.fromEnum(Category)?.label;
				return (
					<Tooltip placement="topLeft" title={label}>
						{label}
					</Tooltip>
				);
			},
		},
		{
			title: 'Quản lý',
			dataIndex: 'Manager',
			key: 'Manager',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'IMEI thiết bị',
			dataIndex: 'Imei',
			key: 'Imei',
			ellipsis: {
				showTitle: false,
			},
			render: (Imei: string) => (
				<Tooltip placement="topLeft" title={Imei}>
					{Imei}
				</Tooltip>
			),
		},
		{
			title: 'Thông số kỹ thuật',
			dataIndex: 'TechnicalInfo',
			key: 'TechnicalInfo',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			width: 100,
			key: 'action',
			align: 'center' as const,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.FIRE_TRUCK_EDIT) ||
				props.permissionList.includes(PermissionEnum.FIRE_TRUCK_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.FIRE_TRUCK_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.FIRE_TRUCK_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle={`Chỉnh sửa thông tin phương tiện "${record.TenPhuongTien}"`}
					EditDialog={
						<DialogAddPTPCCC
							id={record.id}
							mode={'edit'}
							record={record}
							userInfo={props.userInfo}
							callback={getDataPhuongTienByLocation}
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.TenPhuongTien}
							callback={() => {
								getDataPhuongTienByLocation();
								if (props.callback) {
									props.callback();
								}
							}}
							deleteService={fireTruckService.deleteFireTruck}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [form] = Form.useForm();

	const [dataSourceDSPT, setDataSourceDSPT] = useState<any[]>([]);
	const [TongSoPhuongTien, setTongSoPhuongTien] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeChange, setPageSizeChange] = useState<number>(PAGE_SIZE);

	useEffect(() => {
		if (props.searchData) {
			getDataPhuongTienByLocation(CURRENT_PAGE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchData]);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !props.isNavigate) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FIRE_TRUCK_VIEW])) {
				getDataPhuongTienByLocation();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, props.isNavigate]);

	const getRequestPayload = useCallback(() => {
		const fireTruckName = form.getFieldValue('TenPhuongTien');
		const param = {
			...props.searchData,
			...(fireTruckName && { Name: fireTruckName }),
		};

		return param;
	}, [props.searchData, form]);

	const getDataPhuongTienByLocation = (page = currentPage, pageSize = pageSizeChange) => {
		setCurrentPage(page);
		setPageSizeChange(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const param = getRequestPayload();
		fireTruckService
			.searchFireTruck(param, pageOption)
			.then(res => {
				setTongSoPhuongTien(res.TotalItems);
				setDataSourceDSPT(res.Data);
			})
			.catch();
	};

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			getDataPhuongTienByLocation(CURRENT_PAGE);
		}
	};

	const onClickRefresh = () => {
		form.resetFields();
		props.onResetFormAction && props.onResetFormAction();
	};

	function handleChangePage(page: number, pageSize: number) {
		getDataPhuongTienByLocation(page, pageSize);
	}

	const handleExportFileExcel = useCallback(() => {
		const exportFilePayload = getRequestPayload();
		fireTruckService
			.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Phuong_Tien_Phong_Chay_Chua_Chay.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	}, [getRequestPayload]);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> phương tiện PCCC
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeChange}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoPhuongTien}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(
					<DialogAddPTPCCC
						userInfo={props.userInfo}
						id={record.id}
						mode="view"
						record={record}
						callback={getDataPhuongTienByLocation}
					/>,
					{
						className: 'dialog-add',
						title: `${record.TenPhuongTien}`,
						showCloseIcon: true,
						isCanClose: true,
					},
				);
			},
		};
	};

	return (
		<Form
			layout="vertical"
			name="normal_login"
			className="content-container flex--fill p0"
			form={form}
			disabled={props.isDisable}>
			<div id={QuanLyDonViPhongChayTab.PHUONG_TIEN_PCCC} className={'tab__content'}>
				<div className={'flex--row justify-content--between mb15'}>
					<div className="search-input">
						<FormItem name="TenPhuongTien">
							<Input
								placeholder="Nhập tên phương tiện"
								prefix={<SearchOutlined />}
								onKeyPress={handleKeyDown}
								allowClear
							/>
						</FormItem>
					</div>

					<div>
						{/* <Button
							className="ml10"
							style={{ display: props.isModeView ? 'none' : '' }}
							type={'default'}
							onClick={() => {
								onClickRefresh();
							}}>
							<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
						</Button> */}

						<Link to={'/excelTemplate/Phương tiện PCCC.xlsx'} target={'_blank'} download>
							<Button type={'default'} className="ml10" style={{ display: props.isModeView ? 'none' : '' }}>
								<img className="padding-image" style={{ width: 12 }} src="icon/downExcel.png" alt="Tải file" />{' '}
								{t('label.taiFileMau')}
							</Button>
						</Link>

						{props.permissionList.includes(PermissionEnum.FIRE_TRUCK_EXPORT) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<ExportExcelComponent
								className="ml10"
								dataSources={dataSourceDSPT}
								onExportFileExcel={handleExportFileExcel}
							/>
						) : null}

						{props.permissionList.includes(PermissionEnum.FIRE_TRUCK_CREATE) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<Button
								className="buttonAdd ant-btn ant-btn-primary ml10"
								onClick={async () => {
									await CustomDialog(
										<DialogAddPTPCCC
											userInfo={props.userInfo}
											location={props.searchData?.Location}
											donviId={props.donviId}
											callback={() => {
												getDataPhuongTienByLocation();
												if (props.callback) {
													props.callback();
												}
											}}
										/>,
										{
											className: 'dialog-add',
											title: 'Thêm mới phương tiện PCCC & CNCH',
											isCanClose: true,
											showCloseIcon: true,
										},
									);
								}}>
								<PlusOutlined />
								Thêm mới
							</Button>
						) : null}
					</div>
				</div>

				<TableComponent
					dataSource={dataSourceDSPT}
					columns={columnsDSPTPCCC}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					paginator={false}
					onRow={handleShowViewDetail}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(DanhSachPhuongTien);
