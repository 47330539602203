import { Button, Checkbox, Col, Divider, Form, Input, Row, Select } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import { blockInvalidChar } from 'components/page/CommonControl/BlockInvalidChar';
import loaiKhachHangEnum, { LoaiKhachHangEnum } from 'enum/loaiKhachHangEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import { CreateUserParams, UserInfo } from 'services/api/authenService/usersService';
import CustomerService from 'services/api/inventoryService/customerService';
import { default as ProvinceService, default as provinceService } from 'services/api/provinceService';
import { default as utils } from 'services/api/utils';
import Generator from 'services/generator';
import GeneratorProvinceUser from 'services/generator/ProvinceUser';
import { systemProperties } from 'systemProperties';

interface ChiTietNguoiDungDialogProps {
	username?: string;
	Id?: string;
	userInfo?: UserInfo;
	ParentId?: string | null;
	CustomerType?: string;
	callback?: () => void;
}

const { Option } = Select;

const ChiTietKhachHangDialog: FunctionComponent<ChiTietNguoiDungDialogProps> = (props: ChiTietNguoiDungDialogProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();
	const [custommerList, setCustommerList] = useState<JSX.Element[]>([]);
	const [quanHuyenList, setQuanHuyenList] = useState<any[]>([]);
	const [arrayTinhthanh, updateArrarrayTinhthanh] = useState<JSX.Element[] | null>();
	const [quanHuyenIdUser, setQuanHuyenIdUser] = useState<JSX.Element[]>([]);
	const [locations, setLocations] = useState<any>([]);

	const [isContinue, setIsContinue] = useState<boolean>(false);
	const [loaiKhachHang, setLoaiKhachHang] = useState<string>('CO_QUAN_CHUC_NANG');

	const [isAdmin] = useState<boolean>(props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) || false);

	// const danhSachTinhThanh = Utils.TinhThanh();

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	useEffect(() => {
		let quanHuyenArray: any[] = [];
		let tinhThanhArray: any[] = [];

		if (props.ParentId) {
			CustomerService.getCustomerAll().then((res: any) => {
				setDataCustomer(res);
			});
		} else {
			CustomerService.getCustomerAll('CO_QUAN_CHUC_NANG').then((res: any) => {
				setDataCustomer(res);
			});
		}

		form.setFieldsValue({
			CustomerType: props.CustomerType ? props.CustomerType : LoaiKhachHangEnum.CO_QUAN_CHUC_NANG,
		});

		setLoaiKhachHang(props.CustomerType ? props.CustomerType : LoaiKhachHangEnum.CO_QUAN_CHUC_NANG);

		if (props.Id) {
			CustomerService.getCustomer(props.Id).then((result: any) => {
				customTinhThanh(result);
				form.setFieldsValue(result);

				if (result && result.Locations?.length > 0) {
					setLocations(result.Locations);
					result.Locations.forEach((location: any) => {
						if (location.CityId) if (!tinhThanhArray.includes(location.CityId)) tinhThanhArray.push(location.CityId);
						if (location.DistrictId) quanHuyenArray.push(location.DistrictId);
					});
					onGenderChangeTinhThanh(tinhThanhArray);
					form.setFieldsValue({
						QuanHuyen: quanHuyenArray,
						TinhThanh: tinhThanhArray,
					});
				}
			});
		}

		if (props.ParentId) {
			form.setFieldsValue({ ParentId: props.ParentId });
			onChangeLoaiKhachHang(props.ParentId);
		} else {
			form.setFieldsValue({ ParentId: '' });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props.username]);

	const setDataCustomer = (res: any) => {
		const khongTrucThuocParam = [{ Id: '', Name: 'Không trực thuộc' }];
		const dataTrucThuoc = [...khongTrucThuocParam, ...res];
		setCustommerList(utils.getSelectOptionsCustomer(dataTrucThuoc));
	};

	useEffect(() => {
		CustomerService.getCustomerAll(loaiKhachHang).then((res: any) => {
			setDataCustomer(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaiKhachHang]);

	const finishCreate = (formValues: any) => {
		createLocation(formValues);
		// Create
		CustomerService.createCustomer({ ...formValues, TaxCode: formValues.TaxCode ? formValues.TaxCode : null })
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_create_desc', { item: formValues.Name }),
				);
				if (props.callback && !isContinue) {
					dialog.close();
					props.callback();
				} else {
					form.resetFields(['Name', 'PhoneNumber', 'Address', 'Info']);
				}
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const finishUpdate = (formValues: any) => {
		if (locations?.length === 0) {
			createLocation(formValues);
		} else {
			const output = Object.keys(locations).map(key => {
				return {
					TinhThanh: locations[key].CityId,
					QuanHuyen: locations[key].DistrictId,
				};
			});
			formValues.Locations = output;
		}
		formValues.Id = props.Id;
		// update
		CustomerService.updateCustomer({ ...formValues, TaxCode: formValues.TaxCode ? formValues.TaxCode : null })
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_update_desc', { item: formValues.Name }),
				);
				dialog.close();
				if (props.callback) {
					props.callback();
				}
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	// const [arrOptionTinhThanh, updateArrOptionTinhThanh] = useState<any[]>([]);
	const onGenderChangeTinhThanh = (value: any) => {
		setQuanHuyenList([]);
		setLocations([]);
		form.resetFields(['QuanHuyen']);
		updateArrOptionQuanhuyen([]);
		if (value?.length > 0) {
			let promises: PromiseLike<any>[] = [];
			let array: any[] = [];
			value.forEach((tinhId: string) => {
				promises.push(
					provinceService.findDistrictsByProvince(tinhId).then((res: any) => {
						array.push(res);
					}),
				);
			});
			Promise.all(promises).then(() => {
				setQuanHuyenList(array);
				optionquanhuyen(array);
			});
		} else {
			updateArrOptionQuanhuyen([]);
		}
	};
	const [arrayOptionQuanhuyen, updateArrOptionQuanhuyen] = useState<JSX.Element[] | null>();

	const optionquanhuyen = (data: any[]) => {
		let dataListQuanHuyen: any[] = [];
		let quanHuyenList: any[] = [];
		let quanHuyenLists: any[] = [];
		setLocations([]);
		if (data.length > 0) {
			if (quanHuyenIdUser?.length > 0 || !form.getFieldValue('QuanHuyen')) {
				for (let i = 0; i < data.length; i++) {
					if (i > 0) {
						// dataListQuanHuyen = dataListQuanHuyen.concat(data[i]);
						data[i].forEach((quanHuyen: any) => {
							if (
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenLists.push(quanHuyen);
							}
						});
						dataListQuanHuyen = dataListQuanHuyen.concat(quanHuyenLists);
					} else {
						data[i].forEach((quanHuyen: any) => {
							if (
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenList.push(quanHuyen);
							}
						});
						dataListQuanHuyen = quanHuyenList;
					}
				}
			}
		}

		const uniqueArray = dataListQuanHuyen?.filter(function (item, pos) {
			return dataListQuanHuyen.indexOf(item) === pos;
		});

		let array: JSX.Element[] = [];
		if (uniqueArray) {
			uniqueArray.forEach((item: any) =>
				array.push(
					<Option key={item?.Id} value={item?.value}>
						{item?.Name}
					</Option>,
				),
			);
			updateArrOptionQuanhuyen(array);
		}
	};

	const createLocation = (formValues: CreateUserParams) => {
		formValues.Locations = [];
		let arrayTinhThanh: any = [];

		if (form.getFieldValue('QuanHuyen')?.length > 0) {
			form.getFieldValue('QuanHuyen').forEach((quanhuyen: string) => {
				for (let i = 0; i < quanHuyenList.length; i++) {
					if (search(quanhuyen, quanHuyenList[i])) {
						formValues.Locations.push({
							TinhThanh: form.getFieldValue('TinhThanh')[i],
							QuanHuyen: quanhuyen,
						});

						if (!arrayTinhThanh.includes(form.getFieldValue('TinhThanh')[i])) {
							arrayTinhThanh.push(form.getFieldValue('TinhThanh')[i]);
						}
						break;
					}
				}
			});

			if (arrayTinhThanh.length < form.getFieldValue('TinhThanh').length) {
				arrayTinhThanh.forEach((tinhThanh: any) => {
					const index = form.getFieldValue('TinhThanh').indexOf(tinhThanh);
					if (index > -1) {
						form.getFieldValue('TinhThanh').splice(index, 1); // 2nd parameter means remove one item only
					}
				});

				form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
					formValues.Locations.push({
						TinhThanh: tinhThanh,
					});
				});
			}
		} else if (form.getFieldValue('TinhThanh')) {
			form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
				formValues.Locations.push({
					TinhThanh: tinhThanh,
				});
			});
		}
	};

	function search(quanhuyenId: any, quanHuyenArray: any) {
		for (let i = 0; i < quanHuyenArray.length; i++) {
			if (quanHuyenArray[i].Id === quanhuyenId) {
				return quanHuyenArray[i];
			}
		}
	}

	const onChangeLoaiKhachHang = async (value: any) => {
		setQuanHuyenList([]);
		updateArrarrayTinhthanh([]);
		setLocations([]);
		form.resetFields(['TinhThanh', 'QuanHuyen']);
		if (value && value !== '0') {
			await CustomerService.getCustomer(value).then((res: any) => {
				customTinhThanh(res);
			});
		} else {
			updateArrarrayTinhthanh(
				props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer,
			);
		}
	};

	const customTinhThanh = (res: any) => {
		let result: any = [];
		let tinhThanhList: any = [];
		let LocationUserList: any = [{}];
		let quanHuyens: any = [];
		let quanHuyensId: any = [];
		let array: any[] = [];
		ProvinceService.listProvince().then(tinhThanhs => {
			if (res.Locations?.length > 0) {
				res.Locations.forEach((location: any) => {
					tinhThanhs.forEach(tinhThanh => {
						if (tinhThanh.Id === location.CityId && !tinhThanhList.includes(tinhThanh.Id)) {
							tinhThanhList.push(tinhThanh.Id);
							result.push(tinhThanh);
						}

						if (location.DistrictId) {
							quanHuyensId.push(location.DistrictId);
							LocationUserList.push({ tinhThanhId: tinhThanh.Id, quanHuyenId: location.DistrictId });
						}
					});
				});
			}

			if (result.length > 0 && LocationUserList.length > 0) {
				result.forEach((tinhThanhItem: any) => {
					LocationUserList.forEach((loacation: any) => {
						if (loacation.tinhThanhId === tinhThanhItem.Id) {
							tinhThanhItem.Districts.forEach((quanHuyen: any) => {
								if (quanHuyen.Id === loacation.quanHuyenId) {
									quanHuyens.push(quanHuyen);
								}
							});
						}
					});
				});
			}
			setQuanHuyenIdUser(quanHuyensId);
			if (quanHuyens) {
				quanHuyens.forEach((item: any) =>
					array.push(
						<Option key={item?.Id} value={item?.value}>
							{item?.Name}
						</Option>,
					),
				);
				updateArrOptionQuanhuyen(array);
			}
			updateArrarrayTinhthanh(GeneratorProvinceUser.generateOptions(result, 'Id', 'Name'));
		});
	};

	const onChange = (e: CheckboxChangeEvent) => {
		setIsContinue(e.target.checked);
	};

	const OnchangePhanLoaiKhachHang = (value: any) => {
		setLoaiKhachHang(value);
	};

	return (
		<Form className={'common-dialog'} form={form} layout={'vertical'} onFinish={props.Id ? finishUpdate : finishCreate}>
			<Row gutter={16}>
				<Col span={12}>
					{isAdmin && (
						<Form.Item label="Phân loại khách hàng" name="CustomerType">
							<Select
								showSearch
								disabled={props.ParentId !== undefined}
								optionFilterProp="children"
								placeholder="Chọn phân loại khách hàng"
								onChange={OnchangePhanLoaiKhachHang}
								allowClear>
								{Generator.generateOptions(loaiKhachHangEnum.getAll())}
							</Select>
						</Form.Item>
					)}

					<Form.Item
						label="Tên khách hàng"
						name="Name"
						rules={[
							{
								required: true,
								whitespace: true,
								message: t('userDetail.message.fullNameEmpty'),
							},
						]}>
						<Input placeholder={t('label.hoTen')} />
					</Form.Item>

					<Form.Item name="ParentId" label="Trực thuộc">
						<Select
							disabled={props.ParentId !== undefined}
							showSearch
							optionFilterProp="children"
							placeholder="Chọn khách hàng"
							onChange={onChangeLoaiKhachHang}
							allowClear>
							{custommerList}
						</Select>
					</Form.Item>

					<Form.Item
						name="PhoneNumber"
						label="Số điện thoại"
						rules={[
							{
								pattern: systemProperties.phonePattern,
								message: 'SĐT không đúng định dạng!',
							},
							{
								required: true,
								whitespace: true,
								message: t('validation.error.phoneNumber_empty'),
							},
						]}>
						<Input type={'number'} onKeyDown={blockInvalidChar} placeholder="Số điện thoại" />
					</Form.Item>

					<Form.Item
						name="Address"
						label="Địa chỉ"
						rules={[
							{
								required: true,
								whitespace: true,
								message: 'Vị trí không được để trống',
							},
						]}>
						<Input placeholder="Nhập vị trí" />
					</Form.Item>
				</Col>
				<Col span={12}>
					<Form.Item
						name="TinhThanh"
						label="Tỉnh thành"
						style={{
							display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? '' : 'none',
						}}>
						<Select
							showSearch
							mode="multiple"
							optionFilterProp="children"
							placeholder="Tỉnh thành"
							allowClear
							onChange={onGenderChangeTinhThanh}>
							{form.getFieldValue('ParentId')
								? arrayTinhthanh
								: props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
								? tinhThanhsAdmin
								: tinhThanhscustomer}
						</Select>
					</Form.Item>

					<Form.Item
						name="QuanHuyen"
						label="Quận Huyện"
						style={{
							display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? '' : 'none',
						}}>
						<Select showSearch mode="multiple" optionFilterProp="children" placeholder="Quận Huyện" allowClear>
							{arrayOptionQuanhuyen}
						</Select>
					</Form.Item>

					<Form.Item
						name="TaxCode"
						label="Mã số thuế"
						style={{
							display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? 'none' : '',
						}}>
						<Input placeholder="Nhập mã số thuế" />
					</Form.Item>

					<Form.Item name="Info" label="Ghi chú">
						<TextArea
							rows={loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? 7 : 10}
							placeholder="Nhập ghi chú"
						/>
					</Form.Item>
				</Col>
			</Row>

			<Divider className="mt36" />
			<Row justify="end" className={`mt10 ${props.Id ? '' : 'justify-content--between'}`}>
				<Checkbox style={{ display: props.Id ? 'none' : '' }} onChange={onChange}>
					Thêm và tiếp tục
				</Checkbox>
				<div>
					<Button
						onClick={() => {
							dialog.close();
						}}
						type="default">
						{t('label.cancel')}{' '}
					</Button>
					<Button className="ml10" htmlType={'submit'} type="primary">
						{props.Id ? 'Cập nhật' : 'Thêm mới'}
					</Button>
				</div>
			</Row>
		</Form>
	);
};

export default withTranslation()(ChiTietKhachHangDialog);
