import { Button, Divider, Form, Input, Space } from 'antd';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React from 'react';
import { useDialog } from 'react-st-modal';
import AuthService, { ResetPasswordByUserPayload } from 'services/api/authenService/authService';
import { systemProperties } from 'systemProperties';
import './ChangePasswordDialog.scss';

const PASSWORD_PATTERN = systemProperties.passWord;
const PASSWORD_MESSAGE = 'Mật khẩu không phù hợp. Vui Lòng nhập lại.';

const ChangePasswordDialog = (props: any) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	const handleSubmitForm = async (formValues: ResetPasswordByUserPayload) => {
		try {
			await AuthService.resetPassWordByUser(formValues);
			await AuthService.login({ userName: props.userName, password: formValues.NewPassword });
			dialog.close();
			showNotification('success', t('notification.success'), 'Cập nhật mật khẩu thành công');
		} catch (error: any) {
			showNotification('error', t('notification.error'), error.response.data);
		}
	};

	return (
		<div className="change-password-container">
			<Form layout="vertical" form={form} onFinish={handleSubmitForm}>
				<Form.Item
					name="OldPassword"
					label="Mật khẩu hiện tại"
					rules={[
						{
							required: true,
							whitespace: true,
							message: 'Vui lòng nhập mật khẩu hiện tại.',
						},
					]}>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="NewPassword"
					label="Mật khẩu mới"
					rules={[
						{
							required: true,
							whitespace: true,
							message: 'Vui lòng nhập mật khẩu mới.',
						},
						{
							pattern: PASSWORD_PATTERN,
							message: PASSWORD_MESSAGE,
						},
					]}>
					<Input.Password />
				</Form.Item>

				<Form.Item
					name="ReNewPassword"
					label="Xác nhận mật khẩu mới"
					dependencies={['NewPassword']}
					rules={[
						({ getFieldValue }) => ({
							validator: (_, value) => {
								if (!value || getFieldValue('NewPassword') === value) {
									return Promise.resolve();
								}
								return Promise.reject(new Error('Xác nhận mật khẩu chưa trùng khớp, vui lòng nhập lại.'));
							},
						}),
					]}>
					<Input.Password />
				</Form.Item>

				<p>
					<strong>Lưu ý:</strong> Mật khẩu cần ít nhất 8 ký tự bao gồm: chữ hoa, chữ thường, số và ký tự đặc biệt.
				</p>

				<Divider />

				<div className="change-pasword-action mt20">
					<Space>
						<Button type="default" onClick={() => dialog.close()}>
							Thoát
						</Button>
						<Button type="primary" htmlType="submit">
							Cập nhật
						</Button>
					</Space>
				</div>
			</Form>
		</div>
	);
};

export default ChangePasswordDialog;
