import { Spin, Table } from 'antd';
import { t } from 'i18next';
import React from 'react';
import { withTranslation } from 'react-i18next';

interface TableComponentProps {
	dataSource: any;
	columns: any;
	onRow?: any;
	loading?: boolean;
	footer?: any;
	paginator?: any;
	height?: any;
	width?: any;
	bordered?: any;
	containerClass?: string;
}

const TableComponent: React.FC<TableComponentProps> = (props: TableComponentProps) => {
	return (
		<Table
			className={'table-container ' + props.containerClass}
			style={{ border: '1px solid #f0f0f0' }}
			rowClassName={(_, index) =>
				index % 2 === 0 ? 'cursor-table-select-row' : 'table-row-dark cursor-table-select-row'
			}
			locale={{ emptyText: t('label.emptyData') }}
			showSorterTooltip={true}
			dataSource={props.dataSource}
			bordered={props.bordered}
			columns={props.columns}
			rowKey={(record: any) => record?.Id}
			onRow={props.onRow}
			pagination={props.paginator}
			loading={{ indicator: <Spin />, spinning: props.loading || false }}
			footer={props.footer}
			scroll={{ x: props?.width, y: props?.height }}
		/>
	);
};

export default withTranslation()(TableComponent);
