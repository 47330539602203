import store from 'redux/store';
import { setCollapse, setTitle } from 'redux/NavBar/navBar.action';

const NavBarDispatcher = {
	setCollapse: (input: boolean) => {
		return store.dispatch(setCollapse(input));
	},
	setTitle: (input: string) => {
		return store.dispatch(setTitle(input));
	},
};

export default NavBarDispatcher;