import ApiService, { INVENTORY_BASE_URL, PageOption } from '../../index';

export const prefixCustomer = '/Customer';

const _createCustomer = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCustomer}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getCustomer = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixCustomer}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getCustomerAll = (input?: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(input ? `${prefixCustomer}/all?CustomerType=${input}` : `${prefixCustomer}/all`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getCustomerCombobox = (input?: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCustomer}/comboboxItem`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listCustomer = (): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixCustomer}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateCustomer = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixCustomer}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchCustomer = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCustomer}/Search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteCustomer = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixCustomer}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deactiveCustomer = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCustomer}/deactive?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _activeCustomer = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCustomer}/active?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const CustomerService = {
	createCustomer: _createCustomer,
	getCustomer: _getCustomer,
	listCustomer: _listCustomer,
	updateCustomer: _updateCustomer,
	searchCustomer: _searchCustomer,
	deleteCustomer: _deleteCustomer,
	deactiveCustomer: _deactiveCustomer,
	getCustomerAll: _getCustomerAll,
	activeCustomer: _activeCustomer,
	getCustomerCombobox: _getCustomerCombobox
};

export default CustomerService;
