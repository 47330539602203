import { Modal } from 'antd';
import axios, { AxiosInstance } from 'axios';
import permissionEnum from 'enum/permissionEnum';
import { logError } from 'helpers/log-service';
import TokenService, { LOGIN_INFO } from '../tokenService';
import { default as authService, prefixAuth } from './authenService/authService';
const { confirm } = Modal;
export const AUTH_BASE_URL: string = process.env.REACT_APP_AUTH_BASE_URL || 'https://dev-api.basato.vn/authen/api';
export const COMMON_BASE_URL: string = process.env.REACT_APP_BACKEND_ENDPOINT || 'https://localhost:44310/api/';
export const BUSINESS_BASE_URL: string =
	process.env.REACT_APP_BUSINESS_BASE_URL || 'https://dev-api.basato.vn/business/api';
export const HANDLER_BASE_URL: string =
	process.env.REACT_APP_HANDLER_BASE_URL || 'https://dev-api.basato.vn/handler/api';
export const INVENTORY_BASE_URL: string =
	process.env.REACT_APP_INVENTORY_BASE_URL || 'https://dev-api.basato.vn/inventory/api';
export const REPORT_BASE_URL: string = process.env.REACT_APP_REPORT_BASE_URL || 'https://dev-api.basato.vn/report/api';
export const PROVINCE_BASE_URL: string = process.env.REACT_APP_PROVINCE_BASE_URL || 'https://radiotech.vn/data.json';
export const FACT_BASE_URL: string = process.env.REACT_APP_FACT_BASE_URL || 'https://dev-api.basato.vn/fact/api';

export interface PageOption<T = any> {
	CurrentPage: number;
	Data?: T[];
	EndIndex?: number;
	EndPage?: number;
	PageSize: number;
	Pages?: any;
	Paging: boolean;
	StartIndex?: number;
	StartPage?: number;
	TotalItems?: number;
	TotalPages?: number;
}

const ApiService = (urlBase: string): AxiosInstance => {
	const instance = axios.create({
		baseURL: urlBase,
		headers: {
			'Content-Type': 'application/json',
		},
	});

	instance.interceptors.request.use(
		config => {
			document.body.classList.add('loading-indicator');
			const token: string | undefined = TokenService.getAccessToken();
			if (token) {
				config.headers['Authorization'] = 'Bearer ' + token;
			}
			return config;
		},
		error => {
			document.body.classList.remove('loading-indicator');
			logError(error);
			return Promise.reject(error);
		},
	);
	instance.interceptors.response.use(
		res => {
			document.body.classList.remove('loading-indicator');
			return res;
		},
		async err => {
			document.body.classList.remove('loading-indicator');
			const originalConfig = err.config;
			if (originalConfig?.url !== `${prefixAuth}/login` && err.response) {
				// Access Token was expired
				if (err.response.status === 401 && !originalConfig._retry && localStorage.getItem(LOGIN_INFO)) {
					originalConfig._retry = true;

					try {
						await authService.refreshToken({
							RefreshToken: TokenService.getRefreshToken() || '',
						});
						return instance(originalConfig);
					} catch (_error) {
						TokenService.removeLoginInfo();
						window.location.pathname = '/login';
						window.location.search = '';
						return Promise.reject(_error);
					}
				} else if (
					(err.response.status === 401 &&
						!window.location.href.includes('login') &&
						!window.location.href.includes('quenMatKhau') &&
						!window.location.href.includes('resetPassWord') &&
						!window.location.href.includes('404')) ||
					(err.response.status === 403 &&
						(err.response.data === 'Bạn cần đăng nhập lại do đã đổi mật khẩu mới.' ||
							err.response.data === 'Tài khoản của bạn thuộc khách hàng đã bị khóa.' ||
							err.response.data === 'Tài khoản của bạn đã bị khóa.' ||
							err.response.data === 'Hệ thống có bản cập nhật mới, bạn cần đăng nhập lại.' ||
							err.response.data === 'Thông tin phân quyền có sự thay đổi. Yêu cầu người dùng đăng nhập lại.'))
				) {
					if (err.response.data) {
						// message.error(err.response.data).then(_ => {
						// 	TokenService.removeLoginInfo();
						// 	window.location.pathname = '/login';
						// 	window.location.search = '';
						// });
						confirm({
							title: 'Thông báo',
							content: err.response.data,
							okText: 'Đăng xuất',
							okType: 'danger',
							cancelText: 'Đóng',
							onOk() {
								TokenService.removeLoginInfo();
								window.location.pathname = '/login';
								window.location.search = '';
							},
							onCancel() {
								//Do Nothing
							},
						});
					} else {
						TokenService.removeLoginInfo();
						window.location.pathname = '/login';
						window.location.search = '';
					}
				} else if (err.response.status === 403 && !isNaN(+err.response.data)) {
					// message.error(
					// 	'Bạn không có quyền ' +
					// 		permissionEnum.fromEnum(+err.response.data).label +
					// 		', vui lòng liên hệ với quản trị viên!',
					// );
					confirm({
						title: 'Thông báo',
						content:
							'Bạn không có quyền ' +
							permissionEnum.fromEnum(+err.response.data).label +
							', vui lòng liên hệ với quản trị viên!',
						okText: 'Đăng xuất',
						okType: 'danger',
						cancelText: 'Đóng',
						onOk() {
							TokenService.removeLoginInfo();
							window.location.pathname = '/login';
							window.location.search = '';
						},
						onCancel() {
							//Do Nothing
						},
					});
				}
			}
			let errorMessage = '';
			if (err.response?.data) {
				if (err.response?.data?.error) errorMessage = err.response.data.error;
				errorMessage = err.response?.data;
			}
			const newLogMessage = {
				config: {
					baseUrl: originalConfig?.baseURL,
					...(Object.keys(originalConfig?.data || []).length > 0 && { data: originalConfig?.data || [] }),
					url: originalConfig?.url,
					method: originalConfig?.method,
				},
				errorMessage,
				status: err?.response?.status,
				stausText: err?.response?.statusText,
			};
			logError(newLogMessage);
			return Promise.reject(err);
		},
	);

	return instance;
};

export default ApiService;
