import { SearchOutlined, UserOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Pagination, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment, { Moment } from 'moment';
import { RangeValue } from 'rc-picker/lib/interface';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import UsersLoginService from 'services/api/authenService/usersLoginService';
import { UserInfo } from 'services/api/authenService/usersService';
import { PermissionProps } from './NguoiDung';

const { RangePicker } = DatePicker;

const LichSuDangNhap: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const [TongSoDiemChay, setTongSoDiemChay] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [fromDate, setFromDate] = useState<Date>();
	const [toDate, setToDate] = useState<Date>();

	const columns: ColumnsType<UserInfo> = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * 10 + index + 1,
			title: t('label.order'),
		},
		{
			dataIndex: 'FullName',
			title: t('label.fullName'),
		},
		{
			dataIndex: 'UserName',
			title: t('label.userName'),
		},
		// {
		// 	dataIndex: 'hanhDong',
		// 	title: t('label.hanhDong'),
		// },
		{
			dataIndex: 'LoginTime',
			title: t('label.thoiGian'),
		},
		{
			dataIndex: 'Info',
			title: t('label.thongTin'),
		},
	];

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('lichSuDangNhap.title');
		NavBarDispatcher.setTitle(`${t('lichSuDangNhap.title')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.USER_HISTORY_VIEW])) {
				SearchLichSuDangNhap(currentPage);
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	function onChangePage(page: number, pageSize: number) {
		setCurrentPage(page);
		SearchLichSuDangNhap(page, pageSize);
	}

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			SearchLichSuDangNhap();
		}
	};

	function SearchLichSuDangNhap(page = 1, pageSize = 20) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);
		const dateFrom = fromDate ? moment(fromDate).format('MM-DD-YYYY') : '';
		const dateTo = toDate ? moment(toDate).format('MM-DD-YYYY') : '';
		UsersLoginService.searchUsersLogin(
			form.getFieldValue('Name') ? form.getFieldValue('Name') : '',
			dateFrom,
			dateTo,
			pageOption,
		)
			.then((res: any) => {
				setTongSoDiemChay(res.TotalItems);
				optiondanhsachHoatDong(res ? res.Data : []);
			})
			.catch();
	}

	const optiondanhsachHoatDong = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any) => {
				item.LoginTime = moment(item.LoginTime).format('DD/MM/YYYY HH:mm:ss');
				array.push(item);
			});
		}
		Promise.all(promises)
			.then(() => {
				setUsers(array);
			})
			.catch();
	};

	const onChangeDate = (values: RangeValue<Moment>) => {
		setFromDate(values?.at(0)?.toDate());
		setToDate(values?.at(1)?.toDate());
	};

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong>
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={20}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoDiemChay}
					showTotal={showTotal}
					pageSizeOptions={['10', '20', '30']}
				/>
			</div>
		);
	};

	return (
		<Form id="lichSuDangNhap" layout="vertical" name="normal_login" form={form}>
			<div className={'flex--row'}>
				<Form.Item name="Name" className="withMinSelect">
					<Input placeholder="Nhập tên đăng nhập" prefix={<UserOutlined />} onKeyPress={handleKeyDown} allowClear />
				</Form.Item>
				<RangePicker
					className="ml10"
					dateRender={current => {
						return <div className="ant-picker-cell-inner">{current.date()}</div>;
					}}
					format={'YYYY/MM/DD'}
					onChange={onChangeDate}
				/>
				<Button
					className="ml10"
					type="primary"
					onClick={() => {
						SearchLichSuDangNhap();
					}}>
					{<SearchOutlined />}Tìm kiếm
				</Button>
			</div>
			<div className={'break-line mt10'} />
			<div className={'border-quan-ly-cong-tac-pc text--center'}>
				<h2 className={'mb0'}>{t('nav.lichSuDangNhap')}</h2>
			</div>
			<Table
				className="table-header-bored"
				rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
				locale={{ emptyText: t('label.emptyData') }}
				showSorterTooltip={false}
				dataSource={users}
				pagination={false}
				columns={columns}
				style={{ whiteSpace: 'pre' }}
				footer={() => handleFooterTable(currentPage, onChangePage)}
			/>
		</Form>
	);
};

export default withTranslation()(LichSuDangNhap);
