import { t } from 'i18next';

export enum DanhGiaEnum {
	DAM_BAO = 'DAM_BAO',
	CHUA_DAM_BAO = 'CHUA_DAM_BAO'
}

interface DanhGia {
	label: string
	value: DanhGiaEnum,
}

const _fromEnum = (danhGiaEnum: DanhGiaEnum): DanhGia => {
	switch (danhGiaEnum) {
		case DanhGiaEnum.DAM_BAO:
			return {
				label: t('enum.danhGia.DAM_BAO'),
				value: DanhGiaEnum.DAM_BAO,
			};
		case DanhGiaEnum.CHUA_DAM_BAO:
			return {
				label: t('enum.danhGia.CHUA_DAM_BAO'),
				value: DanhGiaEnum.CHUA_DAM_BAO,
			};
	}
};

const _getAll = (): DanhGia[] => {
	return Object.values(DanhGiaEnum).map(r => _fromEnum(r as DanhGiaEnum));
};

const danhGiaEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll
};
export default danhGiaEnum;
