import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomDialog } from 'react-st-modal';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import errorManagementService from 'services/api/factService/errorManagementService';
import QuanLyLoiDialog from './Dialog/QuanLyLoiDialog';
import { PermissionProps } from './QuanLyChatLuongThietBi';

const DATE_TIME_FORMAT = 'DD/MM/YYYY: HH:mm:ss';

const QuanLyLoi: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const [TongSoDiemChay, setTongSoMaLoi] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			title: t('label.order'),
		},
		{
			dataIndex: 'ErrorCode',
			title: 'Mã lỗi',
		},
		{
			dataIndex: 'Description',
			title: 'Mô tả',
		},
		{
			dataIndex: 'CreateTime',
			title: 'Thời gian cập nhật',
			defaultSortOrder: 'descend',
			sorter: (firstItem: any, secondItem: any) => {
				if (firstItem.CreateTime && secondItem.CreateTime) {
					const firstUnixTime = moment(firstItem.CreateTime, DATE_TIME_FORMAT).unix();
					const secondUnixTime = moment(secondItem.CreateTime, DATE_TIME_FORMAT).unix();
					return firstUnixTime - secondUnixTime;
				}
				return;
			},
		},
		{
			dataIndex: 'Creator',
			title: 'người cập nhật',
		},
		{
			align: 'center' as const,
			width: 100,
			title: 'Thao tác',
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
				props.permissionList.includes(PermissionEnum.FACT_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.FACT_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle={`Chỉnh sửa lỗi "${record.ErrorCode}"`}
					EditDialog={<QuanLyLoiDialog errorCode={record.ErrorCode} Id={record.Id} callback={SearchDanhSachLoi} />}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.ErrorCode}
							callback={SearchDanhSachLoi}
							deleteService={errorManagementService.deleteErrorInfo}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.quanLyLoi');
		NavBarDispatcher.setTitle(`${t('nav.quanLyLoi')}`);
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FACT_REPORT_VIEW])) {
				SearchDanhSachLoi();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	function onChangePage(page: number, pageSize: number) {
		const index = Number((page - 1) * pageSize);
		SearchDanhSachLoi(page, pageSize, index);
	}

	function SearchDanhSachLoi(page = 1, pageSize = 10, index = 0) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);

		errorManagementService
			.getbyCode(form.getFieldValue('MaLoi') ? form.getFieldValue('MaLoi') : null, pageOption)
			.then((res: any) => {
				setTongSoMaLoi(res.TotalItems);
				optiondanhsachMaLoi(res ? res.Data : [], index);
			});
	}

	const optiondanhsachMaLoi = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any, index: number) => {
				if (item.CreateTime) {
					item.CreateTime = moment(item.CreateTime).format(DATE_TIME_FORMAT);
				}
				item.key = ++_index;
				array.push(item);
			});
		}
		Promise.all(promises).then(() => setUsers(data));
	};

	const handleSearchMaLoi = (value: string) => {
		if (!value) {
			form.resetFields(['MaLoi']);
		}
		SearchDanhSachLoi();
	};

	const formAction = (
		<>
			<div className={'flex--row'}>
				<Form.Item name="MaLoi">
					<Input.Search placeholder="Nhập mã lỗi" allowClear onSearch={(value: string) => handleSearchMaLoi(value)} />
				</Form.Item>
				{props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type={'primary'}
						onClick={async () => {
							await CustomDialog(<QuanLyLoiDialog callback={SearchDanhSachLoi} />, {
								className: 'dialog-add',
								title: 'Thêm mới lỗi',
							});
						}}>
						<PlusOutlined />
						{t('label.create')}
					</Button>
				) : null}
			</div>
		</>
	);

	const formTable = (
		<TableComponent
			dataSource={users}
			columns={columns}
			paginator={{
				defaultPageSize: 10,
				showSizeChanger: true,
				onChange: onChangePage,
				total: TongSoDiemChay,
				current: currentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);
	return <PageWrapper titlePage={t('quanLyLoi.title')} formAction={formAction} formTable={formTable} form={form} />;
};

export default withTranslation()(QuanLyLoi);
