import { LoaiKhachHangEnum } from 'enum/loaiKhachHangEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import ApiService, { AUTH_BASE_URL, PageOption } from 'services/api/index';

const prefixUsers = '/users';

export interface CreateUserParams {
	Id?: string;
	FullName: string;
	UserName: string;
	Password: string;
	CustomerId: string;
	RoleIds?: string[];
	Permissions?: PermissionEnum[];
	Email: string;
	PhoneNumber: string;
	ConstructionId?: string[];
	Locations: any[];
	IsActive: string;
}

const _createUser = (params: CreateUserParams): Promise<any> =>
	ApiService(AUTH_BASE_URL).post(`${prefixUsers}/create-user`, params);

export interface UserInfo {
	AvatarLink: string | undefined;
	Id: string;
	CustomerId: string;
	FullName: string;
	key: number;
	RoleIds?: string[];
	UserName: string;
	PhoneNumber: string;
	Email: string;
	Permissions: PermissionEnum[] | null;
	ConstructionId: string[] | null;
	Locations: any[] | null;
	InvalidPasswordCount: number;
	IsActive: string; // TODO: Create Enum;
	PcccUnitId: string | null;
	CustomerType: LoaiKhachHangEnum;
}

const _getUser = (username: string): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsers}/get-user/${username}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getUserById = (Id: string): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsers}/get-user-by-id/${Id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getUserByToken = (): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsers}`)
		.then(({ data }: { data: UserInfo }) => Promise.resolve(data));

const _getUsers = (pageOption?: PageOption): Promise<UserInfo[]> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsers}/get-users`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: UserInfo[] }) => {
			return Promise.resolve(data);
		});

const _updateUser = (input: CreateUserParams): Promise<any> =>
	ApiService(AUTH_BASE_URL).put(`${prefixUsers}/update-user`, input);

const _searchData = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(AUTH_BASE_URL)
		.post(`${prefixUsers}/SearchData`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteUser = (input: any): Promise<any> =>
	ApiService(AUTH_BASE_URL)
		.delete(`${prefixUsers}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getPermission = (): Promise<any[]> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsers}/getPermissionCQCN`)
		.then(({ data }: { data: any[] }) => {
			return Promise.resolve(data);
		});

const UserService = {
	createUser: _createUser,
	getUser: _getUser,
	getUserByToken: _getUserByToken,
	getUsers: _getUsers,
	updateUser: _updateUser,
	deleteUser: _deleteUser,
	searchData: _searchData,
	getUserById: _getUserById,
	getPermission: _getPermission,
};

export default UserService;
