import React, { FunctionComponent } from 'react';
import ReactPlayer from 'react-player';

interface DialogCameraProps {
	Imei?: any;
	mode?: string;
}

const DialogCamera: FunctionComponent<DialogCameraProps> = (props: DialogCameraProps) => {
	return (
		<div className="common-dialog">
			<ReactPlayer
				url="https://stream.binhanh.vn/live/869336032632356_CAM1/playlist.m3u8"
				playing
				style={{ width: '100%', height: 'auto' }}
				controls={true}
				// config={{
				// 	file: {
				// 		forceFLV: true,
				// 	},
				// }}
			/>
		</div>
	);
};

export default DialogCamera;
