import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Space } from 'antd';
import SensorTypeSelect from 'components/shared/form-select/SensorType/SensorType';
import DialogLayout from 'components/shared/layouts/DialogLaypout/DialogLayout';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import { formatDate, showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import senSorsService from 'services/api/inventoryService/senSorsService';
import Utils from 'services/api/utils';
import { systemProperties } from 'systemProperties';
import './AddSensorDialog.scss';

interface AddSensorDialogProps {
	mode?: 'add' | 'edit' | 'view';
	id?: string;
	callback?: () => void;
	imei?: string;
}

interface ActiveStatusInfo {
	AlertStatus: 'CO_CANH_BAO' | 'KHONG_CO_CANH_BAO';
	UpdateTime: string;
	Battery: number | null;
	Smoke: number | null;
	Temperature: number | null;
}

const INIT_ACTIVE_STATUS_INFO: ActiveStatusInfo = {
	AlertStatus: 'KHONG_CO_CANH_BAO',
	UpdateTime: '',
	Battery: null,
	Smoke: null,
	Temperature: null,
};

const CONNECTION_STATUS = {
	CO_CANH_BAO: {
		label: 'Đang hoạt động',
		bgColor: '#D1F0E1',
		textColor: '#1BB36B',
	},
	KHONG_CO_CANH_BAO: {
		label: 'Mất kết nối',
		bgColor: '#FFD2CC',
		textColor: '#FF2000',
	},
};

const AddSensorDialog = (props: AddSensorDialogProps) => {
	const { mode = 'add', id, callback, imei } = props;

	const [form] = Form.useForm();
	const dialog = useDialog();
	const centerDeviceList = Utils.GetDSThietBi();

	// states
	const [activeStatusInfo, setActiveStatusInfo] = useState<ActiveStatusInfo>(INIT_ACTIVE_STATUS_INFO);

	const handleSuccess = (remember: boolean, type: 'create' | 'update') => {
		showNotification(
			'success',
			t('notification.success'),
			t(type === 'create' ? 'notification.success_create_desc' : 'notification.success_update_desc', {
				item: form.getFieldValue('SerialNumber'),
			}),
		);
		form.resetFields();
		if (callback) callback();
		if (!remember) {
			dialog.close();
			return;
		}
	};

	const handleError = (errors: any) =>
		showNotification('error', t('notification.error'), errors.response.data || errors.message);

	const handleSubmitForm = (formvalues: any) => {
		const { remember, dateRange, SensorType, ...remainFormValues } = formvalues;
		const payload = {
			...remainFormValues,
			Type: SensorType,
			InstalledTime: dateRange[0],
			ExpiredTime: dateRange[1],
		};
		if (id) {
			payload.Id = id;
			senSorsService
				.updateSenSors(payload)
				.then(() => handleSuccess(remember, 'update'))
				.catch((errors: any) => handleError(errors));
		} else {
			senSorsService
				.createSenSors(payload)
				.then(() => handleSuccess(remember, 'create'))
				.catch((errors: any) => handleError(errors));
		}
	};

	useEffect(() => {
		if (id) {
			senSorsService.getSensor(id).then((result: any) => {
				if (!result) {
					return;
				}
				setActiveStatusInfo({
					AlertStatus: result?.AlertStatus,
					UpdateTime: result?.UpdateTime,
					Battery: result?.Battery,
					Smoke: result?.Smoke,
					Temperature: result?.Temperature,
				});
				const dateRange =
					result?.InstalledTime && result?.ExpiredTime
						? [moment(result?.InstalledTime), moment(result?.ExpiredTime)]
						: [];
				form.setFieldsValue({
					...result,
					SensorType: result.Type,
					Construction: result.Construction.Name,
					dateRange,
				});
			});
		}
		if (imei) {
			form.setFieldsValue({ PairingDevice: imei });
		}
	}, [id, form, imei]);

	const activeStatusOfSensorTemplate = id ? (
		<>
			<Divider />
			<div className="mt16 mb16">
				<div className="active-status-header">
					<h4>Trạng thái hoạt động</h4>
					<span>Cập nhật lần cuối lúc: {formatDate(activeStatusInfo.UpdateTime, systemProperties.dateTimeFormat)}</span>
				</div>
				<div className="active-status-info">
					<div className="active-status-item">
						<span>Kết nối</span>
						<span
							className="value"
							style={{
								backgroundColor: CONNECTION_STATUS[activeStatusInfo.AlertStatus].bgColor,
								color: CONNECTION_STATUS[activeStatusInfo.AlertStatus].textColor,
							}}>
							{CONNECTION_STATUS[activeStatusInfo.AlertStatus].label}
						</span>
					</div>
					<div className="active-status-item">
						<span>Pin</span>
						<span className="value">{activeStatusInfo.Battery || 0}%</span>
					</div>
					<div className="active-status-item">
						<span>Nhiệt độ</span>
						<span className="value">{activeStatusInfo.Temperature || 0}&#8451;</span>
					</div>
					<div className="active-status-item">
						<span>Khói</span>
						<span className="value">{activeStatusInfo.Smoke || 0}%</span>
					</div>
				</div>
			</div>
		</>
	) : null;

	return (
		<DialogLayout className="add-sensor-dialog-wrapper">
			<Form layout="vertical" form={form} onFinish={handleSubmitForm} disabled={mode === 'view'}>
				<Row gutter={32}>
					<Col span={12}>
						<Form.Item name="SerialNumber" label="Số serial">
							<Input allowClear placeholder="Nhập số serial" />
						</Form.Item>
						<Form.Item
							name="Mac"
							label="Địa chỉ mac"
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Địa chỉ mac không được bỏ trống',
								},
							]}>
							<Input allowClear placeholder="Nhập địa chỉ mac" />
						</Form.Item>
						<SensorTypeSelect
							label="Loại cảm biến"
							form={form}
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn loại cảm biến',
								},
							]}
						/>
						<Form.Item
							name="PairingDevice"
							label="Thiết bị trung tâm kết nối"
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn thiết bị trung tâm',
								},
							]}>
							<Select
								showSearch
								optionFilterProp="children"
								disabled={!!imei}
								placeholder="Chọn thiết bị trung tâm"
								allowClear>
								{centerDeviceList}
							</Select>
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="InstallLocation"
							label="Vị trí lắp đặt"
							rules={[
								{
									required: true,
									message: 'Vị trí lắp đặt không được bỏ trống',
								},
							]}>
							<Input allowClear placeholder="Nhập vị trí lắp đặt" />
						</Form.Item>
						<RangePickerInput
							label="Thời gian hoạt động"
							form={form}
							rules={[{ required: true, message: 'Vui lòng chọn thời gian hoạt động' }]}
						/>
						<Form.Item name="Info" label="Ghi chú">
							<Input.TextArea allowClear rows={5} placeholder="Nhập ghi chú" />
						</Form.Item>
					</Col>
				</Row>

				{activeStatusOfSensorTemplate}

				<Divider className="mb15" />

				<div className="flex--row justify-content--between align-items--center">
					<Form.Item
						name="remember"
						valuePropName="checked"
						style={{ marginBottom: 0, visibility: id ? 'hidden' : 'visible' }}>
						<Checkbox>Thêm và tiếp tục</Checkbox>
					</Form.Item>

					<Space>
						{mode !== 'view' && (
							<>
								<Button onClick={() => dialog.close()}>Huỷ</Button>
								<Button type="primary" htmlType="submit">
									{id ? 'Cập nhật' : 'Thêm mới'}
								</Button>
							</>
						)}
					</Space>
				</div>
			</Form>
			{mode === 'view' && (
				<Row justify="end">
					<Button htmlType="button" onClick={() => dialog.close()}>
						Thoát
					</Button>
				</Row>
			)}
		</DialogLayout>
	);
};

export default AddSensorDialog;
