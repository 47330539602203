import { Layout } from 'antd';
import 'antd/dist/antd.less';
import 'App.scss';
import 'common.scss';
import Router from 'components/routers/Router';
import Header from 'components/shared/Header/Header';
import LineBreak from 'components/shared/LineBreak';
import NavBar, { NavTabList } from 'components/shared/NavBar/NavBar';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import AuthService from 'services/api/authenService/authService';
import localFirebase from './firebase';
import { CustomDialog } from 'react-st-modal';
import ConfirmDialog from './components/shared/Dialog/ConfirmDialog';
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import firebaseMessageDispatcher from './redux/firebaseMessage/firebaseMessage.dispatcher';
import fireProtectionService from './services/api/reportService/fireProtectionService';

moment.locale('vi');

export enum FireAlertStatus {
	END_FIRE_ALERT = 'END_FIRE_ALERT',
	START_FIRE_ALERT = 'START_FIRE_ALERT',
	FIRE_PROTECTION = 'FIRE_PROTECTION',
	END_FIRE_PROTECTION = 'END_FIRE_PROTECTION',
	SUPPORT_FIRE_PROTECTION = 12,
	CANCEL_SUPPORT_FIRE_PROTECTION = 13,
}

export interface FirebaseMessage {
	collapseKey?: string;
	from: string;
	messageId?: string;
	notification: {
		title: string;
		body: string;
	};
	data: {
		id: string;
		type: FireAlertStatus;
	};
}

const App: FunctionComponent = () => {
	const [isAuthenticated, setIsAuthenticated] = useState<boolean>();
	const location = useLocation();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const id = searchParams.get('id');
	// receive notification server trả về
	localFirebase.messaging().onMessage((payload: FirebaseMessage) => {
		if (location.pathname === NavTabList.GIAM_SAT_TRUC_TUYEN.value) {
			if (id && payload.data.id !== id && payload.data.type === FireAlertStatus.START_FIRE_ALERT) {
				fireProtectionService.getFireProtectionForDialog(payload.data.id).then(res => {
					CustomDialog(
						<ConfirmDialog
							title={t('dialog.newFire')}
							detail={
								<Trans
									i18nKey={'dialog.newFireDetailGSTT'}
									values={{
										locationDetail: res.Construction?.TenCoSoCongTrinh ? res.Construction?.TenCoSoCongTrinh : res?.Name,
									}}
									components={{
										bold: <b />,
									}}
								/>
							}
							okBtnText={t('label.dongY')}
							okCallback={() => window.open(window.location.origin + `/giamSatTrucTuyen?id=${payload.data.id}`)}
							cancelBtnText={t('dialog.dong')}
						/>,
					);
				});
			} else if (payload && (FireAlertStatus.START_FIRE_ALERT || FireAlertStatus.END_FIRE_ALERT)) {
				firebaseMessageDispatcher.setMessage(payload);
			}
		} else {
			fireProtectionService.getFireProtectionForDialog(payload.data.id).then(res => {
				const isCustomerType = localStorage.getItem('LocationInfo') ? false : true;
				// noinspection JSIgnoredPromiseFromCall
				if (payload.data.type === FireAlertStatus.START_FIRE_ALERT) {
					CustomDialog(
						<ConfirmDialog
							title={t('dialog.newFire')}
							detail={
								<Trans
									i18nKey={'dialog.newFireDetail'}
									values={{
										locationDetail: res.Construction?.TenCoSoCongTrinh ? res.Construction?.TenCoSoCongTrinh : res?.Name,
									}}
									components={{
										bold: <b />,
									}}
								/>
							}
							disableOK={isCustomerType}
							okBtnText={t('label.chuyenTrang')}
							okCallback={() =>
								navigate({ pathname: NavTabList.GIAM_SAT_TRUC_TUYEN.value, search: `?id=${payload.data.id}` })
							}
							cancelBtnText={t('dialog.dong')}
						/>,
					);
				} else if (payload.data.type === FireAlertStatus.END_FIRE_ALERT) {
					CustomDialog(
						<ConfirmDialog
							title={t('dialog.endFire')}
							detail={
								<Trans
									i18nKey={'dialog.endFireDetail'}
									values={{
										locationDetail: res.Construction?.TenCoSoCongTrinh ? res.Construction?.TenCoSoCongTrinh : res?.Name,
									}}
									components={{
										bold: <b />,
									}}
								/>
							}
						/>,
					);
				}
			});
		}
	});

	// getToken và push tokenID lên server
	useEffect(() => {
		setIsAuthenticated(AuthService.isAuthenticated() && window.location.pathname !== '/404');
	}, []);

	return (
		<Layout>
			{isAuthenticated && <NavBar />}
			<Layout.Content>
				{isAuthenticated && (
					<>
						<Header />
						<LineBreak />
					</>
				)}
				<div className={'content-container'}>
					<Router />
				</div>
			</Layout.Content>
		</Layout>
	);
};
export default App;
