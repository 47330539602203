import React from 'react';

interface CheckingStatusIconProps {
	color: string;
	className?: string;
}

const CheckingStatusIcon = (props: CheckingStatusIconProps) => {
	const color = `#${props.color}`;
	return (
		<span className={props.className}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<rect width="12" height="12" rx="8" fill={color} />
			</svg>
		</span>
	);
};

export default CheckingStatusIcon;
