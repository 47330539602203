import { Button } from 'antd';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React from 'react';
import { useDialog } from 'react-st-modal';
import DutyScheduleService from 'services/api/businessService/dutyScheduleService';

interface ScheduleDutyDeleteModalProps {
	scheduleId: string | null;
	teamId: string;
	teamName: string;
	callback: any;
}

const ScheduleDutyDeleteModal = ({ scheduleId, teamId, teamName, callback }: ScheduleDutyDeleteModalProps) => {
	const dialog = useDialog();

	const handleDelete = () => {
		if (scheduleId) {
			DutyScheduleService.deleteDutyScheduleTeamSonLa(scheduleId, teamId)
				.then(() => {
					showNotification('success', t('notification.success'), 'Xoá hạng mục thành công');
					dialog.close();
					callback();
				})
				.catch(error => {
					showNotification(
						'error',
						t('notification.error'),
						typeof error?.response?.message === 'string' ? error?.response?.message : 'Lỗi không xác định',
					);
				});
		}
	};

	return (
		<div className="dialog-delete-container">
			<div className="dialog-delete-image">
				<img alt={'CONFIRM_ICON'} src={'icon/danger.svg'} height={80} />
			</div>
			<h4 className="mt25 heading--6">Xác nhận xóa?</h4>
			<p className="mt10">Bạn chắc chắn muốn xóa "{teamName}"</p>
			<div style={{ display: 'flex', width: '310px', justifyContent: 'space-between' }}>
				<Button
					style={{ width: 150 }}
					type="default"
					onClick={() => {
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button type={'text'} style={{ background: '#D02127', color: '#FFFFFF', width: 150 }} onClick={handleDelete}>
					Xóa
				</Button>
			</div>
		</div>
	);
};

export default ScheduleDutyDeleteModal;
