import { Marker, useMapEvents } from 'react-leaflet';
import React, { forwardRef, ForwardRefRenderFunction, useEffect, useImperativeHandle, useState } from 'react';
import L, { LatLngLiteral } from 'leaflet';
import mapService, { BAMarkerResult } from 'services/api/businessService/mapService';

export enum MarkerType {
	DIEM_CHAY,
	CO_SO_CONG_TRINH,
	DON_VI_HO_TRO,
	DIEM_LAY_NUOC,
	DON_VI_PCCC,
}

interface ToaDoMarkerProps {
	center?: LatLngLiteral;
	markerType?: MarkerType;
	toado?: { kinhDo: number; viDo: number };
	clickCallback(arg: BAMarkerResult): void;
}

export interface ToaDoMarkerRef {
	setMarkerPosition: (newPos: LatLngLiteral) => void;
}

const ToaDoMarker: ForwardRefRenderFunction<any, ToaDoMarkerProps> = (props, ref) => {
	const [markerPosition, setMarkerPosition] = useState<LatLngLiteral | undefined>(props.center);
	const [toaDo, setToaDo] = useState<any>();
	useEffect(() => {
		if (
			(props.toado?.kinhDo && !toaDo) ||
			(props.toado?.kinhDo && (props.toado?.kinhDo !== toaDo.kinhDo || props.toado?.viDo !== toaDo.viDo))
		) {
			setToaDo({ kinhDo: props.toado?.kinhDo, viDo: props.toado?.viDo });
			setMarkerPosition({ lat: props.toado?.kinhDo, lng: props.toado?.viDo });
			mapService.reverseGeocode(props.toado?.kinhDo, props.toado?.viDo).then(props.clickCallback);
		}
	}, [props.toado]);

	useImperativeHandle(ref, () => ({
		setMarkerPosition(newPos: LatLngLiteral) {
			setMarkerPosition(newPos);
		},
	}));

	// Click Map event handler
	useMapEvents({
		click(e: any) {
			setMarkerPosition(e.latlng);
			mapService.reverseGeocode(e.latlng.lat, e.latlng.lng).then(props.clickCallback);
		},
	});

	// set icon for map
	const setIconForMap = () => {
		switch (props.markerType) {
			case MarkerType.CO_SO_CONG_TRINH:
				return 'icon/map/construction.svg';
			case MarkerType.DIEM_CHAY:
				return 'icon/map/firing.svg';
			case MarkerType.DIEM_LAY_NUOC:
				return 'icon/map/fireHydrant.svg';
			case MarkerType.DON_VI_HO_TRO:
				return 'icon/map/support-inActive.svg';
			case MarkerType.DON_VI_PCCC:
				return 'icon/map/fireStation.svg';
			default:
				return 'icon/map/firing.svg';
		}
	};

	return markerPosition ? (
		<Marker
			icon={L.icon({
				iconUrl: setIconForMap(),
				iconSize: [32, 32],
				iconAnchor: [24, 48],
			})}
			key={0}
			position={[markerPosition.lat, markerPosition.lng]}
		/>
	) : null;
};

export default forwardRef(ToaDoMarker);
