import { t } from 'i18next';

export enum NguyenNhanChayEnum {
	CO_Y = 'CO_Y',
	VO_Y = 'VO_Y',
	LOI_KHACH_QUAN = 'LOI_KHACH_QUAN',
	KHUNG_BO_PHA_HOAI  = 'KHUNG_BO_PHA_HOAI'
}

interface NguyenNhanChay {
	value: NguyenNhanChayEnum,
	label: string
}

const _fromEnum = (nguyenNhanChayEnum: NguyenNhanChayEnum): NguyenNhanChay => {
	switch (nguyenNhanChayEnum) {
		case NguyenNhanChayEnum.CO_Y:
			return {
				label: t('enum.nguyenNhanLoi.CO_Y'),
				value: NguyenNhanChayEnum.CO_Y,
			};
		case NguyenNhanChayEnum.VO_Y:
			return {
				label: t('enum.nguyenNhanLoi.VO_Y'),
				value: NguyenNhanChayEnum.VO_Y,
			};
		case NguyenNhanChayEnum.LOI_KHACH_QUAN:
			return {
				label: t('enum.nguyenNhanLoi.LOI_KHACH_QUAN'),
				value: NguyenNhanChayEnum.LOI_KHACH_QUAN,
			};
		case NguyenNhanChayEnum.KHUNG_BO_PHA_HOAI :
			return {
				label: t('enum.nguyenNhanLoi.KHUNG_BO_PHA_HOAI'),
				value: NguyenNhanChayEnum.KHUNG_BO_PHA_HOAI ,
			};
	}
};

const _getAll = (): NguyenNhanChay[] => {
	return Object.values(NguyenNhanChayEnum).map(r => _fromEnum(r as NguyenNhanChayEnum));
};

const nguyenNhanChayEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default nguyenNhanChayEnum;
