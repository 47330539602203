import { CancelToken } from 'axios';
import { SoCuKiemTraModel } from 'models/SoCuKiemTra/SoCuKiemTra.model';
import ApiService, { BUSINESS_BASE_URL, PageOption } from 'services/api/index';

const prefixConstructionCheckingPlan = '/ConstructionCheckingPlan';

const _createConstructionCheckingPlan = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionCheckingPlan}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteConstructionCheckingPlan = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.delete(`${prefixConstructionCheckingPlan}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getConstructionCheckingPlan = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixConstructionCheckingPlan}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchConstructionCheckingPlans = (param: any, pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionCheckingPlan}/SearchData`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateConstructionCheckingPlan = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.put(`${prefixConstructionCheckingPlan}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listConstructionCheckingPlan = (pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixConstructionCheckingPlan}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const getDanhSachSoCuKiemTra = (param = {}, pageOption = {}): Promise<PageOption<SoCuKiemTraModel>> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionCheckingPlan}/SearchData`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));

const getDataDashboard = (param = {}, token?: CancelToken): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionCheckingPlan}/getDataDashBoard`, param, { cancelToken: token })
		.then(({ data }) => Promise.resolve(data));

// lập kế hoạch kiểm tra cơ sở công trình
const ConstructionCheckingPlanService = {
	createConstructionCheckingPlan: _createConstructionCheckingPlan,
	deleteConstructionCheckingPlan: _deleteConstructionCheckingPlan,
	getConstructionCheckingPlan: _getConstructionCheckingPlan,
	searchConstructionCheckingPlan: _searchConstructionCheckingPlans,
	updateConstructionCheckingPlan: _updateConstructionCheckingPlan,
	listConstructionCheckingPlan: _listConstructionCheckingPlan,
	getDanhSachSoCuKiemTra,
	getDataDashboard,
};

export default ConstructionCheckingPlanService;
