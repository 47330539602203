import { t } from 'i18next';

export enum PhuongTienChuaChayEnum {
	XE_THANG = 'XE_THANG',
	XE_NANG = 'XE_NANG',
	XE_CHI_HUY = 'XE_CHI_HUY',
	XE_TRUNG_TAM = 'XE_TRUNG_TAM',
	XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY = 'XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY',
	XE_CHIEU_SANG_CHUA_CHAY = 'XE_CHIEU_SANG_CHUA_CHAY',
	XE_TRAM_BOM = 'XE_TRAM_BOM',
	XE_CHO_NUOC = 'XE_CHO_NUOC',
	XE_CHO_PHUONG_TIEN = 'XE_CHO_PHUONG_TIEN',
	XE_CHO_QUAN = 'XE_CHO_QUAN',
	XE_CHO_HOA_CHAT = 'XE_CHO_HOA_CHAT',
	XE_CUU_NAN = 'XE_CUU_NAN',
	XE_HUT_KHOI = 'XE_HUT_KHOI',
	XE_SUA_CHUA = 'XE_SUA_CHUA',
	XE_HAU_CAN = 'XE_HAU_CAN',
	XE_CAU = 'XE_CAU',
	XE_XU_LY_HOA_CHAT_DOC_HAI = 'XE_XU_LY_HOA_CHAT_DOC_HAI',
	XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY = 'XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY',
	XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY = 'XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY',
	XE_CHO_VOI_CHUA_CHAY = 'XE_CHO_VOI_CHUA_CHAY',
	XE_TIEP_NHIEN_LIEU = 'XE_TIEP_NHIEN_LIEU',
	XE_CUU_THUONG = 'XE_CUU_THUONG',
	MO_TO_CHUA_CHAY = 'MO_TO_CHUA_CHAY',
}

interface PhuongTienChuaChay {
	value: PhuongTienChuaChayEnum;
	label: string;
}

const _fromEnum = (phuongTienChuaChayEnum: PhuongTienChuaChayEnum): PhuongTienChuaChay => {
	switch (phuongTienChuaChayEnum) {
		case PhuongTienChuaChayEnum.XE_THANG:
			return {
				label: t('enum.vehicle.XE_THANG'),
				value: PhuongTienChuaChayEnum.XE_THANG,
			};
		case PhuongTienChuaChayEnum.XE_NANG:
			return {
				label: t('enum.vehicle.XE_NANG'),
				value: PhuongTienChuaChayEnum.XE_NANG,
			};
		case PhuongTienChuaChayEnum.XE_CHI_HUY:
			return {
				label: t('enum.vehicle.XE_CHI_HUY'),
				value: PhuongTienChuaChayEnum.XE_CHI_HUY,
			};
		case PhuongTienChuaChayEnum.XE_TRUNG_TAM:
			return {
				label: t('enum.vehicle.XE_TRUNG_TAM'),
				value: PhuongTienChuaChayEnum.XE_TRUNG_TAM,
			};
		case PhuongTienChuaChayEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY:
			return {
				label: t('enum.vehicle.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY'),
				value: PhuongTienChuaChayEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY,
			};
		case PhuongTienChuaChayEnum.XE_CHIEU_SANG_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHIEU_SANG_CHUA_CHAY'),
				value: PhuongTienChuaChayEnum.XE_CHIEU_SANG_CHUA_CHAY,
			};
		case PhuongTienChuaChayEnum.XE_TRAM_BOM:
			return {
				label: t('enum.vehicle.XE_TRAM_BOM'),
				value: PhuongTienChuaChayEnum.XE_TRAM_BOM,
			};
		case PhuongTienChuaChayEnum.XE_CHO_NUOC:
			return {
				label: t('enum.vehicle.XE_CHO_NUOC'),
				value: PhuongTienChuaChayEnum.XE_CHO_NUOC,
			};
		case PhuongTienChuaChayEnum.XE_CHO_PHUONG_TIEN:
			return {
				label: t('enum.vehicle.XE_CHO_PHUONG_TIEN'),
				value: PhuongTienChuaChayEnum.XE_CHO_PHUONG_TIEN,
			};
		case PhuongTienChuaChayEnum.XE_CHO_QUAN:
			return {
				label: t('enum.vehicle.XE_CHO_QUAN'),
				value: PhuongTienChuaChayEnum.XE_CHO_QUAN,
			};
		case PhuongTienChuaChayEnum.XE_CHO_HOA_CHAT:
			return {
				label: t('enum.vehicle.XE_CHO_HOA_CHAT'),
				value: PhuongTienChuaChayEnum.XE_CHO_HOA_CHAT,
			};

		case PhuongTienChuaChayEnum.XE_CUU_NAN:
			return {
				label: t('enum.vehicle.XE_CUU_NAN'),
				value: PhuongTienChuaChayEnum.XE_CUU_NAN,
			};
		case PhuongTienChuaChayEnum.XE_HUT_KHOI:
			return {
				label: t('enum.vehicle.XE_HUT_KHOI'),
				value: PhuongTienChuaChayEnum.XE_HUT_KHOI,
			};
		case PhuongTienChuaChayEnum.XE_SUA_CHUA:
			return {
				label: t('enum.vehicle.XE_SUA_CHUA'),
				value: PhuongTienChuaChayEnum.XE_SUA_CHUA,
			};
		case PhuongTienChuaChayEnum.XE_HAU_CAN:
			return {
				label: t('enum.vehicle.XE_HAU_CAN'),
				value: PhuongTienChuaChayEnum.XE_HAU_CAN,
			};
		case PhuongTienChuaChayEnum.XE_CAU:
			return {
				label: t('enum.vehicle.XE_CAU'),
				value: PhuongTienChuaChayEnum.XE_CAU,
			};
		case PhuongTienChuaChayEnum.XE_XU_LY_HOA_CHAT_DOC_HAI:
			return {
				label: t('enum.vehicle.XE_XU_LY_HOA_CHAT_DOC_HAI'),
				value: PhuongTienChuaChayEnum.XE_XU_LY_HOA_CHAT_DOC_HAI,
			};
		case PhuongTienChuaChayEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY'),
				value: PhuongTienChuaChayEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY,
			};
		case PhuongTienChuaChayEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY'),
				value: PhuongTienChuaChayEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY,
			};
		case PhuongTienChuaChayEnum.XE_CHO_VOI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHO_VOI_CHUA_CHAY'),
				value: PhuongTienChuaChayEnum.XE_CHO_VOI_CHUA_CHAY,
			};
		case PhuongTienChuaChayEnum.XE_TIEP_NHIEN_LIEU:
			return {
				label: t('enum.vehicle.XE_TIEP_NHIEN_LIEU'),
				value: PhuongTienChuaChayEnum.XE_TIEP_NHIEN_LIEU,
			};
		case PhuongTienChuaChayEnum.XE_CUU_THUONG:
			return {
				label: t('enum.vehicle.XE_CUU_THUONG'),
				value: PhuongTienChuaChayEnum.XE_CUU_THUONG,
			};
		case PhuongTienChuaChayEnum.MO_TO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.MO_TO_CHUA_CHAY'),
				value: PhuongTienChuaChayEnum.MO_TO_CHUA_CHAY,
			};
	}
};

const _getAll = (): PhuongTienChuaChay[] => {
	return Object.values(PhuongTienChuaChayEnum).map(r => _fromEnum(r as PhuongTienChuaChayEnum));
};

const _enumFromLabel = (label: string): PhuongTienChuaChayEnum | undefined => {
	switch (label) {
		case t('enum.vehicle.XE_THANG'):
			return PhuongTienChuaChayEnum.XE_THANG;
		case t('enum.vehicle.XE_NANG'):
			return PhuongTienChuaChayEnum.XE_NANG;
		case t('enum.vehicle.XE_CHI_HUY'):
			return PhuongTienChuaChayEnum.XE_CHI_HUY;
		case t('enum.vehicle.XE_TRUNG_TAM'):
			return PhuongTienChuaChayEnum.XE_TRUNG_TAM;
		case t('enum.vehicle.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY'):
			return PhuongTienChuaChayEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY;
		case t('enum.vehicle.XE_CHIEU_SANG_CHUA_CHAY'):
			return PhuongTienChuaChayEnum.XE_CHIEU_SANG_CHUA_CHAY;
		case t('enum.vehicle.XE_TRAM_BOM'):
			return PhuongTienChuaChayEnum.XE_TRAM_BOM;
		case t('enum.vehicle.XE_CHO_NUOC'):
			return PhuongTienChuaChayEnum.XE_CHO_NUOC;
		case t('enum.vehicle.XE_CHO_PHUONG_TIEN'):
			return PhuongTienChuaChayEnum.XE_CHO_PHUONG_TIEN;
		case t('enum.vehicle.XE_CHO_QUAN'):
			return PhuongTienChuaChayEnum.XE_CHO_QUAN;
		case t('enum.vehicle.XE_CHO_HOA_CHAT'):
			return PhuongTienChuaChayEnum.XE_CHO_HOA_CHAT;
		case t('enum.vehicle.XE_CUU_NAN'):
			return PhuongTienChuaChayEnum.XE_CUU_NAN;
		case t('enum.vehicle.XE_HUT_KHOI'):
			return PhuongTienChuaChayEnum.XE_HUT_KHOI;
		case t('enum.vehicle.XE_SUA_CHUA'):
			return PhuongTienChuaChayEnum.XE_CAU;
		case t('enum.vehicle.XE_CAU'):
			return PhuongTienChuaChayEnum.XE_XU_LY_HOA_CHAT_DOC_HAI;
		case t('enum.vehicle.XE_XU_LY_HOA_CHAT_DOC_HAI'):
			return PhuongTienChuaChayEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY;
		case t('enum.vehicle.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY'):
			return PhuongTienChuaChayEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY;
		case t('enum.vehicle.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY'):
			return PhuongTienChuaChayEnum.XE_CHO_VOI_CHUA_CHAY;
		case t('enum.vehicle.XE_CHO_VOI_CHUA_CHAY'):
			return PhuongTienChuaChayEnum.XE_TIEP_NHIEN_LIEU;
		case t('enum.vehicle.XE_TIEP_NHIEN_LIEU'):
			return PhuongTienChuaChayEnum.XE_CUU_THUONG;
		case t('enum.vehicle.XE_CUU_THUONG'):
			return PhuongTienChuaChayEnum.XE_SUA_CHUA;
		case t('enum.vehicle.MO_TO_CHUA_CHAY'):
			return PhuongTienChuaChayEnum.MO_TO_CHUA_CHAY;
		case t('enum.vehicle.XE_HAU_CAN'):
			return PhuongTienChuaChayEnum.XE_HAU_CAN;
	}
};

const phuongTienChuaChayEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default phuongTienChuaChayEnum;
