import { t } from 'i18next';

export enum LoaiHinHoatDongEnum {
	SAN_XUAT = 'SAN_XUAT',
	KINH_DOANH_DICH_VU = 'KINH_DOANH_DICH_VU',
	HANH_CHINH = 'HANH_CHINH',
}

interface LoaiHinhHoatDong {
	value: LoaiHinHoatDongEnum;
	label: string;
}

const _fromEnum = (loaiHinHoatDongEnum: LoaiHinHoatDongEnum): LoaiHinhHoatDong => {
	switch (loaiHinHoatDongEnum) {
		case LoaiHinHoatDongEnum.SAN_XUAT:
			return {
				label: t('enum.loaiHinhHoatDong.SAN_XUAT'),
				value: LoaiHinHoatDongEnum.SAN_XUAT,
			};
		case LoaiHinHoatDongEnum.KINH_DOANH_DICH_VU:
			return {
				label: t('enum.loaiHinhHoatDong.KINH_DOANH_DICH_VU'),
				value: LoaiHinHoatDongEnum.KINH_DOANH_DICH_VU,
			};
		case LoaiHinHoatDongEnum.HANH_CHINH:
			return {
				label: t('enum.loaiHinhHoatDong.HANH_CHINH'),
				value: LoaiHinHoatDongEnum.HANH_CHINH,
			};
	}
};

const _getAll = (): LoaiHinhHoatDong[] => {
	return Object.values(LoaiHinHoatDongEnum).map((r) => _fromEnum(r as LoaiHinHoatDongEnum));
};

const _enumFromLabel = (label: string): LoaiHinHoatDongEnum | undefined => {
	switch (label) {
		case t('enum.loaiHinhHoatDong.HANH_CHINH'):
			return LoaiHinHoatDongEnum.HANH_CHINH;
		case t('enum.loaiHinhHoatDong.KINH_DOANH_DICH_VU'):
			return LoaiHinHoatDongEnum.KINH_DOANH_DICH_VU;
		case t('enum.loaiHinhHoatDong.SAN_XUAT'):
			return LoaiHinHoatDongEnum.SAN_XUAT;
	}
};

const loaiHinhHoatDongEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default loaiHinhHoatDongEnum;
