import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Select } from 'antd';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import BienPhapXuLyEnum, { BienPhapXuLy } from 'enum/bienPhapXuLyEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import {
	default as ViolationService,
	default as violationService,
} from 'services/api/businessService/violationService';
import Utils from 'services/api/utils';
import nhomHanhViEnum, { NhomHanhViEnum } from '../../../../enum/nhomHanhViEnum';
import { PermissionProps } from '../QuanLyCongTacPhongChay';
import Generator from 'services/generator';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const XuLyViPham: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const getDataTimKiemXuLyViPham = useRef<any>({});
	const navigate = useNavigate();

	// state of component
	const [TongSoViPham, setTongSoViPham] = useState<number>(0);
	const [dataSourceXuLyViPhamPCCC, setdataSourceXuLyViPhamPCCC] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);

	// get list of provinces
	const tinhThanhscustomer = Utils.GetTinhThanhUser();
	const tinhThanhsAdmin = Utils.GetTinhThanhAdmin();

	const columnsDSXuLyViPhamPCCC = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Ngày xử lý',
			dataIndex: 'Date',
			key: 'Date',
		},
		{
			title: 'Cơ sở vi phạm',
			dataIndex: 'ConstructionName',
			key: 'ConstructionName',
			render: (ConstructionName: string, record: any) => {
				return (
					<NavigateButton url={`/chiTietCoSoCongTrinh?id=${record.ConstructionId}&mode=view`} name={ConstructionName} />
				);
			},
		},
		{
			title: 'Đối tượng vi phạm',
			dataIndex: 'ViolatorName',
			key: 'ViolatorName',
		},
		{
			title: 'Nhóm hành vi',
			dataIndex: 'Behaviour',
			key: 'Behaviour',
			render: (behaviours: string[]) => {
				let newBehaviors: { label: string; value: string }[] = [];
				if (behaviours && behaviours.length > 0) {
					newBehaviors = behaviours.map(item => nhomHanhViEnum.fromEnum(item as NhomHanhViEnum));
				}

				return (
					<ul>
						{newBehaviors.length > 0 &&
							newBehaviors.map(suspension => (
								<li className="mb5" key={suspension.value}>
									{suspension.label}
								</li>
							))}
					</ul>
				);
			},
		},
		{
			title: 'Biện pháp xử lý',
			dataIndex: 'SuspensionType',
			key: 'SuspensionType',
			render: (suspensionTypes: string[]) => {
				let suspensions: { label: string; value: string }[] = [];
				if (suspensionTypes && suspensionTypes.length > 0) {
					suspensions = suspensionTypes.map(item => BienPhapXuLyEnum.fromEnum(item as BienPhapXuLy));
				}

				return (
					<ul>
						{suspensions.length > 0 &&
							suspensions.map(suspension => (
								<li className="mb5" key={suspension.value}>
									{suspension.label}
								</li>
							))}
					</ul>
				);
			},
		},
		{
			align: 'center' as const,
			width: 100,
			title: 'Thao tác',
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.VIOLATION_EDIT) ||
				props.permissionList.includes(PermissionEnum.VIOLATION_DELETE)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.VIOLATION_EDIT)
									? ''
									: 'none',
						}}
						type="text"
						onClick={e => e.stopPropagation()}>
						<Link
							to={{
								pathname: '/chiTietXuLyViPham',
								search: `?Id=${record.Id}&mode=edit`,
							}}>
							<img src="icon/Pen.png" alt="edit" />
						</Link>
					</Button>
					<Button
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.VIOLATION_DELETE)
									? ''
									: 'none',
						}}
						type="text"
						onClick={async e => {
							e.stopPropagation();
							await CustomDialog(
								<DeleteDialog
									id={record.Id}
									name={record.ConstructionName}
									callback={getDataTimKiemXuLyViPham.current}
									deleteService={ViolationService.deleteViolation}
								/>,
							);
						}}>
						<img src="icon/Delete.png" alt="delete" />
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	// Method get danh sách Xử lý vi phạm
	getDataTimKiemXuLyViPham.current = (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};

		const param: any = {
			ConstructionName: form.getFieldValue('ConstructionName'),
			SuspensionType: form.getFieldValue('SuspensionType'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Location = {
				TinhThanh: form.getFieldValue('TinhThanh'),
			};
		} else {
			param.Location = undefined;
		}

		violationService
			.searchViolations(param, pageOption)
			.then((res: any) => {
				setTongSoViPham(res.TotalItems);
				optiondanhsachXuLyViPhamPCCC(res ? res.Data : []);
			})
			.catch();
	};

	const optiondanhsachXuLyViPhamPCCC = (data: any[]) => {
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any, index: number) => {
				item.Date = moment(item.Date).format('DD/MM/YYYY');
				array.push(item);
			});
		}
		setdataSourceXuLyViPhamPCCC(data);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.setFieldsValue({ ConstructionName: null });
		}

		getDataTimKiemXuLyViPham.current(CURRENT_PAGE);
	};

	const onChangePage = (page: number, pageSize: number) => {
		getDataTimKiemXuLyViPham.current(page, pageSize);
	};

	const handleRefreshPage = () => {
		form.resetFields();
		getDataTimKiemXuLyViPham.current(CURRENT_PAGE);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietXuLyViPham',
					search: `?Id=${record.Id}&mode=view`,
				});
			},
		};
	};

	useEffect(() => {
		document.title = t('quanLyCongTacPhongChay.xuLyViPham');
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.VIOLATION_VIEW])) {
				getDataTimKiemXuLyViPham.current();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
	}, [props.permissionList]);

	const formAction = (
		<>
			<div className="flex--row">
				<Form.Item name="TinhThanh">
					<Select
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.tinhThanh')}
						onChange={() => getDataTimKiemXuLyViPham.current(CURRENT_PAGE)}
						allowClear
						className="withMinSelect">
						{props.permissionList.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
					</Select>
				</Form.Item>

				<Form.Item className="ml10" name="dateRange">
					<RangePickerInput onChangeDatePicker={() => getDataTimKiemXuLyViPham.current(CURRENT_PAGE)} form={form} />
				</Form.Item>

				<Form.Item name="SuspensionType" className="ml10">
					<Select
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.bienPhapXuLy')}
						onChange={() => getDataTimKiemXuLyViPham.current(CURRENT_PAGE)}
						allowClear
						className="withMinSelect">
						{Generator.generateOptions(BienPhapXuLyEnum.getAll())}
					</Select>
				</Form.Item>

				<Form.Item name="ConstructionName" className="ml10">
					<Input.Search
						placeholder="Nhập tên cơ sở vi phạm"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</Form.Item>
			</div>

			<div>
				<Button type="default" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				{props.permissionList.includes(PermissionEnum.VIOLATION_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button type="primary" className="ml10">
						<Link
							to={{
								pathname: '/chiTietXuLyViPham',
								search: '?mode=add',
							}}>
							<PlusOutlined />
							Thêm mới
						</Link>
					</Button>
				) : null}
			</div>
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cơ sở vi phạm
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoViPham}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const formTable = (
		<TableComponent
			dataSource={dataSourceXuLyViPhamPCCC}
			columns={columnsDSXuLyViPhamPCCC}
			onRow={handleShowViewDetail}
			paginator={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
		/>
	);

	return <PageWrapper titlePage={t('xuLyViPham.title')} formAction={formAction} formTable={formTable} form={form} />;
};

export default XuLyViPham;
