import { t } from 'i18next';

export enum VehicleEnum {
	XE_CHUA_CHAY_CO_XITEC = 'XE_CHUA_CHAY_CO_XITEC',
	XE_CHUA_CHAY_KHONG_CO_XITEC = 'XE_CHUA_CHAY_KHONG_CO_XITEC',
	XE_CHUA_CHAY_SAN_BAY = 'XE_CHUA_CHAY_SAN_BAY',
	XE_CHUA_CHAY_RUNG = 'XE_CHUA_CHAY_RUNG',

	XE_CHUA_CHAY_HOA_CHAT = 'XE_CHUA_CHAY_HOA_CHAT',
	XE_CHUA_CHAY_DUONG_HAM = 'XE_CHUA_CHAY_DUONG_HAM',
	XE_CHUA_CHAY_DUONG_SAT = 'XE_CHUA_CHAY_DUONG_SAT',
	XE_CHUA_CHAY_LUONG_CU = 'XE_CHUA_CHAY_LUONG_CU',

	XE_THANG = 'XE_THANG',
	XE_NANG = 'XE_NANG',
	XE_CHI_HUY = 'XE_CHI_HUY',
	XE_TRUNG_TAM = 'XE_TRUNG_TAM',
	XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY = 'XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY',
	XE_CHIEU_SANG_CHUA_CHAY = 'XE_CHIEU_SANG_CHUA_CHAY',
	XE_TRAM_BOM = 'XE_TRAM_BOM',
	XE_CHO_NUOC = 'XE_CHO_NUOC',
	XE_CHO_PHUONG_TIEN = 'XE_CHO_PHUONG_TIEN',
	XE_CHO_QUAN = 'XE_CHO_QUAN',
	XE_CHO_HOA_CHAT = 'XE_CHO_HOA_CHAT',
	XE_CUU_NAN = 'XE_CUU_NAN',
	XE_HUT_KHOI = 'XE_HUT_KHOI',
	XE_SUA_CHUA = 'XE_SUA_CHUA',
	XE_HAU_CAN = 'XE_HAU_CAN',
	XE_CAU = 'XE_CAU',
	XE_XU_LY_HOA_CHAT_DOC_HAI = 'XE_XU_LY_HOA_CHAT_DOC_HAI',
	XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY = 'XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY',
	XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY = 'XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY',
	XE_CHO_VOI_CHUA_CHAY = 'XE_CHO_VOI_CHUA_CHAY',
	XE_TIEP_NHIEN_LIEU = 'XE_TIEP_NHIEN_LIEU',
	XE_CUU_THUONG = 'XE_CUU_THUONG',
	MO_TO_CHUA_CHAY = 'MO_TO_CHUA_CHAY',

	TAU_CHUA_CHAY = 'TAU_CHUA_CHAY',
	XUONG_CHUA_CHAY = 'XUONG_CHUA_CHAY',
	CA_NO_CHUA_CHAY = 'CA_NO_CHUA_CHAY',
	CAU_TRUC_NOI_CHUA_CHAY = 'CAU_TRUC_NOI_CHUA_CHAY',
}

interface Vehicle {
	value: VehicleEnum;
	label: string;
}

const _fromEnum = (vehicleEnum: VehicleEnum): Vehicle => {
	switch (vehicleEnum) {
		case VehicleEnum.XE_CHUA_CHAY_CO_XITEC:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_CO_XITEC'),
				value: VehicleEnum.XE_CHUA_CHAY_CO_XITEC,
			};
		case VehicleEnum.XE_CHUA_CHAY_KHONG_CO_XITEC:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_KHONG_CO_XITEC'),
				value: VehicleEnum.XE_CHUA_CHAY_KHONG_CO_XITEC,
			};
		case VehicleEnum.XE_CHUA_CHAY_SAN_BAY:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_SAN_BAY'),
				value: VehicleEnum.XE_CHUA_CHAY_SAN_BAY,
			};
		case VehicleEnum.XE_CHUA_CHAY_RUNG:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_RUNG'),
				value: VehicleEnum.XE_CHUA_CHAY_RUNG,
			};
		case VehicleEnum.XE_CHUA_CHAY_HOA_CHAT:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_HOA_CHAT'),
				value: VehicleEnum.XE_CHUA_CHAY_HOA_CHAT,
			};
		case VehicleEnum.XE_CHUA_CHAY_DUONG_HAM:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_DUONG_HAM'),
				value: VehicleEnum.XE_CHUA_CHAY_DUONG_HAM,
			};
		case VehicleEnum.XE_CHUA_CHAY_DUONG_SAT:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_DUONG_SAT'),
				value: VehicleEnum.XE_CHUA_CHAY_DUONG_SAT,
			};
		case VehicleEnum.XE_CHUA_CHAY_LUONG_CU:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_LUONG_CU'),
				value: VehicleEnum.XE_CHUA_CHAY_LUONG_CU,
			};
		case VehicleEnum.XE_THANG:
			return {
				label: t('enum.vehicle.XE_THANG'),
				value: VehicleEnum.XE_THANG,
			};
		case VehicleEnum.XE_NANG:
			return {
				label: t('enum.vehicle.XE_NANG'),
				value: VehicleEnum.XE_NANG,
			};
		case VehicleEnum.XE_CHI_HUY:
			return {
				label: t('enum.vehicle.XE_CHI_HUY'),
				value: VehicleEnum.XE_CHI_HUY,
			};
		case VehicleEnum.XE_TRUNG_TAM:
			return {
				label: t('enum.vehicle.XE_TRUNG_TAM'),
				value: VehicleEnum.XE_TRUNG_TAM,
			};
		case VehicleEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY:
			return {
				label: t('enum.vehicle.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY'),
				value: VehicleEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY,
			};
		case VehicleEnum.XE_CHIEU_SANG_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHIEU_SANG_CHUA_CHAY'),
				value: VehicleEnum.XE_CHIEU_SANG_CHUA_CHAY,
			};
		case VehicleEnum.XE_TRAM_BOM:
			return {
				label: t('enum.vehicle.XE_TRAM_BOM'),
				value: VehicleEnum.XE_TRAM_BOM,
			};
		case VehicleEnum.XE_CHO_NUOC:
			return {
				label: t('enum.vehicle.XE_CHO_NUOC'),
				value: VehicleEnum.XE_CHO_NUOC,
			};
		case VehicleEnum.XE_CHO_PHUONG_TIEN:
			return {
				label: t('enum.vehicle.XE_CHO_PHUONG_TIEN'),
				value: VehicleEnum.XE_CHO_PHUONG_TIEN,
			};
		case VehicleEnum.XE_CHO_QUAN:
			return {
				label: t('enum.vehicle.XE_CHO_QUAN'),
				value: VehicleEnum.XE_CHO_QUAN,
			};
		case VehicleEnum.XE_CHO_HOA_CHAT:
			return {
				label: t('enum.vehicle.XE_CHO_HOA_CHAT'),
				value: VehicleEnum.XE_CHO_HOA_CHAT,
			};

		case VehicleEnum.XE_CUU_NAN:
			return {
				label: t('enum.vehicle.XE_CUU_NAN'),
				value: VehicleEnum.XE_CUU_NAN,
			};
		case VehicleEnum.XE_HUT_KHOI:
			return {
				label: t('enum.vehicle.XE_HUT_KHOI'),
				value: VehicleEnum.XE_HUT_KHOI,
			};
		case VehicleEnum.XE_SUA_CHUA:
			return {
				label: t('enum.vehicle.XE_SUA_CHUA'),
				value: VehicleEnum.XE_SUA_CHUA,
			};
		case VehicleEnum.XE_HAU_CAN:
			return {
				label: t('enum.vehicle.XE_HAU_CAN'),
				value: VehicleEnum.XE_HAU_CAN,
			};
		case VehicleEnum.XE_CAU:
			return {
				label: t('enum.vehicle.XE_CAU'),
				value: VehicleEnum.XE_CAU,
			};
		case VehicleEnum.XE_XU_LY_HOA_CHAT_DOC_HAI:
			return {
				label: t('enum.vehicle.XE_XU_LY_HOA_CHAT_DOC_HAI'),
				value: VehicleEnum.XE_XU_LY_HOA_CHAT_DOC_HAI,
			};
		case VehicleEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY'),
				value: VehicleEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY,
			};
		case VehicleEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY'),
				value: VehicleEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY,
			};
		case VehicleEnum.XE_CHO_VOI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XE_CHO_VOI_CHUA_CHAY'),
				value: VehicleEnum.XE_CHO_VOI_CHUA_CHAY,
			};
		case VehicleEnum.XE_TIEP_NHIEN_LIEU:
			return {
				label: t('enum.vehicle.XE_TIEP_NHIEN_LIEU'),
				value: VehicleEnum.XE_TIEP_NHIEN_LIEU,
			};
		case VehicleEnum.XE_CUU_THUONG:
			return {
				label: t('enum.vehicle.XE_CUU_THUONG'),
				value: VehicleEnum.XE_CUU_THUONG,
			};
		case VehicleEnum.MO_TO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.MO_TO_CHUA_CHAY'),
				value: VehicleEnum.MO_TO_CHUA_CHAY,
			};
		case VehicleEnum.TAU_CHUA_CHAY:
			return {
				label: t('enum.vehicle.TAU_CHUA_CHAY'),
				value: VehicleEnum.TAU_CHUA_CHAY,
			};
		case VehicleEnum.XUONG_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XUONG_CHUA_CHAY'),
				value: VehicleEnum.XUONG_CHUA_CHAY,
			};
		case VehicleEnum.CA_NO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.CA_NO_CHUA_CHAY'),
				value: VehicleEnum.CA_NO_CHUA_CHAY,
			};
		case VehicleEnum.CAU_TRUC_NOI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.CAU_TRUC_NOI_CHUA_CHAY'),
				value: VehicleEnum.CAU_TRUC_NOI_CHUA_CHAY,
			};
	}
};

const _getAll = (): Vehicle[] => {
	return Object.values(VehicleEnum).map(r => _fromEnum(r as VehicleEnum));
};

const _enumFromLabel = (label: string): VehicleEnum | undefined => {
	switch (label) {
		case t('enum.vehicle.XE_CHUA_CHAY_CO_XITEC'):
			return VehicleEnum.XE_CHUA_CHAY_CO_XITEC;
		case t('enum.vehicle.XE_CHUA_CHAY_KHONG_CO_XITEC'):
			return VehicleEnum.XE_CHUA_CHAY_KHONG_CO_XITEC;
		case t('enum.vehicle.XE_CHUA_CHAY_SAN_BAY'):
			return VehicleEnum.XE_CHUA_CHAY_SAN_BAY;
		case t('enum.vehicle.XE_CHUA_CHAY_RUNG'):
			return VehicleEnum.XE_CHUA_CHAY_RUNG;
		case t('enum.vehicle.XE_CHUA_CHAY_HOA_CHAT'):
			return VehicleEnum.XE_CHUA_CHAY_HOA_CHAT;
		case t('enum.vehicle.XE_CHUA_CHAY_DUONG_HAM'):
			return VehicleEnum.XE_CHUA_CHAY_DUONG_HAM;
		case t('enum.vehicle.XE_CHUA_CHAY_DUONG_SAT'):
			return VehicleEnum.XE_CHUA_CHAY_DUONG_SAT;
		case t('enum.vehicle.XE_CHUA_CHAY_LUONG_CU'):
			return VehicleEnum.XE_CHUA_CHAY_LUONG_CU;
		case t('enum.vehicle.XE_THANG'):
			return VehicleEnum.XE_THANG;
		case t('enum.vehicle.XE_NANG'):
			return VehicleEnum.XE_NANG;
		case t('enum.vehicle.XE_CHI_HUY'):
			return VehicleEnum.XE_CHI_HUY;
		case t('enum.vehicle.XE_TRUNG_TAM'):
			return VehicleEnum.XE_TRUNG_TAM;
		case t('enum.vehicle.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY'):
			return VehicleEnum.XE_KHAM_NGHIEM_HIEN_TRUONG_CHAY;
		case t('enum.vehicle.XE_CHIEU_SANG_CHUA_CHAY'):
			return VehicleEnum.XE_CHIEU_SANG_CHUA_CHAY;
		case t('enum.vehicle.XE_TRAM_BOM'):
			return VehicleEnum.XE_TRAM_BOM;
		case t('enum.vehicle.XE_CHO_NUOC'):
			return VehicleEnum.XE_CHO_NUOC;
		case t('enum.vehicle.XE_CHO_PHUONG_TIEN'):
			return VehicleEnum.XE_CHO_PHUONG_TIEN;
		case t('enum.vehicle.XE_CHO_QUAN'):
			return VehicleEnum.XE_CHO_QUAN;
		case t('enum.vehicle.XE_CHO_HOA_CHAT'):
			return VehicleEnum.XE_CHO_HOA_CHAT;
		case t('enum.vehicle.XE_CUU_NAN'):
			return VehicleEnum.XE_CUU_NAN;
		case t('enum.vehicle.XE_HUT_KHOI'):
			return VehicleEnum.XE_HUT_KHOI;
		case t('enum.vehicle.XE_SUA_CHUA'):
			return VehicleEnum.XE_CAU;
		case t('enum.vehicle.XE_CAU'):
			return VehicleEnum.XE_XU_LY_HOA_CHAT_DOC_HAI;
		case t('enum.vehicle.XE_XU_LY_HOA_CHAT_DOC_HAI'):
			return VehicleEnum.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY;
		case t('enum.vehicle.XE_CUNG_CAP_CHAT_KHI_CHUA_CHAY'):
			return VehicleEnum.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY;
		case t('enum.vehicle.XE_CHO_VA_NAP_BINH_KHI_THO_CHUA_CHAY'):
			return VehicleEnum.XE_CHO_VOI_CHUA_CHAY;
		case t('enum.vehicle.XE_CHO_VOI_CHUA_CHAY'):
			return VehicleEnum.XE_TIEP_NHIEN_LIEU;
		case t('enum.vehicle.XE_TIEP_NHIEN_LIEU'):
			return VehicleEnum.XE_CUU_THUONG;
		case t('enum.vehicle.XE_CUU_THUONG'):
			return VehicleEnum.XE_SUA_CHUA;
		case t('enum.vehicle.MO_TO_CHUA_CHAY'):
			return VehicleEnum.MO_TO_CHUA_CHAY;
		case t('enum.vehicle.XE_HAU_CAN'):
			return VehicleEnum.XE_HAU_CAN;
		case t('enum.vehicle.TAU_CHUA_CHAY'):
			return VehicleEnum.TAU_CHUA_CHAY;
		case t('enum.vehicle.XUONG_CHUA_CHAY'):
			return VehicleEnum.XUONG_CHUA_CHAY;
		case t('enum.vehicle.CA_NO_CHUA_CHAY'):
			return VehicleEnum.CA_NO_CHUA_CHAY;
		case t('enum.vehicle.CAU_TRUC_NOI_CHUA_CHAY'):
			return VehicleEnum.CAU_TRUC_NOI_CHUA_CHAY;
	}
};

const vehicleEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default vehicleEnum;
