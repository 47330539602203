import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Tooltip } from 'antd';
import DialogThemMoiCbChienSy from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiCanBoChienSy';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import { QuanLyDonViPhongChayTabProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import { QuanLyDonViPhongChayTab } from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import ExportExcelComponent from 'components/shared/ExportExcelComponent/ExportExcelComponent';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import capBacEnum, { CapBacEnum } from 'enum/capBacEnum';
import chucVuEnum, { ChucVuEnum } from 'enum/chucVuEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import { CanBoKiemTraModel } from 'models/CanBoKiemTra/CanBoKiemTra.model';
import moment, { Moment } from 'moment';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import { default as FireFighterService } from 'services/api/inventoryService/fireFighterService';
import { systemProperties } from 'systemProperties';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DanhSachCanBoChienSy: FunctionComponent<QuanLyDonViPhongChayTabProps> = props => {
	const columnsDsCanBoChienSy = [
		{
			align: 'center' as const,
			width: 45,
			title: 'STT',
			dataIndex: 'key',
			key: 'id',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Họ và tên',
			dataIndex: 'HoTen',
			key: 'HoTen',
			ellipsis: {
				showTitle: false,
			},
			render: (HoTen: string) => (
				<Tooltip placement="topLeft" title={HoTen}>
					{HoTen}
				</Tooltip>
			),
			sorter: (a: any, b: any) => a.HoTen.localeCompare(b.HoTen),
		},
		{
			title: 'Ngày sinh',
			dataIndex: 'NgaySinh',
			key: 'NgaySinh',
			ellipsis: {
				showTitle: false,
			},
			render: (NgaySinh: Moment) => (NgaySinh ? moment(NgaySinh).format(systemProperties.dateFormat) : ''),
		},
		{
			title: 'Số điện thoại',
			dataIndex: 'SoDienThoai',
			key: 'SoDienThoai',
			ellipsis: {
				showTitle: false,
			},
			render: (address: string) => (
				<Tooltip placement="topLeft" title={address}>
					{address}
				</Tooltip>
			),
		},
		{
			title: 'Chức vụ',
			dataIndex: 'ChucVu',
			key: 'ChucVu',
			ellipsis: {
				showTitle: false,
			},
			render: (chucVu: ChucVuEnum) => {
				const label = chucVuEnum.fromEnum(chucVu)?.label;
				return (
					<Tooltip placement="topLeft" title={label}>
						{label}
					</Tooltip>
				);
			},
		},
		{
			title: 'Cấp bậc',
			dataIndex: 'CapBac',
			key: 'CapBac',
			ellipsis: {
				showTitle: false,
			},
			render: (capBac: CapBacEnum) => {
				const level = capBacEnum.fromEnum(capBac)?.label;
				return (
					<Tooltip placement="topLeft" title={level}>
						{level}
					</Tooltip>
				);
			},
		},
		{
			title: 'Trực thuộc đơn vị',
			dataIndex: 'PcccUnitName',
			key: 'PcccUnitName',
			ellipsis: {
				showTitle: false,
			},
			render: (TenDonVi: string) => (
				<Tooltip placement="topLeft" title={TenDonVi}>
					<span>{TenDonVi}</span>
				</Tooltip>
			),
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			key: 'action',
			align: 'center' as const,
			width: 100,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_EDIT) ||
				props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle={`Chỉnh sửa thông tin cán bộ "${record.HoTen}"`}
					EditDialog={
						<DialogThemMoiCbChienSy
							userInfo={props.userInfo}
							id={record.Id}
							mode={'edit'}
							callback={getDataTimKiemDSCBCSByLocation}
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.HoTen}
							callback={getDataTimKiemDSCBCSByLocation}
							deleteService={FireFighterService.deleteFireFighter}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [form] = Form.useForm();

	const [dataSourceDSCanBoChienSy, setDataSourceDSCanBoChienSy] = useState<CanBoKiemTraModel.CanBoKiemTra[]>([]);
	const [TongSoCanBoChienSy, setTongSoCanBoChienSy] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);

	useEffect(() => {
		if (props.searchData && props.mode !== 'detail') {
			getDataTimKiemDSCBCSByLocation(CURRENT_PAGE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchData]);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !props.isNavigate) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FIRE_FIGHTER_VIEW])) {
				getDataTimKiemDSCBCSByLocation();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, props.isNavigate]);

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			getDataTimKiemDSCBCSByLocation(CURRENT_PAGE);
		}
	};

	const onClickRefresh = () => {
		form.resetFields();
		props.onResetFormAction && props.onResetFormAction();
	};

	const getRequestPayload = useCallback(() => {
		const fighterName = form.getFieldValue('tencanbo');
		const param = {
			...props.searchData,
			...(fighterName && { Name: fighterName }),
		};

		return param;
	}, [props.searchData, form]);

	const getDataTimKiemDSCBCSByLocation = (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const param = getRequestPayload();
		FireFighterService.searchFireFighter(param, pageOption)
			.then((res: any) => {
				setTongSoCanBoChienSy(res.TotalItems);
				setDataSourceDSCanBoChienSy(res.Data);
			})
			.catch();
	};

	function handleChangePage(page: number, pageSize: number) {
		getDataTimKiemDSCBCSByLocation(page, pageSize);
	}

	const handleExportFileExcel = useCallback(() => {
		const exportFilePayload = getRequestPayload();
		FireFighterService.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Can_Bo_Chien_Sy.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	}, [getRequestPayload]);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cán bộ và chiến sỹ
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoCanBoChienSy}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(
					<DialogThemMoiCbChienSy
						userInfo={props.userInfo}
						id={record.Id}
						mode="view"
						callback={getDataTimKiemDSCBCSByLocation}
					/>,
					{
						className: 'dialog-add',
						title: `${record.HoTen}`,
						showCloseIcon: true,
						isCanClose: true,
					},
				);
			},
		};
	};

	return (
		<Form layout="vertical" className="p0" form={form} disabled={props.isDisable}>
			<div id={QuanLyDonViPhongChayTab.DANH_SACH_CAN_BO_CHIEN_SY} className={'tab__content'}>
				<div className="flex--row justify-content--between mb15">
					<div className="search-input">
						<Form.Item name="tencanbo">
							<Input placeholder="Họ và tên" prefix={<SearchOutlined />} onKeyPress={handleKeyDown} allowClear />
						</Form.Item>
					</div>
					<div>
						{/* <Button
							className="ml10"
							type={'default'}
							onClick={() => {
								onClickRefresh();
							}}>
							<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
						</Button> */}

						<Link to={'/excelTemplate/Cán bộ chiến sỹ.xlsx'} target={'_blank'} download>
							<Button type={'default'} className="ml10">
								<img className="padding-image" style={{ width: 12 }} src="icon/downExcel.png" alt="Tải file" />{' '}
								{t('label.taiFileMau')}
							</Button>
						</Link>

						{props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_EXPORT) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<ExportExcelComponent
								className="ml10"
								dataSources={dataSourceDSCanBoChienSy}
								onExportFileExcel={handleExportFileExcel}
							/>
						) : null}

						{props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_CREATE) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<Button
								className="buttonAdd ant-btn ant-btn-primary ml10"
								onClick={async () => {
									await CustomDialog(
										<DialogThemMoiCbChienSy
											userInfo={props.userInfo}
											mode="add"
											location={props.searchData?.Location}
											donviId={props.donviId}
											callback={getDataTimKiemDSCBCSByLocation}
										/>,
										{
											className: 'dialog-add',
											title: 'Thêm mới cán bộ chiến sĩ',
											isCanClose: true,
											showCloseIcon: true,
										},
									);
								}}>
								<PlusOutlined />
								Thêm mới
							</Button>
						) : null}
					</div>
				</div>

				<TableComponent
					dataSource={dataSourceDSCanBoChienSy}
					columns={columnsDsCanBoChienSy}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					paginator={false}
					onRow={handleShowViewDetail}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(DanhSachCanBoChienSy);
