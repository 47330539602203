import { Form, Select, Tabs } from 'antd';
import { PermissionProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import DanhSachCanBoChienSy from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachCanBoChienSy';
import DanhSachDiemLayNuoc from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachDiemLayNuoc';
import DanhSachDonViHoTro from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachDonViHoTro';
import DanhSachPhuongTien from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachPhuongTien';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { SearchFireFighterParams } from 'services/api/inventoryService/fireFighterService';
import pcccUnitService, { PcccUnit } from 'services/api/inventoryService/pcccUnitService';

export enum QuanLyDonViPhongChayTab {
	DANH_SACH_CAN_BO_CHIEN_SY = 'DanhSachCanBoChienSy',
	PHUONG_TIEN_PCCC = 'PhuongTienPCCC',
	DON_VI_HO_TRO = 'DonViHoTro',
	DIEM_LAY_NUOC = 'DiemLayNuoc',
	DANH_SACH_DON_VI_PCCC = 'DanhSachDonViPCCC',
}

export interface LocationInfo {
	TinhThanh?: string;
	QuanHuyen?: string;
	XaPhuong?: string;
}

const QuanLyDonVi = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNavigate = navigateFromDashboardSelector.getNavigateStatus();

	// states of component
	const [apiSearchData, setApiSearchData] = useState<SearchFireFighterParams>();
	const [units, setUnits] = useState<PcccUnit[]>([]);
	const [isDistrictsSelect, setIsDistrictsSelect] = useState(false);
	const [title, setTitle] = useState('');
	const [unitInfo, setUnitInfo] = useState<PcccUnit>({} as PcccUnit);
	const [provinceTitle, setProvinceTitle] = useState('');
	const [districtTitle, setDistrictTitle] = useState('');
	const [disabledTrucThuocSelect, setDisabledTrucThuocSelect] = useState(true);
	const [disabledUnitSelect, setDisabledUnitSelect] = useState(true);
	const [currentTab, setCurrentTab] = useState(
		localStorage.getItem('quanLyDonViTab') as string | QuanLyDonViPhongChayTab.DANH_SACH_CAN_BO_CHIEN_SY,
	);
	const [provinceId, setProvinceId] = useState('');

	const getUnits = async (trucThuocType: LoaiHinhDonViEnum) => {
		const provinceId = form.getFieldValue('TinhThanh');
		const districtId = form.getFieldValue('QuanHuyen');
		const param = {
			Location: {
				TinhThanh: provinceId,
				QuanHuyen: districtId,
			},
			UnderType: trucThuocType,
		};
		const unitsFecthed = await pcccUnitService.searchPcccUnit(param);
		return unitsFecthed;
	};

	const handleChangeProvince = async (provinceId: string, option: any) => {
		form.resetFields(['QuanHuyen', 'unit', 'trucThuoc']);

		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = provinceId;
		setApiSearchData(searchParams);

		const title = option && option.children;
		if (title) {
			setTitle(title);
			setProvinceTitle(title);
		} else {
			setTitle('');
			setProvinceTitle('');
			setDistrictTitle('');
		}

		setUnitInfo({} as PcccUnit);
		setUnits([]);

		setProvinceId(provinceId);
		if (provinceId) {
			setDisabledTrucThuocSelect(false);
		} else {
			setIsDistrictsSelect(false);
			setDisabledTrucThuocSelect(true);
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeTrucThuoc = async (trucThuocType: LoaiHinhDonViEnum) => {
		form.resetFields(['QuanHuyen', 'unit']);

		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = form.getFieldValue('TinhThanh');
		searchParams.UnderType = trucThuocType;
		setApiSearchData(searchParams);

		setUnitInfo({} as PcccUnit);
		setUnits([]);

		if (trucThuocType) {
			setIsDistrictsSelect(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
			setDisabledUnitSelect(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
			const units = await getUnits(trucThuocType);
			setUnits(units);
		} else {
			setIsDistrictsSelect(false);
			setDisabledUnitSelect(true);
			setTitle(provinceTitle);
		}
	};

	const handleChangeDistrict = async (districtId: string, option: any) => {
		form.resetFields(['unit']);

		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = form.getFieldValue('TinhThanh');
		searchParams.Location!.QuanHuyen = districtId;
		searchParams.UnderType = form.getFieldValue('trucThuoc');
		setApiSearchData(searchParams);

		const title = option && option.children;
		if (title) {
			setTitle(title);
			setDistrictTitle(title);
		} else {
			setTitle(provinceTitle);
		}

		setUnitInfo({} as PcccUnit);
		setUnits([]);

		if (districtId) {
			const units = await getUnits(LoaiHinhDonViEnum.QUAN_HUYEN);
			setUnits(units);
			setDisabledUnitSelect(false);
		} else {
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeUnit = (unitId: string, option: any) => {
		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = form.getFieldValue('TinhThanh');
		searchParams.Location!.QuanHuyen = form.getFieldValue('QuanHuyen');
		searchParams.UnderType = form.getFieldValue('trucThuoc');
		searchParams.PcccUnitId = unitId;
		setApiSearchData(searchParams);

		const title = option && option.children;
		if (title) {
			setTitle(title);
		} else {
			setTitle(districtTitle ? districtTitle : provinceTitle);
		}

		if (unitId) {
			setUnitInfo(units.find(unit => unit.Id === unitId) as PcccUnit);
		} else {
			setUnitInfo({} as PcccUnit);
		}
	};

	const handleResetFormAction = () => {
		form.resetFields();
		setIsDistrictsSelect(false);
		setDisabledTrucThuocSelect(true);
		setDisabledUnitSelect(true);
		setUnitInfo({} as PcccUnit);
		setTitle('');
		setApiSearchData({});
	};

	const handleChangeTab = (tab: string) => {
		localStorage.setItem('quanLyDonViTab', tab);
		setCurrentTab(tab);
	};

	useEffect(() => {
		document.title = t('quanLyDonViPhongChay.chiTietDonVi');
		return () => localStorage.removeItem('quanLyDonViTab');
	}, []);

	useEffect(() => {
		if (isNavigate) {
			setDisabledTrucThuocSelect(false);
			setIsDistrictsSelect(!!districtIdFromDashboard);
			setDisabledUnitSelect(!districtIdFromDashboard);
			setProvinceId(provinceIdFromDashboard);
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				trucThuoc: districtIdFromDashboard ? LoaiHinhDonViEnum.QUAN_HUYEN : null,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
			});

			setApiSearchData({
				Location: {
					TinhThanh: provinceIdFromDashboard,
					...(districtIdFromDashboard && { QuanHuyen: districtIdFromDashboard }),
				},
				...(districtIdFromDashboard && { UnderType: LoaiHinhDonViEnum.QUAN_HUYEN }),
			});
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
	}, [isNavigate, form, provinceIdFromDashboard, districtIdFromDashboard]);

	return (
		<div className="FirePreventionUnitWrapper">
			<div className="FirePreventionUnitContainer">
				<Form form={form} className="select-container">
					<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

					<Form.Item name="trucThuoc">
						<Select
							showSearch
							optionFilterProp="children"
							allowClear
							onChange={handleChangeTrucThuoc}
							placeholder="Trực thuộc"
							className="withMinSelect"
							disabled={disabledTrucThuocSelect}>
							<Select.Option key={LoaiHinhDonViEnum.PC07} value={LoaiHinhDonViEnum.PC07}>
								{t('enum.trucThuoc.PC07')}
							</Select.Option>
							<Select.Option key={LoaiHinhDonViEnum.QUAN_HUYEN} value={LoaiHinhDonViEnum.QUAN_HUYEN}>
								{t('enum.trucThuoc.QUAN_HUYEN')}
							</Select.Option>
						</Select>
					</Form.Item>

					{isDistrictsSelect && (
						<DistrictSelect
							selectClassName="withMinSelect"
							form={form}
							provinceId={provinceId}
							onChangeDistrict={handleChangeDistrict}
						/>
					)}

					<Form.Item name="unit">
						<Select
							showSearch
							optionFilterProp="children"
							allowClear
							onChange={handleChangeUnit}
							placeholder="Chọn đơn vị"
							style={{ width: 253 }}
							disabled={disabledUnitSelect}>
							{units.map(unit => (
								<Select.Option key={unit.Id} value={unit.Id}>
									{unit.TenDonVi}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>

				{title && (
					<div className="info-container">
						<h3 className="heading">{title}</h3>
						{unitInfo && Object.keys(unitInfo).length > 0 && (
							<div className="manager-info">
								<div className="manager-info-label">
									<span>Cán bộ quản lý:</span>
									<span>SDT trực ban:</span>
									<span>Địa chỉ trụ sở:</span>
								</div>
								<div>
									<span>{unitInfo.CanBoQuanLy}</span>
									<span>{unitInfo.SoDienThoaiTrucBan}</span>
									<span>{unitInfo.LocationDetail}</span>
								</div>
							</div>
						)}
					</div>
				)}

				<Tabs defaultActiveKey={currentTab} className={'mt10 flex--fill'} onTabClick={tab => handleChangeTab(tab)}>
					{(props.permissions.includes(PermissionEnum.ADMIN) ||
						props.permissions.includes(PermissionEnum.FIRE_FIGHTER_VIEW)) && (
						<Tabs.TabPane tab={<span>Cán bộ chiến sĩ</span>} key={QuanLyDonViPhongChayTab.DANH_SACH_CAN_BO_CHIEN_SY}>
							<DanhSachCanBoChienSy
								searchData={apiSearchData}
								onResetFormAction={handleResetFormAction}
								permissionList={props.permissions}
								userInfo={props.userInfo}
								isNavigate={isNavigate}
							/>
						</Tabs.TabPane>
					)}

					{(props.permissions.includes(PermissionEnum.ADMIN) ||
						props.permissions.includes(PermissionEnum.FIRE_TRUCK_VIEW)) && (
						<Tabs.TabPane
							tab={<span>Phương tiện PCCC & CNCH</span>}
							key={QuanLyDonViPhongChayTab.PHUONG_TIEN_PCCC}>
							<DanhSachPhuongTien
								searchData={apiSearchData}
								onResetFormAction={handleResetFormAction}
								permissionList={props.permissions}
								userInfo={props.userInfo}
								isNavigate={isNavigate}
							/>
						</Tabs.TabPane>
					)}

					{(props.permissions.includes(PermissionEnum.ADMIN) ||
						props.permissions.includes(PermissionEnum.SUPPORT_UNIT_VIEW)) && (
						<Tabs.TabPane tab={<span>Đơn vị hỗ trợ</span>} key={QuanLyDonViPhongChayTab.DON_VI_HO_TRO}>
							<DanhSachDonViHoTro
								searchData={apiSearchData}
								onResetFormAction={handleResetFormAction}
								permissionList={props.permissions}
								userInfo={props.userInfo}
							/>
						</Tabs.TabPane>
					)}

					{(props.permissions.includes(PermissionEnum.ADMIN) ||
						props.permissions.includes(PermissionEnum.WATER_POINT_VIEW)) && (
						<Tabs.TabPane tab={<span>Điểm lấy nước</span>} key={QuanLyDonViPhongChayTab.DIEM_LAY_NUOC}>
							<DanhSachDiemLayNuoc
								searchData={apiSearchData}
								onResetFormAction={handleResetFormAction}
								permissionList={props.permissions}
								userInfo={props.userInfo}
								isNavigate={isNavigate}
							/>
						</Tabs.TabPane>
					)}
				</Tabs>
			</div>
		</div>
	);
};

export default QuanLyDonVi;
