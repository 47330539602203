import { t } from 'i18next';

export enum TrangThaiHoatDongEnum {
	TOT = 'TOT',
	TRUNG_BINH = 'TRUNG_BINH',
	KEM = 'KEM',
	CAN_SUA_CHUA = 'CAN_SUA_CHUA',
	CAN_THAY_THE = 'CAN_THAY_THE',
}

interface TrangThaiHoatDong {
	value: TrangThaiHoatDongEnum;
	label: string;
	color: string;
}

const _fromEnum = (trangThaiHoatDongEnum: TrangThaiHoatDongEnum): TrangThaiHoatDong => {
	switch (trangThaiHoatDongEnum) {
		case TrangThaiHoatDongEnum.TOT:
			return {
				label: t('enum.trangThaiHoatDong.TOT'),
				value: TrangThaiHoatDongEnum.TOT,
				color: '#1BB36B',
			};
		case TrangThaiHoatDongEnum.TRUNG_BINH:
			return {
				label: t('enum.trangThaiHoatDong.TRUNG_BINH'),
				value: TrangThaiHoatDongEnum.TRUNG_BINH,
				color: '#F7B652',
			};
		case TrangThaiHoatDongEnum.CAN_SUA_CHUA:
			return {
				label: t('enum.trangThaiHoatDong.CAN_SUA_CHUA'),
				value: TrangThaiHoatDongEnum.CAN_SUA_CHUA,
				color: '#1B56B3',
			};
		case TrangThaiHoatDongEnum.CAN_THAY_THE:
			return {
				label: t('enum.trangThaiHoatDong.CAN_THAY_THE'),
				value: TrangThaiHoatDongEnum.CAN_THAY_THE,
				color: '#FF2000',
			};
		case TrangThaiHoatDongEnum.KEM:
			return {
				label: t('enum.trangThaiHoatDong.KEM'),
				value: TrangThaiHoatDongEnum.KEM,
				color: '#FF720E',
			};
	}
};

const _enumFromLabel = (trangThaiLabel: string): TrangThaiHoatDongEnum | undefined => {
	switch (trangThaiLabel.toUpperCase()) {
		case t('enum.trangThaiHoatDong.TOT').toUpperCase():
			return TrangThaiHoatDongEnum.TOT;
		case t('enum.trangThaiHoatDong.TRUNG_BINH').toUpperCase():
			return TrangThaiHoatDongEnum.TRUNG_BINH;
		case t('enum.trangThaiHoatDong.CAN_SUA_CHUA').toUpperCase():
			return TrangThaiHoatDongEnum.CAN_SUA_CHUA;
		case t('enum.trangThaiHoatDong.CAN_THAY_THE').toUpperCase():
			return TrangThaiHoatDongEnum.CAN_THAY_THE;
		case t('enum.trangThaiHoatDong.KEM').toUpperCase():
			return TrangThaiHoatDongEnum.KEM;
	}
};

const _getAll = (): TrangThaiHoatDong[] => {
	return Object.values(TrangThaiHoatDongEnum).map(r => _fromEnum(r as TrangThaiHoatDongEnum));
};

const trangThaiHoatDongEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default trangThaiHoatDongEnum;
