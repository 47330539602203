import { Button, Col, DatePicker, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import CommonControl from 'components/page/CommonControl';
import { blockInvalidChar } from 'components/page/CommonControl/BlockInvalidChar';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import ConstructionLabel from 'components/shared/ConstructionLabel/ConstructionLabel';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import propagateService from 'services/api/businessService/propagateService';
import ConstructionService, { Construction } from 'services/api/inventoryService/constructionService';
import provinceService, { District } from 'services/api/provinceService';
import Utils from 'services/api/utils';

const { Option } = Select;
const dateFormatList = ['DD/MM/YYYY'];

interface IDialogCongTacTuyenTruyenParams {
	Id?: string;
	callback?: () => void;
	mode?: 'view' | 'edit' | 'add';
	userInfo?: UserInfo;
}

function DialogCongTacTuyenTruyen(props: IDialogCongTacTuyenTruyenParams) {
	const dialog = useDialog();
	const [form] = Form.useForm();
	// const tinhthanh = Utils.TinhThanh();

	const [constructionItems, setConstructionItems] = useState<JSX.Element[]>([]);
	const [districtItems, setDistrictItems] = useState<JSX.Element[]>([]);
	const [constructionId, setConstructionId] = useState('');

	// get list of provinces
	const tinhThanhscustomer = Utils.GetTinhThanhUser();
	const tinhThanhsAdmin = Utils.GetTinhThanhAdmin();

	const generateConstructionItems = (constructions: Construction[]) => {
		if (constructions && constructions.length > 0) {
			const options = constructions.map(construction => (
				<Option key={construction.Id} value={construction.Id}>
					{construction.Name}
				</Option>
			));
			setConstructionItems(options);
		}
	};

	const generateDistrictItem = (districts: District[]) => {
		if (districts && districts.length > 0) {
			const options = districts.map(district => (
				<Option key={district.Id} value={district.Id}>
					{district.Name}
				</Option>
			));
			setDistrictItems(options);
		}
	};

	const getDistricts = async (provinceId: string) => {
		return await provinceService.findDistrictsByProvince(provinceId);
	};

	const getConstructions = async (provinceId: string = '', districtId: string = '') => {
		const param = {
			Location: {
				TinhThanh: provinceId,
				QuanHuyen: districtId,
			},
		};
		return await ConstructionService.selectBoxForContruction(param);
	};

	const handleChangeProvince = async (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'ConstructionId']);
		setDistrictItems([]);
		setConstructionItems([]);
		if (provinceId) {
			const districts = await getDistricts(provinceId);
			let quanHuyenList: any[] = [];
			if (
				props.userInfo &&
				props.userInfo.Locations &&
				props.userInfo.Locations.length > 0 &&
				!props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
			) {
				props.userInfo.Locations.forEach((location: any) => {
					if (location.DistrictId) {
						districts.forEach(quanHuyen => {
							if (quanHuyen.Id === location.DistrictId) {
								quanHuyenList.push(quanHuyen);
							}
						});
					} else {
						quanHuyenList = districts;
					}
				});
			} else {
				quanHuyenList = districts;
			}
			generateDistrictItem(quanHuyenList);
		}
	};

	const handelChangeDistrict = async (districtId: string) => {
		form.resetFields(['ConstructionId']);
		if (districtId) {
			const constructions = await getConstructions(form.getFieldValue('TinhThanh'), districtId);
			generateConstructionItems(constructions);
		} else {
			setConstructionItems([]);
		}
	};

	// Click button Add thêm mới
	async function themMoiCongTacTuyenTruyen() {
		form.validateFields().then(values => {
			if (values) {
				const paramInput: any = {
					Location: {
						TinhThanh: form.getFieldValue('TinhThanh'),
						QuanHuyen: form.getFieldValue('QuanHuyen'),
					},
					ConstructionId: form.getFieldValue('ConstructionId'),
					Time: form.getFieldValue('ThoiGian'),
					CertificateNum: form.getFieldValue('CertificateNum'),
					TotalHour: form.getFieldValue('TotalHour'),
					Content: form.getFieldValue('Content'),
					PcccCount: form.getFieldValue('PcccCount'),
					ManangerCount: form.getFieldValue('ManangerCount'),
					WorkerCount: form.getFieldValue('WorkerCount'),
					OtherCount: form.getFieldValue('OtherCount'),
				};
				if (!props.Id) {
					propagateService
						.createPropagate(paramInput)
						.then((_: any) => {
							showNotification('success', t('notification.success'), 'Một công tác tuyên truyền đã tạo thành công.');
							if (props.callback) {
								props.callback();
							}
							dialog.close();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response?.data || errors.message);
						});
				} else {
					paramInput.Id = props.Id;
					propagateService
						.updatePropagate(paramInput)
						.then((_: any) => {
							showNotification(
								'success',
								t('notification.success'),
								'Một công tác tuyên truyền đã cập nhật thành công.',
							);
							if (props.callback) {
								props.callback();
							}
							dialog.close();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response?.data || errors.message);
						});
				}
			}
		});
	}

	useEffect(() => {
		if (props.Id) {
			// Get Data Công tác tuyên truyền
			propagateService.getPropagate(props.Id).then((result: any) => {
				if (!result) {
					return;
				}
				setConstructionId(result.ConstructionId);
				let promises: PromiseLike<any>[] = [];
				promises.push(
					provinceService
						.findDistrictsByProvince(result.Location.TinhThanh)
						.then((res: any) => generateDistrictItem(res)),
				);
				promises.push(
					ConstructionService.selectBoxForContruction({
						Location: {
							TinhThanh: result.Location.TinhThanh,
						},
					}).then((res: any) => generateConstructionItems(res)),
				);
				Promise.all(promises).then(() => {
					form.setFieldsValue(result);
					form.setFieldsValue({
						TinhThanh: result.Location.TinhThanh,
						QuanHuyen: result.Location.QuanHuyen,
						ThoiGian: result.Time ? moment(result.Time) : undefined,
					});
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Form className="common-dialog" layout="vertical" name="normal_login" form={form}>
			<Row gutter={32} className="mb15">
				<Col span={12}>
					<ColumnTitle columnTitle="I. Thông tin chung" />
					<div>
						<Form.Item
							name="TinhThanh"
							label="Tỉnh thành"
							rules={[
								{
									required: true,
									message: 'Tỉnh thành không được bỏ trống',
								},
							]}>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder={t('placeholder.tinhThanh')}
								onChange={handleChangeProvince}
								allowClear
								disabled={props.mode === 'view'}>
								{props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
							</Select>
						</Form.Item>

						<Form.Item
							name="QuanHuyen"
							label="Quận huyện"
							rules={[
								{
									required: true,
									message: 'Quận huyện không được bỏ trống',
								},
							]}>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder={t('placeholder.quanHuyen')}
								allowClear
								disabled={props.mode === 'view'}
								onChange={handelChangeDistrict}>
								{districtItems}
							</Select>
						</Form.Item>

						<Form.Item
							label={<ConstructionLabel constructionId={constructionId} dialog={dialog} />}
							name="ConstructionId"
							rules={[
								{
									required: true,
									message: 'Cơ sở công trình không được bỏ trống',
								},
							]}>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder={t('placeholder.construction')}
								allowClear
								disabled={props.mode === 'view'}
								onChange={value => setConstructionId(value)}>
								{constructionItems}
							</Select>
						</Form.Item>

						<Form.Item
							label="Thời gian thực hiện"
							name="ThoiGian"
							rules={[
								{
									required: true,
									message: 'Thời gian không được bỏ trống',
								},
							]}>
							<DatePicker
								locale={CommonControl.localeVi}
								placeholder="Chọn thời gian thực hiện"
								format={dateFormatList}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>

						<Form.Item label="Số giấy chứng nhận HLNV PCCC được cấp" name="CertificateNum">
							{/* <Input placeholder="Nhập Số giấy CN HLNV PCCC được cấp" /> */}
							<Input placeholder="Nhập Số giấy CN HLNV PCCC được cấp" disabled={props.mode === 'view'} />
						</Form.Item>

						<Form.Item label="Thời lượng (giờ)" name="TotalHour">
							{/* <Input placeholder="Thời lượng, huấn luyện, bồi dưõng" /> */}

							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Thời lượng, huấn luyện, bồi dưõng"
								min={0}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>
					</div>
				</Col>

				<Col span={12}>
					<ColumnTitle columnTitle="II. Thông tin chi tiết" />
					<div>
						<Form.Item
							label="Nội dung huấn luyện"
							name="Content"
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Hãy nhập nội dung huấn luyện, bồi dưỡng nghiệp vụ PCCC!',
								},
							]}>
							<Input.TextArea
								rows={5}
								placeholder="Nhập nội dung huấn luyện, bồi dưỡng nghiệp vụ PCCC"
								disabled={props.mode === 'view'}
							/>
						</Form.Item>

						<Form.Item
							id="addnew"
							label="Lực lượng PCCC cơ sở (đơn vị: người)"
							name="PcccCount"
							rules={[
								{
									required: true,
									message: 'Hãy nhập số lực lượng pccc cơ sở tham gia!',
								},
							]}>
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Hãy nhập lực lượng PCCC cơ sở"
								min={0}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>

						<Form.Item
							label="Cán bộ quản lý, lãnh đạo (đơn vị: người)"
							name="ManangerCount"
							rules={[
								{
									required: true,
									message: 'Hãy nhập số lượng cán bộ quản lý lãnh đạo tham gia!',
								},
							]}>
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Số lượng cán bộ quản lý tham gia"
								min={0}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>

						<Form.Item
							id="addnew"
							label="Người lao động (đơn vị: người)"
							name="WorkerCount"
							rules={[
								{
									required: true,
									message: 'Hãy nhập số lượng người lao động tham gia!',
								},
							]}>
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Hãy số người lao động"
								min={0}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>

						<Form.Item label="Đối tượng khác (đơn vị: người)" name="OtherCount">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Đối tượng khác"
								min={0}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>
					</div>
				</Col>
			</Row>

			<Divider />

			<div className="dialog__buttons mt10">
				{props.mode === 'view' && (
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						OK
					</Button>
				)}
				{props.mode !== 'view' && (
					<>
						<Button
							type="default"
							onClick={() => {
								dialog.close();
							}}>
							Hủy bỏ
						</Button>
						<Button type="primary" htmlType="submit" onClick={themMoiCongTacTuyenTruyen}>
							{props.Id ? 'Lưu' : 'Thêm mới'}
						</Button>
					</>
				)}
			</div>
		</Form>
	);
}

export default DialogCongTacTuyenTruyen;
