import DanhSachDonVi from 'components/page/QuanLyDonviPhongChay/Tabs/DanhSachDonVi/DanhSachDonVi';
import LichTruc from 'components/page/QuanLyDonviPhongChay/Tabs/LichTruc/LichTruc';
import QuanLyDonVi from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import ScheduleDuty from 'components/page/QuanLyDonviPhongChay/Tabs/ScheduleDuty/ScheduleDuty';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import { SearchFireFighterParams } from 'services/api/inventoryService/fireFighterService';
import './QuanLyDonViPhongChay.scss';

export enum QuanLyDonViPhongChayNav {
	DANH_SACH_DON_VI_PCCC = 'DanhSachDonViPCCC',
	QUAN_LY_DON_VI = 'QuanLyDonVi',
	LICH_TRUC = 'LichTruc',
}

interface CustomMapIn {
	Latitude: number;
	Longitude: number;
}
export interface QuanLyDonViPhongChayTabProps {
	isModeView?: boolean;
	searchData?: SearchFireFighterParams;
	onResetFormAction?: () => void;
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
	isNavigate?: boolean;
	mode?: string;
	isDisable?: boolean;
	donviId?: string;
	mapInput?: CustomMapIn;
	callback?: () => void;
}

export interface PermissionProps {
	permissions: PermissionEnum[];
	userInfo: UserInfo;
}

const mapStateToProps = (): PermissionProps => ({
	permissions: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

// Form Quản lý đơn vị phòng cháy
const QuanLyDonViPhongChay: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	const currentTab = searchParams.get('tab');

	useEffect(() => {
		NavBarDispatcher.setCollapse(false);
		document.title = t('nav.quanLyDonViPhongChayChuaChay');
		NavBarDispatcher.setTitle(`${t('quanLyDonViPhongChay.title')}`);
	}, []);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissions.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (!currentTab || !Object.values(QuanLyDonViPhongChayNav).includes(currentTab as QuanLyDonViPhongChayNav)) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.PCCC_UNIT_VIEW])) {
				navigate(
					{
						pathname: '/quanLyDonViPhongChay',
						search: `?tab=${QuanLyDonViPhongChayNav.DANH_SACH_DON_VI_PCCC}`,
					},
					{ replace: true },
				);
			} else {
				navigate(
					{
						pathname: '/quanLyDonViPhongChay',
						search: `?tab=${QuanLyDonViPhongChayNav.QUAN_LY_DON_VI}`,
					},
					{ replace: true },
				);
			}
		}
	}, [currentTab, navigate]);

	return (
		<>
			{currentTab === QuanLyDonViPhongChayNav.DANH_SACH_DON_VI_PCCC && (
				<DanhSachDonVi permissions={props.permissions} userInfo={props.userInfo} />
			)}

			{currentTab === QuanLyDonViPhongChayNav.QUAN_LY_DON_VI && (
				<QuanLyDonVi permissions={props.permissions} userInfo={props.userInfo} />
			)}

			{/* {currentTab === QuanLyDonViPhongChayNav.LICH_TRUC && <LichTruc />} */}

			{currentTab === QuanLyDonViPhongChayNav.LICH_TRUC && <ScheduleDuty permissions={props.permissions} userInfo={props.userInfo}/>}
		</>
	);
};

export default connect(mapStateToProps)(withTranslation()(QuanLyDonViPhongChay));
