import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import chucVuEnum from 'enum/chucVuEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import { Moment } from 'moment';
import React, { useEffect } from 'react';
import { useDialog } from 'react-st-modal';
import DutyScheduleService from 'services/api/businessService/dutyScheduleService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';

interface ScheduleDutyModalProps {
	unitId: string;
	callback: any;
	dutyScheduleId: string | null;
	teamId?: string;
	dutyDate: Moment;
}

const ScheduleDutyModal = ({ unitId, callback, dutyScheduleId, teamId, dutyDate }: ScheduleDutyModalProps) => {
	const dialog = useDialog();
	const [form] = Form.useForm();

	const handleShowNotificationSuccess = () => {
		showNotification('success', t('notification.success'), 'Thêm lịch trực thành công');
		dialog.close();
		callback();
	};

	const handleShowNotificationError = (error: any) => {
		showNotification(
			'error',
			t('notification.error'),
			typeof error?.response?.message === 'string' ? error?.response?.message : 'Lỗi không xác định',
		);
	};

	const handleSubmit = () => {
		const {
			CommandOfficerName,
			CommandPhoneNumber,
			CommandPosition,
			DailyOfficerName,
			DailyPhoneNumber,
			DailyPosition,
			LeaderOfficerName,
			LeaderPhoneNumber,
			LeaderPosition,
			...extraFields
		} = form.getFieldsValue();

		const DutyList = [];

		const LeadSchedule = {
			OfficerName: LeaderOfficerName,
			PhoneNumber: LeaderPhoneNumber,
			Position: LeaderPosition,
		};
		const DailySchedule = {
			OfficerName: DailyOfficerName,
			PhoneNumber: DailyPhoneNumber,
			Position: DailyPosition,
		};
		const CommandSchedule = {
			OfficerName: CommandOfficerName,
			PhoneNumber: CommandPhoneNumber,
			Position: CommandPosition,
		};

		DutyList.push({ ...extraFields, LeadSchedule, DailySchedule, CommandSchedule });

		const payload = {
			PcccUnitId: unitId,
			DutyDate: dutyDate,
			DutyList,
		};

		const payloadCreateTeam = {
			...extraFields,
			DutyScheduleId: dutyScheduleId,
			LeadSchedule,
			DailySchedule,
			CommandSchedule,
		};

		if (dutyScheduleId && teamId) {
			DutyScheduleService.updateDutyScheduleTeamSonLa({
				DutyScheduleId: dutyScheduleId,
				TeamId: teamId,
				...payloadCreateTeam,
			})
				.then(() => {
					showNotification('success', t('notification.success'), 'Cập nhật lịch trực thành công');
					dialog.close();
					callback();
				})
				.catch(error => handleShowNotificationError(error));
			return;
		}

		dutyScheduleId
			? DutyScheduleService.createDutyScheduleTeamSonLa(payloadCreateTeam)
					.then(() => handleShowNotificationSuccess())
					.catch(error => handleShowNotificationError(error))
			: DutyScheduleService.createDutyScheduleSonLa(payload)
					.then(() => handleShowNotificationSuccess())
					.catch(error => handleShowNotificationError(error));
	};

	useEffect(() => {
		if (dutyScheduleId && teamId) {
			DutyScheduleService.getDutyScheduleSonLaById(dutyScheduleId).then(data => {
				const { DutyList } = data;
				const foundTeam = DutyList?.find(item => item.TeamId === teamId);
				if (foundTeam) {
					const { LeadSchedule, CommandSchedule, DailySchedule, Name, LocationDetail } = foundTeam;
					form.setFieldsValue({
						Name,
						LocationDetail,
						CommandOfficerName: CommandSchedule?.OfficerName,
						CommandPhoneNumber: CommandSchedule?.PhoneNumber,
						CommandPosition: CommandSchedule?.Position,
						DailyOfficerName: DailySchedule?.OfficerName,
						DailyPhoneNumber: DailySchedule?.PhoneNumber,
						DailyPosition: DailySchedule?.Position,
						LeaderOfficerName: LeadSchedule?.OfficerName,
						LeaderPhoneNumber: LeadSchedule?.PhoneNumber,
						LeaderPosition: LeadSchedule?.Position,
					});
				}
			});
		}
	}, [teamId, dutyScheduleId, form]);

	return (
		<div className="common-dialog">
			<Form layout="vertical" name="normal_login" form={form} onFinish={handleSubmit}>
				<Row gutter={24}>
					<Col span={12}>
						<Form.Item
							name="Name"
							label="Tên đội"
							rules={[{ required: true, message: 'Tên đội là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
					</Col>
					<Col span={12}>
						<Form.Item
							name="LocationDetail"
							label="Địa chỉ"
							rules={[{ required: true, message: 'Địa chỉ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập địa chỉ" />
						</Form.Item>
					</Col>
				</Row>

				<Row gutter={24}>
					<Col span={8}>
						<Typography.Title level={5}>Trực chỉ huy</Typography.Title>
						<Form.Item
							name="LeaderOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="LeaderPhoneNumber"
							label="Số điện thoại"
							rules={[
								{
									required: true,
									message: 'Số điện thoại là thông tin bắt buộc',
									whitespace: true,
								},
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="LeaderPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>

					<Col span={8}>
						<Typography.Title level={5}>Trực ban</Typography.Title>
						<Form.Item
							name="DailyOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="DailyPhoneNumber"
							label="Số điện thoại"
							rules={[
								{ required: true, message: 'Số điện thoại là thông tin bắt buộc', whitespace: true },
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="DailyPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
					<Col span={8}>
						<Typography.Title level={5}>Trực điều lệnh</Typography.Title>
						<Form.Item
							name="CommandOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="CommandPhoneNumber"
							label="Số điện thoại"
							rules={[
								{ required: true, message: 'Số điện thoại là thông tin bắt buộc', whitespace: true },
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="CommandPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Divider className="mb15" />

				<Row justify="end">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						Hủy bỏ
					</Button>
					<Button type="primary" htmlType="submit" className="ml10">
						{teamId ? 'Cập nhật' : 'Thêm mới'}
					</Button>
				</Row>
			</Form>
		</div>
	);
};

export default ScheduleDutyModal;
