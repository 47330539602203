export enum DeviceStatus {
	DISCONNECT,
	CONNECT,
}

interface IDeviceStatus {
	label: string;
	value: DeviceStatus;
	color: string;
	bg: string;
}

export const DeviceStatusMap = new Map<number, IDeviceStatus>([
	[
		DeviceStatus.DISCONNECT,
		{ label: 'Mất kết nối', value: DeviceStatus.DISCONNECT, color: '#FF2000', bg: '#FFD2CC' },
	],
	[DeviceStatus.CONNECT, { label: 'Đang kết nối', value: DeviceStatus.CONNECT, color: '#1BB36B', bg: '#D1F0E1' }],
]);

export const getDeviceStatusEnumLabel = () => {
	return Object.values(DeviceStatus)
		.filter(item => typeof item === 'number')
		.map((item: any) => DeviceStatusMap.get(item));
};
