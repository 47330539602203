import ApiService, { BUSINESS_BASE_URL } from '../../index';

export const prefixFileBusiness = '/FileBusiness';

// const REPORT_BASE_URL_FAKE = 'http://192.168.1.56:10000/api';

const _deleteFileBusiness = (fileName: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixFileBusiness}/deleteFile`, fileName)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFileBusiness = (objectId: string, constructionId: string, fileName: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixFileBusiness}/search?objectId=${objectId}&constructionId=${constructionId}&fileName=${fileName}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFileBusinessbyId = (objectId: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixFileBusiness}/id=${objectId}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _uploadFile = (formData: FormData): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixFileBusiness}/uploadFile`, formData, { headers: { 'content-type': 'multipart/form-data' } })
		.then(({ data }: { data: any }) => {
			return Promise.resolve(data);
		});

const uploadImagesAndVideos = (formData: FormData): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixFileBusiness}/uploadFileVideoAndImages`, formData, {
			headers: { 'content-type': 'multipart/form-data' },
		})
		.then(({ data }: { data: any }) => {
			return Promise.resolve(data);
		});

const FireFileBusinessService = {
	uploadFile: _uploadFile,
	findFileBusinessbyId: _findFileBusinessbyId,
	findfindFileBusiness: _findFileBusiness,
	deleteFileBusiness: _deleteFileBusiness,
	uploadImagesAndVideos,
};

export default FireFileBusinessService;
