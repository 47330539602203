import { CaretRightOutlined } from '@ant-design/icons';
import { Button, Collapse, Empty } from 'antd';
import { CollapseType } from 'components/page/QuanLyCongTacPhongChay/Tabs/KeHoachKiemTra/KeHoachKiemTra';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import CheckingStatusIcon from 'components/shared/Icon/CheckingStatusIcon';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import DateTypeEnum from 'enum/date-type/date-type.enum';
import trangThaiThucHienEnum, { TrangThaiThucHienEnum } from 'enum/trangThaiThucHienEnum';
import { formatDate } from 'helpers';
import { uniqBy } from 'lodash';
import { ConstructionCheckingModel } from 'models/ConstructionChecking/ConstructionChecking.model';
import React, { useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import ConstructionCheckingPlanService from 'services/api/businessService/constructionCheckingPlanService';
import ConstructionCheckingService from 'services/api/businessService/constructionCheckingService';
import useDeepCompareEffect from 'use-deep-compare-effect';
import './KeHoachKiemTraCollapseType.scss';

interface KeHoachKiemTraCollapseTypeProps {
	collapseType: CollapseType;
	paramSearch?: any;
}

interface Panel {
	value: any;
	label: string;
}

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = 10;

const KeHoachKiemTraCollapseType = (props: KeHoachKiemTraCollapseTypeProps) => {
	const { collapseType, paramSearch } = props;
	//  states of component
	const [dataSources, setDataSources] = useState<ConstructionCheckingModel.ConstructionChecking[]>([]);
	const [initDataSources, setInitDataSources] = useState<ConstructionCheckingModel.ConstructionChecking[]>([]);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [panels, setPanels] = useState<Panel[]>([]);
	const [panelId, setPanelId] = useState<string>('');
	const [pagination, setPagination] = useState<{ currentPage: number; pageSize: number }>({
		currentPage: CURRENT_PAGE,
		pageSize: PAGE_SIZE,
	});

	const getConstructionCheckingsByCollapseType = useRef<any>();
	const navigate = useNavigate();

	const columns = [
		{
			title: 'STT',
			dataIndex: 'Id',
			key: 'Id',
			align: 'center' as const,
			width: 45,
			render: (value: any, item: any, index: any) => (pagination.currentPage - 1) * pagination.pageSize + index + 1,
		},
		{
			title: 'Tên công trình kiểm tra',
			dataIndex: 'ConstructionName',
			key: 'ConstructionName',
			render: (Name: string, record: ConstructionCheckingModel.ConstructionChecking) => {
				const { color } = trangThaiThucHienEnum.fromEnum(record.CheckingStatus);
				return (
					<Button
						style={{ display: 'inline-flex', alignItems: 'center' }}
						type="link"
						onClick={e => {
							e.stopPropagation();
							navigate(`/chiTietCoSoCongTrinh?id=${record.ConstructionId}&mode=view`);
						}}>
						<CheckingStatusIcon color={color} />
						&nbsp;&nbsp;
						<span className="text-link" title={Name}>
							{Name}
						</span>
					</Button>
				);
			},
		},
		{ title: 'Địa chỉ', dataIndex: 'LocationDetail', key: 'LocationDetail' },
		{
			title: 'Ngày kiểm tra',
			dataIndex: 'CheckDate',
			key: 'CheckDate',
			align: 'center' as const,
			render: (date: string, record: ConstructionCheckingModel.ConstructionChecking) => {
				if (record.IsOutDate) {
					return <span style={{ color: '#FF2000' }}>Quá hạn</span>;
				}

				return date ? formatDate(date) : null;
			},
		},
		{ title: 'Cán bộ kiểm tra', dataIndex: 'CheckerName', key: 'CheckerName' },
		{
			title: 'Trạng thái',
			dataIndex: 'CheckingStatus',
			key: 'CheckingStatus',
			align: 'center' as const,
			render: (CheckingStatus: TrangThaiThucHienEnum) => {
				const checkingStatus = trangThaiThucHienEnum.fromEnum(CheckingStatus);
				return <span style={{ color: `#${checkingStatus.color}` }}>{checkingStatus.label}</span>;
			},
		},
		{
			title: 'Thao tác',
			dataIndex: 'Action',
			key: 'Action',
			width: 120,
			align: 'center' as const,
			render: (_: any, record: ConstructionCheckingModel.ConstructionChecking) => {
				return (
					<>
						<Button type="text" onClick={e => e.stopPropagation()}>
							<Link
								to={{
									pathname: '/chiTietKeHoachKiemTra',
									search: `?id=${record.Id}&mode=edit`,
								}}>
								<img src="icon/Pen.png" alt="edit" />
							</Link>
						</Button>

						<Button type="text" onClick={e => handleShowDeleteDialog(e, record.Id, record.ConstructionName)}>
							<img src="icon/Delete.png" alt="delete" />
						</Button>
					</>
				);
			},
		},
	];

	const handleChangePage = (page: number, pageSize: number) => {
		setPagination({ currentPage: page, pageSize });
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietKeHoachKiemTra',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	const table = (
		<>
			<TableComponent
				dataSource={dataSources}
				columns={columns}
				paginator={{
					defaultPageSize: pagination.pageSize,
					showSizeChanger: true,
					onChange: handleChangePage,
					total: totalItems,
					current: pagination.currentPage,
					pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
					showTotal: (total: number) => `Tổng ${total} công trình`,
				}}
				onRow={handleShowViewDetail}
			/>
		</>
	);

	const handleShowDeleteDialog = async (e: any, id: string, name: string) => {
		e.stopPropagation();
		await CustomDialog(
			<DeleteDialog
				name={name}
				id={id}
				deleteService={ConstructionCheckingService.deleteConstructionChecking}
				callback={refreshData}
			/>,
			{
				showCloseIcon: true,
				isCanClose: true,
			},
		);
	};

	const setData = (
		panelId: any,
		filterItem: 'ConstructionCheckingPlanId' | 'CheckerId' | 'CheckingStatus' | 'DateType',
	) => {
		const dataSources = initDataSources.filter(item => item[filterItem] === panelId);
		setPagination({
			currentPage: CURRENT_PAGE,
			pageSize: PAGE_SIZE,
		});
		setDataSources(dataSources);
		setTotalItems(dataSources.length);
	};

	const handleChangePanel = (panelId: any) => {
		setPanelId(panelId);
		switch (collapseType) {
			case CollapseType.SO_CU:
				setData(panelId, 'ConstructionCheckingPlanId');
				break;
			case CollapseType.CHECKER:
				setData(panelId, 'CheckerId');
				break;
			case CollapseType.STATUS:
				setData(panelId, 'CheckingStatus');
				break;
			case CollapseType.DATE:
				setData(+panelId, 'DateType');
				break;
		}
	};

	getConstructionCheckingsByCollapseType.current = async () => {
		try {
			const data = (await ConstructionCheckingService.getConstructionCheckingByParam(paramSearch)) as any;
			setInitDataSources(data);
			switch (collapseType) {
				case CollapseType.SO_CU:
					const constructionCheckingPlanIds = uniqBy(data, 'ConstructionCheckingPlanId').map(
						(item: any) => item.ConstructionCheckingPlanId,
					);

					const constructionCheckingPlans = (await ConstructionCheckingPlanService.getDanhSachSoCuKiemTra()) as any;

					const constructionCheckingPlansByFiltered = constructionCheckingPlans.filter((item: any) =>
						constructionCheckingPlanIds.includes(item.Id),
					);
					setPanels(constructionCheckingPlansByFiltered.map((item: any) => ({ value: item.Id, label: item.Name })));
					break;
				case CollapseType.CHECKER:
					const checkers = uniqBy(data, 'CheckerId');
					setPanels(checkers.map((item: any) => ({ value: item.CheckerId, label: item.CheckerName })));
					break;
				case CollapseType.STATUS:
					const checkingStatuses = trangThaiThucHienEnum.getAll();
					setPanels(
						checkingStatuses.map(item => ({
							value: item.value,
							label: item.label,
						})),
					);
					break;
				case CollapseType.DATE:
					const dateTypes = DateTypeEnum.getAll();
					setPanels(dateTypes.map(item => ({ label: item.label, value: item.value })));
					break;
			}
		} catch (error) {}
	};

	const refreshData = async () => {
		await getConstructionCheckingsByCollapseType.current();
	};

	useDeepCompareEffect(() => {
		getConstructionCheckingsByCollapseType.current();
	}, [collapseType, paramSearch]);

	useDeepCompareEffect(() => {
		handleChangePanel(panelId);
	}, [initDataSources, panelId]);

	return (
		<Collapse
			expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
			className="collapse-container"
			onChange={handleChangePanel}
			bordered={false}
			accordion>
			{panels.length > 0 ? (
				panels.map(item => (
					<Collapse.Panel header={<span title={item.label}>{item.label}</span>} key={item.value}>
						{table}
					</Collapse.Panel>
				))
			) : (
				<div className="flex--row justify-content--center" style={{ width: '100%' }}>
					<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
				</div>
			)}
		</Collapse>
	);
};

export default KeHoachKiemTraCollapseType;
