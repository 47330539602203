import { Select } from 'antd';
import React from 'react';

const GeneratorProvinceUser = {
	generateOptions: (data: any[], valueName = 'value', labelName = 'label'): JSX.Element[] => {
		let options: JSX.Element[] = [];
		data.forEach(item =>
			options.push(
				<Select.Option key={item[valueName]} value={item[valueName]}>
					{item[labelName] || item[valueName]}
				</Select.Option>,
			),
		);
		return options;
	},
	generateOptionsDashBoard: (data: any[], valueName = 'value', labelName = 'label'): JSX.Element[] => {
		let options: JSX.Element[] = [];
		data.forEach(item =>
			options.push(
				<option key={item[valueName]} value={item[valueName]}>
					{item[labelName] || item[valueName]}
				</option>,
			),
		);
		return options;
	},
};

export default GeneratorProvinceUser;
