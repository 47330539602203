import { Form, Select } from 'antd';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import { FormInstance } from 'rc-field-form';
import React, { ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import ProvinceService, { District } from 'services/api/provinceService';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface DistrictSelectProps {
	onChangeDistrict?: (value: string, option?: any) => void;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: ReactNode;
	provinceId?: string;
	permissions: PermissionEnum[];
	userInfo: UserInfo;
	bordered?: boolean;
	allowClear?: boolean;
	style?: React.CSSProperties;
}
const mapStateToProps = () => ({
	permissions: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const DistrictSelect = (props: DistrictSelectProps) => {
	const {
		onChangeDistrict,
		disabled,
		form,
		rules,
		formItemClassName,
		selectClassName,
		label,
		provinceId,
		permissions,
		userInfo,
		bordered = true,
		allowClear = true,
		style,
	} = props;

	const [districts, setDistricts] = useState<District[]>([]);

	const getDistricts = async (provinceId: string) => {
		return await ProvinceService.findDistrictsByProvince(provinceId);
	};

	useDeepCompareEffect(() => {
		(async () => {
			if (!provinceId) {
				setDistricts([]);
				return;
			}
			const locations = userInfo?.Locations;
			const permissions = props?.permissions;
			const districts = await getDistricts(provinceId);

			if (locations && locations.length > 0 && !permissions.includes(PermissionEnum.ADMIN)) {
				const districtsByUser = locations
					.filter(location => location.CityId === provinceId)
					.map(item => item.DistrictId);
				const matchedDistricts = districts.filter(district => districtsByUser.includes(district.Id));
				setDistricts(matchedDistricts.length === 0 ? districts : matchedDistricts);
			} else {
				setDistricts(districts);
			}
		})();
	}, [provinceId, permissions, userInfo]);

	return (
		<Form.Item name="QuanHuyen" className={formItemClassName} rules={rules} label={label} style={style}>
			<Select
				disabled={disabled}
				showSearch
				optionFilterProp="children"
				className={selectClassName}
				placeholder={t('placeholder.quanHuyen')}
				allowClear={allowClear}
				notFoundContent={'Bạn chưa chọn tỉnh thành'}
				onChange={onChangeDistrict}
				bordered={bordered}>
				{districts.length > 0 &&
					districts.map(district => (
						<Select.Option key={district.Id} value={district.Id}>
							{district.Name}
						</Select.Option>
					))}
			</Select>
		</Form.Item>
	);
};

export default connect(mapStateToProps)(DistrictSelect);
