import { Form, Select } from 'antd';
import { FormInstance } from 'rc-field-form';
import React, { ReactNode, useState } from 'react';
import pcccUnitService, { PcccUnit } from 'services/api/inventoryService/pcccUnitService';
import useDeepCompareEffect from 'use-deep-compare-effect';

interface Location {
	Location: { TinhThanh: string; QuanHuyen?: string };
}

interface UnitManagerSelectProps {
	name: string;
	disabled?: boolean;
	form?: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: ReactNode;
	onChangeUnitManager?: (value: string) => void;
	location: Location;
	bordered?: boolean;
	style?: React.CSSProperties;
}

const UnitManagerSelect = (props: UnitManagerSelectProps) => {
	const {
		name,
		formItemClassName,
		label,
		selectClassName,
		onChangeUnitManager,
		disabled,
		rules,
		location,
		bordered = true,
		style,
	} = props;

	const [units, setUnits] = useState<PcccUnit[]>([]);

	useDeepCompareEffect(() => {
		if (location.Location.TinhThanh) {
			pcccUnitService.searchPcccUnitcomboboxItem(location).then(units => {
				setUnits(units);
			});
		} else {
			setUnits([]);
		}
	}, [location]);

	return (
		<Form.Item name={name} className={formItemClassName} label={label} rules={rules} style={style}>
			<Select
				showSearch
				allowClear
				optionFilterProp="children"
				placeholder="Chọn đơn vị PCCC quản lý"
				disabled={disabled}
				className={selectClassName}
				onChange={onChangeUnitManager}
				bordered={bordered}>
				{units.length > 0 &&
					units.map(unit => (
						<Select.Option key={unit.Id} value={unit.Id}>
							{unit.Name}
						</Select.Option>
					))}
			</Select>
		</Form.Item>
	);
};

export default UnitManagerSelect;
