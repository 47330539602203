import React from 'react';

interface FilterDropdownLayoutProps {
	children: React.ReactNode;
}

const FilterDropdownLayout = (props: FilterDropdownLayoutProps) => {
	const { children } = props;
	return (
		<div
			style={{
				padding: '8px',
				width: '250px',
			}}>
			{children}
		</div>
	);
};

export default FilterDropdownLayout;
