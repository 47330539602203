export interface Province {
	Id: string,
	Name: string,
	Districts: District[]
}

export interface District {
	Id: string,
	Name: string,
	Wards: Ward[]
}

export interface Ward {
	Id: string,
	Name: string,
	Level: string,
}

const data: Province[] = require('./data.json');

const _findDistrictsByProvince = (id: string): Promise<District[]> => Promise.resolve(
	data.find(r => (r.Id === id && r.Districts.length > 0))?.Districts || []);

const _getProvince = (id?: string): Promise<Province | undefined> => Promise.resolve(data.find(r => r.Id === id));

const _listProvince = (): Promise<Province[]> => Promise.resolve(data);

export interface SearchDistrictsParams {
	cityId?: string,
	districtId?: string
}

const _getDistrict = (param: SearchDistrictsParams): Promise<District | undefined> =>
	Promise.resolve(data.find(r => (r.Id === param.cityId))?.Districts.find(p => p.Id === param.districtId));

const _getDistricts = (provinces: Province[], provinceId: string): District[] => {
	return provinces.find(r => r.Id === provinceId)?.Districts.map(r => {
		return {
			...r,
			label: r.Name,
			value: r.Id,
		};
	}) || [];
};

const _getWards = (districts: District[], districtId: string): Ward[] => {
	return districts.find(r => r.Id === districtId)?.Wards?.map(r => {
		return {
			...r,
			label: r.Name,
			value: r.Id,
		};
	}) || [];
};

const ProvinceService = {
	findDistrictsByProvince: _findDistrictsByProvince,
	getDistrict: _getDistrict,
	getDistricts: _getDistricts,
	getProvince: _getProvince,
	getWards: _getWards,
	listProvince: _listProvince,
};

export default ProvinceService;

