export const ACTION_TYPE = {
	SELECT_FIRE_TRUCKS: 'SELECT_FIRE_TRUCKS',
	RESET_DATA: 'RESET_DATA'
};

export const selectFireTrucks = (trucks: string[]) => {
	return {
		type: ACTION_TYPE.SELECT_FIRE_TRUCKS,
		payload: trucks,
	};
};

export const resetFireTrucks = () => {
	return {
		type: ACTION_TYPE.RESET_DATA,
	};
};

