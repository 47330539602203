import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Pagination, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { PermissionProps, QuanLyBaoCaoTab } from 'components/page/QuanLyBaoCao/QuanLyBaoCao';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiPhuongTienEnum, { LoaiPhuongTienEnum } from 'enum/loaiPhuongTienEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import trangThaiHoatDongEnum, { TrangThaiHoatDongEnum } from 'enum/trangThaiHoatDongEnum';
import { downloadExcelFile, formatDate, generateUniqueId, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProvinceService, { District, Province } from 'services/api/provinceService';
import ReportFireTruckService, {
	FireTruckDetail,
	FireTruckStatus,
} from 'services/api/reportService/reportFireTruckService';
import './PhuongTien.scss';

const CURRENT_PAGE = 1;
const PAGE_SIZE = 10;
const TABLE_HEIGHT = 600;
const SUMMARY_TAB = '1';
const DETAIL_TAB = '2';
const EXPORT_SUMMARY_URL = 'ExportExcelFireTruckStatus';
const EXPORT_DETAIL_URL = 'ExportExcelDataFireTruckDetail';
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const PhuongTien: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const getFireTruckReport = useRef<any>({});

	// state of component
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSources, setDataSources] = useState<any>([]);
	const [totalItems, setTotalItems] = useState(0);
	const [currentTab, setCurrentTab] = useState(SUMMARY_TAB);
	const [disableExportButton, setDisableExportButton] = useState(true);
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);

	const [tinhThanh, setTinhThanh] = useState();
	const [quanHuyen, setQuanHuyen] = useState();

	const truckSummaryReportColumns: ColumnsType<any> = [
		{ title: 'Tỉnh/Huyện/Đơn vị', dataIndex: 'name', key: 'name', width: 300 },
		{
			title: 'Số lượng phương tiện',
			children: [
				{ align: 'right' as const, title: 'Xe 3m3', dataIndex: 'Xe3m3Count', key: 'Xe3m3Count' },
				{ align: 'right' as const, title: 'Xe 5m3', dataIndex: 'Xe5m3Count', key: 'Xe5m3Count' },
				{ align: 'right' as const, title: 'Xe bơm', dataIndex: 'XeBomCount', key: 'XeBomCount' },
				{ align: 'right' as const, title: 'Xe bọt', dataIndex: 'XeBotCount', key: 'XeBotCount' },
				{ align: 'right' as const, title: 'Xe máy', dataIndex: 'XeMayCount', key: 'XeMayCount' },
				{ align: 'right' as const, title: 'Xe thang', dataIndex: 'XeThangCount', key: 'XeThangCount' },
			],
		},
		{
			title: 'Tỉ lệ % theo tình trạng xe',
			children: [
				{
					align: 'right' as const,
					title: 'Tốt',
					dataIndex: 'GoodPercent',
					key: 'GoodPercent',
					render: (value: number) => (typeof value === 'number' ? `${value.toFixed(2)}%` : null),
				},
				{
					align: 'right' as const,
					title: 'Kém',
					dataIndex: 'BadPercent',
					key: 'BadPercent',
					render: (value: number) => (typeof value === 'number' ? `${value.toFixed(2)}%` : null),
				},
				{
					align: 'right' as const,
					title: 'Cần sửa chữa, bảo dưỡng',
					dataIndex: 'NeedRepairPercent',
					key: 'NeedRepairPercent',
					render: (value: number) => (typeof value === 'number' ? `${value.toFixed(2)}%` : null),
				},
				{
					align: 'right' as const,
					title: 'Cần thay thế',
					dataIndex: 'NeedReplacePercent',
					key: 'NeedReplacePercent',
					render: (value: number) => (typeof value === 'number' ? `${value.toFixed(2)}%` : null),
				},
			],
		},
	];

	const truckDetailReportColums: ColumnsType<any> = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{ title: 'Tỉnh thành', dataIndex: 'TinhThanh', key: 'TinhThanh', width: 200 },
		{
			title: 'Đơn vị quản lý',
			dataIndex: 'PcccUnitName',
			key: 'PcccUnitName',
			width: 300,
			render: (PcccUnitName: string) => <span className="text-detail-info">{PcccUnitName}</span>,
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record.PcccUnitName ? record.PcccUnitName : null,
					<DialogThemMoiDonViPccc userInfo={props.userInfo} id={record.DonVi} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{ title: 'Tên phương tiện', dataIndex: 'TenPhuongTien', key: 'TenPhuongTien', width: 150 },
		{ title: 'Biển số', dataIndex: 'BienSo', key: 'BienSo', width: 200 },
		{
			title: 'Phân loại phương tiện',
			dataIndex: 'LoaiPhuongTien',
			key: 'LoaiPhuongTien',
			width: 200,
			render: (LoaiPhuongTien: string) => LoaiPhuongTien,
		},
		{
			title: 'Ngày đưa vào sử dụng',
			dataIndex: 'UsedDate',
			key: 'UsedDate',
			width: 200,
			render: date => (date ? formatDate(date) : null),
		},
		{
			title: 'Ngày hết hạn',
			dataIndex: 'ExpireDate',
			key: 'ExpireDate',
			width: 200,
			render: date => (date ? formatDate(date) : null),
		},
		{ title: 'Nhân sự quản lý', dataIndex: 'Manager', key: 'Manager', width: 200 },
		{
			title: 'Tình trạng',
			dataIndex: 'TinhTrangHoatDong',
			key: 'TinhTrangHoatDong',
			width: 150,
			render: (TinhTrangHoatDong: TrangThaiHoatDongEnum) =>
				TinhTrangHoatDong && trangThaiHoatDongEnum.fromEnum(TinhTrangHoatDong).label,
		},
		{ title: 'Ghi chú', dataIndex: 'Note', key: 'Note', width: 200 },
	];

	const getRequestBody = () => {
		return {
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh') ? form.getFieldValue('TinhThanh') : tinhThanh,
				QuanHuyen: form.getFieldValue('QuanHuyen') ? form.getFieldValue('QuanHuyen') : quanHuyen,
			},
		};
	};

	const convertToSummaryDataSources = async (data: FireTruckStatus) => {
		const { Location, Children } = data;
		let province: Province | undefined;
		if (Location) {
			province = await ProvinceService.getProvince(Location.CityId);
		}
		const newParentData = await Promise.all(
			Children.map(async data => {
				const { Location, Children } = data;
				let district: District | undefined;
				if (Location) {
					district = await ProvinceService.getDistrict({
						cityId: Location.CityId,
						districtId: Location.DistrictId,
					});
				}
				const newChidrenData = Children.map(item => ({ ...item, name: item.PcccUnitName }));

				return {
					...data,
					Id: generateUniqueId(),
					name: district?.Name ? district.Name : 'PC07',
					...(newChidrenData.length > 0 && { children: newChidrenData }),
				};
			}),
		);

		return {
			...data,
			Id: generateUniqueId(),
			name: province?.Name,
			children: newParentData,
		};
	};

	const convertToDetailDataSource = async (data: FireTruckDetail) => {
		const {
			Location: { TinhThanh },
		} = data;

		const province = await ProvinceService.getProvince(TinhThanh);

		return {
			...data,
			TinhThanh: province?.Name,
		};
	};

	getFireTruckReport.current = async (
		page = currentPage,
		pageSize = pageSizeDefault,
		tab = currentTab,
		resetParam = false,
	) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);

		const requestBody = getRequestBody();

		if (resetParam && !form.getFieldValue('TinhThanh')) {
			requestBody.Location.TinhThanh = undefined;
			requestBody.Location.QuanHuyen = undefined;
		}
		setDisableExportButton(!requestBody.Location.TinhThanh);

		const isSummaryTab = tab === SUMMARY_TAB;

		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};

		try {
			const { Data, TotalItems } = isSummaryTab
				? await ReportFireTruckService.searchDataFireTruckStatus(requestBody, pageOption)
				: await ReportFireTruckService.searchDataFireTruckDetail(requestBody, pageOption);

			if (Data && Data.length > 0) {
				const dataTranformed = isSummaryTab
					? (Data as FireTruckStatus[]).map(async item => await convertToSummaryDataSources(item))
					: (Data as FireTruckDetail[]).map(async item => await convertToDetailDataSource(item));

				const newDataSources = await Promise.all(dataTranformed);

				setDataSources(newDataSources);
			} else {
				setDataSources([]);
			}

			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}
		getFireTruckReport.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});
		getFireTruckReport.current(CURRENT_PAGE);
	};

	const handleExportFileExcel = () => {
		const isSummaryTab = currentTab === SUMMARY_TAB;
		const exportFilePayload = getRequestBody();
		ReportFireTruckService.exportFileExcel(exportFilePayload, isSummaryTab ? EXPORT_SUMMARY_URL : EXPORT_DETAIL_URL)
			.then(data => {
				const fileName = isSummaryTab ? 'Fire_Truck_Summary_Report.xlsx' : 'Fire_Truck_Detail_Report.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	};

	const handleChangePage = (page: number, pageSize: number) => {
		getFireTruckReport.current(page, pageSize, currentTab);
	};

	const handleChangeTab = (tab: string) => {
		setCurrentTab(tab);
		setTinhThanh(undefined);
		setQuanHuyen(undefined);
		getFireTruckReport.current(CURRENT_PAGE, PAGE_SIZE, tab, true);
	};

	useEffect(() => {
		if (searchParams.get('tab') === QuanLyBaoCaoTab.TRANG_THAI_PHUONG_TIEN_PCCC) {
			document.title = t('quanLyBaoCao.baoCaoTrangThaiPhuongTienPCCC');
			getFireTruckReport.current();
		}
	}, [searchParams]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={handleChangeDistrict}
				/>

				<UnitManagerSelect
					name="PcccUnitId"
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					location={location}
					onChangeUnitManager={() => getFireTruckReport.current(CURRENT_PAGE)}
				/>
			</div>

			{props.permissionList.includes(PermissionEnum.REPORT_FIRE_TRUCK_EXPORT) ||
			props.permissionList.includes(PermissionEnum.ADMIN) ? (
				<Button
					type="primary"
					icon={<DownloadOutlined />}
					onClick={handleExportFileExcel}
					disabled={dataSources.length === 0 || disableExportButton}>
					Xuất Excel
				</Button>
			) : null}
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> {currentTab === '1' ? 'tỉnh thành' : 'phương tiện'}
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={totalItems}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				setCurrentTab(currentTab === SUMMARY_TAB ? DETAIL_TAB : SUMMARY_TAB);
				setTinhThanh(record.Location?.CityId);
				setQuanHuyen(record.Location?.DistrictId);
				const requestBody = {
					Location: {
						TinhThanh: record.Location?.CityId,
						QuanHuyen: record.Location?.DistrictId,
					},
				};

				const pageOption = {
					CurrentPage: currentPage,
					PageSize: pageSizeDefault,
					Paging: true,
				};

				try {
					const { Data, TotalItems } = await ReportFireTruckService.searchDataFireTruckDetail(requestBody, pageOption);

					if (Data && Data.length > 0) {
						const dataTranformed = (Data as FireTruckDetail[]).map(async item => await convertToDetailDataSource(item));
						const newDataSources = await Promise.all(dataTranformed);
						setDataSources(newDataSources);
					} else {
						setDataSources([]);
					}
					setTotalItems(TotalItems!);
				} catch (error) {}
			},
		};
	};

	const formTable = (
		<Tabs defaultActiveKey={SUMMARY_TAB} activeKey={currentTab} onTabClick={handleChangeTab}>
			<Tabs.TabPane tab="Báo cáo tổng hợp" key={SUMMARY_TAB}>
				<TableComponent
					containerClass="mt20 fire-truck-report-table-wrapper"
					dataSource={dataSources}
					columns={truckSummaryReportColumns}
					paginator={false}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					onRow={handleShowViewDetail}
					height={TABLE_HEIGHT}
					bordered={true}
				/>
			</Tabs.TabPane>
			<Tabs.TabPane tab="Báo cáo chi tiết" key={DETAIL_TAB}>
				<TableComponent
					containerClass="mt20"
					dataSource={dataSources}
					columns={truckDetailReportColums}
					paginator={false}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
				/>
			</Tabs.TabPane>
		</Tabs>
	);

	return (
		<PageWrapper titlePage="Báo cáo về phương tiện PCCC" formAction={formAction} formTable={formTable} form={form} />
	);
};

export default PhuongTien;
