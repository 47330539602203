import { t } from 'i18next';

export enum PermissionEnum {
	//admin
	ADMIN = 999,

	// Thông tin cá nhân
	PERSONAL_ACCOUNT_VIEW = 1,
	PERSONAL_ACCOUNT_CREATE,
	PERSONAL_ACCOUNT_EDIT,
	PERSONAL_ACCOUNT_DELETE,

	// Quản lý người dùng
	USER_ACCOUNT_VIEW,
	USER_ACCOUNT_CREATE,
	USER_ACCOUNT_EDIT,
	USER_ACCOUNT_DELETE,

	// Quản lý lịch sử người dùng: ls đăng nhập, ls thao tác
	USER_HISTORY_VIEW,
	USER_HISTORY_CREATE,
	USER_HISTORY_EDIT,
	USER_HISTORY_DELETE,

	// Quản lý danh mục
	CATEGORY_VIEW,
	CATEGORY_CREATE,
	CATEGORY_EDIT,
	CATEGORY_DELETE,

	// Giám sát trực tuyến
	MONITORING_DASHBOARD_VIEW,
	MONITORING_HANDLER_FROM_DEVICE, // Xử lý cảnh báo cháy từ thiết bị
	MONITORING_CREATE_ALERT, // Tạo điểm cháy
	MONITORING_CREATE_CNCH, // Tạo điểm CNCH

	// Quản lý phương tiện
	FIRE_TRUCK_VIEW,
	FIRE_TRUCK_CREATE,
	FIRE_TRUCK_EDIT,
	FIRE_TRUCK_DELETE,

	// Quản lý lính cứu hỏa
	FIRE_FIGHTER_VIEW,
	FIRE_FIGHTER_CREATE,
	FIRE_FIGHTER_EDIT,
	FIRE_FIGHTER_DELETE,
	FIRE_FIGHTER_EXPORT,

	// Quản lý đơn vị hỗ trợ
	SUPPORT_UNIT_VIEW,
	SUPPORT_UNIT_CREATE,
	SUPPORT_UNIT_EDIT,
	SUPPORT_UNIT_DELETE,

	// Quản lý điểm lấy nước
	WATER_POINT_VIEW,
	WATER_POINT_CREATE,
	WATER_POINT_EDIT,
	WATER_POINT_DELETE,

	// Cơ sở công trình
	CONSTRUCTION_VIEW,
	CONSTRUCTION_CREATE,
	CONSTRUCTION_EDIT,
	CONSTRUCTION_DELETE,

	// Kiểm tra CSCT
	CONSTRUCTION_CHECKING_VIEW,
	CONSTRUCTION_CHECKING_CREATE,
	CONSTRUCTION_CHECKING_EDIT,
	CONSTRUCTION_CHECKING_DELETE,

	// Kiểm tra ke hoach CSCT
	CONSTRUCTION_CHECKING_PLAN_VIEW,
	CONSTRUCTION_CHECKING_PLAN_CREATE,
	CONSTRUCTION_CHECKING_PLAN_EDIT,
	CONSTRUCTION_CHECKING_PLAN_DELETE,

	// Thẩm duyệt, nghiệm thu PCCC & CNCH
	CONSTRUCTION_APPROVAL_VIEW,
	CONSTRUCTION_APPROVAL_CREATE,
	CONSTRUCTION_APPROVAL_EDIT,
	CONSTRUCTION_APPROVAL_DELETE,

	// Công tác tuyên truyền, huấn luyện
	PROPAGAE_VIEW,
	PROPAGAE_CREATE,
	PROPAGAE_EDIT,
	PROPAGAE_DELETE,

	// Công tác kiểm định phương tiện PCCC & CNCH

	// Xử lý vi phạm
	VIOLATION_VIEW,
	VIOLATION_CREATE,
	VIOLATION_EDIT,
	VIOLATION_DELETE,

	// Quản lý cơ sở kinh doanh PCCC

	// Lịch trực
	DUTY_SCHEDULE_VIEW,
	DUTY_SCHEDULE_CREATE,
	DUTY_SCHEDULE_EDIT,
	DUTY_SCHEDULE_DELETE,

	// Đăng ký Lịch trực
	DUTY_REGIST_VIEW,
	DUTY_REGIST_CREATE,
	DUTY_REGIST_EDIT,
	DUTY_REGIST_DELETE,

	// Quản lý công tác xây dựng và thực tập phương án chữa cháy, CNCH
	PLANING_VIEW,
	PLANING_CREATE,
	PLANING_EDIT,
	PLANING_DELETE,

	// Quản lý công tác chữa cháy
	FIRE_PROTECTION_VIEW,
	FIRE_PROTECTION_CREATE,
	FIRE_PROTECTION_EDIT,
	FIRE_PROTECTION_DELETE,
	FIRE_PROTECTION_EXPORT,

	// Quản lý cứu nạn cứu hộ
	RESCUE_WORK_VIEW,
	RESCUE_WORK_CREATE,
	RESCUE_WORK_EDIT,
	RESCUE_WORK_DELETE,
	RESCUE_WORK_EXPORT,

	// Quản lý thiết bị
	DEVICE_VIEW,
	DEVICE_CREATE,
	DEVICE_EDIT,
	DEVICE_DELETE,

	// Quản lý Khach hang
	CUSTOMER_VIEW,
	CUSTOMER_CREATE,
	CUSTOMER_EDIT,
	CUSTOMER_DELETE,

	// Quản lý certificate
	CERTIFICATE_VIEW,
	CERTIFICATE_CREATE,
	CERTIFICATE_EDIT,
	CERTIFICATE_DELETE,

	// Báo cáo
	// REPORT_KIEM_TRA_AT_PCCC_VIEW,
	// REPORT_KIEM_TRA_AT_PCCC_EXPORT,

	REPORT_NGHIEM_THU_PHE_DUYET_VIEW,
	REPORT_NGHIEM_THU_PHE_DUYET_EXPORT,

	REPORT_CO_SO_CONG_TRINH_VIEW,
	REPORT_CO_SO_CONG_TRINH_EXPORT,

	REPORT_CONG_TAC_CHUA_CHAY_VIEW,
	REPORT_CONG_TAC_CHUA_CHAY_EXPORT,

	REPORT_CNCH_VIEW,
	REPORT_CNCH_EXPORT,

	REPORT_FIRE_TRUCK_VIEW,
	REPORT_FIRE_TRUCK_EXPORT,

	REPORT_DEVICE_VIEW,
	REPORT_DEVICE_EXPORT,
	DASHBOARD_VIEW,

	NOTICE_VIEW,
	NOTICE_CREATE,
	NOTICE_EDIT,
	NOTICE_DELETE,
	// Quản lý sản xuất
	FACT_VIEW,
	FACT_CREATE,
	FACT_EDIT,
	FACT_DELETE,
	FACT_REPORT_VIEW,

	// Quản lý đơn vị PCCC
	PCCC_UNIT_VIEW,
	PCCC_UNIT_CREATE,
	PCCC_UNIT_EDIT,
	PCCC_UNIT_DELETE,

	// Quản lý phân quyền
	ROLE_ACCOUNT_VIEW,
	ROLE_ACCOUNT_CREATE,
	ROLE_ACCOUNT_EDIT,
	ROLE_ACCOUNT_DELETE,

	// Quản lý file
	FILE_VIEW,
	FILE_CREATE,
	FILE_EDIT,
	FILE_DELETE,

	// Adds
	FIRE_TRUCK_EXPORT,
	SUPPORT_UNIT_EXPORT,
	WATER_POINT_EXPORT,
	CONSTRUCTION_EXPORT,
	// Quản lý giám sát trực tuyến
	MONITORING_DASHBOARD_MANAGER,
	MONITORING_DASHBOARD_DELETE,

	MANAGER_CUSTOMER_BY_LOCATION,

	// Quản lý đơn lắp đặt
	DEVICE_INSTALLATION_VIEW,
	DEVICE_INSTALLATION_CREATE,
	DEVICE_INSTALLATION_EDIT,
	DEVICE_INSTALLATION_DELETE,

	// Bảo hành
	INSURANCE_VIEW,
	INSURANCE_CREATE,
	INSURANCE_EDIT,
	INSURANCE_DELETE,

	// Thanh toán
	PAYMENT_VIEW,
	PAYMENT_CREATE,
	PAYMENT_EDIT,
	PAYMENT_DELETE,

	DISABLE_NOTICE,
	// Quyền confirm vụ cháy cho admin
	ADMIN_CONFIRM_FIRING,
}

export const DashBoardArray = [PermissionEnum.ADMIN, PermissionEnum.DASHBOARD_VIEW];

export const FactArray = [
	PermissionEnum.ADMIN,
	PermissionEnum.FACT_VIEW,
	PermissionEnum.FACT_CREATE,
	PermissionEnum.FACT_EDIT,
	PermissionEnum.FACT_DELETE,
	PermissionEnum.FACT_REPORT_VIEW,
];

export const AccountUserArray = [
	PermissionEnum.ADMIN,
	// Quản lý người dùng
	PermissionEnum.USER_ACCOUNT_VIEW,
	PermissionEnum.USER_ACCOUNT_CREATE,
	PermissionEnum.USER_ACCOUNT_EDIT,
	PermissionEnum.USER_ACCOUNT_DELETE,
	PermissionEnum.USER_HISTORY_VIEW,
	PermissionEnum.USER_HISTORY_EDIT,
	PermissionEnum.USER_HISTORY_DELETE,
	PermissionEnum.CUSTOMER_VIEW,
	PermissionEnum.CUSTOMER_EDIT,
	PermissionEnum.CUSTOMER_DELETE,
];

export const AccountGiamSatTrucTuyenArray = [
	PermissionEnum.ADMIN,
	// Giám sát trực tuyến
	PermissionEnum.MONITORING_DASHBOARD_VIEW,
	PermissionEnum.MONITORING_DASHBOARD_MANAGER,
	PermissionEnum.MONITORING_DASHBOARD_DELETE,
];

export const DonViPhongChayArray = [
	PermissionEnum.ADMIN,
	// Quản đơn vị PCCC
	// Quản lý cán bộ, chiến sĩ
	// PermissionEnum.CBCS_VIEW,
	// PermissionEnum.CBCS_EDIT,
	// PermissionEnum.CBCS_DELETE,
	// Quản lý phương tiện
	PermissionEnum.FIRE_TRUCK_VIEW,
	PermissionEnum.FIRE_TRUCK_CREATE,
	PermissionEnum.FIRE_TRUCK_EDIT,
	PermissionEnum.FIRE_TRUCK_DELETE,
	// Quản lý lính cứu hỏa
	PermissionEnum.FIRE_FIGHTER_VIEW,
	PermissionEnum.FIRE_FIGHTER_CREATE,
	PermissionEnum.FIRE_FIGHTER_EDIT,
	PermissionEnum.FIRE_FIGHTER_DELETE,

	// Quản lý đơn vị hỗ trợ
	PermissionEnum.SUPPORT_UNIT_VIEW,
	PermissionEnum.SUPPORT_UNIT_EDIT,
	PermissionEnum.SUPPORT_UNIT_CREATE,
	PermissionEnum.SUPPORT_UNIT_DELETE,

	// Quản lý điểm lấy nước
	PermissionEnum.WATER_POINT_VIEW,
	PermissionEnum.WATER_POINT_CREATE,
	PermissionEnum.WATER_POINT_EDIT,
	PermissionEnum.WATER_POINT_DELETE,

	// Quản lý đơn vị PCCC
	PermissionEnum.PCCC_UNIT_VIEW,
	PermissionEnum.PCCC_UNIT_CREATE,
	PermissionEnum.PCCC_UNIT_EDIT,
	PermissionEnum.PCCC_UNIT_DELETE,
];

export const CongTacPhongChayArray = [
	PermissionEnum.ADMIN,
	// Cơ sở công trình
	PermissionEnum.CONSTRUCTION_VIEW,
	PermissionEnum.CONSTRUCTION_CREATE,
	PermissionEnum.CONSTRUCTION_EDIT,
	PermissionEnum.CONSTRUCTION_DELETE,

	// Kiểm tra CSCT
	PermissionEnum.CONSTRUCTION_CHECKING_VIEW,
	PermissionEnum.CONSTRUCTION_CHECKING_CREATE,
	PermissionEnum.CONSTRUCTION_CHECKING_EDIT,
	PermissionEnum.CONSTRUCTION_CHECKING_DELETE,

	// Kiểm tra ke hoach CSCT
	PermissionEnum.CONSTRUCTION_CHECKING_PLAN_VIEW,
	PermissionEnum.CONSTRUCTION_CHECKING_PLAN_CREATE,
	PermissionEnum.CONSTRUCTION_CHECKING_PLAN_EDIT,
	PermissionEnum.CONSTRUCTION_CHECKING_PLAN_DELETE,

	// Thẩm duyệt, nghiệm thu PCCC & CNCH
	PermissionEnum.CONSTRUCTION_APPROVAL_VIEW,
	PermissionEnum.CONSTRUCTION_APPROVAL_CREATE,
	PermissionEnum.CONSTRUCTION_APPROVAL_EDIT,
	PermissionEnum.CONSTRUCTION_APPROVAL_DELETE,

	// Công tác tuyên truyền, huấn luyện
	PermissionEnum.PROPAGAE_VIEW,
	PermissionEnum.PROPAGAE_CREATE,
	PermissionEnum.PROPAGAE_EDIT,
	PermissionEnum.PROPAGAE_DELETE,

	// Công tác kiểm định phương tiện PCCC & CNCH

	// Xử lý vi phạm
	PermissionEnum.VIOLATION_VIEW,
	PermissionEnum.VIOLATION_CREATE,
	PermissionEnum.VIOLATION_EDIT,
	PermissionEnum.VIOLATION_DELETE,
];

export const CongTacChuaChayArray = [
	PermissionEnum.ADMIN,
	// Quản lý người dùng
	// Quản lý công tác xây dựng và thực tập phương án chữa cháy, CNCH
	PermissionEnum.PLANING_VIEW,
	PermissionEnum.PLANING_CREATE,
	PermissionEnum.PLANING_EDIT,
	PermissionEnum.PLANING_DELETE,

	// Quản lý công tác chữa cháy
	PermissionEnum.FIRE_PROTECTION_VIEW,
	PermissionEnum.FIRE_PROTECTION_CREATE,
	PermissionEnum.FIRE_PROTECTION_EDIT,
	PermissionEnum.FIRE_PROTECTION_DELETE,

	// Quản lý cứu nạn cứu hộ
	PermissionEnum.RESCUE_WORK_VIEW,
	PermissionEnum.RESCUE_WORK_CREATE,
	PermissionEnum.RESCUE_WORK_EDIT,
	PermissionEnum.RESCUE_WORK_DELETE,

	// Lịch trực
	PermissionEnum.DUTY_SCHEDULE_VIEW,
	PermissionEnum.DUTY_SCHEDULE_CREATE,
	PermissionEnum.DUTY_SCHEDULE_EDIT,
	PermissionEnum.DUTY_SCHEDULE_DELETE,

	// Đăng ký Lịch trực
	PermissionEnum.DUTY_REGIST_VIEW,
	PermissionEnum.DUTY_REGIST_CREATE,
	PermissionEnum.DUTY_REGIST_EDIT,
	PermissionEnum.DUTY_REGIST_DELETE,
];

export const XuLyViPhamArray = [
	PermissionEnum.ADMIN,
	// Xử lý vi phạm
	PermissionEnum.VIOLATION_VIEW,
	PermissionEnum.VIOLATION_CREATE,
	PermissionEnum.VIOLATION_EDIT,
	PermissionEnum.VIOLATION_DELETE,
];

export const ThietBiArray = [
	PermissionEnum.ADMIN,
	// Quản lý thiết bị
	PermissionEnum.DEVICE_VIEW,
	PermissionEnum.DEVICE_CREATE,
	PermissionEnum.DEVICE_EDIT,
	PermissionEnum.DEVICE_DELETE,
];

export const BaoCaoArray = [
	PermissionEnum.ADMIN,
	// Báo cáo
	// PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_VIEW,
	// PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_EXPORT,

	PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_VIEW,
	PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_EXPORT,

	PermissionEnum.REPORT_CO_SO_CONG_TRINH_VIEW,
	PermissionEnum.REPORT_CO_SO_CONG_TRINH_EXPORT,

	PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_VIEW,
	PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_EXPORT,

	PermissionEnum.REPORT_CNCH_VIEW,
	PermissionEnum.REPORT_CNCH_EXPORT,

	PermissionEnum.REPORT_FIRE_TRUCK_VIEW,
	PermissionEnum.REPORT_FIRE_TRUCK_EXPORT,

	PermissionEnum.REPORT_DEVICE_VIEW,
	PermissionEnum.REPORT_DEVICE_EXPORT,
];

interface Permission {
	value: PermissionEnum;
	label: string;
}

const _fromEnum = (trangThaiValue: PermissionEnum): Permission => {
	switch (trangThaiValue) {
		case PermissionEnum.ADMIN:
			return {
				label: t('permission.ADMIN'),
				value: PermissionEnum.ADMIN,
			};
		case PermissionEnum.PERSONAL_ACCOUNT_VIEW:
			return {
				label: t('permission.PERSONAL_ACCOUNT_VIEW'),
				value: PermissionEnum.PERSONAL_ACCOUNT_VIEW,
			};
		case PermissionEnum.PERSONAL_ACCOUNT_CREATE:
			return {
				label: t('permission.PERSONAL_ACCOUNT_CREATE'),
				value: PermissionEnum.PERSONAL_ACCOUNT_CREATE,
			};
		case PermissionEnum.PERSONAL_ACCOUNT_EDIT:
			return {
				label: t('permission.PERSONAL_ACCOUNT_EDIT'),
				value: PermissionEnum.PERSONAL_ACCOUNT_EDIT,
			};
		case PermissionEnum.PERSONAL_ACCOUNT_DELETE:
			return {
				label: t('permission.PERSONAL_ACCOUNT_DELETE'),
				value: PermissionEnum.PERSONAL_ACCOUNT_DELETE,
			};
		case PermissionEnum.USER_ACCOUNT_VIEW:
			return {
				label: t('permission.USER_ACCOUNT_VIEW'),
				value: PermissionEnum.USER_ACCOUNT_VIEW,
			};
		case PermissionEnum.USER_ACCOUNT_CREATE:
			return {
				label: t('permission.USER_ACCOUNT_CREATE'),
				value: PermissionEnum.USER_ACCOUNT_CREATE,
			};

		case PermissionEnum.USER_ACCOUNT_EDIT:
			return {
				label: t('permission.USER_ACCOUNT_EDIT'),
				value: PermissionEnum.USER_ACCOUNT_EDIT,
			};
		case PermissionEnum.USER_ACCOUNT_DELETE:
			return {
				label: t('permission.USER_ACCOUNT_DELETE'),
				value: PermissionEnum.USER_ACCOUNT_DELETE,
			};
		case PermissionEnum.USER_HISTORY_VIEW:
			return {
				label: t('permission.USER_HISTORY_VIEW'),
				value: PermissionEnum.USER_HISTORY_VIEW,
			};
		case PermissionEnum.USER_HISTORY_CREATE:
			return {
				label: t('permission.USER_HISTORY_CREATE'),
				value: PermissionEnum.USER_HISTORY_CREATE,
			};
		case PermissionEnum.USER_HISTORY_EDIT:
			return {
				label: t('permission.USER_HISTORY_EDIT'),
				value: PermissionEnum.USER_HISTORY_EDIT,
			};
		case PermissionEnum.USER_HISTORY_DELETE:
			return {
				label: t('permission.USER_HISTORY_DELETE'),
				value: PermissionEnum.USER_HISTORY_DELETE,
			};
		case PermissionEnum.CATEGORY_VIEW:
			return {
				label: t('permission.CATEGORY_VIEW'),
				value: PermissionEnum.CATEGORY_VIEW,
			};
		case PermissionEnum.CATEGORY_CREATE:
			return {
				label: t('permission.CATEGORY_CREATE'),
				value: PermissionEnum.CATEGORY_CREATE,
			};

		case PermissionEnum.CATEGORY_EDIT:
			return {
				label: t('permission.CATEGORY_EDIT'),
				value: PermissionEnum.CATEGORY_EDIT,
			};
		case PermissionEnum.CATEGORY_DELETE:
			return {
				label: t('permission.CATEGORY_DELETE'),
				value: PermissionEnum.CATEGORY_DELETE,
			};
		case PermissionEnum.MONITORING_DASHBOARD_VIEW:
			return {
				label: t('permission.MONITORING_DASHBOARD_VIEW'),
				value: PermissionEnum.MONITORING_DASHBOARD_VIEW,
			};
		case PermissionEnum.MONITORING_DASHBOARD_MANAGER:
			return {
				label: t('permission.MONITORING_DASHBOARD_MANAGER'),
				value: PermissionEnum.MONITORING_DASHBOARD_MANAGER,
			};
		case PermissionEnum.MONITORING_DASHBOARD_DELETE:
			return {
				label: t('permission.MONITORING_DASHBOARD_DELETE'),
				value: PermissionEnum.MONITORING_DASHBOARD_DELETE,
			};
		case PermissionEnum.MANAGER_CUSTOMER_BY_LOCATION:
			return {
				label: t('permission.MANAGER_CUSTOMER_BY_LOCATION'),
				value: PermissionEnum.MANAGER_CUSTOMER_BY_LOCATION,
			};
		case PermissionEnum.MONITORING_HANDLER_FROM_DEVICE:
			return {
				label: t('permission.MONITORING_HANDLER_FROM_DEVICE'),
				value: PermissionEnum.MONITORING_HANDLER_FROM_DEVICE,
			};
		case PermissionEnum.MONITORING_CREATE_ALERT:
			return {
				label: t('permission.MONITORING_CREATE_ALERT'),
				value: PermissionEnum.MONITORING_CREATE_ALERT,
			};
		case PermissionEnum.MONITORING_CREATE_CNCH:
			return {
				label: t('permission.MONITORING_CREATE_CNCH'),
				value: PermissionEnum.MONITORING_CREATE_CNCH,
			};
		case PermissionEnum.FIRE_TRUCK_VIEW:
			return {
				label: t('permission.FIRE_TRUCK_VIEW'),
				value: PermissionEnum.FIRE_TRUCK_VIEW,
			};
		case PermissionEnum.FIRE_TRUCK_CREATE:
			return {
				label: t('permission.FIRE_TRUCK_CREATE'),
				value: PermissionEnum.FIRE_TRUCK_CREATE,
			};
		case PermissionEnum.FIRE_TRUCK_EDIT:
			return {
				label: t('permission.FIRE_TRUCK_EDIT'),
				value: PermissionEnum.FIRE_TRUCK_EDIT,
			};
		case PermissionEnum.FIRE_TRUCK_DELETE:
			return {
				label: t('permission.FIRE_TRUCK_DELETE'),
				value: PermissionEnum.FIRE_TRUCK_DELETE,
			};
		case PermissionEnum.FIRE_TRUCK_EXPORT:
			return {
				label: t('permission.FIRE_TRUCK_EXPORT'),
				value: PermissionEnum.FIRE_TRUCK_EXPORT,
			};
		case PermissionEnum.FIRE_FIGHTER_VIEW:
			return {
				label: t('permission.FIRE_FIGHTER_VIEW'),
				value: PermissionEnum.FIRE_FIGHTER_VIEW,
			};
		case PermissionEnum.FIRE_FIGHTER_CREATE:
			return {
				label: t('permission.FIRE_FIGHTER_CREATE'),
				value: PermissionEnum.FIRE_FIGHTER_CREATE,
			};

		case PermissionEnum.FIRE_FIGHTER_EDIT:
			return {
				label: t('permission.FIRE_FIGHTER_EDIT'),
				value: PermissionEnum.FIRE_FIGHTER_EDIT,
			};
		case PermissionEnum.FIRE_FIGHTER_DELETE:
			return {
				label: t('permission.FIRE_FIGHTER_DELETE'),
				value: PermissionEnum.FIRE_FIGHTER_DELETE,
			};
		case PermissionEnum.FIRE_FIGHTER_EXPORT:
			return {
				label: t('permission.FIRE_FIGHTER_EXPORT'),
				value: PermissionEnum.FIRE_FIGHTER_EXPORT,
			};
		case PermissionEnum.SUPPORT_UNIT_VIEW:
			return {
				label: t('permission.SUPPORT_UNIT_VIEW'),
				value: PermissionEnum.SUPPORT_UNIT_VIEW,
			};
		case PermissionEnum.SUPPORT_UNIT_CREATE:
			return {
				label: t('permission.SUPPORT_UNIT_CREATE'),
				value: PermissionEnum.SUPPORT_UNIT_CREATE,
			};

		case PermissionEnum.SUPPORT_UNIT_EDIT:
			return {
				label: t('permission.SUPPORT_UNIT_EDIT'),
				value: PermissionEnum.SUPPORT_UNIT_EDIT,
			};
		case PermissionEnum.SUPPORT_UNIT_DELETE:
			return {
				label: t('permission.SUPPORT_UNIT_DELETE'),
				value: PermissionEnum.SUPPORT_UNIT_DELETE,
			};
		case PermissionEnum.SUPPORT_UNIT_EXPORT:
			return {
				label: t('permission.SUPPORT_UNIT_EXPORT'),
				value: PermissionEnum.SUPPORT_UNIT_EXPORT,
			};
		case PermissionEnum.WATER_POINT_VIEW:
			return {
				label: t('permission.WATER_POINT_VIEW'),
				value: PermissionEnum.WATER_POINT_VIEW,
			};
		case PermissionEnum.WATER_POINT_CREATE:
			return {
				label: t('permission.WATER_POINT_CREATE'),
				value: PermissionEnum.WATER_POINT_CREATE,
			};
		case PermissionEnum.WATER_POINT_EDIT:
			return {
				label: t('permission.WATER_POINT_EDIT'),
				value: PermissionEnum.WATER_POINT_EDIT,
			};
		case PermissionEnum.WATER_POINT_DELETE:
			return {
				label: t('permission.WATER_POINT_DELETE'),
				value: PermissionEnum.WATER_POINT_DELETE,
			};
		case PermissionEnum.WATER_POINT_EXPORT:
			return {
				label: t('permission.WATER_POINT_EXPORT'),
				value: PermissionEnum.WATER_POINT_EXPORT,
			};
		case PermissionEnum.CONSTRUCTION_VIEW:
			return {
				label: t('permission.CONSTRUCTION_VIEW'),
				value: PermissionEnum.CONSTRUCTION_VIEW,
			};
		case PermissionEnum.CONSTRUCTION_CREATE:
			return {
				label: t('permission.CONSTRUCTION_CREATE'),
				value: PermissionEnum.CONSTRUCTION_CREATE,
			};
		case PermissionEnum.CONSTRUCTION_EDIT:
			return {
				label: t('permission.CONSTRUCTION_EDIT'),
				value: PermissionEnum.CONSTRUCTION_EDIT,
			};
		case PermissionEnum.CONSTRUCTION_DELETE:
			return {
				label: t('permission.CONSTRUCTION_DELETE'),
				value: PermissionEnum.CONSTRUCTION_DELETE,
			};
		case PermissionEnum.CONSTRUCTION_EXPORT:
			return {
				label: t('permission.CONSTRUCTION_EXPORT'),
				value: PermissionEnum.CONSTRUCTION_EXPORT,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_VIEW:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_VIEW'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_VIEW,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_CREATE:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_CREATE'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_CREATE,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_EDIT:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_EDIT'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_EDIT,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_DELETE:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_DELETE'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_DELETE,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_PLAN_VIEW:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_PLAN_VIEW'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_PLAN_VIEW,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_PLAN_CREATE:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_PLAN_CREATE'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_PLAN_CREATE,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_PLAN_EDIT:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_PLAN_EDIT'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_PLAN_EDIT,
			};
		case PermissionEnum.CONSTRUCTION_CHECKING_PLAN_DELETE:
			return {
				label: t('permission.CONSTRUCTION_CHECKING_PLAN_DELETE'),
				value: PermissionEnum.CONSTRUCTION_CHECKING_PLAN_DELETE,
			};
		case PermissionEnum.CONSTRUCTION_APPROVAL_VIEW:
			return {
				label: t('permission.CONSTRUCTION_APPROVAL_VIEW'),
				value: PermissionEnum.CONSTRUCTION_APPROVAL_VIEW,
			};
		case PermissionEnum.CONSTRUCTION_APPROVAL_CREATE:
			return {
				label: t('permission.CONSTRUCTION_APPROVAL_CREATE'),
				value: PermissionEnum.CONSTRUCTION_APPROVAL_CREATE,
			};
		case PermissionEnum.CONSTRUCTION_APPROVAL_EDIT:
			return {
				label: t('permission.CONSTRUCTION_APPROVAL_EDIT'),
				value: PermissionEnum.CONSTRUCTION_APPROVAL_EDIT,
			};
		case PermissionEnum.CONSTRUCTION_APPROVAL_DELETE:
			return {
				label: t('permission.CONSTRUCTION_APPROVAL_DELETE'),
				value: PermissionEnum.CONSTRUCTION_APPROVAL_DELETE,
			};
		case PermissionEnum.PROPAGAE_VIEW:
			return {
				label: t('permission.PROPAGAE_VIEW'),
				value: PermissionEnum.PROPAGAE_VIEW,
			};
		case PermissionEnum.PROPAGAE_CREATE:
			return {
				label: t('permission.PROPAGAE_CREATE'),
				value: PermissionEnum.PROPAGAE_CREATE,
			};
		case PermissionEnum.PROPAGAE_EDIT:
			return {
				label: t('permission.PROPAGAE_EDIT'),
				value: PermissionEnum.PROPAGAE_EDIT,
			};
		case PermissionEnum.PROPAGAE_DELETE:
			return {
				label: t('permission.PROPAGAE_DELETE'),
				value: PermissionEnum.PROPAGAE_DELETE,
			};
		case PermissionEnum.VIOLATION_VIEW:
			return {
				label: t('permission.VIOLATION_VIEW'),
				value: PermissionEnum.VIOLATION_VIEW,
			};
		case PermissionEnum.VIOLATION_CREATE:
			return {
				label: t('permission.VIOLATION_CREATE'),
				value: PermissionEnum.VIOLATION_CREATE,
			};
		case PermissionEnum.VIOLATION_EDIT:
			return {
				label: t('permission.VIOLATION_EDIT'),
				value: PermissionEnum.VIOLATION_EDIT,
			};
		case PermissionEnum.VIOLATION_DELETE:
			return {
				label: t('permission.VIOLATION_DELETE'),
				value: PermissionEnum.VIOLATION_DELETE,
			};
		case PermissionEnum.DUTY_SCHEDULE_VIEW:
			return {
				label: t('permission.DUTY_SCHEDULE_VIEW'),
				value: PermissionEnum.DUTY_SCHEDULE_VIEW,
			};
		case PermissionEnum.DUTY_SCHEDULE_CREATE:
			return {
				label: t('permission.DUTY_SCHEDULE_CREATE'),
				value: PermissionEnum.DUTY_SCHEDULE_CREATE,
			};
		case PermissionEnum.DUTY_SCHEDULE_EDIT:
			return {
				label: t('permission.DUTY_SCHEDULE_EDIT'),
				value: PermissionEnum.DUTY_SCHEDULE_EDIT,
			};
		case PermissionEnum.DUTY_SCHEDULE_DELETE:
			return {
				label: t('permission.DUTY_SCHEDULE_DELETE'),
				value: PermissionEnum.DUTY_SCHEDULE_DELETE,
			};
		case PermissionEnum.DUTY_REGIST_VIEW:
			return {
				label: t('permission.DUTY_REGIST_VIEW'),
				value: PermissionEnum.DUTY_REGIST_VIEW,
			};
		case PermissionEnum.DUTY_REGIST_CREATE:
			return {
				label: t('permission.DUTY_REGIST_CREATE'),
				value: PermissionEnum.DUTY_REGIST_CREATE,
			};
		case PermissionEnum.DUTY_REGIST_EDIT:
			return {
				label: t('permission.DUTY_REGIST_EDIT'),
				value: PermissionEnum.DUTY_REGIST_EDIT,
			};
		case PermissionEnum.DUTY_REGIST_DELETE:
			return {
				label: t('permission.DUTY_REGIST_DELETE'),
				value: PermissionEnum.DUTY_REGIST_DELETE,
			};
		case PermissionEnum.PLANING_VIEW:
			return {
				label: t('permission.PLANING_VIEW'),
				value: PermissionEnum.PLANING_VIEW,
			};
		case PermissionEnum.PLANING_CREATE:
			return {
				label: t('permission.PLANING_CREATE'),
				value: PermissionEnum.PLANING_CREATE,
			};
		case PermissionEnum.PLANING_EDIT:
			return {
				label: t('permission.PLANING_EDIT'),
				value: PermissionEnum.PLANING_EDIT,
			};
		case PermissionEnum.PLANING_DELETE:
			return {
				label: t('permission.PLANING_DELETE'),
				value: PermissionEnum.PLANING_DELETE,
			};
		case PermissionEnum.FIRE_PROTECTION_VIEW:
			return {
				label: t('permission.FIRE_PROTECTION_VIEW'),
				value: PermissionEnum.FIRE_PROTECTION_VIEW,
			};
		case PermissionEnum.FIRE_PROTECTION_CREATE:
			return {
				label: t('permission.FIRE_PROTECTION_CREATE'),
				value: PermissionEnum.FIRE_PROTECTION_CREATE,
			};
		case PermissionEnum.FIRE_PROTECTION_EDIT:
			return {
				label: t('permission.FIRE_PROTECTION_EDIT'),
				value: PermissionEnum.FIRE_PROTECTION_EDIT,
			};
		case PermissionEnum.FIRE_PROTECTION_DELETE:
			return {
				label: t('permission.FIRE_PROTECTION_DELETE'),
				value: PermissionEnum.FIRE_PROTECTION_DELETE,
			};
		case PermissionEnum.FIRE_PROTECTION_EXPORT:
			return {
				label: t('permission.FIRE_PROTECTION_EXPORT'),
				value: PermissionEnum.FIRE_PROTECTION_EXPORT,
			};

		case PermissionEnum.RESCUE_WORK_VIEW:
			return {
				label: t('permission.RESCUE_WORK_VIEW'),
				value: PermissionEnum.RESCUE_WORK_VIEW,
			};
		case PermissionEnum.RESCUE_WORK_CREATE:
			return {
				label: t('permission.RESCUE_WORK_CREATE'),
				value: PermissionEnum.RESCUE_WORK_CREATE,
			};
		case PermissionEnum.RESCUE_WORK_EDIT:
			return {
				label: t('permission.RESCUE_WORK_EDIT'),
				value: PermissionEnum.RESCUE_WORK_EDIT,
			};
		case PermissionEnum.RESCUE_WORK_DELETE:
			return {
				label: t('permission.RESCUE_WORK_DELETE'),
				value: PermissionEnum.RESCUE_WORK_DELETE,
			};
		case PermissionEnum.RESCUE_WORK_EXPORT:
			return {
				label: t('permission.RESCUE_WORK_EXPORT'),
				value: PermissionEnum.RESCUE_WORK_EXPORT,
			};

		case PermissionEnum.DEVICE_VIEW:
			return {
				label: t('permission.DEVICE_VIEW'),
				value: PermissionEnum.DEVICE_VIEW,
			};
		case PermissionEnum.DEVICE_CREATE:
			return {
				label: t('permission.DEVICE_CREATE'),
				value: PermissionEnum.DEVICE_CREATE,
			};
		case PermissionEnum.DEVICE_EDIT:
			return {
				label: t('permission.DEVICE_EDIT'),
				value: PermissionEnum.DEVICE_EDIT,
			};
		case PermissionEnum.DEVICE_DELETE:
			return {
				label: t('permission.DEVICE_DELETE'),
				value: PermissionEnum.DEVICE_DELETE,
			};
		case PermissionEnum.CUSTOMER_VIEW:
			return {
				label: t('permission.CUSTOMER_VIEW'),
				value: PermissionEnum.CUSTOMER_VIEW,
			};
		case PermissionEnum.CUSTOMER_CREATE:
			return {
				label: t('permission.CUSTOMER_CREATE'),
				value: PermissionEnum.CUSTOMER_CREATE,
			};
		case PermissionEnum.CUSTOMER_EDIT:
			return {
				label: t('permission.CUSTOMER_EDIT'),
				value: PermissionEnum.CUSTOMER_EDIT,
			};
		case PermissionEnum.CUSTOMER_DELETE:
			return {
				label: t('permission.CUSTOMER_DELETE'),
				value: PermissionEnum.CUSTOMER_DELETE,
			};
		case PermissionEnum.CERTIFICATE_VIEW:
			return {
				label: t('permission.CERTIFICATE_VIEW'),
				value: PermissionEnum.CERTIFICATE_VIEW,
			};
		case PermissionEnum.CERTIFICATE_CREATE:
			return {
				label: t('permission.CERTIFICATE_CREATE'),
				value: PermissionEnum.CERTIFICATE_CREATE,
			};
		case PermissionEnum.CERTIFICATE_EDIT:
			return {
				label: t('permission.CERTIFICATE_EDIT'),
				value: PermissionEnum.CERTIFICATE_EDIT,
			};
		case PermissionEnum.CERTIFICATE_DELETE:
			return {
				label: t('permission.CERTIFICATE_DELETE'),
				value: PermissionEnum.CERTIFICATE_DELETE,
			};
		// case PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_VIEW:
		// 	return {
		// 		label: t('permission.REPORT_KIEM_TRA_AT_PCCC_VIEW'),
		// 		value: PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_VIEW,
		// 	};
		// case PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_EXPORT:
		// 	return {
		// 		label: t('permission.REPORT_KIEM_TRA_AT_PCCC_EXPORT'),
		// 		value: PermissionEnum.REPORT_KIEM_TRA_AT_PCCC_EXPORT,
		// 	};
		case PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_VIEW:
			return {
				label: t('permission.REPORT_NGHIEM_THU_PHE_DUYET_VIEW'),
				value: PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_VIEW,
			};
		case PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_EXPORT:
			return {
				label: t('permission.REPORT_NGHIEM_THU_PHE_DUYET_EXPORT'),
				value: PermissionEnum.REPORT_NGHIEM_THU_PHE_DUYET_EXPORT,
			};
		case PermissionEnum.REPORT_CO_SO_CONG_TRINH_VIEW:
			return {
				label: t('permission.REPORT_CO_SO_CONG_TRINH_VIEW'),
				value: PermissionEnum.REPORT_CO_SO_CONG_TRINH_VIEW,
			};
		case PermissionEnum.REPORT_CO_SO_CONG_TRINH_EXPORT:
			return {
				label: t('permission.REPORT_CO_SO_CONG_TRINH_EXPORT'),
				value: PermissionEnum.REPORT_CO_SO_CONG_TRINH_EXPORT,
			};
		case PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_VIEW:
			return {
				label: t('permission.REPORT_CONG_TAC_CHUA_CHAY_VIEW'),
				value: PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_VIEW,
			};
		case PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_EXPORT:
			return {
				label: t('permission.REPORT_CONG_TAC_CHUA_CHAY_EXPORT'),
				value: PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_EXPORT,
			};
		case PermissionEnum.REPORT_CNCH_VIEW:
			return {
				label: t('permission.REPORT_CNCH_VIEW'),
				value: PermissionEnum.REPORT_CNCH_VIEW,
			};
		case PermissionEnum.REPORT_CNCH_EXPORT:
			return {
				label: t('permission.REPORT_CNCH_EXPORT'),
				value: PermissionEnum.REPORT_CNCH_EXPORT,
			};
		case PermissionEnum.REPORT_FIRE_TRUCK_VIEW:
			return {
				label: t('permission.REPORT_FIRE_TRUCK_VIEW'),
				value: PermissionEnum.REPORT_FIRE_TRUCK_VIEW,
			};
		case PermissionEnum.REPORT_FIRE_TRUCK_EXPORT:
			return {
				label: t('permission.REPORT_FIRE_TRUCK_EXPORT'),
				value: PermissionEnum.REPORT_FIRE_TRUCK_EXPORT,
			};
		case PermissionEnum.REPORT_DEVICE_VIEW:
			return {
				label: t('permission.REPORT_DEVICE_VIEW'),
				value: PermissionEnum.REPORT_DEVICE_VIEW,
			};
		case PermissionEnum.REPORT_DEVICE_EXPORT:
			return {
				label: t('permission.REPORT_DEVICE_EXPORT'),
				value: PermissionEnum.REPORT_DEVICE_EXPORT,
			};
		case PermissionEnum.DASHBOARD_VIEW:
			return {
				label: t('permission.DASHBOARD_VIEW'),
				value: PermissionEnum.DASHBOARD_VIEW,
			};
		case PermissionEnum.NOTICE_VIEW:
			return {
				label: t('permission.NOTICE_VIEW'),
				value: PermissionEnum.NOTICE_VIEW,
			};
		case PermissionEnum.NOTICE_CREATE:
			return {
				label: t('permission.NOTICE_CREATE'),
				value: PermissionEnum.NOTICE_CREATE,
			};
		case PermissionEnum.NOTICE_EDIT:
			return {
				label: t('permission.NOTICE_EDIT'),
				value: PermissionEnum.NOTICE_EDIT,
			};
		case PermissionEnum.NOTICE_DELETE:
			return {
				label: t('permission.NOTICE_DELETE'),
				value: PermissionEnum.NOTICE_DELETE,
			};
		case PermissionEnum.PCCC_UNIT_VIEW:
			return {
				label: t('permission.PCCC_UNIT_VIEW'),
				value: PermissionEnum.PCCC_UNIT_VIEW,
			};
		case PermissionEnum.PCCC_UNIT_CREATE:
			return {
				label: t('permission.PCCC_UNIT_CREATE'),
				value: PermissionEnum.PCCC_UNIT_CREATE,
			};
		case PermissionEnum.PCCC_UNIT_EDIT:
			return {
				label: t('permission.PCCC_UNIT_EDIT'),
				value: PermissionEnum.PCCC_UNIT_EDIT,
			};
		case PermissionEnum.PCCC_UNIT_DELETE:
			return {
				label: t('permission.PCCC_UNIT_DELETE'),
				value: PermissionEnum.PCCC_UNIT_DELETE,
			};
		case PermissionEnum.FACT_VIEW:
			return {
				label: t('permission.FACT_VIEW'),
				value: PermissionEnum.FACT_VIEW,
			};
		case PermissionEnum.FACT_CREATE:
			return {
				label: t('permission.FACT_CREATE'),
				value: PermissionEnum.FACT_CREATE,
			};
		case PermissionEnum.FACT_EDIT:
			return {
				label: t('permission.FACT_EDIT'),
				value: PermissionEnum.FACT_EDIT,
			};
		case PermissionEnum.FACT_DELETE:
			return {
				label: t('permission.FACT_DELETE'),
				value: PermissionEnum.FACT_DELETE,
			};
		case PermissionEnum.FACT_REPORT_VIEW:
			return {
				label: t('permission.FACT_REPORT_VIEW'),
				value: PermissionEnum.FACT_REPORT_VIEW,
			};
		case PermissionEnum.ROLE_ACCOUNT_VIEW:
			return {
				label: t('permission.ROLE_ACCOUNT_VIEW'),
				value: PermissionEnum.ROLE_ACCOUNT_VIEW,
			};
		case PermissionEnum.ROLE_ACCOUNT_CREATE:
			return {
				label: t('permission.ROLE_ACCOUNT_CREATE'),
				value: PermissionEnum.ROLE_ACCOUNT_CREATE,
			};
		case PermissionEnum.ROLE_ACCOUNT_EDIT:
			return {
				label: t('permission.ROLE_ACCOUNT_EDIT'),
				value: PermissionEnum.ROLE_ACCOUNT_EDIT,
			};

		case PermissionEnum.ROLE_ACCOUNT_DELETE:
			return {
				label: t('permission.ROLE_ACCOUNT_DELETE'),
				value: PermissionEnum.ROLE_ACCOUNT_DELETE,
			};
		case PermissionEnum.FILE_VIEW:
			return {
				label: t('permission.FILE_VIEW'),
				value: PermissionEnum.FILE_VIEW,
			};
		case PermissionEnum.FILE_CREATE:
			return {
				label: t('permission.FILE_CREATE'),
				value: PermissionEnum.FILE_CREATE,
			};
		case PermissionEnum.FILE_EDIT:
			return {
				label: t('permission.FILE_EDIT'),
				value: PermissionEnum.FILE_EDIT,
			};
		case PermissionEnum.FILE_DELETE:
			return {
				label: t('permission.FILE_DELETE'),
				value: PermissionEnum.FILE_DELETE,
			};
		case PermissionEnum.DEVICE_INSTALLATION_VIEW:
			return {
				label: t('permission.DEVICE_INSTALLATION_VIEW'),
				value: PermissionEnum.DEVICE_INSTALLATION_VIEW,
			};
		case PermissionEnum.DEVICE_INSTALLATION_CREATE:
			return {
				label: t('permission.DEVICE_INSTALLATION_CREATE'),
				value: PermissionEnum.DEVICE_INSTALLATION_CREATE,
			};
		case PermissionEnum.DEVICE_INSTALLATION_EDIT:
			return {
				label: t('permission.DEVICE_INSTALLATION_EDIT'),
				value: PermissionEnum.DEVICE_INSTALLATION_EDIT,
			};
		case PermissionEnum.DEVICE_INSTALLATION_DELETE:
			return {
				label: t('permission.DEVICE_INSTALLATION_DELETE'),
				value: PermissionEnum.DEVICE_INSTALLATION_DELETE,
			};
		case PermissionEnum.INSURANCE_VIEW:
			return {
				label: t('permission.INSURANCE_VIEW'),
				value: PermissionEnum.INSURANCE_VIEW,
			};
		case PermissionEnum.INSURANCE_CREATE:
			return {
				label: t('permission.INSURANCE_CREATE'),
				value: PermissionEnum.INSURANCE_CREATE,
			};
		case PermissionEnum.INSURANCE_EDIT:
			return {
				label: t('permission.INSURANCE_EDIT'),
				value: PermissionEnum.INSURANCE_EDIT,
			};
		case PermissionEnum.INSURANCE_DELETE:
			return {
				label: t('permission.INSURANCE_DELETE'),
				value: PermissionEnum.INSURANCE_DELETE,
			};
		case PermissionEnum.PAYMENT_VIEW:
			return {
				label: t('permission.PAYMENT_VIEW'),
				value: PermissionEnum.PAYMENT_VIEW,
			};
		case PermissionEnum.PAYMENT_CREATE:
			return {
				label: t('permission.PAYMENT_CREATE'),
				value: PermissionEnum.PAYMENT_CREATE,
			};
		case PermissionEnum.PAYMENT_EDIT:
			return {
				label: t('permission.PAYMENT_EDIT'),
				value: PermissionEnum.PAYMENT_EDIT,
			};
		case PermissionEnum.PAYMENT_DELETE:
			return {
				label: t('permission.PAYMENT_DELETE'),
				value: PermissionEnum.PAYMENT_DELETE,
			};
		case PermissionEnum.DISABLE_NOTICE:
			return {
				label: t('permission.DISABLE_NOTICE'),
				value: PermissionEnum.DISABLE_NOTICE,
			};

		case PermissionEnum.ADMIN_CONFIRM_FIRING:
			return {
				label: t('permission.ADMIN_CONFIRM_FIRING'),
				value: PermissionEnum.ADMIN_CONFIRM_FIRING,
			};
	}
};

const _getAll = (): Permission[] => {
	// return Object.values(PermissionEnum).map((r) => _fromEnum(r as PermissionEnum));
	return Object.values(PermissionEnum)
		.filter(r => !isNaN(parseFloat(r + '')))
		.map(r => _fromEnum(r as PermissionEnum));
};

const _getForAPI = (per: PermissionEnum): Permission[] => {
	// return Object.values(PermissionEnum).map((r) => _fromEnum(r as PermissionEnum));
	return Object.values(per)
		.filter(r => !isNaN(parseFloat(r + '')))
		.map(r => _fromEnum(r as PermissionEnum));
};

const permissionEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	getForAPI: _getForAPI,
};

export default permissionEnum;
