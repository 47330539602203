import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import LichSuDangNhap from './LichSuDangNhap';
import PhanQuyen from './PhanQuyen';
import QuanLyKhachHang from './QuanLyKhachHang';
import QuanLyNguoiDung from './QuanLyNguoiDung';

export enum NguoiDungTab {
	QUAN_LY_NGUOI_DUNG = 'quanLyNguoiDung',
	QUAN_LY_KHACH_HANG = 'quanLyKhachHang',
	LICH_SU_DANG_NHAP = 'lichSuDangNhap',
	PHAN_QUYEN = 'phanQuyen',
}

export interface PermissionProps {
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
}

const mapStateToProps = (): PermissionProps => ({
	permissionList: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const NguoiDung: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		NavBarDispatcher.setTitle(`${t('quanLyNguoiDung.title')}`);
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(NguoiDungTab).includes(_tabParam as NguoiDungTab)) {
			navigate(
				{
					pathname: '/nguoiDung',
					search: `?tab=${NguoiDungTab.QUAN_LY_NGUOI_DUNG}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as NguoiDungTab);
		}
	}, [navigate, searchParams]);
	const [selectedTab, setSelectedTab] = useState<NguoiDungTab>();

	const renderSelectedTab = (): JSX.Element => {
		switch (selectedTab) {
			case NguoiDungTab.QUAN_LY_NGUOI_DUNG:
			default:
				return <QuanLyNguoiDung permissionList={props.permissionList} userInfo={props.userInfo}/>;
			case NguoiDungTab.QUAN_LY_KHACH_HANG:
				return <QuanLyKhachHang permissionList={props.permissionList} userInfo={props.userInfo} />;
			case NguoiDungTab.LICH_SU_DANG_NHAP:
				return <LichSuDangNhap permissionList={props.permissionList} userInfo={props.userInfo}/>;
			case NguoiDungTab.PHAN_QUYEN:
				return <PhanQuyen permissionList={props.permissionList} userInfo={props.userInfo}/>;
		}
	};
	return renderSelectedTab();
};

export default connect(mapStateToProps)(NguoiDung);
