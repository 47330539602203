import { DeleteOutlined, EllipsisOutlined, FormOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Empty, Menu, Pagination, Row } from 'antd';
import { TableOption } from 'constants/tableOption.const';
import { formatDate } from 'helpers';
import { SoCuKiemTraModel } from 'models/SoCuKiemTra/SoCuKiemTra.model';
import React from 'react';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { useNavigate } from 'react-router-dom';
import './SoCuKiemTraGridType.scss';

interface SoCuKiemTraGridTypeProps {
	data: SoCuKiemTraModel[];
	pageSizeDefault: number;
	totalItem: number;
	currentPage: number;
	onChangePage: (page: number, pageSize: number) => void;
	onShowDeleteDialog: (id: string, name: string, e?: any) => void;
}

const SoCuKiemTraGridType = (props: SoCuKiemTraGridTypeProps) => {
	const navigate = useNavigate();

	const handleShowDeleteDialog = (id: string, name: string) => {
		props.onShowDeleteDialog(id, name, undefined);
	};

	const handleNavigatePage = (path: string, id: string, mode?: string) => {
		navigate({ pathname: path, search: `?id=${id}${mode ? '&mode=' + mode : ''}` });
	};
	return (
		<>
			<Row gutter={[24, 24]}>
				{props.data.length > 0 ? (
					props.data.map(item => (
						<Col span={6} key={item.Id}>
							<div className="card">
								<Dropdown
									className="card-icon"
									placement="bottomRight"
									overlay={
										<Menu className="card-menu">
											<Menu.Item
												title="Tạo kế hoạch"
												icon={<PlusOutlined />}
												key="1"
												onClick={() => handleNavigatePage('/themMoiKeHoachKiemTra', item.Id)}>
												Tạo kế hoạch
											</Menu.Item>
											<Menu.Item
												title="Xem chi tiết"
												icon={<InfoCircleOutlined />}
												key="2"
												onClick={() => handleNavigatePage('/chiTietSoCuKiemTra', item.Id, 'view')}>
												Xem chi tiết
											</Menu.Item>
											<Menu.Item
												title="Chỉnh sửa"
												icon={<FormOutlined />}
												key="3"
												onClick={() => handleNavigatePage('/chiTietSoCuKiemTra', item.Id, 'edit')}>
												Chỉnh sửa
											</Menu.Item>
											<Menu.Item
												title="Xoá"
												icon={<DeleteOutlined />}
												key="4"
												onClick={() => handleShowDeleteDialog(item.Id, item.Name)}>
												Xoá
											</Menu.Item>
										</Menu>
									}>
									<Button className="card-btn" type="default" size="small" icon={<EllipsisOutlined />} />
								</Dropdown>

								{item.FileNameUrl && item.FileNameUrl.length > 0 ? (
									<div className="card-file">
										<Document file={item.FileNameUrl.at(0)?.FileUrl} loading="Đang tải file">
											<Page pageNumber={1} height={300} />
										</Document>
									</div>
								) : (
									<div className="card-img">
										<img src="icon/pdf.png" alt="pdf" />
									</div>
								)}

								<div className="card-content">
									<h4 className="card-title">{item.Name}</h4>
									<span className="card-date">
										{formatDate(item.FromDate)} - {formatDate(item.ToDate)}
									</span>
								</div>
							</div>
						</Col>
					))
				) : (
					<div className="flex--row justify-content--center" style={{ width: '100%' }}>
						<Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="Không có dữ liệu" />
					</div>
				)}
			</Row>
			{props.totalItem > 15 && (
				<Row justify="center" className="mt20 pb20">
					<Pagination
						defaultPageSize={props.pageSizeDefault}
						showSizeChanger={true}
						onChange={(page: number, pageSize: number) => props.onChangePage(page, pageSize)}
						total={props.totalItem}
						current={props.currentPage}
						pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
					/>
				</Row>
			)}
		</>
	);
};

export default SoCuKiemTraGridType;
