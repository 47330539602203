import DiemChay from 'components/page/QuanLyBaoCao/DiemChay/DiemChay';
import PhuongTien from 'components/page/QuanLyBaoCao/PhuongTien/PhuongTien';
import ThietBiLapDat from 'components/page/QuanLyBaoCao/ThietBiLapDat/ThietBiLapDat';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import CoSoCongTrinhTab from './CoSoCongTrinh/CoSoCongTrinhTab';

export enum QuanLyBaoCaoTab {
	THIET_BI_LAP_DAT = 'thietBiLapDat',
	CO_SO_CONG_TRINH = 'coSoCongTrinh',
	TRANG_THAI_PHUONG_TIEN_PCCC = 'trangThaiPhuongTienPCCC',
	DIEM_CHAY = 'diemChay',
}

export interface PermissionProps {
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
}

const mapStateToProps = (): PermissionProps => ({
	permissionList: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const QuanLyBaoCao: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		// document.title = t('nav.quanLyBaoCao');
		NavBarDispatcher.setTitle(`${t('quanLyBaoCao.title')}`);
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(QuanLyBaoCaoTab).includes(_tabParam as QuanLyBaoCaoTab)) {
			navigate(
				{
					pathname: '/quanLyBaoCao',
					search: `?tab=${QuanLyBaoCaoTab.THIET_BI_LAP_DAT}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as QuanLyBaoCaoTab);
		}
	}, [navigate, searchParams]);
	const [selectedTab, setSelectedTab] = useState<QuanLyBaoCaoTab>();

	const renderSelectedTab = (): JSX.Element => {
		switch (selectedTab) {
			case QuanLyBaoCaoTab.THIET_BI_LAP_DAT:
			default:
				return <ThietBiLapDat permissionList={props.permissionList} userInfo={props.userInfo}/>;
			case QuanLyBaoCaoTab.CO_SO_CONG_TRINH:
				return <CoSoCongTrinhTab permissionList={props.permissionList} userInfo={props.userInfo}/>;
			case QuanLyBaoCaoTab.TRANG_THAI_PHUONG_TIEN_PCCC:
				return <PhuongTien permissionList={props.permissionList} userInfo={props.userInfo}/>;
			case QuanLyBaoCaoTab.DIEM_CHAY:
				return <DiemChay permissionList={props.permissionList} userInfo={props.userInfo}/>;
		}
	};
	return renderSelectedTab();
};

export default connect(mapStateToProps)(QuanLyBaoCao);
