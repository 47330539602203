import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import FilterDropdownLayout from 'components/shared/layouts/FilterDropdownLayout/FilterDropdownLayout';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { formatDate, openDialog, showDetailCellTableInfo } from 'helpers';
import { t } from 'i18next';
import 'moment/locale/vi';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { PageOption } from 'services/api';
import PlanningInfoService from 'services/api/businessService/planningInfoService';
import { PermissionProps } from '../CongTacPhongChayVaChcn';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const XDThucTapPhuongAnChuaChay: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	// states
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);
	const [responseApi, setResponseApi] = useState({
		dataSources: [],
		totalItems: 0,
	});
	const [paginateOption, setPaginateOption] = useState({
		CurrentPage: CURRENT_PAGE,
		PageSize: PAGE_SIZE,
		Paging: true,
	});
	const [filteredInfo, setFilteredInfo] = useState<any>({});

	// columns
	const columns = [
		{
			title: 'STT',
			dataIndex: 'key',
			key: 'key',
			align: 'center' as const,
			width: 45,
			render: (value: any, item: any, index: any) =>
				(paginateOption.CurrentPage - 1) * paginateOption.PageSize + index + 1,
		},
		{
			title: 'Cơ sở công trình',
			dataIndex: 'ConstructionDto',
			key: 'ConstructionDto',
			filteredValue: filteredInfo.ConstructionDto || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="ConstructionName" style={{ marginBottom: 0 }}>
							<Input.Search
								placeholder="Nhập tên cơ sở công trình"
								allowClear
								onSearch={e => {
									if (!e) form.setFieldsValue({ ConstructionName: '' });
									handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (!e.target.value) handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			render: (ConstructionDto: { Name: string; Id: string }) => {
				return (
					<NavigateButton
						url={`/chiTietCoSoCongTrinh?id=${ConstructionDto?.Id}&mode=view`}
						name={ConstructionDto?.Name}
					/>
				);
			},
		},
		{
			title: 'Ngày thực tập',
			dataIndex: 'PracticeDate',
			key: 'PracticeDate',
			width: 200,
			render: (PracticeDate: string) => {
				return formatDate(PracticeDate);
			},
		},
		{
			title: 'Tình huống thực tập',
			dataIndex: 'PracticeSituation',
			key: 'PracticeSituation',
			ellipsis: true,
			filteredValue: filteredInfo.PracticeSituation || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="PracticeSituation" style={{ marginBottom: 0 }}>
							<Input.Search
								placeholder="Nhập tình huống thực tập"
								allowClear
								onSearch={e => {
									if (!e) form.setFieldsValue({ PracticeSituation: '' });
									handleSearchColumn('PracticeSituation', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (!e.target.value) handleSearchColumn('PracticeSituation', confirm, setSelectedKeys);
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		},
		{
			title: 'Đơn vị tham gia',
			dataIndex: 'PcccUnitDtos',
			key: 'PcccUnitDtos',
			render: (PcccUnitDtos: { Name: string; Id: string }[]) => {
				return <span className="text-detail-info">{PcccUnitDtos[0]?.Name}</span>;
			},
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record?.PcccUnitDtos[0]?.Name,
					<DialogThemMoiDonViPccc userInfo={props.userInfo} id={record?.PcccUnitDtos[0]?.Id} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{
			title: 'Số lượng cán bộ chiến sĩ',
			dataIndex: 'FireFighterCount',
			key: 'FireFighterCount',
			align: 'center' as const,
			width: 150,
		},
		{
			title: 'Số lượng phương tiện',
			dataIndex: 'FireTruckCount',
			key: 'FireTruckCount',
			align: 'center' as const,
			width: 150,
		},
		{
			title: 'Thao tác',
			dataIndex: 'Action',
			key: 'Action',
			width: 100,
			align: 'center' as const,
			render: (_: any, record: any) => {
				return (
					<>
						<Button type="text" onClick={e => e.stopPropagation()}>
							<Link
								to={{
									pathname: '/chiTietXDPAChuaChay',
									search: `?id=${record?.Id}&mode=edit`,
								}}>
								<img src="icon/Pen.png" alt="edit" />
							</Link>
						</Button>

						<Button type="text" onClick={e => handleShowDeleteDialog(record.Id, record?.PracticeSituation, e)}>
							<img src="icon/Delete.png" alt="delete" />
						</Button>
					</>
				);
			},
		},
	];

	const handleSearchColumn = (
		column: string,
		confirm: () => void,
		setSelectedKeys: (selectedKeys: React.Key[]) => void,
	) => {
		const value = form.getFieldValue(column);
		setSelectedKeys(value ? [value] : []);
		if (column === 'ConstructionName') {
			let updatedValue = value ? { ConstructionDto: value } : { ConstructionDto: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		}
		if (column === 'PracticeSituation') {
			let updatedValue = value ? { PracticeSituation: value } : { PracticeSituation: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		}
		confirm();
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		getDataDsXayDungTT(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});

		getDataDsXayDungTT(CURRENT_PAGE);
	};

	const handleShowDeleteDialog = async (id: string, name: string, e?: any) => {
		if (e) {
			e.stopPropagation();
		}
		await openDialog(
			<DeleteDialog
				name={name ? name : 'Phương án thực tập'}
				id={id}
				deleteService={PlanningInfoService.deletePlanningInfo}
				callback={getDataDsXayDungTT}
			/>,
			'',
			'',
		);
	};

	const getPayload = useCallback(() => {
		const provinceId = form.getFieldValue('TinhThanh');
		const districtId = form.getFieldValue('QuanHuyen');
		const unitId = form.getFieldValue('PcccUnitId');
		const dateRange = form.getFieldValue('dateRange');
		const constructionName = form.getFieldValue('ConstructionName');
		const practiceSituation = form.getFieldValue('PracticeSituation');
		return {
			PracticeSituation: practiceSituation,
			PcccUnitId: unitId,
			ConstructionName: constructionName,
			FromDate: dateRange?.length > 0 ? dateRange[0] : null,
			ToDate: dateRange?.length > 0 ? dateRange[1] : null,
			Location: {
				TinhThanh: provinceId,
				QuanHuyen: districtId,
			},
		};
	}, [form]);

	const getDataDsXayDungTT = useCallback(
		(page = CURRENT_PAGE, pageSize = PAGE_SIZE) => {
			const pageOption: PageOption = {
				CurrentPage: page,
				PageSize: pageSize,
				Paging: true,
			};
			const payload = getPayload();
			PlanningInfoService.searchPlanningInfo(payload, pageOption).then((res: any) => {
				const { Data, TotalItems } = res;
				setResponseApi({
					dataSources: Data,
					totalItems: TotalItems,
				});
			});
		},
		[getPayload],
	);

	const handleChangePage = (page: number, pageSize: number) => {
		setPaginateOption({
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		});

		getDataDsXayDungTT(page, pageSize);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietXDPAChuaChay',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	useEffect(() => {
		document.title = t('congTacPhongChayVaCHCN.xayDungTTPAChuaChay');
	}, []);

	useEffect(() => {
		getDataDsXayDungTT();
	}, [getDataDsXayDungTT]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect onChangeProvince={handleChangeProvince} form={form} selectClassName="withMinSelect" />

				<DistrictSelect
					onChangeDistrict={handleChangeDistrict}
					form={form}
					provinceId={provinceId}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
				/>

				<UnitManagerSelect
					name="PcccUnitId"
					form={form}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					location={location}
					onChangeUnitManager={() => getDataDsXayDungTT(CURRENT_PAGE)}
				/>

				<RangePickerInput className="ml10" form={form} onChangeDatePicker={() => getDataDsXayDungTT(CURRENT_PAGE)} />
			</div>

			<div>
				<Button
					className="mr10"
					type={'default'}
					onClick={() => {
						form.resetFields();
						setFilteredInfo({});
						getDataDsXayDungTT(CURRENT_PAGE);
					}}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				<Button type="primary" icon={<PlusOutlined />} onClick={() => navigate({ pathname: '/chiTietXDPAChuaChay' })}>
					Thêm mới
				</Button>
			</div>
		</>
	);
	const formTable = (
		<TableComponent
			dataSource={responseApi.dataSources}
			columns={columns}
			paginator={{
				defaultPageSize: paginateOption.PageSize,
				showSizeChanger: true,
				onChange: handleChangePage,
				total: responseApi.totalItems,
				current: paginateOption.CurrentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
			onRow={handleShowViewDetail}
		/>
	);

	return (
		<PageWrapper
			titlePage="Xây dựng thực tập phương án chữa cháy"
			form={form}
			formAction={formAction}
			formTable={formTable}
		/>
	);
};

export default withTranslation()(XDThucTapPhuongAnChuaChay);
