import { RightOutlined } from '@ant-design/icons';
import { Column } from '@ant-design/plots';
import { DatePicker, Form, Select, Tag } from 'antd';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import TitlePage from 'components/shared/TitlePage/TitlePage';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import reportFactService from 'services/api/factService/reportFactService';
import utils from 'services/api/utils';
import { systemProperties } from 'systemProperties';
import CommonControl from '../CommonControl';
import { PermissionProps } from './QuanLyChatLuongThietBi';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = 10;

const BaoCaoKetQuaSanXuat: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const loaiThietBi = utils.GetDSLoaiThietBiError();

	const [TongSoSanXuat, setTongSoSanXuat] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [chartDataNotPass, setChartDataNotPass] = useState([]);
	const [chartDataPass, setChartDataPass] = useState([]);

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			title: t('label.order'),
		},
		{
			dataIndex: 'GsmIMEI',
			title: t('label.imeiThietBi'),
		},
		{
			dataIndex: 'DeviceType',
			title: t('label.loaiThietBi'),
			align: 'center' as const,
			width: 200,
		},
		{
			dataIndex: 'Result',
			title: t('label.ketQua'),
			align: 'center' as const,
			width: 100,
		},
		{
			dataIndex: 'DateTest',
			title: t('label.thoiGian'),
			align: 'center' as const,
			width: 250,
		},
		{
			dataIndex: 'IsPass',
			title: t('label.danhGia'),
			align: 'center' as const,
			width: 100,
			render: (IsPass: string) => {
				return <Tag color={IsPass ? '#1BB36B' : '#FF2000'}>{IsPass ? 'PASS' : 'FAIL'}</Tag>;
			},
		},
	];

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.quanLyLoaiThietBi');
		NavBarDispatcher.setTitle(`${t('nav.quanLyLoaiThietBi')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FACT_REPORT_VIEW])) {
				SearchDanhSachLoi();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	const config: any = {
		data: [...chartDataPass, ...chartDataNotPass],
		isStack: true,
		xField: 'Key',
		yField: 'Value',
		seriesField: 'Name',
		label: {
			// 可手动配置 label 数据标签位置
			position: 'middle', // 'top', 'bottom', 'middle'
		},
		interactions: [
			{
				type: 'active-region',
				enable: false,
			},
		],
		columnBackground: {
			style: {
				fill: 'rgba(0,0,0,0.1)',
			},
		},
	};

	function onChangePage(page: number, pageSize: number) {
		const index = Number((page - 1) * pageSize);
		SearchDanhSachLoi(page, pageSize, index);
	}

	function SearchDanhSachLoi(page = CURRENT_PAGE, pageSize = PAGE_SIZE, index = 0) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);
		const param = {
			DeviceType: form.getFieldValue('MaLoi'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
		reportFactService.search(param, pageOption).then((res: any) => {
			setTongSoSanXuat(res.TotalItems);
			optiondanhsachSanXuat(res ? res.Data[0].ReportFact : [], index);
			setChartDataPass(res.Data[0]?.ChartDataPass);
			setChartDataNotPass(res.Data[0]?.ChartDataNotPass);
		});
	}

	const optiondanhsachSanXuat = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any, index: number) => {
				if (item.DateTest) {
					item.DateTest = moment(item.DateTest).format('DD/MM/YYYY HH:mm:ss');
				}
				item.Result = item.Result + '/' + item.Total;
				item.key = ++_index;
				array.push(item);
			});
		}
		Promise.all(promises).then(() => setUsers(data));
	};

	const handleChangeDatePicker = () => {
		SearchDanhSachLoi();
	};
	const onChangeLoaiThietBi = () => {
		SearchDanhSachLoi();
	};

	const showTotal = (total: number) => {
		return (
			<span>
				{t('label.tong')} <strong>{total}</strong> {t('label.thietbi')}
			</span>
		);
	};

	return (
		<Form id="baoCaoKetQuaSanXuat" layout="vertical" name="normal_login" form={form}>
			<TitlePage name={t('nav.baoCaoKetQuaSanXuat')} />
			<div className="flex--row">
				<Form.Item name="dateRange">
					<DatePicker.RangePicker
						locale={CommonControl.localeVi}
						format={systemProperties.dateFormat}
						placeholder={[t('label.tuNgay'), t('label.denNgay')]}
						separator={<RightOutlined style={{ color: '#00000040', fontSize: '12px' }} />}
						onChange={handleChangeDatePicker}
					/>
				</Form.Item>

				<Form.Item name="DeviceType" className="ml10 withMinSelect">
					<Select
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.chonLoaiThietBi')}
						onChange={onChangeLoaiThietBi}
						filterSort={(optionA, optionB) =>
							(optionA!.children as unknown as string)
								.toLowerCase()
								.localeCompare((optionB!.children as unknown as string).toLowerCase())
						}
						allowClear>
						{loaiThietBi}
					</Select>
				</Form.Item>
			</div>
			<div className="ml40 mb40 mt40 mr40">
				<Column className="mt10" {...config} />
			</div>
			<div className={'break-line mt10'} />
			<TableComponent
				dataSource={users}
				columns={columns}
				paginator={{
					defaultPageSize: PAGE_SIZE,
					showSizeChanger: true,
					onChange: onChangePage,
					total: TongSoSanXuat,
					current: currentPage,
					pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
					showTotal: showTotal,
				}}
			/>
		</Form>
	);
};

export default withTranslation()(BaoCaoKetQuaSanXuat);
