export enum DateType {
	HOM_NAY,
	QUA_HAN,
	SAP_TOI,
	CHUA_LEN_LICH,
	DA_HOAN_THANH,
}

interface IDateType {
	label: string;
	value: DateType;
}

const _fromEnum = (dateType: DateType): IDateType => {
	switch (dateType) {
		case DateType.HOM_NAY:
			return {
				label: 'Hôm nay',
				value: DateType.HOM_NAY,
			};
		case DateType.QUA_HAN:
			return {
				label: 'Quá hạn',
				value: DateType.QUA_HAN,
			};
		case DateType.SAP_TOI:
			return {
				label: 'Sắp tới',
				value: DateType.SAP_TOI,
			};
		case DateType.CHUA_LEN_LICH:
			return {
				label: 'Chưa lên lịch',
				value: DateType.CHUA_LEN_LICH,
			};
		case DateType.DA_HOAN_THANH:
			return {
				label: 'Đã hoàn thành',
				value: DateType.DA_HOAN_THANH,
			};
	}
};

const _getAll = (): IDateType[] => {
	return Object.values(DateType)
		.filter(x => typeof x === 'number')
		.map(r => _fromEnum(r as DateType));
};

const DateTypeEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};
export default DateTypeEnum;
