import store from 'redux/store';
import { FirebaseMessage } from "../../App";
import { setMessage } from "./firebaseMessage.action";

const FirebaseMessageDispatcher = {
	setMessage: (input: FirebaseMessage) => {
		return store.dispatch(setMessage(input));
	},
};

export default FirebaseMessageDispatcher;