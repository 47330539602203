import ApiService, { BUSINESS_BASE_URL, PageOption } from 'services/api/index';

const prefixApproval = '/approval';

const _createApproval = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixApproval}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteApproval = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).delete(`${prefixApproval}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getApproval = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).get(`${prefixApproval}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchApprovals = (param: { ConstructionId: string }, pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixApproval}/SearchData`, param, {
		headers: {
			'PageOption': JSON.stringify(pageOption),
		},
	})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateApproval = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).put(`${prefixApproval}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listApproval = (pageOption?: PageOption): Promise<any[]> => ApiService(BUSINESS_BASE_URL)
	.get(`${prefixApproval}`, {
		headers: {
			'PageOption': JSON.stringify(pageOption),
		},
	}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const ApprovalService = {
	createApproval: _createApproval,
	deleteApproval: _deleteApproval,
	getApproval: _getApproval,
	searchApprovals: _searchApprovals,
	updateApproval: _updateApproval,
	listApproval: _listApproval,
};

export default ApprovalService;