import { Form, Select } from 'antd';
import { t } from 'i18next';
import React, { ReactNode } from 'react';
import Utils from 'services/api/utils';
import { FormInstance } from 'rc-field-form';
import { PermissionEnum } from 'enum/permissionEnum';
import PermissionSelector from 'redux/Permission/permission.selector';
import { connect } from 'react-redux';

interface ProvinceSelectProps {
	onChangeProvince?: (value: string, option?: any) => void;
	disabled?: boolean;
	form?: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: ReactNode;
	permissions?: PermissionEnum[];
	bordered?: boolean;
	allowClear?: boolean;
	style?: React.CSSProperties;
}

const mapStateToProps = () => ({
	permissions: PermissionSelector.getPermissionList(),
});

const ProvinceSelect = (props: ProvinceSelectProps) => {
	const {
		onChangeProvince,
		disabled,
		form,
		rules,
		formItemClassName,
		selectClassName,
		label,
		permissions,
		bordered = true,
		allowClear = true,
		style
	} = props;
	const provinceOfCustomer = Utils.GetTinhThanhUser();
	const provinceOfAdmin = Utils.GetTinhThanhAdmin();
	return (
		<Form.Item name="TinhThanh" className={formItemClassName} rules={rules} label={label} style={style}>
			<Select
				showSearch
				optionFilterProp="children"
				className={selectClassName}
				placeholder={t('placeholder.tinhThanh')}
				onChange={onChangeProvince}
				allowClear={allowClear}
				disabled={disabled}
				bordered={bordered}>
				{permissions && permissions.includes(PermissionEnum.ADMIN) ? provinceOfAdmin : provinceOfCustomer}
			</Select>
		</Form.Item>
	);
};

export default connect(mapStateToProps)(ProvinceSelect);
