import { t } from 'i18next';

export enum NhomHanhViEnum {
	VI_PHAM_BAN_HANH = 'VI_PHAM_BAN_HANH',
	VI_PHAM_QUY_DINH_KIEM_TRA = 'VI_PHAM_QUY_DINH_KIEM_TRA',
	VI_PHAM_HO_SO = 'VI_PHAM_HO_SO',
	VI_PHAM_QUY_DINH_PCCC = 'VI_PHAM_QUY_DINH_PCCC'
}

interface NhomHanhVi {
	value: NhomHanhViEnum,
	label: string
}

const _fromEnum = (nhomHanhViEnum: NhomHanhViEnum): NhomHanhVi => {
	switch (nhomHanhViEnum) {
		case NhomHanhViEnum.VI_PHAM_BAN_HANH:
			return {
				label: t('enum.nhomHanhVi.VI_PHAM_BAN_HANH'),
				value: NhomHanhViEnum.VI_PHAM_BAN_HANH,
			};
		case NhomHanhViEnum.VI_PHAM_QUY_DINH_KIEM_TRA:
			return {
				label: t('enum.nhomHanhVi.VI_PHAM_QUY_DINH_KIEM_TRA'),
				value: NhomHanhViEnum.VI_PHAM_QUY_DINH_KIEM_TRA,
			};
		case NhomHanhViEnum.VI_PHAM_HO_SO:
			return {
				label: t('enum.nhomHanhVi.VI_PHAM_HO_SO'),
				value: NhomHanhViEnum.VI_PHAM_HO_SO,
			};
		case NhomHanhViEnum.VI_PHAM_QUY_DINH_PCCC:
			return {
				label: t('enum.nhomHanhVi.VI_PHAM_QUY_DINH_PCCC'),
				value: NhomHanhViEnum.VI_PHAM_QUY_DINH_PCCC,
			};
	}
};

const _getAll = (): NhomHanhVi[] => {
	return Object.values(NhomHanhViEnum).map(r => _fromEnum(r as NhomHanhViEnum));
};

const nhomHanhViEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default nhomHanhViEnum;
