import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Table, Tabs } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { TableOption } from 'constants/tableOption.const';
import loaiKhachHangEnum, { LoaiKhachHangEnum } from 'enum/loaiKhachHangEnum';
import permissionEnum, { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog, useDialog } from 'react-st-modal';
import PermissionSelector from 'redux/Permission/permission.selector';
import RoleService from 'services/api/authenService/roleService';
import UsersService, { CreateUserParams, UserInfo } from 'services/api/authenService/usersService';
import ConstructionService from 'services/api/inventoryService/constructionService';
import CustomerService from 'services/api/inventoryService/customerService';
import { default as provinceService, default as ProvinceService } from 'services/api/provinceService';
import { default as utils, default as Utils } from 'services/api/utils';
import Generator from 'services/generator';
import GeneratorProvinceUser from 'services/generator/ProvinceUser';
import { systemProperties } from 'systemProperties';
import { blockInvalidChar } from '../CommonControl/BlockInvalidChar';
import ChonCoSoCongTrinhDialog from '../ThemMoiKeHoachKiemTra/Dialog/ChonCoSoCongTrinhDialog';
import ChiTietPhanQuyenDialog from './Dialog/ChiTietPhanQuyenDialog';
import './EditKhachHang.scss';
import { NguoiDungTab } from './NguoiDung';

interface ThemNguoiDungProps {
	RoleName?: string;
	Id?: string;
	userInfo?: UserInfo;
	callback?: () => void;
}

interface RecordType {
	key: string;
	title: string;
	// description: string;
	chosen: boolean;
}

const { Option } = Select;

const { TabPane } = Tabs;

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DialogLockAndUnlock = (props: any) => {
	const dialog = useDialog();

	const lockItemCustomer = async () => {
		props.callback && props.callback();
		dialog.close();
	};

	return (
		<div className="dialog-delete-container">
			<div className="dialog-delete-image">
				<img alt={'CONFIRM_ICON'} src={'icon/Lock.png'} height={80} />
			</div>
			<h4 className="mt10 heading--6">Xác nhận tạm khóa người dùng</h4>
			<p>Người dùng sẽ không thể đăng nhập khi tạm khóa. Bạn có chắc chắn muốn tạm khóa người dùng: {props.Name}</p>
			<div>
				<Button
					className="mr10"
					style={{ width: 150 }}
					type="default"
					onClick={() => {
						// Сlose the dialog and return the value
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button
					type={'primary'}
					style={{
						background: '#D02127',
						color: '#FFFFFF',
						width: 150,
						border: 'none',
					}}
					danger
					onClick={lockItemCustomer}>
					Khóa và cập nhật
				</Button>
			</div>
		</div>
	);
};

const ThemNguoiDung: FunctionComponent<ThemNguoiDungProps> = (props: ThemNguoiDungProps) => {
	const [form] = Form.useForm();

	const [searchParams] = useSearchParams();
	const Id = useMemo(() => searchParams.get('Id'), [searchParams]);
	const customerId: string | null = searchParams.get('CustomerId');
	const mode = useMemo(() => searchParams.get('mode'), [searchParams]);

	const navigate = useNavigate();
	const [permissionTable, setPermissionTable] = useState<any>();
	const [dataCoSoCongTrinh, setDataCoSoCongTrinh] = useState<any>([]);
	const [dataDanhSachQuyen, setDataDanhSachQuyen] = useState<any>([]);

	const [isContinue, setIsContinue] = useState<boolean>(false);
	const [roleList, setRoleList] = useState<JSX.Element[]>([]);

	const [dataVaiTro, setDataVaiTro] = useState<JSX.Element[]>([]);

	const [loaiKhachHang, setLoaiKhachHang] = useState<string>('CO_QUAN_CHUC_NANG');

	const [arrayTinhthanh, updateArrarrayTinhthanh] = useState<JSX.Element[] | null>();

	const [locations, setLocations] = useState<any>([]);

	const [quanHuyenList, setQuanHuyenList] = useState<any[]>([]);
	const [quanHuyenIdUser, setQuanHuyenIdUser] = useState<JSX.Element[]>([]);

	const [titleScreen, setTitleScreen] = useState<string>();

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const [custommerList, setCustommerList] = useState<JSX.Element[]>([]);

	useEffect(() => {
		let quanHuyenArray: any[] = [];
		let tinhThanhArray: any[] = [];

		let title = 'Thêm mới người dùng';
		if (mode === 'view') {
			title = 'Chi tiết người dùng';
		} else if (Id) {
			title = 'Cập nhật người dùng';
		}
		setTitleScreen(title);

		if (customerId) {
			CustomerService.getCustomer(customerId).then((res: any) => {
				form.setFieldsValue({
					CustomerType: res.CustomerType,
				});
				form.setFieldsValue({ CustomerId: customerId });
				setLoaiKhachHang(res.CustomerType);
			});
			if (!Id) {
				onChangeLoaiKhachHang(customerId);
			}
		} else {
			form.setFieldsValue({
				CustomerType: LoaiKhachHangEnum.CO_QUAN_CHUC_NANG,
				CustomerId: '',
			});
		}

		RoleService.searchRole({}).then((res: any) => {
			setRoleList(Utils.getSelectOptionsRolde(res));
		});

		if (Id) {
			UsersService.getUserById(Id).then((result: any) => {
				result && setValueToForm(result);
				setDataCoSoCongTrinh(result.ConstructionDto)
			});
		}

		function setValueToForm(result: any) {
			form.setFieldsValue({
				...result,
			});
			setLoaiKhachHang(result.CustomerType);
			RoleService.getRoleById(result.RoleIds[0]).then((dataVaiTro: any) => {
				if (dataVaiTro.Permissions?.length > 0) {
					let array: any[] = [];
					dataVaiTro.Permissions.forEach((item: any, index: number) => {
						const value: any = {};
						value.Name = permissionEnum.fromEnum(item)?.label;
						value.key = ++index;
						array.push(value);
					});
					setDataVaiTro(array);
				}
			});

			if (result.Permissions && result.Permissions.length > 0) {
				let array: any[] = [];
				result.PermissionAdded?.forEach((element: any) => {
					const value: any = {};
					value.label = permissionEnum.fromEnum(element)?.label;
					value.value = element;
					array.push(value);
				});
				setPermissionTable(array);
			}

			onChangeLoaiKhachHang(result?.CustomerId)
				.then(_ => {
					form.setFieldsValue({
						...result,
						Permissions: result.Permissions ? result.Permissions : [],
						ConstructionId: result.ConstructionId ? result.ConstructionId : [],
					});
					// customTinhThanh(result);
				})
				.catch(_ => {});
			if (result && result.Locations?.length > 0) {
				setLocations(result.Locations);
				result.Locations.forEach((location: any) => {
					if (location.CityId) if (!tinhThanhArray.includes(location.CityId)) tinhThanhArray.push(location.CityId);
					if (location.DistrictId) quanHuyenArray.push(location.DistrictId);
				});
				onGenderChangeTinhThanh(tinhThanhArray);
				form.setFieldsValue({
					QuanHuyen: quanHuyenArray,
					TinhThanh: tinhThanhArray,
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Id]);

	useEffect(() => {
		CustomerService.getCustomerAll(loaiKhachHang).then((res: any) => {
			setDataCustomer(res);
		});
	}, [loaiKhachHang]);

	const setDataCustomer = (res: any) => {
		const khongTrucThuocParam = [{ Id: '', Name: 'Không trực thuộc' }];
		const dataTrucThuoc = [...khongTrucThuocParam, ...res];
		setCustommerList(utils.getSelectOptionsCustomer(dataTrucThuoc));
	};

	const customTinhThanh = (res: any) => {
		let result: any = [];
		let tinhThanhList: any = [];
		let LocationUserList: any = [{}];
		let quanHuyens: any = [];
		let quanHuyensId: any = [];
		let array: any[] = [];
		ProvinceService.listProvince().then(tinhThanhs => {
			if (res.Locations?.length > 0) {
				res.Locations.forEach((location: any) => {
					tinhThanhs.forEach(tinhThanh => {
						if (tinhThanh.Id === location.CityId && !tinhThanhList.includes(tinhThanh.Id)) {
							tinhThanhList.push(tinhThanh.Id);
							result.push(tinhThanh);
							if (!location.DistrictId) {
								quanHuyens = [...quanHuyens, ...tinhThanh.Districts];
							}
						}

						if (location.DistrictId) {
							quanHuyensId.push(location.DistrictId);
							LocationUserList.push({ tinhThanhId: tinhThanh.Id, quanHuyenId: location.DistrictId });
						}
					});
				});
			}

			if (result.length > 0 && LocationUserList.length > 0) {
				result.forEach((tinhThanhItem: any) => {
					LocationUserList.forEach((loacation: any) => {
						if (loacation.tinhThanhId === tinhThanhItem.Id) {
							tinhThanhItem.Districts.forEach((quanHuyen: any) => {
								if (quanHuyen.Id === loacation.quanHuyenId) {
									quanHuyens.push(quanHuyen);
								}
							});
						}
					});
				});
			}
			setQuanHuyenIdUser(quanHuyensId);
			if (quanHuyens) {
				quanHuyens.forEach((item: any) =>
					array.push(
						<Option key={item?.Id} value={item?.value}>
							{item?.Name}
						</Option>,
					),
				);
				updateArrOptionQuanhuyen(array);
			}
			updateArrarrayTinhthanh(GeneratorProvinceUser.generateOptions(result, 'Id', 'Name'));
		});
	};

	const onGenderChangeTinhThanh = (value: any) => {
		setQuanHuyenList([]);
		setLocations([]);
		form.resetFields(['QuanHuyen']);
		updateArrOptionQuanhuyen([]);
		if (value?.length > 0) {
			let promises: PromiseLike<any>[] = [];
			let array: any[] = [];
			value.forEach((tinhId: string) => {
				promises.push(
					provinceService.findDistrictsByProvince(tinhId).then((res: any) => {
						array.push(res);
					}),
				);
			});
			Promise.all(promises).then(() => {
				setQuanHuyenList(array);
				optionquanhuyen(array);
			});
		} else {
			updateArrOptionQuanhuyen([]);
		}
	};

	const [arrayOptionQuanhuyen, updateArrOptionQuanhuyen] = useState<JSX.Element[] | null>();
	const optionquanhuyen = (data: any[]) => {
		let dataListQuanHuyen: any[] = [];
		let quanHuyenList: any[] = [];
		let quanHuyenLists: any[] = [];
		setLocations([]);
		if (data.length > 0) {
			if (quanHuyenIdUser?.length > 0 || !form.getFieldValue('QuanHuyen') || !form.getFieldValue('CustomerId')) {
				for (let i = 0; i < data.length; i++) {
					if (i > 0) {
						// dataListQuanHuyen = dataListQuanHuyen.concat(data[i]);
						data[i].forEach((quanHuyen: any) => {
							if (
								!form.getFieldValue('CustomerId') ||
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenLists.push(quanHuyen);
							}
						});
						dataListQuanHuyen = dataListQuanHuyen.concat(quanHuyenLists);
					} else {
						data[i].forEach((quanHuyen: any) => {
							if (
								!form.getFieldValue('CustomerId') ||
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenList.push(quanHuyen);
							}
						});
						dataListQuanHuyen = quanHuyenList;
					}
				}
			}
		}

		const uniqueArray = dataListQuanHuyen?.filter(function (item, pos) {
			return dataListQuanHuyen.indexOf(item) === pos;
		});

		let array: JSX.Element[] = [];
		if (uniqueArray) {
			uniqueArray.forEach((item: any) =>
				array.push(
					<Option key={item?.Id} value={item?.value}>
						{item?.Name}
					</Option>,
				),
			);
			updateArrOptionQuanhuyen(array);
		}
	};

	const createLocation = (formValues: CreateUserParams) => {
		formValues.Locations = [];
		let arrayTinhThanh: any = [];

		if (form.getFieldValue('QuanHuyen')?.length > 0) {
			form.getFieldValue('QuanHuyen').forEach((quanhuyen: string) => {
				for (let i = 0; i < quanHuyenList.length; i++) {
					if (search(quanhuyen, quanHuyenList[i])) {
						formValues.Locations.push({
							TinhThanh: form.getFieldValue('TinhThanh')[i],
							QuanHuyen: quanhuyen,
						});

						if (!arrayTinhThanh.includes(form.getFieldValue('TinhThanh')[i])) {
							arrayTinhThanh.push(form.getFieldValue('TinhThanh')[i]);
						}
						break;
					}
				}
			});

			if (arrayTinhThanh.length < form.getFieldValue('TinhThanh').length) {
				arrayTinhThanh.forEach((tinhThanh: any) => {
					const index = form.getFieldValue('TinhThanh').indexOf(tinhThanh);
					if (index > -1) {
						form.getFieldValue('TinhThanh').splice(index, 1); // 2nd parameter means remove one item only
					}
				});

				form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
					formValues.Locations.push({
						TinhThanh: tinhThanh,
					});
				});
			}
		} else if (form.getFieldValue('TinhThanh')) {
			form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
				formValues.Locations.push({
					TinhThanh: tinhThanh,
				});
			});
		}
	};

	function search(quanhuyenId: any, quanHuyenArray: any) {
		for (let i = 0; i < quanHuyenArray.length; i++) {
			if (quanHuyenArray[i].Id === quanhuyenId) {
				return quanHuyenArray[i];
			}
		}
	}

	const onChangeLoaiKhachHang = async (value: any) => {
		setQuanHuyenList([]);
		updateArrarrayTinhthanh([]);
		setLocations([]);
		form.resetFields(['TinhThanh', 'QuanHuyen']);
		if (value && value !== '0') {
			await CustomerService.getCustomer(value).then((res: any) => {
				customTinhThanh(res);
			});
		} else {
			updateArrarrayTinhthanh(
				props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer,
			);
		}
	};

	const finishCreate = (formValues: any) => {
		if (permissionTable?.length > 0) {
			const permisstions: any[] = [];
			permissionTable.forEach((permisstion: any) => {
				permisstions.push(permisstion.value);
			});
			formValues.Permissions = permisstions;
			formValues.PermissionAdded = permisstions ? permisstions : [];
		} else {
			formValues.PermissionAdded = [];
		}
		if (dataCoSoCongTrinh?.length > 0) {
			const contructionIds: any[] = [];
			dataCoSoCongTrinh.forEach((contructions: any) => {
				contructionIds.push(contructions.Id);
			});
			formValues.ConstructionId = contructionIds;
		} else {
			formValues.ConstructionId = [];
		}
		formValues.IsActive = 'ACTIVE';
		formValues.RoleIds = [formValues.RoleIds];
		createLocation(formValues);
		// Create
		UsersService.createUser(formValues)
			.then(() => {
				if (isContinue) {
					form.resetFields(['FullName', 'Email', 'PhoneNumber', 'UserName']);
				} else {
					navigateUrl();
				}
				showNotification(
					'success',
					t('notification.success'),
					'Thêm mới thành công, mật khẩu đã được gửi vào gmail của người dùng.',
				);
			})
			.catch((errors: { response: { data: string } }) => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const finishUpdate = (formValues: any) => {
		if (formValues.IsActive === 'INACTIVE') {
			CustomDialog(<DialogLockAndUnlock Name={formValues.FullName} callback={_Update} />, {
				showCloseIcon: true,
				isCanClose: true,
			});
		} else {
			_Update();
		}

		function _Update() {
			if (locations?.length === 0) {
				createLocation(formValues);
			} else {
				const output = Object.keys(locations).map(key => {
					return {
						TinhThanh: locations[key].CityId,
						QuanHuyen: locations[key].DistrictId,
					};
				});
				formValues.Locations = output;
			}
			if (permissionTable?.length > 0) {
				const permisstions: any[] = [];
				permissionTable.forEach((permisstion: any) => {
					permisstions.push(permisstion.value);
				});
				formValues.Permissions = permisstions;
				formValues.PermissionAdded = permisstions ? permisstions : [];
			} else {
				formValues.PermissionAdded = [];
			}
			if (dataCoSoCongTrinh?.length > 0) {
				const contructionIds: any[] = [];
				dataCoSoCongTrinh.forEach((contructions: any) => {
					contructionIds.push(contructions.Id);
				});
				formValues.ConstructionId = contructionIds;
			} else {
				formValues.ConstructionId = [];
			}
			formValues.Id = Id;
			if (formValues.RoleIds?.length === 0 || formValues.RoleIds.length > 1) {
				formValues.RoleIds = [formValues.RoleIds];
			}
			// update
			UsersService.updateUser(formValues)
				.then(() => {
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: formValues.FullName }),
					);
					navigateUrl();
				})
				.catch((errors: { response: { data: string } }) => {
					showNotification('error', t('notification.error'), errors.response.data);
				});
		}
	};

	const navigateUrl = () => {
		if (customerId) {
			navigate({ pathname: `/nguoiDung?tab=quanLyKhachHang&child=EditKhachHang&Id=${customerId}` });
		} else {
			navigate({
				pathname: '/nguoiDung',
				search: `?tab=${NguoiDungTab.QUAN_LY_NGUOI_DUNG}`,
			});
		}
	};

	const DanhSachQuyen = () => {
		const [currentPageParent, setCurrentPageParent] = useState<number>(CURRENT_PAGE);
		const [pageSizeDefaultParent, setPageSizeDefaultParent] = useState<number>(PAGE_SIZE);

		const [currentPageChild, setCurrentPageChild] = useState<number>(CURRENT_PAGE);
		const [pageSizeDefaultChild, setPageSizeDefaultChild] = useState<number>(PAGE_SIZE);
		const columnsDanhSachQuyen: any = [
			{
				title: 'STT',
				dataIndex: 'key',
				align: 'center' as const,
				render: (value: any, item: any, index: any) => (currentPageParent - 1) * pageSizeDefaultParent + index + 1,
				width: 45,
			},
			{
				dataIndex: 'Name',
				title: 'Tên quyền',
			},
		]; // .filter(item => !item.hidden);

		const columnsQuyen: any = [
			{
				align: 'center',
				className: 'min-col',
				dataIndex: 'key',
				width: 30,
				render: (value: any, item: any, index: any) => (currentPageChild - 1) * pageSizeDefaultChild + index + 1,
				title: t('label.order'),
			},
			{
				dataIndex: 'label',
				title: 'Tên quyền',
				width: 350,
			},
			{
				align: 'center' as const,
				title: 'Thao tác',
				key: 'action',
				width: 100,
				hidden: mode === 'view',
				render: (_: any, record: any) => (
					<Button
						type={'text'}
						onClick={async (e: any) => {
							setPermissionTable(permissionTable.filter((item: any) => item !== record));
						}}>
						<img src="icon/Delete.png" alt="delete" />
					</Button>
				),
			},
		].filter(item => !item.hidden);

		const showTotal = (total: number) => {
			return (
				<span>
					Tổng: <strong>{total} quyền</strong>
				</span>
			);
		};

		const onchangeVaitro = (value: any, role: any) => {
			let array: any[] = [];
			if (value && role.Permissions?.length > 0) {
				role.Permissions.forEach((item: any) => {
					const value: any = {};
					value.Name = permissionEnum.fromEnum(item)?.label;
					array.push(value);
				});
				setDataDanhSachQuyen(array);
			} else {
				setDataDanhSachQuyen([]);
			}
		};

		function GetPhanQuyen(data: any) {
			setPermissionTable(data);
		}

		return (
			<>
				<Row>
					<Form.Item
						name="RoleIds"
						label="Vai trò"
						style={{ flex: '1 1' }}
						rules={[
							{
								required: true,
								message: 'Vai trò không được bỏ trống',
							},
						]}>
						<Select
							disabled={mode === 'view'}
							showSearch
							placeholder="Vai trò"
							optionFilterProp="children"
							allowClear
							onChange={onchangeVaitro}>
							{roleList}
						</Select>
					</Form.Item>
				</Row>
				<Row gutter={32} align="stretch">
					<Col span={12}>
						<label style={{ marginBottom: '10px', display: 'inline-block' }}>Danh sách quyền của vai trò</label>
						<Table
							className="table-header-bored"
							rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
							locale={{ emptyText: 'Không có dữ liệu' }}
							showSorterTooltip={false}
							dataSource={dataDanhSachQuyen.length > 0 ? dataDanhSachQuyen : dataVaiTro}
							pagination={{
								showTotal: showTotal,
								pageSize: pageSizeDefaultParent,
								onChange(page, pageSize) {
									setCurrentPageParent(page);
									setPageSizeDefaultParent(pageSize);
								},
							}}
							columns={columnsDanhSachQuyen}
							bordered
							style={{ whiteSpace: 'pre' }}
						/>
					</Col>

					<Col span={12}>
						<>
							<label>Danh sách quyền khác</label>
							<Button
								type={'link'}
								disabled={mode === 'view'}
								onClick={async () => {
									await CustomDialog(
										<ChiTietPhanQuyenDialog
											callback={GetPhanQuyen}
											datachosen={permissionTable}
											userInfo={props.userInfo}
										/>,
										{
											className: 'h60',
											title: 'Thêm quyền',
											showCloseIcon: true,
											isCanClose: true,
										},
									);
								}}>
								<PlusOutlined />
								Thêm quyền
							</Button>
						</>

						<Table
							className="table-header-bored"
							rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
							locale={{ emptyText: 'Không có dữ liệu' }}
							showSorterTooltip={false}
							dataSource={permissionTable}
							columns={columnsQuyen}
							bordered
							style={{ whiteSpace: 'pre' }}
							pagination={{
								showTotal: showTotal,
								pageSize: pageSizeDefaultChild,
								showSizeChanger: true,
								pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
								onChange(page, pageSize) {
									setCurrentPageChild(page);
									setPageSizeDefaultChild(pageSize);
								},
							}}
						/>
					</Col>
				</Row>
			</>
		);
	};

	const DanhSachCoSoCongTrinh = () => {
		enum PhoneNumberType {
			CHU_CO_SO_CONG_TRINH = 'CHU_CO_SO_CONG_TRINH',
			NGUOI_XAC_NHAN = 'NGUOI_XAC_NHAN',
			NGUOI_BACKUP = 'NGUOI_BACKUP',
		}
		interface PhoneNumber {
			PhoneNumber: string;
			FullName: string;
			Info: string;
			PhoneNumberType: PhoneNumberType;
		}
		const getPhoneNumber = (phoneNumbers: PhoneNumber[], type: PhoneNumberType) => {
			return phoneNumbers.find(phone => phone.PhoneNumberType === type);
		};
		const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
		const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
		const columnsCoSoCongTrinh: any = [
			{
				align: 'center',
				className: 'min-col',
				dataIndex: 'key',
				width: 30,
				render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
				title: t('label.order'),
			},
			{
				dataIndex: 'TenCoSoCongTrinh',
				title: 'Tên công trình',
			},
			{
				dataIndex: 'LocationDetail',
				title: 'Địa chỉ',
			},
			{
				title: 'SĐT chủ cơ sở',
				dataIndex: 'SoDienThoai',
				key: 'SoDienThoai',
				render: (phoneNumbers: PhoneNumber[]) => {
					const ownerConstructionPhone = getPhoneNumber(phoneNumbers, PhoneNumberType.CHU_CO_SO_CONG_TRINH);
					return ownerConstructionPhone?.PhoneNumber;
				},
			},
			// {
			// 	align: 'center' as const,
			// 	title: 'Thao tác',
			// 	key: 'action',
			// 	width: 50,
			// 	render: (_: any, record: any) => (
			// 		<Button
			// 			type={'text'}
			// 			onClick={async (e: any) => {
			// 				await CustomDialog(
			// 					<DialogDelete
			// 						Id={record.Id}
			// 						Name={record.TenCoSoCongTrinh}
			// 						mode={'coSoCongTrinh'}
			// 						callback={getDataCoSoCongTrinh}
			// 					/>,
			// 					{
			// 						showCloseIcon: true,
			// 						isCanClose: true,
			// 					},
			// 				);
			// 			}}>
			// 			<img src="icon/Delete.png" alt="delete" />
			// 		</Button>
			// 	),
			// },
		];

		const showTotal = (total: number) => {
			return (
				<span>
					Tổng: <strong>{total} cơ sở công trình</strong>
				</span>
			);
		};

		const handleOpenChonCoSoCongTrinhDialog = async () => {
			const selectedConstructionIds =
				dataCoSoCongTrinh?.length > 0 ? dataCoSoCongTrinh.map((item: any) => item?.Id) : [];
			await CustomDialog(
				<ChonCoSoCongTrinhDialog
					selectedConstructionIds={selectedConstructionIds}
					onGetSelectedConstructions={getSelectedConstructions}
					custommerId={form.getFieldValue('CustomerId')}
				/>,
				{
					className: 'themmoikehoachkt-dialog',
					title: 'Chọn cơ sở công trình',
					isCanClose: true,
					showCloseIcon: true,
				},
			);
		};

		const getSelectedConstructions = (selectedConstructionIds: string[]) => {
			getConstructionsByIds(selectedConstructionIds);
		};

		const getConstructionsByIds = (ids: string[]) => {
			// setLoading(true);
			ConstructionService.getConstructionsByIds(ids).then(data => {
				setDataCoSoCongTrinh(data);
				// setLoading(false);
			});
			// .catch(error => setLoading(false));
		};

		return (
			<div className="flex--col">
				<div className="flex--row mt10">
					<label className="heading--8">Cơ sở công trình</label>
					<Button disabled={mode === 'view'} className="ml10" type={'link'} onClick={handleOpenChonCoSoCongTrinhDialog}>
						<PlusOutlined />
						Thêm mới cơ sở công trình
					</Button>
				</div>

				<Table
					className="table-header-bored"
					rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					dataSource={dataCoSoCongTrinh}
					columns={columnsCoSoCongTrinh}
					bordered
					style={{ whiteSpace: 'pre' }}
					pagination={{
						showTotal: showTotal,
						pageSize: pageSizeDefault,
						showSizeChanger: true,
						pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
						onChange(page, pageSize) {
							setCurrentPage(page);
							setPageSizeDefault(pageSize);
						},
					}}
				/>
			</div>
		);
	};

	const OnchangePhanLoaiKhachHang = (value: any) => {
		form.resetFields(['CustomerId']);
		CustomerService.getCustomerAll(value).then((res: any) => {
			setDataCustomer(res);
		});
		setLoaiKhachHang(value);
	};

	const onChangeCheckBox = (e: CheckboxChangeEvent) => {
		setIsContinue(e.target.checked);
	};

	return (
		<div className="editKhachHang-container">
			<Form className={'common-dialog'} form={form} layout={'vertical'} onFinish={Id ? finishUpdate : finishCreate}>
				<Row gutter={32} className="mb15">
					<Col span={8}>
						<h2>{titleScreen} </h2>
						<h5 className="heading--8 font-weight--bold mb10 mt25">Thông tin chi tiết</h5>

						<Form.Item
							label="Tên người dùng"
							name="FullName"
							rules={[
								{
									whitespace: true,
									required: true,
									message: 'Tên người dùng không được bỏ trống!',
								},
							]}>
							<Input disabled={mode === 'view'} placeholder="Nhập tên người dùng" />
						</Form.Item>
						<Form.Item label="Phân loại khách hàng" name="CustomerType">
							<Select
								disabled={(customerId ? true : false) || mode === 'view'}
								showSearch
								optionFilterProp="children"
								// defaultValue={LoaiKhachHangEnum.CO_QUAN_CHUC_NANG}
								placeholder="Chọn phân loại khách hàng"
								onChange={OnchangePhanLoaiKhachHang}
								allowClear>
								{Generator.generateOptions(loaiKhachHangEnum.getAll())}
							</Select>
						</Form.Item>
						<Form.Item
							name="CustomerId"
							label="Trực thuộc"
							rules={[
								{
									required: true,
									message: 'Tên khách hàng không được bỏ trống',
								},
							]}>
							<Select
								disabled={(customerId ? true : false) || mode === 'view'}
								showSearch
								optionFilterProp="children"
								placeholder="Chọn khách hàng"
								onChange={onChangeLoaiKhachHang}
								allowClear>
								{custommerList}
							</Select>
						</Form.Item>

						<Form.Item
							label="Email"
							name="Email"
							rules={[
								{
									pattern: systemProperties.emailPattern,
									message: 'Email không đúng định dạng!',
								},
								{
									required: true,
									whitespace: true,
									message: 'Email không được bỏ trống',
								},
							]}>
							<Input disabled={mode === 'view'} placeholder="Nhập địa chỉ Email" />
						</Form.Item>

						<Form.Item
							name="PhoneNumber"
							label="Số điện thoại"
							rules={[
								{
									pattern: systemProperties.phonePattern,
									message: 'SĐT không đúng định dạng!',
								},
								{
									required: true,
									whitespace: true,
									message: t('validation.error.phoneNumber_empty'),
								},
							]}>
							<Input
								disabled={mode === 'view'}
								type={'number'}
								onKeyDown={blockInvalidChar}
								placeholder="Số điện thoại"
							/>
						</Form.Item>

						<Form.Item
							name="UserName"
							label={t('label.userName')}
							rules={[
								{
									whitespace: true,
									required: true,
									message: t('login.message.userNameEmpty'),
								},
								{
									pattern: systemProperties.userName,
									message: 'Tên đăng nhập phải tối thiểu 4 và tối đa 20 ký tự',
								},
							]}>
							<Input disabled={mode === 'view'} placeholder={t('label.userName')} />
						</Form.Item>

						{loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG && (
							<Form.Item name="TinhThanh" label="Tỉnh thành">
								<Select
									showSearch
									mode="multiple"
									optionFilterProp="children"
									disabled={mode === 'view'}
									placeholder="Tỉnh thành"
									allowClear
									onChange={onGenderChangeTinhThanh}>
									{form.getFieldValue('CustomerId')
										? arrayTinhthanh
										: props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
										? tinhThanhsAdmin
										: tinhThanhscustomer}
								</Select>
							</Form.Item>
						)}
						{loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG && (
							<Form.Item name="QuanHuyen" label="Quận Huyện">
								<Select
									disabled={mode === 'view'}
									showSearch
									mode="multiple"
									optionFilterProp="children"
									placeholder="Quận Huyện"
									allowClear>
									{arrayOptionQuanhuyen}
								</Select>
							</Form.Item>
						)}

						<Form.Item style={{ display: Id ? '' : 'none' }} name="IsActive" label="Trạng thái hoạt động">
							<Select
								showSearch
								optionFilterProp="Children"
								disabled={mode === 'view'}
								placeholder="Chọn trạng thái"
								allowClear>
								{utils.TrangThaiCustommer()}
							</Select>
						</Form.Item>
					</Col>
					<Col span={16} className="mt45" style={{ backgroundColor: 'none' }}>
						<Tabs defaultActiveKey="1">
							<TabPane tab="Phân quyền" key="1" destroyInactiveTabPane>
								<DanhSachQuyen />
							</TabPane>
							{form.getFieldValue('CustomerType') === LoaiKhachHangEnum.DAI_LY && (
								<TabPane tab="Danh sách cơ sở công trình" key="2" destroyInactiveTabPane>
									<DanhSachCoSoCongTrinh />
								</TabPane>
							)}
						</Tabs>
					</Col>
				</Row>

				<Divider />
				<Row justify="end" className={`mt10 ${Id ? '' : 'justify-content--between'}`}>
					<Checkbox style={{ display: Id ? 'none' : '' }} onChange={onChangeCheckBox}>
						Thêm và tiếp tục
					</Checkbox>
					<div>
						<Button type="default" onClick={navigateUrl}>
							{mode ? 'OK' : 'Hủy bỏ'}
						</Button>
						{mode !== 'view' && (
							<Button className="ml10" htmlType={'submit'} type="primary">
								{Id ? 'Cập nhật' : 'Thêm mới'}
							</Button>
						)}
					</div>
				</Row>
			</Form>
		</div>
	);
};

export default ThemNguoiDung;
