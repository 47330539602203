import {
	AccountGiamSatTrucTuyenArray, AccountUserArray, BaoCaoArray, CongTacChuaChayArray, CongTacPhongChayArray,
	DashBoardArray, DonViPhongChayArray, FactArray, PermissionEnum, ThietBiArray,
} from 'enum/permissionEnum';
import GetValueFromToken from 'hooks/userInfoHook';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import AuthService from 'services/api/authenService/authService';

interface AuthorizedRouteProps {
	isReqAuthenticated: boolean;
}

const AuthorizedRoute: FunctionComponent<AuthorizedRouteProps> = (props: AuthorizedRouteProps) => {
	const [urlDefault, setUrlDefault] = useState<string>('');

	useEffect(() => {
		const showDashBoard = checkOrPermission(DashBoardArray);
		const showNguoiDung = checkOrPermission(AccountUserArray);
		const giamSatTrucTuyen = checkOrPermission(AccountGiamSatTrucTuyenArray);
		const showDonViPCCC = checkOrPermission(DonViPhongChayArray);
		const showCongTacPhongChay = checkOrPermission(CongTacPhongChayArray);
		const showCongTacChuaChay = checkOrPermission(CongTacChuaChayArray);
		const showThietBi = checkOrPermission(ThietBiArray);
		const showBaoCao = checkOrPermission(BaoCaoArray);
		const showFact = checkOrPermission(FactArray);

		if (showDashBoard) {
			setUrlDefault('/dashboard');
		} else if (giamSatTrucTuyen) {
			setUrlDefault('/giamSatTrucTuyen');
		} else if (showDonViPCCC) {
			setUrlDefault('/quanLyDonViPhongChay');
		} else if (showCongTacPhongChay) {
			setUrlDefault('/quanLyCongTacPhongChay');
		} else if (showCongTacChuaChay) {
			setUrlDefault('/congTacChuaChayVaCNCH');
		} else if (showThietBi) {
			setUrlDefault('/camBien');
		} else if (showBaoCao) {
			setUrlDefault('/quanLyBaoCao');
		} else if (showNguoiDung) {
			setUrlDefault('/nguoiDung');
		} else if (showFact) {
			setUrlDefault('/quanLyChatLuongThietBi');
		}
	}, []);

	const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
		let accepted = false;

		orPermissions.forEach((r) => {
			if (GetValueFromToken.useGetPermission?.includes(r)) {
				accepted = true;
			}
		});

		return accepted;
	};

	if (props.isReqAuthenticated) {
		return AuthService.isAuthenticated() ? <Outlet /> : <Navigate to={'/login'} replace />;
	} else {
		return !AuthService.isAuthenticated() ? <Outlet /> : <Navigate to={urlDefault} replace />;
	}
};

export default AuthorizedRoute;
