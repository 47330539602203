import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Input, Tabs, Tag } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { ColumnsType } from 'antd/lib/table/interface';
import DialogCentralDeviceList from 'components/page/QuanLyBaoCao/CoSoCongTrinh/Dialog/DialogCentralDeviceList';
import { PermissionProps, QuanLyBaoCaoTab } from 'components/page/QuanLyBaoCao/QuanLyBaoCao';
import DialogThemMoiCbChienSy from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiCanBoChienSy';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import EvaluateSelect from 'components/shared/form-select/EvaluateSelect/Evaluate';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import danhGiaEnum, { DanhGiaEnum } from 'enum/danhGiaEnum';
import nganhLinhVucEnum from 'enum/nghanhLinhVucEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, formatDate, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { PageOption } from 'services/api';
import provinceService from 'services/api/provinceService';
import ReportConstructionCheckingService from 'services/api/reportService/reportConstructionCheckingService';

const { TabPane } = Tabs;

const KET_QUA_COLOR = [
	{
		key: 'Đảm bảo yêu cầu về PCCC',
		value: 'blue',
	},
	{
		key: 'Chưa đảm bảo',
		value: 'red',
	},
];

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const MAX_WIDTH_PER_CELL = 600;
const SUMMARY_TAB = '1';
const DETAIL_TAB = '2';
const EXPORT_SUMMARY_URL = 'ExportConstruction';
const EXPORT_DETAIL_URL = 'ExportConstructionDetail';
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const CoSoCongTrinhTab: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const getDataSources = useRef<any>({});
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNaviagte = navigateFromDashboardSelector.getNavigateStatus();

	// states of component
	const [totalItems, setTotalItems] = useState<number>(0);
	const [dataSources, setDataSource] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);
	const [currentTab, setCurrentTab] = useState(SUMMARY_TAB);
	const [disableExportButton, setDisableExportButton] = useState(true);
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);

	// table colums
	const safetyReportColumns: ColumnsType<any> = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			fixed: 'left',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tỉnh thành',
			dataIndex: 'TinhThanh',
			key: 'TinhThanh',
			fixed: 'left',
			width: 200,
		},
		{
			title: 'Quận huyện',
			dataIndex: 'QuanHuyen',
			key: 'QuanHuyen',
			fixed: 'left',
			width: 200,
		},
		{
			title: 'Đơn vị PCCC quản lý',
			dataIndex: 'PcccUnitName',
			key: 'PcccUnitName',
			width: 300,
			render: (PcccUnitName: string) => <span className="text-detail-info">{PcccUnitName}</span>,
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record.PcccUnitName ? record.PcccUnitName : null,
					<DialogThemMoiDonViPccc id={record.DonViPcccID} userInfo={props.userInfo} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{
			title: 'Cơ sở pháp lý',
			dataIndex: 'CheckingDto',
			key: 'CheckingDto',
			width: 300,
			render: CheckingDto => {
				if (CheckingDto && CheckingDto.ConstructionCheckingPlan) {
					return (
						<NavigateButton
							url={`/chiTietSoCuKiemTra?id=${CheckingDto.ConstructionCheckingPlan?.Id}&mode=view`}
							name={CheckingDto.ConstructionCheckingPlan?.Name}
						/>
					);
				}
				return;
			},
		},
		{
			title: 'Tên cơ sở kiểm tra',
			dataIndex: 'TenCoSoCongTrinh',
			key: 'TenCoSoCongTrinh',
			width: 300,
			render: (TenCoSoCongTrinh, record: any) => {
				return <NavigateButton url={`/chiTietCoSoCongTrinh?id=${record.Id}&mode=view`} name={TenCoSoCongTrinh} />;
			},
		},
		{
			title: 'Ngày kiểm tra',
			dataIndex: 'CheckedDate',
			key: 'CheckedDate',
			width: 150,
			render: (checkerDate: string) => <>{checkerDate ? formatDate(checkerDate) : null}</>,
		},
		{
			title: 'Cán bộ kiểm tra',
			dataIndex: 'CheckerName',
			key: 'CheckerName',
			width: 250,
			render: (CheckerName: string) => <span className="text-detail-info">{CheckerName}</span>,
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record.CheckerName ? `Cán bộ "${record.CheckerName}"` : null,
					<DialogThemMoiCbChienSy
						id={record.Checker && record?.Checker.length > 0 ? record.Checker.at(0) : null}
						mode="view"
					/>,
				);
			},
		},
		{
			title: 'Nội dung kiểm tra',
			dataIndex: 'CheckedContent',
			key: 'CheckedContent',
			width: 150,
		},
		{
			title: 'Đánh giá',
			dataIndex: 'ResultCheckedDate',
			key: 'ResultCheckedDate',
			width: 200,
			render: ResultCheckedDate => (
				<Tag color={KET_QUA_COLOR.find(r => ResultCheckedDate === r.key)?.value} key={ResultCheckedDate}>
					{ResultCheckedDate}
				</Tag>
			),
		},
	];

	// TODO: cho thong nhat cac cot hien thi
	const detailReportColumns: ColumnsType<any> = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			fixed: 'left',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên cơ sở kiểm tra',
			dataIndex: 'TenCoSoCongTrinh',
			key: 'TenCoSoCongTrinh',
			fixed: 'left',
			width: 300,
			render: (TenCoSoCongTrinh, record: any) => {
				return <NavigateButton url={`/chiTietCoSoCongTrinh?id=${record.Id}&mode=view`} name={TenCoSoCongTrinh} />;
			},
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			key: 'LocationDetail',
			fixed: 'left',
			width: 350,
		},
		{
			title: 'Chủ cơ sở',
			dataIndex: 'ChuCSCT',
			key: 'ChuCSCT',
			width: 200,
		},
		{
			title: 'Loại hình cơ sở',
			dataIndex: 'NghanhLinhVuc',
			key: 'NghanhLinhVuc',
			width: 200,
			render: NghanhLinhVuc => {
				return NghanhLinhVuc && nganhLinhVucEnum.fromEnum(NghanhLinhVuc).label;
			},
		},
		{
			title: 'Ngày phê duyệt PCCC',
			dataIndex: 'ApprovePcccDate',
			key: 'ApprovePcccDate',
			width: 150,
			render: (ApprovePcccDate: string) => <>{ApprovePcccDate ? formatDate(ApprovePcccDate) : null}</>,
		},

		{
			title: 'Ngày cấp giấy phép hoạt động',
			dataIndex: 'AprroveOperateDate',
			key: 'AprroveOperateDate',
			width: 150,
			render: (AprroveOperateDate: string) => <>{AprroveOperateDate ? formatDate(AprroveOperateDate) : null}</>,
		},
		{
			title: 'Đơn vị PCCC quản lý',
			dataIndex: 'PcccUnitName',
			key: 'PcccUnitName',
			width: 250,
			render: (PcccUnitName: string) => <span className="text-detail-info">{PcccUnitName}</span>,
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record.PcccUnitName ? record.PcccUnitName : null,
					<DialogThemMoiDonViPccc id={record.DonViPcccID} userInfo={props.userInfo} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{
			width: 150,
			title: 'Đơn vị lắp đặt',
			dataIndex: 'InstallUnit',
			key: 'InstallUnit',
		},
		{
			width: 100,
			title: 'Số lần kiểm tra',
			dataIndex: 'CheckedCount',
			key: 'CheckedCount',
			align: 'right' as const,
		},
		{
			width: 200,
			title: 'Kết quả kiểm tra gần nhất',
			dataIndex: 'ResultCheckedDate',
			key: 'ResultCheckedDate',
			render: ResultCheckedDate => (
				<Tag color={KET_QUA_COLOR.find(r => ResultCheckedDate === r.key)?.value} key={ResultCheckedDate}>
					{ResultCheckedDate}
				</Tag>
			),
		},
		{
			width: 150,
			title: 'Ghi chú',
			dataIndex: 'Note',
			key: 'Note',
		},
	];

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		getDataSources.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: any) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});

		getDataSources.current(CURRENT_PAGE);
	};

	const getRequestBody = () => {
		return {
			Name: form.getFieldValue('Name'),
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			},
			Evaluate: form.getFieldValue('Evaluate'),
		};
	};

	const convertToDataSources = async (constructionDetail: any) => {
		const {
			Location: { TinhThanh, QuanHuyen },
			ResultCheckedDate,
		} = constructionDetail;
		const province = await provinceService.getProvince(TinhThanh);
		const district = await provinceService.getDistrict({ cityId: TinhThanh, districtId: QuanHuyen });
		const evaluateResult = danhGiaEnum.fromEnum(ResultCheckedDate);

		return {
			...constructionDetail,
			TinhThanh: province?.Name,
			QuanHuyen: district?.Name,
			...(evaluateResult && { ResultCheckedDate: evaluateResult.label }),
		};
	};

	getDataSources.current = async (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);

		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const requestBody = getRequestBody();
		setDisableExportButton(!requestBody.Location.QuanHuyen);
		try {
			const { Data, TotalItems } = await ReportConstructionCheckingService.searchDataConstructionDetail(
				requestBody,
				pageOption,
			);
			if (Data && Data.length > 0) {
				const newDataSources = await Promise.all(Data!.map(async (item: any) => await convertToDataSources(item)));
				setDataSource(newDataSources);
			} else {
				setDataSource([]);
			}
			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	const handleChangePage = (page: number, pageSize: number) => {
		getDataSources.current(page, pageSize);
	};

	const handleExportFileExcel = () => {
		const isSummaryTab = currentTab === SUMMARY_TAB;
		const exportFilePayload = getRequestBody();
		ReportConstructionCheckingService.exportFileExcel(
			exportFilePayload,
			isSummaryTab ? EXPORT_SUMMARY_URL : EXPORT_DETAIL_URL,
		)
			.then(data => {
				const fileName = isSummaryTab
					? 'Fire_Construction_Checking_Summary_Report.xlsx'
					: 'Fire_Truck_Construction_Checking_Detail_Report.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	};

	const handleChangeTab = (tab: string) => {
		setCurrentTab(tab);
		getDataSources.current(CURRENT_PAGE, PAGE_SIZE);
	};

	const handleShowCentralDeviceList = (event: any) => {
		return {
			onClick: async () => {
				await CustomDialog(<DialogCentralDeviceList Id={event.Id} />, {
					className: 'dialog-add',
					title: 'Danh sách thiết bị trung tâm',
					showCloseIcon: true,
					isCanClose: true,
				});
			},
		};
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['Name']);
		}

		getDataSources.current(CURRENT_PAGE);
	};

	useEffect(() => {
		document.title = t('quanLyBaoCao.baoCaoCoSoCongTrinh');
	}, []);

	useEffect(() => {
		if (searchParams.get('tab') === QuanLyBaoCaoTab.CO_SO_CONG_TRINH && !isNaviagte) {
			getDataSources.current();
		}
	}, [searchParams, isNaviagte]);

	useEffect(() => {
		if (isNaviagte) {
			setProvinceId(provinceIdFromDashboard);
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
				Evaluate: DanhGiaEnum.DAM_BAO,
			});

			getDataSources.current(CURRENT_PAGE);
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
	}, [isNaviagte, form, provinceIdFromDashboard, districtIdFromDashboard]);

	const formAction = (
		<>
			<div className={'flex--row'}>
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={handleChangeDistrict}
				/>

				<UnitManagerSelect
					name="PcccUnitId"
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					location={location}
					onChangeUnitManager={() => getDataSources.current(CURRENT_PAGE)}
				/>

				<EvaluateSelect
					name="Evaluate"
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					onChangeEvaluate={() => getDataSources.current(CURRENT_PAGE)}
				/>

				<FormItem className="ml10" name="Name">
					<Input.Search
						placeholder="Nhập tên cơ sở kiểm tra"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</FormItem>
			</div>

			{props.permissionList.includes(PermissionEnum.REPORT_CO_SO_CONG_TRINH_EXPORT) ||
			props.permissionList.includes(PermissionEnum.ADMIN) ? (
				<Button
					type="primary"
					icon={<DownloadOutlined />}
					onClick={handleExportFileExcel}
					disabled={dataSources.length === 0 || disableExportButton}>
					Xuất Excel
				</Button>
			) : null}
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cơ sở công trình
			</span>
		);
	};

	// Select row show detail
	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				setCurrentTab(currentTab === SUMMARY_TAB ? DETAIL_TAB : SUMMARY_TAB);

				const requestBody = {
					Location: {
						TinhThanh: record.Location.TinhThanh,
						QuanHuyen: record.Location.QuanHuyen,
					},
				};

				const pageOption = {
					CurrentPage: currentPage,
					PageSize: pageSizeDefault,
					Paging: true,
				};
				try {
					const { Data, TotalItems } = await ReportConstructionCheckingService.searchDataConstructionDetail(
						requestBody,
						pageOption,
					);
					if (Data && Data.length > 0) {
						const newDataSources = await Promise.all(Data!.map(async (item: any) => await convertToDataSources(item)));
						setDataSource(newDataSources);
					} else {
						setDataSource([]);
					}
					setTotalItems(TotalItems!);
				} catch (error) {}
			},
		};
	};

	const formTable = (
		<Tabs defaultActiveKey={SUMMARY_TAB} activeKey={currentTab} onTabClick={handleChangeTab}>
			<TabPane tab="Báo cáo công tác kiểm tra an toàn PCCC" key={SUMMARY_TAB}>
				<TableComponent
					containerClass="mt20"
					dataSource={dataSources}
					columns={safetyReportColumns}
					width={MAX_WIDTH_PER_CELL}
					onRow={handleShowViewDetail}
					paginator={{
						defaultPageSize: pageSizeDefault,
						showSizeChanger: true,
						onChange: handleChangePage,
						total: totalItems,
						current: currentPage,
						pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
						showTotal: showTotal,
					}}
				/>
			</TabPane>
			<TabPane tab="Báo cáo chi tiết" key={DETAIL_TAB}>
				<TableComponent
					containerClass="mt20"
					dataSource={dataSources}
					columns={detailReportColumns}
					width={MAX_WIDTH_PER_CELL}
					paginator={{
						defaultPageSize: pageSizeDefault,
						showSizeChanger: true,
						onChange: handleChangePage,
						total: totalItems,
						current: currentPage,
						pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
						showTotal: showTotal,
					}}
					onRow={handleShowCentralDeviceList}
				/>
			</TabPane>
		</Tabs>
	);

	return (
		<PageWrapper titlePage="Báo cáo về cơ sở công trình" formAction={formAction} formTable={formTable} form={form} />
	);
};

export default CoSoCongTrinhTab;
