import { Button, Col, Divider, Form, Input, message, Row, Select } from 'antd';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import CustomMap, { CustomMapIn, CustomMapOut } from 'components/shared/CustomMap/CustomMap';
import loaiDiemLayNuocEnum from 'enum/loaiDiemLayNuocEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import coordinatesService from 'services/api/coordinatesService';
import pcccUnitService from 'services/api/inventoryService/pcccUnitService';
import waterPointService from 'services/api/inventoryService/waterPointService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import { MarkerType } from '../../../shared/Map/ToaDoMarker';
import { LocationInfo } from '../Tabs/QuanLyDonVi/QuanLyDonVi';
import './DialogMap.scss';

const { Option } = Select;
const { TextArea } = Input;

interface DialogThemMoiDiemLayNuocProps {
	mode?: 'edit' | 'add' | 'view';
	id?: string;
	callback?: () => void;
	userInfo?: UserInfo;
	location?: LocationInfo;
	donviId?: string;
	mapInput?: CustomMapIn;
}

enum WaterSupplyCapacity {
	POSSIBLE = 1,
	NOT_POSSIBLE = 0,
}

const DialogThemMoiDiemLayNuoc: FunctionComponent<DialogThemMoiDiemLayNuocProps> = (
	props: DialogThemMoiDiemLayNuocProps,
) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	// state of component
	const [mapInput, setMapInput] = useState<CustomMapIn>();
	const [mapOutput, setMapOutput] = useState<CustomMapOut>();
	const [optionUnits, setOptionUnits] = useState<JSX.Element[]>([]);

	const generateOptionUnits = (data: any[]) => {
		if (data && data.length > 0) {
			const elements = data.map(item => (
				<Option key={item.Id} value={item.Id}>
					{item.TenDonVi ? item.TenDonVi : item.Name}
				</Option>
			));
			setOptionUnits(elements);
		}
	};

	const handleChangeInputMapData = (newVal: CustomMapOut) => {
		setMapOutput(newVal);
		// form.resetFields(['PcccUnitId']);
		setOptionUnits([]);
		const params = {
			Location: {
				TinhThanh: newVal.Location.TinhThanh,
				QuanHuyen: newVal.Location.QuanHuyen,
			},
			IncludePC07: true, // Thêm yêu cầu của QuynhNT
		};
		pcccUnitService.searchPcccUnitForSelect(params).then(generateOptionUnits);
	};

	const postWaterSupplyCapacity = async () => {
		const validated = await form.validateFields();
		const id = props.id;
		if (validated) {
			const waterSupplyCapacityInfo: any = {
				MaDiem: form.getFieldValue('MaDiem'),
				TenDiemLayNuoc: form.getFieldValue('TenDiemLayNuoc'),
				PcccUnitId: props.donviId,
				Type: form.getFieldValue('Type'),
				PhoneNumber: form.getFieldValue('PhoneNumber'),
				MoTa: form.getFieldValue('MoTa'),
				WaterForFireTruck: Boolean(form.getFieldValue('WaterForFireTruck')),
				DoQuanTrong: form.getFieldValue('DoQuanTrong'),
				...mapOutput,
			};

			try {
				if (id) {
					waterSupplyCapacityInfo.Id = id;
					await waterPointService.updateWaterPoint(waterSupplyCapacityInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: form.getFieldValue('TenDiemLayNuoc') }),
					);
				} else {
					// add a new unit support
					await waterPointService.createWaterPoint(waterSupplyCapacityInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_create_desc', { item: form.getFieldValue('TenDiemLayNuoc') }),
					);
				}

				dialog.close();
				if (props.callback) {
					props.callback();
				}
			} catch (error: any) {
				showNotification('error', t('notification.error'), error.response.data);
			}
		}
	};

	useEffect(() => {
		const id = props.id;

		const getUnitPcccById = async (param: any) => {
			const result = await pcccUnitService.searchPcccUnitForSelect(param);
			generateOptionUnits(result);
		};

		const getWaterSupplyCapacityById = async (id: string) => {
			const data = await waterPointService.getWaterPoint(id);

			await getUnitPcccById({ Location: { TinhThanh: data.Location?.TinhThanh, QuanHuyen: data.Location?.QuanHuyen } });
			form.setFieldsValue({
				...data,
				WaterForFireTruck: data['WaterForFireTruck'] ? WaterSupplyCapacity.POSSIBLE : WaterSupplyCapacity.NOT_POSSIBLE,
			});

			setMapInput(data);
		};

		if (id) {
			getWaterSupplyCapacityById(id);
		} else {
			form.setFieldsValue({
				TinhThanh: props.location?.TinhThanh,
				QuanHuyen: props.location?.QuanHuyen,
			});
			setMapInput(props.mapInput);
		}
	}, [props.id, form]);

	return (
		<div className="common-dialog dialog-units-support">
			<Form layout="vertical" name="normal_login" form={form}>
				<Row gutter={32} className="mb15">
					<Col span={10}>
						<ColumnTitle columnTitle="I. Vị trí" />
						<CustomMap
							containerClass={'height-map'}
							form={form}
							data={mapInput}
							markerType={MarkerType.DIEM_LAY_NUOC}
							changeDataCallback={handleChangeInputMapData}
						/>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="II. Thông tin chung" />
						<div>
							<Form.Item
								label="Mã điểm lấy nước"
								name="MaDiem"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Mã điểm không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập mã điểm" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Tên điểm lấy nước"
								name="TenDiemLayNuoc"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên điểm không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên điểm" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Số điện thoại"
								name="PhoneNumber"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder={t('placeholder.phoneNumber')}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item name="Type" label="Loại điểm lấy nước">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn loại điểm lấy nước"
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(loaiDiemLayNuocEnum.getAll())}
								</Select>
							</Form.Item>

							{/* <Form.Item name="PcccUnitId" label="Đơn vị PCCC">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn một đơn vị"
									allowClear
									disabled={props.mode === 'view'}>
									{optionUnits}
								</Select>
							</Form.Item> */}
						</div>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="III. Thông tin thêm" />
						<div>
							<Form.Item name="DoQuanTrong" label="Độ quan trọng">
								<Input placeholder="Nhập độ quan trọng" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item name="WaterForFireTruck" label="Khả năng cấp nước cho xe chữa cháy">
								<Select placeholder="Chọn khả năng" allowClear disabled={props.mode === 'view'}>
									<Option key={WaterSupplyCapacity.POSSIBLE} value={WaterSupplyCapacity.POSSIBLE}>
										Có khả năng
									</Option>
									<Option key={WaterSupplyCapacity.NOT_POSSIBLE} value={WaterSupplyCapacity.NOT_POSSIBLE}>
										Không có khả năng
									</Option>
								</Select>
							</Form.Item>

							<Form.Item label="Mô tả" name="MoTa">
								<TextArea rows={4} placeholder="Nhập mô tả" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Divider />

				<div className="dialog__buttons mt10">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						{props.mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{props.mode !== 'view' && (
						<Button type="primary" htmlType="submit" onClick={postWaterSupplyCapacity}>
							{props.mode === 'add' ? 'Thêm mới' : 'Lưu'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogThemMoiDiemLayNuoc;
