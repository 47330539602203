import { Form, FormInstance, Upload } from 'antd';
import { UploadFileOption } from 'constants/uploadFileOption.const';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React from 'react';
import FireFileBusinessService from 'services/api/businessService/FileBusinessService';

interface UploadFileInputProps {
	disabled?: boolean;
	form?: FormInstance;
	lable?: string;
}

const UploadFileInput: React.FC<UploadFileInputProps> = ({
	disabled = false,
	lable = 'Đính kèm tập tin',
}: UploadFileInputProps) => {
	const uploadFileProps = {
		multiple: false,
		beforeUpload(file: any) {
			if (file.size > UploadFileOption.LIMIT_SIZE) {
				showNotification('error', t('notification.error'), 'Kích thước tập tin không quá 20MB');
				return Upload.LIST_IGNORE;
			}
			if (!UploadFileOption.ALLOWED_EXTENSIONS.exec(file.name)) {
				showNotification('error', t('notification.error'), 'Tập tin không đúng định dạng');
				return Upload.LIST_IGNORE;
			}
			return true;
		},
		onChange(info: any) {
			const { status, error } = info.file;
			if (status === 'done') {
				showNotification('success', t('notification.success'), 'Tập tin tải lên thành công');
			} else if (status === 'error') {
				if (error) {
					showNotification('error', t('notification.error'), `${error}`);
				} else {
					showNotification('error', t('notification.error'), 'Tập tin tải lên thất bại');
				}
			}
		},
		onRemove: async (file: any) => {
			const { response } = file;
			if (response && response.FileUrl) {
				const fileName = response.FileUrl.split('/').at(-1);

				try {
					await FireFileBusinessService.deleteFileBusiness(fileName);
					showNotification('success', t('notification.success'), 'Xóa tập tin thành công');
					return true;
				} catch (error) {
					showNotification('error', t('notification.error'), `${error}`);
					return false;
				}
			}
		},
	};

	const uploadFile = async (options: any) => {
		const { onSuccess, onError, file, lable } = options;

		// set form data
		const formData = new FormData();
		const extensionPattern = /\.[0-9a-z]+$/i;
		const fileExtension = file.name.match(extensionPattern)[0].substring(1);
		formData.append('UploadedFile', file);
		formData.append('FileExtension', fileExtension);

		// upload file
		FireFileBusinessService.uploadFile(formData)
			.then(res => {
				onSuccess(res);
			})
			.catch(err => {
				if (err.response) {
					onError(err.response.data);
				}
			});
	};

	const normFile = (e: any) => {
		if (Array.isArray(e)) {
			return e;
		}
		return e && e.fileList;
	};

	return (
		<Form.Item
			name="fileUpload"
			valuePropName="fileList"
			getValueFromEvent={normFile}
			label={lable}>
			<Upload.Dragger
				name="files"
				{...uploadFileProps}
				customRequest={uploadFile}
				disabled={disabled}
				style={{ backgroundColor: '#f1f6fe' }}>
				<p className="ant-upload-text">
					<img src="icon/upload.png" alt="upload" />
					<span style={{ color: '#1B56B3' }}>Chọn file</span> hoặc kéo thả file vào đây
				</p>
				<p className="ant-upload-hint">
					<i>Kích thước tập tin không quá 20MB, định dạng pdf, png hoặc jpg</i>
				</p>
			</Upload.Dragger>
		</Form.Item>
	);
};

export default UploadFileInput;
