import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import { QuanLyDonViPhongChayTabProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import { QuanLyDonViPhongChayTab } from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import ExportExcelComponent from 'components/shared/ExportExcelComponent/ExportExcelComponent';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiDonViEnum from 'enum/loaiDonViEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import { default as SupportUnitService } from 'services/api/inventoryService/supportUnitService';
import ProvinceService, { SearchDistrictsParams } from 'services/api/provinceService';
import DialogThemMoiDonViHoTro from '../../Dialogs/DialogThemMoiDonViHoTro';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DanhSachDonViHoTro: FunctionComponent<QuanLyDonViPhongChayTabProps> = props => {
	const columnsDsDonViHoTro = [
		{
			title: 'STT',
			dataIndex: 'key',
			align: 'center' as const,
			key: 'name',
			width: 45,
			ellipsis: {
				showTitle: false,
			},
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Mã đơn vị',
			dataIndex: 'MaDonVi',
			key: 'MaDonVi',
			ellipsis: {
				showTitle: false,
			},
			render: (MaDonVi: string) => (
				<Tooltip placement="topLeft" title={MaDonVi}>
					{MaDonVi}
				</Tooltip>
			),
		},
		{
			title: 'Tên đơn vị',
			dataIndex: 'Tendonvi',
			key: 'Tendonvi',
			ellipsis: {
				showTitle: false,
			},
			render: (Tendonvi: string) => (
				<Tooltip placement="topLeft" title={Tendonvi}>
					{Tendonvi}
				</Tooltip>
			),
		},
		{
			title: 'Loại đơn vị',
			dataIndex: 'LoaiDonVi',
			key: 'LoaiDonVi',
			ellipsis: {
				showTitle: false,
			},
			render: (LoaiDonVi: string) => (
				<Tooltip placement="topLeft" title={LoaiDonVi}>
					{LoaiDonVi}
				</Tooltip>
			),
		},
		{
			title: 'Số điện thoại',
			dataIndex: 'SoDienThoai',
			key: 'SoDienThoai',
			ellipsis: {
				showTitle: false,
			},
			render: (SoDienThoai: string) => (
				<Tooltip placement="topLeft" title={SoDienThoai}>
					{SoDienThoai}
				</Tooltip>
			),
		},
		{
			title: 'Quận huyện',
			dataIndex: 'QuanHuyen',
			key: 'address',
			ellipsis: {
				showTitle: false,
			},
			render: (TenPhuongTien: string) => (
				<Tooltip placement="topLeft" title={TenPhuongTien}>
					{TenPhuongTien}
				</Tooltip>
			),
		},
		{
			title: 'Tỉnh thành',
			dataIndex: 'TinhThanh',
			key: 'address',
			ellipsis: {
				showTitle: false,
			},
			render: (TenPhuongTien: string) => (
				<Tooltip placement="topLeft" title={TenPhuongTien}>
					{TenPhuongTien}
				</Tooltip>
			),
		},
		{
			className: 'nowrap',
			title: 'Chi tiết',
			width: 60,
			key: 'detail',
			align: 'center' as const,
			hidden: !props.isModeView,
			render: (_: any, record: any) => (
				<Button type={'primary'}>
					<Link
						to={{
							pathname: '/chiTietDonViHoTro',
							search: `?id=${record.Id}`,
							hash: 'detail',
						}}>
						<EyeOutlined />
					</Link>
				</Button>
			),
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			width: 100,
			key: 'action',
			align: 'center' as const,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_DELETE) ||
				props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_EDIT)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					className="dialog-water-supply-add"
					editTitle={`Chỉnh sửa thông tin đơn vị "${record.Tendonvi}"`}
					EditDialog={
						<DialogThemMoiDonViHoTro
							userInfo={props.userInfo}
							id={record.Id}
							mode="edit"
							callback={getDataDonViHoTroByLocation}
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.Tendonvi}
							callback={getDataDonViHoTroByLocation}
							deleteService={SupportUnitService.deleteSupportUnit}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [form] = Form.useForm();

	const [tongDonViHoTro, setTongDonViHoTro] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSourceDSDonViHoTro, setDataSourceDSDonViHoTro] = useState<any[]>([]);

	useEffect(() => {
		if (props.searchData) {
			getDataDonViHoTroByLocation(CURRENT_PAGE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchData]);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.SUPPORT_UNIT_VIEW])) {
				getDataDonViHoTroByLocation();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	const getRequestPayload = useCallback(() => {
		const unitName = form.getFieldValue('TimKiemHoTro');
		let param = {
			...props.searchData,
			...(unitName && { Name: unitName }),
		};

		return param;
	}, [props.searchData, form]);

	const getDataDonViHoTroByLocation = (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const unitName = form.getFieldValue('TimKiemHoTro');
		let param = {
			...props.searchData,
			...(unitName && { Name: unitName }),
		};

		SupportUnitService.SearchSupportUnitByLocation(param, pageOption)
			.then(res => {
				setTongDonViHoTro(res.TotalItems);
				optiondanhsachDonViHoTro(res.Data ? res.Data : []);
			})
			.catch();
	};

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			getDataDonViHoTroByLocation(CURRENT_PAGE);
		}
	};

	const onClickRefresh = () => {
		form.resetFields();
		props.onResetFormAction && props.onResetFormAction();
	};

	const optiondanhsachDonViHoTro = (data: any[], isExport?: boolean) => {
		let array: any[] = [];
		let promises: PromiseLike<any>[] = [];
		if (data.length > 0) {
			data.forEach((item, index) => {
				if (item?.Location !== null) {
					promises.push(
						ProvinceService.getProvince(item.Location.TinhThanh).then((result: any) => {
							item.TinhThanh = result?.Name;
						}),
					);
					const param: SearchDistrictsParams = {
						cityId: item.Location.TinhThanh,
						districtId: item.Location.QuanHuyen,
					};
					promises.push(
						ProvinceService.getDistrict(param).then((result: any) => {
							item.QuanHuyen = result?.Name;
						}),
					);
				}
				// item.key = ++_index;
				item.LoaiDonVi = loaiDonViEnum.fromEnum(item.LoaiDonVi)?.label;
				array.push(item);
			});
		}

		Promise.all(promises)
			.then(() => {
				setDataSourceDSDonViHoTro(array);
			})
			.catch();
	};

	const handleExportFileExcel = useCallback(() => {
		const exportFilePayload = getRequestPayload();
		SupportUnitService.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Don_Vi_Ho_Tro.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	}, [getRequestPayload]);

	function handleChangePage(page: number, pageSize: number) {
		getDataDonViHoTroByLocation(page, pageSize);
	}

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> đơn vị hỗ trợ
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={tongDonViHoTro}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(
					<DialogThemMoiDonViHoTro
						id={record.Id}
						userInfo={props.userInfo}
						mode="view"
						callback={getDataDonViHoTroByLocation}
					/>,
					{
						className: 'dialog-water-supply-add',
						title: `${record.Tendonvi}`,
						showCloseIcon: true,
						isCanClose: true,
					},
				);
			},
		};
	};
	return (
		<Form
			layout="vertical"
			name="normal_login"
			className="content-container flex--fill p0"
			form={form}
			disabled={props.isDisable}>
			<div id={QuanLyDonViPhongChayTab.DON_VI_HO_TRO} className={'tab__content'}>
				<div className={'flex--row justify-content--between mb15'}>
					<div className="search-input">
						<FormItem name="TimKiemHoTro">
							<Input placeholder="Nhập tên đơn vị" prefix={<SearchOutlined />} onKeyPress={handleKeyDown} allowClear />
						</FormItem>
					</div>

					<div>
						{/* <Button
							className="ml10"
							style={{ display: props.isModeView ? 'none' : '' }}
							type={'default'}
							onClick={() => {
								onClickRefresh();
							}}>
							<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
						</Button> */}

						<Link to={'/excelTemplate/Đơn vị hỗ trợ.xlsx'} target={'_blank'} download>
							<Button type={'default'} className="ml10" style={{ display: props.isModeView ? 'none' : '' }}>
								<img className="padding-image" style={{ width: 12 }} src="icon/downExcel.png" alt="Tải file" />{' '}
								{t('label.taiFileMau')}
							</Button>
						</Link>

						{props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_EXPORT) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<ExportExcelComponent
								className="ml10"
								dataSources={dataSourceDSDonViHoTro}
								onExportFileExcel={handleExportFileExcel}
							/>
						) : null}

						{props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_CREATE) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<Button
								className="ml10"
								type={'primary'}
								onClick={async () => {
									await CustomDialog(
										<DialogThemMoiDonViHoTro
											userInfo={props.userInfo}
											mode="add"
											location={props.searchData?.Location}
											donviId={props.donviId}
											callback={getDataDonViHoTroByLocation}
											mapInput = {props.mapInput}
										/>,
										{
											className: 'dialog-water-supply-add',
											title: 'Thêm mới đơn vị hỗ trợ ',
											isCanClose: true,
											showCloseIcon: true,
										},
									);
								}}>
								<PlusOutlined />
								Thêm mới
							</Button>
						) : null}
					</div>
				</div>

				<TableComponent
					dataSource={dataSourceDSDonViHoTro}
					columns={columnsDsDonViHoTro}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					paginator={false}
					onRow={handleShowViewDetail}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(DanhSachDonViHoTro);
