import { PlusOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row, Select, Table, Tabs, Tag } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import TableAction from 'components/shared/TableAction/TableAction';
import TitleComponent from 'components/shared/TitleComponent/TitleComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiKhachHangEnum, { LoaiKhachHangEnum } from 'enum/loaiKhachHangEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import generateUniqueId from 'helpers/generateUniqueId';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import UserService, { CreateUserParams, UserInfo } from 'services/api/authenService/usersService';
import ConstructionService from 'services/api/inventoryService/constructionService';
import CustomerService from 'services/api/inventoryService/customerService';
import { default as provinceService, default as ProvinceService } from 'services/api/provinceService';
import utils from 'services/api/utils';
import Generator from 'services/generator';
import GeneratorProvinceUser from 'services/generator/ProvinceUser';
import { systemProperties } from 'systemProperties';
import { blockInvalidChar } from '../CommonControl/BlockInvalidChar';
import ChiTietKhachHangDialog from './Dialog/ChiTietKhachHangDialog';
import DialogDelete from './Dialog/DialogDelete';
import './EditKhachHang.scss';
import { NguoiDungTab } from './NguoiDung';

interface ChiTietNguoiDungProps {
	userInfo?: UserInfo;
	callback?: () => void;
}

interface RecordType {
	key: string;
	title: string;
	// description: string;
	chosen: boolean;
}

const { Option } = Select;

const { TabPane } = Tabs;

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const EditKhachHang: FunctionComponent<ChiTietNguoiDungProps> = (props: ChiTietNguoiDungProps) => {
	const [form] = Form.useForm();

	const [searchParams] = useSearchParams();
	const Id = useMemo(() => searchParams.get('Id'), [searchParams]);
	const mode = useMemo(() => searchParams.get('mode'), [searchParams]);

	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);

	const [loaiKhachHang, setLoaiKhachHang] = useState<string>('CO_QUAN_CHUC_NANG');

	const [arrayTinhthanh, updateArrarrayTinhthanh] = useState<JSX.Element[] | null>();

	const [locations, setLocations] = useState<any>([]);

	const [quanHuyenList, setQuanHuyenList] = useState<any[]>([]);
	const [quanHuyenIdUser, setQuanHuyenIdUser] = useState<JSX.Element[]>([]);

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const [custommerList, setCustommerList] = useState<JSX.Element[]>([]);

	const [titleScreen, setTitleScreen] = useState<string>();

	useEffect(() => {
		let quanHuyenArray: any[] = [];
		let tinhThanhArray: any[] = [];

		let title = 'Thêm mới khách hàng';
		if (mode === 'view') {
			title = 'Chi tiết thông tin khách hàng';
		} else if (Id) {
			title = 'Sửa thông tin khách hàng';
		}
		setTitleScreen(title);

		CustomerService.getCustomerAll().then((res: any) => {
			setDataCustomer(res);
		});

		if (Id) {
			CustomerService.getCustomer(Id).then((result: any) => {
				setLoaiKhachHang(result.CustomerType);
				if (result.ParentId) {
					customTinhThanh(result);
					onChangeLoaiKhachHang(result.ParentId);
				} else {
					updateArrarrayTinhthanh(
						props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer,
					);
				}

				form.setFieldsValue(result);

				if (result && result.Locations?.length > 0) {
					setLocations(result.Locations);
					result.Locations.forEach((location: any) => {
						if (location.CityId) if (!tinhThanhArray.includes(location.CityId)) tinhThanhArray.push(location.CityId);
						if (location.DistrictId) quanHuyenArray.push(location.DistrictId);
					});
					onGenderChangeTinhThanh(tinhThanhArray);
					form.setFieldsValue({
						QuanHuyen: quanHuyenArray,
						TinhThanh: tinhThanhArray,
					});
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [Id]);

	const customTinhThanh = (res: any) => {
		let result: any = [];
		let tinhThanhList: any = [];
		let LocationUserList: any = [{}];
		let quanHuyens: any = [];
		let quanHuyensId: any = [];
		let array: any[] = [];
		ProvinceService.listProvince().then(tinhThanhs => {
			if (res.Locations?.length > 0) {
				res.Locations.forEach((location: any) => {
					tinhThanhs.forEach(tinhThanh => {
						if (tinhThanh.Id === location.CityId && !tinhThanhList.includes(tinhThanh.Id)) {
							tinhThanhList.push(tinhThanh.Id);
							result.push(tinhThanh);
						}

						if (location.DistrictId) {
							quanHuyensId.push(location.DistrictId);
							LocationUserList.push({ tinhThanhId: tinhThanh.Id, quanHuyenId: location.DistrictId });
						}
					});
				});
			}

			if (result.length > 0 && LocationUserList.length > 0) {
				result.forEach((tinhThanhItem: any) => {
					LocationUserList.forEach((loacation: any) => {
						if (loacation.tinhThanhId === tinhThanhItem.Id) {
							tinhThanhItem.Districts.forEach((quanHuyen: any) => {
								if (quanHuyen.Id === loacation.quanHuyenId) {
									quanHuyens.push(quanHuyen);
								}
							});
						}
					});
				});
			}
			setQuanHuyenIdUser(quanHuyensId);
			if (quanHuyens) {
				quanHuyens.forEach((item: any) =>
					array.push(
						<Option key={item?.Id} value={item?.value}>
							{item?.Name}
						</Option>,
					),
				);
				updateArrOptionQuanhuyen(array);
			}
			updateArrarrayTinhthanh(GeneratorProvinceUser.generateOptions(result, 'Id', 'Name'));
		});
	};

	const onGenderChangeTinhThanh = (value: any) => {
		setQuanHuyenList([]);
		setLocations([]);
		form.resetFields(['QuanHuyen']);
		updateArrOptionQuanhuyen([]);
		if (value?.length > 0) {
			let promises: PromiseLike<any>[] = [];
			let array: any[] = [];
			value.forEach((tinhId: string) => {
				promises.push(
					provinceService.findDistrictsByProvince(tinhId).then((res: any) => {
						array.push(res);
					}),
				);
			});
			Promise.all(promises).then(() => {
				setQuanHuyenList(array);
				optionquanhuyen(array);
			});
		} else {
			updateArrOptionQuanhuyen([]);
		}
	};
	const [arrayOptionQuanhuyen, updateArrOptionQuanhuyen] = useState<JSX.Element[] | null>();

	const optionquanhuyen = (data: any[]) => {
		let dataListQuanHuyen: any[] = [];
		let quanHuyenList: any[] = [];
		let quanHuyenLists: any[] = [];
		setLocations([]);
		if (data.length > 0) {
			if (quanHuyenIdUser?.length > 0 || !form.getFieldValue('QuanHuyen') || !form.getFieldValue('ParentId')) {
				for (let i = 0; i < data.length; i++) {
					if (i > 0) {
						// dataListQuanHuyen = dataListQuanHuyen.concat(data[i]);
						data[i].forEach((quanHuyen: any) => {
							if (
								!form.getFieldValue('ParentId') ||
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenLists.push(quanHuyen);
							}
						});
						dataListQuanHuyen = dataListQuanHuyen.concat(quanHuyenLists);
					} else {
						data[i].forEach((quanHuyen: any) => {
							if (
								!form.getFieldValue('ParentId') ||
								quanHuyenIdUser.includes(quanHuyen.Id) ||
								(quanHuyenIdUser.length === 0 && !form.getFieldValue('QuanHuyen'))
							) {
								quanHuyenList.push(quanHuyen);
							}
						});
						dataListQuanHuyen = quanHuyenList;
					}
				}
			}
		}

		const uniqueArray = dataListQuanHuyen?.filter(function (item, pos) {
			return dataListQuanHuyen.indexOf(item) === pos;
		});

		let array: JSX.Element[] = [];
		if (uniqueArray) {
			uniqueArray.forEach((item: any) =>
				array.push(
					<Option key={item?.Id} value={item?.value}>
						{item?.Name}
					</Option>,
				),
			);
			updateArrOptionQuanhuyen(array);
		}
	};

	const createLocation = (formValues: CreateUserParams) => {
		formValues.Locations = [];
		let arrayTinhThanh: any = [];

		if (form.getFieldValue('QuanHuyen')?.length > 0) {
			form.getFieldValue('QuanHuyen').forEach((quanhuyen: string) => {
				for (let i = 0; i < quanHuyenList.length; i++) {
					if (search(quanhuyen, quanHuyenList[i])) {
						formValues.Locations.push({
							TinhThanh: form.getFieldValue('TinhThanh')[i],
							QuanHuyen: quanhuyen,
						});

						if (!arrayTinhThanh.includes(form.getFieldValue('TinhThanh')[i])) {
							arrayTinhThanh.push(form.getFieldValue('TinhThanh')[i]);
						}
						break;
					}
				}
			});

			if (arrayTinhThanh.length < form.getFieldValue('TinhThanh').length) {
				arrayTinhThanh.forEach((tinhThanh: any) => {
					const index = form.getFieldValue('TinhThanh').indexOf(tinhThanh);
					if (index > -1) {
						form.getFieldValue('TinhThanh').splice(index, 1); // 2nd parameter means remove one item only
					}
				});

				form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
					formValues.Locations.push({
						TinhThanh: tinhThanh,
					});
				});
			}
		} else if (form.getFieldValue('TinhThanh')) {
			form.getFieldValue('TinhThanh').forEach((tinhThanh: string) => {
				formValues.Locations.push({
					TinhThanh: tinhThanh,
				});
			});
		}
	};

	function search(quanhuyenId: any, quanHuyenArray: any) {
		for (let i = 0; i < quanHuyenArray.length; i++) {
			if (quanHuyenArray[i].Id === quanhuyenId) {
				return quanHuyenArray[i];
			}
		}
	}

	const onChangeLoaiKhachHang = async (value: any) => {
		setQuanHuyenList([]);
		updateArrarrayTinhthanh([]);
		setLocations([]);
		form.resetFields(['TinhThanh', 'QuanHuyen']);
		if (value && value !== '0') {
			await CustomerService.getCustomer(value).then((res: any) => {
				customTinhThanh(res);
			});
		} else {
			updateArrarrayTinhthanh(
				props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer,
			);
		}
	};

	const finishCreate = (formValues: any) => {
		createLocation(formValues);
		// Create
		CustomerService.createCustomer({ ...formValues, TaxCode: formValues.TaxCode ? formValues.TaxCode : null })
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_create_desc', { item: formValues.Name }),
				);
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const finishUpdate = (formValues: any) => {
		if (locations?.length === 0) {
			createLocation(formValues);
		} else {
			const output = Object.keys(locations).map(key => {
				return {
					TinhThanh: locations[key].CityId,
					QuanHuyen: locations[key].DistrictId,
				};
			});
			formValues.Locations = output;
		}
		formValues.Id = Id;
		// update
		CustomerService.updateCustomer({ ...formValues, TaxCode: formValues.TaxCode ? formValues.TaxCode : null })
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_update_desc', { item: formValues.Name }),
				);
				navigateUrl();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const navigateUrl = () => {
		navigate({
			pathname: '/nguoiDung',
			search: `?tab=${NguoiDungTab.QUAN_LY_KHACH_HANG}`,
		});
	};

	useEffect(() => {
		CustomerService.getCustomerAll(loaiKhachHang).then((res: any) => {
			setDataCustomer(res);
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loaiKhachHang]);

	const setDataCustomer = (res: any) => {
		const khongTrucThuocParam = [{ Id: '', Name: 'Không trực thuộc' }];
		const dataTrucThuoc = [...khongTrucThuocParam, ...res];
		setCustommerList(utils.getSelectOptionsCustomer(dataTrucThuoc));
	};

	const OnchangePhanLoaiKhachHang = (value: any) => {
		form.resetFields(['CustomerId']);
		setLoaiKhachHang(value);
	};

	const DanhSachKhachHangCon = () => {
		const [dataKhachHangCon, setDataKhachHangCon] = useState<any>([]);
		const columnsKhachHangCon: any = [
			{
				dataIndex: 'Name',
				title: 'Tên khách hàng con',
				width: 350,
			},
			{
				dataIndex: 'Address',
				title: 'Địa chỉ',
				width: 350,
			},
			{
				align: 'center' as const,
				title: 'Thao tác',
				key: 'action',
				width: 100,
				hidden: mode === 'view',
				render: (_: any, record: any) => (
					<TableAction
						isViewButtonEdit={true}
						isViewButtonDelete={true}
						editTitle={`Cập nhật khách hàng "${record.Name}"`}
						className="dialog-add"
						EditDialog={
							<ChiTietKhachHangDialog
								userInfo={props.userInfo}
								Id={record.Id}
								ParentId={Id}
								callback={getDataKhachHangCon}
							/>
						}
						DeleteDialog={
							<DialogDelete
								Name={record.Name}
								mode={NguoiDungTab.QUAN_LY_KHACH_HANG}
								Id={record.Id}
								callback={getDataKhachHangCon}
							/>
						}
					/>
				),
			},
		].filter(item => !item.hidden);

		const showTotal = (total: number) => {
			return (
				<span>
					Tổng: <strong>{total} khách hàng con</strong>
				</span>
			);
		};

		useEffect(() => {
			if (form.getFieldValue('Name')) {
				getDataKhachHangCon();
			}
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [form.getFieldValue('Name')]);

		const getDataKhachHangCon = () => {
			CustomerService.searchCustomer({ Name: form.getFieldValue('Name') }).then(data => {
				if (data?.length > 0) {
					data.forEach((item: any) => {
						if (item.Children?.length > 0 && item.Id === Id) {
							optiondanhsachKhachHang(item.Children);
						}
					});
				}
				// setDataKhachHangCon(data);
			});
		};

		const optiondanhsachKhachHang = (data: any) => {
			let array: any[] = [];
			if (data?.length > 0) {
				data.forEach((item: any) => {
					if (item.Children?.length > 0) {
						item.children = convertData(item.Children);
						item.key = generateUniqueId();
					} else {
						item.children = null;
					}
					array.push(item);
				});
			}
			setDataKhachHangCon(array);
		};

		const convertData = (Children: any) => {
			if (Children.length > 0) {
				Children.forEach((element: any) => {
					if (element.Children.length > 0) {
						element.key = generateUniqueId();
						element.children = convertData(element.Children);
					} else {
						element.children = null;
					}
				});
			} else {
				Children = null;
			}

			return Children;
		};

		return (
			<>
				<div className="flex--row mt10" style={{ justifyContent: 'end' }}>
					{/* <label className="heading--8">Khách hàng con</label> */}
					<Button
						disabled={mode === 'view'}
						type={'link'}
						onClick={async () => {
							await CustomDialog(
								<ChiTietKhachHangDialog
									userInfo={props.userInfo}
									CustomerType={form.getFieldValue('CustomerType')}
									ParentId={Id}
								/>,
								{
									className: 'dialog-add',
									title: 'Thêm mới khách hàng',
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<PlusOutlined />
						Thêm khách hàng con
					</Button>
				</div>

				<Table
					className="table-header-bored"
					rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					dataSource={dataKhachHangCon}
					pagination={{
						showTotal: showTotal,
						pageSize: 15,
						onChange(page, pageSize) {
							setCurrentPage(page);
							setPageSizeDefault(pageSize);
						},
					}}
					columns={columnsKhachHangCon}
					bordered
					style={{ whiteSpace: 'pre' }}
				/>
			</>
		);
	};

	const DanhSachNguoiDung = () => {
		const [dataNguoiDung, setDataNguoiDung] = useState<any>([]);
		const columnsNguoiDung: any = [
			{
				align: 'center',
				className: 'min-col',
				dataIndex: 'key',
				width: 30,
				render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
				title: t('label.order'),
			},
			{
				dataIndex: 'FullName',
				title: 'Tên người dùng',
			},
			{
				dataIndex: 'UserName',
				title: 'Tên đăng nhập',
			},
			{
				dataIndex: 'PhoneNumber',
				title: 'Số điện thoại',
				align: 'right' as const,
			},
			{
				dataIndex: 'IsActive',
				align: 'center' as const,
				title: 'Trạng thái',
				render: (IsActive: string) => {
					return (
						<Tag color={IsActive === 'ACTIVE' ? '#1BB36B' : '#FF2000'}>
							{IsActive === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
						</Tag>
					);
				},
			},
			{
				align: 'center' as const,
				title: 'Thao tác',
				key: 'action',
				width: 50,
				hidden: mode === 'view',
				render: (_: any, record: any) => (
					<Button
						className="ml10"
						type={'link'}
						onClick={async () => {
							navigate({
								pathname: `/nguoiDung?tab=quanLyNguoiDung&child=ThemNguoiDung&CustomerId=${Id}&Id=${record.Id}`,
							});
						}}>
						<img src="icon/Pen.png" alt="edit" />
					</Button>
				),
			},
		].filter(item => !item.hidden);
		useEffect(() => {
			getDataNguoiDung();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [Id]);

		const getDataNguoiDung = () => {
			UserService.searchData({ CustomerId: Id }).then(data => {
				setDataNguoiDung(data);
			});
		};

		const showTotal = (total: number) => {
			return (
				<span>
					Tổng: <strong>{total} Người dùng</strong>
				</span>
			);
		};
		return (
			<>
				<div className="flex--row mt10" style={{ justifyContent: 'end' }}>
					{/* <label className="heading--8">Tài khoản người dùng</label> */}
					{/* TODO:: ADD người dùng */}
					<Button
						disabled={mode === 'view'}
						className="ml10"
						type={'link'}
						onClick={async () => {
							navigate({
								pathname: `/nguoiDung?tab=quanLyNguoiDung&child=ThemNguoiDung&CustomerId=${Id}`,
							});
						}}>
						<PlusOutlined />
						Thêm mới người dùng
					</Button>
				</div>

				<Table
					className="table-header-bored"
					rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					dataSource={dataNguoiDung}
					pagination={{
						showTotal: showTotal,
						pageSize: 15,
						onChange(page, pageSize) {
							setCurrentPage(page);
							setPageSizeDefault(pageSize);
						},
					}}
					columns={columnsNguoiDung}
					bordered
					style={{ whiteSpace: 'pre' }}
				/>
			</>
		);
	};

	const DanhSachCoSoCongTrinh = () => {
		enum PhoneNumberType {
			CHU_CO_SO_CONG_TRINH = 'CHU_CO_SO_CONG_TRINH',
			NGUOI_XAC_NHAN = 'NGUOI_XAC_NHAN',
			NGUOI_BACKUP = 'NGUOI_BACKUP',
		}
		interface PhoneNumber {
			PhoneNumber: string;
			FullName: string;
			Info: string;
			PhoneNumberType: PhoneNumberType;
		}
		const [dataCoSoCongTrinh, setDataCoSoCongTrinh] = useState<any>([]);
		const getPhoneNumber = (phoneNumbers: PhoneNumber[], type: PhoneNumberType) => {
			return phoneNumbers.find(phone => phone.PhoneNumberType === type);
		};
		const columnsCoSoCongTrinh: any = [
			{
				align: 'center',
				className: 'min-col',
				dataIndex: 'key',
				width: 30,
				render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
				title: t('label.order'),
			},
			{
				dataIndex: 'TenCoSoCongTrinh',
				title: 'Tên công trình',
			},
			{
				dataIndex: 'LocationDetail',
				title: 'Địa chỉ',
			},
			{
				title: 'SĐT chủ cơ sở',
				dataIndex: 'SoDienThoai',
				key: 'SoDienThoai',
				render: (phoneNumbers: PhoneNumber[]) => {
					const ownerConstructionPhone = getPhoneNumber(phoneNumbers, PhoneNumberType.CHU_CO_SO_CONG_TRINH);
					return ownerConstructionPhone?.PhoneNumber;
				},
			},
			{
				align: 'center' as const,
				title: 'Thao tác',
				key: 'action',
				width: 50,
				hidden: mode === 'view',
				render: (_: any, record: any) => (
					<Button
						type={'text'}
						onClick={async (e: any) => {
							await CustomDialog(
								<DialogDelete
									Id={record.Id}
									Name={record.TenCoSoCongTrinh}
									mode={'coSoCongTrinh'}
									callback={getDataCoSoCongTrinh}
								/>,
								{
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<img src="icon/Delete.png" alt="delete" />
					</Button>
				),
			},
		].filter(item => !item.hidden);

		useEffect(() => {
			getDataCoSoCongTrinh();
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [Id]);

		const getDataCoSoCongTrinh = () => {
			ConstructionService.searchConstruction({ CustomerId: Id }).then(data => {
				setDataCoSoCongTrinh(data);
			});
		};

		const showTotal = (total: number) => {
			return (
				<span>
					Tổng: <strong>{total} cơ sở công trình</strong>
				</span>
			);
		};

		return (
			<>
				<div className="flex--row mt10" style={{ justifyContent: 'end' }}>
					{/* <label className="heading--8">Tổng {0} cơ sở công trình</label> */}
					<Button
						disabled={mode === 'view'}
						className="ml10"
						type={'link'}
						onClick={async () => {
							navigate({
								pathname: '/chiTietCoSoCongTrinh',
								search: `?CustomerId=${Id}&mode=add`,
							});
						}}>
						<PlusOutlined />
						Thêm mới cơ sở công trình
					</Button>
				</div>

				<Table
					className="table-header-bored"
					rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					dataSource={dataCoSoCongTrinh}
					pagination={{
						showTotal: showTotal,
						pageSize: 15,
						onChange(page, pageSize) {
							setCurrentPage(page);
							setPageSizeDefault(pageSize);
						},
					}}
					columns={columnsCoSoCongTrinh}
					bordered
					style={{ whiteSpace: 'pre' }}
				/>
			</>
		);
	};

	return (
		<div className="editKhachHang-container page-detail-wrapper">
			<TitleComponent
				title={
					<div className="flex--row">
						<h2>
							{titleScreen} {form.getFieldValue('Name')}{' '}
						</h2>
						<Tag className="ml10" color={form.getFieldValue('IsActive') ? 'blue' : 'red'}>
							{form.getFieldValue('IsActive') ? 'Hoạt động' : 'Tạm khóa'}
						</Tag>
					</div>
				}
			/>
			<Form className={'common-dialog'} form={form} layout={'vertical'} onFinish={Id ? finishUpdate : finishCreate}>
				<Row gutter={32}>
					<Col span={8}>
						<h5 className="heading--8 font-weight--bold mb10">Thông tin chi tiết</h5>

						<Form.Item label="Phân loại khách hàng" name="CustomerType">
							<Select
								disabled={mode === 'view'}
								showSearch
								optionFilterProp="children"
								// defaultValue={LoaiKhachHangEnum.CO_QUAN_CHUC_NANG}
								placeholder="Chọn phân loại khách hàng"
								onChange={OnchangePhanLoaiKhachHang}
								allowClear>
								{Generator.generateOptions(loaiKhachHangEnum.getAll())}
							</Select>
						</Form.Item>
						<Form.Item
							label="Tên khách hàng"
							name="Name"
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Tên khách hàng không được để trống',
								},
							]}>
							<Input disabled={mode === 'view'} placeholder="Nhập tên khách hàng" />
						</Form.Item>

						<Form.Item name="ParentId" label="Trực thuộc">
							<Select
								disabled={mode === 'view'}
								showSearch
								optionFilterProp="children"
								placeholder="Chọn trực thuộc"
								onChange={onChangeLoaiKhachHang}
								allowClear>
								{custommerList}
							</Select>
						</Form.Item>

						<Form.Item
							name="PhoneNumber"
							label="Số điện thoại"
							rules={[
								{
									pattern: systemProperties.phonePattern,
									message: 'SĐT không đúng định dạng!',
								},
								{
									required: true,
									whitespace: true,
									message: t('validation.error.phoneNumber_empty'),
								},
							]}>
							<Input
								disabled={mode === 'view'}
								type={'number'}
								onKeyDown={blockInvalidChar}
								placeholder="Số điện thoại"
							/>
						</Form.Item>

						<Form.Item
							name="Address"
							label="Địa chỉ"
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Vị trí không được để trống',
								},
							]}>
							<Input disabled={mode === 'view'} placeholder="Nhập vị trí" />
						</Form.Item>

						<Form.Item name="IsActive" label="Trạng thái hoạt động">
							<Select
								disabled={mode === 'view'}
								showSearch
								optionFilterProp="Children"
								placeholder="Chọn trạng thái"
								allowClear>
								{utils.TrangThaiCustommer()}
							</Select>
						</Form.Item>

						<Form.Item
							name="TaxCode"
							label="Mã số thuế"
							style={{
								display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? 'none' : '',
							}}>
							<Input disabled={mode === 'view'} placeholder="Nhập mã số thuế" />
						</Form.Item>

						<Form.Item
							name="TinhThanh"
							label="Tỉnh thành"
							style={{
								display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? '' : 'none',
							}}>
							<Select
								disabled={mode === 'view'}
								showSearch
								mode="multiple"
								optionFilterProp="children"
								placeholder="Tỉnh thành"
								allowClear
								onChange={onGenderChangeTinhThanh}>
								{form.getFieldValue('ParentId')
									? arrayTinhthanh
									: props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
									? tinhThanhsAdmin
									: tinhThanhscustomer}
							</Select>
						</Form.Item>

						<Form.Item
							name="QuanHuyen"
							label="Quận Huyện"
							style={{
								display: loaiKhachHang === LoaiKhachHangEnum.CO_QUAN_CHUC_NANG ? '' : 'none',
							}}>
							<Select
								disabled={mode === 'view'}
								showSearch
								mode="multiple"
								optionFilterProp="children"
								placeholder="Quận Huyện"
								allowClear>
								{arrayOptionQuanhuyen}
							</Select>
						</Form.Item>

						<Form.Item name="Info" label="Ghi chú">
							<TextArea disabled={mode === 'view'} rows={5} placeholder="Nhập ghi chú" />
						</Form.Item>
					</Col>
					<Col span={16} style={{ backgroundColor: 'none', padding: 10 }}>
						<Tabs defaultActiveKey="1">
							<TabPane tab="Danh sách khách hàng con" key="1" destroyInactiveTabPane>
								<DanhSachKhachHangCon />
							</TabPane>
							<TabPane tab="Danh sách người dùng" key="2">
								<DanhSachNguoiDung />
							</TabPane>
							{form.getFieldValue('CustomerType') === LoaiKhachHangEnum.DAI_LY && (
								<TabPane tab="Danh sách cơ sở công trình" key="3">
									<DanhSachCoSoCongTrinh />
								</TabPane>
							)}
						</Tabs>
					</Col>
				</Row>

				<Divider />
				<Row justify="end" className={'mt10 end'}>
					<div>
						<Button type="default" onClick={navigateUrl}>
							{mode ? 'OK' : 'Hủy bỏ'}
						</Button>
						{!mode && (
							<Button className="ml10" htmlType={'submit'} type="primary">
								Cập nhật
							</Button>
						)}
					</div>
				</Row>
			</Form>
		</div>
	);
};

export default withTranslation()(EditKhachHang);
