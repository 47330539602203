import { Form, Select } from 'antd';
import trucThuocEnum from 'enum/trucThuocEnum';
import { FormInstance } from 'rc-field-form';
import React, { ReactNode } from 'react';

interface DonViTrucThuocSelectProps {
	onChangeDonViTrucThuoc?: (value: string) => void;
	disabled?: boolean;
	form?: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: ReactNode;
	style?: React.CSSProperties;
	bordered?: boolean;
}

const DonViTrucThuocSelect = (props: DonViTrucThuocSelectProps) => {
	const {
		onChangeDonViTrucThuoc,
		disabled,
		form,
		rules,
		formItemClassName,
		selectClassName,
		label,
		style,
		bordered: bodered = true,
	} = props;
	const trucThuocList = trucThuocEnum.getAll();
	return (
		<Form.Item name="TrucThuoc" className={formItemClassName} rules={rules} label={label} style={style}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn trực thuộc"
				optionFilterProp="children"
				className={selectClassName}
				onChange={onChangeDonViTrucThuoc}
				disabled={disabled}
				bordered={bodered}>
				{trucThuocList.map(item => (
					<Select.Option value={item.value} key={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default DonViTrucThuocSelect;
