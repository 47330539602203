import { Button } from 'antd';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import RoleService from 'services/api/authenService/roleService';
import UsersService from 'services/api/authenService/usersService';
import ConstructionService from 'services/api/inventoryService/constructionService';
import CustomerService from 'services/api/inventoryService/customerService';
import { NguoiDungTab } from '../NguoiDung';

interface IDialogDeleteProps {
	Id: any;
	Name: any;
	callback?: () => void;
	mode?: any;
}

const DialogDelete: FunctionComponent<IDialogDeleteProps> = (props: IDialogDeleteProps) => {
	const dialog = useDialog();

	const deleteItem = async () => {
		try {
			switch (props?.mode) {
				case NguoiDungTab.QUAN_LY_NGUOI_DUNG:
					await UsersService.deleteUser(props?.Id);
					break;
				case NguoiDungTab.QUAN_LY_KHACH_HANG:
					await CustomerService.deleteCustomer(props?.Id);
					break;
				case 'coSoCongTrinh':
					await ConstructionService.deleteConstruction(props?.Id);
					break;
				case NguoiDungTab.PHAN_QUYEN:
					await RoleService.deleteRole(props?.Id);
					break;
				default:
					await UsersService.deleteUser(props?.Id);
					break;
			}
			showNotification(
				'success',
				t('notification.success'),
				t('notification.success_delete_desc', { item: props.Name }),
			);
			// Сlose the dialog and return the value
			dialog.close();
			if (props.callback) {
				props.callback();
			}
		} catch (errors: any) {
			showNotification('error', t('notification.error'), errors.response?.data || errors.message);
			dialog.close();
		}
	};

	return (
		<div className="dialog-delete-container">
			<div className="dialog-delete-image">
				<img alt={'CONFIRM_ICON'} src={'icon/danger.svg'} height={80} />
			</div>
			<h4 className="mt25 heading--6">Xác nhận xóa?</h4>
			<p className="mt10">Bạn chắc chắn muốn xóa "{props.Name}"</p>
			<div style={{ display: 'flex', width: '310px', justifyContent: 'space-between' }}>
				<Button
					style={{ width: 150 }}
					type="default"
					onClick={() => {
						// Сlose the dialog and return the value
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button type={'text'} style={{ background: '#D02127', color: '#FFFFFF', width: 150 }} onClick={deleteItem}>
					Xóa
				</Button>
			</div>
		</div>
	);
};

export default withTranslation()(DialogDelete);
