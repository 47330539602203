import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigateButtonProps {
	url: string;
	name: string | undefined;
}

const NavigateButton = (props: NavigateButtonProps) => {
	const navigate = useNavigate();
	const { url, name } = props;
	return (
		<Button
			type="link"
			onClick={e => {
				e.stopPropagation();
				navigate(url);
			}}>
			<span className="text-link" title={name}>
				{name}
			</span>
		</Button>
	);
};

export default NavigateButton;
