import { LogoutOutlined, MenuOutlined, UnlockOutlined, UserAddOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Breadcrumb, Dropdown, Menu, Space } from 'antd';
import ChangePasswordDialog from 'components/shared/Header/Dialog/ChangePassword/ChangePasswordDialog';
import UserInfoDialog from 'components/shared/Header/Dialog/UserInfo/UserInfoDialog';
import GetValueFromToken from 'hooks/userInfoHook';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import NavBarSelector from 'redux/NavBar/navBar.selector';
import PermissionSelector from 'redux/Permission/permission.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import ProvinceService from 'services/api/provinceService';
import { LOCATION_INFO } from 'services/tokenService';
import NavBarDispatcher from '../../../redux/NavBar/navBar.dispatcher';
import { NavTabList } from '../NavBar/NavBar';
import LogoutDialog from './Dialog/LogoutDialog';
import './Header.scss';

const mapStateToProps = () => ({
	userInfo: PermissionSelector.getUserInfo(),
});

const Header: FunctionComponent<{ userInfo: UserInfo }> = (props: { userInfo: UserInfo }) => {
	const [searchParams] = useSearchParams();
	const [UserName, setUserName] = useState<string>('');
	const [tenTinhThanh, setTenTinhThanh] = useState<string>('');
	const breadcrumbNameMap = {
		[NavTabList.GIAM_SAT_TRUC_TUYEN.value]: t('nav.giamSatTrucTuyen'),
		'/trungTamChiHuy': t('nav.trungTamChiHuy'),
		'/quanLyDonViPhongChay': t('nav.quanLyDonViPhongChayChuaChay'),
		'/quanLyCongTacPhongChay': t('nav.quanLyCongTacPhongChay'),
		'/congTacChuaChayVaCNCH': t('nav.congTacChuaChayVaCuuHoCuuNan'),
		'/camBien': t('nav.quanLyThietBi'),
		'/quanLyBaoCao': t('nav.quanLyBaoCao'),
		'/nguoiDung': t('nav.nguoiDung'),
		'/quanLyChatLuongThietBi': t('nav.quanLyChatLuong'),
	};

	const tabBreadcrumbNameMap = {
		// Đơn vị PCCC
		'/DanhSachDonViPCCC': t('quanLyDonViPhongChay.quanLyDonVi'),
		'/QuanLyDonVi': t('quanLyDonViPhongChay.chiTietDonVi'),
		// Công tác phòng cháy
		'/QuanLyCoSoVaCongTrinh': t('quanLyCongTacPhongChay.quanLyCoSoVaCongTrinh'),
		'/ThamDuyetChungNhan': t('quanLyCongTacPhongChay.thamDuyetChungNhan'),
		'/SoCuKiemTra': 'Sở Cứ Kiểm Tra',
		'/KeHoachKiemTra': 'Kế Hoạch Kiểm Tra',
		'/TuyenTruyenHuanLuyen': t('quanLyCongTacPhongChay.tuyenTruyenHuanLuyen'),
		'/XuLyViPham': t('quanLyCongTacPhongChay.xuLyViPham'),
		// Danh sách điểm cháy
		'/CongTacChuaChay': t('congTacPhongChayVaCHCN.congTacChuaChay'),
		'/XayDungTTPAChuaChay': t('congTacPhongChayVaCHCN.xayDungTTPAChuaChay'),
		'/CongTacCNCH': t('congTacPhongChayVaCHCN.congTacCHCN'),
		'/QuanLyLichTruc': t('quanLyCongTacPhongChay.quanLyLichTruc'),
		// Thiết bị
		'/quanLyThietBiTrungTam': t('quanLyThietBi.quanLyThietBiTrungTam'),
		'/quanLyCamBien': t('quanLyThietBi.quanLyCamBien'),
		// Báo cáo
		'/thietBiLapDat': t('quanLyBaoCao.baoCaoThietBiLapDat'),
		'/coSoCongTrinh': t('quanLyBaoCao.baoCaoCoSoCongTrinh'),
		'/trangThaiPhuongTienPCCC': t('quanLyBaoCao.baoCaoTrangThaiPhuongTienPCCC'),
		'/diemChay': t('quanLyBaoCao.baoCaoDiemChay'),
		// Người dùng
		'/quanLyNguoiDung': t('nav.quanLyNguoiDung'),
		'/quanLyKhachHang': t('nav.quanLyKhachHang'),
		'/lichSuDangNhap': t('nav.lichSuDangNhap'),
		'/phanQuyen': t('nav.phanQuyen'),
		// Quản lý chất lượng
		'/quanLyLoi': t('nav.quanLyLoi'),
		'/quanLyLoaiThietBi': t('nav.quanLyLoaiThietBi'),
		'/baoCaoKetQuaSanXuat': t('nav.baoCaoKetQuaSanXuat'),
		'/baoCaoChiTiet': t('nav.baoCaoChiTiet'),
		'/tongHopKetQuaTest': t('nav.tongHop'),
		'/baoCaoKetQuaTest': t('nav.baoCaoKetQua'),
	};

	const childBreadcrumbNameMap = {
		'/AddVaiTro': 'Thêm mới vai trò',
		'/EditKhachHang': 'Cập nhật khách hàng',
		'/ThemNguoiDung': 'Thêm mới người dùng',
	};

	const navigate = useNavigate();

	useEffect(() => {
		const name = GetValueFromToken.useGetUserName;
		const per = GetValueFromToken.useGetPermission;
		if (per && per[0] === 999) {
			setTenTinhThanh('');
		} else {
			const tinhThanhUser = localStorage.getItem(LOCATION_INFO);
			const tinhThanhList = tinhThanhUser && JSON.parse(tinhThanhUser);
			if (tinhThanhList) {
				for (let i = 0; i < tinhThanhList.length; i++) {
					const tinhThanhFirst = tinhThanhList[0];
					if (tinhThanhFirst.CityId === tinhThanhList[i].CityId) {
						if (i === tinhThanhList.length - 1) {
							ProvinceService.getProvince(tinhThanhList[i].CityId).then(data => {
								setTenTinhThanh(data ? data.Name.toUpperCase() : '');
							});
						}
					} else {
						setTenTinhThanh('');
					}
				}
			}
		}

		setUserName(name);
	}, []);

	const pathSnippets = useLocation()
		.pathname.split('/')
		.filter(i => i);
	const extraBreadcrumbItems = pathSnippets.map((_, index) => {
		const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
		return (
			<Breadcrumb.Item key={url}>
				<Link to={url}>{breadcrumbNameMap[url as keyof typeof breadcrumbNameMap]}</Link>
			</Breadcrumb.Item>
		);
	});

	const tabBreadcrumbItems = pathSnippets.map(_ => {
		const url = `/${searchParams.get('tab')}`;
		return (
			<Breadcrumb.Item
				key={url}
				onClick={e => {
					const path = '/' + pathSnippets[0];
					const tab = searchParams.get('tab');
					navigate({
						pathname: path,
						search: `?tab=${tab}`,
					});
				}}>
				{tabBreadcrumbNameMap[url as keyof typeof tabBreadcrumbNameMap]}
			</Breadcrumb.Item>
		);
	});

	// const childBreadcrumbNameItems = pathSnippets.map(_ => {
	// 	const url = `/${searchParams.get('child')}`;
	// 	return (
	// 		<Breadcrumb.Item key={url}>{childBreadcrumbNameMap[url as keyof typeof childBreadcrumbNameMap]}</Breadcrumb.Item>
	// 	);
	// });

	const breadcrumbItems = [
		<Breadcrumb.Item key={'home'}>
			<Link to={'/'}>{t('label.trangChu')}</Link>
		</Breadcrumb.Item>,
	].concat(extraBreadcrumbItems, tabBreadcrumbItems);

	const menu = (
		<Menu>
			<Menu.Item
				key="1"
				icon={<UserAddOutlined style={{ fontSize: '18px' }} />}
				onClick={async () =>
					await CustomDialog(<UserInfoDialog userInfo={props.userInfo} />, {
						title: 'Thông tin cá nhân',
						className: 'user-info-wrapper',
						isCanClose: true,
						showCloseIcon: true,
					})
				}>
				Thông tin cá nhân
			</Menu.Item>
			<Menu.Item
				key="2"
				icon={<UnlockOutlined style={{ fontSize: '18px' }} />}
				onClick={async () =>
					await CustomDialog(
						<ChangePasswordDialog userName={props.userInfo?.UserName ? props.userInfo?.UserName : UserName} />,
						{
							title: 'Đổi mật khẩu',
							className: 'change-password-wrapper',
							isCanClose: true,
							showCloseIcon: true,
						},
					)
				}>
				Đổi mật khẩu
			</Menu.Item>

			<Menu.Divider />

			<Menu.Item
				key="3"
				icon={<LogoutOutlined style={{ fontSize: '18px' }} />}
				onClick={() => CustomDialog(<LogoutDialog navigate={navigate} />)}>
				{t('nav.dangXuat')}
			</Menu.Item>
		</Menu>
	);

	return (
		<div className={'header-container'}>
			<MenuOutlined onClick={() => NavBarDispatcher.setCollapse(!NavBarSelector.getCollapse())} />
			{/* <Breadcrumb separator={'>'}>{breadcrumbItems}</Breadcrumb> */}
			<div className="title-content-page">
				TRUNG TÂM THÔNG TIN CHỈ HUY PCCC & CNCH {tenTinhThanh ? `- ${tenTinhThanh}` : ''}
			</div>
			{/* <div className={'flex--fill'} /> */}
			{/* <div className={'flex--fill'}></div> */}
			<div className="icon-user">
				<div>
					<Space wrap>
						<Dropdown overlay={menu} placement="bottomRight">
							<Avatar style={{ backgroundColor: '#87d068', cursor: 'pointer' }} icon={<UserOutlined />} />
						</Dropdown>
					</Space>
				</div>
				<div className="ml10">
					<label>Xin chào,</label>
					<label className="titleText">
						<br /> {props.userInfo?.UserName ? props.userInfo?.UserName : UserName}
					</label>
				</div>
			</div>
		</div>
	);
};

export default connect(mapStateToProps)(withTranslation()(Header));
