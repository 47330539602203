import { t } from 'i18next';

export enum BienPhapXuLy {
	CANH_CAO = 'CANH_CAO',
	PHAT_TIEN = 'PHAT_TIEN',
	TAM_DINH_CHI = 'TAM_DINH_CHI',
	DINH_CHI_VINH_VIEN = 'DINH_CHI_VINH_VIEN',
	KHAC = 'KHAC',
}

interface IBienPhapXuLy {
	value: BienPhapXuLy;
	label: string;
}

const _fromEnum = (bienPhapXuLy: BienPhapXuLy): IBienPhapXuLy => {
	switch (bienPhapXuLy) {
		case BienPhapXuLy.CANH_CAO:
			return {
				label: t('enum.bienPhapXuLy.CANH_CAO'),
				value: BienPhapXuLy.CANH_CAO,
			};
		case BienPhapXuLy.PHAT_TIEN:
			return {
				label: t('enum.bienPhapXuLy.PHAT_TIEN'),
				value: BienPhapXuLy.PHAT_TIEN,
			};
		case BienPhapXuLy.TAM_DINH_CHI:
			return {
				label: t('enum.bienPhapXuLy.TAM_DINH_CHI'),
				value: BienPhapXuLy.TAM_DINH_CHI,
			};
		case BienPhapXuLy.DINH_CHI_VINH_VIEN:
			return {
				label: t('enum.bienPhapXuLy.DINH_CHI_VINH_VIEN'),
				value: BienPhapXuLy.DINH_CHI_VINH_VIEN,
			};
		default:
			return {
				label: t('enum.bienPhapXuLy.KHAC'),
				value: BienPhapXuLy.KHAC,
			};
	}
};

const _getAll = (): IBienPhapXuLy[] => {
	return Object.values(BienPhapXuLy).map((item) => _fromEnum(item));
};

const BienPhapXuLyEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default BienPhapXuLyEnum;
