import ApiService, { INVENTORY_BASE_URL } from 'services/api/index';

export const prefixCategory = '/category';

const _createCategory = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCategory}/create`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteCategory = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixCategory}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listCategory = (): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixCategory}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _listCategorybyCode = (input: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixCategory}/code=${input}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _searchCategory = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixCategory}/search`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _updateCategory = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixCategory}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const CategoryService = {
	createCategory: _createCategory,
	deleteCategory: _deleteCategory,
	listCategory: _listCategory,
	searchCategory: _searchCategory,
	updateCategory: _updateCategory,
	listCategorybyCode: _listCategorybyCode,
};

export default CategoryService;
