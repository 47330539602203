import { RESET_NAVIGATE, SET_NAVIGATE } from 'redux/navigate-from-dashboard/navigate-from-dashboard.type';
import { ReduxAction } from 'redux/store';

export interface NavigateFromDashboardState {
	isNavigate: boolean;
}

const INITIAL_STATE: NavigateFromDashboardState = {
	isNavigate: false,
};

const navigateFromDashboardReducer = (state = INITIAL_STATE, action: ReduxAction): NavigateFromDashboardState => {
	switch (action.type) {
		case SET_NAVIGATE:
			return {
				...state,
				isNavigate: action.payload,
			};
		case RESET_NAVIGATE:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default navigateFromDashboardReducer;
