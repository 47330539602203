import { CloudDownloadOutlined, DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Select, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { PageOption } from 'services/api';
import {
	default as ConstructionService,
	default as constructionService,
} from 'services/api/inventoryService/constructionService';
import CustomerService from 'services/api/inventoryService/customerService';
import provinceService from 'services/api/provinceService';
import { default as utils } from 'services/api/utils';
import { PermissionProps, QuanLyCongTacPhongChayTab } from '../QuanLyCongTacPhongChay';
import DialogHistoryConstruction from './Dialogs/DialogHistoryConstruction';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

enum PhoneNumberType {
	CHU_CO_SO_CONG_TRINH = 'CHU_CO_SO_CONG_TRINH',
	NGUOI_XAC_NHAN = 'NGUOI_XAC_NHAN',
	NGUOI_BACKUP = 'NGUOI_BACKUP',
}

interface PhoneNumber {
	PhoneNumber: string;
	FullName: string;
	Info: string;
	PhoneNumberType: PhoneNumberType;
}

const QuanLyCoSoVaCongTrinh: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNaviagte = navigateFromDashboardSelector.getNavigateStatus();

	//  state of component
	const [TongSoCoSoCongTrinh, setTongSoCoSoCongTrinh] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSourceDSCoSoCongTrinh, setdataSourceDSCoSoCongTrinh] = useState<JSX.Element[]>([]);
	const [disableExportButton, setDisableExportButton] = useState(true);
	const [custommerList, setCustommerList] = useState<JSX.Element[]>([]);

	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);

	const getPhoneNumber = (phoneNumbers: PhoneNumber[], type: PhoneNumberType) => {
		return phoneNumbers.find(phone => phone?.PhoneNumberType === type);
	};

	const columnsDSCoSoCongTrinh = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên công trình',
			dataIndex: 'TenCoSoCongTrinh',
			ellipsis: {
				showTitle: false,
			},
			key: 'TenCoSoCongTrinh',
			render: (TenCoSoCongTrinh: string) => (
				<Tooltip placement="topLeft" title={TenCoSoCongTrinh}>
					{TenCoSoCongTrinh}
				</Tooltip>
			),
			sorter: (a: any, b: any) => a.TenCoSoCongTrinh.localeCompare(b.TenCoSoCongTrinh),
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			ellipsis: {
				showTitle: false,
			},
			render: (LocationDetail: string) => (
				<Tooltip placement="topLeft" title={LocationDetail}>
					{LocationDetail}
				</Tooltip>
			),
			key: 'LocationDetail',
		},
		{
			title: 'Tỉnh thành',
			dataIndex: 'TinhThanh',
			ellipsis: {
				showTitle: false,
			},
			key: 'TinhThanh',
		},
		{
			width: 180,
			title: 'SĐT chủ cơ sở',
			dataIndex: 'SoDienThoai',
			ellipsis: {
				showTitle: false,
			},
			key: 'SoDienThoai',
			render: (phoneNumbers: PhoneNumber[]) => {
				const ownerConstructionPhone = getPhoneNumber(phoneNumbers, PhoneNumberType.CHU_CO_SO_CONG_TRINH);
				return ownerConstructionPhone?.PhoneNumber;
			},
		},
		{
			width: 180,
			title: 'SĐT xác minh',
			dataIndex: 'SoDienThoai',
			ellipsis: {
				showTitle: false,
			},
			key: 'SoDienThoai',
			render: (phoneNumbers: PhoneNumber[]) => {
				const confirmPhone = getPhoneNumber(phoneNumbers, PhoneNumberType.NGUOI_XAC_NHAN);
				return confirmPhone?.PhoneNumber;
			},
		},
		{
			title: 'Đơn vị PCCC quản lý',
			dataIndex: 'PcccUnitName',
			ellipsis: {
				showTitle: false,
			},
			key: 'PcccUnitName',
			sorter: (a: any, b: any) => {
				if (a.PcccUnitName && b.PcccUnitName) {
					return a.PcccUnitName.localeCompare(b.PcccUnitName);
				}
				return;
			},
			render: (PcccUnitName: string) => <span className="text-detail-info">{PcccUnitName}</span>,
			onCell: (record: any) => {
				return showDetailCellTableInfo(
					record.PcccUnitName,
					<DialogThemMoiDonViPccc userInfo={props.userInfo} id={record.DonViPcccID} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			key: 'action',
			align: 'center' as const,
			width: 120,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_EDIT) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_DELETE)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.CONSTRUCTION_VIEW)
									? ''
									: 'none',
						}}
						onClick={async e => {
							e.stopPropagation();
							await CustomDialog(<DialogHistoryConstruction Id={record.Id} />, {
								className: 'dialog-add',
								title: 'Lịch sử thay đổi ',
								isCanClose: true,
								showCloseIcon: true,
							});
						}}>
						<img src="icon/History.svg" alt="edit" />
					</Button>

					<Button
						type="text"
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.CONSTRUCTION_EDIT)
									? ''
									: 'none',
						}}
						onClick={e => e.stopPropagation()}>
						<Link
							to={{
								pathname: '/chiTietCoSoCongTrinh',
								search: `?id=${record.Id}&mode=edit`,
							}}>
							<img src="icon/Pen.png" alt="edit" />
						</Link>
					</Button>

					<Button
						type="text"
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.CONSTRUCTION_DELETE)
									? ''
									: 'none',
						}}
						onClick={async e => {
							e.stopPropagation();
							await CustomDialog(
								<DeleteDialog
									id={record.Id}
									name={record.TenCoSoCongTrinh}
									callback={searchConstruction}
									deleteService={ConstructionService.deleteConstruction}
								/>,
								{
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<img src="icon/Delete.png" alt="delete" />
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	const getRequestPayload = () => {
		return {
			Name: form.getFieldValue('thongTinTimKiem'),
			CustomerId: form.getFieldValue('CustomerId'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			},
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			IsConstructionDigitized: form.getFieldValue('IsConstructionDigitized'),
		};
	};

	// Method get giá trị Danh sách cán bộ chiến sĩ
	function searchConstruction(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const paramSearch = getRequestPayload();
		setDisableExportButton(!paramSearch.PcccUnitId);
		constructionService
			.searchConstruction(paramSearch, pageOption)
			.then((res: any) => {
				const { Data, TotalItems } = res;
				if (Data && Data.length > 0) {
					Promise.all(Data.map(async (item: any) => await convertToDataSources(item))).then(newDataSources =>
						setdataSourceDSCoSoCongTrinh(newDataSources),
					);
				} else {
					setdataSourceDSCoSoCongTrinh([]);
				}
				setTongSoCoSoCongTrinh(TotalItems);
			})
			.catch();
	}

	const convertToDataSources = async (construction: any) => {
		const {
			Location: { TinhThanh },
		} = construction;

		const province = await provinceService.getProvince(TinhThanh);
		return {
			...construction,
			TinhThanh: province?.Name,
		};
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		searchConstruction(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});
		searchConstruction(CURRENT_PAGE);
	};

	function onChangePage(page: number, pageSize: number) {
		searchConstruction(page, pageSize);
	}

	const handleRefreshPage = () => {
		form.resetFields();
		searchConstruction(CURRENT_PAGE);
	};

	const handleChangeCustommer = () => {
		searchConstruction(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['thongTinTimKiem']);
		}

		searchConstruction(CURRENT_PAGE);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietCoSoCongTrinh',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	const handleExportFileExcel = () => {
		const exportFilePayload = getRequestPayload();
		constructionService
			.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Co_So_Va_Cong_Trinh.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	};

	useEffect(() => {
		document.title = t('quanLyCongTacPhongChay.quanLyCoSoVaCongTrinh');
	}, []);

	useEffect(() => {
		const _tabParam: string | null = searchParams.get('tab');
		if (!custommerList) {
			CustomerService.searchCustomer({}).then((res: any) => {
				setCustommerList(utils.getSelectOptionsCustomer(res));
			});
		}

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (
			props.permissionList.length > 0 &&
			(_tabParam === QuanLyCongTacPhongChayTab.QUAN_LY_CO_SO_VA_CONG_TRINH || _tabParam === null) &&
			!isNaviagte
		) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.CONSTRUCTION_VIEW])) {
				searchConstruction();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, isNaviagte]);

	useEffect(() => {
		if (isNaviagte) {
			setProvinceId(provinceIdFromDashboard);
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
				IsConstructionDigitized: true,
			});

			searchConstruction(CURRENT_PAGE);
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNaviagte, form, provinceIdFromDashboard, districtIdFromDashboard]);

	const formAction = (
		<>
			<div className="flex--row">
				<div className="flex--row">
					<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

					<DistrictSelect
						formItemClassName="ml10"
						selectClassName="withMinSelect"
						form={form}
						provinceId={provinceId}
						onChangeDistrict={handleChangeDistrict}
					/>

					<UnitManagerSelect
						name="PcccUnitId"
						formItemClassName="ml10"
						selectClassName="withMinSelect"
						form={form}
						location={location}
						onChangeUnitManager={() => searchConstruction(CURRENT_PAGE)}
					/>
				</div>

				<FormItem name="CustomerId">
					<Select
						showSearch
						optionFilterProp="children"
						className="withMinSelect ml10"
						placeholder="Chọn khách hàng"
						allowClear
						onChange={handleChangeCustommer}>
						{custommerList}
					</Select>
				</FormItem>

				<FormItem className="ml10" name="thongTinTimKiem">
					<Input.Search
						placeholder="Nhập tên cơ sở công trình"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</FormItem>

				<FormItem name="IsConstructionDigitizedate" hidden>
					<Input />
				</FormItem>
			</div>

			<div className="flex--row">
				<Button type="default" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>

				<Link to={'/excelTemplate/Cơ sở công trình.xlsx'} target={'_blank'} download>
					<Button type="default" className="ml10">
						<CloudDownloadOutlined /> {t('label.taiFileMau')}
					</Button>
				</Link>

				{props.permissionList.includes(PermissionEnum.CONSTRUCTION_EXPORT) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type="default"
						icon={<DownloadOutlined />}
						onClick={handleExportFileExcel}
						disabled={dataSourceDSCoSoCongTrinh.length === 0 || disableExportButton}>
						Xuất Excel
					</Button>
				) : null}

				{props.permissionList.includes(PermissionEnum.CONSTRUCTION_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button className="ml10" type={'primary'}>
						<Link
							to={{
								pathname: '/chiTietCoSoCongTrinh',
								search: `?mode=add`,
							}}>
							<PlusOutlined /> Thêm mới
						</Link>
					</Button>
				) : null}
			</div>
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cơ sở công trình
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoCoSoCongTrinh}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const formTable = (
		<TableComponent
			dataSource={dataSourceDSCoSoCongTrinh}
			columns={columnsDSCoSoCongTrinh}
			onRow={handleShowViewDetail}
			paginator={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
		/>
	);

	return (
		<PageWrapper titlePage="Danh sách cơ sở công trình" formAction={formAction} formTable={formTable} form={form} />
	);
};

export default withTranslation()(QuanLyCoSoVaCongTrinh);
