import { FIREBASE_TOKEN, LOCATION_INFO, LOGIN_INFO, PERMISSION_INFO, SESSION_INFO } from 'services/tokenService';
import { PermissionEnum } from '../enum/permissionEnum';
import jwt from 'jwt-decode';

const _useGetProvinceId = () => {
	let provinceId = '';
	if (localStorage.getItem(LOCATION_INFO)) {
		const location = JSON.parse(localStorage.getItem(LOCATION_INFO) as string);
		provinceId = location[0]?.CityId ? location[0].CityId : '01';
	}
	return { provinceId };
};

const _useGetPermission = (): PermissionEnum[] | undefined => {
	let permistion = [];
	if (localStorage.getItem(PERMISSION_INFO)) {
		permistion = JSON.parse(localStorage.getItem(PERMISSION_INFO) as string);
	} else {
		_removeLoginInfo();
	}
	return permistion;
};

const _useGetUserName = (): string => {
	let userName = '';
	const token = JSON.parse(localStorage.getItem(LOGIN_INFO) as string);
	if (token) {
		const tokenJson: any = jwt(token?.AccessToken);
		userName = tokenJson.UserName;
	} else {
		_removeLoginInfo();
	}
	return userName;
};

const _removeLoginInfo = () => {
	localStorage.removeItem(LOGIN_INFO);
	localStorage.removeItem(FIREBASE_TOKEN);
	localStorage.removeItem(SESSION_INFO);
	localStorage.removeItem(LOCATION_INFO);
	localStorage.removeItem(PERMISSION_INFO);
};

const GetValueFromToken = {
	useGetProvinceId: _useGetProvinceId(),
	useGetPermission: _useGetPermission(),
	useGetUserName: _useGetUserName(),
};

export default GetValueFromToken;
