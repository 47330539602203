import { PermissionEnum } from 'enum/permissionEnum';
import GetValueFromToken from 'hooks/userInfoHook';
import React, { FunctionComponent } from 'react';
import { Navigate } from 'react-router-dom';

interface ProtectedRouteProps {
	orPermissions?: PermissionEnum[];
	andPermissions?: PermissionEnum[];
	children: JSX.Element;
	permissions?: PermissionEnum[];
	pathName?: string
}

const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = (props) => {
	return ((props.andPermissions ? checkAndPermissions(props.andPermissions) : true) &&
		(props.orPermissions ? checkOrPermission(props.orPermissions) : true)) ?
		props.children : <Navigate to={'/400'} replace />;
};

const checkAndPermissions = (andPermissions: PermissionEnum[]): boolean => {
	let accepted = true;
	andPermissions.forEach(r => {
		if (!GetValueFromToken.useGetPermission?.includes(r)) {
			accepted = false;
		}
	});
	return accepted;
};

const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
	let accepted = false;

	orPermissions.forEach(r => {
		if (GetValueFromToken.useGetPermission?.includes(r)) {
			accepted = true;
		}
	});

	return accepted;
};

export default ProtectedRoute;
