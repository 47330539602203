import { DownloadOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import React from 'react';

interface ExportExcelComponentProps {
	type?: 'default' | 'primary';
	className?: string;
	dataSources: any;
	onExportFileExcel: () => void;
}

const ExportExcelComponent = ({
	type = 'default',
	className,
	dataSources = [],
	onExportFileExcel,
}: ExportExcelComponentProps) => {
	const handleExportFileExcel = () => {
		onExportFileExcel();
	};
	return (
		<Button
			className={className}
			type={type}
			icon={<DownloadOutlined />}
			onClick={handleExportFileExcel}
			disabled={dataSources.length === 0}>
			Xuất Excel
		</Button>
	);
};

export default ExportExcelComponent;
