import DeviceCenter from 'components/page/QuanLyThietBi/DeviceCenter/DeviceCenter';
import Sensor from 'components/page/QuanLyThietBi/Sensor/Sensor';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';

export enum DeviceManagementTab {
	CAM_BIEN = 'camBien',
	THIET_BI_TRUNG_TAM = 'thietBiTrungTam',
}

const DeviceManagement = () => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [selectedTab, setSelectedTab] = useState<DeviceManagementTab | null>(null);

	useEffect(() => {
		NavBarDispatcher.setTitle(`${t('quanLyThietBi.title')}`);
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(DeviceManagementTab).includes(_tabParam as DeviceManagementTab)) {
			navigate(
				{
					pathname: '/quanLyThietBi',
					search: `?tab=${DeviceManagementTab.THIET_BI_TRUNG_TAM}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as DeviceManagementTab);
		}
	}, [navigate, searchParams]);

	const renderSelectedTab = () => {
		switch (selectedTab) {
			case DeviceManagementTab.THIET_BI_TRUNG_TAM:
				return <DeviceCenter />;
			case DeviceManagementTab.CAM_BIEN:
				return <Sensor />;
			default:
				return null;
		}
	};
	return renderSelectedTab();
};

export default DeviceManagement;
