export const ACTION_TYPE = {
	SELECT_CHECKERS: 'SELECT_CHECKERS',
	RESET_DATA: 'RESET_DATA'
};

export const selectCheckers = (checkers: any) => {
	return {
		type: ACTION_TYPE.SELECT_CHECKERS,
		payload: checkers,
	};
};

export const resetCheckers = () => {
	return {
		type: ACTION_TYPE.RESET_DATA,
	};
};

