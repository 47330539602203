import { ACTION_TYPE } from 'redux/fire-trucks-selected/fire-trucks-selected.action';
import { ReduxAction } from 'redux/store';

export interface FireTruckSelectedState {
	trucks: string[];
}

const INITIAL_STATE = { trucks: [] };

const fireTrucksSelectedReducer = (state = INITIAL_STATE, action: ReduxAction): FireTruckSelectedState => {
	switch (action.type) {
		case ACTION_TYPE.SELECT_FIRE_TRUCKS:
			return {
				trucks: [...action.payload],
			};
		case ACTION_TYPE.RESET_DATA:
			return {
				trucks: [],
			};
		default:
			return state;
	}
};

export default fireTrucksSelectedReducer;
