import { CustomDialog } from 'react-st-modal';

const showDetailCellTableInfo = (title: string | null, component: JSX.Element, className = 'dialog-add') => {
	return {
		onClick: async (e: any) => {
			e.stopPropagation();
			if (!title || !component) return;
			await CustomDialog(component, {
				className,
				title: `${title}`,
				showCloseIcon: true,
				isCanClose: true,
			});
		},
	};
};

export default showDetailCellTableInfo;
