import { PermissionEnum } from 'enum/permissionEnum';
import React from 'react';
import { UserInfo } from 'services/api/authenService/usersService';
import './TroGiup.scss';

export interface PermissionProps {
	permissions: PermissionEnum[];
	userInfo: UserInfo;
}

const TroGiup = () => {
	return (
		<iframe
			src="https://docs.basato.vn/pccc"
			height="100%"
			width="100%"
			title="Hướng dẫn sử dụng"></iframe>
	);
};

export default TroGiup;
