import ApiService, { BUSINESS_BASE_URL, PageOption } from '../../index';

export const prefixPlanningInfo = '/planningInfo';

const _createPlanningInfo = (input: any): Promise<any> =>
		ApiService(BUSINESS_BASE_URL).post(`${prefixPlanningInfo}`, input)
				.then(({ data }: { data: any }) => Promise.resolve(data));

const _deletePlanningInfo = (input: any): Promise<any> =>
		ApiService(BUSINESS_BASE_URL).delete(`${prefixPlanningInfo}?id=${input}`)
				.then(({ data }: { data: any }) => Promise.resolve(data));

const _findPlanningInfosByLocation = (locationId: string): Promise<any> =>
		ApiService(BUSINESS_BASE_URL).get(`${prefixPlanningInfo}/locationId=${locationId}`)
				.then(({ data }: { data: any }) => Promise.resolve(data));

const _getPlanningInfo = (id: string): Promise<any> =>
		ApiService(BUSINESS_BASE_URL).get(`${prefixPlanningInfo}/id=${id}`)
				.then(({ data }: { data: any }) => Promise.resolve(data));

const _getPlanningInfosForDashboard = (): Promise<any[]> =>
		ApiService(BUSINESS_BASE_URL).get(`${prefixPlanningInfo}/DataPlanningForDashBoard`)
				.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _listPlanningInfo = (pageOption: PageOption): Promise<any[]> =>
		ApiService(BUSINESS_BASE_URL).get(`${prefixPlanningInfo}`, {
			headers: {
				PageOption: JSON.stringify(pageOption)
			}
		}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updatePlanningInfo = (input: any): Promise<any> =>
		ApiService(BUSINESS_BASE_URL).put(`${prefixPlanningInfo}`, input)
				.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchPlanningInfo = (payload: any = {}, pageOption: PageOption): Promise<any[]> =>
		ApiService(BUSINESS_BASE_URL).post(
				`${prefixPlanningInfo}/SearchData`, payload, {
					headers: {
						PageOption: JSON.stringify(pageOption)
					}
				}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const PlanningInfoService = {
	createPlanningInfo: _createPlanningInfo,
	deletePlanningInfo: _deletePlanningInfo,
	findPlanningInfosByLocation: _findPlanningInfosByLocation,
	getPlanningInfo: _getPlanningInfo,
	getPlanningInfosForDashboard: _getPlanningInfosForDashboard,
	listPlanningInfo: _listPlanningInfo,
	updatePlanningInfo: _updatePlanningInfo,
	searchPlanningInfo: _searchPlanningInfo
};

export default PlanningInfoService;
