import ApiService, { BUSINESS_BASE_URL, PageOption } from '../../index';

export const prefixRescueWork = '/rescueWork';

const _createRescueWork = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixRescueWork}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteRescueWork = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.delete(`${prefixRescueWork}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findRescueWorksByDateSearch = (dateSearch: Date): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/dateSearch=${dateSearch}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findRescueWorksByLocation = (locationId: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/locationId=${locationId}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findRescueWorksByLocationDetail = (locationDetail: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/locationDetail=${locationDetail}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getDataChcn = (): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/DataCHCN`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getMoneyLostByMonth = (locationId: string): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/DataMoneyLossByMonth/LocationId=${locationId}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getRescueWork = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listRescueWork = (pageOption: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixRescueWork}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateRescueWork = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.put(`${prefixRescueWork}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchRescueWork = (param = {}, pageOption = {}): Promise<PageOption<any>> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixRescueWork}/search`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));

const exportFileExcel = (param = {}): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixRescueWork}/Export`, param, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const RescueWorkService = {
	createRescueWork: _createRescueWork,
	deleteRescueWork: _deleteRescueWork,
	findRescueWorksByDateSearch: _findRescueWorksByDateSearch,
	findRescueWorksByLocation: _findRescueWorksByLocation,
	findRescueWorksByLocationDetail: _findRescueWorksByLocationDetail,
	getDataChcn: _getDataChcn,
	getMoneyLostByMonth: _getMoneyLostByMonth,
	getRescueWork: _getRescueWork,
	listRescueWork: _listRescueWork,
	updateRescueWork: _updateRescueWork,
	searchPropagates: _searchRescueWork,
	exportFileExcel,
};

export default RescueWorkService;
