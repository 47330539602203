import { PlusOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Select, Table } from 'antd';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import UsersService from 'services/api/authenService/usersService';
import provinceService from 'services/api/provinceService';
import { default as utils, default as Utils } from 'services/api/utils';
import Generator from 'services/generator';
import ChangePassDialog from './Dialog/ChangePassDialog';
import { NguoiDungTab, PermissionProps } from './NguoiDung';
import ThemNguoiDung from './ThemNguoiDung';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const QuanLyNguoiDung: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const child = searchParams.get('child');
	const tab = searchParams.get('tab');

	// state of component
	const [TongSoDiemChay, setTongSoDiemChay] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [districtElements, setDistrictElements]: any[] = useState([]);

	// get list of provinces
	const tinhThanhscustomer = Utils.GetTinhThanhUser();
	const tinhThanhsAdmin = Utils.GetTinhThanhAdmin();

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			title: t('label.order'),
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			dataIndex: 'FullName',
			title: t('label.fullName'),
		},
		{
			dataIndex: 'UserName',
			title: t('label.userName'),
		},
		{
			align: 'right' as const,
			dataIndex: 'PhoneNumber',
			title: t('label.soDienThoai'),
			width: 110,
		},
		{
			dataIndex: 'CustomerName',
			title: 'Trực thuộc',
		},
		{
			dataIndex: 'RoleName',
			title: 'Vai trò',
		},
		{
			align: 'center' as const,
			dataIndex: 'IsActive',
			title: 'Trạng thái',
			render: (IsActive: string) => {
				return (
					<div style={{ color: IsActive === 'ACTIVE' ? '#1BB36B' : '#FF2000' }}>
						{IsActive === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
					</div>
				);
			},
		},
		{
			align: 'center' as const,
			title: 'Thao tác',
			key: 'action',
			width: 50,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.USER_ACCOUNT_EDIT)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={e => {
							e.stopPropagation();
							CustomDialog(<ChangePassDialog username={record.UserName} Id={record.Id} />, {
								isCanClose: true,
								showCloseIcon: true,
								title: 'Cập nhật mật khẩu',
							});
						}}>
						{/* <KeyOutlined /> */}
						<img className="mt2" src="icon/Key.png" alt="edit" />
					</Button>

					<Button
						className="ml10"
						type={'link'}
						onClick={async e => {
							e.stopPropagation();
							navigate({
								pathname: `/nguoiDung?tab=quanLyNguoiDung&child=ThemNguoiDung&Id=${record.Id}`,
							});
						}}>
						<img src="icon/Pen.png" alt="edit" />
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	const onChangeTinhthanh = (value: any) => {
		form.resetFields(['QuanHuyen']);
		setDistrictElements([]);
		if (value) {
			provinceService.findDistrictsByProvince(value).then(result => {
				let quanHuyenList: any[] = [];
				if (
					props.userInfo &&
					props.userInfo.Locations &&
					props.userInfo.Locations.length > 0 &&
					!props.permissionList.includes(PermissionEnum.ADMIN)
				) {
					props.userInfo.Locations.forEach((location: any) => {
						if (location.DistrictId) {
							result.forEach(quanHuyen => {
								if (quanHuyen.Id === location.DistrictId) {
									quanHuyenList.push(quanHuyen);
								}
							});
						} else {
							quanHuyenList = result;
						}
					});
				} else {
					quanHuyenList = result;
				}
				setDistrictElements(Generator.generateOptions(quanHuyenList, 'Id', 'Name'));
			});
		}

		SearchDanhSachNguoiDung(CURRENT_PAGE);
	};

	const handleChangeDistrict = () => {
		SearchDanhSachNguoiDung(CURRENT_PAGE);
	};

	const handleChangeIsActive = () => {
		SearchDanhSachNguoiDung(CURRENT_PAGE);
	};

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.quanLyNguoiDung');
		NavBarDispatcher.setTitle(`${t('quanLyNguoiDung.title')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && (tab === NguoiDungTab.QUAN_LY_NGUOI_DUNG || tab === null)) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.USER_ACCOUNT_VIEW])) {
				SearchDanhSachNguoiDung();
			} else {
				message.error(t('message.unauthorizedView'));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, child]);

	// function GetDanhSachNguoiDung(page = 1, pageSize = 10, index = 0) {
	// 	const pageOption: PageOption = {
	// 		CurrentPage: page,
	// 		PageSize: pageSize,
	// 		Paging: true,
	// 	};
	// 	AuthService.getUsers(pageOption).then((res: any) => {
	// 		setTongSoDiemChay(res.TotalItems);
	// 		optiondanhsachNguoiDung(res ? res.Data : []);
	// 	});
	// }

	function onChangePage(page: number, pageSize: number) {
		setPageSizeDefault(pageSize);
		SearchDanhSachNguoiDung(page, pageSize);
	}

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			SearchDanhSachNguoiDung();
		}
	};

	function SearchDanhSachNguoiDung(page = currentPage, pageSize = pageSizeDefault) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);
		const param: any = {
			FullName: form.getFieldValue('Name'),
			IsActive: form.getFieldValue('IsActive'),
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Locations = {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			};
		}
		UsersService.searchData(param, pageOption)
			.then((res: any) => {
				setTongSoDiemChay(res.TotalItems);
				optiondanhsachNguoiDung(res ? res.Data : []);
			})
			.catch();
	}

	const optiondanhsachNguoiDung = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any) => {
				item.TrangThai = item.IsActive === 'ACTIVE' ? 'Hoạt động' : 'Không hoạt động';
				item.key = ++_index;
				array.push(item);
			});
		}
		Promise.all(promises)
			.then(() => {
				setUsers(array);
			})
			.catch();
	};

	const NguoiDung = () => {
		const handleShowViewDetail = (record: any) => {
			return {
				onClick: async () => {
					navigate({
						pathname: `/nguoiDung?tab=quanLyNguoiDung&child=ThemNguoiDung&Id=${record.Id}&mode=view`,
					});
				},
			};
		};
		return (
			<Form id="quanLyNguoiDung" layout="vertical" name="normal_login" form={form}>
				<div className={'flex--row'}>
					<Form.Item name="TinhThanh" className="withMinSelect">
						<Select
							showSearch
							optionFilterProp="children"
							placeholder="Tỉnh thành"
							onChange={onChangeTinhthanh}
							allowClear>
							{props.permissionList.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
						</Select>
					</Form.Item>
					<Form.Item name="QuanHuyen" className="ml10">
						<Select
							showSearch
							optionFilterProp="children"
							className="withMinSelect"
							placeholder={t('placeholder.quanHuyen')}
							allowClear
							onChange={handleChangeDistrict}>
							{districtElements}
						</Select>
					</Form.Item>
					<Form.Item name="IsActive">
						<Select
							className="withMinSelect ml10"
							showSearch
							optionFilterProp="Children"
							placeholder="Chọn trạng thái"
							onChange={handleChangeIsActive}
							allowClear>
							{utils.TrangThaiCustommer()}
						</Select>
					</Form.Item>
					<Form.Item name="Name" className="withMinSelect ml10">
						<Input placeholder="Nhập tên người dùng" prefix={<UserOutlined />} onKeyPress={handleKeyDown} allowClear />
					</Form.Item>
					{props.permissionList.includes(PermissionEnum.USER_ACCOUNT_CREATE) ||
					props.permissionList.includes(PermissionEnum.ADMIN) ? (
						<Button
							className="ml--auto"
							type={'primary'}
							onClick={async (e: any) => {
								navigate({ pathname: `/nguoiDung?tab=quanLyNguoiDung&child=ThemNguoiDung` });
							}}>
							<PlusOutlined />
							{t('label.create')}
						</Button>
					) : null}
				</div>
				<div className={'break-line mt10'} />
				<div className={'border-quan-ly-cong-tac-pc text--center'}>
					<h2 className={'mb0'}>{t('nav.quanLyNguoiDung')}</h2>
				</div>
				<Table
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					dataSource={users}
					columns={columns}
					bordered
					style={{ whiteSpace: 'pre' }}
					onRow={handleShowViewDetail}
					pagination={{
						defaultPageSize: 10,
						showSizeChanger: true,
						onChange: onChangePage,
						current: currentPage,
						total: TongSoDiemChay,
						pageSizeOptions: ['5', '10', '20'],
					}}
				/>
			</Form>
		);
	};

	const renderSelectedTab = (): JSX.Element => {
		switch (child) {
			case undefined:
			default:
				return NguoiDung();
			case 'ThemNguoiDung':
				return <ThemNguoiDung userInfo={props.userInfo} />;
		}
	};

	return renderSelectedTab();
};

export default withTranslation()(QuanLyNguoiDung);
