import { NavigateFromDashboardState } from 'redux/navigate-from-dashboard/navigate-from-dashboard.reducer';
import store from 'redux/store';

const navigateFromDashboardSelector = {
	getNavigateStatus: (): boolean => {
		return store.getState().navigateState.isNavigate;
	},
};

export default navigateFromDashboardSelector;
