import { RESET_lOCATION, SET_LOCATION } from 'redux/location/location.type';
import { ReduxAction } from 'redux/store';
import { DialogState } from 'react-st-modal/build/hooks/useDialog';

export interface IdialogState {
	dialog: DialogState<any>;
}

const INITIAL_STATE: IdialogState = { dialog: {} as DialogState<any> };

const dialogReducer = (state = INITIAL_STATE, action: ReduxAction): IdialogState => {
	switch (action.type) {
		case SET_LOCATION:
			return {
				...state,
				...action.payload,
			};
		case RESET_lOCATION:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default dialogReducer;
