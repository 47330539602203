import React, { memo } from 'react';

const ColumnTitle: React.FC<{ columnTitle: string }> = ({ columnTitle }: { columnTitle: string }) => {
	return (
		<p className="heading--8">
			<strong>{columnTitle}</strong>
		</p>
	);
};

export default memo(ColumnTitle);
