import { Button, Col, DatePicker, Divider, Form, Input, Row, Select } from 'antd';
import CommonControl from 'components/page/CommonControl';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import capBacEnum from 'enum/capBacEnum';
import chucVuEnum from 'enum/chucVuEnum';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import trucThuocEnum from 'enum/trucThuocEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import {
	default as FireFighterService,
	default as fireFighterService,
} from 'services/api/inventoryService/fireFighterService';
import pcccUnitService, { PcccUnit } from 'services/api/inventoryService/pcccUnitService';
import ProvinceService from 'services/api/provinceService';
import utils from 'services/api/utils';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import { LocationInfo } from '../Tabs/QuanLyDonVi/QuanLyDonVi';

const { Option } = Select;

interface IDialogThemMoiCbChienSyParams {
	id?: any;
	mode?: 'view' | 'edit' | 'add';
	callback?: () => void;
	userInfo?: UserInfo;
	location?: LocationInfo;
	donviId?: string;
}

// Dialog Thêm mới cán bộ chiến sỹ
const DialogThemMoiCbChienSy: FunctionComponent<IDialogThemMoiCbChienSyParams> = (
	props: IDialogThemMoiCbChienSyParams,
) => {
	const dialog = useDialog();
	const [form] = Form.useForm();

	const [districts, setDistricts] = useState<JSX.Element[]>([]);
	const [isShowDistrict, setIsShowDistrict] = useState(false);
	const [unitElements, setUnitElements] = useState<JSX.Element[]>([]);
	const [disabledTrucThuocSelect, setDisabledTrucThuocSelect] = useState(true);
	const [disabledUnitSelect, setDisabledUnitSelect] = useState(true);
	const [disabledTeamSelect, setDisabledTeamSelect] = useState(true);
	const [teamObject, setTeamObject] = useState<JSX.Element[]>([]);

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const executeCallback = () => {
		if (props.callback) {
			props.callback();
		}
	};

	// Click button Add thêm mới
	async function themMoiDSCanBoChienSy() {
		form.validateFields().then(values => {
			if (values) {
				let param: any = {
					CbcsID: form.getFieldValue('maCanBoNganh'),
					HoTen: form.getFieldValue('hovaten'),
					UserName: form.getFieldValue('UserName'),
					NgaySinh: form.getFieldValue('ngaysinh') ? moment(form.getFieldValue('ngaysinh')) : null,
					ChucVu: form.getFieldValue('chucvu'),
					CapBac: form.getFieldValue('capbac'),
					trucThuoc: form.getFieldValue('trucThuoc'),
					DonVi: form.getFieldValue('donViPCCC'),
					SoDienThoai: form.getFieldValue('PhoneNumber'),
					CMTNhanDan: form.getFieldValue('socmnd'),
					NgayBatDauLamViec: form.getFieldValue('thoiGianBatDau') ? moment(form.getFieldValue('thoiGianBatDau')) : null,
					Email: form.getFieldValue('email'),
					ChucNangCongViec: form.getFieldValue('chucnangcongviec'),
					TeamId: form.getFieldValue('team'),
					Location: {
						TinhThanh: form.getFieldValue('tinhThanh'),
						QuanHuyen: form.getFieldValue('quanHuyen'),
					},
				};
				if (props.id) {
					param.Id = props.id;
					fireFighterService
						.updateFireFighter(param)
						.then((_: any) => {
							// callback();
							showNotification(
								'success',
								t('notification.success'),
								t('notification.success_update_desc', { item: form.getFieldValue('hovaten') }),
							);
							dialog.close();
							executeCallback();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response.data || errors.message);
						});
				} else {
					fireFighterService
						.createFireFighter(param)
						.then((_: any) => {
							// callback();
							showNotification(
								'success',
								t('notification.success'),
								t('notification.success_create_desc', { item: form.getFieldValue('hovaten') }),
							);
							dialog.close();
							executeCallback();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response.data || errors.message);
						});
				}
			}
		});
	}

	const getUnits = async (params: any) => {
		const unitsFecthed = await pcccUnitService.searchPcccUnitForSelect(params);
		return unitsFecthed;
	};

	const getTeamUnits = async (params: any) => {
		const unitsFecthed = await pcccUnitService.getPcccUnit(params);
		return unitsFecthed;
	};

	const getDistricts = async (provinceId: string) => {
		return await ProvinceService.findDistrictsByProvince(provinceId);
	};

	// Chọn tỉnh thành
	const handleChangeProvince = async (provinceId: any) => {
		form.resetFields(['quanHuyen', 'donViPCCC', 'trucThuoc']);

		setDistricts([]);
		setUnitElements([]);

		if (provinceId) {
			const districts = await getDistricts(provinceId);
			let quanHuyenList: any[] = [];
			if (
				props.userInfo &&
				props.userInfo.Locations &&
				props.userInfo.Locations.length > 0 &&
				!props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
			) {
				props.userInfo.Locations.forEach((location: any) => {
					if (location.DistrictId) {
						districts.forEach(quanHuyen => {
							if (quanHuyen.Id === location.DistrictId) {
								quanHuyenList.push(quanHuyen);
							}
						});
					} else {
						quanHuyenList = districts;
					}
				});
			} else {
				quanHuyenList = districts;
			}
			setDistricts(Generator.generateOptions(quanHuyenList, 'Id', 'Name'));
			setDisabledTrucThuocSelect(false);
		} else {
			setIsShowDistrict(false);
			setDisabledTrucThuocSelect(true);
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeTrucThuoc = async (trucThuocType: LoaiHinhDonViEnum) => {
		form.resetFields(['quanHuyen', 'donViPCCC']);
		setUnitElements([]);

		if (trucThuocType) {
			const params = {
				Location: {
					TinhThanh: form.getFieldValue('tinhThanh'),
					QuanHuyen: form.getFieldValue('quanHuyen'),
				},
				UnderType: trucThuocType,
			};
			const units = await getUnits(params);
			generateUnitElements(units);
			setIsShowDistrict(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
			setDisabledUnitSelect(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
		} else {
			setIsShowDistrict(false);
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeDistrict = async (districtId: string) => {
		form.resetFields(['donViPCCC']);
		setUnitElements([]);
		if (districtId) {
			const params = {
				Location: {
					TinhThanh: form.getFieldValue('tinhThanh'),
					QuanHuyen: districtId,
				},
				UnderType: form.getFieldValue('trucThuoc'),
			};
			const units = await getUnits(params);
			generateUnitElements(units);
			setDisabledUnitSelect(false);
		} else {
			setDisabledUnitSelect(true);
		}
	};

	const handleChangePCCCUnit = async (PCCCId: string) => {
		form.resetFields(['team']);
		setTeamObject([]);
		if (PCCCId) {
			const data = await getTeamUnits(PCCCId);
			generateTeamElements(data.Teams);
			setDisabledTeamSelect(false);
		} else {
			setDisabledTeamSelect(true);
		}
	};

	const generateUnitElements = (units: PcccUnit[]) => {
		if (units && units.length > 0) {
			const options = units.map(unit => (
				<Option key={unit.Id} value={unit.Id}>
					{unit.Name}
				</Option>
			));

			setUnitElements(options);
		}
	};

	const generateTeamElements = (units: any) => {
		if (units && units.length > 0) {
			const options = units.map((team: any) => (
				<Option key={team.TeamId} value={team.TeamId}>
					{team.Name}
				</Option>
			));
			setTeamObject(options);
		}
	};

	useEffect(() => {
		if (props.id) {
			setDisabledTrucThuocSelect(false);
			setDisabledUnitSelect(false);
			setDisabledTeamSelect(false);
			// Get Data Công tác xây dựng thực tập phương án chữa cháy
			FireFighterService.findFireFightersByPosition(props.id).then((result: any) => {
				if (!result) {
					return;
				}
				const _data = result;
				let promises: PromiseLike<any>[] = [];

				setIsShowDistrict(_data.TrucThuoc === 'QUAN_HUYEN');
				getDistricts(_data.Location?.TinhThanh).then(districts => {
					setDistricts(Generator.generateOptions(districts, 'Id', 'Name'));
				});

				getUnitSelect();

				promises.push(
					getTeamUnits(result.DonVi).then(team => {
						generateTeamElements(team.Teams);
					}),
				);
				Promise.all(promises).then(() => {
					form.setFieldsValue({
						hovaten: _data.HoTen,
						UserName: _data.UserName,
						capbac: _data.CapBac,
						chucvu: _data.ChucVu,
						ngaysinh: _data.NgaySinh ? moment(_data.NgaySinh) : '',
						PhoneNumber: _data.SoDienThoai,
						tinhThanh: _data.Location?.TinhThanh,
						trucThuoc: _data.TrucThuoc,
						socmnd: _data.CMTNhanDan,
						donViPCCC: _data.DonVi,
						email: _data.Email,
						thoiGianBatDau: _data.StartOfWorkDate ? moment(_data.StartOfWorkDate) : '',
						quanHuyen: _data.Location?.QuanHuyen,
						maCanBoNganh: _data.CbcsID,
						chucnangcongviec: _data.ChucNangCongViec,
						team: _data.TeamId,
					});
				});
			});
		} else {
			form.setFieldsValue({
				tinhThanh: props.location?.TinhThanh,
				quanHuyen: props.location?.QuanHuyen,
			});
			if (props.location?.QuanHuyen) {
				form.setFieldsValue({ trucThuoc: 'QUAN_HUYEN' });
				setIsShowDistrict(true);
			} else {
				form.setFieldsValue({ trucThuoc: 'PC07' });
			}
			getDistricts(props.location?.TinhThanh ?? '').then(districts => {
				setDistricts(Generator.generateOptions(districts, 'Id', 'Name'));
			});
			getUnitSelect();

			handleChangePCCCUnit(props.donviId ?? '');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getUnitSelect = () => {
		const params = {
			Location: {
				TinhThanh: props.location?.TinhThanh,
				QuanHuyen: props.location?.QuanHuyen,
			},
		};
		getUnits(params).then(units => {
			let obj = units.find(o => o.Id === props.donviId);
			if (obj) {
				form.setFieldsValue({ donViPCCC: props.donviId });
				getTeamUnits(props.donviId).then(team => {
					generateTeamElements(team);
				});
			}
			generateUnitElements(units);
		});
	};

	return (
		<div className={'common-dialog'}>
			<Form layout="vertical" name="normal_login" form={form}>
				<Row gutter={32} className="mb15">
					<Col span={8}>
						<ColumnTitle columnTitle="I. Thông tin chung" />
						<div>
							<Form.Item
								label="Họ và Tên"
								name="hovaten"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Họ và tên không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập họ và tên" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Số điện thoại"
								name="PhoneNumber"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input type="text" placeholder={t('placeholder.phoneNumber')} disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="CMND/CCCD"
								name="socmnd"
								rules={[
									{
										pattern: systemProperties.citizenIdentifyPattern,
										message: 'Số CMND không đúng định dạng!',
									},
									{
										required: true,
										whitespace: true,
										message: 'Số CMND không được bỏ trống',
									},
								]}>
								<Input type="text" placeholder="Nhập số chứng minh nhân dân" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Email"
								name="email"
								rules={[
									{
										pattern: systemProperties.emailPattern,
										message: 'Email không đúng định dạng!',
									},
									{
										required: true,
										whitespace: true,
										message: 'Email không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập Email" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item className={'mt8'} label="Ngày sinh" name="ngaysinh">
								<DatePicker
									locale={CommonControl.localeVi}
									placeholder="Chọn ngày sinh"
									format="DD/MM/YYYY"
									style={{ width: '100%' }}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>
						</div>
					</Col>

					<Col span={8}>
						<ColumnTitle columnTitle="II. Thông tin đơn vị" />
						<div>
							<Form.Item
								name="tinhThanh"
								label="Tỉnh thành"
								rules={[
									{
										message: 'Tỉnh thành không được bỏ trống',
										required: true,
										whitespace: true,
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={t('placeholder.tinhThanh')}
									allowClear
									onChange={handleChangeProvince}
									disabled>
									{props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
								</Select>
							</Form.Item>

							<Form.Item
								name="trucThuoc"
								label="Trực thuộc"
								rules={[
									{
										message: 'Trực thuộc không được bỏ trống',
										required: true,
										whitespace: true,
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn trực thuộc"
									allowClear
									onChange={handleChangeTrucThuoc}
									disabled>
									{Generator.generateOptions(trucThuocEnum.getAll())}
								</Select>
							</Form.Item>

							{isShowDistrict ? (
								<Form.Item
									name="quanHuyen"
									className={'mt8'}
									label="Quận Huyện"
									rules={[
										{
											message: 'Quận Huyện không được bỏ trống',
											required: true,
											whitespace: true,
										},
									]}>
									<Select
										showSearch
										optionFilterProp="children"
										placeholder={t('placeholder.quanHuyen')}
										allowClear
										onChange={handleChangeDistrict}
										disabled>
										{districts}
									</Select>
								</Form.Item>
							) : null}

							<Form.Item
								label="Đơn vị PCCC"
								name="donViPCCC"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Đơn vị PCCC không được bỏ trống',
									},
								]}>
								<Select
									optionFilterProp="children"
									showSearch
									placeholder="Chọn đơn vị PCCC"
									allowClear
									onChange={handleChangePCCCUnit}
									disabled>
									{unitElements}
								</Select>
							</Form.Item>

							<Form.Item label="Tên người dùng" name="UserName">
								<Input placeholder="Nhập người dùng" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item label="Đội trực thuộc" name="team">
								<Select optionFilterProp="children" showSearch placeholder="Chọn đội trực thuộc" allowClear>
									{teamObject}
								</Select>
							</Form.Item>
						</div>
					</Col>

					<Col span={8}>
						<ColumnTitle columnTitle="III. Thông tin thêm" />
						<div>
							<Form.Item label="Mã cán bộ ngành" name="maCanBoNganh">
								<Input placeholder="Nhập mã cán bộ ngành" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item name="capbac" label="Cấp bậc">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn cấp bậc"
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(capBacEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item name="chucvu" label="Chức vụ">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn chức vụ"
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(chucVuEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item label="Ngày bắt đầu" name="thoiGianBatDau">
								<DatePicker
									locale={CommonControl.localeVi}
									placeholder="Chọn ngày bắt đầu"
									format="DD/MM/YYYY"
									style={{ width: '100%' }}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item label="Chức năng công việc" name="chucnangcongviec">
								<Input placeholder="Nhập chức năng công việc" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Divider />

				<div className={'dialog__buttons mt10'}>
					<Button
						type="default"
						htmlType="button"
						onClick={() => {
							dialog.close();
						}}>
						{props.mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{props.mode !== 'view' && (
						<Button type={'primary'} htmlType="submit" onClick={() => themMoiDSCanBoChienSy()}>
							{props.id ? 'Lưu' : 'Thêm mới'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogThemMoiCbChienSy;
