import { UserInfo } from 'services/api/authenService/usersService';
import { PermissionEnum } from 'enum/permissionEnum';
import store from 'redux/store';
import { setPermissionList, setUserInfo } from './permission.action';

const PermissionDispatcher = {
	setPermission: (input: PermissionEnum[]) => {
		return store.dispatch(setPermissionList(input));
	},
	setUserInfo: (input: UserInfo) => {
		return store.dispatch(setUserInfo(input));
	},
};

export default PermissionDispatcher;
