import { QuestionCircleOutlined } from '@ant-design/icons';
import { Menu, Tooltip } from 'antd';
import Sider from 'antd/es/layout/Sider';
import SubMenu from 'antd/lib/menu/SubMenu';
import { QuanLyCongTacCHCNTab } from 'components/page/CongTacPhongChayVaCHCN/CongTacPhongChayVaChcn';
import { QuanLyBaoCaoTab } from 'components/page/QuanLyBaoCao/QuanLyBaoCao';
import { QuanLyChatLuongThietBiTab } from 'components/page/QuanLyChatLuongThietBi/QuanLyChatLuongThietBi';
import { QuanLyCongTacPhongChayTab } from 'components/page/QuanLyCongTacPhongChay/QuanLyCongTacPhongChay';
import { QuanLyDonViPhongChayNav } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import { NguoiDungTab } from 'components/page/QuanLyNguoiDung/NguoiDung';
import { DeviceManagementTab } from 'components/page/QuanLyThietBi/DeviceManagement';
import CustomIcon from 'components/shared/Icon';
import LineBreak from 'components/shared/LineBreak';
import {
	AccountGiamSatTrucTuyenArray,
	AccountUserArray,
	BaoCaoArray,
	CongTacChuaChayArray,
	CongTacPhongChayArray,
	DashBoardArray,
	DonViPhongChayArray,
	FactArray,
	PermissionEnum,
	ThietBiArray,
} from 'enum/permissionEnum';
import GetValueFromToken from 'hooks/userInfoHook';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, NavLink, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import NavBarSelector from 'redux/NavBar/navBar.selector';
import PermissionSelector from 'redux/Permission/permission.selector';
import './NavBar.scss';

interface INavTabNode {
	key: string;
	value: string;
	children?: INavTabNode[];
}

interface INavTabData {
	TRUNG_TAM_CHI_HUY: INavTabNode;
	GIAM_SAT_TRUC_TUYEN: INavTabNode;
	QUAN_LY_DON_VI_PHONG_CHAY: INavTabNode;
	QUAN_LY_CONG_TAC_PHONG_CHAY: INavTabNode;
	CONG_TAC_PHONG_CHAY_VA_CHCN: INavTabNode;
	CAM_BIEN: INavTabNode;
	QUAN_LY_BAO_CAO: INavTabNode;
	NGUOI_DUNG: INavTabNode;
	CHAT_LUONG: INavTabNode;
	TRO_GIUP: INavTabNode;
}

export const NavTabList: INavTabData = {
	TRUNG_TAM_CHI_HUY: {
		key: '1',
		value: '/dashboard',
	},
	GIAM_SAT_TRUC_TUYEN: {
		key: '2',
		value: '/giamSatTrucTuyen',
	},
	QUAN_LY_DON_VI_PHONG_CHAY: {
		key: '3',
		value: '/quanLyDonViPhongChay',
		children: [
			{
				key: '3-1',
				value: `${QuanLyDonViPhongChayNav.DANH_SACH_DON_VI_PCCC}`,
			},
			{
				key: '3-2',
				value: `${QuanLyDonViPhongChayNav.QUAN_LY_DON_VI}`,
			},
			{
				key: '3-3',
				value: `${QuanLyDonViPhongChayNav.LICH_TRUC}`,
			},
		],
	},
	QUAN_LY_CONG_TAC_PHONG_CHAY: {
		key: '4',
		value: '/quanLyCongTacPhongChay',
		children: [
			{
				key: '4-1',
				value: `${QuanLyCongTacPhongChayTab.QUAN_LY_CO_SO_VA_CONG_TRINH}`,
			},
			{
				key: '4-2',
				value: `${QuanLyCongTacPhongChayTab.THAM_DUYET_VA_CHUNG_NHAN}`,
			},
			{
				key: '4-3',
				value: `${QuanLyCongTacPhongChayTab.SO_CU_KIEM_TRA}`,
			},
			{
				key: '4-4',
				value: `${QuanLyCongTacPhongChayTab.KE_HOACH_KIEM_TRA}`,
			},
			{
				key: '4-5',
				value: `${QuanLyCongTacPhongChayTab.TUYEN_TRUYEN_HUAN_LUYEN_PCCC}`,
			},
			{
				key: '4-6',
				value: `${QuanLyCongTacPhongChayTab.XU_LY_VI_PHAM_PCCC}`,
			},
		],
	},
	CONG_TAC_PHONG_CHAY_VA_CHCN: {
		key: '5',
		value: '/congTacChuaChayVaCNCH',
		children: [
			{
				key: '5-1',
				value: `${QuanLyCongTacCHCNTab.CONG_TAC_CHUA_CHAY}`,
			},
			{
				key: '5-2',
				value: `${QuanLyCongTacCHCNTab.XAY_DUNG_TTPA_CHUA_CHAY}`,
			},
			{
				key: '5-3',
				value: `${QuanLyCongTacCHCNTab.CONG_TAC_CHCN}`,
			},
		],
	},
	CAM_BIEN: {
		key: '6',
		value: '/quanLyThietBi',
		children: [
			{
				key: '6-1',
				value: `${DeviceManagementTab.THIET_BI_TRUNG_TAM}`,
			},
			{
				key: '6-2',
				value: `${DeviceManagementTab.CAM_BIEN}`,
			},
		],
	},
	QUAN_LY_BAO_CAO: {
		key: '7',
		value: '/quanLyBaoCao',
		children: [
			{
				key: '7-1',
				value: `${QuanLyBaoCaoTab.THIET_BI_LAP_DAT}`,
			},
			{
				key: '7-2',
				value: `${QuanLyBaoCaoTab.CO_SO_CONG_TRINH}`,
			},
			{
				key: '7-3',
				value: `${QuanLyBaoCaoTab.TRANG_THAI_PHUONG_TIEN_PCCC}`,
			},
			{
				key: '7-4',
				value: `${QuanLyBaoCaoTab.DIEM_CHAY}`,
			},
		],
	},
	NGUOI_DUNG: {
		key: '8',
		value: '/nguoiDung',
		children: [
			{
				key: '8-1',
				value: `${NguoiDungTab.QUAN_LY_NGUOI_DUNG}`,
			},
			{
				key: '8-2',
				value: `${NguoiDungTab.QUAN_LY_KHACH_HANG}`,
			},
			{
				key: '8-3',
				value: `${NguoiDungTab.LICH_SU_DANG_NHAP}`,
			},
			{
				key: '8-4',
				value: `${NguoiDungTab.PHAN_QUYEN}`,
			},
		],
	},
	CHAT_LUONG: {
		key: '9',
		value: '/quanLyChatLuongThietBi',
		children: [
			{
				key: '9-1',
				value: `${QuanLyChatLuongThietBiTab.QUAN_LY_LOI}`,
			},
			{
				key: '9-2',
				value: `${QuanLyChatLuongThietBiTab.QUAN_LY_LOAI_THIET_BI}`,
			},
			{
				key: '9-3',
				value: `${QuanLyChatLuongThietBiTab.BAO_CAO_KET_QUA_SAN_XUAT}`,
			},
			{
				key: '9-4',
				value: `${QuanLyChatLuongThietBiTab.BAO_CAO_CHI_TIET}`,
			},
			{
				key: '9-5',
				value: `${QuanLyChatLuongThietBiTab.TONG_HOP_KET_QUA_TEST}`,
			},
		],
	},
	TRO_GIUP:{
		key: '10',
		value: '/troGiup',
	}

};

interface NavBarProps {
	collapse: boolean;
	permissionList: PermissionEnum[];
}

const mapStateToProps = (): NavBarProps => ({
	collapse: NavBarSelector.getCollapse(),
	permissionList: PermissionSelector.getPermissionList(),
});
const NavBar: FunctionComponent<NavBarProps> = (props: NavBarProps) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [collapsed, setCollapsed] = useState<boolean>(props.collapse);
	const [permisstion, setPermisstion] = useState<PermissionEnum[]>(props.permissionList);
	const [searchParams] = useSearchParams();
	const [selectedKey, setSelectedKey] = useState<string>('1-1');
	const [openKeys, setOpenKeys] = useState<string[]>([]);

	const [isShowDashBoard, setIsShowDashBoard] = useState<boolean>(false);
	const [isShowNguoiDung, setIsShowNguoiDung] = useState<boolean>(false);
	const [isShowGiamSatTrucTuyen, setIsShowGiamSatTrucTuyen] = useState<boolean>(false);
	const [isShowDonViPCCC, setIsShowDonViPCCC] = useState<boolean>(false);
	const [isShowCongTacPhongChay, setIsShowCongTacPhongChay] = useState<boolean>(false);
	const [isShowCongTacChuaChay, setIsShowCongTacChuaChay] = useState<boolean>(false);
	const [isShowThietBi, setIsShowThietBi] = useState<boolean>(false);
	const [isShowBaoCao, setIsShowBaoCao] = useState<boolean>(false);
	const [isShowFact, setIsShowFact] = useState<boolean>(false);

	useEffect(() => {
		setPermisstion(props.permissionList);
		setCollapsed(props.collapse);
	}, [props.collapse, props.permissionList]);

	useEffect(() => {
		const showNguoiDung = checkOrPermission(AccountUserArray);
		const showDashBoard = checkOrPermission(DashBoardArray);
		const giamSatTrucTuyen = checkOrPermission(AccountGiamSatTrucTuyenArray);
		const showDonViPCCC = checkOrPermission(DonViPhongChayArray);
		const showCongTacPhongChay = checkOrPermission(CongTacPhongChayArray);
		const showCongTacChuaChay = checkOrPermission(CongTacChuaChayArray);
		const showThietBi = checkOrPermission(ThietBiArray);
		const showBaoCao = checkOrPermission(BaoCaoArray);
		const showFact = checkOrPermission(FactArray);
		setIsShowDashBoard(showDashBoard);
		setIsShowNguoiDung(showNguoiDung);
		setIsShowGiamSatTrucTuyen(giamSatTrucTuyen);
		setIsShowDonViPCCC(showDonViPCCC);
		setIsShowCongTacPhongChay(showCongTacPhongChay);
		setIsShowCongTacChuaChay(showCongTacChuaChay);
		setIsShowThietBi(showThietBi);
		setIsShowBaoCao(showBaoCao);
		setIsShowFact(showFact);
	}, []);

	const toggleCollapsed = (value: boolean) => {
		setCollapsed(value);
		NavBarDispatcher.setCollapse(value);
	};

	const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
		let accepted = false;

		orPermissions.forEach(r => {
			if (GetValueFromToken.useGetPermission?.includes(r)) {
				accepted = true;
			}
		});

		return accepted;
	};

	useEffect(() => {
		// Set default tab
		const navTabList = Object.getOwnPropertyNames(NavTabList);
		let open: INavTabNode =
			NavTabList[
				navTabList.find(
					r => location.pathname.toUpperCase() === NavTabList[r as keyof INavTabData].value.toUpperCase(),
				) as keyof INavTabData
			];
		if (open) {
			if (open?.children) {
				open = open.children.find(r => r.value === searchParams.get('tab')) || open.children[0];
			}
			setSelectedKey(open?.key);
			setOpenKeys([...openKeys, open?.key.split('-')[0]]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname, searchParams]);

	return (
		<Sider
			collapsible
			collapsed={collapsed}
			onCollapse={toggleCollapsed}
			className={'nav-bar-container'}
			trigger={null}
			width={260}>
			<Link to={{ pathname: '/' }}>
				{collapsed ? null : (
					<div className={'logo-container'}>
						<img src={'icon/Logo/01.Logo-duong-ban_Crop.png'} alt={'fs-logo'} />
					</div>
				)}
				{collapsed ? (
					<div className={'logo-container'}>
						<img src={'icon/Logo/favicon_Crop.png'} alt={'fs-logo'} />
					</div>
				) : null}
			</Link>

			<LineBreak />
			<Menu
				mode="inline"
				openKeys={openKeys}
				selectedKeys={[selectedKey]}
				onSelect={node => setSelectedKey(node.key)}
				onOpenChange={openKeys => setOpenKeys(openKeys)}>
				{isShowDashBoard && (
					<Menu.Item
						key={NavTabList.TRUNG_TAM_CHI_HUY.key}
						icon={CustomIcon.NavBar.BangDieuKhien}
						onClick={() => {
							navigate(NavTabList.TRUNG_TAM_CHI_HUY.value);
						}}>
						<Tooltip title={t('nav.trungTamChiHuy')}></Tooltip>
						<Link to={{ pathname: '/' }} />
						{t('nav.trungTamChiHuy')}
					</Menu.Item>
				)}

				{isShowGiamSatTrucTuyen && (
					<Menu.Item
						key={NavTabList.GIAM_SAT_TRUC_TUYEN.key}
						icon={CustomIcon.NavBar.GiamSatTrucTuyen}
						onClick={() => {
							navigate(NavTabList.GIAM_SAT_TRUC_TUYEN.value);
						}}>
						<Tooltip title={t('nav.giamSatTrucTuyen')}></Tooltip>
						<Link to={{ pathname: NavTabList.GIAM_SAT_TRUC_TUYEN.value }} />
						{t('nav.giamSatTrucTuyen')}
					</Menu.Item>
				)}

				{isShowDonViPCCC && (
					<SubMenu
						key={NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.key}
						icon={CustomIcon.NavBar.QuanLyDonViPCCC}
						title={t('nav.quanLyDonViPhongChayChuaChay')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.PCCC_UNIT_VIEW)) && (
							<Menu.Item key={NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![0].key} icon={CustomIcon.NavBar.Item}>
								<Tooltip title={t('nav.quanLyDonViPhongChayChuaChay')}></Tooltip>
								<Link
									to={{
										pathname: '/quanLyDonViPhongChay',
										search: `?tab=${NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![0].value}`,
									}}
								/>
								{t('quanLyDonViPhongChay.quanLyDonVi')}
							</Menu.Item>
						)}
						{/* {(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FIRE_TRUCK_VIEW) ||
							props.permissionList.includes(PermissionEnum.FIRE_FIGHTER_VIEW) ||
							props.permissionList.includes(PermissionEnum.SUPPORT_UNIT_VIEW) ||
							props.permissionList.includes(PermissionEnum.WATER_POINT_VIEW)) && (
							<Menu.Item key={NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![1].key} icon={CustomIcon.NavBar.Item}>
								<Tooltip title={t('nav.quanLyDonViPhongChayChuaChay')}></Tooltip>
								<Link
									to={{
										pathname: '/quanLyDonViPhongChay',
										search: `?tab=${NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![1].value}`,
									}}
								/>
								{t('quanLyDonViPhongChay.chiTietDonVi')}
							</Menu.Item>
						)} */}

						<Menu.Item key={NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![2].key} icon={CustomIcon.NavBar.Item}>
							<Tooltip title={t('nav.quanLyDonViPhongChayChuaChay')}></Tooltip>
							<Link
								to={{
									pathname: '/quanLyDonViPhongChay',
									search: `?tab=${NavTabList.QUAN_LY_DON_VI_PHONG_CHAY.children![2].value}`,
								}}
							/>
							Lịch trực
						</Menu.Item>
					</SubMenu>
				)}

				{isShowCongTacPhongChay && (
					<SubMenu
						key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.key}
						// icon={<ContainerOutlined />}
						icon={CustomIcon.NavBar.QuanLyCongTacPC}
						title={t('nav.quanLyCongTacPhongChay')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.CONSTRUCTION_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![0].value}`,
									});
								}}
								title={!collapsed && t('quanLyCongTacPhongChay.quanLyCoSoVaCongTrinh')}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=QuanLyCoSoVaCongTrinh' }} />
								{t('quanLyCongTacPhongChay.quanLyCoSoVaCongTrinh')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![1].value}`,
									});
								}}
								title={!collapsed && t('quanLyCongTacPhongChay.thamDuyetChungNhan')}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=ThamDuyetChungNhan' }} />
								{t('quanLyCongTacPhongChay.thamDuyetChungNhan')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_PLAN_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![2].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![2].value}`,
									});
								}}
								title={!collapsed && 'Cơ sở pháp lý'}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=SoCuKiemTra' }} />
								Cơ sở pháp lý
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![3].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![3].value}`,
									});
								}}
								title={!collapsed && 'Kế hoạch kiểm tra'}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=KeHoachKiemTra' }} />
								Kế hoạch kiểm tra
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.PROPAGAE_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![4].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![4].value}`,
									});
								}}
								title={!collapsed && t('quanLyCongTacPhongChay.tuyenTruyenHuanLuyen')}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=TuyenTruyenHuanLuyen' }} />
								{t('quanLyCongTacPhongChay.tuyenTruyenHuanLuyen')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.VIOLATION_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![5].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.value,
										search: `?tab=${NavTabList.QUAN_LY_CONG_TAC_PHONG_CHAY.children![5].value}`,
									});
								}}
								title={!collapsed && t('quanLyCongTacPhongChay.xuLyViPham')}>
								<Link to={{ pathname: '/quanLyCongTacPhongChay?tab=XuLyViPham' }} />
								{t('quanLyCongTacPhongChay.xuLyViPham')}
							</Menu.Item>
						)}
					</SubMenu>
				)}

				{isShowCongTacChuaChay && (
					<SubMenu
						key={NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.key}
						icon={CustomIcon.NavBar.CongTacChuaChay}
						title={t('nav.congTacChuaChayVaCuuHoCuuNan')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FIRE_PROTECTION_VIEW)) && (
							<Menu.Item
								key={NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.value,
										search: `?tab=${NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![0].value}`,
									});
								}}
								title={!collapsed && t('congTacPhongChayVaCHCN.congTacChuaChay')}>
								<Link to={{ pathname: '/congTacChuaChayVaCNCH?tab=CongTacChuaChay' }} />
								{t('congTacPhongChayVaCHCN.congTacChuaChay')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.PLANING_VIEW)) && (
							<Menu.Item
								key={NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.value,
										search: `?tab=${NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![1].value}`,
									});
								}}
								title={!collapsed && t('congTacPhongChayVaCHCN.xayDungTTPAChuaChay')}>
								<Link to={{ pathname: '/congTacChuaChayVaCNCH?tab=XayDungTTPAChuaChay' }} />
								{t('congTacPhongChayVaCHCN.xayDungTTPAChuaChay')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.RESCUE_WORK_VIEW)) && (
							<Menu.Item
								key={NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![2].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.value,
										search: `?tab=${NavTabList.CONG_TAC_PHONG_CHAY_VA_CHCN.children![2].value}`,
									});
								}}
								title={!collapsed && t('congTacPhongChayVaCHCN.congTacCHCN')}>
								<Link to={{ pathname: '/congTacChuaChayVaCNCH?tab=CongTacCNCH' }} />
								{t('congTacPhongChayVaCHCN.congTacCHCN')}
							</Menu.Item>
						)}
					</SubMenu>
				)}

				{isShowThietBi && (
					<SubMenu key={NavTabList.CAM_BIEN.key} icon={CustomIcon.NavBar.ThietBi} title={t('nav.quanLyThietBi')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.DEVICE_VIEW)) && (
							<Menu.Item
								key={NavTabList.CAM_BIEN.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CAM_BIEN.value,
										search: `?tab=${NavTabList.CAM_BIEN.children![0].value}`,
									});
								}}
								title={!collapsed && t('quanLyThietBi.quanLyThietBiTrungTam')}>
								<Link
									to={{ pathname: NavTabList.CAM_BIEN.value, search: `?tab=${NavTabList.CAM_BIEN.children![0].value}` }}
								/>
								{t('quanLyThietBi.quanLyThietBiTrungTam')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.DEVICE_VIEW)) && (
							<Menu.Item
								key={NavTabList.CAM_BIEN.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CAM_BIEN.value,
										search: `?tab=${NavTabList.CAM_BIEN.children![1].value}`,
									});
								}}
								title={!collapsed && t('quanLyThietBi.quanLyCamBien')}>
								<Link
									to={{ pathname: NavTabList.CAM_BIEN.value, search: `?tab=${NavTabList.CAM_BIEN.children![1].value}` }}
								/>
								{t('quanLyThietBi.quanLyCamBien')}
							</Menu.Item>
						)}
					</SubMenu>
				)}

				{isShowBaoCao && (
					<SubMenu key={NavTabList.QUAN_LY_BAO_CAO.key} icon={CustomIcon.NavBar.BaoCao} title={t('nav.quanLyBaoCao')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.REPORT_DEVICE_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_BAO_CAO.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_BAO_CAO.value,
										search: `?tab=${NavTabList.QUAN_LY_BAO_CAO.children![0].value}`,
									});
								}}
								title={!collapsed && t('quanLyBaoCao.baoCaoThietBiLapDat')}>
								<Link to={{ pathname: '/quanLyBaoCao?tab=thietBiLapDat' }} />
								{t('quanLyBaoCao.baoCaoThietBiLapDat')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.REPORT_CO_SO_CONG_TRINH_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_BAO_CAO.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_BAO_CAO.value,
										search: `?tab=${NavTabList.QUAN_LY_BAO_CAO.children![1].value}`,
									});
								}}
								title={!collapsed && t('quanLyBaoCao.baoCaoCoSoCongTrinh')}>
								<Link to={{ pathname: '/quanLyBaoCao?tab=coSoCongTrinh' }} />
								{t('quanLyBaoCao.baoCaoCoSoCongTrinh')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.REPORT_FIRE_TRUCK_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_BAO_CAO.children![2].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_BAO_CAO.value,
										search: `?tab=${NavTabList.QUAN_LY_BAO_CAO.children![2].value}`,
									});
								}}
								title={!collapsed && t('quanLyBaoCao.baoCaoTrangThaiPhuongTienPCCC')}>
								<Link to={{ pathname: '/quanLyBaoCao?tab=trangThaiPhuongTienPCCC' }} />
								{t('quanLyBaoCao.baoCaoTrangThaiPhuongTienPCCC')}
							</Menu.Item>
						)}
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_VIEW)) && (
							<Menu.Item
								key={NavTabList.QUAN_LY_BAO_CAO.children![3].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.QUAN_LY_BAO_CAO.value,
										search: `?tab=${NavTabList.QUAN_LY_BAO_CAO.children![3].value}`,
									});
								}}
								title={!collapsed && t('quanLyBaoCao.baoCaoDiemChay')}>
								<Link to={{ pathname: '/quanLyBaoCao?tab=diemChay' }} />
								{t('quanLyBaoCao.baoCaoDiemChay')}
							</Menu.Item>
						)}
					</SubMenu>
				)}

				{isShowNguoiDung && (
					<SubMenu key={NavTabList.NGUOI_DUNG.key} icon={CustomIcon.NavBar.NguoiDung} title={t('nav.nguoiDung')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.USER_ACCOUNT_VIEW)) && (
							<Menu.Item
								key={NavTabList.NGUOI_DUNG.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.NGUOI_DUNG.value,
										search: `?tab=${NavTabList.NGUOI_DUNG.children![0].value}`,
									});
								}}
								title={!collapsed && t('nav.quanLyNguoiDung')}>
								<Link to={{ pathname: '/nguoiDung?tab=quanLyNguoiDung' }} />
								{t('nav.quanLyNguoiDung')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.CUSTOMER_VIEW)) && (
							<Menu.Item
								key={NavTabList.NGUOI_DUNG.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.NGUOI_DUNG.value,
										search: `?tab=${NavTabList.NGUOI_DUNG.children![1].value}`,
									});
								}}
								title={!collapsed && t('nav.quanLyKhachHang')}>
								<Link to={{ pathname: '/nguoiDung?tab=quanLyKhachHang' }} />
								{t('nav.quanLyKhachHang')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.USER_HISTORY_VIEW)) && (
							<Menu.Item
								key={NavTabList.NGUOI_DUNG.children![2].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.NGUOI_DUNG.value,
										search: `?tab=${NavTabList.NGUOI_DUNG.children![2].value}`,
									});
								}}
								title={!collapsed && t('nav.lichSuDangNhap')}>
								<Link to={{ pathname: '/nguoiDung?tab=quanLyKhachHang' }} />
								{t('nav.lichSuDangNhap')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.USER_ACCOUNT_VIEW)) && (
							<Menu.Item
								key={NavTabList.NGUOI_DUNG.children![3].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.NGUOI_DUNG.value,
										search: `?tab=${NavTabList.NGUOI_DUNG.children![3].value}`,
									});
								}}
								title={!collapsed && t('nav.phanQuyen')}>
								<Link to={{ pathname: '/nguoiDung?tab=phanQuyen' }} />
								{t('nav.phanQuyen')}
							</Menu.Item>
						)}
					</SubMenu>
				)}

				{isShowFact && (
					<SubMenu
						key={NavTabList.CHAT_LUONG.key}
						icon={CustomIcon.NavBar.SafetyCertificate}
						title={t('nav.quanLyChatLuong')}>
						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FACT_VIEW)) && (
							<Menu.Item
								key={NavTabList.CHAT_LUONG.children![0].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CHAT_LUONG.value,
										search: `?tab=${NavTabList.CHAT_LUONG.children![0].value}`,
									});
								}}
								title={!collapsed && t('nav.quanLyLoi')}>
								<Link to={{ pathname: '/quanLyChatLuong?tab=quanLyLoi' }} />
								{t('nav.quanLyLoi')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FACT_VIEW)) && (
							<Menu.Item
								key={NavTabList.CHAT_LUONG.children![1].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CHAT_LUONG.value,
										search: `?tab=${NavTabList.CHAT_LUONG.children![1].value}`,
									});
								}}
								title={!collapsed && t('nav.quanLyLoaiThietBi')}>
								<Link to={{ pathname: '/quanLyChatLuong?tab=quanLyLoaiThietBi' }} />
								{t('nav.quanLyLoaiThietBi')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FACT_VIEW)) && (
							<Menu.Item
								key={NavTabList.CHAT_LUONG.children![2].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CHAT_LUONG.value,
										search: `?tab=${NavTabList.CHAT_LUONG.children![2].value}`,
									});
								}}
								title={!collapsed && t('nav.baoCaoKetQuaSanXuat')}>
								<Link to={{ pathname: '/quanLyChatLuong?tab=baoCaoKetQuaSanXuat' }} />
								{t('nav.baoCaoKetQuaSanXuat')}
							</Menu.Item>
						)}

						{/* <Menu.Item
							key={NavTabList.CHAT_LUONG.children![2].key}
							icon={CustomIcon.NavBar.Item}
							onClick={() => {
								navigate({
									pathname: NavTabList.CHAT_LUONG.value,
									search: `?tab=${NavTabList.CHAT_LUONG.children![2].value}`,
								});
							}}
							title={!collapsed && t('nav.baoCaoKetQua')}
						>
							<Link to={{ pathname: '/quanLyChatLuong?tab=baoCaoKetQuaTest' }} />
							{t('nav.baoCaoKetQua')}
						</Menu.Item> */}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FACT_VIEW)) && (
							<Menu.Item
								key={NavTabList.CHAT_LUONG.children![3].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CHAT_LUONG.value,
										search: `?tab=${NavTabList.CHAT_LUONG.children![3].value}`,
									});
								}}
								title={!collapsed && t('nav.baoCaoChiTiet')}>
								<Link to={{ pathname: '/quanLyChatLuong?tab=baoCaoChiTiet' }} />
								{t('nav.baoCaoChiTiet')}
							</Menu.Item>
						)}

						{(props.permissionList.includes(PermissionEnum.ADMIN) ||
							props.permissionList.includes(PermissionEnum.FACT_VIEW)) && (
							<Menu.Item
								key={NavTabList.CHAT_LUONG.children![4].key}
								icon={CustomIcon.NavBar.Item}
								onClick={() => {
									navigate({
										pathname: NavTabList.CHAT_LUONG.value,
										search: `?tab=${NavTabList.CHAT_LUONG.children![4].value}`,
									});
								}}
								title={!collapsed && t('nav.tongHop')}>
								<Link to={{ pathname: '/quanLyChatLuong?tab=tongHopKetQuaTest' }} />
								{t('nav.tongHop')}
							</Menu.Item>
						)}
					</SubMenu>
				)}
				{/* TODO:: Thêm trợ giúp */}
				{/* <Menu.Item
					key={10}
					icon={<QuestionCircleOutlined style={{ fontSize: '22px' }} />}
					onClick={() => {
						navigate({pathname: NavTabList.TRO_GIUP.value});
					}}
					title={!collapsed && t('nav.tongHop')}>
					<Link to={{ pathname: '/quanLyChatLuong?tab=tongHopKetQuaTest' }} />
					{t('nav.troGiup')}
				</Menu.Item> */}
			</Menu>
			<div className="display-version">
				<label>
					{collapsed ? (
						<img width={60} src={'icon/Logo/01.Logo-duong-ban_Crop.png'} alt={'fs-logo'} />
					) : (
						'®BASATO 2022 Phiên bản 1.0'
					)}
				</label>
			</div>
		</Sider>
	);
};

export default connect(mapStateToProps)(withTranslation()(NavBar));
