import { t } from 'i18next';

export enum TrangThaiThucHienEnum {
	DANG_THUC_HIEN = 'DANG_THUC_HIEN',
	CHUA_BAT_DAU = 'CHUA_BAT_DAU',
	HOAN_THANH = 'HOAN_THANH',
}

interface TrangThaiThucHien {
	value: TrangThaiThucHienEnum;
	label: string;
	color: string;
	bg?: string;
}

const _fromEnum = (trangThaiValue: TrangThaiThucHienEnum): TrangThaiThucHien => {
	switch (trangThaiValue) {
		case TrangThaiThucHienEnum.CHUA_BAT_DAU:
			return {
				label: t('enum.trangThaiThucHien.CHUA_BAT_DAU'),
				value: TrangThaiThucHienEnum.CHUA_BAT_DAU,
				color: 'FF720E',
				bg: 'FFEECC'
			};
		case TrangThaiThucHienEnum.DANG_THUC_HIEN:
			return {
				label: t('enum.trangThaiThucHien.DANG_THUC_HIEN'),
				value: TrangThaiThucHienEnum.DANG_THUC_HIEN,
				color: '1B56B3',
				bg: 'C7DCFC'
			};
		case TrangThaiThucHienEnum.HOAN_THANH:
			return {
				label: t('enum.trangThaiThucHien.HOAN_THANH'),
				value: TrangThaiThucHienEnum.HOAN_THANH,
				color: '1BB36B',
				bg: 'D1F0E1' 
			};
	}
};

const _getAll = (): TrangThaiThucHien[] => {
	return Object.values(TrangThaiThucHienEnum).map(r => _fromEnum(r as TrangThaiThucHienEnum));
};

const trangThaiThucHienEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default trangThaiThucHienEnum;
