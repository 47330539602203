import {t} from "i18next";

export enum AlertTypeEnum {
	CenterButton, // Cảnh báo từ nút bấm trung tâm
	RemoteControl, // Cảnh báo từ điều khiển từ xa
	SmokeSensor, // Cảnh báo từ cảm biến khói
	DoorSensor, // Cảnh báo từ cảm biến cửa
	PirSensor, // Cảnh báo từ cảm biến PIR
	TemperatureSensor, // Cảnh báo từ cảm biến nhiệt độ
	DeviceIo, // Cảnh báo từ I/O (đấu với tủ báo cháy)
	Dtmf, // Cảnh báo từ tín hiệu DTMF
	FireBoxError, // Cảnh báo tủ báo cháy có lỗi.
	LostMainPower, // Cảnh báo mất nguồn chính
	LostSubPower, // Cảnh báo mất nguồn dự phòng
	FireRaySensor, // Cảnh báo từ cảm biến tia lửa
	LightBellDevice, // Cảnh báo từ bộ chuông đèn, nút bấm
	SmokeAndTempSensor, // Cảnh báo từ cảm biến khói+nhiệt
	UserAlert, // Cảnh báo do người dân gọi điện
}

interface alertType {
	value: AlertTypeEnum,
	label: string
}

const _fromEnum = (value: AlertTypeEnum): alertType => {
	let label: string = '';
	switch (value) {
		case AlertTypeEnum.CenterButton:
			label = t('enum.alertType.CenterButton');
			break;
		case AlertTypeEnum.RemoteControl:
			label = t('enum.alertType.RemoteControl');
			break;
		case AlertTypeEnum.SmokeSensor:
			label = t('enum.alertType.SmokeSensor');
			break;
		case AlertTypeEnum.DoorSensor:
			label = t('enum.alertType.DoorSensor');
			break;
		case AlertTypeEnum.PirSensor:
			label = t('enum.alertType.PirSensor');
			break;
		case AlertTypeEnum.TemperatureSensor:
			label = t('enum.alertType.TemperatureSensor');
			break;
		case AlertTypeEnum.DeviceIo:
			label = t('enum.alertType.DeviceIo');
			break;
		case AlertTypeEnum.Dtmf:
			label = t('enum.alertType.Dtmf');
			break;
		case AlertTypeEnum.FireBoxError:
			label = t('enum.alertType.FireBoxError');
			break;
		case AlertTypeEnum.LostMainPower:
			label = t('enum.alertType.LostMainPower');
			break;
		case AlertTypeEnum.LostSubPower:
			label = t('enum.alertType.LostSubPower');
			break;
		case AlertTypeEnum.FireRaySensor:
			label = t('enum.alertType.FireRaySensor');
			break;
		case AlertTypeEnum.LightBellDevice:
			label = t('enum.alertType.LightBellDevice');
			break;
		case AlertTypeEnum.SmokeAndTempSensor:
			label = t('enum.alertType.SmokeAndTempSensor');
			break;
		case AlertTypeEnum.UserAlert:
			label = t('enum.alertType.UserAlert');
			break;
	}

	return {
		label: label,
		value: value,
	};
};

const _getAll = (): alertType[] => {
	return Object.values(AlertTypeEnum).filter(r => !isNaN(parseFloat(r + ''))).map(r => _fromEnum(r as AlertTypeEnum));
};


const AlertTypeEnumVal = {
	AlertTypeEnum: AlertTypeEnum,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default AlertTypeEnumVal;