import { Button } from 'antd';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React from 'react';
import { useDialog } from 'react-st-modal';

interface DeleteDialogProps {
	name?: string;
	id: string;
	callback: () => void;
	deleteService: (id: string) => Promise<void>;
}

const DeleteDialog = (props: DeleteDialogProps) => {
	const dialog = useDialog();
	const handleDeleteItem = async () => {
		try {
			await props.deleteService(props.id);
			showNotification(
				'success',
				t('notification.success'),
				t('notification.success_delete_desc', { item: props.name }),
			);
		} catch (error) {
			showNotification('error', t('notification.error'), t('notification.error_delete_desc', { item: props.name }));
		}
		dialog.close();
		props.callback();
	};
	return (
		<div className="dialog-delete-container">
			<div className="dialog-delete-image">
				<img alt={'CONFIRM_ICON'} src={'icon/danger.svg'} height={80} />
			</div>
			<h4 className="mt25 heading--6">Xác nhận xóa?</h4>
			<p className="mt10">Bạn chắc chắn muốn xóa "{props.name}"</p>
			<div style={{ display: 'flex', width: '310px', justifyContent: 'space-between' }}>
				<Button
					style={{ width: 150 }}
					type="default"
					onClick={() => {
						// Сlose the dialog and return the value
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button
					type={'text'}
					style={{ background: '#D02127', color: '#FFFFFF', width: 150 }}
					onClick={handleDeleteItem}>
					Xóa
				</Button>
			</div>
		</div>
	);
};

export default DeleteDialog;
