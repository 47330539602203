import { ConfigProvider } from 'antd';
import viVN from 'antd/es/locale/vi_VN';
import ChiTietDonViPCCC from 'components/page/ChiTietDonViPCCC/ChiTietDonViPCCC';
import ProtectedRoute from 'components/shared/Route/ProtectedRoute';
import {
	AccountGiamSatTrucTuyenArray,
	AccountUserArray,
	BaoCaoArray,
	CongTacChuaChayArray,
	CongTacPhongChayArray,
	DashBoardArray,
	DonViPhongChayArray,
	FactArray,
	PermissionEnum,
	ThietBiArray,
} from 'enum/permissionEnum';
import GetValueFromToken from 'hooks/userInfoHook';
import React, { FunctionComponent, lazy, memo, Suspense, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import PermissionDispatcher from 'redux/Permission/permission.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import UsersService from 'services/api/authenService/usersService';
import { default as TokenService, default as tokenService } from 'services/tokenService';
import NotFound from '../page/NotFound';
import AuthorizedRoute from '../shared/Route/AuthorizedRoute';
import TroGiup from 'components/page/TroGiup/TroGiup';

interface PermissionProps {
	permissions: PermissionEnum[];
}

const mapStateToProps = (): PermissionProps => ({
	permissions: PermissionSelector.getPermissionList(),
});

const ChiTietCoSoCongTrinh = lazy(() => import('components/page/ChiTietCoSoCongTrinh/ChiTietCoSoCongTrinh'));
const ChiTietDiemChay = lazy(() => import('components/page/ChiTietDiemChay/ChiTietDiemChay'));
const ChiTietDiemLayNuoc = lazy(() => import('components/page/ChiTietDiemLayNuoc/ChiTietDiemLayNuoc'));
const ChiTietDonViHoTro = lazy(() => import('components/page/ChiTietDonViHoTro/ChiTietDonViHoTro'));
const ChiTietDonViPhongChay = lazy(() => import('components/page/ChiTietDonviPhongChay/ChiTietDonViPhongChay'));
const ChiTietKeHoachKiemTra = lazy(() => import('components/page/ChiTietKeHoachKiemTra/ChiTietKeHoachKiemTra'));
const ChiTietPhuongTien = lazy(() => import('components/page/ChiTietPhuongTien/ChiTietPhuongTien'));
const ChiTietXuLyViPham = lazy(() => import('components/page/ChiTietXuLyViPham/ChiTietXuLyViPham'));
const Login = lazy(() => import('components/page/Login/Login'));
const QuenMatKhau = lazy(() => import('components/page/Login/QuenMatKhau'));
const ResetMatKhau = lazy(() => import('components/page/Login/ResetMatKhau'));
const QuanLyBaoCao = lazy(() => import('components/page/QuanLyBaoCao/QuanLyBaoCao'));
const QuanLyChatLuongThietBi = lazy(() => import('components/page/QuanLyChatLuongThietBi/QuanLyChatLuongThietBi'));
const ChiTietSoCuKiemTra = lazy(() => import('components/page/ChiTietSoCuKiemTra/ChiTietSoCuKiemTra'));
const QuanLyCongTacPhongChay = lazy(() => import('components/page/QuanLyCongTacPhongChay/QuanLyCongTacPhongChay'));
const QuanLyDonViPhongChay = lazy(() => import('components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay'));
const NguoiDung = lazy(() => import('components/page/QuanLyNguoiDung/NguoiDung'));
const CuuHoCuuNan = lazy(() => import('components/page/ThemMoiCuuHoCuuNan/CuuHoCuuNan'));
const ThemMoiKeHoachKiemTra = lazy(() => import('components/page/ThemMoiKeHoachKiemTra/ThemMoiKeHoachKiemTra'));
const ThietBiQuay = lazy(() => import('components/page/ThemMoiThietbiQuay/ThietBiQuay'));
const CongTacPhongChayVaCHCN = lazy(() => import('../page/CongTacPhongChayVaCHCN/CongTacPhongChayVaChcn'));
const GiamSatTrucTuyen = lazy(() => import('../page/GiamSatTrucTuyen/GiamSatTrucTuyen'));
const Dashboard = lazy(() => import('components/page/Dashboard/Dashboard'));
const DeviceManagement = lazy(() => import('components/page/QuanLyThietBi/DeviceManagement'));
const EditCenterDevice = lazy(
	() => import('components/page/QuanLyThietBi/DeviceCenter/EditCenterDevice/EditCenterDevice'),
);
const ChiTietXDPAChuaChay = lazy(() => import('components/page/ChiTietXDPAChuaChay/ChiTietXDPAChuaChay'));

const Router: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	useEffect(() => {
		UsersService.getUserByToken()
			.then(data => {
				if (JSON.stringify(data.Permissions) !== JSON.stringify(GetValueFromToken.useGetPermission)) {
					window.location.pathname = '';
					window.location.search = '';
					tokenService.setUserInfo(data.Locations ? data.Locations : [], data.Permissions ? data.Permissions : []);
				} else {
					PermissionDispatcher.setPermission(data.Permissions ? data.Permissions : []);
					PermissionDispatcher.setUserInfo(data);
					if (!data) {
						TokenService.removeLoginInfo();
					}
				}
			})
			.catch(_ => {
				TokenService.removeLoginInfo();
			});
	}, []);

	return (
		<ConfigProvider locale={viVN}>
			<Routes>
				<Route path={'/'} element={<AuthorizedRoute isReqAuthenticated={false} />}>
					<Route index element={<Navigate to={'/login'} replace />} />
					<Route
						path="login"
						element={
							<Suspense fallback={'Đang tải...'}>
								<Login />
							</Suspense>
						}
					/>
					<Route
						path="quenMatKhau"
						element={
							<Suspense fallback={'Đang tải...'}>
								<QuenMatKhau />
							</Suspense>
						}
					/>
					<Route
						path="resetPassWord"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ResetMatKhau />
							</Suspense>
						}
					/>
				</Route>
				<Route
					path={'/'}
					element={
						<Suspense fallback={'Đang tải...'}>
							<AuthorizedRoute isReqAuthenticated={true} />
						</Suspense>
					}>
					<Route index element={<Navigate to={'/dashboard'} replace />} />

					<Route
						path="dashboard"
						element={
							<ProtectedRoute orPermissions={DashBoardArray} permissions={props.permissions} pathName={'dashboard'}>
								<Suspense fallback={'Đang tải...'}>
									<Dashboard />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="giamSatTrucTuyen"
						element={
							<ProtectedRoute
								orPermissions={AccountGiamSatTrucTuyenArray}
								permissions={props.permissions}
								pathName={'giamSatTrucTuyen'}>
								<Suspense fallback={'Đang tải...'}>
									<GiamSatTrucTuyen />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					{/*<Route path="/createChecklist" element={CreateCL} />*/}
					<Route
						path="quanLyDonViPhongChay"
						element={
							<ProtectedRoute
								orPermissions={DonViPhongChayArray}
								permissions={props.permissions}
								pathName={'quanLyDonViPhongChay'}>
								<Suspense fallback={'Đang tải...'}>
									<QuanLyDonViPhongChay />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="quanLyBaoCao"
						element={
							<ProtectedRoute orPermissions={BaoCaoArray} permissions={props.permissions} pathName={'quanLyBaoCao'}>
								<Suspense fallback={'Đang tải...'}>
									<QuanLyBaoCao />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="chiTietDonViPCCC"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietDonViPCCC />
							</Suspense>
						}
					/>

					<Route
						path="chiTietDonViPhongChay"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ProtectedRoute
									orPermissions={DonViPhongChayArray}
									permissions={props.permissions}
									pathName={'chiTietDonViPhongChay'}>
									<ChiTietDonViPhongChay />
								</ProtectedRoute>
							</Suspense>
						}
					/>
					<Route
						path="chiTietDonViHoTro"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ProtectedRoute
									orPermissions={DonViPhongChayArray}
									permissions={props.permissions}
									pathName={'chiTietDonViHoTro'}>
									<ChiTietDonViHoTro />
								</ProtectedRoute>
							</Suspense>
						}
					/>
					<Route
						path="chiTietPhuongTien"
						element={
							<ProtectedRoute
								orPermissions={DonViPhongChayArray}
								permissions={props.permissions}
								pathName={'chiTietPhuongTien'}>
								<Suspense fallback={'Đang tải...'}>
									<ChiTietPhuongTien />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="chiTietDiemLayNuoc"
						element={
							<ProtectedRoute
								orPermissions={DonViPhongChayArray}
								permissions={props.permissions}
								pathName={'chiTietDiemLayNuoc'}>
								<Suspense fallback={'Đang tải...'}>
									<ChiTietDiemLayNuoc />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="quanLyCongTacPhongChay"
						element={
							<ProtectedRoute
								orPermissions={CongTacPhongChayArray}
								permissions={props.permissions}
								pathName={'quanLyCongTacPhongChay'}>
								<Suspense fallback={'Đang tải...'}>
									<QuanLyCongTacPhongChay />
								</Suspense>
							</ProtectedRoute>
						}
					/>
					<Route
						path="chiTietCoSoCongTrinh"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietCoSoCongTrinh />
							</Suspense>
						}
					/>
					<Route
						path="thietBiQuay"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ThietBiQuay />
							</Suspense>
						}
					/>
					<Route
						path="chiTietSoCuKiemTra"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietSoCuKiemTra />
							</Suspense>
						}
					/>
					<Route
						path="themMoiKeHoachKiemTra"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ThemMoiKeHoachKiemTra />
							</Suspense>
						}
					/>
					<Route
						path="chiTietKeHoachKiemTra"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietKeHoachKiemTra />
							</Suspense>
						}
					/>
					<Route
						path="congTacChuaChayVaCNCH"
						element={
							<ProtectedRoute
								orPermissions={CongTacChuaChayArray}
								permissions={props.permissions}
								pathName={'congTacChuaChayVaCNCH'}>
								<Suspense fallback={'Đang tải...'}>
									<CongTacPhongChayVaCHCN />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="chiTietXDPAChuaChay"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietXDPAChuaChay />
							</Suspense>
						}
					/>

					<Route
						path="quanLyThietBi"
						element={
							<ProtectedRoute orPermissions={ThietBiArray} permissions={props.permissions} pathName={'quanLyThietBi'}>
								<Suspense fallback={'Đang tải...'}>
									<DeviceManagement />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="chiTietThietBiTrungTam"
						element={
							<Suspense fallback={'Đang tải...'}>
								<EditCenterDevice />
							</Suspense>
						}
					/>

					<Route
						path="cuuHoCuuNan"
						element={
							<Suspense fallback={'Đang tải...'}>
								<CuuHoCuuNan />
							</Suspense>
						}
					/>
					<Route
						path="chiTietDiemChay"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietDiemChay />
							</Suspense>
						}
					/>

					<Route
						path="nguoiDung"
						element={
							<ProtectedRoute orPermissions={AccountUserArray} permissions={props.permissions} pathName={'nguoiDung'}>
								<Suspense fallback={'Đang tải...'}>
									<NguoiDung />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="chiTietXulyViPham"
						element={
							<Suspense fallback={'Đang tải...'}>
								<ChiTietXuLyViPham />
							</Suspense>
						}
					/>

					<Route
						path="quanLyChatLuongThietBi"
						element={
							<ProtectedRoute
								orPermissions={FactArray}
								permissions={props.permissions}
								pathName={'quanLyChatLuongThietBi'}>
								<Suspense fallback={'Đang tải...'}>
									<QuanLyChatLuongThietBi />
								</Suspense>
							</ProtectedRoute>
						}
					/>

					<Route
						path="troGiup"
						element={
							<TroGiup />
						}
					/>
				</Route>

				<Route path="/404" element={<NotFound />} />
				<Route path="*" element={<Navigate to={'/404'} replace />} />
			</Routes>
		</ConfigProvider>
	);
};
export default connect(mapStateToProps)(memo(Router));
