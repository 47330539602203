import { Column, ColumnConfig } from '@ant-design/charts';
import { RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Select, Table } from 'antd';
import type { RangePickerProps } from 'antd/es/date-picker';
import FormItem from 'antd/lib/form/FormItem';
import { ColumnsType } from 'antd/lib/table/interface';
import TitlePage from 'components/shared/TitlePage/TitlePage';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomDialog } from 'react-st-modal';
import reportTestService from 'services/api/factService/reportTestService';
import utils from 'services/api/utils';
import { systemProperties } from 'systemProperties';
import CommonControl from '../CommonControl';
import BaoCaoKetQuaTestDialog from './BaoCaoKetQuaTestDialog';
import { PermissionProps } from './QuanLyChatLuongThietBi';

const defaultChartConfig: ColumnConfig = {
	data: [],
	xField: 'key',
	yField: 'value',
	label: {
		position: 'middle',
		style: {
			fill: '#fff',
			opacity: 0.6,
		},
	},
	xAxis: {
		label: {
			autoHide: true,
			autoRotate: false,
		},
	},
	meta: {
		key: { alias: 'Cơ sở công trình' },
		value: { alias: 'Số lượng lỗi' },
	},
};

const columnsCoSoCongTrinh: ColumnsType<any> = [
	{
		align: 'center' as const,
		width: 45,
		className: 'min-col',
		title: 'STT',
		dataIndex: 'key',
		key: 'key',
	},
	{
		title: 'IMEI',
		align: 'center' as const,
		dataIndex: 'GsmIMEI',
		key: 'GsmIMEI',
	},
	{
		title: 'Thời gian',
		align: 'center' as const,
		dataIndex: 'DateTest',
		key: 'DateTest',
	},
];

interface ISearchData {
	FromDate?: Date;
	ToDate?: Date;
	DeviceTypeCode?: string;
	ErrorCode?: string;
}

const BaoCaoChiTietLoiGSM: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const [chartConfig, setChartConfig] = useState<ColumnConfig>(defaultChartConfig);
	const [TongSoCoSoCongTrinh] = useState<number>(0);

	useEffect(() => {
		document.title = t('quanLyBaoCao.baoCaoThietBiLapDat');
		getErrorDetail();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const [dataSourceErrorDetail, setDataSourceErrorDetail] = useState<any[]>([]);
	const errType = utils.GetDSLoaiError();
	const loaiThietBi = utils.GetDSLoaiThietBiError();

	// Method get Số lượng thiết bị theo cơ sở công trình
	function getErrorDetail(page = 1, pageSize = 10, _index = 0) {
		const param: ISearchData = {
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			DeviceTypeCode: form.getFieldValue('DeviceTypeCode'),
			ErrorCode: form.getFieldValue('ErrorCode'),
		};
		reportTestService
			.searchDetail(param)
			.then((res: any) => {
				// Set chart data
				let resultList = res ? res.ChartErrorDetail : [];
				let chartDataList: any[] = [];
				if (resultList.length > 0) {
					resultList.forEach((data: any) => {
						chartDataList.push({
							key: data.Key,
							value: data.Value,
						});
					});
				}

				let _chartConfig: any = cloneDeep(defaultChartConfig);
				_chartConfig.data = chartDataList;
				setChartConfig(_chartConfig);

				const dataTable = res.DeviceInfoDtos ? res.DeviceInfoDtos : [];
				let promises: PromiseLike<any>[] = [];
				let array: any[] = [];
				if (dataTable.length > 0) {
					dataTable.forEach((item: any) => {
						if (item.DateTest) {
							item.DateTest = moment(item.DateTest).format('DD/MM/YYYY: HH:mm:ss');
						}
						item.key = ++_index;
						array.push(item);
					});
				}
				Promise.all(promises).then(() => setDataSourceErrorDetail(array));
			})
			.catch((errors: any) => {
				if (!errors) {
					return;
				}
			});
	}

	function onChangePage(page: number, pageSize: number) {
		const index = Number((page - 1) * pageSize);
		getErrorDetail(page, pageSize, index);
	}

	const showTotal = (total: number) => {
		return (
			<span>
				{t('label.tong')} <strong>{total}</strong> {t('label.thietbi')}
			</span>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(<BaoCaoKetQuaTestDialog gsmIMEI={record.GsmIMEI} />, {
					className: 'dialog-add',
					title: `BÁO CÁO KẾT QUẢ TEST GsmIMEI: ${record.GsmIMEI}`,
					showCloseIcon: true,
					isCanClose: true,
				});
			},
		};
	};

	const handleChangeDatePicker = () => {
		getErrorDetail();
		// if (
		// 	form.getFieldValue('dateRange') &&
		// 	form.getFieldValue('dateRange')[0].add(7, 'days') > form.getFieldValue('dateRange')[1]
		// ) {
		// 	getErrorDetail();
		// } else {
		// 	message.error('Vui lòng chọn lại giới hạn ngày(trong khoảng 7 ngày)');
		// }
	};

	const disabledDate: RangePickerProps['disabledDate'] = (current): boolean => {
		return current > moment();
	};

	return (
		<Form id="baoCaoChiTiet" layout="vertical" name="normal_login" form={form}>
			<TitlePage name={t('baoCaoChiTiet.title')} />
			<div className="tab__container flex--row">
				<Form.Item name="dateRange">
					<DatePicker.RangePicker
						locale={CommonControl.localeVi}
						format={systemProperties.dateFormat}
						placeholder={[t('label.tuNgay'), t('label.denNgay')]}
						separator={<RightOutlined style={{ color: '#00000040', fontSize: '12px' }} />}
						onChange={handleChangeDatePicker}
						// disabledDate={disabledDate}
					/>
				</Form.Item>

				<FormItem name="DeviceTypeCode" className="ml10 withMinSelect">
					<Select
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.chonLoaiThietBi')}
						filterSort={(optionA, optionB) =>
							(optionA!.children as unknown as string)
								.toLowerCase()
								.localeCompare((optionB!.children as unknown as string).toLowerCase())
						}
						allowClear>
						{loaiThietBi}
					</Select>
				</FormItem>

				<FormItem name="ErrorCode" className="ml10 withMinSelect">
					<Select
						showSearch
						filterSort={(optionA, optionB) =>
							(optionA!.children as unknown as string)
								.toLowerCase()
								.localeCompare((optionB!.children as unknown as string).toLowerCase())
						}
						optionFilterProp="children"
						placeholder={t('placeholder.chonLoaiLoi')}
						allowClear>
						{errType}
					</Select>
				</FormItem>

				<Button className="ml10" type="primary" onClick={() => getErrorDetail()}>
					{t('label.timKiem')}
				</Button>
			</div>
			<div className="ml40 mb40 mt40 mr40">
				<Column {...chartConfig} />
			</div>
			<div className={'break-line mt10'} />
			<Table
				locale={{ emptyText: t('label.emptyData') }}
				showSorterTooltip={false}
				className="ml10 mb10 mt5 mr10"
				dataSource={dataSourceErrorDetail}
				columns={columnsCoSoCongTrinh}
				onRow={handleShowViewDetail}
				bordered
				pagination={{
					defaultPageSize: 10,
					showSizeChanger: true,
					onChange: onChangePage,
					total: TongSoCoSoCongTrinh,
					pageSizeOptions: ['5', '10', '20'],
					showTotal: showTotal,
				}}
			/>
		</Form>
	);
};

export default withTranslation()(BaoCaoChiTietLoiGSM);
