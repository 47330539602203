import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Select, Table } from 'antd';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import { TableOption } from 'constants/tableOption.const';
import loaiKhachHangEnum from 'enum/loaiKhachHangEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import generateUniqueId from 'helpers/generateUniqueId';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog, useDialog } from 'react-st-modal';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import CustomerService from 'services/api/inventoryService/customerService';
import utils from 'services/api/utils';
import Generator from 'services/generator';
import ChiTietKhachHangDialog from './Dialog/ChiTietKhachHangDialog';
import EditKhachHang from './EditKhachHang';
import { PermissionProps } from './NguoiDung';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DialogLockAndUnlock = (props: any) => {
	const dialog = useDialog();

	const lockItemCustomer = async () => {
		if (props.mode === 'lock') {
			CustomerService.deactiveCustomer(props.Id)
				.then(_ => {
					dialog.close();
					showNotification('success', t('notification.success'), 'Khóa thành công!');
					props.callback && props.callback();
				})
				.catch(error => {
					if (error.response) {
						showNotification('error', t('notification.error'), error.response.data);
					}
				});
		} else {
			CustomerService.activeCustomer(props.Id)
				.then(_ => {
					dialog.close();
					showNotification('success', t('notification.success'), 'Mở khóa thành công!');
					props.callback && props.callback();
				})
				.catch(error => {
					if (error.response) {
						showNotification('error', t('notification.error'), error.response.data);
					}
				});
		}
	};

	return (
		<div className="dialog-delete-container">
			<div className="dialog-delete-image">
				<img alt={'CONFIRM_ICON'} src={props.mode === 'lock' ? 'icon/Lock.png' : 'icon/Union.png'} height={80} />
			</div>
			<h4 className="mt10 heading--6">
				{props.mode === 'lock' ? 'Xác nhận tạm khóa khách hàng?' : 'Xác nhận mở khóa khách hàng?'}
			</h4>
			<p className="mt10">
				{props.mode === 'lock'
					? `Khóa khách hàng này sẽ khóa tất cả các khách hàng và người dùng trực thuộc. Bạn chắc chắn muốn tạm
				khóa khách hàng: "${props.Name}"`
					: `Mở khóa khách hàng này sẽ mở khóa tất cả các khách hàng và người dùng trực thuộc. Bạn chắc chắn muốn mở khóa khách hàng "${props.Name}"`}
			</p>
			<div>
				<Button
					className="mr10"
					style={{ width: 150 }}
					type="default"
					onClick={() => {
						// Сlose the dialog and return the value
						dialog.close();
					}}>
					Hủy
				</Button>
				<Button
					type={'primary'}
					style={{
						background: props.mode === 'lock' ? '#D02127 ' : '#1BB36B',
						color: '#FFFFFF',
						width: 150,
						border: 'none',
					}}
					danger
					onClick={lockItemCustomer}>
					{props.mode === 'lock' ? 'Khóa' : 'Mở khóa'}
				</Button>
			</div>
		</div>
	);
};

const QuanLyKhachHang: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [TongSoCanBoChienSy, setTongSoCanBoChienSy] = useState<number>(0);
	const [customer, setCustomer] = useState<any[]>();

	const child = searchParams.get('child');

	const columns: any = [
		{
			dataIndex: 'Name',
			title: 'Tên khách hàng',
		},
		{
			width: 100,
			dataIndex: 'TaxCode',
			align: 'right' as const,
			title: 'Mã số thuế',
		},
		{
			dataIndex: 'PhoneNumber',
			align: 'right' as const,
			title: 'Số điện thoại',
			width: 130,
		},
		{
			dataIndex: 'Address',
			title: 'Địa chỉ',
		},
		{
			dataIndex: 'CreatedDate',
			title: 'Ngày tạo',
			width: 100,
		},
		{
			dataIndex: 'IsActive',
			align: 'center' as const,
			title: 'Trạng thái',
			width: 100,
			render: (IsActive: string) => {
				return (
					<div style={{ color: IsActive === 'ACTIVE' ? '#1BB36B' : '#FF2000' }}>
						{IsActive === 'ACTIVE' ? 'Hoạt động' : 'Tạm khóa'}
					</div>
				);
			},
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			key: 'action',
			align: 'center' as const,
			width: 100,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.CUSTOMER_EDIT)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={async (e: any) => {
							e.stopPropagation();
							await CustomDialog(
								<ChiTietKhachHangDialog
									userInfo={props.userInfo}
									ParentId={record.Id}
									callback={GetDanhSachKhachHang}
									CustomerType={record.CustomerType}
								/>,
								{
									className: 'dialog-add',
									title: 'Thêm mới khách hàng',
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<img src="icon/Plus.png" alt="edit" />
					</Button>
					<Button
						type="text"
						onClick={async (e: any) => {
							e.stopPropagation();
							navigate({ pathname: `/nguoiDung?tab=quanLyKhachHang&child=EditKhachHang&Id=${record.Id}` });
						}}>
						<img src="icon/Pen.png" alt="edit" />
					</Button>
					<Button
						type="text"
						onClick={async (e: any) => {
							e.stopPropagation();
							await CustomDialog(
								<DialogLockAndUnlock
									Id={record.Id}
									Name={record.Name}
									mode={record.IsActive === 'ACTIVE' ? 'lock' : 'unlock'}
									callback={GetDanhSachKhachHang}
								/>,
								{
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<img src={record.IsActive === 'ACTIVE' ? 'icon/password.png' : 'icon/Unlock.png'} alt="edit" />
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	useEffect(() => {
		document.title = t('nav.quanLyKhachHang');
		NavBarDispatcher.setTitle(`${t('quanLyNguoiDung.title')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.CUSTOMER_VIEW])) {
				GetDanhSachKhachHang();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, child]);

	function GetDanhSachKhachHang(page = currentPage, pageSize = pageSizeDefault) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};

		const param: any = {
			name: form.getFieldValue('Name'),
			IsActive: form.getFieldValue('IsActive'),
			CustomerType: form.getFieldValue('CustomerType'),
			IsParent: true,
		};
		if (form.getFieldValue('Name') || form.getFieldValue('IsActive') || form.getFieldValue('CustomerType')) {
			param.IsParent = false;
		}
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		CustomerService.searchCustomer(param, pageOption)
			.then((res: any) => {
				setTongSoCanBoChienSy(res.TotalItems);
				optiondanhsachKhachHang(res.Data);
			})
			.catch();
	}

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			GetDanhSachKhachHang();
		}
	};

	const optiondanhsachKhachHang = (data: any) => {
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any) => {
				item.CreatedDate = moment(item.CreatedDate).format('DD/MM/YYYY');
				if (item.Children.length > 0) {
					item.children = convertData(item.Children);
					item.key = generateUniqueId();
				} else {
					item.children = null;
				}
				array.push(item);
			});
		}
		setCustomer(array);
	};

	const convertData = (Children: any) => {
		if (Children.length > 0) {
			Children.forEach((element: any) => {
				element.CreatedDate = moment(element.CreatedDate).format('DD/MM/YYYY');
				if (element.Children?.length > 0) {
					element.key = generateUniqueId();
					element.children = convertData(element.Children);
				} else {
					element.children = null;
				}
			});
		} else {
			Children = null;
		}

		return Children;
	};

	function onChangePage(page: number, pageSize: number) {
		GetDanhSachKhachHang(page, pageSize);
	}

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> người dùng
			</span>
		);
	};

	const handleChangeLoaiKhachHang = () => {
		GetDanhSachKhachHang(CURRENT_PAGE);
	};

	const handleChangeIsActive = () => {
		GetDanhSachKhachHang(CURRENT_PAGE);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoCanBoChienSy}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const formAction = (
		<>
			<div className={'flex--row'}>
				<Form.Item name="CustomerType">
					<Select
						className="withMinSelect"
						showSearch
						optionFilterProp="Children"
						placeholder="Chọn phân loại khách hàng"
						onChange={handleChangeLoaiKhachHang}
						allowClear>
						{Generator.generateOptions(loaiKhachHangEnum.getAll())}
					</Select>
				</Form.Item>

				<Form.Item name="IsActive">
					<Select
						className="withMinSelect ml10"
						showSearch
						optionFilterProp="Children"
						placeholder="Chọn trạng thái"
						onChange={handleChangeIsActive}
						allowClear>
						{utils.TrangThaiCustommer()}
					</Select>
				</Form.Item>

				<Form.Item name="Name" className="ml10 withMinSelect">
					<Input placeholder="Nhập tên khách hàng" prefix={<SearchOutlined />} onKeyPress={handleKeyDown} allowClear />
				</Form.Item>
			</div>
			<div>
				{props.permissionList.includes(PermissionEnum.CUSTOMER_EDIT) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type={'primary'}
						onClick={async () => {
							await CustomDialog(<ChiTietKhachHangDialog userInfo={props.userInfo} callback={GetDanhSachKhachHang} />, {
								className: 'dialog-add',
								title: 'Thêm mới khách hàng',
								showCloseIcon: true,
								isCanClose: true,
							});
						}}>
						<PlusOutlined />
						{t('label.create')}
					</Button>
				) : null}
			</div>
		</>
	);

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				navigate({ pathname: `/nguoiDung?tab=quanLyKhachHang&child=EditKhachHang&Id=${record.Id}&mode=view` });
			},
		};
	};

	const formTable = (
		<Table
			locale={{ emptyText: t('label.emptyData') }}
			showSorterTooltip={false}
			dataSource={customer}
			columns={columns}
			onRow={handleShowViewDetail}
			bordered
			pagination={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
		/>
	);

	const PhanQuyen = () => {
		return <PageWrapper titlePage="Quản lý khách hàng" formAction={formAction} formTable={formTable} form={form} />;
	};

	const renderSelectedTab = (): JSX.Element => {
		switch (child) {
			case undefined:
			default:
				return PhanQuyen();
			case 'EditKhachHang':
				return <EditKhachHang callback={GetDanhSachKhachHang} userInfo={props.userInfo} />;
		}
	};

	return renderSelectedTab();
};

export default withTranslation()(QuanLyKhachHang);
