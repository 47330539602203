import { EyeOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Select } from 'antd';
import ExcelUtils from 'components/lib/excel/ExcelUtils';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import processStatusEnum, { ProcessStatusEnum } from 'enum/processStatusEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { PageOption } from 'services/api';
import provinceService from 'services/api/provinceService';
import FireProtectionService from 'services/api/reportService/fireProtectionService';
import Generator from 'services/generator';
import { PermissionProps } from '../CongTacPhongChayVaChcn';
import DialogCamera from './DialogCamera';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const CongTacChuaChay: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const navigate = useNavigate();

	const [searchParams] = useSearchParams();
	const Id = searchParams.get('Id');
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNaviagte = navigateFromDashboardSelector.getNavigateStatus();

	// states of component
	const [TongSoDiemChay, setTongSoDiemChay] = useState<number>(0);
	const [dataSourceDSdiemchay, setdataSourceDSdiemchay] = useState<any[]>([]);
	const [dataSourceExport, setDataSourceExport] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);

	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);
	
	const formatDate = (date: string, format = 'HH:mm:ss - DD/MM/YYYY') => {
		if (!date) return;
		return moment(date).format(format);
	  };

	const columnsDsDiemChay = [
		{
			className: 'min-col',
			align: 'center' as const,
			width: 45,
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Thời điểm cháy',
			dataIndex: 'FireAlarmTime',
			key: 'FireAlarmTime',
			render: (date: string) => (date ? formatDate(date, 'HH:mm:ss - DD/MM/YYYY') : null),
		},
		{
			title: 'ID vụ cháy',
			dataIndex: 'Id',
			key: 'Id',
		},
		{
			title: 'Tên điểm cháy',
			dataIndex: 'tendiemchay',
			key: 'tendiemchay',
		},
		{
			title: 'Cơ sở công trình',
			dataIndex: 'cosocongtrinh',
			key: 'cosocongtrinh',
			render: (cosocongtrinh: string, record: any) => {
				return (
					<NavigateButton url={`/chiTietCoSoCongTrinh?id=${record.Construction?.Id}&mode=view`} name={cosocongtrinh} />
				);
			},
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'diachi',
			key: 'diachi',
		},
		{
			title: 'Số điện thoại người báo',
			dataIndex: 'sodienthoai',
			key: 'sodienthoai',
		},
		{
			title: 'Trạng thái',
			dataIndex: 'trangthai',
			key: 'trangthai',
		},
		{
			align: 'left' as const,
			className: 'min-col',
			title: 'Thao tác',
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.FIRE_PROTECTION_EDIT)
			),
			render: (_: any, record: any) => (
				<>
					{(record.trangthai === 'Đang cháy' || record.trangthai === 'Đang xử lý') &&
						(record.tendiemchay === 'Tân Mai Stadium - Sân Vận Động Tân Mai' ||
							record.tendiemchay === 'Công Ty Cổ Phần Basato') && (
							<Button
								type={'text'}
								title="Xem camera"
								onClick={async e => {
									e.stopPropagation();
									await CustomDialog(<DialogCamera />, {
										title: 'Thông tin camera',
										showCloseIcon: true,
										isCanClose: true,
									});
								}}>
								<EyeOutlined />
							</Button>
						)}
					<Button type="text" onClick={e => e.stopPropagation()}>
						<Link
							to={{
								pathname: '/chiTietDiemChay',
								search: `?id=${record.Id}&mode=edit`,
							}}>
							<img src="icon/Pen.png" alt="edit" />
						</Link>
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	function SearchDanhSachDiemChay(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		let param: any = {
			Name: form.getFieldValue('Name'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			ProcessStatus: form.getFieldValue('ProcessStatus'),
			ConstructionIds: form.getFieldValue('ConstructionIds'),
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Location = {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			};
		}
		FireProtectionService.searchCommandCenter(param, pageOption).then((res: any) => {
			setTongSoDiemChay(res.TotalItems);
			optiondanhsachDiemChay(res ? res.Data : []);
		});
	}

	function onChangePage(page: number, pageSize: number) {
		SearchDanhSachDiemChay(page, pageSize);
	}

	const optiondanhsachDiemChay = (data: any, isExport?: boolean) => {
		let array: any[] = [];
		let promises: PromiseLike<any>[] = [];
		if (data.length > 0) {
			data.forEach((item: any) => {
				promises.push(
					provinceService
						.getProvince(item.Construction?.Location.TinhThanh)
						.then((result: any) => {
							item.tinhthanh = result.Name;
						})
						.catch(_ => {
							item.tinhthanh = null;
						}),
				);
				item.tendiemchay = item.Name;
				item.cosocongtrinh = item.Construction?.TenCoSoCongTrinh;
				item.sodienthoai = item.PhoneNumber;
				item.diachi = item.Construction?.LocationDetail ? item.Construction?.LocationDetail : item.LocationDetail;
				// if (item.FireAlarmTime) {
				// 	item.thoigianbaochay = moment(item.FireAlarmTime).format('HH:MM:ss DD/MM/YYYY');
				// }
				// if (item.StartTime) {
				// 	item.thoigianbatdauxuly = moment(item.StartTime)?.format('DD/MM/YYYY');
				// }
				// if (item.EndTime) {
				// 	item.thoigianketthucxuly = moment(item.EndTime)?.format('DD/MM/YYYY');
				// }

				item.trangthai = processStatusEnum.fromEnum(item.ProcessStatus).label;
				array.push(item);
			});
		}
		if (!isExport) {
			Promise.all(promises).then(() => setdataSourceDSdiemchay(array));
		} else {
			Promise.all(promises).then(() => {
				setDataSourceExport(array);
				setTimeout(() => {
					// TODO:: PhuongNM
					setDataSourceExport([]);
				}, 1000);
			});
		}
	};

	const GetDataExcel = () => {
		let param: any = {
			Name: form.getFieldValue('Name'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			PcccUnitId: form.getFieldValue('PcccUnitId'),
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Location = {
				TinhThanh: form.getFieldValue('TinhThanh'),
			};
		}
		FireProtectionService.searchCommandCenter(param).then((res: any) => {
			optiondanhsachDiemChay(res ? res : [], true);
		});
	};

	const handleSearchDsDiemChay = () => {
		SearchDanhSachDiemChay(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.setFieldsValue({ Name: null });
		}

		SearchDanhSachDiemChay(CURRENT_PAGE);
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		SearchDanhSachDiemChay(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});
		SearchDanhSachDiemChay(CURRENT_PAGE);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietDiemChay',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	useEffect(() => {
		document.title = t('congTacPhongChayVaCHCN.congTacChuaChay');
	}, []);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !isNaviagte) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FIRE_PROTECTION_VIEW])) {
				SearchDanhSachDiemChay();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, isNaviagte]);

	useEffect(() => {
		if (isNaviagte) {
			setProvinceId(provinceIdFromDashboard);
			setLocation({
				Location: {
					TinhThanh: provinceIdFromDashboard,
					QuanHuyen: districtIdFromDashboard,
				},
			});
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
			});

			if (Id) {
				const startOfMonth = moment().startOf('months').utc(true);
				const endOfMonth = moment().endOf('months');
				form.setFieldsValue({
					ProcessStatus: ProcessStatusEnum.CANH_BAO_SAI,
					ConstructionIds: [Id],
					dateRange: [startOfMonth, endOfMonth],
				});
			}

			SearchDanhSachDiemChay(CURRENT_PAGE);
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNaviagte, form, provinceIdFromDashboard, districtIdFromDashboard, Id]);

	const FormAction = (
		<>
			<div className="flex--row">
				<div className="flex--row">
					<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

					<DistrictSelect
						formItemClassName="ml10"
						selectClassName="withMinSelect"
						form={form}
						provinceId={provinceId}
						onChangeDistrict={handleChangeDistrict}
					/>

					<UnitManagerSelect
						name="PcccUnitId"
						formItemClassName="ml10"
						selectClassName="withMinSelect"
						form={form}
						location={location}
						onChangeUnitManager={() => SearchDanhSachDiemChay(CURRENT_PAGE)}
					/>
				</div>

				<Form.Item name="ProcessStatus" className="ml10">
					<Select
						className="withMinSelect"
						showSearch
						optionFilterProp="children"
						placeholder="Trạng thái cháy"
						allowClear
						onChange={handleSearchDsDiemChay}>
						{Generator.generateOptions(processStatusEnum.getAll())}
					</Select>
				</Form.Item>

				<Form.Item className="ml10" name="dateRange">
					<RangePickerInput onChangeDatePicker={handleSearchDsDiemChay} form={form} />
				</Form.Item>

				<Form.Item name="Name" className="ml10">
					<Input.Search placeholder="Tên điểm cháy" onSearch={(value: string) => handleSearchName(value)} allowClear />
				</Form.Item>
			</div>

			{/* {dataSourceDSdiemchay.length > 0 && (
				<ExcelUtils.ExportDiemChay
					element={
						<Button className="ml10" type={'primary'}>
							<DownloadOutlined /> Xuất excel
						</Button>
					}
					data={dataSourceDSdiemchay}
				/>
			)} */}

			{props.permissionList.includes(PermissionEnum.FIRE_PROTECTION_EXPORT) ||
			props.permissionList.includes(PermissionEnum.ADMIN) ? (
				<Button className="ml10" type={'default'} onClick={GetDataExcel}>
					<img className="padding-image" style={{ width: 12 }} src="icon/exportExcel.png" alt="Tải file" /> Xuất file
					Excel
				</Button>
			) : null}

			{dataSourceExport.length > 0 && <ExcelUtils.ExportDiemChay data={dataSourceExport} />}
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> điểm cháy
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoDiemChay}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const FormTable = (
		<TableComponent
			dataSource={dataSourceDSdiemchay}
			columns={columnsDsDiemChay}
			paginator={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
			onRow={handleShowViewDetail}
		/>
	);

	return <PageWrapper titlePage="Danh sách điểm cháy" form={form} formAction={FormAction} formTable={FormTable} />;
};

export default withTranslation()(CongTacChuaChay);
