import { t } from 'i18next';

export enum XeChuaChayEnum {
	XE_CHUA_CHAY_CO_XITEC = 'XE_CHUA_CHAY_CO_XITEC',
	XE_CHUA_CHAY_KHONG_CO_XITEC = 'XE_CHUA_CHAY_KHONG_CO_XITEC',
	XE_CHUA_CHAY_SAN_BAY = 'XE_CHUA_CHAY_SAN_BAY',
	XE_CHUA_CHAY_RUNG = 'XE_CHUA_CHAY_RUNG',

	XE_CHUA_CHAY_HOA_CHAT = 'XE_CHUA_CHAY_HOA_CHAT',
	XE_CHUA_CHAY_DUONG_HAM = 'XE_CHUA_CHAY_DUONG_HAM',
	XE_CHUA_CHAY_DUONG_SAT = 'XE_CHUA_CHAY_DUONG_SAT',
	XE_CHUA_CHAY_LUONG_CU = 'XE_CHUA_CHAY_LUONG_CU',
}

interface XeChuaChay {
	value: XeChuaChayEnum;
	label: string;
}

const _fromEnum = (xeChuaChayEnum: XeChuaChayEnum): XeChuaChay => {
	switch (xeChuaChayEnum) {
		case XeChuaChayEnum.XE_CHUA_CHAY_CO_XITEC:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_CO_XITEC'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_CO_XITEC,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_KHONG_CO_XITEC:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_KHONG_CO_XITEC'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_KHONG_CO_XITEC,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_SAN_BAY:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_SAN_BAY'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_SAN_BAY,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_RUNG:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_RUNG'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_RUNG,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_HOA_CHAT:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_HOA_CHAT'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_HOA_CHAT,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_DUONG_HAM:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_DUONG_HAM'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_DUONG_HAM,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_DUONG_SAT:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_DUONG_SAT'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_DUONG_SAT,
			};
		case XeChuaChayEnum.XE_CHUA_CHAY_LUONG_CU:
			return {
				label: t('enum.vehicle.XE_CHUA_CHAY_LUONG_CU'),
				value: XeChuaChayEnum.XE_CHUA_CHAY_LUONG_CU,
			};
	}
};

const _getAll = (): XeChuaChay[] => {
	return Object.values(XeChuaChayEnum).map(r => _fromEnum(r as XeChuaChayEnum));
};

const _enumFromLabel = (label: string): XeChuaChayEnum | undefined => {
	switch (label) {
		case t('enum.vehicle.XE_CHUA_CHAY_CO_XITEC'):
			return XeChuaChayEnum.XE_CHUA_CHAY_CO_XITEC;
		case t('enum.vehicle.XE_CHUA_CHAY_KHONG_CO_XITEC'):
			return XeChuaChayEnum.XE_CHUA_CHAY_KHONG_CO_XITEC;
		case t('enum.vehicle.XE_CHUA_CHAY_SAN_BAY'):
			return XeChuaChayEnum.XE_CHUA_CHAY_SAN_BAY;
		case t('enum.vehicle.XE_CHUA_CHAY_RUNG'):
			return XeChuaChayEnum.XE_CHUA_CHAY_RUNG;
		case t('enum.vehicle.XE_CHUA_CHAY_HOA_CHAT'):
			return XeChuaChayEnum.XE_CHUA_CHAY_HOA_CHAT;
		case t('enum.vehicle.XE_CHUA_CHAY_DUONG_HAM'):
			return XeChuaChayEnum.XE_CHUA_CHAY_DUONG_HAM;
		case t('enum.vehicle.XE_CHUA_CHAY_DUONG_SAT'):
			return XeChuaChayEnum.XE_CHUA_CHAY_DUONG_SAT;
		case t('enum.vehicle.XE_CHUA_CHAY_LUONG_CU'):
			return XeChuaChayEnum.XE_CHUA_CHAY_LUONG_CU;
	}
};

const xeChuaChayEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default xeChuaChayEnum;
