export namespace UploadFileOption {
	export const LIMIT_SIZE = 20971520;
	export const ALLOWED_EXTENSIONS = /(\.png|\.jpg|\.pdf)$/i;
	export const PHONE_PATTERN = /^((09|03|07|08|05)+([0-9]{8})\b)$/;
	export const EMAIL_PATTERN =
		/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/;
	export const ALLOWED_EXCEL_FILE = /\.xlsx$/i;
	export const DATE_PATTERN = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
	export const CITIZEN_IDENTIFY_PATTERN = /^[0-9]{9}$|^[0-9]{12}$/;
}
