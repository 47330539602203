import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import CustomMap, { CustomMapIn, CustomMapOut } from 'components/shared/CustomMap/CustomMap';
import { MarkerType } from 'components/shared/Map/ToaDoMarker';
import loaiDonViEnum, { LoaiDonViEnum } from 'enum/loaiDonViEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import pcccUnitService from 'services/api/inventoryService/pcccUnitService';
import supportUnitService from 'services/api/inventoryService/supportUnitService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import { LocationInfo } from '../Tabs/QuanLyDonVi/QuanLyDonVi';
import './DialogMap.scss';
import { log } from 'console';
import { blockInvalidChar } from 'components/page/CommonControl/BlockInvalidChar';

const { Option } = Select;

interface DialogThemMoiDonViHoTroProps {
	mode?: 'edit' | 'add' | 'view';
	id?: string;
	callback?: () => void;
	userInfo?: UserInfo;
	location?: LocationInfo;
	donviId?: string;
	mapInput?: CustomMapIn;
}

const DialogThemMoiDonViHoTro: FunctionComponent<DialogThemMoiDonViHoTroProps> = (
	props: DialogThemMoiDonViHoTroProps,
) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	// state of component
	const [mapInput, setMapInput] = useState<CustomMapIn>();
	const [mapOutput, setMapOutput] = useState<CustomMapOut>();
	const [optionUnits, setOptionUnits] = useState<JSX.Element[]>([]);
	const [isShowDoi, setIsShowDoi] = useState<boolean>(Boolean);

	const generateOptionUnits = (data: any[]) => {
		const elements = data.map(item => (
			<Option key={item.Id} value={item.Id}>
				{item.TenDonVi ? item.TenDonVi : item.Name}
			</Option>
		));
		setOptionUnits(elements);
	};

	const handleChangeInputMapData = (newVal: CustomMapOut) => {
		setMapOutput(newVal);
		form.resetFields(['PcccSupport']);
		setOptionUnits([]);
		const params = {
			Location: {
				TinhThanh: newVal.Location.TinhThanh,
				QuanHuyen: newVal.Location.QuanHuyen,
			},
			IncludePC07: true, // Thêm yêu cầu của QuynhNT
		};
		pcccUnitService.searchPcccUnitForSelect(params).then(generateOptionUnits);
	};

	const postUnitSupport = async () => {
		const validated = await form.validateFields();
		const id = props.id;
		if (validated) {
			const unitSupportInfo: any = {
				MaDonVi: form.getFieldValue('MaDonVi'),
				Tendonvi: form.getFieldValue('Tendonvi'),
				SoDienThoai: form.getFieldValue('SoDienThoai'),
				LoaiDonVi: form.getFieldValue('LoaiDonVi'),
				PcccUnitId: props.donviId,
				PcccSupport: form.getFieldValue('PcccSupport'),
				Forces: form.getFieldValue('Forces'),
				Manager: form.getFieldValue('Manager'),
				...mapOutput,
			};

			try {
				if (id) {
					unitSupportInfo.Id = id;
					await supportUnitService.updateSupportUnit(unitSupportInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: form.getFieldValue('Tendonvi') }),
					);
				} else {
					// add a new unit support
					await supportUnitService.createSupportUnit(unitSupportInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_create_desc', { item: form.getFieldValue('Tendonvi') }),
					);
				}

				dialog.close();
				if (props.callback) {
					props.callback();
				}
			} catch (error: any) {
				showNotification('error', t('notification.error'), error.response.data);
			}
		}
	};

	useEffect(() => {
		const id = props.id;
		const getUnitPcccById = async (param: any) => {
			const result = await pcccUnitService.searchPcccUnitForSelect(param);
			generateOptionUnits(result);
		};

		const getUnitSupportById = async (id: string) => {
			const data = await supportUnitService.getSupportUnit(id);
			setIsShowDoi(data.LoaiDonVi === LoaiDonViEnum.DOI_PCCC_VA_CNCH);

			await getUnitPcccById({ Location: { TinhThanh: data.Location?.TinhThanh, QuanHuyen: data.Location?.QuanHuyen } });
			form.setFieldsValue(data);

			setMapInput(data);
		};

		if (id) {
			getUnitSupportById(id);
		} else {
			setMapInput(props.mapInput);
		}
	}, [props.id, form]);

	const changeType = (value: any) => {
		setIsShowDoi(value === LoaiDonViEnum.DOI_PCCC_VA_CNCH);
	};

	return (
		<div className="common-dialog dialog-units-support">
			<Form layout="vertical" name="normal_login" form={form}>
				<Row gutter={32} className="mb15">
					<Col span={10}>
						<ColumnTitle columnTitle="I. Vị trí" />
						<CustomMap
							containerClass={'height-map'}
							form={form}
							data={mapInput}
							markerType={MarkerType.DON_VI_HO_TRO}
							changeDataCallback={handleChangeInputMapData}
						/>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="II. Thông tin chung" />
						<div>
							<Form.Item
								label="Mã đơn vị"
								name="MaDonVi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Mã đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập mã đơn vị" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Tên đơn vị"
								name="Tendonvi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên đơn vị" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								name="LoaiDonVi"
								label="Loại đơn vị hỗ trợ"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Loại đơn vị hỗ trợ không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn loại đơn vị hỗ trợ"
									onChange={changeType}
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(loaiDonViEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item
								label="Số điện thoại"
								name="SoDienThoai"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder={t('placeholder.phoneNumber')}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							{/* <Form.Item
								label="Đơn vị PCCC"
								name="PcccUnitId"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Đơn vị PCCC không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn đơn vị PCCC"
									allowClear
									disabled={props.mode === 'view'}>
									{optionUnits}
								</Select>
							</Form.Item> */}
						</div>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="III. Thông tin thêm" />
						{isShowDoi && (
							<Form.Item label="Đội CSPCCC và CNCH" name="PcccSupport">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn đội CSPCCC và CNCH"
									allowClear
									disabled={props.mode === 'view'}>
									{optionUnits}
								</Select>
							</Form.Item>
						)}
						<Form.Item label="Người quản lý" name="Manager">
							<Input placeholder="Nhập tên người quản lý" disabled={props.mode === 'view'} />
						</Form.Item>

						<Form.Item label="Lực lượng có thể huy động" name="Forces">
							<Input
								placeholder="Nhập lực lượng có thể huy động"
								onKeyDown={blockInvalidChar}
								disabled={props.mode === 'view'}
							/>
						</Form.Item>
					</Col>
				</Row>

				<Divider />

				<div className="dialog__buttons mt10">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						{props.mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{props.mode !== 'view' && (
						<Button type="primary" htmlType="submit" onClick={postUnitSupport}>
							{props.mode === 'add' ? 'Thêm mới' : 'Lưu'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogThemMoiDonViHoTro;
