import { Form, FormInstance, Spin } from 'antd';
import TitleComponent from 'components/shared/TitleComponent/TitleComponent';
import React from 'react';
import './PageWrapper.scss';

interface PageWrapperProps {
	formAction: JSX.Element;
	formTable: JSX.Element;
	titlePage: string;
	form: FormInstance<any>;
	loading?: boolean;
}

const PageWrapper: React.FC<PageWrapperProps> = (props: PageWrapperProps) => {
	const { formAction, formTable, titlePage, form, loading = false } = props;
	return (
		<div className="page-common-wrapper">
			<TitleComponent title={titlePage} />
			<Form layout="vertical" name="normal_login" className="form-container" form={form}>
				<Spin spinning={loading}>
					<div className="form-action">{formAction}</div>

					{formTable}
				</Spin>
			</Form>
		</div>
	);
};

export default PageWrapper;
