import { Button, Col, DatePicker, Divider, Form, Input, Radio, RadioChangeEvent, Row, Select } from 'antd';
import CommonControl from 'components/page/CommonControl';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import phuongTienChuaChayEnum from 'enum/phuongTienChuaChayEnum';
import phuongTienThuyChuaChayEnum from 'enum/phuongTienThuyChuaChayEnum';
import trangThaiHoatDongEnum from 'enum/trangThaiHoatDongEnum';
import trucThuocEnum from 'enum/trucThuocEnum';
import xeChuaChayEnum from 'enum/xeChuaChayEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import {
	default as FireTruckService,
	default as fireTruckService,
} from 'services/api/inventoryService/fireTruckService';
import pcccUnitService, { PcccUnit } from 'services/api/inventoryService/pcccUnitService';
import ProvinceService from 'services/api/provinceService';
import utils from 'services/api/utils';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import { LocationInfo } from '../Tabs/QuanLyDonVi/QuanLyDonVi';

const { Option } = Select;

interface IDialogAddPTPCCCParams {
	record?: {
		tenPhuongTien: any;
		soHieu: any;
		bienso: any;
		soDienThoai: any;
		imei: any;
		loaiPhuongTienID: any;
		loaiPhuongTien: any;
		trangThaiHoatDongID: any;
		trangThaiHoatDong: any;
		tinhThanhID: any;
		tinhThanh: any;
		trucThuocID: any;
		trucThuoc: any;
		donviID: any;
		quanly: any;
		Id: any;
	};
	id?: any;
	mode?: 'add' | 'edit' | 'view';
	callback?: () => void;
	userInfo?: UserInfo;
	location?: LocationInfo;
	donviId?: string;
}

// Dialog Thêm mới Phương tiện PCCC & CNCH
const DialogAddPTPCCC: FunctionComponent<IDialogAddPTPCCCParams> = (props: IDialogAddPTPCCCParams) => {
	const dialog = useDialog();
	const [form] = Form.useForm();

	const [isShowDistrict, setIsShowDistrict] = useState(false);
	const [districts, setDistricts] = useState<JSX.Element[]>([]);
	const [unitElements, setUnitElements] = useState<JSX.Element[]>([]);
	const [disabledTrucThuocSelect, setDisabledTrucThuocSelect] = useState(true);
	const [disabledUnitSelect, setDisabledUnitSelect] = useState(true);

	const [phuongTienChuaChayCoGioi, setPhuongTienChuaChayCoGioi] = useState('XE_CHUA_CHAY');

	// get list of provinces
	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const getUnits = async (params: any) => {
		const unitsFecthed = await pcccUnitService.searchPcccUnitForSelect(params);
		return unitsFecthed;
	};

	const getDistricts = async (provinceId: string) => {
		return await ProvinceService.findDistrictsByProvince(provinceId);
	};

	const handleChangeProvince = async (provinceId: string) => {
		form.resetFields(['quanHuyen', 'donViPCCC', 'trucThuoc']);
		setDistricts([]);
		setUnitElements([]);
		if (provinceId) {
			const districts = await getDistricts(provinceId);
			let quanHuyenList: any[] = [];
			if (
				props.userInfo &&
				props.userInfo.Locations &&
				props.userInfo.Locations.length > 0 &&
				!props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
			) {
				props.userInfo.Locations.forEach((location: any) => {
					if (location.DistrictId) {
						districts.forEach(quanHuyen => {
							if (quanHuyen.Id === location.DistrictId) {
								quanHuyenList.push(quanHuyen);
							}
						});
					} else {
						quanHuyenList = districts;
					}
				});
			} else {
				quanHuyenList = districts;
			}
			setDistricts(Generator.generateOptions(quanHuyenList, 'Id', 'Name'));
			setDisabledTrucThuocSelect(false);
		} else {
			setIsShowDistrict(false);
			setDisabledTrucThuocSelect(true);
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeTrucThuoc = async (trucThuocType: LoaiHinhDonViEnum) => {
		form.resetFields(['quanHuyen', 'donViPCCC']);
		setUnitElements([]);
		if (trucThuocType) {
			const params = {
				Location: {
					TinhThanh: form.getFieldValue('tinhThanh'),
					QuanHuyen: form.getFieldValue('quanHuyen'),
				},
				UnderType: trucThuocType,
			};
			const units = await getUnits(params);
			generateUnitElements(units);
			setIsShowDistrict(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
			setDisabledUnitSelect(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
		} else {
			setIsShowDistrict(false);
			setDisabledUnitSelect(true);
		}
	};

	const handleChangeDistrict = async (districtId: string) => {
		form.resetFields(['donViPCCC']);
		setUnitElements([]);
		if (districtId) {
			const params = {
				Location: {
					TinhThanh: form.getFieldValue('tinhThanh'),
					QuanHuyen: districtId,
				},
				UnderType: form.getFieldValue('trucThuoc'),
			};
			const units = await getUnits(params);
			generateUnitElements(units);
			setDisabledUnitSelect(false);
		} else {
			setDisabledUnitSelect(true);
		}
	};

	const generateUnitElements = (units: PcccUnit[]) => {
		if (units && units.length > 0) {
			const options = units.map(unit => (
				<Option key={unit.Id} value={unit.Id}>
					{unit.Name}
				</Option>
			));

			setUnitElements(options);
		}
	};

	useEffect(() => {
		if (props.record) {
			setDisabledTrucThuocSelect(false);
			setDisabledUnitSelect(false);
			// Get Data danh sách phương tiện pccc & cnch
			FireTruckService.getFireTruck(props?.record.Id).then((result: any) => {
				if (result.length === 0) {
					return;
				}
				const _data = result;
				let promises: PromiseLike<any>[] = [];
				setIsShowDistrict(_data.TrucThuoc === 'QUAN_HUYEN');
				getDistricts(_data.Location?.TinhThanh).then(districts => {
					setDistricts(Generator.generateOptions(districts, 'Id', 'Name'));
				});

				const params = {
					Location: {
						TinhThanh: _data.Location?.TinhThanh,
						QuanHuyen: _data.Location?.QuanHuyen,
					},
					// UnderType: _data.TrucThuoc,
				};

				promises.push(
					getUnits(params).then(units => {
						generateUnitElements(units);
					}),
				);
				Promise.all(promises).then(() => {
					setPhuongTienChuaChayCoGioi(_data.LoaiPhuongTien);
					form.setFieldsValue({
						tenptpccc: _data.TenPhuongTien,
						sohieu: _data.SoHieuPhuongTien,
						bienso: _data.BienSo,
						SoDienThoai: _data.SoDienThoai,
						imei: _data.Imei,
						loaiphuongtien: _data.LoaiPhuongTien,
						trangthaihd: _data.TinhTrangHoatDong,
						tinhThanh: _data.Location?.TinhThanh,
						quanHuyen: _data.Location?.QuanHuyen,
						trucThuoc: _data.TrucThuoc,
						donViPCCC: _data.DonVi,
						Manager: _data?.Manager,
						UsedDate: _data.UsedDate ? moment(_data.UsedDate) : null,
						ExpireDate: _data.ExpireDate ? moment(_data.ExpireDate) : null,
						Note: _data?.Note,
						TechnicalInfo: _data?.TechnicalInfo,
						Category: _data?.Category || null,
					});
				});
			});
		} else {
			form.setFieldsValue({
				tinhThanh: props.location?.TinhThanh,
				quanHuyen: props.location?.QuanHuyen,
			});
			const params = {
				Location: {
					TinhThanh: props.location?.TinhThanh,
					QuanHuyen: props.location?.QuanHuyen,
				},
			};
			if (props.location?.QuanHuyen) {
				form.setFieldsValue({ trucThuoc: 'QUAN_HUYEN' });
				setIsShowDistrict(true);
			} else {
				form.setFieldsValue({ trucThuoc: 'PC07' });
			}

			getDistricts(props.location?.TinhThanh ?? '').then(districts => {
				setDistricts(Generator.generateOptions(districts, 'Id', 'Name'));
			});

			getUnits(params).then(units => {
				let obj = units.find(o => o.Id === props.donviId);
				if (obj) {
					form.setFieldsValue({ donViPCCC: props.donviId });
				}
				generateUnitElements(units);
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props]);

	const executeCallback = () => {
		if (props.callback) {
			props.callback();
		}
	};

	// Click button Add thêm mới
	async function themMoiPTPCCC() {
		form.validateFields().then(values => {
			if (values) {
				let param: any = {
					TenPhuongTien: form.getFieldValue('tenptpccc'),
					LoaiPhuongTien: form.getFieldValue('loaiphuongtien') || 'XE_CHUA_CHAY',
					TinhTrangHoatDong: form.getFieldValue('trangthaihd'),
					BienSo: form.getFieldValue('bienso'),
					Location: {
						TinhThanh: form.getFieldValue('tinhThanh'),
						QuanHuyen: form.getFieldValue('quanHuyen'),
					},
					TrucThuoc: form.getFieldValue('trucThuoc'),
					DonVi: form.getFieldValue('donViPCCC'),
					SoDienThoai: form.getFieldValue('SoDienThoai'),
					Imei: form.getFieldValue('imei'),
					Manager: form.getFieldValue('Manager'),
					UsedDate: form.getFieldValue('UsedDate'),
					ExpireDate: form.getFieldValue('ExpireDate'),
					Note: form.getFieldValue('Note'),
					Category: form.getFieldValue('Category') || '',
					TechnicalInfo: form.getFieldValue('TechnicalInfo'),
				};

				if (props?.record?.Id) {
					param.Id = props.record.Id;
					fireTruckService
						.updateFireTruck(param)
						.then((_: any) => {
							// callback();
							showNotification(
								'success',
								t('notification.success'),
								t('notification.success_update_desc', { item: form.getFieldValue('tenptpccc') }),
							);
							dialog.close();
							executeCallback();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response.data || errors.message);
						});
				} else {
					fireTruckService
						.createFireTruck(param)
						.then((_: any) => {
							// callback();
							showNotification(
								'success',
								t('notification.success'),
								t('notification.success_create_desc', { item: form.getFieldValue('tenptpccc') }),
							);
							dialog.close();
							executeCallback();
						})
						.catch((errors: any) => {
							showNotification('error', t('notification.error'), errors.response.data || errors.message);
						});
				}
			}
		});
	}

	const onChange = (e: RadioChangeEvent) => {
		form.resetFields(['Category']);
		if (e.target.value === 'MAY_BAY_CHUA_CHAY') {
			form.setFieldsValue({ Category: null });
		}
		setPhuongTienChuaChayCoGioi(e.target.value);
	};

	return (
		<div className="common-dialog">
			<Form layout="vertical" name="normal_login" form={form}>
				<Row gutter={32} className="mb15">
					<Col span={8}>
						<ColumnTitle columnTitle="I. Thông tin chung" />
						<div>
							<Form.Item
								label="Tên phương tiện"
								name="tenptpccc"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên phương tiện PCCC không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên phương tiện PCCC & CNCH" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item label="Biển số xe" name="bienso">
								<Input placeholder="Nhập biển số" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Số điện thoại"
								name="SoDienThoai"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder={t('placeholder.phoneNumber')}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							{/* <Form.Item label="Loại phương tiện PCCC & CNCH" name="loaiphuongtien">
								<Input placeholder="Nhập loại phương tiện PCCC & CNCH" disabled={props.mode === 'view'} />
							</Form.Item> */}
							<Form.Item label="Phương tiện chữa cháy cơ giới" name="loaiphuongtien">
								<Radio.Group
									name="radiogroup"
									defaultValue={'XE_CHUA_CHAY'}
									onChange={onChange}
									style={{ display: 'flex', flexDirection: 'column' }}>
									<Radio value={'XE_CHUA_CHAY'}>Xe chữa cháy</Radio>
									<Radio value={'XE_CHUYEN_DUNG'}>Xe chuyên dùng</Radio>
									<Radio value={'MAY_BAY_CHUA_CHAY'}>Máy bay chữa cháy</Radio>
									<Radio value={'PHUONG_TIEN_THUY_CHUA_CHAY'}>Phương tiện thủy chữa cháy</Radio>
								</Radio.Group>
							</Form.Item>

							<Form.Item label="Loại phương tiện PCCC & CNCH" name="Category">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn Loại phương tiện PCCC & CNCH"
									allowClear
									disabled={props.mode === 'view' || phuongTienChuaChayCoGioi === 'MAY_BAY_CHUA_CHAY'}>
									{Generator.generateOptions(
										phuongTienChuaChayCoGioi === 'XE_CHUA_CHAY'
											? xeChuaChayEnum.getAll()
											: phuongTienChuaChayCoGioi === 'XE_CHUYEN_DUNG'
											? phuongTienChuaChayEnum.getAll()
											: phuongTienThuyChuaChayEnum.getAll(),
									)}
								</Select>
							</Form.Item>

							<Form.Item label="Thông số kỹ thuật" name="TechnicalInfo">
								<Input placeholder="Nhập thông số kỹ thuật" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>

					<Col span={8}>
						<ColumnTitle columnTitle="II. Thông tin đơn vị" />
						<div>
							<Form.Item
								name="tinhThanh"
								label="Tỉnh thành"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tỉnh thành không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder={t('placeholder.tinhThanh')}
									onChange={handleChangeProvince}
									allowClear
									disabled>
									{props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
								</Select>
							</Form.Item>

							<Form.Item
								name="trucThuoc"
								label="Trực thuộc"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Trực thuộc không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									onChange={handleChangeTrucThuoc}
									placeholder="Chọn đơn vị trực thuộc"
									allowClear
									disabled>
									{Generator.generateOptions(trucThuocEnum.getAll())}
								</Select>
							</Form.Item>

							{isShowDistrict ? (
								<Form.Item
									name="quanHuyen"
									label="Quận Huyện"
									rules={[
										{
											message: 'Quận Huyện không được bỏ trống',
											required: true,
											whitespace: true,
										},
									]}>
									<Select
										showSearch
										optionFilterProp="children"
										placeholder={t('placeholder.quanHuyen')}
										allowClear
										onChange={handleChangeDistrict}
										disabled>
										{districts}
									</Select>
								</Form.Item>
							) : null}

							<Form.Item
								label="Đơn vị PCCC"
								name="donViPCCC"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Đơn vị PCCC không được bỏ trống',
									},
								]}>
								{/* <Input placeholder="Nhập đơn vị phòng cháy chữa cháy" /> */}
								<Select showSearch optionFilterProp="children" placeholder="Chọn đơn vị PCCC" allowClear disabled>
									{unitElements}
								</Select>
							</Form.Item>

							<Form.Item label="Cán bộ quản lý" name="Manager">
								<Input placeholder="Nhập tên cán bộ quản lý" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>

					<Col span={8}>
						<ColumnTitle columnTitle="III. Thông tin thêm" />
						<div>
							<Form.Item className={'mt10'} name="trangthaihd" label="Trạng thái hoạt động">
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn trạng thái hoạt động"
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(trangThaiHoatDongEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item label="Ngày đưa vào sử dụng" name="UsedDate">
								<DatePicker
									placeholder="Chọn ngày đưa vào sử dụng"
									locale={CommonControl.localeVi}
									format={systemProperties.dateFormat}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item
								label="Ngày hết hạn kiểm định"
								name="ExpireDate"
								dependencies={['UsedDate']}
								rules={[
									({ getFieldValue }) => {
										return {
											validator: (_, value) => {
												if (!value || moment(getFieldValue('UsedDate')).isBefore(moment(value))) {
													return Promise.resolve();
												}
												return Promise.reject(new Error('Ngày hết hạn phải sau ngày đưa vào sử dụng'));
											},
										};
									},
								]}>
								<DatePicker
									placeholder="Chọn ngày hết hạn kiểm định"
									locale={CommonControl.localeVi}
									format={systemProperties.dateFormat}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item label="IMEI thiết bị" name="imei">
								<Input placeholder="Nhập IMEI thiết bị định vị" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item label="Ghi chú" name="Note">
								<Input.TextArea placeholder="Nhập ghi chú" rows={5} disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Divider />

				<div className="dialog__buttons mt10">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						{props.mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{props.mode !== 'view' && (
						<Button type="primary" htmlType="submit" onClick={() => themMoiPTPCCC()}>
							{props.record ? 'Lưu' : 'Thêm mới'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogAddPTPCCC;
