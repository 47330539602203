import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Select } from 'antd';
import DialogCongTacTuyenTruyen from 'components/page/QuanLyCongTacPhongChay/Dialogs/DialogCongTacTuyenTruyen';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import {
	default as PropagateService,
	default as propagateService,
} from 'services/api/businessService/propagateService';
import constructionService from 'services/api/inventoryService/constructionService';
import Utils from 'services/api/utils';
import { PermissionProps } from '../QuanLyCongTacPhongChay';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const TuyenTruyenHuanLuyen: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();

	const [TongCongTacTuyenTruyen, setTongSoCongTacTuyenTruyen] = useState<number>(0);
	const [dataSourceCongTacTuyenTruyenHuanLuyen, setdataSourceCongTacTuyenTruyenHuanLuyen] = useState<any[]>([]);
	const [currentPage, setCurrentPage] = useState(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);

	// get list of provinces
	const tinhThanhscustomer = Utils.GetTinhThanhUser();
	const tinhThanhsAdmin = Utils.GetTinhThanhAdmin();

	const columnsDSCongTacTuyenTruyenHuanLuyenPCCC = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Thời gian',
			dataIndex: 'thoigian',
			key: 'thoigian',
		},
		{
			title: 'Cơ sở công trình',
			dataIndex: 'cosocongtrinh',
			key: 'cosocongtrinh',
			render: (cosocongtrinh: string, record: any) => {
				return (
					<NavigateButton url={`/chiTietCoSoCongTrinh?id=${record.ConstructionId}&mode=view`} name={cosocongtrinh} />
				);
			},
		},
		{
			title: 'Nội dung huấn luyện bồi dưỡng PCCC',
			dataIndex: 'Content',
			key: 'Content',
		},
		{
			className: 'text--right',
			title: 'Thời lượng',
			dataIndex: 'TotalHour',
			key: 'TotalHour',
		},
		{
			align: 'center' as const,
			title: 'Số lượng đối tượng tham gia',
			children: [
				{
					className: 'text--right',
					title: 'Lực lượng PCCC',
					dataIndex: 'PcccCount',
					key: 'PcccCount',
					width: 130,
				},
				{
					className: 'text--right',
					title: 'Cán bộ quản lý, lãnh đạo',
					dataIndex: 'ManangerCount',
					key: 'ManangerCount',
					width: 130,
				},
				{
					className: 'text--right',
					title: 'Người lao động',
					dataIndex: 'WorkerCount',
					key: 'WorkerCount',
					width: 100,
				},
				{
					className: 'text--right',
					title: 'Đối tượng khác',
					dataIndex: 'OtherCount',
					key: 'OtherCount',
					width: 100,
				},
			],
		},
		{
			className: 'text--right',
			title: 'Số giấy CN PCCC được cấp',
			dataIndex: 'CertificateNum',
			key: 'CertificateNum',
		},
		{
			align: 'center' as const,
			width: 100,
			title: 'Thao tác',
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.PROPAGAE_EDIT) ||
				props.permissionList.includes(PermissionEnum.PROPAGAE_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.PROPAGAE_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.PROPAGAE_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle="Chỉnh sửa công tác tuyên truyền huấn luyện"
					EditDialog={
						<DialogCongTacTuyenTruyen
							userInfo={props.userInfo}
							Id={record.Id}
							callback={getDataTimKiemTuyenTruyenHuanLuyen}
							mode="add"
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.Content}
							callback={getDataTimKiemTuyenTruyenHuanLuyen}
							deleteService={PropagateService.deletePropagate}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	useEffect(() => {
		document.title = t('quanLyCongTacPhongChay.tuyenTruyenHuanLuyen');
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.PROPAGAE_VIEW])) {
				getDataTimKiemTuyenTruyenHuanLuyen();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	function onChangePage(page: number, pageSize: number) {
		SearchCongTacTuyenTruyen(page, pageSize);
	}

	function SearchCongTacTuyenTruyen(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		let param: any = {
			Name: form.getFieldValue('Name'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Location = {
				TinhThanh: form.getFieldValue('TinhThanh'),
			};
		}

		propagateService
			.searchPropagates(param, pageOption)
			.then((res: any) => {
				setTongSoCongTacTuyenTruyen(res.TotalItems);
				optiondanhsachTuyenTruyenHuanLuyen(res ? res.Data : []);
			})
			.catch();
	}

	function getDataTimKiemTuyenTruyenHuanLuyen(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		propagateService
			.listPropagate(pageOption)
			.then((res: any) => {
				setTongSoCongTacTuyenTruyen(res.TotalItems);
				optiondanhsachTuyenTruyenHuanLuyen(res ? res.Data : []);
			})
			.catch();
	}

	const optiondanhsachTuyenTruyenHuanLuyen = (data: any[]) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data.length > 0) {
			data.forEach((item: any) => {
				item.thoigian = moment(item.Time).format('DD/MM/YYYY HH:mm:ss');
				if (item.ConstructionId) {
					promises.push(
						constructionService
							.getConstruction(item.ConstructionId)
							.then((result: any) => {
								item.cosocongtrinh = result.TenCoSoCongTrinh;
							})
							.catch(() => {
								item.cosocongtrinh = null;
							}),
					);
				}
				array.push(item);
			});
		}
		Promise.all(promises)
			.then(() => {
				setdataSourceCongTacTuyenTruyenHuanLuyen(data);
			})
			.catch();
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.setFieldsValue({ Name: null });
		}

		SearchCongTacTuyenTruyen(CURRENT_PAGE);
	};

	// Event change tỉnh thành danh sách cơ sở công trình
	const handleChangeProvince = () => {
		SearchCongTacTuyenTruyen(CURRENT_PAGE);
	};

	const handleChangeDatePicker = () => {
		SearchCongTacTuyenTruyen(CURRENT_PAGE);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(<DialogCongTacTuyenTruyen userInfo={props.userInfo} Id={record.Id} mode="view" />, {
					className: 'dialog-add',
					title: `${record.cosocongtrinh}`,
					showCloseIcon: true,
					isCanClose: true,
				});
			},
		};
	};

	const handleRefreshPage = () => {
		form.resetFields();
		getDataTimKiemTuyenTruyenHuanLuyen(CURRENT_PAGE);
	};

	const formAction = (
		<>
			<div className="flex--row">
				<Form.Item name="TinhThanh">
					<Select
						className="withMinSelect"
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.tinhThanh')}
						onChange={handleChangeProvince}
						allowClear>
						{props.permissionList.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
					</Select>
				</Form.Item>

				<Form.Item className="ml10" name="dateRange">
					<RangePickerInput onChangeDatePicker={handleChangeDatePicker} form={form} />
				</Form.Item>

				<Form.Item name="Name" className="ml10">
					<Input.Search
						placeholder="Thông tin tìm kiếm"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</Form.Item>
			</div>

			<div>
				<Button type="default" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				{props.permissionList.includes(PermissionEnum.PROPAGAE_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type="primary"
						onClick={async () => {
							await CustomDialog(
								<DialogCongTacTuyenTruyen
									userInfo={props.userInfo}
									callback={getDataTimKiemTuyenTruyenHuanLuyen}
									Id={''}
									mode="add"
								/>,
								{
									className: 'dialog-add',
									title: 'Thêm mới công tác tuyên truyền huấn luyện',
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<PlusOutlined />
						Thêm mới
					</Button>
				) : null}
			</div>
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cơ sở tuyên truyền
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongCongTacTuyenTruyen}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const formTable = (
		<TableComponent
			dataSource={dataSourceCongTacTuyenTruyenHuanLuyen}
			columns={columnsDSCongTacTuyenTruyenHuanLuyenPCCC}
			onRow={handleShowViewDetail}
			paginator={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
		/>
	);

	return (
		<PageWrapper
			titlePage="Công tác tuyên truyền, huấn luyện và bồi dưỡng nghiệp vụ về PCCC"
			form={form}
			formAction={formAction}
			formTable={formTable}
		/>
	);
};

export default withTranslation()(TuyenTruyenHuanLuyen);
