import React from 'react';
import { Select } from 'antd';

const Generator = {
	generateOptions: (data: any[], valueName = 'value', labelName = 'label', disabled = 'disabled'): JSX.Element[] => {
		let options: JSX.Element[] = [];
		data.forEach(item =>
			options.push(
				<Select.Option key={item[valueName]} value={item[valueName]} disabled={item[disabled]}>
					{item[labelName] || item[valueName]}
				</Select.Option>,
			),
		);

		return options;
	},
};

export default Generator;
