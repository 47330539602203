import { Button, Col, Divider, Form, Input, InputNumber, Row, Select, Tabs } from 'antd';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import CustomMap, { CustomMapIn, CustomMapOut } from 'components/shared/CustomMap/CustomMap';
import { MarkerType } from 'components/shared/Map/ToaDoMarker';
import TitleComponent from 'components/shared/TitleComponent/TitleComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiHinhDonViEnum from 'enum/loaiHinhDonViEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PermissionSelector from 'redux/Permission/permission.selector';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { UserInfo } from 'services/api/authenService/usersService';
import coordinatesService from 'services/api/coordinatesService';
import { SearchFireFighterParams } from 'services/api/inventoryService/fireFighterService';
import pcccUnitService from 'services/api/inventoryService/pcccUnitService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import { blockInvalidChar } from '../CommonControl/BlockInvalidChar';
import DanhSachCanBoChienSy from '../QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachCanBoChienSy';
import DanhSachDiemLayNuoc from '../QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachDiemLayNuoc';
import DanhSachDonViHoTro from '../QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachDonViHoTro';
import DanhSachPhuongTien from '../QuanLyDonviPhongChay/Tabs/QuanLyDonVi/DanhSachPhuongTien';
import { QuanLyDonViPhongChayTab } from '../QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import './ChiTietDonViPCCC.scss';

export interface TabProps {
	id: string;
}
export interface PermissionProps {
	permissions: PermissionEnum[];
	userInfo: UserInfo;
}
const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const mapStateToProps = (): PermissionProps => ({
	permissions: PermissionSelector.getPermissionList(),
	userInfo: PermissionSelector.getUserInfo(),
});

const ChiTietDonViPCCC: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const navigate = useNavigate();
	// state of component
	const [mapInput, setMapInput] = useState<CustomMapIn>();
	const [mapOutput, setMapOutput] = useState<CustomMapOut>();
	const _mode = useMemo(() => searchParams.get('mode'), [searchParams]);
	const _id: string | null = searchParams.get('id');
	const [titleScreen, setTitleScreen] = useState<string>();
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataListTeams, setDataListTeams] = useState<any>([]);
	const isNavigate = navigateFromDashboardSelector.getNavigateStatus();
	const [apiSearchData, setApiSearchData] = useState<SearchFireFighterParams>();

	// const columnsTeam: any = [
	// 	{
	// 		title: 'STT',
	// 		dataIndex: 'key',
	// 		align: 'center' as const,
	// 		key: 'key',
	// 		width: 45,
	// 		ellipsis: {
	// 			showTitle: false,
	// 		},
	// 		render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
	// 	},
	// 	{
	// 		title: 'Tên đội',
	// 		dataIndex: 'Name',
	// 		key: 'Name',
	// 		width: '45%',
	// 	},
	// 	{
	// 		title: 'Quân số',
	// 		dataIndex: 'MemberCount',
	// 		key: 'MemberCount',
	// 		render: (MemberCount: any) => (MemberCount ? MemberCount : 0),
	// 	},
	// 	{
	// 		title: 'Quân số',
	// 		hidden: true,
	// 		dataIndex: 'checkIndex',
	// 		key: 'checkIndex',
	// 	},
	// 	{
	// 		align: 'center' as const,
	// 		title: 'Thao tác',
	// 		key: 'action',
	// 		width: 100,
	// 		hidden: _mode === 'view',
	// 		render: (_: any, record: any) => (
	// 			<TableAction
	// 				isViewButtonEdit={true}
	// 				isViewButtonDelete={true}
	// 				editTitle={`Cập nhật đội "${record.Name}"`}
	// 				className="dialog-add-doi-truc"
	// 				EditDialog={<DialogAddDoi callback={addDanhSachDoi} checkIndex={record.checkIndex} data={record} />}
	// 				DeleteDialog={
	// 					<DeleteDialog
	// 						id={record.TeamId}
	// 						checkIndex={record.checkIndex}
	// 						name={record.Name}
	// 						dataListTeams={dataListTeams}
	// 						deleteLocal={deleteLocal}
	// 					/>
	// 				}
	// 			/>
	// 		),
	// 	},
	// ].filter(item => !item.hidden);

	// const deleteLocal = (dataList: any) => {
	// 	setDataListTeams(dataList);
	// };

	const postUnitPccc = async () => {
		const validated = await form.validateFields();
		const id = _id;
		if (validated) {
			const unitPcccInfo: any = {
				TenDonVi: form.getFieldValue('TenDonVi'),
				MaDonVi: form.getFieldValue('MaDonVi'),
				PhoneNumber: form.getFieldValue('PhoneNumber'),
				SoDienThoaiTrucBan: form.getFieldValue('SoDienThoaiTrucBan'),
				CanBoQuanLy: form.getFieldValue('CanBoQuanLy'),
				Email: form.getFieldValue('Email'),
				SoDiemLayNuocQuanLy: form.getFieldValue('SoDiemLayNuocQuanLy'),
				SoNhanSuQuanLy: form.getFieldValue('SoNhanSuQuanLy'),
				SoNhanSuChuaChay: form.getFieldValue('SoNhanSuChuaChay'),
				LoaiHinhDonVi: form.getFieldValue('LoaiHinhDonVi'),
				Teams: dataListTeams,
				Leader: form.getFieldValue('Leader'),
				ProfessionalStaffCount: form.getFieldValue('ProfessionalStaffCount'),
				TechnicalStaffCount: form.getFieldValue('TechnicalStaffCount'),
				...mapOutput,
			};

			try {
				if (id) {
					unitPcccInfo.Id = id;
					await pcccUnitService.updatePcccUnit(unitPcccInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: form.getFieldValue('TenDonVi') }),
					);
					navigate(-1);
				} else {
					// add a new unit support
					await pcccUnitService.createPcccUnit(unitPcccInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_create_desc', { item: form.getFieldValue('TenDonVi') }),
					);
					navigate(-1);
				}
			} catch (error: any) {
				showNotification('error', t('notification.error'), error.response.data);
			}
		}
	};

	useEffect(() => {
		const id = _id;

		let title = 'THÊM MỚI ĐƠN VỊ ';
		if (_mode === 'view') {
			title = 'CHI TIẾT ĐƠN VỊ ';
		} else if (_mode === 'edit') {
			title = 'CHỈNH SỬA ĐƠN VỊ ';
		}
		setTitleScreen(title);

		const getPcccUnit = async (id: string) => {
			const data = await pcccUnitService.getPcccUnit(id);
			setApiSearchData({ Location: data.Location, PcccUnitId: id ?? '' });
			setDataListTeams(data.Teams ? data.Teams : []);
			form.setFieldsValue(data);
			setMapInput(data);
		};

		if (id) {
			getPcccUnit(id);
		} else {
			coordinatesService
				.findCoordinateById(props.userInfo && props.userInfo.Locations ? props.userInfo.Locations[0]?.CityId : '01')
				.then(coordinate => {
					if (coordinate) {
						setMapInput({ Latitude: coordinate.latitude, Longitude: coordinate.longitude });
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_id, form]);

	const handleChangeInputMapData = (newVal: CustomMapOut) => {
		setMapOutput(newVal);
	};

	const refreshPcccUnit = async () => {
		const data = await pcccUnitService.getPcccUnit(_id!);
		setApiSearchData({ Location: data.Location, PcccUnitId: _id! ?? '' });
		setDataListTeams(data.Teams ? data.Teams : []);
		form.setFieldsValue(data);
		setMapInput(data);
	};

	// const addDanhSachDoi = (param: any) => {
	// 	if (param.TeamId) {
	// 		const newState = dataListTeams.map((obj: any) => {
	// 			if (obj.TeamId === param.TeamId) {
	// 				return param;
	// 			}
	// 			return obj;
	// 		});
	// 		setDataListTeams(newState);
	// 	} else {
	// 		const array = [];
	// 		dataListTeams.forEach((element: any) => {
	// 			if (element.checkIndex && element.checkIndex === param.checkIndex) {
	// 				array.push(element);
	// 			}
	// 		});
	// 		if (array.length > 0) {
	// 			const newState = dataListTeams.map((obj: any) => {
	// 				if (obj.checkIndex === param.checkIndex) {
	// 					return param;
	// 				}
	// 				return obj;
	// 			});
	// 			setDataListTeams(newState);
	// 		} else {
	// 			setDataListTeams((data: any) => [...data, param]);
	// 		}
	// 	}
	// };

	const handleResetFormAction = () => {
		form.resetFields();
	};

	return (
		<div className="chi-tiet-don-vi-container">
			<TitleComponent title={titleScreen} />
			<Form layout={'vertical'} form={form} onFinishFailed={r => console.error(r)} onFinish={postUnitPccc}>
				<Row gutter={32} className="mb15">
					<Col span={10}>
						<ColumnTitle columnTitle="I. Vị trí" />
						<div className="custom-map-container">
							<CustomMap
								containerClass={'chi-tiet-csct-map'}
								form={form}
								data={mapInput}
								markerType={MarkerType.DON_VI_PCCC}
								changeDataCallback={handleChangeInputMapData}
							/>
						</div>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="II. Thông tin chung" />
						<div>
							<Form.Item
								label="Mã đơn vị"
								name="MaDonVi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Mã đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập mã đơn vị" disabled={_mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Tên đơn vị"
								name="TenDonVi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên đơn vị" disabled={_mode === 'view'} />
							</Form.Item>

							<Form.Item label="Đội trưởng" name="CanBoQuanLy">
								<Input placeholder="Nhập đội trưởng" disabled={_mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Số điện thoại đội trưởng"
								name="PhoneNumber"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder="Nhập Số điện thoại"
									disabled={_mode === 'view'}
								/>
							</Form.Item>

							<Form.Item
								label="Số điện thoại trực ban"
								name="SoDienThoaiTrucBan"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder="Nhập Số điện thoại trực ban"
									disabled={_mode === 'view'}
								/>
							</Form.Item>

							<Form.Item
								name="LoaiHinhDonVi"
								label="Loại hình đơn vị"
								rules={[
									{
										required: true,
										message: 'Loại hình đơn vị không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn đơn vị"
									allowClear
									disabled={_mode === 'view'}>
									{Generator.generateOptions(loaiHinhDonViEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item
								label="Email"
								name="Email"
								rules={[
									{
										pattern: systemProperties.emailPattern,
										message: 'Email không đúng định dạng!',
									},
									{
										required: true,
										whitespace: true,
										message: 'Email không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập Email" disabled={_mode === 'view'} />
							</Form.Item>
						</div>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="III. Lực Lượng" />
						<Form.Item label="Chỉ huy" name="Leader">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Nhập số lượng chỉ huy"
								disabled={_mode === 'view'}
							/>
						</Form.Item>

						<Form.Item label="Cán bộ nghiệp vụ" name="ProfessionalStaffCount">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Nhập số lượng cán bộ nghiệp vụ"
								disabled={_mode === 'view'}
							/>
						</Form.Item>
						<Form.Item label="Cán bộ chuyên môn kỹ thuật" name="TechnicalStaffCount">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Nhập số lượng cán bộ chuyên môn"
								disabled={_mode === 'view'}
							/>
						</Form.Item>
						<Form.Item label="Chiến sỹ" name="SoNhanSuChuaChay">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Nhập số lượng chiến sỹ"
								disabled={_mode === 'view'}
							/>
						</Form.Item>
						<Form.Item label="Số lượng phương tiện" name="FireTruckCount">
							<InputNumber
								type="number"
								onKeyDown={blockInvalidChar}
								placeholder="Nhập số lượng phương tiện"
								disabled
							/>
						</Form.Item>
						{/* <div className="danh-sach-truc-thuoc">
							<Button
								className="button-them-moi"
								disabled={_mode === 'view'}
								type={'link'}
								onClick={async () => {
									await CustomDialog(<DialogAddDoi callback={addDanhSachDoi} />, {
										className: 'dialog-add-doi-truc',
										title: 'Thêm đội trực thuộc',
										showCloseIcon: true,
										isCanClose: true,
									});
								}}>
								<PlusOutlined />
								Thêm đội trực thuộc
							</Button>
							<Table columns={columnsTeam} rowKey={(record: any) => record?.Name} dataSource={dataListTeams} />
						</div> */}
					</Col>
				</Row>

				{apiSearchData && (
					<Row style={{ display: 'flow-root' }}>
						<ColumnTitle columnTitle="IV. Chi tiết lực lượng phương tiện " />
						<Tabs defaultActiveKey={QuanLyDonViPhongChayTab.DANH_SACH_CAN_BO_CHIEN_SY}>
							{(props.permissions.includes(PermissionEnum.ADMIN) ||
								props.permissions.includes(PermissionEnum.FIRE_FIGHTER_VIEW)) && (
								<Tabs.TabPane
									tab={<span>Cán bộ chiến sĩ</span>}
									key={QuanLyDonViPhongChayTab.DANH_SACH_CAN_BO_CHIEN_SY}>
									<DanhSachCanBoChienSy
										isDisable={_mode === 'view'}
										searchData={apiSearchData}
										donviId={_id ?? ''}
										onResetFormAction={handleResetFormAction}
										permissionList={props.permissions}
										userInfo={props.userInfo}
										isNavigate={isNavigate}
										mode="detail"
									/>
								</Tabs.TabPane>
							)}

							{(props.permissions.includes(PermissionEnum.ADMIN) ||
								props.permissions.includes(PermissionEnum.FIRE_TRUCK_VIEW)) &&
								apiSearchData && (
									<Tabs.TabPane
										tab={<span>Phương tiện PCCC & CNCH</span>}
										key={QuanLyDonViPhongChayTab.PHUONG_TIEN_PCCC}>
										<DanhSachPhuongTien
											callback={refreshPcccUnit}
											searchData={apiSearchData}
											donviId={_id ?? ''}
											onResetFormAction={handleResetFormAction}
											permissionList={props.permissions}
											userInfo={props.userInfo}
											isNavigate={isNavigate}
											isDisable={_mode === 'view'}
											mode="detail"
										/>
									</Tabs.TabPane>
								)}

							{(props.permissions.includes(PermissionEnum.ADMIN) ||
								props.permissions.includes(PermissionEnum.SUPPORT_UNIT_VIEW)) && (
								<Tabs.TabPane tab={<span>Đơn vị hỗ trợ</span>} key={QuanLyDonViPhongChayTab.DON_VI_HO_TRO}>
									<DanhSachDonViHoTro
										searchData={apiSearchData}
										donviId={_id ?? ''}
										onResetFormAction={handleResetFormAction}
										permissionList={props.permissions}
										userInfo={props.userInfo}
										isDisable={_mode === 'view'}
										mapInput={mapInput}
										mode="detail"
									/>
								</Tabs.TabPane>
							)}

							{(props.permissions.includes(PermissionEnum.ADMIN) ||
								props.permissions.includes(PermissionEnum.WATER_POINT_VIEW)) && (
								<Tabs.TabPane tab={<span>Điểm lấy nước</span>} key={QuanLyDonViPhongChayTab.DIEM_LAY_NUOC}>
									<DanhSachDiemLayNuoc
										searchData={apiSearchData}
										donviId={_id ?? ''}
										onResetFormAction={handleResetFormAction}
										permissionList={props.permissions}
										userInfo={props.userInfo}
										isNavigate={isNavigate}
										isDisable={_mode === 'view'}
										mapInput={mapInput}
										mode="detail"
									/>
								</Tabs.TabPane>
							)}
						</Tabs>
					</Row>
				)}

				<Divider />

				<div className="dialog__buttons mt10">
					<Button type="default" onClick={_ => navigate(-1)}>
						{_mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{_mode !== 'view' && (
						<Button type="primary" htmlType="submit">
							{_mode === 'add' ? 'Thêm mới' : 'Cập nhật'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default connect(mapStateToProps)(withTranslation()(ChiTietDonViPCCC));
