import { Button, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import chucVuEnum from 'enum/chucVuEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import { Moment } from 'moment';
import React, { useEffect } from 'react';
import { useDialog } from 'react-st-modal';
import DutyScheduleService from 'services/api/businessService/dutyScheduleService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';

interface EditDutyScheduleProps {
	unitId: string;
	scheduleId: string | null;
	callback: any;
	dutyDate: Moment;
}

const EditDutySchedule = ({ scheduleId, unitId, dutyDate, callback }: EditDutyScheduleProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	const handleSuccess = () => {
		showNotification('success', t('notification.success'), 'Chỉnh sửa thông tin lịch trực thành công');
		dialog.close();
		callback();
	};

	const handleError = (error: any) => {
		showNotification(
			'error',
			t('notification.error'),
			typeof error?.response?.message === 'string' ? error?.response?.message : 'Lỗi không xác định',
		);
	};

	const handleSubmit = () => {
		const {
			LeaderOfficerName,
			LeaderPhoneNumber,
			LeaderPosition,
			FirstTeamOfficerName,
			FirstTeamPhoneNumber,
			FirstTeamPosition,
			SecondTeamOfficerName,
			SecondTeamPhoneNumber,
			SecondTeamPosition,
			ThirdTeamOfficerName,
			ThirdTeamPhoneNumber,
			ThirdTeamPosition,
			FourthTeamOfficerName,
			FourthTeamPhoneNumber,
			FourthTeamPosition,
		} = form.getFieldsValue();

		const LeadSchedule = {
			OfficerName: LeaderOfficerName,
			PhoneNumber: LeaderPhoneNumber,
			Position: LeaderPosition,
		};
		const Team1 = {
			OfficerName: FirstTeamOfficerName,
			PhoneNumber: FirstTeamPhoneNumber,
			Position: FirstTeamPosition,
		};
		const Team2 = {
			OfficerName: SecondTeamOfficerName,
			PhoneNumber: SecondTeamPhoneNumber,
			Position: SecondTeamPosition,
		};
		const Team3 = {
			OfficerName: ThirdTeamOfficerName,
			PhoneNumber: ThirdTeamPhoneNumber,
			Position: ThirdTeamPosition,
		};
		const Team4 = {
			OfficerName: FourthTeamOfficerName,
			PhoneNumber: FourthTeamPhoneNumber,
			Position: FourthTeamPosition,
		};

		const payload = {
			DutyDate: dutyDate,
			PcccUnitId: unitId,
			LeadSchedule,
			Team1,
			Team2,
			Team3,
			Team4,
		};

		scheduleId
			? DutyScheduleService.updateDutyScheduleSonLa({ ...payload, Id: scheduleId })
					.then(() => handleSuccess())
					.catch(error => handleError(error))
			: DutyScheduleService.createDutyScheduleSonLa(payload)
					.then(() => handleSuccess())
					.catch(error => handleError(error));
	};

	useEffect(() => {
		if (scheduleId) {
			DutyScheduleService.getDutyScheduleSonLaById(scheduleId).then(data => {
				const { LeadSchedule, Team1, Team2, Team3, Team4 } = data;
				form.setFieldsValue({
					LeaderOfficerName: LeadSchedule?.OfficerName,
					LeaderPhoneNumber: LeadSchedule?.PhoneNumber,
					LeaderPosition: LeadSchedule?.Position,
					FirstTeamOfficerName: Team1?.OfficerName,
					FirstTeamPhoneNumber: Team1?.PhoneNumber,
					FirstTeamPosition: Team1?.Position,
					SecondTeamOfficerName: Team2?.OfficerName,
					SecondTeamPhoneNumber: Team2?.PhoneNumber,
					SecondTeamPosition: Team2?.Position,
					ThirdTeamOfficerName: Team3?.OfficerName,
					ThirdTeamPhoneNumber: Team3?.PhoneNumber,
					ThirdTeamPosition: Team3?.Position,
					FourthTeamOfficerName: Team4?.OfficerName,
					FourthTeamPhoneNumber: Team4?.PhoneNumber,
					FourthTeamPosition: Team4?.Position,
				});
			});
		}
	}, [scheduleId, form]);

	return (
		<div className="common-dialog">
			<Form layout="vertical" name="normal_login" form={form} onFinish={handleSubmit}>
				<Row>
					<Typography.Title level={5}>Trực lãnh đạo</Typography.Title>

					<Col span={24}>
						<Form.Item
							name="LeaderOfficerName"
							label="Đồng chí"
							rules={[{ required: true, message: 'Đồng chí là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên đồng chí" />
						</Form.Item>
					</Col>
					<Col span={24}>
						<Form.Item
							name="LeaderPhoneNumber"
							label="Số điện thoại"
							rules={[
								{
									required: true,
									message: 'Số điện thoại là thông tin bắt buộc',
									whitespace: true,
								},
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item name="LeaderPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Divider className="mb15" />

				<Row gutter={24}>
					<Col span={6}>
						<Typography.Title level={5}>Trực đội 1</Typography.Title>
						<Form.Item
							name="FirstTeamOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="FirstTeamPhoneNumber"
							label="Số điện thoại"
							rules={[
								{
									required: true,
									message: 'Số điện thoại là thông tin bắt buộc',
									whitespace: true,
								},
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="FirstTeamPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>

					<Col span={6}>
						<Typography.Title level={5}>Trực đội 2</Typography.Title>
						<Form.Item
							name="SecondTeamOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="SecondTeamPhoneNumber"
							label="Số điện thoại"
							rules={[
								{ required: true, message: 'Số điện thoại là thông tin bắt buộc', whitespace: true },
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="SecondTeamPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Typography.Title level={5}>Trực đội 3</Typography.Title>
						<Form.Item
							name="ThirdTeamOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="ThirdTeamPhoneNumber"
							label="Số điện thoại"
							rules={[
								{ required: true, message: 'Số điện thoại là thông tin bắt buộc', whitespace: true },
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="ThirdTeamPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
					<Col span={6}>
						<Typography.Title level={5}>Trực đội 4</Typography.Title>
						<Form.Item
							name="FourthTeamOfficerName"
							label="Cán bộ trực"
							rules={[{ required: true, message: 'Tên cán bộ là thông tin bắt buộc', whitespace: true }]}>
							<Input placeholder="Nhập tên cán bộ" />
						</Form.Item>
						<Form.Item
							name="FourthTeamPhoneNumber"
							label="Số điện thoại"
							rules={[
								{ required: true, message: 'Số điện thoại là thông tin bắt buộc', whitespace: true },
								{
									pattern: systemProperties.phonePattern,
									message: 'Số điện thoại không đúng định dạng',
								},
							]}>
							<Input placeholder="Nhập tên đội" />
						</Form.Item>
						<Form.Item name="FourthTeamPosition" label="Chức vụ">
							<Select showSearch optionFilterProp="children" placeholder="Chọn chức vụ" allowClear>
								{Generator.generateOptions(chucVuEnum.getAll())}
							</Select>
						</Form.Item>
					</Col>
				</Row>

				<Divider className="mb15" />

				<Row justify="end">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						Hủy bỏ
					</Button>
					<Button type="primary" htmlType="submit" className="ml10">
						Cập nhật
					</Button>
				</Row>
			</Form>
		</div>
	);
};

export default EditDutySchedule;
