import {
	BlockOutlined,
	BookOutlined,
	CalendarOutlined,
	CheckOutlined,
	PlusOutlined,
	UserOutlined,
} from '@ant-design/icons';
import { Button, Form, Input, Select, Tooltip } from 'antd';
import KeHoachKiemTraCollapseType from 'components/page/QuanLyCongTacPhongChay/Tabs/KeHoachKiemTra/KeHoachKiemTraCollapseType/KeHoachKiemTraCollapseType';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import CheckingStatusIcon from 'components/shared/Icon/CheckingStatusIcon';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import trangThaiThucHienEnum, { TrangThaiThucHienEnum } from 'enum/trangThaiThucHienEnum';
import { formatDate, showDetailCellTableInfo } from 'helpers';
import { ConstructionCheckingModel } from 'models/ConstructionChecking/ConstructionChecking.model';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import ConstructionCheckingService from 'services/api/businessService/constructionCheckingService';
import { PermissionProps } from '../../QuanLyCongTacPhongChay';
import DialogThemMoiCbChienSy from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiCanBoChienSy';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

export enum CollapseType {
	DEFAULT = 'DEFAULT',
	SO_CU = 'SO_CU',
	CHECKER = 'CHECKER',
	STATUS = 'STATUS',
	DATE = 'DATE',
}

const collapseTypes = [
	{ value: CollapseType.DEFAULT, label: 'Nhóm: Mặc định', icon: <BlockOutlined /> },
	{ value: CollapseType.SO_CU, label: 'Nhóm: Cơ sở pháp lý', icon: <BookOutlined /> },
	{ value: CollapseType.CHECKER, label: 'Nhóm: Cán bộ kiểm tra', icon: <UserOutlined /> },
	{ value: CollapseType.STATUS, label: 'Nhóm: Trạng thái', icon: <CheckOutlined /> },
	{ value: CollapseType.DATE, label: 'Nhóm: Lịch kiểm tra', icon: <CalendarOutlined /> },
];

const KeHoachKiemTra: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const navigate = useNavigate();
	const getDanhSachKeHoachKiemTra = useRef<any>(null);

	const columns = [
		{
			title: 'STT',
			dataIndex: 'Id',
			key: 'Id',
			align: 'center' as const,
			width: 45,
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên công trình kiểm tra',
			dataIndex: 'ConstructionName',
			key: 'ConstructionName',
			render: (Name: string, record: ConstructionCheckingModel.ConstructionChecking) => {
				const { color } = trangThaiThucHienEnum.fromEnum(record.CheckingStatus);
				return (
					<Button
						style={{ display: 'inline-flex', alignItems: 'center' }}
						type="link"
						onClick={e => {
							e.stopPropagation();
							navigate(`/chiTietCoSoCongTrinh?id=${record.ConstructionId}&mode=view`);
						}}>
						<CheckingStatusIcon color={color} />
						&nbsp;&nbsp;
						<span className="text-link" title={Name}>
							{Name}
						</span>
					</Button>
				);
			},
		},
		{ title: 'Địa chỉ', dataIndex: 'LocationDetail', key: 'LocationDetail' },
		{
			title: 'Ngày kiểm tra',
			dataIndex: 'CheckDate',
			key: 'CheckDate',
			align: 'center' as const,
			render: (date: string, record: ConstructionCheckingModel.ConstructionChecking) => {
				if (record.IsOutDate) {
					return <span style={{ color: '#FF2000' }}>Quá hạn</span>;
				}

				return date ? formatDate(date) : null;
			},
		},
		{
			title: 'Cán bộ kiểm tra',
			dataIndex: 'CheckerName',
			key: 'CheckerName',
			render: (CheckerName: string) => <span className="text-detail-info">{CheckerName}</span>,
			onCell: (record: ConstructionCheckingModel.ConstructionChecking) => {
				return showDetailCellTableInfo(
					`Cán bộ "${record.CheckerName}"`,
					<DialogThemMoiCbChienSy id={record.CheckerId} mode="view" />,
				);
			},
		},
		{
			title: 'Trạng thái',
			dataIndex: 'CheckingStatus',
			key: 'CheckingStatus',
			align: 'center' as const,
			render: (CheckingStatus: TrangThaiThucHienEnum) => {
				const checkingStatus = trangThaiThucHienEnum.fromEnum(CheckingStatus);
				return <span style={{ color: `#${checkingStatus.color}` }}>{checkingStatus.label}</span>;
			},
		},
		{
			title: 'Thao tác',
			dataIndex: 'Action',
			key: 'Action',
			width: 120,
			align: 'center' as const,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_EDIT) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_DELETE)
			),
			render: (_: any, record: any) => {
				return (
					<>
						<Button
							style={{
								display:
									props.permissionList.includes(PermissionEnum.ADMIN) ||
									props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_EDIT)
										? ''
										: 'none',
							}}
							type="text"
							onClick={e => e.stopPropagation()}>
							<Link
								to={{
									pathname: '/chiTietKeHoachKiemTra',
									search: `?id=${record.Id}&mode=edit`,
								}}>
								<img src="icon/Pen.png" alt="edit" />
							</Link>
						</Button>

						<Button
							style={{
								display:
									props.permissionList.includes(PermissionEnum.ADMIN) ||
									props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_DELETE)
										? ''
										: 'none',
							}}
							type="text"
							onClick={e => handleShowDeleteDialog(e, record.Id, record.ConstructionName)}>
							<img src="icon/Delete.png" alt="delete" />
						</Button>
					</>
				);
			},
		},
	].filter(item => !item.hidden);

	// state of component
	const [provinceId, setProvinceId] = useState('');
	const [dataSources, setDataSources] = useState<ConstructionCheckingModel.ConstructionChecking[]>([]);
	const [totalItems, setTotalItems] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [collapseType, setCollapseType] = useState(CollapseType.DEFAULT);
	const [paramSearch, setParamSearch] = useState({});

	const handleShowDeleteDialog = async (e: any, id: string, name: string) => {
		e.stopPropagation();
		await CustomDialog(
			<DeleteDialog
				name={name}
				id={id}
				deleteService={ConstructionCheckingService.deleteConstructionChecking}
				callback={getDanhSachKeHoachKiemTra.current}
			/>,
			{
				showCloseIcon: true,
				isCanClose: true,
			},
		);
	};

	const getSearchParam = () => {
		return {
			ConstructionName: form.getFieldValue('ConstructionName'),
			...(form.getFieldValue('TinhThanh') && {
				Location: {
					TinhThanh: form.getFieldValue('TinhThanh'),
					QuanHuyen: form.getFieldValue('QuanHuyen'),
				},
			}),

			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
	};

	getDanhSachKeHoachKiemTra.current = async (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const params = getSearchParam();
		try {
			const { Data, TotalItems } = await ConstructionCheckingService.getConstructionCheckingByParam(params, pageOption);
			setDataSources(Data && Data.length > 0 ? Data : []);
			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	useEffect(() => {
		document.title = 'Kế hoạch kiểm tra';
		getDanhSachKeHoachKiemTra.current();
	}, []);

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
		}
		setParamSearch(getSearchParam());
		getDanhSachKeHoachKiemTra.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = () => {
		setParamSearch(getSearchParam());
		getDanhSachKeHoachKiemTra.current(CURRENT_PAGE);
	};

	const handleChangeDatePicker = () => {
		setParamSearch(getSearchParam());
		getDanhSachKeHoachKiemTra.current(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['ConstructionName']);
		}
		setParamSearch(getSearchParam());
		getDanhSachKeHoachKiemTra.current(CURRENT_PAGE);
	};

	const handleRefreshPage = () => {
		form.resetFields();
		setProvinceId('');
		setParamSearch({});
		getDanhSachKeHoachKiemTra.current(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, pageSize: number) => {
		getDanhSachKeHoachKiemTra.current(page, pageSize);
	};

	const handleChangeCollapseType = (type: CollapseType) => {
		setCollapseType(type);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: () => {
				navigate({
					pathname: '/chiTietKeHoachKiemTra',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	const formTable = (
		<>
			<TableComponent
				dataSource={dataSources}
				columns={columns}
				paginator={{
					defaultPageSize: pageSizeDefault,
					showSizeChanger: true,
					onChange: handleChangePage,
					total: totalItems,
					current: currentPage,
					pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
				}}
				onRow={handleShowViewDetail}
			/>
		</>
	);

	const formCollapseType = (
		<>
			<KeHoachKiemTraCollapseType collapseType={collapseType} paramSearch={paramSearch} />
		</>
	);

	const formAction = (
		<>
			<div className={'flex--row'}>
				<ProvinceSelect onChangeProvince={handleChangeProvince} form={form} selectClassName="withMinSelect" />

				<DistrictSelect
					onChangeDistrict={handleChangeDistrict}
					provinceId={provinceId}
					form={form}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
				/>

				<RangePickerInput onChangeDatePicker={handleChangeDatePicker} form={form} className="ml10" />

				<Form.Item name="ConstructionName" className="ml10">
					<Input.Search
						placeholder="Nhập tên cơ sở công trình"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</Form.Item>
			</div>

			<div className="flex--row">
				<Select defaultValue={CollapseType.DEFAULT} style={{ width: '175px' }} onChange={handleChangeCollapseType}>
					{collapseTypes.map(collapse => (
						<Select.Option value={collapse.value} key={collapse.value}>
							<Tooltip title={collapse.label}>
								<span>
									{collapse.icon} {collapse.label}
								</span>
							</Tooltip>
						</Select.Option>
					))}
				</Select>
				<Button type="default" className="ml10" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>

				{props.permissionList.includes(PermissionEnum.CONSTRUCTION_CHECKING_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button className="ml10" type="primary">
						<Link
							to={{
								pathname: '/themMoiKeHoachKiemTra',
							}}>
							<PlusOutlined /> Thêm mới
						</Link>
					</Button>
				) : null}
			</div>
		</>
	);
	return (
		<PageWrapper
			titlePage="Danh sách kế hoạch kiểm tra cơ sở công trình"
			formAction={formAction}
			formTable={collapseType === CollapseType.DEFAULT ? formTable : formCollapseType}
			form={form}
		/>
	);
};

export default KeHoachKiemTra;
