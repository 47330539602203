import { Col, Form, Image, Input, Row } from 'antd';
import React, { useEffect } from 'react';
import { UserInfo } from 'services/api/authenService/usersService';
import './UserInfoDialog.scss';

const UserInfoDialog = (props: { userInfo: UserInfo }) => {
	const [form] = Form.useForm();
	useEffect(() => {
		form.setFieldsValue({ ...props.userInfo });
	}, [form, props.userInfo]);

	return (
		<div className="user-info-container">
			<Row gutter={32}>
				<Col span={10}>
					<Image src={props.userInfo.AvatarLink || 'img/default-avatar.png'} />
				</Col>
				<Col span={14}>
					<Form layout="vertical" form={form}>
						<Form.Item label="Họ và tên" name="FullName">
							<Input placeholder="Họ và tên" disabled />
						</Form.Item>

						<Form.Item label="Số điện thoại" name="PhoneNumber">
							<Input placeholder="Số điện thoại" disabled />
						</Form.Item>

						<Form.Item label="CMND/CCCD" name="MaDonVi">
							<Input placeholder="CMND/CCCD" disabled />
						</Form.Item>

						<Form.Item label="Email" name="Email">
							<Input placeholder="Email" disabled />
						</Form.Item>

						<Form.Item label="Ngày sinh" name="MaDonVi">
							<Input placeholder="Ngày sinh" disabled />
						</Form.Item>
					</Form>
				</Col>
			</Row>
		</div>
	);
};

export default UserInfoDialog;
