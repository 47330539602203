import { t } from 'i18next';

export enum KhuVucEnum {
	KHU_DAN_CU = 'KHU_DAN_CU',
	CO_QUAN_DOANH_NGHIEP = 'CO_QUAN_DOANH_NGHIEP',
	NOI_CONG_CONG = 'NOI_CONG_CONG',
	CHAY_RUNG = 'CHAY_RUNG',
	// KHO_VU_KHI = 'KHO_VU_KHI',
	// NHA_CAO_TANG = 'NHA_CAO_TANG',
	PHUONG_TIEN_GTVT = 'PHUONG_TIEN_GTVT',
}

interface KhuVuc {
	label: string
	value: KhuVucEnum,
}

const _fromEnum = (khuVucEnum: KhuVucEnum): KhuVuc => {
	switch (khuVucEnum) {
		case KhuVucEnum.KHU_DAN_CU:
			return {
				label: t('enum.khuVuc.KHU_DAN_CU'),
				value: KhuVucEnum.KHU_DAN_CU,
			};
		case KhuVucEnum.CO_QUAN_DOANH_NGHIEP:
			return {
				label: t('enum.khuVuc.CO_QUAN_DOANH_NGHIEP'),
				value: KhuVucEnum.CO_QUAN_DOANH_NGHIEP,
			};
		case KhuVucEnum.NOI_CONG_CONG:
			return {
				label: t('enum.khuVuc.NOI_CONG_CONG'),
				value: KhuVucEnum.NOI_CONG_CONG,
			};
		case KhuVucEnum.CHAY_RUNG:
			return {
				label: t('enum.khuVuc.CHAY_RUNG'),
				value: KhuVucEnum.CHAY_RUNG,
			};
		// case KhuVucEnum.KHO_VU_KHI:
		// 	return {
		// 		label: t('enum.khuVuc.KHO_VU_KHI'),
		// 		value: KhuVucEnum.KHO_VU_KHI,
		// 	};
		// case KhuVucEnum.NHA_CAO_TANG:
		// 	return {
		// 		label: t('enum.khuVuc.NHA_CAO_TANG'),
		// 		value: KhuVucEnum.NHA_CAO_TANG,
		// 	};
		case KhuVucEnum.PHUONG_TIEN_GTVT:
			return {
				label: t('enum.khuVuc.PHUONG_TIEN_GTVT'),
				value: KhuVucEnum.PHUONG_TIEN_GTVT,
			};
	}
};

const _getAll = (): KhuVuc[] => {
	return Object.values(KhuVucEnum).map(r => _fromEnum(r as KhuVucEnum));
};

const khuVucEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default khuVucEnum;
