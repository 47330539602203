import { Button, Divider, Form, FormInstance } from 'antd';
import React, { ReactNode } from 'react';
import { DialogState } from 'react-st-modal/build/hooks/useDialog';
import './LayoutSelectedDialog.scss';

interface LayoutSelectedDialogProps {
	label: ReactNode;
	formFilter: ReactNode;
	table: ReactNode;
	dialog: DialogState<any>;
	form: FormInstance<any>;
	onHandleSelected: () => void;
}

const LayoutSelectedDialog = (props: LayoutSelectedDialogProps) => {
	const { label, formFilter, table, dialog, form, onHandleSelected } = props;

	return (
		<div className="LayoutSelectedDialog">
			<div className="flex--row mb10">
				<h5 className="label">{label}</h5>
				<Form className="form-container" form={form} layout="horizontal">
					<div className="flex--row">{formFilter}</div>
				</Form>
			</div>

			{table}

			<Divider />

			<div className="flex--row justify-content--end mt16">
				<Button
					className="mr10"
					type="default"
					onClick={() => {
						dialog.close();
					}}>
					Hủy
				</Button>

				<Button type="primary" style={{ maxWidth: '148px' }} onClick={() => onHandleSelected()}>
					Chọn
				</Button>
			</div>
		</div>
	);
};

export default LayoutSelectedDialog;
