import { t } from 'i18next';

export enum PhuongTienThuyChuaChayEnum {
	TAU_CHUA_CHAY = 'TAU_CHUA_CHAY',
	XUONG_CHUA_CHAY = 'XUONG_CHUA_CHAY',
	CA_NO_CHUA_CHAY = 'CA_NO_CHUA_CHAY',
	CAU_TRUC_NOI_CHUA_CHAY = 'CAU_TRUC_NOI_CHUA_CHAY',
}

interface PhuongTienThuyChuaChay {
	value: PhuongTienThuyChuaChayEnum;
	label: string;
}

const _fromEnum = (phuongTienThuyChuaChayEnum: PhuongTienThuyChuaChayEnum): PhuongTienThuyChuaChay => {
	switch (phuongTienThuyChuaChayEnum) {
		case PhuongTienThuyChuaChayEnum.TAU_CHUA_CHAY:
			return {
				label: t('enum.vehicle.TAU_CHUA_CHAY'),
				value: PhuongTienThuyChuaChayEnum.TAU_CHUA_CHAY,
			};
		case PhuongTienThuyChuaChayEnum.XUONG_CHUA_CHAY:
			return {
				label: t('enum.vehicle.XUONG_CHUA_CHAY'),
				value: PhuongTienThuyChuaChayEnum.XUONG_CHUA_CHAY,
			};
		case PhuongTienThuyChuaChayEnum.CA_NO_CHUA_CHAY:
			return {
				label: t('enum.vehicle.CA_NO_CHUA_CHAY'),
				value: PhuongTienThuyChuaChayEnum.CA_NO_CHUA_CHAY,
			};
		case PhuongTienThuyChuaChayEnum.CAU_TRUC_NOI_CHUA_CHAY:
			return {
				label: t('enum.vehicle.CAU_TRUC_NOI_CHUA_CHAY'),
				value: PhuongTienThuyChuaChayEnum.CAU_TRUC_NOI_CHUA_CHAY,
			};
		
	}
};

const _getAll = (): PhuongTienThuyChuaChay[] => {
	return Object.values(PhuongTienThuyChuaChayEnum).map(r => _fromEnum(r as PhuongTienThuyChuaChayEnum));
};

const _enumFromLabel = (label: string): PhuongTienThuyChuaChayEnum | undefined => {
	switch (label) {
		case t('enum.vehicle.TAU_CHUA_CHAY'):
			return PhuongTienThuyChuaChayEnum.TAU_CHUA_CHAY;
		case t('enum.vehicle.XUONG_CHUA_CHAY'):
			return PhuongTienThuyChuaChayEnum.XUONG_CHUA_CHAY;
		case t('enum.vehicle.CA_NO_CHUA_CHAY'):
			return PhuongTienThuyChuaChayEnum.CA_NO_CHUA_CHAY;
		case t('enum.vehicle.CAU_TRUC_NOI_CHUA_CHAY'):
			return PhuongTienThuyChuaChayEnum.CAU_TRUC_NOI_CHUA_CHAY;
	}
};

const phuongTienThuyChuaChayEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default phuongTienThuyChuaChayEnum;
