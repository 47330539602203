import { SET_COLLAPSE, SET_TITLE } from 'redux/NavBar/navBar.type';
import { ReduxAction } from 'redux/store';

export const setCollapse = (input: boolean): ReduxAction => {
  return {
    type: SET_COLLAPSE,
    payload: input,
  };
};

export const setTitle = (input: string): ReduxAction => {
  return {
    type: SET_TITLE,
    payload: input,
  };
};
