import { Button, Divider, Form, Row, Select, Space, Upload } from 'antd';
import DialogLayout from 'components/shared/layouts/DialogLaypout/DialogLayout';
import { UploadFileOption } from 'constants/uploadFileOption.const';
import loaiCamBienEnum from 'enum/loaiCamBienEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import senSorsService from 'services/api/inventoryService/senSorsService';
import Utils from 'services/api/utils';
import { read, utils } from 'xlsx';
import './CreateMultipleSensorDialog.scss';

interface Error {
	row: number;
	column: string;
	value: string;
	type: string;
}

interface CreateMultipleSensorDialogProps {
	callback: () => void;
	imei?: string;
}

const HEADER = {
	serial: 'Số serial',
	macAddress: 'Địa chỉ Mac *',
	sensorType: 'Loại cảm biến *',
	installedPosition: 'Vị trí lắp đặt',
	implementationDate: 'Ngày triển khai *',
	expirationDate: 'Ngày hết hạn *',
	note: 'Ghi chú',
};

const REQUIRED = 'Điền đầy đủ thông tin.';
const DATE = 'Ngày đúng định dạng (Vd: 20/10/2022).';
const DATE_RANGE = 'Ngày triển khai phải trước ngày hết hạn.';

const CreateMultipleSensorDialog = ({ callback, imei }: CreateMultipleSensorDialogProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	const centerDeviceList = Utils.GetDSThietBi();

	// states
	const [errors, setErrors] = useState<Error[]>([]);
	const [payload, setPayload] = useState<any>([]);

	const proccessExcel = (reader: FileReader) => {
		const result = reader.result;
		const workbook = read(result, { type: 'binary', cellDates: true, cellText: false });
		const firstSheet = workbook.SheetNames[0];
		const rows = utils.sheet_to_json(workbook.Sheets[firstSheet], {
			raw: false,
			dateNF: 'd"/"m"/"yyyy',
		});
		const columns = utils.sheet_to_json(workbook.Sheets[firstSheet], { header: 1 }).at(0);

		return {
			rows,
			columns,
		};
	};

	useEffect(() => {
		if (imei) {
			form.setFieldsValue({ CenterDevice: imei });
		}
	}, [imei, form]);

	const convertToRequestBody = (data: any) => {
		return {
			SerialNumber: data[HEADER.serial],
			Mac: data[HEADER.macAddress],
			InstallLocation: data[HEADER.installedPosition],
			Info: data[HEADER.note],
			Type: loaiCamBienEnum.enumFromLabel(data[HEADER.sensorType]),
			InstalledTime: moment(data[HEADER.implementationDate], 'DD/MM/YYYY'),
			ExpiredTime: moment(data[HEADER.expirationDate], 'DD/MM/YYYY'),
		};
	};

	const validRequire = (row: number, element: any, errors: Error[], column: string) => {
		const value = element[column];
		if (!value) {
			errors.push({
				row: row + 2,
				column,
				type: REQUIRED,
				value: 'Không có giá trị',
			});
		}
	};

	const validDate = (row: number, element: any, errors: Error[], column: string) => {
		const DATE_PATTERN = UploadFileOption.DATE_PATTERN;
		const value = element[column];
		if (!DATE_PATTERN.test(value)) {
			errors.push({
				row: row + 2,
				column: column,
				type: DATE,
				value: value,
			});
		}
	};

	const validRangeDate = (row: number, element: any, errors: Error[]) => {
		const implementDate = element[HEADER.implementationDate];
		const exprireDate = element[HEADER.expirationDate];
		if (moment(implementDate, 'DD/MM/YYYY').isAfter(moment(exprireDate, 'DD/MM/YYYY'))) {
			errors.push({
				row: row + 2,
				column: HEADER.implementationDate,
				type: DATE_RANGE,
				value: implementDate,
			});
		}
	};

	const validMacAddress = (row: number, element: any, errors: Error[]) => {
		const COLUMN = HEADER.macAddress;
		validRequire(row, element, errors, COLUMN);
	};

	const validSensorType = (row: number, element: any, errors: Error[]) => {
		const COLUMN = HEADER.sensorType;
		validRequire(row, element, errors, COLUMN);
	};

	const validImplementationDate = (row: number, element: any, errors: Error[]) => {
		const COLUMN = HEADER.implementationDate;
		validDate(row, element, errors, COLUMN);
		validRequire(row, element, errors, COLUMN);
	};

	const validExpirationDate = (row: number, element: any, errors: Error[]) => {
		const COLUMN = HEADER.expirationDate;
		validDate(row, element, errors, COLUMN);
		validRequire(row, element, errors, COLUMN);
	};

	const validateFileTemplate = (excelColumns: any) => {
		const hasColumn = Object.values(HEADER).every((column: string) => excelColumns.includes(column));
		if (!hasColumn) {
			showNotification('error', t('notification.error'), 'Tập tin tải lên không đúng với tập tin mẫu.');
			return false;
		}
		return true;
	};

	const validateFile = (rows: any) => {
		let errors: Error[] = [];
		for (let index = 0; index < rows.length; index++) {
			const element = rows[index];
			validMacAddress(index, element, errors);
			validSensorType(index, element, errors);
			validImplementationDate(index, element, errors);
			validExpirationDate(index, element, errors);
			validRangeDate(index, element, errors);
		}
		if (errors.length === 0) {
			setErrors([]);
			return true;
		}
		setErrors(errors);
		return false;
	};

	const uploadFileProps = {
		multiple: false,
		maxCount: 1,
		beforeUpload: (file: any): Promise<boolean> => {
			return new Promise(resolve => {
				if (file.size > UploadFileOption.LIMIT_SIZE) {
					showNotification('error', t('notification.error'), 'Kích thước tập tin không quá 20MB');
					return resolve(false);
				}

				if (!UploadFileOption.ALLOWED_EXCEL_FILE.exec(file.name)) {
					showNotification('error', t('notification.error'), 'Tập tin tải lên không đúng định dạng.');
					return resolve(false);
				}

				const reader = new FileReader();

				reader.onload = () => {
					const { rows, columns } = proccessExcel(reader);
					const isValidTemplate = validateFileTemplate(columns);
					const isValidFile = validateFile(rows);
					if (!isValidTemplate) return resolve(false);
					if (!isValidFile) return resolve(false);
					const payload = rows.map(row => convertToRequestBody(row));
					setPayload(payload);
					return resolve(true);
				};

				reader.readAsBinaryString(file);
			});
		},
		onChange(info: any) {
			const { status, name } = info.file;
			if (status === 'done') {
				showNotification('success', t('notification.success'), `${name} đã được tải lên thành công`);
			}
		},
		customRequest(options: any) {
			const { onSuccess } = options;
			onSuccess('ok');
		},
	};

	const handleCreateSensorList = async () => {
		if (errors.length > 0) return;
		if (payload.length === 0) {
			showNotification('error', t('notification.error'), 'Vui lòng đính kèm thêm tập tin');
			return;
		}

		form
			.validateFields()
			.then(() => {
				const newPayload = [...payload].map(item => ({ ...item, PairingDevice: form.getFieldValue('CenterDevice') }));
				senSorsService
					.createSenSorsList(newPayload)
					.then(() => {
						showNotification('success', t('notification.success'), `Đã thêm ${payload.length} cảm biến vào danh sách.`);
						callback();
						dialog.close();
					})
					.catch((error: any) => {
						showNotification('error', t('notification.error'), `Tập tin tải lên thất bại: ${error.response.data}`);
					});
			})
			.catch(err => {
				return;
			});
	};

	const errorTemplate =
		errors.length > 0 ? (
			<div className="error-wrapper">
				<p>Không thể tải file lên do có lỗi từ file Excel. Danh sách lỗi:</p>
				<ul>
					{errors.map((item, index) => (
						<li key={index}>
							[ Dòng | Cột ]: {`[ ${item.row} | ${item.column} ]`} với giá trị: <strong>{item.value}</strong>
							&nbsp;Yêu cầu: {item.type}
						</li>
					))}
				</ul>
			</div>
		) : null;

	return (
		<DialogLayout>
			<div className="step-wrapper">
				<h5 className="mb16">Bước 1. Chọn thiết bị trung tâm</h5>
				<Form layout="horizontal" form={form}>
					<Form.Item
						name="CenterDevice"
						rules={[
							{
								required: true,
								message: 'Vui lòng chọn thiết bị trung tâm',
							},
						]}>
						<Select
							disabled={imei ? true : false}
							style={{ width: '100%' }}
							showSearch
							optionFilterProp="children"
							placeholder="Chọn thiết bị trung tâm"
							allowClear>
							{centerDeviceList}
						</Select>
					</Form.Item>
				</Form>
			</div>
			<div className="step-wrapper">
				<h5 className="mb16">Bước 2. Tải lên file Excel</h5>
				<p>
					Tải lên file Excel để tiến hành nhập liệu hàng loạt. Nếu chưa có file mẫu, vui lòng tải tại đây:
					<Button type="link" href={'/excelTemplate/Cảm biến.xlsx'} download>
						<img src="icon/upload.png" alt="upload" width="18px" height="18px" /> Tải file mẫu
					</Button>
				</p>
				<Upload.Dragger {...uploadFileProps} className="upload-sensor">
					<p className="ant-upload-text">
						<img src="icon/upload.png" alt="upload" />
						<span style={{ color: '#1B56B3' }}>Chọn file</span> hoặc kéo thả file vào đây
					</p>
					<p className="ant-upload-hint">
						<i>Kích thước tập tin không quá 20MB, định dạng xlsx</i>
					</p>
				</Upload.Dragger>
			</div>

			{errorTemplate}

			<Divider className="mb16 mt16" />

			<Row justify="end">
				<Space>
					<Button type="default" onClick={() => dialog.close()}>
						Huỷ
					</Button>
					<Button type="primary" onClick={handleCreateSensorList}>
						Thêm mới
					</Button>
				</Space>
			</Row>
		</DialogLayout>
	);
};

export default CreateMultipleSensorDialog;
