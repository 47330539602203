import { Form, Select } from 'antd';
import { FormInstance } from 'rc-field-form';
import React, { useEffect, useState } from 'react';
import CategoryService from 'services/api/inventoryService/categoryService';

interface CenterDeviceTypeProps {
	onChangeCenterDeviceType?: (value: string) => void;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
	style?: React.CSSProperties;
}

const CenterDeviceTypeSelect = (props: CenterDeviceTypeProps) => {
	const [centerDeviceTypes, setCenterDeviceTypes] = useState<any>([]);
	useEffect(() => {
		CategoryService.listCategorybyCode('THIET_BI').then((data: any) => {
			setCenterDeviceTypes(data.CategoryChild);
		});
	}, []);

	return (
		<Form.Item
			name="DeviceType"
			className={props.formItemClassName}
			rules={props.rules}
			label={props.label}
			style={props.style}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn loại thiết bị"
				optionFilterProp="children"
				className={props.selectClassName}
				onChange={props.onChangeCenterDeviceType}
				disabled={props.disabled}>
				{centerDeviceTypes.map((item: any) => (
					<Select.Option value={item!.Name} key={item!.Code}>
						{item!.Name}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default CenterDeviceTypeSelect;
