import { PlusOutlined } from '@ant-design/icons';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Table } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import TextArea from 'antd/lib/input/TextArea';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import { TableOption } from 'constants/tableOption.const';
import permissionEnum, { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import RoleService from 'services/api/authenService/roleService';
import { UserInfo } from 'services/api/authenService/usersService';
import ProvinceService from 'services/api/provinceService';
import utils from 'services/api/utils';
import Generator from 'services/generator';
import ChiTietPhanQuyenDialog from './Dialog/ChiTietPhanQuyenDialog';
import { NguoiDungTab } from './NguoiDung';

interface ChiTietPhanQuyenDialogProps {
	RoleName?: string;
	Id?: string;
	callback?: () => void;
	userInfo?: UserInfo;
}
const { Option } = Select;
interface RecordType {
	key: string;
	title: string;
	// description: string;
	chosen: boolean;
}

const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const AddVaiTro: FunctionComponent<ChiTietPhanQuyenDialogProps> = (props: ChiTietPhanQuyenDialogProps) => {
	const [form] = Form.useForm();

	const [searchParams] = useSearchParams();
	const Id = useMemo(() => searchParams.get('Id'), [searchParams]);
	const mode = useMemo(() => searchParams.get('mode'), [searchParams]);

	const navigate = useNavigate();
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [permissionTable, setPermissionTable] = useState<any>();
	const [isContinue, setIsContinue] = useState<boolean>(false);
	const [tongSoQuyen, setTongSoQuyen] = useState<number>(PAGE_SIZE);

	const [districts, setDistricts] = useState<JSX.Element[]>([]);

	const tinhThanhscustomer = utils.GetTinhThanhUser();
	const tinhThanhsAdmin = utils.GetTinhThanhAdmin();

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			width: 30,
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
			title: t('label.order'),
		},
		{
			dataIndex: 'label',
			title: 'Tên quyền',
			width: 350,
		},
		{
			align: 'center' as const,
			title: 'Thao tác',
			key: 'action',
			width: 100,
			render: (_: any, record: any) => (
				<Button
					type={'text'}
					onClick={async (e: any) => {
						setPermissionTable(permissionTable.filter((item: any) => item !== record));
					}}>
					<img src="icon/Delete.png" alt="delete" />
				</Button>
			),
		},
	]; // .filter(item => !item.hidden);

	const executeCallback = () => {
		if (props.callback) {
			props.callback();
		}
	};

	useEffect(() => {
		if (Id) {
			RoleService.getRoleById(Id).then((result: any) => {
				const permission: { label: any; value: any }[] = [];
				result.Permissions.forEach((element: any) => {
					permission.push({ label: permissionEnum.fromEnum(element)?.label, value: element });
				});
				setTongSoQuyen(permission?.length);
				setPermissionTable(permission);
				form.setFieldsValue(result);
				handleChangeProvince(result.Locations[0].CityId);
				form.setFieldsValue({
					tinhthanh: result.Locations[0].CityId,
					quanHuyen: result.Locations[0].DistrictId,
				});
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, Id]);

	const finishCreate = (formValues: any) => {
		if (permissionTable?.length > 0) {
			const permisstions: any[] = [];
			permissionTable.forEach((permisstion: any) => {
				permisstions.push(permisstion.value);
			});
			formValues.Permissions = permisstions;
			createLoccation(formValues);
			// Create
			RoleService.createRole(formValues)
				.then(() => {
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_create_desc', { item: formValues.RoleName }),
					);
					if (!isContinue) {
						executeCallback();
						navigateUrl();
					} else {
						form.resetFields(['RoleName']);
					}
				})
				.catch(errors => {
					showNotification('error', t('notification.error'), errors.response.data);
				});
		} else {
			showNotification('error', t('notification.error'), 'Quyền không được để trống!');
		}
	};

	const finishUpdate = (formValues: any) => {
		if (permissionTable?.length > 0) {
			const permisstions: any[] = [];
			permissionTable.forEach((permisstion: any) => {
				permisstions.push(permisstion.value);
			});
			formValues.Permissions = permisstions;
			formValues.Id = Id;
			createLoccation(formValues);
			// update
			RoleService.updateRole(formValues)
				.then(() => {
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: formValues.RoleName }),
					);
					executeCallback();
					navigateUrl();
				})
				.catch(errors => {
					showNotification('error', t('notification.error'), errors.response.data);
				});
		} else {
			showNotification('error', t('notification.error'), 'Quyền không được để trống!');
		}
	};

	const createLoccation = (formValue: any) => {
		formValue.Locations = [
			{
				CityId: formValue.tinhthanh,
				DistrictId: formValue.quanHuyen,
			},
		];
	};

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total} quyền</strong>
			</span>
		);
	};

	const navigateUrl = () => {
		navigate({
			pathname: '/nguoiDung',
			search: `?tab=${NguoiDungTab.PHAN_QUYEN}`,
		});
	};

	function GetPhanQuyen(data: any) {
		setTongSoQuyen(data?.length);
		setPermissionTable(data);
	}

	const onChange = (e: CheckboxChangeEvent) => {
		setIsContinue(e.target.checked);
	};

	const getDistricts = async (provinceId: string) => {
		return await ProvinceService.findDistrictsByProvince(provinceId);
	};

	// Chọn tỉnh thành
	const handleChangeProvince = async (provinceId: any) => {
		form.resetFields(['quanHuyen', 'donViPCCC', 'trucThuoc']);

		setDistricts([]);

		if (provinceId) {
			const districts = await getDistricts(provinceId);
			let quanHuyenList: any[] = [];
			if (
				props.userInfo &&
				props.userInfo.Locations &&
				props.userInfo.Locations.length > 0 &&
				!props?.userInfo?.Permissions?.includes(PermissionEnum.ADMIN)
			) {
				props.userInfo.Locations.forEach((location: any) => {
					if (location.DistrictId) {
						districts.forEach(quanHuyen => {
							if (quanHuyen.Id === location.DistrictId) {
								quanHuyenList.push(quanHuyen);
							}
						});
					} else {
						quanHuyenList = districts;
					}
				});
			} else {
				quanHuyenList = districts;
			}
			setDistricts(Generator.generateOptions(quanHuyenList, 'Id', 'Name'));
		}
	};

	return (
		<Form className={'common-dialog'} form={form} layout={'vertical'} onFinish={Id ? finishUpdate : finishCreate}>
			<Row gutter={32} className="mb15">
				<Col flex={2}>
					<h5 className="heading--8 font-weight--bold mb10">I. Thông tin chung</h5>
					<Form.Item
						label="Vai trò"
						name="RoleName"
						rules={[
							{
								required: true,
								whitespace: true,
								message: 'Tên vai trò không được để trống',
							},
						]}>
						<Input disabled={mode === 'view'} placeholder="Nhập tên vai trò" />
					</Form.Item>
					<Form.Item
						name="tinhthanh"
						label="Tỉnh thành"
						rules={[
							{
								required: true,
								whitespace: true,
								message: 'Tỉnh thành không được bỏ trống',
							},
						]}>
						<Select
							disabled={mode === 'view'}
							showSearch
							optionFilterProp="children"
							placeholder={t('placeholder.tinhThanh')}
							allowClear
							onChange={handleChangeProvince}>
							{props.userInfo?.Permissions?.includes(PermissionEnum.ADMIN) ? tinhThanhsAdmin : tinhThanhscustomer}
						</Select>
					</Form.Item>

					<Form.Item name="quanHuyen" className="mt10" label="Quận Huyện">
						<Select
							showSearch
							optionFilterProp="children"
							placeholder={t('placeholder.quanHuyen')}
							allowClear
							disabled={mode === 'view'}>
							{districts}
						</Select>
					</Form.Item>

					<Form.Item name="Information" label="Ghi chú">
						<TextArea rows={20} placeholder="Nhập ghi chú" disabled={mode === 'view'} />
					</Form.Item>
				</Col>
				<Col flex={3}>
					<h5 className="heading--8 font-weight--bold mb10">II. Phân quyền</h5>
					<div className="flex--row">
						<label className="heading--8">Danh sách quyền của vai trò</label>
						<Button
							disabled={mode === 'view'}
							className="ml10"
							type={'link'}
							onClick={async () => {
								await CustomDialog(
									<ChiTietPhanQuyenDialog
										callback={GetPhanQuyen}
										datachosen={permissionTable}
										userInfo={props.userInfo}
									/>,
									{
										className: 'h60',
										title: 'Thêm quyền',
										showCloseIcon: true,
										isCanClose: true,
									},
								);
							}}>
							<PlusOutlined />
							{t('label.create')}
						</Button>
					</div>

					<Table
						className="table-header-bored"
						rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
						locale={{ emptyText: t('label.emptyData') }}
						showSorterTooltip={false}
						dataSource={permissionTable}
						columns={columns}
						bordered
						style={{ whiteSpace: 'pre' }}
						pagination={{
							showTotal: showTotal,
							pageSize: pageSizeDefault,
							showSizeChanger: true,
							pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
							onChange(page, pageSize) {
								setCurrentPage(page);
								setPageSizeDefault(pageSize);
							},
						}}
					/>
				</Col>
			</Row>

			<Divider />

			<Row justify="end" className={`mt10 ${Id ? '' : 'justify-content--between'}`}>
				<Checkbox style={{ display: Id ? 'none' : '' }} onChange={onChange} disabled={mode === 'view'}>
					Thêm và tiếp tục
				</Checkbox>
				<div>
					<Button type="default" onClick={navigateUrl}>
						{mode ? 'OK' : 'Hủy bỏ'}
					</Button>
					{!mode && (
						<Button className="ml10" htmlType={'submit'} type="primary">
							Cập nhật
						</Button>
					)}
				</div>
			</Row>
		</Form>
	);
};

export default withTranslation()(AddVaiTro);
