import { t } from 'i18next';

export enum ChucVuEnum {
	// [Description("Chiến sĩ")]
	CHIEN_SI = 'CHIEN_SI',
	// [Description("Phó ban")]
	PHO_BAN = 'PHO_BAN',
	// [Description("Trưởng ban")]
	TRUONG_BAN = 'TRUONG_BAN',
	// [Description("Phó phòng")]
	PHO_PHONG = 'PHO_PHONG',
	// [Description("Trưởng phòng")]
	TRUONG_PHONG = 'TRUONG_PHONG',
	// [Description("Phó giám đốc")]
	PHO_GIAM_DOC = 'PHO_GIAM_DOC',
	// [Description("Giám đốc")]
	GIAM_DOC = 'GIAM_DOC',
	// [Description("Đội trưởng")]
	DOI_TRUONG = 'DOI_TRUONG',
	// [Description("Phó đội trưởng")]
	PHO_DOI_TRUONG = 'PHO_DOI_TRUONG',
}

interface ChucVu {
	label: string;
	value: ChucVuEnum;
}

const _fromEnum = (chucVuEnum: ChucVuEnum): ChucVu => {
	switch (chucVuEnum) {
		case ChucVuEnum.CHIEN_SI:
			return {
				label: t('enum.chucVu.CHIEN_SI'),
				value: ChucVuEnum.CHIEN_SI,
			};
		case ChucVuEnum.PHO_BAN:
			return {
				label: t('enum.chucVu.PHO_BAN'),
				value: ChucVuEnum.PHO_BAN,
			};
		case ChucVuEnum.TRUONG_BAN:
			return {
				label: t('enum.chucVu.TRUONG_BAN'),
				value: ChucVuEnum.TRUONG_BAN,
			};
		case ChucVuEnum.PHO_PHONG:
			return {
				label: t('enum.chucVu.PHO_PHONG'),
				value: ChucVuEnum.PHO_PHONG,
			};
		case ChucVuEnum.TRUONG_PHONG:
			return {
				label: t('enum.chucVu.TRUONG_PHONG'),
				value: ChucVuEnum.TRUONG_PHONG,
			};
		case ChucVuEnum.PHO_GIAM_DOC:
			return {
				label: t('enum.chucVu.PHO_GIAM_DOC'),
				value: ChucVuEnum.PHO_GIAM_DOC,
			};
		case ChucVuEnum.GIAM_DOC:
			return {
				label: t('enum.chucVu.GIAM_DOC'),
				value: ChucVuEnum.GIAM_DOC,
			};
		case ChucVuEnum.DOI_TRUONG:
			return {
				label: t('enum.chucVu.DOI_TRUONG'),
				value: ChucVuEnum.DOI_TRUONG,
			};
		case ChucVuEnum.PHO_DOI_TRUONG:
			return {
				label: t('enum.chucVu.PHO_DOI_TRUONG'),
				value: ChucVuEnum.PHO_DOI_TRUONG,
			};
	}
};

const _enumFromLabel = (label: string): ChucVuEnum | undefined => {
	switch (label) {
		case t('enum.chucVu.CHIEN_SI'):
			return ChucVuEnum.CHIEN_SI;
		case t('enum.chucVu.PHO_BAN'):
			return ChucVuEnum.PHO_BAN;
		case t('enum.chucVu.TRUONG_BAN'):
			return ChucVuEnum.TRUONG_BAN;
		case t('enum.chucVu.PHO_PHONG'):
			return ChucVuEnum.PHO_PHONG;
		case t('enum.chucVu.TRUONG_PHONG'):
			return ChucVuEnum.TRUONG_PHONG;
		case t('enum.chucVu.PHO_GIAM_DOC'):
			return ChucVuEnum.PHO_GIAM_DOC;
		case t('enum.chucVu.GIAM_DOC'):
			return ChucVuEnum.GIAM_DOC;
		case t('enum.chucVu.DOI_TRUONG'):
			return ChucVuEnum.DOI_TRUONG;
		case t('enum.chucVu.PHO_DOI_TRUONG'):
			return ChucVuEnum.PHO_DOI_TRUONG;
	}
};

const _getAll = (): ChucVu[] => {
	return Object.values(ChucVuEnum).map(r => _fromEnum(r as ChucVuEnum));
};

const chucVuEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};
export default chucVuEnum;
