import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Tooltip } from 'antd';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import { QuanLyDonViPhongChayTab } from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import pcccUnitService from 'services/api/inventoryService/pcccUnitService';
import provinceService from 'services/api/provinceService';
import { QuanLyDonViPhongChayTabProps } from '../QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import './DonViPhongChayChuaChay.scss';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DonViPhongChayChuaChay: FunctionComponent<QuanLyDonViPhongChayTabProps> = props => {
	const [form] = Form.useForm();
	// const [totalDonViPCCC, setTotalDonViPCCC] = useState<number>(0);
	const [searchParams] = useSearchParams();
	const [TongSoDonvi, setTongSoDonVi] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [, setmadonvi] = useState('');
	const navigate = useNavigate();
	const [dataSourceDonViPCCC, setdataSourceDSDonViPCCC] = useState<any[]>([]);

	const columnsDonViPCCC = [
		{
			align: 'center' as const,
			width: 45,
			title: 'STT',
			dataIndex: 'key',
			key: 'id',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Mã đơn vị',
			dataIndex: 'MaDonVi',
			key: 'MaDonVi',
			ellipsis: {
				showTitle: false,
			},
			render: (MaDonVi: string) => (
				<Tooltip placement="topLeft" title={MaDonVi}>
					{MaDonVi}
				</Tooltip>
			),
		},
		{
			title: 'Tên đơn vị',
			dataIndex: 'TenDonVi',
			key: 'TenDonVi',
			ellipsis: {
				showTitle: false,
			},
			render: (TenDonVi: string) => (
				<Tooltip placement="topLeft" title={TenDonVi}>
					{TenDonVi}
				</Tooltip>
			),
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			key: 'LocationDetail',
			ellipsis: {
				showTitle: false,
			},
			render: (DiaChi: string) => (
				<Tooltip placement="topLeft" title={DiaChi}>
					{DiaChi}
				</Tooltip>
			),
		},
		{
			title: 'Số điện thoại',
			dataIndex: 'SoDienThoaiTrucBan',
			key: 'SoDienThoaiTrucBan',
			ellipsis: {
				showTitle: false,
			},
			render: (SoDienThoaiTrucBan: string) => (
				<Tooltip placement="topLeft" title={SoDienThoaiTrucBan}>
					{SoDienThoaiTrucBan}
				</Tooltip>
			),
		},
		{
			title: 'Tỉnh thành',
			dataIndex: 'TinhThanh',
			key: 'TinhThanh',
			ellipsis: {
				showTitle: false,
			},
			render: (TinhThanh: string) => (
				<Tooltip placement="topLeft" title={TinhThanh}>
					{TinhThanh}
				</Tooltip>
			),
		},
		{
			title: 'Tổng số cán bộ',
			align: 'center' as const,
			width: 130,
			dataIndex: 'SoNhanSuChuaChay',
			key: 'SoNhanSuChuaChay',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			title: 'Tổng phương tiện',
			dataIndex: 'FireTruckCount',
			align: 'center' as const,
			width: 140,
			key: 'FireTruckCount',
			ellipsis: {
				showTitle: false,
			},
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			key: 'action',
			align: 'center' as const,
			width: 100,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.PCCC_UNIT_EDIT) ||
				props.permissionList.includes(PermissionEnum.PCCC_UNIT_DELETE)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.PCCC_UNIT_EDIT)
									? ''
									: 'none',
						}}
						onClick={e => e.stopPropagation()}>
						<Link
							to={{
								pathname: '/chiTietDonViPCCC',
								search: `?id=${record.Id}&mode=edit`,
							}}>
							<img src="icon/Pen.png" alt="edit" />
						</Link>
					</Button>

					<Button
						type="text"
						style={{
							display:
								props.permissionList.includes(PermissionEnum.ADMIN) ||
								props.permissionList.includes(PermissionEnum.PCCC_UNIT_DELETE)
									? ''
									: 'none',
						}}
						onClick={async e => {
							e.stopPropagation();
							await CustomDialog(
								<DeleteDialog
									id={record.Id}
									name={record.TenDonVi}
									callback={timKiemDSDonViPCCC}
									deleteService={pcccUnitService.deletePcccUnit}
								/>,
								{
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<img src="icon/Delete.png" alt="delete" />
					</Button>
				</>
			),
		},
	].filter(item => !item.hidden);

	const _mode = searchParams.get('mode');

	// Khởi tạo data table cho bảng Danh sách cán bộ chiến sĩ
	useEffect(() => {
		if (props.searchData) {
			timKiemDSDonViPCCC(CURRENT_PAGE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [_mode, props.searchData]);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !props.isNavigate) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.PCCC_UNIT_VIEW])) {
				timKiemDSDonViPCCC();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, props.isNavigate]);

	function onChangePage(page: number, pageSize: number) {
		timKiemDSDonViPCCC(page, pageSize);
	}

	const optiondanhsachDonViPCCC = (data: any[], _index = 0) => {
		let array: any[] = [];
		let promises: PromiseLike<any>[] = [];
		if (data.length > 0) {
			data.forEach((item, index) => {
				if (item?.Location !== null) {
					promises.push(
						provinceService.getProvince(item.Location.TinhThanh).then((result: any) => {
							item.TinhThanh = result?.Name;
						}),
					);
				}
				// item.key = ++_index;
				array.push(item);
			});
		}

		Promise.all(promises).then(() => {
			setdataSourceDSDonViPCCC(array);
		});
	};

	// Event click button tìm kiếm Danh sách cán bộ chiến sĩ
	function timKiemDSDonViPCCC(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		let param: any = {
			Name: form.getFieldValue('Name'),
			Location: {},
		};
		if (props.searchData) {
			param = props.searchData;
			param.Name = form.getFieldValue('Name');
		}

		pcccUnitService.searchPcccUnit(param, pageOption).then((res: any) => {
			setTongSoDonVi(res.TotalItems);
			optiondanhsachDonViPCCC(res.Data);
		});
	}

	function handleKeyDown(event: any) {
		if (event.nativeEvent.key === 'Enter') {
			timKiemDSDonViPCCC(CURRENT_PAGE);
		}
	}

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> đơn vị PCCC
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoDonvi}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleClickRefresh = () => {
		form.resetFields();
		props.onResetFormAction && props.onResetFormAction();
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				navigate({
					pathname: '/chiTietDonViPCCC',
					search: `?id=${record.Id}&mode=view`,
				});
			},
		};
	};

	return (
		<Form layout="vertical" name="normal_login" className="content-container flex--fill p0" form={form}>
			<div id={QuanLyDonViPhongChayTab.DANH_SACH_DON_VI_PCCC}>
				<div className={'flex--row justify-content--between mb15'}>
					<div className="search-input">
						<Form.Item name="Name">
							<Input
								placeholder="Nhập tên đơn vị"
								prefix={<SearchOutlined />}
								onChange={event => setmadonvi(event.target.value)}
								onKeyPress={handleKeyDown}
								allowClear
							/>
						</Form.Item>
					</div>

					<div>
						<>
							<Button
								type={'default'}
								onClick={() => {
									handleClickRefresh();
								}}>
								<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
							</Button>

							{props.permissionList.includes(PermissionEnum.PCCC_UNIT_CREATE) ||
							props.permissionList.includes(PermissionEnum.ADMIN) ? (
								<Button className="ml10" type={'primary'}>
									<Link
										to={{
											pathname: '/chiTietDonViPCCC',
											search: `?mode=add`,
										}}>
										<PlusOutlined /> Thêm mới
									</Link>
								</Button>
							) : null}
						</>
					</div>
				</div>

				<TableComponent
					dataSource={dataSourceDonViPCCC}
					columns={columnsDonViPCCC}
					footer={() => handleFooterTable(currentPage, onChangePage)}
					paginator={false}
					onRow={handleShowViewDetail}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(DonViPhongChayChuaChay);
