import localFirebase from 'firebase';
import { Moment } from 'moment';
import notificationsService from 'services/api/handlerService/notifications';
import ApiService, { AUTH_BASE_URL } from 'services/api/index';
import tokenService from 'services/tokenService';

export const prefixAuth = '/users';

export interface LoginParams {
	userName: string;
	password: string;
	info?: string;
}

export interface LoginResult {
	AccessToken: string;
	ExpirationTime: number | Moment;
	RefreshToken: string;
	Code: string;
}

export interface ResetPasswordByUserPayload {
	OldPassword: string;
	NewPassword: string;
	ReNewPassword: string;
}

const _login = (params: LoginParams): Promise<LoginResult> =>
	ApiService(AUTH_BASE_URL)
		.post(`${prefixAuth}/login`, params)
		.then(async ({ data }: { data: LoginResult }) => {
			let firebaseToken: string;
			tokenService.setLoginInfo({ loginResult: data });
			// connect firebase
			await localFirebase
				.messaging()
				.getToken({
					vapidKey:
						process.env.REACT_APP_VAPID_KEY ||
						'BC7LtV5R1edGC1_NNRk3fx3FCyB4U2FkefgheEeLtbgvMAD7bnZIPa1GibgyGSZl0lWr8RbbNHPgJ3VquUZ8grU',
				})
				.then(notificationsService.fbRegister)
				.then(token => (firebaseToken = token))
				.catch(errors => {
					data.Code = errors.code;
				})
				.finally(() => tokenService.setLoginInfo({ firebaseToken }));

			return Promise.resolve(data);
		});

const _logout = async (callback?: Function) => {
	tokenService.getFirebaseToken() &&
		(await notificationsService.fbUnregister(tokenService.getFirebaseToken()!).catch(_ => {
			tokenService.removeLoginInfo();
		}));
	tokenService.removeLoginInfo();
	callback && callback();
};

export interface RefreshTokenParams {
	RefreshToken: string;
	info?: string;
}

const _refreshToken = (params: RefreshTokenParams): Promise<LoginResult> =>
	ApiService(AUTH_BASE_URL)
		.post(`${prefixAuth}/refresh-token`, params)
		.then(({ data }: { data: LoginResult }) => {
			tokenService.setLoginInfo({ loginResult: data, firebaseToken: tokenService.getFirebaseToken() });
			return Promise.resolve(data);
		});

const _forgotPassWord = (input: string): Promise<any> =>
	ApiService(AUTH_BASE_URL).post(`${prefixAuth}/forgot-password`, input);

const _resetPassWord = (input: any): Promise<any> =>
	ApiService(AUTH_BASE_URL).post(`${prefixAuth}/reset-pasword`, input);

const _resetPassWordByAdmin = (input: any): Promise<any> =>
	ApiService(AUTH_BASE_URL).put(`${prefixAuth}/admin-reset-pasword`, input);

const _resetPassWordByUser = (input: ResetPasswordByUserPayload): Promise<any> =>
	ApiService(AUTH_BASE_URL).put(`${prefixAuth}/user-reset-pasword`, input);

const _validTokenReset = (input: string): Promise<any> =>
	ApiService(AUTH_BASE_URL).post(`${prefixAuth}/token-validate`, input);

const AuthService = {
	login: _login,
	logout: _logout,
	refreshToken: _refreshToken,
	isAuthenticated: () => tokenService.getAccessToken() !== undefined,
	forgotPassWord: _forgotPassWord,
	resetPassWord: _resetPassWord,
	resetPassWordByAdmin: _resetPassWordByAdmin,
	resetPassWordByUser: _resetPassWordByUser,
	validTokenReset: _validTokenReset,
};

export default AuthService;
