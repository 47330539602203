import ApiService, { PageOption, REPORT_BASE_URL } from '../../index';
import { Construction } from 'services/api/inventoryService/constructionService';
import ExplosionTypeEnum from 'enum/explosionTypeEnum';
import AreaEnum from 'enum/areaEnum';
import { PcccUnit } from 'services/api/inventoryService/pcccUnitService';
import { WaterPoint } from 'services/api/inventoryService/waterPointService';
import { FireTruck } from 'services/api/inventoryService/fireTruckService';
import moment, { Moment } from 'moment';
import { ProcessStatusEnum } from 'enum/processStatusEnum';
import { SupportUnit } from 'services/api/inventoryService/supportUnitService';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import { NguyenNhanChayEnum } from 'enum/nguyenNhanChayEnum';
import { AlertTypeEnum } from 'enum/alertTypeEnum';
import { CancelToken } from 'axios';

export const prefixFireProtection = '/fireProtection';

// const REPORT_BASE_URL_FAKE = 'http://192.168.1.56:10000/api';
export interface ProcessLog {
	Info: string;
	Time: Moment;
}

export interface FireFocus {
	id: string;
	name: string;
}

export interface FireProtection {
	AlertType?: AlertTypeEnum[];
	Area: AreaEnum;
	Construction?: Construction;
	DeadCount?: 0;
	DeviceName?: string;
	EndTime: Moment;
	EstimateMoney?: 0;
	ExplosionType: ExplosionTypeEnum;
	FireAlarmTime: Moment;
	FireTrucks: FireTruck[];
	FiremanCount?: 0;
	FiringSource?: string;
	Id: string;
	InjuredCount?: 0;
	Location: LocationInfo;
	LocationDetail?: string;
	Name: string;
	NearFireTrucks: FireTruck[];
	NearSupportUnits: SupportUnit[];
	NearWaterPoints: WaterPoint[];
	Note?: string;
	PcccUnit: PcccUnit;
	PhoneNumber: string;
	ProcessLogs: ProcessLog[];
	ProcessStatus: ProcessStatusEnum;
	Reason: NguyenNhanChayEnum;
	ReporterName?: string;
	StartTime: Moment;
	SupportUnits: SupportUnit[];
	WaterPoints: WaterPoint[];
	Latitude: number;
	Longitude: number;
	NguonBao?: string;
	NearPcccUnits: PcccUnit[];
	PcccUnitSupport: PcccUnit[];
	PcccUnitSupports: PcccUnit[];
	SupportUnitLog: SupportUnit[];
	FireTruckLog: FireTruck[];
	PcccUnitSupportLog: PcccUnit[];
}

export interface CreateFireProtectionParams {
	AlertType?: AlertTypeEnum;
	CurrentConstructions?: string;
	CurrentCustomerId?: string;
	Name: string;
	Latitude: number;
	Location: {
		TinhThanh?: string;
		QuanHuyen?: string;
		XaPhuong?: string;
	};
	LocationDetail: string;
	Longitude: number;
	PhoneNumber: string;
	StartTime?: string;
}

const _createFireProtection = (input: CreateFireProtectionParams): Promise<{ data: string }> =>
	ApiService(REPORT_BASE_URL).post(`${prefixFireProtection}/create`, input);

const _deleteFireProtection = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.delete(`${prefixFireProtection}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFireProtectionsByDateSearch = (dateSearch: Date): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/dateSearch=${dateSearch}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFireProtectionsByLocation = (location: string): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/location=${location}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFireProtectionsByName = (name: string): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/name=${name}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFireProtectionsByPcccUnit = (pcccUnitId: string): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/pcccUnitId=${pcccUnitId}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getFireProtectionById = (id: string, radius = 1): Promise<FireProtection> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/id=${id}?radius=${radius}`)
		.then(({ data }: { data: FireProtection }) => Promise.resolve(_map(data)));

const _getFireProtectionForDialog = (id: string): Promise<FireProtection> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/getById=${id}`)
		.then(({ data }: { data: FireProtection }) => Promise.resolve(_map(data)));

const _searchFireProtection = (
	param: any,
	pageOption?: PageOption<FireProtection>,
): Promise<PageOption<FireProtection>> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/search`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: PageOption<FireProtection> }) => Promise.resolve(data));

const _searchCommandCenter = (param = {}, pageOption = {}): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/searchForControlCenter`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listFiringTable = (pageOption: PageOption): Promise<any[]> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/firing`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => {
			return Promise.resolve(data);
		});

const _constructionAlarmWrong = (param: any, pageOption?: PageOption): Promise<PageOption> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/constructionAlarmWrong`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: PageOption }) => {
			return Promise.resolve(data);
		});

const getCountConstructionAlarmWrong = (param = {}, pageOption?: PageOption, token?: CancelToken): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/countConstructionAlarmWrong`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
			cancelToken: token,
		})
		.then(({ data }: { data: any }) => {
			return Promise.resolve(data);
		});

export interface UpdateFireProtectionParams {
	AlertType?: any;
	Area?: AreaEnum;
	BoDieuDongDonViHoTroId?: string;
	BoDieuDongPhuongTienId?: string;
	ConstructionId?: string;
	DeadCount?: number;
	DeviceName?: string;
	DieuDongDonViHoTroId?: string;
	DieuDongPhuongTienId?: string;
	HandlingOfficer?: string;
	EndTime?: Moment | string;
	EstimateMoney?: number;
	ExplosionType?: ExplosionTypeEnum;
	FireAlarmTime?: Moment | string;
	FiremanCount?: number;
	FiringSource?: string;
	Id: string;
	InjuredCount?: number;
	Location?: {
		TinhThanh?: string;
		QuanHuyen?: string;
		XaPhuong?: string;
	};
	LocationDetail?: string;
	Name?: string;
	Note?: string;
	PhoneNumber?: string;
	ProcessLog?: string;
	ProcessStatus?: ProcessStatusEnum;
	Reason?: NguyenNhanChayEnum;
	StartTime?: Moment | string;
	ReasonNote?: string;
	PcccUnitIdToAdd?: string;
	PcccUnitIdToRemove?: string;
	SupportUnitNote?: string;
}

const _updateFireProtection = (input: UpdateFireProtectionParams): Promise<any> => {
	if (input.FireAlarmTime instanceof moment) {
		input.FireAlarmTime = (input.FireAlarmTime as Moment).format();
	}
	if (input.StartTime instanceof moment) {
		input.StartTime = (input.StartTime as Moment).format();
	}
	if (input.EndTime instanceof moment) {
		input.EndTime = (input.EndTime as Moment).format();
	}
	return ApiService(REPORT_BASE_URL)
		.put(`${prefixFireProtection}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const _listFiring = (): Promise<FireProtection[]> =>
	ApiService(REPORT_BASE_URL)
		.get(`${prefixFireProtection}/firing`)
		.then(({ data }: { data: FireProtection[] }) => {
			data.forEach(_map);
			return Promise.resolve(data);
		});

const _map = (input: FireProtection): FireProtection => {
	if (input) {
		input.AlertType = input.AlertType || [];
		input.EndTime = moment(input.EndTime);
		input.FireAlarmTime = moment(input.FireAlarmTime);
		input.ProcessLogs.forEach(p => (p.Time = moment(p.Time)));
		input.StartTime = moment(input.StartTime);
		input.SupportUnits = input.SupportUnits || [];
		input.WaterPoints = input.WaterPoints || [];
		input.NearWaterPoints = input.NearWaterPoints || [];
		input.NearSupportUnits = input.NearSupportUnits || [];
		input.NearFireTrucks = input.NearFireTrucks || [];
		input.FireTrucks = input.FireTrucks || [];
	}
	return input;
};

export interface SearchSummaryReportParams {
	Name: string;
	Location: {
		TinhThanh: string;
		QuanHuyen: string;
		XaPhuong: string;
	};
	PcccUnitId: string;
	FromDate: '2022-02-22T07:47:30.659Z';
	ToDate: '2022-02-22T07:47:30.659Z';
	ConstrctionIds: null;
	FromLossMoney: 0;
	ToLossMoney: 0;
}

const _searchSummaryReport = (requestBody = {}, pageOption = {}): Promise<PageOption<any>> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/summaryReport`, requestBody, {
			headers: { PageOption: JSON.stringify(pageOption) },
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataSummaryResult = (input: SearchSummaryReportParams): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/dataSummaryResult`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchCountFireFighter = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/countFireFighter`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataFireConstruction = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/dataFireConstruction`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataProcessingStatus = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/dataProcessingStatus`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataCountFireConstruction = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/countFireConstruction`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataFireFighter = (input: any, pageOption: PageOption): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/getDataFireFighter`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const exportFileExcel = (requestBody = {}, url: string) => {
	return ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/${url}`, requestBody, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const _deleteAllProtection = (param: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixFireProtection}/clearFiring`, param)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const fireProtectionService = {
	constructionAlarmWrong: _constructionAlarmWrong,
	createFireProtection: _createFireProtection,
	deleteFireProtection: _deleteFireProtection,
	findFireProtectionsByDateSearch: _findFireProtectionsByDateSearch,
	findFireProtectionsByLocation: _findFireProtectionsByLocation,
	findFireProtectionsByName: _findFireProtectionsByName,
	findFireProtectionsByPcccUnit: _findFireProtectionsByPcccUnit,
	getFireProtectionById: _getFireProtectionById,
	listFiring: _listFiring,
	listFiringTable: _listFiringTable,
	searchCommandCenter: _searchCommandCenter,
	searchCountFireFighter: _searchCountFireFighter,
	searchDataCountFireConstruction: _searchDataCountFireConstruction,
	searchDataFireConstruction: _searchDataFireConstruction,
	searchDataFireFighter: _searchDataFireFighter,
	searchDataProcessingStatus: _searchDataProcessingStatus,
	searchDataSummaryResult: _searchDataSummaryResult,
	searchFireProtection: _searchFireProtection,
	searchSummaryReport: _searchSummaryReport,
	updateFireProtection: _updateFireProtection,
	exportFileExcel,
	getFireProtectionForDialog: _getFireProtectionForDialog,
	getCountConstructionAlarmWrong,
	deleteAllProtection: _deleteAllProtection,
};

export default fireProtectionService;
