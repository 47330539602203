import React, {FunctionComponent} from 'react';

const NotFound: FunctionComponent = () => (
  <div className="not-found">
    <div>
      <img
        src="https://www.pngitem.com/pimgs/m/561-5616833_image-not-found-png-not-found-404-png.png"
        alt="not-found"
      />
    </div>
    <a href={'/'} className="link-home">
      Go Home
    </a>
  </div>
);

export default NotFound;