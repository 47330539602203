import { DeleteOutlined, EllipsisOutlined, FormOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Dropdown, Form, Menu, Row, Select, Space, Typography } from 'antd';
import CommonControl from 'components/page/CommonControl';
import EditDutySchedule from 'components/page/QuanLyDonviPhongChay/Tabs/ScheduleDuty/EditDutySchedule';
import ScheduleDutyDeleteModal from 'components/page/QuanLyDonviPhongChay/Tabs/ScheduleDuty/ScheduleDutyDeleteModal';
import ScheduleDutyModal from 'components/page/QuanLyDonviPhongChay/Tabs/ScheduleDuty/ScheduleDutyModal';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import DonViTrucThuocSelect from 'components/shared/form-select/DonViTrucThuocSelect/DonViTrucThuocSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import chucVuEnum, { ChucVuEnum } from 'enum/chucVuEnum';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';
import { TrucThuocEnum } from 'enum/trucThuocEnum';
import { openDialog, showNotification } from 'helpers';
import { TeamInfo } from 'models/duty-schedule/duty-schedule.model';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import DutyScheduleService from 'services/api/businessService/dutyScheduleService';
import pcccUnitService, { PcccUnit } from 'services/api/inventoryService/pcccUnitService';
import { systemProperties } from 'systemProperties';
import './ScheduleDuty.scss';
import { PermissionProps } from '../../QuanLyDonViPhongChay';

interface Duty {
	Id: any;
	phuongTienPCCCNumber: number;
	canBoCSNumber: number;
	Types: {
		Title: string;
		OfficerName: string;
		PhoneNumber: string;
		Position: string;
	}[];
	Name: string;
	TeamId: string;
	LocationDetail: string;
	FireFighterCount: number;
	FireTruckCount: number;
}

const INIT_TEAMS_DATA = [
	{ Title: 'Trực đội 1', OfficeName: null, PhoneNumber: null, Position: null },
	{ Title: 'Trực đội 2', OfficeName: null, PhoneNumber: null, Position: null },
	{ Title: 'Trực đội 3', OfficeName: null, PhoneNumber: null, Position: null },
	{ Title: 'Trực đội 4', OfficeName: null, PhoneNumber: null, Position: null },
];

const ScheduleDuty = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const provinceId = Form.useWatch('TinhThanh', form);
	const trucThuoc = Form.useWatch('TrucThuoc', form);
	const districtId = Form.useWatch('QuanHuyen', form);
	const unitId = Form.useWatch('UnitId', form);
	const date = Form.useWatch('Date', form);

	// states
	const [units, setUnits] = useState<PcccUnit[]>([]);
	const [teams, setTeams] = useState<Array<TeamInfo & { Title: string }>>(INIT_TEAMS_DATA as any);
	const [leaderInfo, setLeaderInfo] = useState<TeamInfo | null>(null);
	const [dutyList, setDutyList] = useState<Duty[]>([]);
	const [dutyScheduleId, setDutyScheduleId] = useState<string | null>(null);

	const [isShowButtonAdd, setIsShowButtonAdd] = useState<boolean>(false);

	const currentDate = moment();
	const isSelectedDistrict = trucThuoc === LoaiHinhDonViEnum.QUAN_HUYEN;
	const isDisabledUnitSelect = !!trucThuoc || !!districtId;
	const isSameOrBeforeDate = currentDate.isSameOrBefore(date, 'date');

	const navigate = useNavigate();

	const handleChangeProvince = () => {
		form.resetFields(['QuanHuyen', 'UnitId', 'TrucThuoc']);
		setIsShowButtonAdd(false);
		setUnits([]);
	};

	const handleChangeDistrict = () => {
		form.resetFields(['UnitId']);
		setIsShowButtonAdd(false);
		setUnits([]);
	};

	const handleOpenScheduleDutyModal = async (teamId?: string, teamName?: string) => {
		if (!unitId) {
			showNotification('info', 'Thông báo', 'Vui lòng chọn đơn vị PCCC');
			return;
		}

		await openDialog(
			<ScheduleDutyModal
				unitId={unitId}
				dutyScheduleId={dutyScheduleId}
				teamId={teamId}
				dutyDate={date}
				callback={getDutySchedule}
			/>,
			teamId ? `Chỉnh sửa "${teamName}"` : 'Thêm đội',
			'schedule-modal',
		);
	};

	const handleOpenDeleteModal = async (teamId: string, teamName: string) => {
		await openDialog(
			<ScheduleDutyDeleteModal
				scheduleId={dutyScheduleId}
				teamId={teamId}
				teamName={teamName}
				callback={getDutySchedule}
			/>,
			'',
			'',
		);
	};

	const handleOpenEditDutyScheduleModal = async () => {
		if (!unitId) {
			showNotification('info', 'Thông báo', 'Vui lòng chọn đơn vị PCCC');
			return;
		}

		if (!date) {
			showNotification('info', 'Thông báo', 'Vui lòng ngày trực');
			return;
		}
		await openDialog(
			<EditDutySchedule unitId={unitId} scheduleId={dutyScheduleId} dutyDate={date} callback={getDutySchedule} />,
			'Chỉnh sửa lịch trực',
			'schedule-modal',
		);
	};

	const getDutySchedule = useCallback(() => {
		const payload = {
			PcccUnitId: unitId,
			SearchDate: date,
		};
		DutyScheduleService.getDutyScheduleSonLaWithFilter(payload).then(data => {
			if (data) {
				const { Team1, Team2, Team3, Team4, LeadSchedule, DutyList, Id } = data;

				const teams = [];
				teams.push(
					{ ...Team1, Title: 'Trực đội 1' },
					{ ...Team2, Title: 'Trực đội 2' },
					{ ...Team3, Title: 'Trực đội 3' },
					{ ...Team4, Title: 'Trực đội 4' },
				);

				const dutyList = DutyList?.map(dutyItem => {
					const { CommandSchedule, DailySchedule, LeadSchedule, ...extraFields } = dutyItem;
					const types = [
						{ ...LeadSchedule, Title: 'Trực chỉ huy' },
						{ ...DailySchedule, Title: 'Trực ban' },
						{ ...CommandSchedule, Title: 'Trực điều lệnh' },
					];
					extraFields.canBoCSNumber = data?.PcccUnitDto?.SoNhanSuChuaChay ?? 0;
					extraFields.phuongTienPCCCNumber = data?.PcccUnitDto?.FireTruckCount ?? 0;
					extraFields.Id = data.PcccUnitId;
					return { ...extraFields, Types: types };
				});

				setTeams(teams);
				setLeaderInfo(LeadSchedule);
				setDutyList(dutyList);
				setDutyScheduleId(Id);
			} else {
				setTeams(INIT_TEAMS_DATA as any);
				setLeaderInfo(null);
				setDutyList([]);
				setDutyScheduleId(null);
			}
		});
	}, [unitId, date]);

	useEffect(() => {
		getDutySchedule();
	}, [getDutySchedule]);

	useEffect(() => {
		if (provinceId && trucThuoc) {
			const payload = {
				Location: {
					TinhThanh: provinceId,
					QuanHuyen: districtId,
				},
				UnderType: trucThuoc,
			};

			if (trucThuoc === TrucThuocEnum.QUAN_HUYEN && !districtId) {
				setUnits([]);
			} else {
				pcccUnitService.searchPcccUnit(payload).then(units => {
					setUnits(units);
				});
			}
		}
	}, [provinceId, districtId, trucThuoc]);

	const onchangeUnit = (event: string) => {
		setIsShowButtonAdd(event !== undefined);
	};

	return (
		<div className="ScheduleDuty">
			<div className="flex--row form-container">
				<Form form={form} initialValues={{ Date: currentDate }}>
					<div className="flex--row">
						<ProvinceSelect
							form={form}
							bordered={false}
							label={<strong>Tỉnh</strong>}
							style={{ marginBottom: 0 }}
							onChangeProvince={handleChangeProvince}
						/>
						<DonViTrucThuocSelect
							formItemClassName="ml10"
							form={form}
							bordered={false}
							label={<strong>Trực thuộc</strong>}
							style={{ marginBottom: 0 }}
							disabled={!provinceId}
							onChangeDonViTrucThuoc={() => {
								form.resetFields(['QuanHuyen', 'UnitId']);
								setIsShowButtonAdd(false);
							}}
						/>
						{isSelectedDistrict && (
							<DistrictSelect
								formItemClassName="ml10"
								form={form}
								bordered={false}
								label={<strong>Quận huyện</strong>}
								style={{ marginBottom: 0 }}
								provinceId={provinceId}
								onChangeDistrict={handleChangeDistrict}
							/>
						)}
						<Form.Item name="UnitId" className="ml10" style={{ marginBottom: 0 }} label={<strong>Đơn vị</strong>}>
							<Select
								showSearch
								allowClear
								placeholder="Chọn đơn vị pccc"
								className="withMinSelect"
								disabled={!isDisabledUnitSelect}
								onChange={onchangeUnit}
								bordered={false}>
								{units.map(unit => (
									<Select.Option key={unit.Id} value={unit.Id}>
										{unit.TenDonVi}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item className="ml10" name="Date" style={{ marginBottom: 0 }} label={<strong>Ngày</strong>}>
							<DatePicker
								bordered={false}
								locale={CommonControl.localeVi}
								placeholder="Chọn ngày"
								format={systemProperties.dateFormat}
								style={{ width: '100%' }}
								defaultValue={currentDate as any}
								value={currentDate as any}
							/>
						</Form.Item>
					</div>
				</Form>

				{isSameOrBeforeDate && isShowButtonAdd && (
					<Button type="primary" icon={<PlusOutlined />} onClick={() => handleOpenScheduleDutyModal()}>
						Thêm đội
					</Button>
				)}
			</div>
			{dutyList?.length === 0 && trucThuoc !== TrucThuocEnum.PC07 && (
				<Space direction="vertical" size={16}>
					<Card title="Các thao tác để tạo lịch trực:">
						<p>Bước 1: Chọn tỉnh thành</p>
						<p>Bước 2: Chọn trực thuộc</p>
						<p>Bước 3: Chọn đơn vị</p>
						<p>Bước 4: Chọn ngày tạo lịch trực</p>
						<p style={{ display: 'flex' }}>
							Bước 5: Bấm nút:
							<p style={{ fontWeight: 'bold' }}>&nbsp; Thêm đội &nbsp;</p> đối với đội trực thuộc quận huyện hoặc{' '}
							<p style={{ fontWeight: 'bold' }}>&nbsp; chỉnh sửa &nbsp;</p> đối với đội trực thuộc PC07
						</p>
					</Card>
				</Space>
			)}

			{trucThuoc === TrucThuocEnum.PC07 && (
				<>
					<div className="team-under-pc07">
						{isSameOrBeforeDate && (
							<div className="card-icon" title="chỉnh sửa" onClick={() => handleOpenEditDutyScheduleModal()}>
								<FormOutlined />
							</div>
						)}
						<div className="leader-info">
							<Typography.Title level={5}>Trực lãnh đạo</Typography.Title>
							<table>
								<tbody>
									<tr>
										<th>Đồng chí</th>
										<td>{leaderInfo?.OfficerName ?? '-'}</td>
									</tr>
									<tr>
										<th>Số điện thoại</th>
										<td>{leaderInfo?.PhoneNumber ?? '-'}</td>
									</tr>
									<tr>
										<th>Chức vụ</th>
										<td>
											{leaderInfo?.Position ? chucVuEnum.fromEnum(leaderInfo?.Position as ChucVuEnum).label : '-'}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<Row gutter={16}>
							{teams?.map((team, index) => (
								<Col span={6} key={index}>
									<div className="team-info-item">
										<Typography.Title level={5}>{team?.Title}</Typography.Title>

										<table>
											<tbody>
												<tr>
													<th>Cán bộ trực</th>
													<td>{team?.OfficerName ?? '-'}</td>
												</tr>
												<tr>
													<th>Số điện thoại</th>
													<td>{team?.PhoneNumber ?? '-'}</td>
												</tr>
												<tr>
													<th>Chức vụ</th>
													<td>{team?.Position ? chucVuEnum.fromEnum(team?.Position as ChucVuEnum).label : '-'}</td>
												</tr>
											</tbody>
										</table>
									</div>
								</Col>
							))}
						</Row>
					</div>
				</>
			)}

			<Row gutter={[16, 16]}>
				{dutyList?.map(dutyItem => (
					<Col span={12} key={dutyItem.TeamId}>
						<div className="team-item">
							{isSameOrBeforeDate && (
								<Dropdown
									className="card-icon"
									placement="bottomRight"
									overlay={
										<Menu className="card-menu">
											<Menu.Item
												title="Chỉnh sửa"
												icon={<FormOutlined />}
												key="3"
												onClick={() => handleOpenScheduleDutyModal(dutyItem.TeamId, dutyItem.Name)}>
												Chỉnh sửa
											</Menu.Item>
											<Menu.Item
												title="Xoá"
												icon={<DeleteOutlined />}
												onClick={() => handleOpenDeleteModal(dutyItem.TeamId, dutyItem.Name)}
												key="4">
												Xoá
											</Menu.Item>
										</Menu>
									}>
									<EllipsisOutlined style={{ cursor: 'pointer' }} />
								</Dropdown>
							)}

							<Typography.Title level={5}>{dutyItem?.Name}</Typography.Title>
							<Row gutter={16} className="mt16">
								<Col span={12}>
									<div className="team-info">
										<div className="title">Địa chỉ</div>
										<div className="value">{dutyItem?.LocationDetail}</div>
									</div>
								</Col>
								<Col span={12}>
									<div className="team-info">
										<div className="title">Lực lượng</div>
										<div
											className="value"
											onClick={e => {
												navigate({
													pathname: '/chiTietDonViPCCC',
													search: `?id=${dutyItem?.Id}&mode=view`,
												});
											}}>
											{dutyItem?.canBoCSNumber ?? 0} cán bộ chiến sĩ/ {dutyItem?.phuongTienPCCCNumber ?? 0} phương tiện
										</div>
									</div>
								</Col>
							</Row>

							<Row gutter={16} className="mt16">
								{dutyItem.Types?.map((type, index) => (
									<Col span={8} key={index}>
										<div className="team-type">
											<p className="title">{type?.Title}</p>
											<table>
												<tbody>
													<tr>
														<th>Cán bộ trực</th>
														<td>{type?.OfficerName}</td>
													</tr>
													<tr>
														<th>SĐT</th>
														<td>{type?.PhoneNumber}</td>
													</tr>
													<tr>
														<th>Chức vụ</th>
														<td>{type?.Position ? chucVuEnum.fromEnum(type?.Position as ChucVuEnum)?.label : '-'}</td>
													</tr>
												</tbody>
											</table>
										</div>
									</Col>
								))}
							</Row>
						</div>
					</Col>
				))}
			</Row>
		</div>
	);
};

export default ScheduleDuty;
