import ApiService, { INVENTORY_BASE_URL, PageOption, REPORT_BASE_URL } from '../../index';

export const prefixreportConstruction = '/reportConstructionChecking';
export const prefixConstruction = '/construction';
// const REPORT_BASE_URL_FaKE = 'http://192.168.1.38:10000/api/'
export interface SearchConstructionCheckingParams {
	Location: {
		TinhThanh: string;
		QuanHuyen: string;
		XaPhuong: string;
	};
	PcccUnitId: string;
	JobType: 0;
	ConstructionType: 0;
	FromDate: '2022-02-22T10:11:03.661Z';
	ToDate: '2022-02-22T10:11:03.661Z';
}
const _searchDataSummaryResult = (input: any, pageOption: PageOption): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportConstruction}/dataSummaryResult`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataConstructionChecking = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportConstruction}/dataConstructionChecking`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchCountConstructionChecking = (input: any): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportConstruction}/countConstructionChecking`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDataConstruction = (input: any, pageOption: PageOption): Promise<any> =>
	ApiService(REPORT_BASE_URL)
		.post(`${prefixreportConstruction}/dataConstruction`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const searchDataConstructionDetail = (requestBody: any = {}, pageOption: any = {}): Promise<PageOption<any>> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/dataConstructionDetail`, requestBody, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const exportFileExcel = (requestBody: any = {}, url: string): Promise<any> => {
	return ApiService(REPORT_BASE_URL)
		.post(`${prefixreportConstruction}/${url}`, requestBody, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const ReportConstructionCheckingService = {
	searchDataSummaryResult: _searchDataSummaryResult,
	searchDataConstructionChecking: _searchDataConstructionChecking,
	searchCountConstructionChecking: _searchCountConstructionChecking,
	searchDataConstruction: _searchDataConstruction,
	searchDataConstructionDetail,
	exportFileExcel,
};

export default ReportConstructionCheckingService;
