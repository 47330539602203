import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { LoaiDonViEnum } from 'enum/loaiDonViEnum';
import { LocationInfo } from 'services/api/inventoryService/locationService';

export const prefixSupportUnit = '/supportUnit';

export interface SupportUnit {
	Id: string;
	IsDieuDong: boolean;
	Latitude: number;
	LoaiDonVi: LoaiDonViEnum;
	Location: LocationInfo;
	LocationDetail?: string;
	MaDonVi: string;
	PcccUnitId: string;
	SoDienThoai: string;
	Tendonvi: string;
	Longitude: number;
	PcccUnitName?: string;
}

const createSupportUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSupportUnit}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));
const insertListSupportUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSupportUnit}/insertListSupportUnit`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const deleteSupportUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixSupportUnit}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

export interface SearchSupportUnitParams {
	Name?: string;
	Location?: {
		TinhThanh?: string;
		QuanHuyen?: string;
		XaPhuong?: string;
	};
}

const searchSupportUnit = (input: SearchSupportUnitParams, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSupportUnit}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const SearchSupportUnitByLocation = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSupportUnit}/getByPcccUnitAndLocation`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const findSupportUnitByName = (name: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixSupportUnit}/name=${name}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const getSupportUnit = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixSupportUnit}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const listSupportUnit = (pageOption?: PageOption): Promise<SupportUnit[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixSupportUnit}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: SupportUnit[] }) => Promise.resolve(data));

const updateSupportUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixSupportUnit}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const exportFileExcel = (payload = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSupportUnit}/ExportByPcccUnitAndLocation`, payload, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

export default {
	createSupportUnit,
	deleteSupportUnit,
	searchSupportUnit,
	findSupportUnitByName,
	getSupportUnit,
	listSupportUnit,
	updateSupportUnit,
	SearchSupportUnitByLocation,
	insertListSupportUnit,
	exportFileExcel
};
