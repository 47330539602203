import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Pagination, Select } from 'antd';
import { PermissionProps, QuanLyBaoCaoTab } from 'components/page/QuanLyBaoCao/QuanLyBaoCao';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import PercentComponent from 'components/shared/PercentComponent/PercentComponent';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Construction } from 'services/api/inventoryService/constructionService';
import DeviceService, {
	DeviceSummaryReport,
	DeviceSummaryReportRequest,
} from 'services/api/inventoryService/deviceService';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const ThietBiLapDat: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const searchDeviceSummaryReport = useRef<any>({});
	const [searchParams] = useSearchParams();

	// state of component
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSources, setDataSources] = useState<DeviceSummaryReport[]>([]);
	const [totalItems, setTotalItems] = useState(0);
	const [disableExportButton, setDisableExportButton] = useState(true);
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);

	// table columns
	const tableColumns = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên công trình',
			key: 'constructionName',
			dataIndex: 'Construction',
			render: (construction: Construction) => {
				return (
					<NavigateButton
						url={`/chiTietCoSoCongTrinh?id=${construction.Id}&mode=view`}
						name={construction?.TenCoSoCongTrinh}
					/>
				);
			},
		},
		{
			title: 'Địa chỉ',
			key: 'address',
			dataIndex: 'Construction',
			render: (construction: Construction) => {
				return <span>{construction?.LocationDetail}</span>;
			},
		},
		{
			title: 'Đơn vị PCCC quản lý',
			key: 'TenDonVi',
			dataIndex: 'Construction',
			render: (construction: Construction) => <span className="text-detail-info">{construction?.PcccUnitName}</span>,
			onCell: (record: DeviceSummaryReport) => {
				return showDetailCellTableInfo(
					record.Construction.PcccUnitName ? record.Construction.PcccUnitName : null,
					<DialogThemMoiDonViPccc userInfo={props.userInfo} id={record.Construction.DonViPcccID} mode="view" />,
					'dialog-water-supply-add',
				);
			},
		},
		{
			align: 'right' as const,
			title: 'Tổng số thiết bị',
			key: 'DeviceSum',
			dataIndex: 'DeviceSum',
		},
		{
			title: 'Tỉ trọng thiết bị',
			children: [
				{
					align: 'right' as const,
					title: 'Thiết bị trung tâm',
					dataIndex: 'DeviceCount',
					key: 'DeviceCount',
					render: (deviceCount: number, record: DeviceSummaryReport) => {
						return <PercentComponent count={deviceCount} percent={record.DevicePercent} />;
					},
				},
				{
					align: 'right' as const,
					title: 'Chuông đèn',
					dataIndex: 'BellCount',
					key: 'BellCount',
					render: (BellCount: number, record: DeviceSummaryReport) => {
						return <PercentComponent count={BellCount} percent={record.BellPercent} />;
					},
				},
				{
					align: 'right' as const,
					title: 'Cảm biến khói',
					dataIndex: 'SmokeSensorCount',
					key: 'SmokeSensorCount',
					render: (smokeSensorCount: number, record: DeviceSummaryReport) => {
						return <PercentComponent count={smokeSensorCount} percent={record.SmokeSensorPercent} />;
					},
				},
				{
					align: 'right' as const,
					title: 'Cảm biến nhiệt',
					dataIndex: 'TemperatureSensorCount',
					key: 'TemperatureSensorCount',
					render: (temperatureSensorCount: number, record: DeviceSummaryReport) => {
						return <PercentComponent count={temperatureSensorCount} percent={record.TemperatureSensorPercent} />;
					},
				},
			],
		},
	];

	const getRequestBody = () => {
		return {
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			Location: {
				CityId: form.getFieldValue('TinhThanh'),
				DistrictId: form.getFieldValue('QuanHuyen'),
			},
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
	};

	searchDeviceSummaryReport.current = async (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const requestBody: DeviceSummaryReportRequest = getRequestBody();
		setDisableExportButton(!requestBody.Location?.DistrictId);
		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		try {
			const { Data: deviceSummaryReports, TotalItems } = await DeviceService.searchDeviceSummaryReport(
				requestBody,
				pageOption,
			);
			if (deviceSummaryReports && deviceSummaryReports.length > 0) {
				const newDataSources = deviceSummaryReports.map((item, index) => ({ ...item, Id: index }));
				setDataSources(newDataSources);
			} else {
				setDataSources([]);
			}
			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		searchDeviceSummaryReport.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});
		searchDeviceSummaryReport.current(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, pageSize: number) => {
		searchDeviceSummaryReport.current(page, pageSize);
	};

	const handleExportFileExcel = async () => {
		const exportFilePayload = getRequestBody();
		DeviceService.exportFileExcel(exportFilePayload)
			.then(data => {
				downloadExcelFile(data, 'Fire_Device_Report.xlsx');
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	};

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> cơ sở công trình
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={totalItems}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	useEffect(() => {
		if (searchParams.get('tab') === QuanLyBaoCaoTab.THIET_BI_LAP_DAT) {
			document.title = t('quanLyBaoCao.baoCaoThietBiLapDat');
			searchDeviceSummaryReport.current();
		}
	}, [searchParams]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={handleChangeDistrict}
				/>

				<UnitManagerSelect
					name="PcccUnitId"
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					location={location}
					onChangeUnitManager={() => searchDeviceSummaryReport.current(CURRENT_PAGE)}
				/>
			</div>
			{props.permissionList.includes(PermissionEnum.REPORT_DEVICE_EXPORT) ||
			props.permissionList.includes(PermissionEnum.ADMIN) ? (
				<Button
					type="primary"
					icon={<DownloadOutlined />}
					onClick={handleExportFileExcel}
					disabled={dataSources.length === 0 || disableExportButton}>
					Xuất Excel
				</Button>
			) : null}
		</>
	);

	const formTable = (
		<TableComponent
			columns={tableColumns}
			dataSource={dataSources}
			paginator={false}
			footer={() => handleFooterTable(currentPage, handleChangePage)}
		/>
	);

	return (
		<PageWrapper
			titlePage="Báo cáo tổng hợp về thiết bị lắp đặt"
			formAction={formAction}
			formTable={formTable}
			form={form}
		/>
	);
};

export default ThietBiLapDat;
