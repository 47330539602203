import ApiService, { FACT_BASE_URL } from '../../index';

export const prefixReportTest = '/report';

const _getbyEmei = (imei: string): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.get(imei ? `${prefixReportTest}/test-result-detail?imei=${imei}` : `${prefixReportTest}/test-result-detail`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchDetail = (input: any): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.post(`${prefixReportTest}/report-error-detail`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchSummary = (input: any): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.post(`${prefixReportTest}/report-error-summary`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

export default {
	getbyEmei: _getbyEmei,
	searchDetail: _searchDetail,
	searchSummary: _searchSummary,
};
