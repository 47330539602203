import { Form, Input } from 'antd';
import Table, { ColumnsType } from 'antd/lib/table';
import LayoutSelectedDialog from 'components/shared/layouts/LayoutSelectedDialog/LayoutSelectedDialog';
import NganhLinhVucSelect from 'components/shared/form-select/NganhLinhVucSelect/NganhLinhVucSelect';
import { TableOption } from 'constants/tableOption.const';
import nganhLinhVucEnum from 'enum/nghanhLinhVucEnum';
import { PhoneNumberType } from 'enum/phone-number-type/phone-number-type.enum';
import trucThuocEnum, { TrucThuocEnum } from 'enum/trucThuocEnum';
import { getPhoneNumberByType } from 'helpers';
import { t } from 'i18next';
import { CommonModel } from 'models/common.model';
import React, { useEffect, useRef, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import ConstructionService from 'services/api/inventoryService/constructionService';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = 10;

interface ChonCoSoCongTrinhDialogProps {
	selectedConstructionIds: string[];
	onGetSelectedConstructions: (selectedConstructionIds: string[]) => void;
	location?: CommonModel.Location | null;
	constructionCheckingPlanId?: string;
	custommerId?: string;
}

const ChonCoSoCongTrinhDialog = (props: ChonCoSoCongTrinhDialogProps) => {
	const dialog = useDialog();
	const [form] = Form.useForm();
	const getDanhSachCoSoCongTrinh = useRef<any>(null);

	const columns: ColumnsType<any> = [
		{ title: 'Tên cơ sở công trình', dataIndex: 'TenCoSoCongTrinh', key: 'TenCoSoCongTrinh' },
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			key: 'LocationDetail',
		},
		{
			title: 'Ngành, lĩnh vực',
			dataIndex: 'NghanhLinhVuc',
			key: 'NghanhLinhVuc',
			render: NghanhLinhVuc => {
				return NghanhLinhVuc ? nganhLinhVucEnum.fromEnum(NghanhLinhVuc).label : null;
			},
			width: 150,
		},
		{
			title: 'Chủ cơ sở',
			dataIndex: 'ChuCSCT',
			key: 'ChuCSCT',
			width: 200,
		},
		{
			title: 'SĐT chủ cơ sở ',
			dataIndex: 'SoDienThoai',
			key: 'SoDienThoai',
			render: (SoDienThoai: CommonModel.PhoneNumber[]) => {
				const ownerConstructionPhone = getPhoneNumberByType(SoDienThoai, PhoneNumberType.CHU_CO_SO_CONG_TRINH);
				return ownerConstructionPhone?.PhoneNumber;
			},
		},
		{
			title: 'Đã kiểm tra/tổng số lần kiểm tra cần thực hiện                        ',
			dataIndex: 'TrucThuoc',
			key: 'TrucThuoc',
			render: (TrucThuoc: TrucThuocEnum) => (TrucThuoc ? trucThuocEnum.fromEnum(TrucThuoc).label : null),
		},
	];

	// state of component
	const [dataSources, setDataSources] = useState<any>([]);
	const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>(props.selectedConstructionIds);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [totalItems, setTotalItems] = useState(0);

	const getSearchParam = () => {
		return {
			Name: form.getFieldValue('Name')?.trim(),
			IndustryFields: form.getFieldValue('IndustryFields'),
			Location: props.location,
			ConstructionCheckingPlanId: props.constructionCheckingPlanId,
			CustomerId: props.custommerId,
		};
	};

	getDanhSachCoSoCongTrinh.current = async (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const params = getSearchParam();
		try {
			const { Data, TotalItems } = props.custommerId
				? await ConstructionService.searchConstruction(params, pageOption)
				: await ConstructionService.searchConstructionForChecking(params, pageOption);
			setDataSources(Data!);
			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
		setSelectedRowKeys(newSelectedRowKeys);
	};

	const rowSelection = {
		selectedRowKeys,
		onChange: onSelectChange,
		preserveSelectedRowKeys: true,
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['Name']);
		}
		getDanhSachCoSoCongTrinh.current(CURRENT_PAGE);
	};

	const handleSelectedConstructions = () => {
		props.onGetSelectedConstructions(selectedRowKeys as string[]);
		dialog.close();
	};

	useEffect(() => {
		getDanhSachCoSoCongTrinh.current();
	}, []);

	const formFilter = (
		<>
			<NganhLinhVucSelect
				form={form}
				selectClassName="withMinSelect"
				onChangeNganhLinhVuc={() => getDanhSachCoSoCongTrinh.current(CURRENT_PAGE)}
			/>
			<Form.Item name="Name" className="ml10">
				<Input.Search
					placeholder="Nhập tên cơ sở công trình"
					allowClear
					onSearch={(value: string) => handleSearchName(value)}
				/>
			</Form.Item>
		</>
	);

	const table = (
		<Table
			rowKey={record => record.Id}
			rowSelection={rowSelection}
			dataSource={dataSources}
			columns={columns}
			locale={{ emptyText: t('label.emptyData') }}
			pagination={{
				defaultPageSize: pageSizeDefault,
				showSizeChanger: true,
				onChange: (page: number, pageSize: number) => getDanhSachCoSoCongTrinh.current(page, pageSize),
				total: totalItems,
				current: currentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);

	return (
		<LayoutSelectedDialog
			label={
				<span>
					Đã chọn <strong>{selectedRowKeys.length}</strong> Cơ sở công trình
				</span>
			}
			formFilter={formFilter}
			table={table}
			dialog={dialog}
			form={form}
			onHandleSelected={handleSelectedConstructions}
		/>
	);
};

export default withTranslation()(ChonCoSoCongTrinhDialog);
