import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, formatDate, showNotification } from 'helpers';
import { t } from 'i18next';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { PageOption } from 'services/api';
import rescueWorkService from 'services/api/businessService/rescueWorkService';
import provinceService from 'services/api/provinceService';
import { PermissionProps } from '../CongTacPhongChayVaChcn';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const CongTacCuuHoCuuNan: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNaviagte = navigateFromDashboardSelector.getNavigateStatus();

	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);
	const [dataSourceDSCHCN, setdataSourceDSCHCN] = useState<any>([]);
	const [totalItem, setTotalItem] = useState<number>(0);
	const [provinceId, setProvinceId] = useState('');

	const columnsDSCuuHoCuuNan = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên vụ CNCH',
			dataIndex: 'Name',
			key: 'Name',
		},
		{
			title: 'Tỉnh thành',
			dataIndex: 'TinhThanh',
			key: 'TinhThanh',
			width: 200,
		},
		{
			title: 'Quận huyện',
			dataIndex: 'QuanHuyen',
			key: 'QuanHuyen',
			width: 200,
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			key: 'LocationDetail',
		},
		{
			align: 'center' as const,
			title: 'Thời gian nhận tin báo',
			dataIndex: 'ReportDate',
			key: 'ReportDate',
			render: (date: string) => (date ? formatDate(date, 'DD/MM/YYYY HH:mm:ss') : null),
		},
		{
			align: 'center' as const,
			title: 'Thời gian bắt đầu xử lý',
			dataIndex: 'StartTime',
			key: 'StartTime',
			render: (date: string) => (date ? formatDate(date, 'DD/MM/YYYY HH:mm:ss') : null),
		},
		{
			align: 'center' as const,
			title: 'Thời gian kết thúc xử lý',
			dataIndex: 'EndTime',
			key: 'EndTime',
			render: (date: string) => (date ? formatDate(date, 'DD/MM/YYYY HH:mm:ss') : null),
		},
		{
			align: 'center' as const,
			width: 100,
			title: 'Thao tác',
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.RESCUE_WORK_EDIT) ||
				props.permissionList.includes(PermissionEnum.RESCUE_WORK_DELETE)
			),
			render: (_: any, record: any) => (
				<>
					{(props.permissionList.includes(PermissionEnum.ADMIN) ||
						props.permissionList.includes(PermissionEnum.RESCUE_WORK_EDIT)) && (
						<Button type="text" onClick={e => e.stopPropagation()}>
							<Link
								to={{
									pathname: '/cuuHoCuuNan',
									search: `?id=${record.Id}`,
								}}>
								<img src="icon/Pen.png" alt="edit" />
							</Link>
						</Button>
					)}

					{(props.permissionList.includes(PermissionEnum.ADMIN) ||
						props.permissionList.includes(PermissionEnum.RESCUE_WORK_DELETE)) && (
						<Button
							type="text"
							onClick={async e => {
								e.stopPropagation();
								await CustomDialog(
									<DeleteDialog
										id={record.Id}
										name={record.Name}
										callback={SearchDanhSachCHCN}
										deleteService={rescueWorkService.deleteRescueWork}
									/>,
								);
							}}>
							<img src="icon/Delete.png" alt="delete" />
						</Button>
					)}
				</>
			),
		},
	].filter(item => !item.hidden);

	const getSearchRequestPayload = () => {
		return {
			Name: form.getFieldValue('Name'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			},
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
		};
	};

	const convertDataSources = async (data: any) => {
		const {
			Location: { TinhThanh, QuanHuyen },
		} = data;
		const province = await provinceService.getProvince(TinhThanh);
		const district = await provinceService.getDistrict({ cityId: TinhThanh, districtId: QuanHuyen });
		return {
			...data,
			TinhThanh: province?.Name,
			QuanHuyen: district?.Name,
		};
	};

	async function SearchDanhSachCHCN(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};

		const param = getSearchRequestPayload();

		try {
			const { Data, TotalItems } = await rescueWorkService.searchPropagates(param, pageOption);
			if (Data && Data.length > 0) {
				const newDataSources = await Promise.all(Data.map(async item => await convertDataSources(item)));
				setdataSourceDSCHCN(newDataSources);
			} else {
				setdataSourceDSCHCN([]);
			}
			setTotalItem(TotalItems ? TotalItems : 0);
		} catch (error) {}
	}

	const handleChangeProvince = (value: any) => {
		form.resetFields(['QuanHuyen']);
		setProvinceId(value);

		SearchDanhSachCHCN(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.resetFields(['Name']);
		}

		SearchDanhSachCHCN(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, pageSize: number) => {
		SearchDanhSachCHCN(page, pageSize);
	};

	const handleExportFileExcel = () => {
		const exportFilePayload = getSearchRequestPayload();
		rescueWorkService
			.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Vu_Cuu_Nan_Cuu_Ho.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin lên thất bại');
			});
	};

	useEffect(() => {
		document.title = t('congTacPhongChayVaCHCN.congTacCHCN');
	}, []);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !isNaviagte) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.RESCUE_WORK_VIEW])) {
				SearchDanhSachCHCN();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, isNaviagte]);

	useEffect(() => {
		if (isNaviagte) {
			setProvinceId(provinceIdFromDashboard);
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
			});

			SearchDanhSachCHCN(CURRENT_PAGE);
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isNaviagte, form, provinceIdFromDashboard, districtIdFromDashboard]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={() => SearchDanhSachCHCN(CURRENT_PAGE)}
				/>

				<Form.Item className="ml10" name="dateRange">
					<RangePickerInput onChangeDatePicker={() => SearchDanhSachCHCN(CURRENT_PAGE)} form={form} />
				</Form.Item>

				<Form.Item className="ml10" name="Name">
					<Input.Search
						placeholder="Nhập tên vụ CNCH"
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</Form.Item>
			</div>

			<div className="flex--row">
				{props.permissionList.includes(PermissionEnum.RESCUE_WORK_EXPORT) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						type="default"
						icon={<DownloadOutlined />}
						onClick={handleExportFileExcel}
						disabled={dataSourceDSCHCN.length === 0}>
						Xuất Excel
					</Button>
				) : null}
				{props.permissionList.includes(PermissionEnum.RESCUE_WORK_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button className="ml10" type={'primary'}>
						<Link to="/cuuHoCuuNan" type="submit">
							<PlusOutlined /> Thêm mới
						</Link>
					</Button>
				) : null}
			</div>
		</>
	);

	const formTable = (
		<TableComponent
			dataSource={dataSourceDSCHCN}
			columns={columnsDSCuuHoCuuNan}
			paginator={{
				defaultPageSize: pageSizeDefault,
				showSizeChanger: true,
				onChange: handleChangePage,
				total: totalItem,
				current: currentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);

	return (
		<PageWrapper titlePage="Danh sách vụ cứu nạn cứu hộ" formAction={formAction} formTable={formTable} form={form} />
	);
};

export default withTranslation()(CongTacCuuHoCuuNan);
