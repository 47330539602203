import i18n from 'i18next';
import React, { FunctionComponent } from 'react';
import ReactExport, { ExcelSheetData } from 'react-data-export';

interface ExportExcelProps {
	element?: JSX.Element;
	data: any[];
}

const _ExportCanBoChienSy: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.hoTen') },
				{ title: i18n.t('label.ngaySinh') },
				{ title: i18n.t('label.soDienThoai') },
				{ title: i18n.t('label.chucVu') },
				{ title: i18n.t('label.capBac') },
				{ title: i18n.t('label.trucThuoc') },
			],
			data: props.data.map((r, index) => {
				return [index + 1, r.HoTen, r.NgaySinh, r.SoDienThoai, r.ChucVu, r.CapBac, r.TrucThuoc];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Cán bộ chiến sỹ'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Cán bộ chiến sỹ'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportPhuongTien: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.tenPhuongTien') },
				{ title: i18n.t('label.soHieu') },
				{ title: i18n.t('label.bienSo') },
				{ title: i18n.t('label.soDienThoai') },
				{ title: i18n.t('label.loaiPhuongTien') },
				{ title: i18n.t('label.tenDonVi') },
				{ title: i18n.t('label.imei') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.TenPhuongTien,
					r.SoHieuPhuongTien,
					r.BienSo,
					r.SoDienThoai,
					r.LoaiPhuongTien,
					r.TenDonViPCCC,
					r.Imei,
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Phương tiện PCCC'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Phương tiện'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportDonViHoTro: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.maDonVi') },
				{ title: i18n.t('label.tenDonVi') },
				{ title: i18n.t('label.loaiDonVi') },
				{ title: i18n.t('label.soDienThoai') },
				{ title: i18n.t('label.quanHuyen') },
				{ title: i18n.t('label.tinhThanh') },
			],
			data: props.data.map((r, index) => {
				return [index + 1, r.MaDonVi, r.Tendonvi, r.LoaiDonVi, r.SoDienThoai, r.QuanHuyen, r.TinhThanh];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Đơn vị hỗ trợ'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Đơn vị hỗ trợ'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportDiemLayNuoc: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.tenDiemLayNuoc') },
				{ title: i18n.t('label.donViQuanLy') },
				{ title: i18n.t('label.loaiDiemLayNuoc') },
				{ title: i18n.t('label.diaChi') },
				{ title: i18n.t('label.moTa') },
			],
			data: props.data.map((r, index) => {
				return [index + 1, r.TenDiemLayNuoc, r.TenDonViQuanLy, r.LoaiDiemLayNuoc, r.LocationDetail, r.MoTa];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Điểm lấy nước'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Điểm lấy nước'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportDiemChay: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.tenDiemChay') },
				{ title: i18n.t('label.soDienThoai') },
				{ title: i18n.t('label.coSoCongTrinh') },
				{ title: i18n.t('label.tinhThanh') },
				{ title: i18n.t('label.diaChi') },
				{ title: i18n.t('label.trangThai') },
				{ title: i18n.t('label.thoiGianBaoChay') },
				{ title: i18n.t('label.startTime') },
				{ title: i18n.t('label.endTime') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.tendiemchay,
					r.sodienthoai,
					r.cosocongtrinh,
					r.tinhthanh,
					r.diachi,
					r.trangthai,
					r.thoigianbaochay,
					r.thoigianbatdauxuly,
					r.thoigianketthucxuly,
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Danh sách điểm cháy'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'DiemChay'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportCamBien: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.SerialNumber') },
				{ title: i18n.t('label.Mac') },
				{ title: i18n.t('label.Firmware') },
				{ title: i18n.t('label.status') },
				{ title: i18n.t('label.Battery') },
				{ title: i18n.t('label.Temperature') },
				{ title: i18n.t('label.Smoke') },
				{ title: i18n.t('label.UpdateTime') },
				{ title: i18n.t('label.PairingDevice') },
				{ title: i18n.t('label.InstallLocation') },
				{ title: i18n.t('label.info') },
				{ title: i18n.t('label.Type') },
				{ title: i18n.t('label.ExpiredTime') },
				{ title: i18n.t('label.InstalledTime') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.SerialNumber,
					r.Mac,
					r.Firmware,
					r.Status,
					r.Battery,
					r.Temperature,
					r.Smoke,
					r.UpdateTime,
					r.PairingDevice,
					r.InstallLocation,
					r.Info,
					r.Type,
					r.ExpiredTime,
					r.InstalledTime,
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Danh sách cảm biến'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Cảm biến'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportDanhSachCoSoCongTrinh: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.MaCoSoCongTrinh') },
				{ title: i18n.t('label.TenCoSoCongTrinh') },
				{ title: i18n.t('label.diaChi') },
				{ title: i18n.t('label.tinhThanh') },
				{ title: i18n.t('label.FullName') },
				{ title: i18n.t('label.SDTChuCoSo') },
				{ title: i18n.t('label.SDTXacMinh') },
				{ title: i18n.t('label.DonViPcccID') },
				{ title: i18n.t('label.HuongVaoTiepCanCSCT') },
				{ title: i18n.t('label.LoaiHinhSoHuuEnum') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.MaCoSoCongTrinh,
					r.TenCoSoCongTrinh,
					r.LocationDetail,
					r.TinhThanh,
					r.ChuCSCT,
					r.OwnerConstructionPhone ? r.OwnerConstructionPhone : '',
					r.ConfirmPhone ? r.ConfirmPhone : '',
					r.DonViPcccID,
					r.HuongVaoTiepCanCSCT,
					r.LoaiHinhSoHuu,
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile hideElement={true} filename={'Danh sách cơ sở công trình'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'DiemChay'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportDanhSachThietBiLapDat: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.TenCoSoCongTrinh') },
				{ title: i18n.t('label.diaChi') },
				{ title: i18n.t('label.SDTChuCoSo') },
				{ title: i18n.t('label.SDTXacMinh') },
				{ title: i18n.t('label.DonViPcccID') },
				{ title: i18n.t('label.LoaiHinhSoHuuEnum') },
				{ title: i18n.t('label.DonViPcccID') },
				{ title: i18n.t('label.HuongVaoTiepCanCSCT') },
				{ title: i18n.t('label.deviceNumber') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.TenCoSoCongTrinh ? r.TenCoSoCongTrinh : '',
					r.DiaChi ? r.DiaChi : '',
					r.OwnerConstructionPhone ? r.OwnerConstructionPhone : '',
					r.ConfirmPhone ? r.ConfirmPhone : '',
					r.PcccUnitName ? r.PcccUnitName : '',
					r.LoaiHinhSoHuu ? r.LoaiHinhSoHuu : '',
					r.DeviceCount ? r.DeviceCount : '',
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile element={props.element} filename={'Báo cáo thiết bị lắp đặt'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Thiết bị lắp đặt'} />
		</ReactExport.ExcelFile>
	);
};

const _ExportBaoCaoCoSoCongTrinh: FunctionComponent<ExportExcelProps> = (props) => {
	const dataSet: ExcelSheetData[] = [
		{
			columns: [
				{ title: 'STT' },
				{ title: i18n.t('label.TenCoSoCongTrinh') },
				{ title: i18n.t('label.namThanhLap') },
				{ title: i18n.t('label.loaiCoSo') },
				{ title: i18n.t('label.diaChi') },
				{ title: i18n.t('label.tinhThanh') },
				{ title: i18n.t('label.quanHuyen') },
				{ title: i18n.t('label.tinhChatHoatDong') },
				{ title: i18n.t('label.DonViPcccID') },
				{ title: i18n.t('label.ngayKiemTraGanNhat') },
				{ title: i18n.t('label.ketQuaKiemTraGanNhat') },
				{ title: i18n.t('label.canBoKiemTra') },
			],
			data: props.data.map((r, index) => {
				return [
					index + 1,
					r.TenCoSoCongTrinh,
					r.NamThanhLap,
					r.LoaiHinhSoHuu,
					r.DiaChi,
					r.TinhThanh,
					r.QuanHuyen,
					r.LoaiHinhHoatDong,
					r.PcccUnitName,
					r.CheckedDate,
					r.ResultCheckedDate,
					r.Checker,
				];
			}),
		},
	];

	return (
		<ReactExport.ExcelFile element={props.element} filename={'Báo cáo cơ sở công trình'}>
			<ReactExport.ExcelFile.ExcelSheet dataSet={dataSet} name={'Danh sách'} />
		</ReactExport.ExcelFile>
	);
};

const ExcelUtils = {
	ExportCanBoChienSy: _ExportCanBoChienSy,
	ExportDiemChay: _ExportDiemChay,
	ExportCamBien: _ExportCamBien,
	ExportDanhSachCoSoCongTrinh: _ExportDanhSachCoSoCongTrinh,
	ExportDanhSachThietBiLapDat: _ExportDanhSachThietBiLapDat,
	ExportBaoCaoCoSoCongTrinh: _ExportBaoCaoCoSoCongTrinh,
	ExportPhuongTien: _ExportPhuongTien,
	ExportDonViHoTro: _ExportDonViHoTro,
	ExportDiemLayNuoc: _ExportDiemLayNuoc,
};

export default ExcelUtils;
