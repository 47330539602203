import ApiService, { AUTH_BASE_URL, PageOption } from 'services/api/index';
import { UserInfo } from '../usersService';

const prefixUsersLogin = '/usersLogin';

const _getUsersLogin = (pageOption?: PageOption): Promise<UserInfo[]> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsersLogin}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: UserInfo[] }) => {
			return Promise.resolve(data);
		});

const _searchUsersLogin = (
	userName: string,
	fromDate?: string,
	toDate?: string,
	pageOption?: PageOption,
): Promise<UserInfo[]> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixUsersLogin}/search?userName=${userName}&fromDate=${fromDate}&toDate=${toDate}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: UserInfo[] }) => {
			return Promise.resolve(data);
		});

const UsersLoginService = {
	getUsersLogin: _getUsersLogin,
	searchUsersLogin: _searchUsersLogin,
};

export default UsersLoginService;
