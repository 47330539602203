import { t } from 'i18next';

export enum NganhLinhVucEnum {
	CONG_TY_SAN_XUAT = 'CONG_TY_SAN_XUAT',
	BAO_CHI = 'BAO_CHI',
	BENH_VIEN = 'BENH_VIEN',
	CHO = 'CHO',
	DET_MAY = 'DET_MAY',
	IN_AN = 'IN_AN',
	NGHIEN_CUU = 'NGHIEN_CUU',
	DIEN_LUOI = 'DIEN_LUOI',
}

interface NganhLinhVuc {
	value: NganhLinhVucEnum;
	label: string;
}

const _fromEnum = (nganhLinhVucEnum: NganhLinhVucEnum): NganhLinhVuc => {
	switch (nganhLinhVucEnum) {
		case NganhLinhVucEnum.CONG_TY_SAN_XUAT:
			return {
				label: t('enum.nganhLinhVuc.CONG_TY_SAN_XUAT'),
				value: NganhLinhVucEnum.CONG_TY_SAN_XUAT,
			};
		case NganhLinhVucEnum.BAO_CHI:
			return {
				label: t('enum.nganhLinhVuc.BAO_CHI'),
				value: NganhLinhVucEnum.BAO_CHI,
			};
		case NganhLinhVucEnum.BENH_VIEN:
			return {
				label: t('enum.nganhLinhVuc.BENH_VIEN'),
				value: NganhLinhVucEnum.BENH_VIEN,
			};
		case NganhLinhVucEnum.CHO:
			return {
				label: t('enum.nganhLinhVuc.CHO'),
				value: NganhLinhVucEnum.CHO,
			};
		case NganhLinhVucEnum.DET_MAY:
			return {
				label: t('enum.nganhLinhVuc.DET_MAY'),
				value: NganhLinhVucEnum.DET_MAY,
			};
		case NganhLinhVucEnum.IN_AN:
			return {
				label: t('enum.nganhLinhVuc.IN_AN'),
				value: NganhLinhVucEnum.IN_AN,
			};
		case NganhLinhVucEnum.NGHIEN_CUU:
			return {
				label: t('enum.nganhLinhVuc.NGHIEN_CUU'),
				value: NganhLinhVucEnum.NGHIEN_CUU,
			};
		case NganhLinhVucEnum.DIEN_LUOI:
			return {
				label: t('enum.nganhLinhVuc.DIEN_LUOI'),
				value: NganhLinhVucEnum.DIEN_LUOI,
			};
	}
};

const _getAll = (): NganhLinhVuc[] => {
	return Object.values(NganhLinhVucEnum).map((r) => _fromEnum(r as NganhLinhVucEnum));
};

const _enumFromLabel = (label: string): NganhLinhVucEnum | undefined => {
	switch (label) {
		case t('enum.nganhLinhVuc.BAO_CHI'):
			return NganhLinhVucEnum.BAO_CHI;
		case t('enum.nganhLinhVuc.BENH_VIEN'):
			return NganhLinhVucEnum.BENH_VIEN;
		case t('enum.nganhLinhVuc.CHO'):
			return NganhLinhVucEnum.CHO;
		case t('enum.nganhLinhVuc.CONG_TY_SAN_XUAT'):
			return NganhLinhVucEnum.CONG_TY_SAN_XUAT;
		case t('enum.nganhLinhVuc.DET_MAY'):
			return NganhLinhVucEnum.DET_MAY;
		case t('enum.nganhLinhVuc.DIEN_LUOI'):
			return NganhLinhVucEnum.DIEN_LUOI;
		case t('enum.nganhLinhVuc.IN_AN'):
			return NganhLinhVucEnum.IN_AN;
		case t('enum.nganhLinhVuc.NGHIEN_CUU'):
			return NganhLinhVucEnum.NGHIEN_CUU;
	}
};

const nganhLinhVucEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default nganhLinhVucEnum;
