import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';

export const prefixPcccUnit = '/pcccUnit';

export interface PcccUnit {
	CanBoQuanLy?: string;
	DiaChi: string;
	Email?: string;
	Id: string;
	Latitude: number;
	LoaiHinhDonVi: LoaiHinhDonViEnum;
	Location: LocationInfo;
	Longitude: number;
	MaDonVi: string;
	PhoneNumber: string;
	SoDiemLayNuocQuanLy?: number;
	SoDienThoaiTrucBan?: string;
	SoNhanSuChuaChay?: number;
	SoNhanSuQuanLy?: number;
	TenDonVi: string;
	LocationDetail: string;
	HandleFiring: boolean;
	FireTruckCount: number;
	FireFighterCount: number;
	Name?: string;
	IsDieuDong?: boolean;
	IsAvailable?: boolean;
	AcceptType?: number;
	Teams?: any;
}

const _createPcccUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixPcccUnit}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deletePcccUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixPcccUnit}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findPcccUnitByLocation = (locationId: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixPcccUnit}/locationId=${locationId}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _findPcccUnitByType = (type: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixPcccUnit}/type=${type}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getPcccUnit = (unitId: string): Promise<PcccUnit> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixPcccUnit}/id=${unitId}`)
		.then(({ data }: { data: PcccUnit }) => Promise.resolve(data));

const _listPcccUnit = (pageOption?: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixPcccUnit}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updatePcccUnit = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixPcccUnit}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchPcccUnit = (input: any, pageOption?: PageOption): Promise<PcccUnit[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixPcccUnit}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchPcccUnitcomboboxItem = (input: any, pageOption?: PageOption): Promise<PcccUnit[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixPcccUnit}/comboboxItem`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchPcccUnitForSelect = (input: any): Promise<PcccUnit[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixPcccUnit}/comboboxItem`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _instanceOf = (object: any): object is PcccUnit => {
	return object && 'LoaiHinhDonVi' in object;
};

export default {
	createPcccUnit: _createPcccUnit,
	deletePcccUnit: _deletePcccUnit,
	findPcccUnitByLocation: _findPcccUnitByLocation,
	findPcccUnitByType: _findPcccUnitByType,
	getPcccUnit: _getPcccUnit,
	listPcccUnit: _listPcccUnit,
	updatePcccUnit: _updatePcccUnit,
	searchPcccUnit: _searchPcccUnit,
	searchPcccUnitForSelect: _searchPcccUnitForSelect,
	instanceOf: _instanceOf,
	searchPcccUnitcomboboxItem: _searchPcccUnitcomboboxItem,
};
