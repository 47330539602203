import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Table, Tag } from 'antd';
import TableAction from 'components/shared/TableAction/TableAction';
import { TableOption } from 'constants/tableOption.const';
import permissionEnum, { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import RoleService from 'services/api/authenService/roleService';
import AddVaiTro from './AddVaiTro';
import DialogDelete from './Dialog/DialogDelete';
import { NguoiDungTab, PermissionProps } from './NguoiDung';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const PhanQuyen: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const [searchParams] = useSearchParams();
	const [TongSoDiemChay, setTongSoDiemChay] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const navigate = useNavigate();

	const child = searchParams.get('child');

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			width: 70,
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
			title: t('label.order'),
		},
		{
			dataIndex: 'RoleName',
			title: 'Vai trò',
			width: 234,
		},
		{
			dataIndex: 'Permission',
			title: 'Danh sách quyền',
			width: 'auto',
			render: (Permissions: any) => (
				<>
					{Permissions.map((Permission: any) => {
						return <Tag key={Permission}>{Permission}</Tag>;
					})}
				</>
			),
		},
		{
			dataIndex: 'Information',
			title: 'Ghi chú',
			width: 300,
		},
		{
			align: 'center' as const,
			title: 'Thao tác',
			key: 'action',
			width: 152,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.ROLE_ACCOUNT_EDIT) ||
				props.permissionList.includes(PermissionEnum.ROLE_ACCOUNT_DELETE)
			),
			render: (_: any, record: any) => (
				<>
					<Button
						type="text"
						onClick={async (e: any) => {
							e.stopPropagation();
							RoleService.createRole({
								RoleName: record.RoleName + '- Copy',
								Information: record.Information,
								Permissions: record.Permissions,
							})
								.then(() => {
									showNotification(
										'success',
										t('notification.success'),
										t('notification.success_create_desc', { item: record.RoleName }),
									);
									GetPhanQuyen();
								})
								.catch(errors => {
									showNotification('error', t('notification.error'), errors.response.data);
								});
						}}>
						<img src="icon/add_book.png" alt="edit" />
					</Button>
					<TableAction
						isViewButtonEdit={
							props.permissionList.includes(PermissionEnum.ROLE_ACCOUNT_EDIT) ||
							props.permissionList.includes(PermissionEnum.ADMIN)
						}
						isViewButtonDelete={
							props.permissionList.includes(PermissionEnum.ROLE_ACCOUNT_DELETE) ||
							props.permissionList.includes(PermissionEnum.ADMIN)
						}
						// isViewButtonAdd={
						// 	props.permissionList.includes(PermissionEnum.USER_ACCOUNT_EDIT) ||
						// 	props.permissionList.includes(PermissionEnum.ADMIN)
						// }
						isVaitro={true}
						editTitle={`Chỉnh sửa phân quyền "${record.RoleName}`}
						EditDialog={record.Id}
						DeleteDialog={
							<DialogDelete
								Id={record.Id}
								Name={record.RoleName}
								mode={NguoiDungTab.PHAN_QUYEN}
								callback={GetPhanQuyen}
							/>
						}
					/>
				</>
			),
		},
	].filter(item => !item.hidden);

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.phanQuyen');
		NavBarDispatcher.setTitle(`${t('nav.phanQuyen')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.USER_ACCOUNT_VIEW])) {
				GetPhanQuyen();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	function GetPhanQuyen(page = CURRENT_PAGE, pageSize = PAGE_SIZE) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const param = { RoleName: form.getFieldValue('RoleName') };
		RoleService.searchRole(param, pageOption)
			.then((res: any) => {
				setTongSoDiemChay(res.TotalItems);
				optionPhanQuyen(res ? res.Data : []);
			})
			.catch();
	}

	function onChangePage(page: number, pageSize: number) {
		GetPhanQuyen(page, pageSize);
	}

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			GetPhanQuyen(currentPage, pageSizeDefault);
		}
	};

	const optionPhanQuyen = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		// let PermissionArray: any = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any) => {
				let PermissionArray: any = [];
				item.Permissions.forEach((permission: any) => {
					if (PermissionArray.length < 6) {
						if (PermissionArray.length === 5) {
							PermissionArray.push('+' + (item.Permissions.length - 5));
						} else if (PermissionArray.length > 0) {
							PermissionArray.push('\n' + permissionEnum.fromEnum(permission)?.label);
						} else {
							PermissionArray.push(permissionEnum.fromEnum(permission)?.label);
						}
					}
				});
				item.Permission = PermissionArray;
				array.push(item);
			});
		}
		Promise.all(promises)
			.then(() => {
				setUsers(array);
			})
			.catch();
	};

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total} vai trò</strong>
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongSoDiemChay}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				navigate({ pathname: `/nguoiDung?tab=phanQuyen&child=AddVaiTro&Id=${record.Id}&mode=view` });
			},
		};
	};

	const PhanQuyen = () => {
		return (
			<Form id="quanLyPhanQuyen" layout="vertical" name="normal_login" form={form}>
				<div className={'flex--row'}>
					<Form.Item name="RoleName" className="withMinSelect">
						<Input placeholder="Tên vai trò" prefix={<SearchOutlined />} onKeyPress={handleKeyDown} allowClear />
					</Form.Item>
					{props.permissionList.includes(PermissionEnum.ROLE_ACCOUNT_CREATE) ||
					props.permissionList.includes(PermissionEnum.ADMIN) ? (
						<Button
							className="ml10"
							type={'primary'}
							onClick={() => {
								navigate({ pathname: `/nguoiDung?tab=phanQuyen&child=AddVaiTro` });
							}}>
							<PlusOutlined />
							{t('label.create')}
						</Button>
					) : null}
				</div>
				<div className={'break-line mt10'} />
				<div className={'border-quan-ly-cong-tac-pc text--center'}>
					<h2 className={'mb0'}>{t('nav.phanQuyen')}</h2>
				</div>
				<Table
					className="table-header-bored"
					rowClassName={(record, index) => (index % 2 === 0 ? '' : 'table-row-dark')}
					locale={{ emptyText: t('label.emptyData') }}
					showSorterTooltip={false}
					onRow={handleShowViewDetail}
					dataSource={users}
					pagination={false}
					columns={columns}
					bordered
					style={{ whiteSpace: 'break-spaces' }}
					footer={() => handleFooterTable(currentPage, onChangePage)}
				/>
			</Form>
		);
	};

	const renderSelectedTab = (): JSX.Element => {
		switch (child) {
			case undefined:
			default:
				return PhanQuyen();
			case 'AddVaiTro':
				return <AddVaiTro callback={GetPhanQuyen} userInfo={props.userInfo} />;
		}
	};

	return renderSelectedTab();
};

export default withTranslation()(PhanQuyen);
