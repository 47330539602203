import { t } from 'i18next';

export enum LoaiKhachHangEnum {
	CO_QUAN_CHUC_NANG = 'CO_QUAN_CHUC_NANG',
	DAI_LY = 'DAI_LY',
}

interface LoaiKhachHang {
	value: LoaiKhachHangEnum;
	label: string;
}

const _fromEnum = (loaiKhachHangEnum: LoaiKhachHangEnum): LoaiKhachHang => {
	switch (loaiKhachHangEnum) {
		case LoaiKhachHangEnum.CO_QUAN_CHUC_NANG:
			return {
				label: t('enum.loaiKhachHang.CO_QUAN_CHUC_NANG'),
				value: LoaiKhachHangEnum.CO_QUAN_CHUC_NANG,
			};
		case LoaiKhachHangEnum.DAI_LY:
			return {
				label: t('enum.loaiKhachHang.DAI_LY'),
				value: LoaiKhachHangEnum.DAI_LY,
			};
	}
};

const _getAll = (): LoaiKhachHang[] => {
	return Object.values(LoaiKhachHangEnum).map(r => _fromEnum(r as LoaiKhachHangEnum));
};

const _enumFromLabel = (label: string): LoaiKhachHangEnum | undefined => {
	switch (label) {
		case t('enum.loaiKhachHang.CO_QUAN_CHUC_NANG'):
			return LoaiKhachHangEnum.CO_QUAN_CHUC_NANG;
		case t('enum.loaiKhachHang.DAI_LY'):
			return LoaiKhachHangEnum.DAI_LY;
	}
};

const loaiKhachHangEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default loaiKhachHangEnum;
