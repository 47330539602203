import { InfoCircleOutlined } from '@ant-design/icons';
import { history } from 'helpers';
import React from 'react';
import { DialogState } from 'react-st-modal/build/hooks/useDialog';

interface ConstructionLabelProps {
	constructionId: string;
	dialog?: DialogState<any>;
	label?: string;
}

const ConstructionLabel = (props: ConstructionLabelProps) => {
	const { constructionId, dialog, label = 'Cơ sở công trình' } = props;

	const handleNavigate = () => {
		if (constructionId) {
			if (dialog) dialog.close();
			history.push(`/chiTietCoSoCongTrinh?id=${constructionId}&mode=view`);
		}
		return;
	};

	return (
		<span title='Xem chi tiết'>
			{label}&nbsp;
			<InfoCircleOutlined style={{ cursor: 'pointer', color: '#3B72BF' }} onClick={handleNavigate} />
		</span>
	);
};

export default ConstructionLabel;
