import { ConstructionCheckingModel } from 'models/ConstructionChecking/ConstructionChecking.model';
import { Moment } from 'moment';
import ApiService, { BUSINESS_BASE_URL, PageOption } from 'services/api/index';
import { DanhGiaEnum } from '../../../../enum/danhGiaEnum';
import { TrangThaiThucHienEnum } from '../../../../enum/trangThaiThucHienEnum';
import { Construction } from '../../inventoryService/constructionService';

const prefixConstructionChecking = '/constructionChecking';

export interface ConstructionChecking {
	ConstructionName?: any;
	CheckDate: Moment;
	Checker: string;
	CheckingStatus: TrangThaiThucHienEnum;
	ConstructionCheckingPlanId: string;
	ConstructionId: string;
	Construction?: Construction;
	Content: string;
	ContentDetail: string;
	Evaluate: DanhGiaEnum;
	FileUrl?: string;
	Id: string;
	Name: string;
	SolvingStatus?: any;
	Type: string;
}

const _createConstructionChecking = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteConstructionChecking = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.delete(`${prefixConstructionChecking}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getConstructionChecking = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixConstructionChecking}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchConstructionCheckings = (param: any, pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}/search`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _searchConstructionCheckingsByLocation = (param: any, pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}/getAllByLocation`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateConstructionChecking = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.put(`${prefixConstructionChecking}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listConstructionChecking = (pageOption: PageOption): Promise<PageOption<ConstructionChecking>> =>
	ApiService(BUSINESS_BASE_URL)
		.get(`${prefixConstructionChecking}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: PageOption }) => Promise.resolve(data));

const getConstructionCheckingByParam = (
	param = {},
	pageOption?: PageOption,
): Promise<PageOption<ConstructionCheckingModel.ConstructionChecking>> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}/search`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));

const sendNoticeToConstruction = (param = {}): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}/sendNotice`, param)
		.then(({ data }) => Promise.resolve(data));

const sendNoticeToEmail = (param = {}): Promise<any> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixConstructionChecking}/sendEmail`, param)
		.then(({ data }) => Promise.resolve(data));

// Thực hiện kiếm tra cơ sở công trình
const ConstructionCheckingService = {
	createConstructionChecking: _createConstructionChecking,
	deleteConstructionChecking: _deleteConstructionChecking,
	getConstructionChecking: _getConstructionChecking,
	searchConstructionChecking: _searchConstructionCheckings,
	updateConstructionChecking: _updateConstructionChecking,
	listConstructionChecking: _listConstructionChecking,
	searchConstructionCheckingsByLocation: _searchConstructionCheckingsByLocation,
	getConstructionCheckingByParam,
	sendNoticeToConstruction,
	sendNoticeToEmail,
};

export default ConstructionCheckingService;
