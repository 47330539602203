import { PermissionEnum } from 'enum/permissionEnum';
import { ReduxAction } from 'redux/store';
import { UserInfo } from 'services/api/authenService/usersService';
import { SET_PERMISSION_LIST, SET_USER_INFO } from './permission.type';

export const setPermissionList = (input: PermissionEnum[]): ReduxAction => {
	return {
		type: SET_PERMISSION_LIST,
		payload: input,
	};
};

export const setUserInfo = (userInfo: UserInfo): ReduxAction => {
	return {
		type: SET_USER_INFO,
		payload: userInfo,
	};
};
