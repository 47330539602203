import { t } from 'i18next';

export enum CapBacEnum {
	CHIEN_SI = 'CHIEN_SI',
	DAI_TA = 'DAI_TA',
	DAI_TUONG = 'DAI_TUONG',
	DAI_UY = 'DAI_UY',
	HA_SI = 'HA_SI',
	THIEU_TA = 'THIEU_TA',
	THIEU_TUONG = 'THIEU_TUONG',
	THIEU_UY = 'THIEU_UY',
	THUONG_SI = 'THUONG_SI',
	THUONG_TA = 'THUONG_TA',
	THUONG_TUONG = 'THUONG_TUONG',
	THUONG_UY = 'THUONG_UY',
	TRUNG_SI = 'TRUNG_SI',
	TRUNG_TA = 'TRUNG_TA',
	TRUNG_TUONG = 'TRUNG_TUONG',
	TRUNG_UY = 'TRUNG_UY',
}

interface CapBac {
	label: string,
	value: CapBacEnum
}

const _fromEnum = (capBacValue: CapBacEnum): CapBac => {
	switch (capBacValue) {
		case CapBacEnum.CHIEN_SI:
			return {
				label: t('enum.capBac.CHIEN_SI'),
				value: CapBacEnum.CHIEN_SI,
			};
		case CapBacEnum.DAI_TA:
			return {
				label: t('enum.capBac.DAI_TA'),
				value: CapBacEnum.DAI_TA,
			};
		case CapBacEnum.DAI_TUONG:
			return {
				label: t('enum.capBac.DAI_TUONG'),
				value: CapBacEnum.DAI_TUONG,
			};
		case CapBacEnum.DAI_UY:
			return {
				label: t('enum.capBac.DAI_UY'),
				value: CapBacEnum.DAI_UY,
			};
		case CapBacEnum.HA_SI:
			return {
				label: t('enum.capBac.HA_SI'),
				value: CapBacEnum.HA_SI,
			};
		case CapBacEnum.THIEU_TA:
			return {
				label: t('enum.capBac.THIEU_TA'),
				value: CapBacEnum.THIEU_TA,
			};
		case CapBacEnum.THIEU_TUONG:
			return {
				label: t('enum.capBac.THIEU_TUONG'),
				value: CapBacEnum.THIEU_TUONG,
			};
		case CapBacEnum.THIEU_UY:
			return {
				label: t('enum.capBac.THIEU_UY'),
				value: CapBacEnum.THIEU_UY,
			};
		case CapBacEnum.THUONG_SI:
			return {
				label: t('enum.capBac.THUONG_SI'),
				value: CapBacEnum.THUONG_SI,
			};
		case CapBacEnum.THUONG_TA:
			return {
				label: t('enum.capBac.THUONG_TA'),
				value: CapBacEnum.THUONG_TA,
			};
		case CapBacEnum.THUONG_TUONG:
			return {
				label: t('enum.capBac.THUONG_TUONG'),
				value: CapBacEnum.THUONG_TUONG,
			};
		case CapBacEnum.THUONG_UY:
			return {
				label: t('enum.capBac.THUONG_UY'),
				value: CapBacEnum.THUONG_UY,
			};
		case CapBacEnum.TRUNG_SI:
			return {
				label: t('enum.capBac.TRUNG_SI'),
				value: CapBacEnum.TRUNG_SI,
			};
		case CapBacEnum.TRUNG_TA:
			return {
				label: t('enum.capBac.TRUNG_TA'),
				value: CapBacEnum.TRUNG_TA,
			};
		case CapBacEnum.TRUNG_TUONG:
			return {
				label: t('enum.capBac.TRUNG_TUONG'),
				value: CapBacEnum.TRUNG_TUONG,
			};
		case CapBacEnum.TRUNG_UY:
			return {
				label: t('enum.capBac.TRUNG_UY'),
				value: CapBacEnum.TRUNG_UY,
			};
	}
};

const _enumFromLabel = (capBacLabel: string): CapBacEnum | undefined => {
	switch (capBacLabel.toUpperCase()) {
		case t('enum.capBac.CHIEN_SI').toUpperCase():
			return CapBacEnum.CHIEN_SI;
		case t('enum.capBac.DAI_TA').toUpperCase():
			return CapBacEnum.DAI_TA;
		case t('enum.capBac.DAI_TUONG').toUpperCase():
			return CapBacEnum.DAI_TUONG;
		case t('enum.capBac.DAI_UY').toUpperCase():
			return CapBacEnum.DAI_UY;
		case t('enum.capBac.HA_SI').toUpperCase():
			return CapBacEnum.HA_SI;
		case t('enum.capBac.THIEU_TA').toUpperCase():
			return CapBacEnum.THIEU_TA;
		case t('enum.capBac.THIEU_TUONG').toUpperCase():
			return CapBacEnum.THIEU_TUONG;
		case t('enum.capBac.THIEU_UY').toUpperCase():
			return CapBacEnum.THIEU_UY;
		case t('enum.capBac.THUONG_SI').toUpperCase():
			return CapBacEnum.THUONG_SI;
		case t('enum.capBac.THUONG_TA').toUpperCase():
			return CapBacEnum.THUONG_TA;
		case t('enum.capBac.THUONG_TUONG').toUpperCase():
			return CapBacEnum.THUONG_TUONG;
		case t('enum.capBac.THUONG_UY').toUpperCase():
			return CapBacEnum.THUONG_UY;
		case t('enum.capBac.TRUNG_SI').toUpperCase():
			return CapBacEnum.TRUNG_SI;
		case t('enum.capBac.TRUNG_TA').toUpperCase():
			return CapBacEnum.TRUNG_TA;
		case t('enum.capBac.TRUNG_TUONG').toUpperCase():
			return CapBacEnum.TRUNG_TUONG;
		case t('enum.capBac.TRUNG_UY').toUpperCase():
			return CapBacEnum.TRUNG_UY;
	}
};

const _getAll = (): CapBac[] => {
	return Object.values(CapBacEnum).map(r => _fromEnum(r as CapBacEnum));
};

const capBacEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default capBacEnum;
