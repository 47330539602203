import { Button, Form, Input } from 'antd';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import deviceTypeService, { CreateDeviceTypeParams } from 'services/api/factService/deviceTypeService';

interface LoaiThietBiDialogProps {
	errorCode?: string;
	Id?: string;
	callback?: () => void;
}

const LoaiThietBiDialog: FunctionComponent<LoaiThietBiDialogProps> = (props: LoaiThietBiDialogProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	const executeCallback = () => {
		if (props.callback) {
			props.callback();
		}
	};

	useEffect(() => {
		if (props.Id) {
			deviceTypeService.getbyId(props.Id).then((result: any) => {
				form.setFieldsValue({ ...result });
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [form, props.Id]);

	const finishCreate = (formValues: CreateDeviceTypeParams) => {
		deviceTypeService
			.createDeviceType(formValues)
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_create_desc', {
						item: form.getFieldValue('DeviceTypeCode'),
					}),
				);
				dialog.close();
				executeCallback();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	const finishUpdate = (formValues: CreateDeviceTypeParams) => {
		// update
		deviceTypeService
			.updateDeviceType({ ...formValues, Id: props.Id })
			.then(() => {
				showNotification(
					'success',
					t('notification.success'),
					t('notification.success_update_desc', {
						item: form.getFieldValue('DeviceTypeCode'),
					}),
				);
				dialog.close();
				executeCallback();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	return (
		<Form
			className={'common-dialog'}
			form={form}
			layout={'vertical'}
			onFinish={props.errorCode ? finishUpdate : finishCreate}>
			<Form.Item
				label={t('label.maLoaiThietBi')}
				name="DeviceTypeCode"
				rules={[
					{
						whitespace: true,
						required: true,
						message: 'Mã loại thiết bị không được để trống',
					},
				]}>
				<Input placeholder={t('placeholder.nhapMaLoaiThietBi')} />
			</Form.Item>

			<Form.Item className="mt20" name="Description" label={t('label.moTa')}>
				<Input.TextArea placeholder={t('placeholder.nhapMoTa')} rows={5} />
			</Form.Item>

			<div className="absolute-end-cont">
				<Button type={'primary'} htmlType={'submit'}>
					{props.Id ? t('label.update') : t('label.create')}
				</Button>

				<Button
					className="ml10"
					type="default"
					onClick={() => {
						dialog.close();
					}}>
					{t('label.cancel')}
				</Button>
			</div>
		</Form>
	);
};

export default withTranslation()(LoaiThietBiDialog);
