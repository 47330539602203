import { Select } from 'antd';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import initVariableGlobal from 'services/api/globalVariable';
import ProvinceService from 'services/api/provinceService';
import Generator from 'services/generator';
import GeneratorProvinceUser from 'services/generator/ProvinceUser';
import { LOCATION_INFO } from 'services/tokenService';
import APICommon from './backendService/common';
import ConstructionCheckingPlanService from './businessService/constructionCheckingPlanService';
import deviceTypeService from './factService/deviceTypeService';
import errorManagementService from './factService/errorManagementService';
import categoryService from './inventoryService/categoryService';
import { default as constructionService, default as ConstructionService } from './inventoryService/constructionService';
import DeviceService from './inventoryService/deviceService';
import pcccUnitService from './inventoryService/pcccUnitService';

const { Option } = Select;

export default {
	async GetTinhThanhId(tinhThanhLable: string): Promise<string> {
		let IdTinhThanh = '';
		await ProvinceService.listProvince().then((result: any) => {
			result.forEach((element: any) => {
				if (element.Name.toUpperCase() === tinhThanhLable.toUpperCase()) {
					IdTinhThanh = element.Id;
				}
			});
		});
		return IdTinhThanh;
	},

	async GetQuanHuyenId(tinhThanhId: string, quanHuyenLable: string): Promise<string> {
		let IdQuanhuyen = '';
		await ProvinceService.findDistrictsByProvince(tinhThanhId).then((result: any) => {
			result.forEach((element: any) => {
				if (element.Name.toUpperCase() === quanHuyenLable.toUpperCase()) {
					IdQuanhuyen = element.Id;
				}
			});
		});
		return IdQuanhuyen;
	},

	async GetDonViId(tinhThanhId: string, donViPCCCLable: string): Promise<string> {
		let IdDonViPCCC = '';
		const param = {
			TinhThanh: tinhThanhId,
		};
		await pcccUnitService.searchPcccUnit(param).then((result: any) => {
			result.forEach((element: any) => {
				if (element.TenDonVi.toUpperCase() === donViPCCCLable.toUpperCase()) {
					IdDonViPCCC = element.Id;
				}
			});
		});
		return IdDonViPCCC;
	},

	// Get tỉnh thành
	TinhThanh(): JSX.Element[] {
		const [tinhThanhs, setTinhThanhs] = useState<JSX.Element[]>([]);
		useEffect(() => {
			ProvinceService.listProvince().then(result => setTinhThanhs(Generator.generateOptions(result, 'Id', 'Name')));
		}, []);
		return tinhThanhs;
	},

	GetTinhThanhAdmin(): JSX.Element[] {
		const [tinhThanhs, setTinhThanhs] = useState<JSX.Element[]>([]);
		useEffect(() => {
			ProvinceService.listProvince().then(result =>
				setTinhThanhs(GeneratorProvinceUser.generateOptions(result, 'Id', 'Name')),
			);
		}, []);
		return tinhThanhs;
	},

	// Get tỉnh thành Admin cho TT chỉ huy
	GetTinhThanhDashBoard(): JSX.Element[] {
		const [tinhThanhs, setTinhThanhs] = useState<JSX.Element[]>([]);
		useEffect(() => {
			ProvinceService.listProvince().then(result =>
				setTinhThanhs(GeneratorProvinceUser.generateOptionsDashBoard(result, 'Id', 'Name')),
			);
		}, []);
		return tinhThanhs;
	},

	// Get tỉnh thành cho TT chỉ huy
	GetTinhThanhUserDashBoard(): JSX.Element[] {
		const [tinhThanhs, setTinhThanhs] = useState<JSX.Element[]>([]);
		useEffect(() => {
			let result: any = [];
			ProvinceService.listProvince().then(tinhThanhs => {
				if (localStorage.getItem(LOCATION_INFO)) {
					const locations = JSON.parse(localStorage.getItem(LOCATION_INFO) as string);
					locations.forEach((location: any) => {
						tinhThanhs.forEach(tinhThanh => {
							if (tinhThanh.Id === location.CityId) {
								result.push(tinhThanh);
							}
						});
					});
				}
				const uniqueArray = result?.filter(function (item: any, pos: any) {
					return result.indexOf(item) === pos;
				});
				setTinhThanhs(GeneratorProvinceUser.generateOptionsDashBoard(uniqueArray, 'Id', 'Name'));
			});
		}, []);
		return tinhThanhs;
	},

	// Get tỉnh thành
	GetTinhThanhUser(): JSX.Element[] {
		const [tinhThanhs, setTinhThanhs] = useState<JSX.Element[]>([]);
		useEffect(() => {
			let result: any = [];
			ProvinceService.listProvince().then(tinhThanhs => {
				if (localStorage.getItem(LOCATION_INFO)) {
					const locations = JSON.parse(localStorage.getItem(LOCATION_INFO) as string);
					locations.forEach((location: any) => {
						tinhThanhs.forEach(tinhThanh => {
							if (tinhThanh.Id === location.CityId) {
								result.push(tinhThanh);
							}
						});
					});
				}
				const uniqueArray = result?.filter(function (item: any, pos: any) {
					return result.indexOf(item) === pos;
				});
				setTinhThanhs(GeneratorProvinceUser.generateOptions(uniqueArray, 'Id', 'Name'));
			});
		}, []);
		return tinhThanhs;
	},

	// Get Loại Thiết bị
	LoaiThietBi() {
		const [arrayOptionLoaiThietBi, updateArrOptionLoaiThietBi] = useState<JSX.Element[] | null>();
		useEffect(() => {
			categoryService.listCategorybyCode('THIET_BI').then((res: any) => {
				optionLoaiThietBi(res ? res : []);
			});
		}, []);
		const optionLoaiThietBi = (data: { CategoryChild: any[] }) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.CategoryChild.forEach(item =>
					array.push(
						<Option key={item.Code} value={item.Name}>
							{item.Name}
						</Option>,
					),
				);
				updateArrOptionLoaiThietBi(array);
			}
		};
		return arrayOptionLoaiThietBi;
	},

	// Get Danh sách thiết bị
	GetDSThietBi() {
		const [arrayOptionThietBi, updateArrOptionThietBi] = useState<JSX.Element[] | null>();
		useEffect(() => {
			DeviceService.listDevice().then((res: any) => optionThietBi(res ? res : []));
		}, []);

		const optionThietBi = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.Imei} value={item.Imei}>
							{item.Name}_{item.Imei}
						</Option>,
					),
				);
				updateArrOptionThietBi(array);
			}
		};
		return arrayOptionThietBi;
	},

	// Get Danh sách thiết bị
	GetDSLoaiThietBiError() {
		const [arrayOptionThietBi, updateArrOptionThietBi] = useState<JSX.Element[] | null>();
		useEffect(() => {
			deviceTypeService.getbyCode(null).then((res: any) => optionThietBi(res ? res : []));
		}, []);

		const optionThietBi = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.Id} value={item.DeviceTypeCode}>
							{item.DeviceTypeCode}
						</Option>,
					),
				);
				updateArrOptionThietBi(array);
			}
		};
		return arrayOptionThietBi;
	},

	// Get Danh sách thiết bị
	GetDSLoaiError() {
		const [arrayOptionErrType, updateArrOptionErrType] = useState<JSX.Element[] | null>();
		useEffect(() => {
			errorManagementService.getbyCode(null).then((res: any) => optionErrType(res ? res : []));
		}, []);

		const optionErrType = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.Id} value={item.ErrorCode}>
							{item.ErrorCode}
						</Option>,
					),
				);
				updateArrOptionErrType(array);
			}
		};
		return arrayOptionErrType;
	},

	// Get Giới tính
	Gioitinh() {
		const [arrayOptionGioitinh, updateArrOptionGioitinh] = useState<JSX.Element[] | null>();
		useEffect(() => {
			APICommon.getgioitinh().then((res: { data: any }) => optionGioitinh(res.data));
		}, []);

		const optionGioitinh = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.id} value={item.id}>
							{item.gioiTinh}
						</Option>,
					),
				);
				updateArrOptionGioitinh(array);
			}
		};
		return arrayOptionGioitinh;
	},

	TrangThaiDevice() {
		return [
			<Option key={'ACTIVE'} value={true}>
				Kết nối
			</Option>,
			<Option key={'INACTIVE'} value={false}>
				Chưa kết nối
			</Option>,
		];
	},

	// Get trạng thái
	TrangThaiCustommer() {
		return [
			<Option key={0} value={'ACTIVE'}>
				Hoạt động
			</Option>,
			<Option key={1} value={'INACTIVE'}>
				Tạm khóa
			</Option>,
		];
	},

	// Get Danh sách cơ sở công trình

	DSCoSoCongTrinh() {
		const [arrayCoSoCongTrinh, updateCoSoCongTrinh] = useState<JSX.Element[] | null>();
		useEffect(() => {
			constructionService.listConstruction().then((res: any) => optionCoSoCongTrinh(res));
		}, []);
		const optionCoSoCongTrinh = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.Id} value={item.Id}>
							{item.TenCoSoCongTrinh}
						</Option>,
					),
				);
				updateCoSoCongTrinh(array);
			}
		};
		return arrayCoSoCongTrinh;
	},

	// Get Loại Camera
	DSLoaiCamera() {
		const [arrayOptionLoaiCamera, updateArrOptionLoaiCamera] = useState<JSX.Element[] | null>();
		useEffect(() => {
			APICommon.getLoaiCamera().then((res: { data: any }) => optionLoaiCamera(res.data));
		}, []);
		const optionLoaiCamera = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.id} value={item.id}>
							{item.tenloaimayquay}
						</Option>,
					),
				);
				updateArrOptionLoaiCamera(array);
			}
		};
		return arrayOptionLoaiCamera;
	},

	// Get Đại Lý
	DanhSachDaiLy() {
		return [
			<Option key={'DAI_LY1'} value="DAI_LY1">
				Đại lý 1
			</Option>,
			<Option key={'DAI_LY2'} value="DAI_LY2">
				Đại lý 2
			</Option>,
		];
	},
	// Get danh sách phòng cháy chữa cháy
	DanhSachPCCC(tinhid?: any) {
		const [arrayOptiondanhsachpccc, updateArrOptiondanhsachpccc] = useState<JSX.Element[]>([]);
		useEffect(() => {
			APICommon.getUnitPCCC(tinhid).then((res: { data: any }) => optiondanhsachpccc(res.data));
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
		const optiondanhsachpccc = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.id} value={item.id}>
							{item.tenDonVi}
						</Option>,
					),
				);
				updateArrOptiondanhsachpccc(array);
			}
		};
		return arrayOptiondanhsachpccc;
	},
	// Get danh sách phòng cháy chữa cháy
	DanhSachChayNo() {
		return [
			<Option key="CHAY" value="CHAY">
				{t('enum.chayNo.CHAY')}
			</Option>,
			<Option key="NO" value="NO">
				{t('enum.chayNo.NO')}
			</Option>,
		];
	},

	getSelectOptionsCanBoChienSy(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.Id} value={item.Id}>
					{item.HoTen}
				</Option>,
			),
		);
		return optionDOMs;
	},

	getSelectOptionsPhuongTienPCCC(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.Id} value={item.Id}>
					{item.TenPhuongTien}
				</Option>,
			),
		);
		return optionDOMs;
	},

	// Get danh sách cán bộ chiến sỹ
	DanhSachCanBoChienSy(userName: string, loaihinhtruc: string, ngayform: string, mode: string) {
		const [arrayOptiondanhsachcanbochiensy, updateArrOptiondanhsachcanbochiensy] = useState<JSX.Element[] | null>();
		useEffect(() => {
			APICommon.getDsCanBoChienSy(userName, loaihinhtruc, ngayform).then((res: { data: any }) =>
				optiondanhsachcanbochiensy(res.data),
			);
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, []);
		const optiondanhsachcanbochiensy = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data && mode !== 'all') {
				data.forEach(item =>
					array.push(
						<Option key={item.id} value={item.id}>
							{item.hoTen}
						</Option>,
					),
				);
				updateArrOptiondanhsachcanbochiensy(array);
			} else if (data && mode === 'all') {
				data.forEach((item, index) => array.push(Object.assign(item, { key: index + 1 })));
				updateArrOptiondanhsachcanbochiensy(data);
			}
		};
		return arrayOptiondanhsachcanbochiensy;
	},
	// Get danh sách loại hình trực
	DanhSachCaTruc() {
		const [arrayOptiondanhsachCaTruc, updateArrOptiondanhsachCaTruc] = useState<JSX.Element[]>([]);
		useEffect(() => {
			APICommon.GetCanBoChienSyDangKyTruc().then((res: { data: any }) => optiondanhsachCaTruc(res.data));
		}, []);
		const optiondanhsachCaTruc = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.id} value={item.id}>
							{item.thoigiandangky}
						</Option>,
					),
				);
				updateArrOptiondanhsachCaTruc(array);
			}
		};
		return arrayOptiondanhsachCaTruc;
	},
	// Get danh sách cơ sở công trình
	DanhSachCoSoCongTrinh() {
		const [arrayOptiondanhsachcosocongtrinh, updateArrOptiondanhsachcosocongtrinh] = useState<JSX.Element[] | null>();
		useEffect(() => {
			ConstructionService.listConstruction().then((res: any) => optiondanhsachcosocongtrinh(res ? res : []));
		}, []);
		const optiondanhsachcosocongtrinh = (data: any[]) => {
			let array: JSX.Element[] = [];
			data.forEach(item =>
				array.push(
					<Option key={item.Id} value={item.Id}>
						{item.TenCoSoCongTrinh}
					</Option>,
				),
			);
			updateArrOptiondanhsachcosocongtrinh(array);
		};
		return arrayOptiondanhsachcosocongtrinh;
	},
	// Get danh sách kế hoạch kiểm tra
	DanhSachKeHoachKiemTra() {
		const [arrayOptiondanhsachkehoachkiemtra, updateArrOptiondanhsachkehoachkiemtra] = useState<JSX.Element[] | null>();
		useEffect(() => {
			ConstructionCheckingPlanService.listConstructionCheckingPlan().then((res: any) =>
				optiondanhsachkehoachkiemtra(res),
			);
		}, []);
		const optiondanhsachkehoachkiemtra = (data: any[]) => {
			let array: JSX.Element[] = [];
			if (data) {
				data.forEach(item =>
					array.push(
						<Option key={item.Id} value={item.Id}>
							{item.Name}
						</Option>,
					),
				);
				updateArrOptiondanhsachkehoachkiemtra(array);
			}
		};
		return arrayOptiondanhsachkehoachkiemtra;
	},
	//Get danh sách đơn vị phòng cháy chữa cháy
	DanhSachDonViPCCC() {
		const [arrayOptiondanhsachDonViPCCC, updateArrOptiondanhsachDonViPCCC] = useState<JSX.Element[] | null>();
		useEffect(() => {
			pcccUnitService.listPcccUnit().then((res: any) => optionDonViPCCC(res));
		}, []);
		const optionDonViPCCC = (data: any[]) => {
			let array: JSX.Element[] = [];
			data.forEach(item =>
				array.push(
					<Option key={item.Id} value={item.Id}>
						{item.TenDonVi}
					</Option>,
				),
			);
			updateArrOptiondanhsachDonViPCCC(array);
		};
		return arrayOptiondanhsachDonViPCCC;
	},
	getSelectOptionsDonViPCCC(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.Id} value={item.Id}>
					{item.TenDonVi ? item.TenDonVi : item.Name}
				</Option>,
			),
		);
		return optionDOMs;
	},

	getSelectOptionsCustomer(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.Id} value={item.Id}>
					{item.Name}
				</Option>,
			),
		);
		return optionDOMs;
	},

	getSelectOptionsRolde(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.Id} value={item.Id} Permissions={item.Permissions}>
					{item.RoleName}
				</Option>,
			),
		);
		return optionDOMs;
	},

	// Generate <option> list
	getSelectOptions(data: any[]) {
		let optionDOMs: JSX.Element[] = [];
		data.forEach(item =>
			optionDOMs.push(
				<Option key={item.id} value={item.id}>
					{item.value}
				</Option>,
			),
		);

		return optionDOMs;
	},
};
