import { SearchOutlined } from '@ant-design/icons';
import { Column, ColumnConfig } from '@ant-design/plots';
import { Button, Form, Select } from 'antd';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import { t } from 'i18next';
import cloneDeep from 'lodash/cloneDeep';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import reportTestService from 'services/api/factService/reportTestService';
import utils from 'services/api/utils';
import { PermissionProps } from './QuanLyChatLuongThietBi';

const defaultChartConfig: ColumnConfig = {
	data: [],
	xField: 'key',
	yField: 'value',
	label: {
		position: 'middle',
		style: {
			fill: '#fff',
			opacity: 0.6,
		},
	},
	xAxis: {
		label: {
			autoHide: true,
			autoRotate: false,
		},
	},
};

const BaoCaoLoiTongHop: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const [chartConfig, setChartConfig] = useState<ColumnConfig>(defaultChartConfig);
	const loaiThietBi = utils.GetDSLoaiThietBiError();

	useEffect(() => {
		document.title = t('nav.baoCaoKetQua');
		NavBarDispatcher.setTitle(`${t('nav.baoCaoKetQua')}`);
		SearchKetQuaTest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function SearchKetQuaTest() {
		const param = {
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			DeviceTypeCode: form.getFieldValue('DeviceTypeCode'),
			ErrorCode: null,
			IMEI: null,
		};
		reportTestService.searchSummary(param).then((res: any) => {
			// setDataTongHop(res ? res.ChartErrorDetail : []);
			let resultList = res ? res.ChartErrorDetail : [];
			let chartDataList: any[] = [];
			resultList.forEach((data: any) => {
				chartDataList.push({
					key: data.Key,
					value: data.Value,
				});
			});

			let _chartConfig: any = cloneDeep(defaultChartConfig);
			_chartConfig.data = chartDataList;
			setChartConfig(_chartConfig);
		});
	}

	return (
		<Form id="baoCaoTongHop" layout="vertical" name="normal_login" form={form}>
			<div className={'flex--row'}>
				<Form.Item name="DeviceTypeCode" className="withMinSelect">
					<Select
						showSearch
						optionFilterProp="children"
						placeholder={t('placeholder.chonLoaiThietBi')}
						filterSort={(optionA, optionB) =>
							(optionA!.children as unknown as string)
								.toLowerCase()
								.localeCompare((optionB!.children as unknown as string).toLowerCase())
						}
						allowClear>
						{loaiThietBi}
					</Select>
					{/* <Input placeholder="Mã loại thiết bị" prefix={<UserOutlined />} onKeyPress={handleKeyDown} allowClear /> */}
				</Form.Item>
				<Form.Item className="ml10" name="dateRange">
					<RangePickerInput form={form} />
				</Form.Item>
				<Button
					className="ml10"
					type="primary"
					icon={<SearchOutlined />}
					onClick={() => {
						SearchKetQuaTest();
					}}>
					{t('label.timKiem')}
				</Button>
			</div>
			<div className={'break-line mt10'} />
			<div className={'border-quan-ly-cong-tac-pc text--center'}>
				<h2 className={'mb0'}>{t('tongHopKetQua.title')}</h2>
			</div>
			<Column className="mt100" {...chartConfig} />
		</Form>
	);
};

export default withTranslation()(BaoCaoLoiTongHop);
