import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, DatePicker, Form, Input, Space } from 'antd';
import { FilterDropdownProps } from 'antd/lib/table/interface';
import CommonControl from 'components/page/CommonControl';
import AddSensorDialog from 'components/page/QuanLyThietBi/Sensor/AddSensorDialog/AddSensorDialog';
import CreateMultipleSensorDialog from 'components/page/QuanLyThietBi/Sensor/CreateMultipleSensorDialog/CreateMultipleSensorDialog';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import SensorTypeSelect from 'components/shared/form-select/SensorType/SensorType';
import FilterDropdownLayout from 'components/shared/layouts/FilterDropdownLayout/FilterDropdownLayout';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import ShowForPermission from 'components/shared/ShowForPermission/ShowForPermission';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiCamBienEnum from 'enum/loaiCamBienEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { formatDate, openDialog } from 'helpers';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import senSorsService from 'services/api/inventoryService/senSorsService';
import { systemProperties } from 'systemProperties';
import './Sensor.scss';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const Sensor = () => {
	const [form] = Form.useForm();

	// states
	const [provinceId, setProvinceId] = useState('');
	const [responseApi, setResponseApi] = useState({
		dataSources: [],
		totalItems: 0,
	});
	const [filteredInfo, setFilteredInfo] = useState<any>({});
	const [paginateOption, setPaginateOption] = useState({
		CurrentPage: CURRENT_PAGE,
		PageSize: PAGE_SIZE,
		Paging: true,
	});

	// columns of table
	const columns = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) =>
				(paginateOption.CurrentPage - 1) * paginateOption.PageSize + index + 1,
		},
		{
			title: 'Địa chỉ MAC',
			dataIndex: 'Mac',
			key: 'Mac',
			filteredValue: filteredInfo.Mac || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="MAC" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập địa chỉ MAC"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ MAC: '' });
									}
									handleSearchColumn('MAC', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('MAC', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		},
		{
			title: 'Loại cảm biến',
			dataIndex: 'Type',
			key: 'Type',
			filteredValue: filteredInfo.Type || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<SensorTypeSelect
							style={{ marginBottom: '0' }}
							form={form}
							onChangeSensorType={(value: string) => {
								setSelectedKeys(value ? [value] : []);
								setFilteredInfo((filteredInfo: any) => ({
									...filteredInfo,
									...(value ? { Type: 'Type' } : { Type: null }),
								}));
								confirm();
							}}
						/>
					</FilterDropdownLayout>
				);
			},
		},
		{
			title: 'IMEI TB trung tâm',
			dataIndex: 'PairingDevice',
			key: 'PairingDevice',
			filteredValue: filteredInfo.IMEI || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="IMEI" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập imei thiết bị"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ IMEI: '' });
									}
									handleSearchColumn('IMEI', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('IMEI', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			render: (PairingDevice: string) => {
				return <NavigateButton url={`/chiTietThietBiTrungTam?mode=view&imei=${PairingDevice}`} name={PairingDevice} />;
			},
		},
		{
			title: 'Cơ sở công trình',
			dataIndex: 'Construction',
			key: 'Construction',
			width: 300,
			filteredValue: filteredInfo.ConstructionName || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="ConstructionName" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập tên cơ sở công trình"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ ConstructionName: '' });
									}
									handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('ConstructionName', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
			render: (construction: { Name: string; Id: string }) => {
				return (
					<NavigateButton url={`/chiTietCoSoCongTrinh?id=${construction.Id}&mode=view`} name={construction.Name} />
				);
			},
		},
		{
			title: 'Vị trí lắp đặt',
			dataIndex: 'InstallLocation',
			key: 'InstallLocation',
			filteredValue: filteredInfo.InstallLocation || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="InstallLocation" style={{ marginBottom: '10px' }}>
							<Input.Search
								placeholder="Nhập vị trí lắp đặt"
								allowClear
								onSearch={e => {
									if (e === '') {
										form.setFieldsValue({ InstallLocation: '' });
									}
									handleSearchColumn('InstallLocation', confirm, setSelectedKeys);
								}}
								onChange={e => {
									if (e.target.value === '') {
										handleSearchColumn('InstallLocation', confirm, setSelectedKeys);
									}
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
			filterIcon: (filtered: boolean) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
		},
		{
			title: 'Thời gian hoạt động',
			dataIndex: 'ActiveTime',
			key: 'ActiveTime',
			filteredValue: filteredInfo.ActiveTime || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<RangePickerInput
							style={{ marginBottom: 0 }}
							onChangeDatePicker={date => {
								setSelectedKeys(date && date.length > 0 ? date : []);
								setFilteredInfo((filteredInfo: any) => ({
									...filteredInfo,
									...(date ? { ActiveTime: 'ActiveTime' } : { ActiveTime: null }),
								}));
								confirm();
							}}
						/>
					</FilterDropdownLayout>
				);
			},
		},
		{
			title: 'Cập nhật lần cuối',
			dataIndex: 'UpdateTime',
			key: 'UpdateTime',
			filteredValue: filteredInfo.UpdateTime || null,
			filterDropdown: ({ setSelectedKeys, confirm }: FilterDropdownProps) => {
				return (
					<FilterDropdownLayout>
						<Form.Item name="UpdateTime" style={{ marginBottom: 0 }}>
							<DatePicker
								locale={CommonControl.localeVi}
								format={systemProperties.dateFormat}
								placeholder="Chọn thời gian cập nhật"
								onChange={(date: any) => {
									setSelectedKeys(date ? [date] : []);
									setFilteredInfo((filteredInfo: any) => ({
										...filteredInfo,
										...(date ? { UpdateTime: 'UpdateTime' } : { UpdateTime: null }),
									}));
									confirm();
								}}
							/>
						</Form.Item>
					</FilterDropdownLayout>
				);
			},
		},
		{
			align: 'center' as const,
			className: 'min-col',
			title: 'Thao tác',
			key: 'edit',
			// hidden: !(
			// 	props.permissionList.includes(PermissionEnum.ADMIN) || props.permissionList.includes(PermissionEnum.DEVICE_EDIT)
			// ),
			render: (_: any, record: any) => (
				<ShowForPermission allowedPermissions={[PermissionEnum.DEVICE_EDIT, PermissionEnum.ADMIN]}>
					<Button type="text" onClick={e => handleEditSensorType(e, record.Id)}>
						<img src="icon/Pen.png" alt="edit" />
					</Button>
				</ShowForPermission>
			),
		},
	];

	const transformData = (data: any) => {
		return data.map((item: any) => {
			const activeTime =
				item?.InstalledTime &&
				item?.ExpiredTime &&
				`${formatDate(item?.InstalledTime)} - ${formatDate(item?.ExpiredTime)}`;
			const sensorType = loaiCamBienEnum.fromEnum(item.Type)?.label;
			return {
				...item,
				Type: sensorType,
				ActiveTime: activeTime,
				UpdateTime: formatDate(item?.UpdateTime, systemProperties.dateTimeFormat),
			};
		});
	};

	const getRequestBody = useCallback(() => {
		const provinceId = form.getFieldValue('TinhThanh');
		const districtId = form.getFieldValue('QuanHuyen');
		const sensorType = form.getFieldValue('SensorType');
		const sensorStatus = form.getFieldValue('SensorStatus');
		const imei = form.getFieldValue('IMEI');
		const constructionName = form.getFieldValue('ConstructionName');
		const installLocation = form.getFieldValue('InstallLocation');
		const dateRange = form.getFieldValue('dateRange');
		const updateTime = form.getFieldValue('UpdateTime');
		const macAddress = form.getFieldValue('MAC');
		return {
			Type: sensorType,
			Status: sensorStatus,
			DeviceInstallation: imei,
			ConstructionName: constructionName,
			InstallLocation: installLocation,
			InstallFromTime: dateRange && dateRange.length > 0 ? dateRange[0] : null,
			InstallToTime: dateRange && dateRange.length > 0 ? dateRange[1] : null,
			UpdateFromTime: updateTime,
			UpdateToTime: updateTime,
			Mac: macAddress,
			Location: {
				CityId: provinceId,
				DistrictId: districtId,
			},
		};
	}, [form]);

	const getSensorList = useCallback(
		(currentPage = CURRENT_PAGE, pageSize = PAGE_SIZE) => {
			const pagination = {
				CurrentPage: currentPage,
				PageSize: pageSize,
				Paging: true,
			};
			const requestBody = getRequestBody();
			senSorsService.searchSenSors(requestBody, pagination).then((res: any) => {
				const { Data, TotalItems } = res;
				const newData = Data ? transformData(Data) : [];
				setResponseApi({
					dataSources: newData,
					totalItems: TotalItems,
				});
			});
		},
		[getRequestBody],
	);

	const handleSearchColumn = (
		column: string,
		confirm: () => void,
		setSelectedKeys: (selectedKeys: React.Key[]) => void,
	) => {
		const value = form.getFieldValue(column);
		setSelectedKeys(value ? [value] : []);
		if (column === 'MAC') {
			let updatedValue = value ? { Mac: 'Mac' } : { Mac: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		} else if (column === 'IMEI') {
			let updatedValue = value ? { IMEI: value } : { IMEI: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		} else if (column === 'ConstructionName') {
			let updatedValue = value ? { ConstructionName: 'Construction' } : { ConstructionName: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		} else if (column === 'InstallLocation') {
			let updatedValue = value ? { InstallLocation: 'InstallLocation' } : { InstallLocation: null };
			setFilteredInfo((filteredInfo: any) => ({
				...filteredInfo,
				...updatedValue,
			}));
		}
		confirm();
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
		}

		getSensorList(CURRENT_PAGE);
	};

	const handleChangePage = (page: number, size: number) => {
		setPaginateOption({
			CurrentPage: page,
			PageSize: size,
			Paging: true,
		});

		getSensorList(page, size);
	};

	const handleOpenAddSensorDialog = async (
		title: string = 'Thêm mới thiết bị cảm biến',
		id?: string,
		mode?: 'add' | 'edit' | 'view',
	) => {
		await openDialog(<AddSensorDialog id={id} mode={mode} callback={getSensorList} />, title, 'addsensor-dialog');
	};

	const handleOpenCreateMultipleSensorDialog = async () => {
		await openDialog(<CreateMultipleSensorDialog callback={getSensorList} />, 'Nhập liệu từ Excel', 'addsensor-dialog');
	};

	const handleEditSensorType = async (e: any, id: string) => {
		e.stopPropagation();
		await handleOpenAddSensorDialog('Chỉnh sửa thông tin thiết bị cảm biến', id, 'edit');
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => handleOpenAddSensorDialog('Chi tiết thiết bị cảm biến', record.Id, 'view'),
		};
	};

	useEffect(() => {
		document.title = t('quanLyThietBi.quanLyCamBien');
	}, []);

	useEffect(() => {
		getSensorList();
	}, [getSensorList]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

				<DistrictSelect
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					form={form}
					provinceId={provinceId}
					onChangeDistrict={() => getSensorList(CURRENT_PAGE)}
				/>
			</div>
			<div className="flex--row">
				<Button
					type={'default'}
					onClick={() => {
						form.resetFields();
						setFilteredInfo({});
						getSensorList(CURRENT_PAGE);
					}}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				<Button className="ml10" type="default" onClick={handleOpenCreateMultipleSensorDialog}>
					<img src="icon/upload.png" alt="upload file" width="18px" height="18px" />
					Nhập liệu từ Excel
				</Button>
				<ShowForPermission allowedPermissions={[PermissionEnum.DEVICE_CREATE, PermissionEnum.ADMIN]}>
					<Button className="ml10" type="primary" icon={<PlusOutlined />} onClick={() => handleOpenAddSensorDialog()}>
						Thêm mới
					</Button>
				</ShowForPermission>
			</div>
		</>
	);

	const formTable = (
		<TableComponent
			dataSource={responseApi.dataSources}
			columns={columns}
			onRow={handleShowViewDetail}
			paginator={{
				onChange: handleChangePage,
				defaultPageSize: paginateOption.PageSize,
				showSizeChanger: true,
				total: responseApi.totalItems,
				current: paginateOption.CurrentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);

	return <PageWrapper titlePage="Danh sách cảm biến" formAction={formAction} formTable={formTable} form={form} />;
};

export default Sensor;
