import { CloseOutlined } from '@ant-design/icons';
import { Button, Col, Row } from 'antd';
import { t } from 'i18next';
import React, { FunctionComponent } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import './ConfirmDialog.scss';

interface ConfirmDialogProps {
	cancelCallback?: () => void;
	cancelBtnText?: string;
	detail?: JSX.Element;
	okCallback?: () => void;
	detailsFiring?: () => void;
	okBtnText?: string;
	title: JSX.Element;
	disableOK?: boolean;
}

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = props => {
	const dialog = useDialog();

	// const { dialog: otherDialog } = dialogSelector.getDialog();

	const _onCancel = () => {
		dialog.close();
		if (props.cancelCallback) {
			props.cancelCallback();
		}
	};
	// useEffect(() => {
	// 	if (otherDialog.close) {
	// 		otherDialog.close();
	// 	}
	// }, [otherDialog]);

	return (
		<div className={'confirmDialog-container'}>
			<CloseOutlined className={'close-icon'} onClick={_onCancel} />
			<div className={'dialog-header'}>
				<img alt={'CONFIRM_ICON'} src={'icon/danger.svg'} height={80} />
			</div>
			<div className={'dialog-content'}>
				<h3>
					<b>{props.title}</b>
				</h3>
				<h5
					className={'mt16 cursor-pointer'}
					onClick={() => {
						if (props.detailsFiring) {
							props.detailsFiring();
							_onCancel();
						}
					}}>
					{props.detail}
				</h5>
				<Row gutter={18} className={'mt32'}>
					{props.cancelBtnText && (
						<Col span={12} className={'text--right'}>
							<Button type={'primary'} ghost className={'regular--16 p8 h100'} onClick={_onCancel}>
								{props.cancelBtnText || t('label.huy')}
							</Button>
						</Col>
					)}
					<Col span={12}>
						<Button
							disabled={props.disableOK}
							type={'primary'}
							className={'regular--16 p8 h100'}
							onClick={() => {
								dialog.close();
								if (props.okCallback) {
									props.okCallback();
								}
							}}>
							{props.okBtnText || t('label.dongY')}
						</Button>
					</Col>
				</Row>
			</div>
		</div>
	);
};

export default withTranslation()(ConfirmDialog);
