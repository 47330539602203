import { LocationState } from 'redux/location/location.reducer';
import store from 'redux/store';

const locationSelector = {
	getLocation: (): LocationState => {
		return store.getState().locationState;
	},
};

export default locationSelector;
