import { LoginResult } from '../api/authenService/authService';
import { LatLngLiteral, LatLngTuple } from 'leaflet';

export const LOGIN_INFO = 'loginInfo';
export const FIREBASE_TOKEN = 'firebaseToken';
export const SESSION_INFO = 'sessionInfo';
export const LOCATION_INFO = 'LocationInfo';
export const PERMISSION_INFO = 'permissioninfo';

const _setLoginInfo = ({ loginResult, firebaseToken }: { loginResult?: LoginResult; firebaseToken?: string }) => {
	loginResult && localStorage.setItem(LOGIN_INFO, JSON.stringify(loginResult));
	firebaseToken && localStorage.setItem(FIREBASE_TOKEN, firebaseToken);
};

const _setUserInfo = (Locations: any[] = [], permission: any[] = []) => {
	Locations.length > 0 && localStorage.setItem(LOCATION_INFO, JSON.stringify(Locations));
	permission.length > 0 && localStorage.setItem(PERMISSION_INFO, JSON.stringify(permission));
};

const _removeLoginInfo = () => {
	localStorage.removeItem(LOGIN_INFO);
	localStorage.removeItem(FIREBASE_TOKEN);
	localStorage.removeItem(SESSION_INFO);
	localStorage.removeItem(LOCATION_INFO);
	localStorage.removeItem(PERMISSION_INFO);
};

const _getAccessToken = (): string | undefined => {
	if (!localStorage.getItem(LOGIN_INFO)) {
		return;
	}
	return (JSON.parse(localStorage.getItem(LOGIN_INFO)!) as LoginResult).AccessToken;
};

const _getRefreshToken = (): string | undefined => {
	if (!localStorage.getItem(LOGIN_INFO)) {
		return;
	}
	return (JSON.parse(localStorage.getItem(LOGIN_INFO)!) as LoginResult).RefreshToken;
};

const _getFirebaseToken = (): string | undefined => {
	return localStorage.getItem(FIREBASE_TOKEN) || undefined;
};

interface SessionInfo {
	gsttMap?: LastMapLocation;
	dashboardMap?: LastDashboardMapLocation;
}

interface LastMapLocation {
	center: LatLngLiteral;
	zoom: number;
}

const _setGsttMap = (input: LastMapLocation) => {
	localStorage.setItem(
		SESSION_INFO,
		JSON.stringify({
			...JSON.parse(localStorage.getItem(SESSION_INFO) || '{}'),
			gsttMap: input,
		} as SessionInfo),
	);
};

const _getGsttMap = (): LastMapLocation | undefined => {
	if (!localStorage.getItem(SESSION_INFO)) {
		return;
	}

	return (JSON.parse(localStorage.getItem(SESSION_INFO)!) as SessionInfo).gsttMap;
};

interface LastDashboardMapLocation extends LastMapLocation {
	selectProviceId?: string;
	selectedDistrict?: {
		id: string;
		name: string;
		points: LatLngTuple[];
	};
}

const _setDashboardMap = (input: LastDashboardMapLocation) => {
	localStorage.setItem(
		SESSION_INFO,
		JSON.stringify({
			...JSON.parse(localStorage.getItem(SESSION_INFO) || '{}'),
			dashboardMap: input,
		} as SessionInfo),
	);
};

const _getDashboardMap = (): LastDashboardMapLocation | undefined => {
	if (!localStorage.getItem(SESSION_INFO)) {
		return;
	}

	return (JSON.parse(localStorage.getItem(SESSION_INFO)!) as SessionInfo).dashboardMap;
};

const tokenService = {
	getAccessToken: _getAccessToken,
	getFirebaseToken: _getFirebaseToken,
	getDashboardMap: _getDashboardMap,
	getGsttMap: _getGsttMap,
	getRefreshToken: _getRefreshToken,
	removeLoginInfo: _removeLoginInfo,
	setDashboardMap: _setDashboardMap,
	setGsttMap: _setGsttMap,
	setLoginInfo: _setLoginInfo,
	setUserInfo: _setUserInfo,
};

export default tokenService;
