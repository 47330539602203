import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomDialog } from 'react-st-modal';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import { PageOption } from 'services/api';
import deviceTypeService from 'services/api/factService/deviceTypeService';
import LoaiThietBiDialog from './Dialog/LoaiThietBiDialog';
import { PermissionProps } from './QuanLyChatLuongThietBi';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = 10;

const QuanLyThietBi: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const [form] = Form.useForm();

	const [TongSoDiemChay, setTongSoMaLoi] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(1);

	const columns: any = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			title: t('label.order'),
		},
		{
			dataIndex: 'DeviceTypeCode',
			title: t('loaiThietBi.columnTitle.maLoaiThietBi'),
		},
		{
			dataIndex: 'Description',
			title: t('loaiThietBi.columnTitle.moTa'),
		},
		{
			dataIndex: 'CreateTime',
			title: t('loaiThietBi.columnTitle.thoiGianCapNhat'),
		},
		{
			dataIndex: 'Creator',
			title: t('loaiThietBi.columnTitle.nguoiCapNhat'),
		},
		{
			align: 'center' as const,
			width: 100,
			title: t('loaiThietBi.columnTitle.hanhDong'),
			key: 'action',
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
				props.permissionList.includes(PermissionEnum.FACT_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.FACT_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle={`${t('loaiThietBi.editTitle')} "${record.DeviceTypeCode}"`}
					EditDialog={
						<LoaiThietBiDialog errorCode={record.DeviceTypeCode} Id={record.Id} callback={SearchDanhSachLoi} />
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.DeviceTypeCode}
							callback={SearchDanhSachLoi}
							deleteService={deviceTypeService.deleteDeviceType}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [users, setUsers] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.quanLyLoaiThietBi');
		NavBarDispatcher.setTitle(`${t('nav.quanLyLoaiThietBi')}`);

		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.FACT_REPORT_VIEW])) {
				SearchDanhSachLoi();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	function onChangePage(page: number, pageSize: number) {
		const index = Number((page - 1) * pageSize);
		SearchDanhSachLoi(page, pageSize, index);
	}

	function SearchDanhSachLoi(page = CURRENT_PAGE, pageSize = PAGE_SIZE, index = 0) {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		setCurrentPage(page);

		deviceTypeService
			.getbyCode(form.getFieldValue('MaLoi') ? form.getFieldValue('MaLoi') : null, pageOption)
			.then((res: any) => {
				setTongSoMaLoi(res.TotalItems);
				optiondanhsachMaLoi(res ? res.Data : [], index);
			});
	}

	const optiondanhsachMaLoi = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.length > 0) {
			data.forEach((item: any, index: number) => {
				if (item.CreateTime) {
					item.CreateTime = moment(item.CreateTime).format('DD/MM/YYYY: HH:mm:ss');
				}
				item.key = ++_index;
				array.push(item);
			});
		}
		Promise.all(promises).then(() => setUsers(data));
	};

	const handleSearchMaLoi = (value: string) => {
		if (!value) {
			form.resetFields(['MaLoi']);
		}
		SearchDanhSachLoi(CURRENT_PAGE);
	};

	const formAction = (
		<>
			<div className={'flex--row'}>
				<Form.Item name="MaLoi">
					<Input.Search
						placeholder={t('placeholder.nhapMaLoaiThietBi')}
						allowClear
						onSearch={(value: string) => handleSearchMaLoi(value)}
					/>
				</Form.Item>
				{props.permissionList.includes(PermissionEnum.FACT_EDIT) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type={'primary'}
						onClick={async () => {
							await CustomDialog(<LoaiThietBiDialog callback={SearchDanhSachLoi} />, {
								className: 'dialog-add',
								title: t('loaiThietBi.addTitle'),
								isCanClose: true,
								showCloseIcon: true,
							});
						}}>
						<PlusOutlined />
						{t('label.create')}
					</Button>
				) : null}
			</div>
		</>
	);

	const formTable = (
		<TableComponent
			dataSource={users}
			columns={columns}
			paginator={{
				defaultPageSize: PAGE_SIZE,
				showSizeChanger: true,
				onChange: onChangePage,
				total: TongSoDiemChay,
				current: currentPage,
				pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
			}}
		/>
	);

	return <PageWrapper titlePage={t('loaiThietBi.title')} formAction={formAction} formTable={formTable} form={form} />;
};

export default withTranslation()(QuanLyThietBi);
