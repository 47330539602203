import { Form, Select } from 'antd';
import loaiCamBienEnum from 'enum/loaiCamBienEnum';
import { FormInstance } from 'rc-field-form';
import React from 'react';

interface SensorTypeProps {
	onChangeSensorType?: (value: string) => void;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
	style?: React.CSSProperties;
}

const SensorTypeSelect = (props: SensorTypeProps) => {
	const sensorTypes = loaiCamBienEnum.getAll();
	return (
		<Form.Item
			name="SensorType"
			className={props.formItemClassName}
			rules={props.rules}
			label={props.label}
			style={props.style}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn loại cảm biến"
				optionFilterProp="children"
				className={props.selectClassName}
				onChange={props.onChangeSensorType}
				disabled={props.disabled}>
				{sensorTypes.map(item => (
					<Select.Option value={item.value} key={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default SensorTypeSelect;
