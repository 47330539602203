import { DutySchedule, DutyScheduleSonLa } from 'models/duty-schedule/duty-schedule.model';
import { ResponseApi } from 'models/response-api.model';
import ApiService, { BUSINESS_BASE_URL, PageOption } from '../../index';

export const prefixDutySchedule = '/dutySchedule';

const getDutyScheduleWithFilter = (payload = {}, pageOption: PageOption): Promise<ResponseApi<DutySchedule[]>> =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixDutySchedule}/search`, payload, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: ResponseApi<DutySchedule[]> }) => Promise.resolve(data));

const createDutySchedule = (payload: any) =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixDutySchedule}`, payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const updateDutySchedule = (payload: any) =>
	ApiService(BUSINESS_BASE_URL)
		.put(`${prefixDutySchedule}`, payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const createDutyScheduleByScheduleAvailable = (payload: any) =>
	ApiService(BUSINESS_BASE_URL)
		.post(`${prefixDutySchedule}/CreateByScheduleAvailabel`, payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const getDutyScheduleSonLaWithFilter = (payload = {}): Promise<DutyScheduleSonLa> =>
	ApiService(BUSINESS_BASE_URL)
		.post('/DutyScheduleSonLa/search', payload)
		.then(({ data }: { data: DutyScheduleSonLa[] }) => Promise.resolve(data[0]));

const createDutyScheduleSonLa = (payload = {}) =>
	ApiService(BUSINESS_BASE_URL)
		.post('/DutyScheduleSonLa', payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const updateDutyScheduleSonLa = (payload = {}) =>
	ApiService(BUSINESS_BASE_URL)
		.put('/DutyScheduleSonLa', payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const getDutyScheduleSonLaById = (id: string) =>
	ApiService(BUSINESS_BASE_URL)
		.get(`/DutyScheduleSonLa/id=${id}`)
		.then(({ data }: { data: DutyScheduleSonLa }) => Promise.resolve(data));

const createDutyScheduleTeamSonLa = (payload = {}) =>
	ApiService(BUSINESS_BASE_URL)
		.post('/DutyScheduleSonLa/createTeam', payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const updateDutyScheduleTeamSonLa = (payload = {}) =>
	ApiService(BUSINESS_BASE_URL)
		.put('/DutyScheduleSonLa/updateTeam', payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const deleteDutyScheduleTeamSonLa = (scheduleId: string, teamId: string) =>
	ApiService(BUSINESS_BASE_URL)
		.delete(`/DutyScheduleSonLa/deleteTeam?scheduleId=${scheduleId}&teamId=${teamId}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const DutyScheduleService = {
	getDutyScheduleWithFilter,
	createDutySchedule,
	updateDutySchedule,
	createDutyScheduleByScheduleAvailable,
	getDutyScheduleSonLaWithFilter,
	createDutyScheduleSonLa,
	createDutyScheduleTeamSonLa,
	deleteDutyScheduleTeamSonLa,
	updateDutyScheduleSonLa,
	getDutyScheduleSonLaById,
	updateDutyScheduleTeamSonLa
};

export default DutyScheduleService;
