import { resetNavigate, setNavigate } from 'redux/navigate-from-dashboard/navigate-from-dashboard.action';
import store from 'redux/store';

const navigateFromDashboardDispatcher = {
	setNavigateStatus: (input: boolean) => {
		return store.dispatch(setNavigate(input));
	},
	resetNavigateStatus: () => {
		return store.dispatch(resetNavigate());
	},
};

export default navigateFromDashboardDispatcher;
