import { Form, Select } from 'antd';
import nganhLinhVucEnum from 'enum/nghanhLinhVucEnum';
import { FormInstance } from 'rc-field-form';
import React from 'react';

interface NganhLinhVucSelectProps {
	onChangeNganhLinhVuc?: (value: string) => void;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
}

const NganhLinhVucSelect = (props: NganhLinhVucSelectProps) => {
	const nganhLinhVucList = nganhLinhVucEnum.getAll();
	return (
		<Form.Item name="IndustryFields" className={props.formItemClassName} rules={props.rules} label={props.label}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn ngành, lĩnh vực"
				optionFilterProp="children"
				className={props.selectClassName}
				onChange={props.onChangeNganhLinhVuc}
				disabled={props.disabled}>
				{nganhLinhVucList.map(item => (
					<Select.Option value={item.value} key={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default NganhLinhVucSelect;
