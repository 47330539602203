import { t } from 'i18next';

export enum ThuocThanhPhanKinhTeEnum {
	NHA_NUOC = 'NHA_NUOC',
	TAP_THE = 'TAP_THE',
	TU_NHAN = 'TU_NHAN',
	TU_BAN_NHA_NUOC = 'TU_BAN_NHA_NUOC',
	VON_DAU_TU_NUOC_NGOAI = 'VON_DAU_TU_NUOC_NGOAI',
}

interface ThuocThanhPhanKinhTe {
	value: ThuocThanhPhanKinhTeEnum;
	label: string;
}

const _fromEnum = (thuocThanhPhanKinhTeEnum: ThuocThanhPhanKinhTeEnum): ThuocThanhPhanKinhTe => {
	switch (thuocThanhPhanKinhTeEnum) {
		case ThuocThanhPhanKinhTeEnum.NHA_NUOC:
			return {
				label: t('enum.loaiThanhPhanKinhTe.NHA_NUOC'),
				value: ThuocThanhPhanKinhTeEnum.NHA_NUOC,
			};
		case ThuocThanhPhanKinhTeEnum.TAP_THE:
			return {
				label: t('enum.loaiThanhPhanKinhTe.TAP_THE'),
				value: ThuocThanhPhanKinhTeEnum.TAP_THE,
			};
		case ThuocThanhPhanKinhTeEnum.TU_NHAN:
			return {
				label: t('enum.loaiThanhPhanKinhTe.TU_NHAN'),
				value: ThuocThanhPhanKinhTeEnum.TU_NHAN,
			};
		case ThuocThanhPhanKinhTeEnum.TU_BAN_NHA_NUOC:
			return {
				label: t('enum.loaiThanhPhanKinhTe.TU_BAN_NHA_NUOC'),
				value: ThuocThanhPhanKinhTeEnum.TU_BAN_NHA_NUOC,
			};
		case ThuocThanhPhanKinhTeEnum.VON_DAU_TU_NUOC_NGOAI:
			return {
				label: t('enum.loaiThanhPhanKinhTe.VON_DAU_TU_NUOC_NGOAI'),
				value: ThuocThanhPhanKinhTeEnum.VON_DAU_TU_NUOC_NGOAI,
			};
	}
};

const _getAll = (): ThuocThanhPhanKinhTe[] => {
	return Object.values(ThuocThanhPhanKinhTeEnum).map((r) => _fromEnum(r as ThuocThanhPhanKinhTeEnum));
};

const _enumFromLabel = (label: string): ThuocThanhPhanKinhTeEnum | undefined => {
	switch (label) {
		case t('enum.loaiThanhPhanKinhTe.NHA_NUOC'):
			return ThuocThanhPhanKinhTeEnum.NHA_NUOC;
		case t('enum.loaiThanhPhanKinhTe.TAP_THE'):
			return ThuocThanhPhanKinhTeEnum.TAP_THE;
		case t('enum.loaiThanhPhanKinhTe.TU_BAN_NHA_NUOC'):
			return ThuocThanhPhanKinhTeEnum.TU_BAN_NHA_NUOC;
		case t('enum.loaiThanhPhanKinhTe.TU_NHAN'):
			return ThuocThanhPhanKinhTeEnum.TU_NHAN;
		case t('enum.loaiThanhPhanKinhTe.VON_DAU_TU_NUOC_NGOAI'):
			return ThuocThanhPhanKinhTeEnum.VON_DAU_TU_NUOC_NGOAI;
	}
};

const thuocThanhPhanKinhTeEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
	enumFromLabel: _enumFromLabel,
};

export default thuocThanhPhanKinhTeEnum;
