import { Button, Col, Divider, Form, Input, message, Row } from 'antd';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect } from 'react';
import { withTranslation } from 'react-i18next';
import { useDialog } from 'react-st-modal';
import AuthService from 'services/api/authenService/authService';
import { systemProperties } from 'systemProperties';
import UsersService from 'services/api/authenService/usersService';
import showNotification from 'helpers/showNotification';

interface ChiTietNguoiDungDialogProps {
	username?: string;
	Id?: string;
}

const ChangePassDialog: FunctionComponent<ChiTietNguoiDungDialogProps> = (props: ChiTietNguoiDungDialogProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	useEffect(() => {
		// document.title = t('label.ChangePassWord');
		if (props.username) {
			UsersService.getUser(props.username).then((result: any) => {
				form.setFieldsValue(result);
			});
		}
	}, [form, props.username]);

	const finishCreate = () => {
		if (form.getFieldValue('newPassword') !== form.getFieldValue('reNewPassword')) {
			showNotification('error', t('notification.error'), 'mật khẩu xác nhận không đúng');
			return;
		}
		let formValues: any = {
			userName: form.getFieldValue('UserName'),
			newPassword: form.getFieldValue('newPassword'),
			reNewPassword: form.getFieldValue('reNewPassword'),
		};
		// update
		AuthService.resetPassWordByAdmin(formValues)
			.then(() => {
				showNotification('success', t('notification.success'), t('message.updateSuccess'));
				dialog.close();
			})
			.catch(errors => {
				showNotification('error', t('notification.error'), errors.response.data);
			});
	};

	return (
		<Form className={'common-dialog'} form={form} layout={'vertical'} onFinish={finishCreate}>
			<Col>
				<Form.Item
					name="UserName"
					label={t('label.userName')}
					rules={[
						{
							required: true,
							whitespace: true,
							message: t('login.message.userNameEmpty'),
						},
					]}>
					<Input disabled placeholder={t('label.userName')} />
				</Form.Item>

				<Form.Item
					name="newPassword"
					label={t('label.passwordNew')}
					rules={[
						{
							required: true,
							whitespace: true,
							message: t('login.message.passwordEmpty'),
						},
						{
							pattern: systemProperties.passWord,
							message: 'Mật khẩu không phù hợp. Vui Lòng nhập lại.',
						},
					]}>
					<Input.Password placeholder={t('label.passwordNew')} />
				</Form.Item>

				<Form.Item
					name="reNewPassword"
					label={t('label.passwordConfirm')}
					rules={[
						{
							required: true,
							whitespace: true,
							message: t('login.message.passwordConfirmEmpty'),
						},
					]}>
					<Input.Password placeholder={t('label.passwordConfirm')} />
				</Form.Item>

				<Form.Item>
					<p className="mb20">
						<strong>Lưu ý:</strong> Mật khẩu cần ít nhất 8 ký tự bao gồm: chữ hoa, chữ thường, số và ký tự đặc biệt.
					</p>
				</Form.Item>
			</Col>
			<Divider />
			<Row justify="end" className={`mt10 dialog__buttons`}>
				<div>
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						{'Hủy bỏ'}
					</Button>
					<Button className="ml10" htmlType={'submit'} type="primary">
						{props.username ? t('label.update') : t('label.create')}
					</Button>
				</div>
			</Row>
		</Form>
	);
};

export default withTranslation()(ChangePassDialog);
