import { Form, Select } from 'antd';
import { getDeviceStatusEnumLabel } from 'enum/device-status/device-status.enum';
import { FormInstance } from 'rc-field-form';
import React from 'react';

interface CenterDeviceStatusProps {
	onChangeCenterDeviceStatus?: (value: string) => void;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
	style?: React.CSSProperties;
}

const CenterDeviceStatusSelect = (props: CenterDeviceStatusProps) => {
	const centerDeviceStatuses = getDeviceStatusEnumLabel();
	return (
		<Form.Item
			name="DeviceStatus"
			className={props.formItemClassName}
			rules={props.rules}
			label={props.label}
			style={props.style}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn trạng thái thiết bị"
				optionFilterProp="children"
				className={props.selectClassName}
				onChange={props.onChangeCenterDeviceStatus}
				disabled={props.disabled}>
				{centerDeviceStatuses.map(item => (
					<Select.Option value={item!.value} key={item!.value}>
						{item!.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default CenterDeviceStatusSelect;
