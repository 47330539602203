import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import { LoaiDiemLayNuocEnum } from 'enum/loaiDiemLayNuocEnum';
import { PcccUnit } from 'services/api/inventoryService/pcccUnitService';

export const prefixWaterPoint = '/waterPoint';

export interface WaterPoint {
	LocationDetail?: string;
	DoQuanTrong?: string;
	Id: string;
	Longitude: number;
	Location?: LocationInfo;
	MaDiem?: string;
	MoTa?: string;
	PcccUnit?: PcccUnit;
	PhoneNumber?: string;
	TenDiemLayNuoc?: string;
	Type: LoaiDiemLayNuocEnum;
	Latitude: number;
	WaterForFireTruck?: boolean;
	PcccUnitId?: string;
	PcccUnitName? : string;
}
const _createWaterPoint = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixWaterPoint}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _insertListWaterPoint = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixWaterPoint}/insertListWaterPoint`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteWaterPoint = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixWaterPoint}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findWaterPointByDistrict = (name: string, city: string, district: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixWaterPoint}/name=${name}/city=${city}/district=${district}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getWaterPoint = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixWaterPoint}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listWaterPoint = (pageOption?: PageOption): Promise<WaterPoint[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixWaterPoint}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: WaterPoint[] }) => Promise.resolve(data));

export interface SearchWaterPointParams {
	Name?: string;
	Location?: {
		TinhThanh?: string;
		QuanHuyen?: string;
		XaPhuong?: string;
	};
}

const _searchWaterPoint = (input: SearchWaterPointParams, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixWaterPoint}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _updateWaterPoint = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixWaterPoint}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchWaterPointByLocation = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixWaterPoint}/getByPcccUnitAndLocation`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _instanceOf = (object: any): object is WaterPoint => {
	return object && 'WaterForFireTruck' in object;
};

const exportFileExcel = (payload = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixWaterPoint}/ExportByPcccUnitAndLocation`, payload, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};


export default {
	createWaterPoint: _createWaterPoint,
	deleteWaterPoint: _deleteWaterPoint,
	findWaterPointByDistrict: _findWaterPointByDistrict,
	getWaterPoint: _getWaterPoint,
	instanceOf: _instanceOf,
	listWaterPoint: _listWaterPoint,
	searchWaterPoint: _searchWaterPoint,
	updateWaterPoint: _updateWaterPoint,
	searchWaterPointByLocation: _searchWaterPointByLocation,
	insertListWaterPoint: _insertListWaterPoint,
	exportFileExcel
};
