import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import FireInsuranceEnum from 'enum/fireInsuranceEnum';
import { Moment } from 'moment';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import { CapQuanLyEnum } from 'enum/capQuanLyEnum';
import { LoaiHinHoatDongEnum } from 'enum/loaiHinhHoatDongEnum';
import { ThuocThanhPhanKinhTeEnum } from 'enum/thuocThanhPhanKinhTeEnum';
import { HinhThucDauTuEnum } from 'enum/hinhThucDauTuEnum';
import { LoaiHinhSoHuuEnum } from 'enum/loaiHinhSoHuuEnum';
import { PhoneNumberTypeEnum } from 'enum/PhoneNumberTypeEnum';

export const prefixConstruction = '/construction';

// const INVENTORY_BASE_URL = 'http://192.168.1.64:8000/api';
export interface PhoneNumberInfo {
	FullName?: string;
	Info?: string;
	PhoneNumber?: string;
	PhoneNumberType: PhoneNumberTypeEnum;
}

export interface Construction {
	BaoHiemChayNo: FireInsuranceEnum;
	CanBoKiemTra?: string;
	CapQuanLy: CapQuanLyEnum;
	ChuCSCT?: string;
	CustomerId?: string;
	LocationDetail?: string;
	DonViPcccID?: string;
	Email?: string;
	ExpiredDate: Moment;
	HinhThucDauTu: HinhThucDauTuEnum;
	HuongVaoTiepCanCSCT?: string;
	Id: string;
	Longitude: number;
	LoaiHinhHoatDong: LoaiHinHoatDongEnum;
	LoaiHinhSoHuu: LoaiHinhSoHuuEnum;
	MaCoSoCongTrinh?: string;
	NamThanhLap: number;
	NghanhLinhVuc?: string;
	RegisterDate: Moment;
	SoDienThoai?: PhoneNumberInfo[];
	TenCoSoCongTrinh?: string;
	ThanhPhanKinhTe: ThuocThanhPhanKinhTeEnum;
	Location: LocationInfo;
	Latitude: number;
	Website?: string;
	PcccUnitName?: string;
	Name?: string;
	FileNameUrl?: any;
}

const _createConstruction = (input: InputConstruction): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteConstruction = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixConstruction}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findConstructionByName = (constructionName: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/getByName=${constructionName}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _findConstructionByPcccUnit = (code: string, locationId: string, pcccUnitId: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/code=${code}/location=${locationId}/pcccUnit=${pcccUnitId}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getConstruction = (id: string): Promise<Construction> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/id=${id}`)
		.then(({ data }: { data: Construction }) => Promise.resolve(data));

const _getConstructionByCustomer = (): Promise<Construction> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/customer`)
		.then(({ data }: { data: Construction }) => Promise.resolve(data));

const _listConstruction = (pageOption?: PageOption): Promise<Construction[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: Construction[] }) => Promise.resolve(data));

const _getMapByFireId = (input: any): Promise<Construction> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/search-object-by-radius`, input)
		.then(({ data }: { data: Construction }) => Promise.resolve(data));

const _searchDataMonitoring = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/searchDataMonitoring?textSearch=${input}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _getConstructionCurrenUser = (CustomerId?: string, pageOption?: PageOption): Promise<PageOption<any>> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixConstruction}/dataConstructionCurrentUser?customerId=${CustomerId}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

export interface InputConstruction {
	BaoHiemChayNo: FireInsuranceEnum;
	CanBoKiemTra?: Array<string>;
	CapQuanLy: CapQuanLyEnum;
	ChuCSCT?: string;
	CustomerId?: string;
	LocationDetail?: string;
	DonViPcccID?: string;
	Email?: string;
	HinhThucDauTu: HinhThucDauTuEnum;
	HuongVaoTiepCanCSCT?: string;
	Id?: string;
	Longitude: number;
	LoaiHinhHoatDong: LoaiHinHoatDongEnum;
	LoaiHinhSoHuu: LoaiHinhSoHuuEnum;
	MaCoSoCongTrinh?: string;
	NganhLinhVuc?: string;
	SoDienThoai?: PhoneNumberInfo[];
	TenCoSoCongTrinh?: string;
	ThanhPhanKinhTe: ThuocThanhPhanKinhTeEnum;
	Location: LocationInfo;
	Latitude: number;
	Website?: string;
	FileNameUrl: string;
	AlertDelay: boolean;
	AprroveOperateDate: Date;
	ApprovePcccDate: Date;
	InstallUnit?: string;
	DelayTime?: number;
}

const _updateConstruction = (input: InputConstruction): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixConstruction}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchConstruction = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _SelectBoxForContruction = (input: any): Promise<Construction[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/comboboxItem`, input)
		.then(({ data }: { data: Construction[] }) => Promise.resolve(data));

const _searchConstructionByLocation = (input: LocationInfo): Promise<Construction[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/searchByLocation`, input)
		.then(({ data }: { data: Construction[] }) => Promise.resolve(data));

const _searchForReport = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/searchForReport`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _instanceOf = (object: any): object is Construction => {
	return object && 'BaoHiemChayNo' in object;
};

const _createListConstructions = (requestBody: any) => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/insertListConstruction`, requestBody)
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const exportFileExcel = (payload = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/export`, payload, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const getConstructionsByIds = (ids: string[], pageOption?: PageOption): Promise<PageOption<any>> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/constructionsByListId`, ids, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const searchConstructionForChecking = (params = {}, pageOption?: PageOption): Promise<PageOption<any>> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/searchForChecking`, params, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

export interface HistoryPayload {
	Id: string;
	HistoryType?: string;
	ChangeTimeFrom?: string;
	ChangeTimeTo?: string;
	ChangeBy?: string;
	DeviceStatus?: boolean;
}

const _searchHistory = (params: HistoryPayload): Promise<PageOption<any>> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixConstruction}/searchHistory`, params)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const ConstructionService = {
	createConstruction: _createConstruction,
	deleteConstruction: _deleteConstruction,
	findConstructionByName: _findConstructionByName,
	findConstructionByPcccUnit: _findConstructionByPcccUnit,
	getConstruction: _getConstruction,
	getConstructionByCustomer: _getConstructionByCustomer,
	instanceOf: _instanceOf,
	listConstruction: _listConstruction,
	updateConstruction: _updateConstruction,
	searchConstruction: _searchConstruction,
	searchConstructionByLocation: _searchConstructionByLocation,
	searchForReport: _searchForReport,
	createListConstructions: _createListConstructions,
	getMapByFireId: _getMapByFireId,
	exportFileExcel,
	getConstructionsByIds,
	searchConstructionForChecking,
	selectBoxForContruction: _SelectBoxForContruction,
	searchDataMonitoring: _searchDataMonitoring,
	getConstructionCurrenUser: _getConstructionCurrenUser,
	searchHistory: _searchHistory,
};

export default ConstructionService;
