import ApiService, { FACT_BASE_URL, PageOption } from '../../index';

export const prefixErrorManagement = '/error-info';

export interface CreateErrorParams {
	Id?: string;
	ErrorCode: string;
	Description?: string;
	PassFrom?: number;
	PassTo?: number;
	fileUpload?: any;
}

const _getbyCode = (code: any, pageOption?: PageOption): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.get(
			code ? `${prefixErrorManagement}/search-by-code?code=${code}` : `${prefixErrorManagement}/search-by-code`,
			{
				headers: {
					PageOption: JSON.stringify(pageOption),
				},
			},
		)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getbyId = (id: string): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.get(`${prefixErrorManagement}/get-by-id/${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _createErrorInfo = (input: CreateErrorParams): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.post(`${prefixErrorManagement}/create`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _updateErrorInfo = (input: CreateErrorParams): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.put(`${prefixErrorManagement}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteErrorInfo = (Id: string): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.delete(`${prefixErrorManagement}/delete/${Id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

export default {
	deleteErrorInfo: _deleteErrorInfo,
	getbyCode: _getbyCode,
	getbyId: _getbyId,
	updateErrorInfo: _updateErrorInfo,
	createErrorInfo: _createErrorInfo,
};
