import { RightOutlined } from '@ant-design/icons';
import { DatePicker, Form } from 'antd';
import CommonControl from 'components/page/CommonControl';
import { FormInstance } from 'rc-field-form';
import React from 'react';
import { systemProperties } from 'systemProperties';

interface RangePickerInputProps {
	onChangeDatePicker?: (value: any) => void;
	disabled?: boolean;
	form?: FormInstance<any>;
	rules?: any;
	className?: string;
	label?: string;
	disabledDate?: (currentDate?: any) => boolean;
	style?: React.CSSProperties;
	name?: string;
	placeholder?: any;
}

const RangePickerInput: React.FC<RangePickerInputProps> = ({
	onChangeDatePicker,
	disabled = false,
	rules,
	className,
	label,
	disabledDate,
	style,
	name = 'dateRange',
	placeholder = ['Ngày bắt đầu', 'Ngày kết thúc'],
}: RangePickerInputProps) => {
	return (
		<Form.Item name={name} rules={rules} className={className} label={label} style={style}>
			<DatePicker.RangePicker
				locale={CommonControl.localeVi}
				format={systemProperties.dateFormat}
				placeholder={placeholder}
				separator={<RightOutlined style={{ color: '#00000040', fontSize: '12px' }} />}
				onChange={onChangeDatePicker}
				disabled={disabled}
				disabledDate={disabledDate}
			/>
		</Form.Item>
	);
};

export default RangePickerInput;
