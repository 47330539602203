import ApiService, { FACT_BASE_URL, PageOption } from '../../index';

export const prefixReportTest = '/Report-Pact';

const _search = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.post(`${prefixReportTest}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

export default {
	search: _search,
};
