import { combineReducers, createStore } from 'redux';
import checkerSelectedReducer from 'redux/checkers-selected/checkers-selected.reducer';
import fireTrucksSelectedReducer from 'redux/fire-trucks-selected/fire-trucks-selected.reducer';
import locationSelectedReducer from 'redux/location-selected/location-selected.reducer';
import locationReducer from 'redux/location/location.reducer';
import navBarReducer from 'redux/NavBar/navBar.reducer';
import navigateFromDashboardReducer from 'redux/navigate-from-dashboard/navigate-from-dashboard.reducer';
import dialogReducer from './dialog/dialog.reducer';
import firebaseMessageReducer from './firebaseMessage/firebaseMessage.reducer';
import permissionReducer from './Permission/permission.reducer';

export interface ReduxAction {
	type: string;
	payload: any;
}

export default createStore(
	combineReducers({
		navBar: navBarReducer,
		firebaseMessage: firebaseMessageReducer,
		permission: permissionReducer,
		locationState: locationReducer,
		dialogState: dialogReducer,
		navigateState: navigateFromDashboardReducer,
		checkerSelectedState: checkerSelectedReducer,
		fireTrucksSelectedState: fireTrucksSelectedReducer,
		locationSelectedState: locationSelectedReducer,
	}),
);
