import { Form, Select } from 'antd';
import React, { useState } from 'react';
import ConstructionService from 'services/api/inventoryService/constructionService';
import useDeepCompareEffect from 'use-deep-compare-effect';
import { FormInstance } from 'rc-field-form';

interface Location {
	Location: { TinhThanh: string; QuanHuyen: string };
}

interface ConstructionProps {
	name: string;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
	onChangeConstruction?: (value: string) => void;
	location: Location;
	unitId?: string;
}

const ConstructionSelect = (props: ConstructionProps) => {
	const {
		name,
		formItemClassName,
		label,
		selectClassName,
		onChangeConstruction: onChangeUnitManager,
		disabled,
		rules,
		location,
		unitId,
	} = props;

	const [constructions, setConstructions] = useState<any[]>([]);

	useDeepCompareEffect(() => {
		if (location.Location.TinhThanh) {
			const params = { ...(unitId && { PcccUnitId: unitId }), Location: location.Location };
			ConstructionService.searchConstruction(params).then(constructions => {
				setConstructions(constructions);
			});
		} else {
			setConstructions([]);
		}
	}, [location, unitId]);

	return (
		<Form.Item name={name} className={formItemClassName} label={label} rules={rules}>
			<Select
				showSearch
				allowClear
				optionFilterProp="children"
				placeholder="Chọn cơ sở công trình"
				disabled={disabled}
				notFoundContent={'Bạn chưa chọn quận huyện'}
				className={selectClassName}
				onChange={onChangeUnitManager}>
				{constructions.length > 0 &&
					constructions.map(construction => (
						<Select.Option key={construction.Id} value={construction.Id}>
							{construction.TenCoSoCongTrinh}
						</Select.Option>
					))}
			</Select>
		</Form.Item>
	);
};

export default ConstructionSelect;
