import { Button, Col, Divider, Form, Input, Row, Select } from 'antd';
import { blockInvalidChar } from 'components/page/CommonControl/BlockInvalidChar';
import ColumnTitle from 'components/shared/ColumnTitle/ColumnTitle';
import CustomMap, { CustomMapIn, CustomMapOut } from 'components/shared/CustomMap/CustomMap';
import { MarkerType } from 'components/shared/Map/ToaDoMarker';
import loaiHinhDonViEnum from 'enum/loaiHinhDonViEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { useDialog } from 'react-st-modal';
import { UserInfo } from 'services/api/authenService/usersService';
import coordinatesService from 'services/api/coordinatesService';
import pcccUnitService from 'services/api/inventoryService/pcccUnitService';
import Generator from 'services/generator';
import { systemProperties } from 'systemProperties';
import './DialogMap.scss';

interface DialogThemMoiDonViPcccProps {
	mode?: 'edit' | 'add' | 'view';
	id?: string;
	callback?: () => void;
	userInfo?: UserInfo;
}

const DialogThemMoiDonViPccc: FunctionComponent<DialogThemMoiDonViPcccProps> = (props: DialogThemMoiDonViPcccProps) => {
	const [form] = Form.useForm();
	const dialog = useDialog();

	// state of component
	const [mapInput, setMapInput] = useState<CustomMapIn>();
	const [mapOutput, setMapOutput] = useState<CustomMapOut>();

	const postUnitPccc = async () => {
		const validated = await form.validateFields();
		const id = props.id;
		if (validated) {
			const unitPcccInfo: any = {
				TenDonVi: form.getFieldValue('TenDonVi'),
				MaDonVi: form.getFieldValue('MaDonVi'),
				PhoneNumber: form.getFieldValue('PhoneNumber'),
				SoDienThoaiTrucBan: form.getFieldValue('SoDienThoaiTrucBan'),
				CanBoQuanLy: form.getFieldValue('CanBoQuanLy'),
				Email: form.getFieldValue('Email'),
				SoDiemLayNuocQuanLy: form.getFieldValue('SoDiemLayNuocQuanLy'),
				SoNhanSuQuanLy: form.getFieldValue('SoNhanSuQuanLy'),
				SoNhanSuChuaChay: form.getFieldValue('SoNhanSuChuaChay'),
				LoaiHinhDonVi: form.getFieldValue('LoaiHinhDonVi'),
				...mapOutput,
			};

			try {
				if (id) {
					unitPcccInfo.Id = id;
					await pcccUnitService.updatePcccUnit(unitPcccInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_update_desc', { item: form.getFieldValue('TenDonVi') }),
					);
				} else {
					// add a new unit support
					await pcccUnitService.createPcccUnit(unitPcccInfo);
					showNotification(
						'success',
						t('notification.success'),
						t('notification.success_create_desc', { item: form.getFieldValue('TenDonVi') }),
					);
				}

				dialog.close();
				if (props.callback) {
					props.callback();
				}
			} catch (error: any) {
				showNotification('error', t('notification.error'), error.response.data);
			}
		}
	};

	useEffect(() => {
		const id = props.id;

		const getPcccUnit = async (id: string) => {
			const data = await pcccUnitService.getPcccUnit(id);
			form.setFieldsValue(data);
			setMapInput(data);
		};

		if (id) {
			getPcccUnit(id);
		} else {
			coordinatesService
				.findCoordinateById(props.userInfo && props.userInfo.Locations ? props.userInfo.Locations[0]?.CityId : '01')
				.then(coordinate => {
					if (coordinate) {
						setMapInput({ Latitude: coordinate.latitude, Longitude: coordinate.longitude });
					}
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.id, form]);

	const handleChangeInputMapData = (newVal: CustomMapOut) => {
		setMapOutput(newVal);
	};

	return (
		<div className="common-dialog dialog-units-support">
			<Form layout="vertical" name="normal_login" form={form}>
				<Row gutter={32} className="mb15">
					<Col span={10}>
						<ColumnTitle columnTitle="I. Vị trí" />
						<CustomMap
							containerClass={'height-map'}
							form={form}
							data={mapInput}
							markerType={MarkerType.DON_VI_PCCC}
							changeDataCallback={handleChangeInputMapData}
						/>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="II. Thông tin chung" />
						<div>
							<Form.Item
								label="Mã đơn vị"
								name="MaDonVi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Mã đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập mã đơn vị" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Tên đơn vị"
								name="TenDonVi"
								rules={[
									{
										required: true,
										whitespace: true,
										message: 'Tên đơn vị không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập tên đơn vị" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item label="Cán bộ quản lý" name="CanBoQuanLy">
								<Input placeholder="Nhập cán bộ quản lý" disabled={props.mode === 'view'} />
							</Form.Item>

							<Form.Item
								label="Số điện thoại cán bộ quản lý"
								name="PhoneNumber"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder="Nhập Số điện thoại"
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item
								label="Số điện thoại trực ban"
								name="SoDienThoaiTrucBan"
								rules={[
									{
										pattern: systemProperties.phonePattern,
										message: t('validation.error.phoneNumber_format'),
									},
									{
										required: true,
										whitespace: true,
										message: 'Số điện thoại không được bỏ trống',
									},
								]}>
								<Input
									className="ant-input-affix-wrapper"
									type="text"
									placeholder="Nhập Số điện thoại trực ban"
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item
								name="LoaiHinhDonVi"
								label="Loại hình đơn vị"
								rules={[
									{
										required: true,
										message: 'Loại hình đơn vị không được bỏ trống',
									},
								]}>
								<Select
									showSearch
									optionFilterProp="children"
									placeholder="Chọn đơn vị"
									allowClear
									disabled={props.mode === 'view'}>
									{Generator.generateOptions(loaiHinhDonViEnum.getAll())}
								</Select>
							</Form.Item>

							<Form.Item
								label="Email"
								name="Email"
								rules={[
									{
										pattern: systemProperties.emailPattern,
										message: 'Email không đúng định dạng!',
									},
									{
										required: true,
										whitespace: true,
										message: 'Email không được bỏ trống',
									},
								]}>
								<Input placeholder="Nhập Email" disabled={props.mode === 'view'} />
							</Form.Item>
						</div>
					</Col>

					<Col span={7}>
						<ColumnTitle columnTitle="III. Thông tin thêm" />
						<div>
							<Form.Item label="Số nhân sự PCCC quản lý" name="SoNhanSuQuanLy">
								<Input
									type="number"
									onKeyDown={blockInvalidChar}
									placeholder="Nhập số nhân sự PCCC quản lý"
									min={0}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item label="Số điểm lấy nước quản lý" name="SoDiemLayNuocQuanLy">
								<Input
									type={'number'}
									onKeyDown={blockInvalidChar}
									placeholder="0"
									min={0}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>

							<Form.Item label="Số lượng nhân sự chữa cháy" name="SoNhanSuChuaChay">
								<Input
									type={'number'}
									onKeyDown={blockInvalidChar}
									placeholder="0"
									min={0}
									disabled={props.mode === 'view'}
								/>
							</Form.Item>
						</div>
					</Col>
				</Row>

				<Divider />

				<div className="dialog__buttons mt10">
					<Button
						type="default"
						onClick={() => {
							dialog.close();
						}}>
						{props.mode === 'view' ? 'Đóng' : 'Hủy bỏ'}
					</Button>

					{props.mode !== 'view' && (
						<Button type="primary" htmlType="submit" onClick={postUnitPccc}>
							{props.mode === 'add' ? 'Thêm mới' : 'Lưu'}
						</Button>
					)}
				</div>
			</Form>
		</div>
	);
};

export default DialogThemMoiDonViPccc;
