import React from 'react';

export const blockInvalidChar = (e: React.KeyboardEvent<HTMLInputElement>) => {
	return ['e', 'E', '+', '-'].includes(e.key) && e.preventDefault();
};

export const blockInvalidCharMinute = (e: React.KeyboardEvent<HTMLInputElement>) => {
	return ['e', 'E', '+', '-', '.', ','].includes(e.key) && e.preventDefault();
};

export const blockSpaceForPassWord = (e: React.KeyboardEvent<HTMLInputElement>) => {
	return [' '].includes(e.key) && e.preventDefault();
};

export const isPhone = (mobile: any) => {
	const vnf_regex = /^[\d_-]{9}$|^[\d_-]{12}$/g;
	return vnf_regex.test(mobile);
};

export const formatToCurrency = (amount: number) => {
	return `${amount.toString()}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const onlyNumberKeys = (event: React.KeyboardEvent<HTMLInputElement>) =>
	!/[0-9]/.test(event.key) && event.preventDefault();
