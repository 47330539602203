import { PermissionEnum } from 'enum/permissionEnum';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import PermissionSelector from 'redux/Permission/permission.selector';
import useDeepCompareEffect from 'use-deep-compare-effect';

export interface ShowForPermissionProps {
	allowedPermissions: PermissionEnum[];
	children: JSX.Element;
}

export interface ShowForPermissionStateProps {
	permissionStateList: PermissionEnum[];
}

const mapStateToProps = (): ShowForPermissionStateProps => ({
	permissionStateList: PermissionSelector.getPermissionList(),
});

const ShowForPermission = (props: ShowForPermissionProps & ShowForPermissionStateProps) => {
	const { permissionStateList, allowedPermissions: permissions, children } = props;
	const [matchedPermissions, setMatchedPermissions] = useState<PermissionEnum[]>([]);

	useDeepCompareEffect(() => {
		const matchedPermissions = permissionStateList.filter(item => permissions.includes(item));
		setMatchedPermissions(matchedPermissions);
	}, [permissionStateList, permissions]);

	return matchedPermissions.length > 0 ? children : null;
};

export default connect<ShowForPermissionStateProps, ShowForPermissionProps>(mapStateToProps)(ShowForPermission);
