import { UserInfo } from 'services/api/authenService/usersService';
import { PermissionEnum } from 'enum/permissionEnum';
import { ReduxAction } from 'redux/store';
import { SET_PERMISSION_LIST, SET_USER_INFO } from './permission.type';

interface PermissionState {
	permissionList: PermissionEnum[];
	userInfo?: UserInfo;
}

const DEFAULT_PERMISSION_STATE: PermissionState = { permissionList: [] };

const permissionReducer = (state = DEFAULT_PERMISSION_STATE, action: ReduxAction): PermissionState => {
	switch (action.type) {
		case SET_PERMISSION_LIST:
			return {
				...state,
				permissionList: action.payload,
			};
		case SET_USER_INFO:
			return {
				...state,
				userInfo: action.payload,
			};
		default:
			return state;
	}
};

export default permissionReducer;
