export interface coordinates {
	Id: string;
	Name: string;
	latitude: number;
	longitude: number;
}

const data: coordinates[] = require('./data.json');

const _findCoordinateById = (id?: string): Promise<coordinates | undefined> =>
	Promise.resolve(data.find(r => r.Id === id));

const coordinatesService = {
	findCoordinateById: _findCoordinateById,
};

export default coordinatesService;
