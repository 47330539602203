import { ACTION_TYPE } from 'redux/location-selected/location-selected.action';
import { ReduxAction } from 'redux/store';

export interface LocationSelectedState {
	provinceId: string;
	districtId?: string;
}

const INITIAL_STATE: LocationSelectedState = { provinceId: '', districtId: '' };

const locationSelectedReducer = (state = INITIAL_STATE, action: ReduxAction): LocationSelectedState => {
	switch (action.type) {
		case ACTION_TYPE.SELECT_LOCATION:
			return {
				...state,
				...action.payload,
			};
		case ACTION_TYPE.RESET_LOCATION:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default locationSelectedReducer;
