import ApiService, { HANDLER_BASE_URL } from '../../index';

const prefixNotifications = '/Notifications';

const _fbRegister = (token: string): Promise<string> =>
	ApiService(HANDLER_BASE_URL)
		.post(`${prefixNotifications}/fb-register`, {
			Token: token,
		})
		.then(() => Promise.resolve(token));

const _fbUnregister = (token: string): Promise<void> =>
	ApiService(HANDLER_BASE_URL).post(`${prefixNotifications}/fb-unregister`, {
		Token: token,
	});

interface FbSendNotificationParams {
	Content: string;
	CustomerId: string;
	Title: string;
}

const _fbSendNotification = (params: FbSendNotificationParams): Promise<any> =>
	ApiService(HANDLER_BASE_URL)
		.post(`${prefixNotifications}/fb-send-notification`, params)
		.then(({ data }: { data: any }) => Promise.resolve(data));

interface SmsSendParams {
	Content: string;
	ToNumber: string;
}

const _smsSend = (params: SmsSendParams): Promise<any> =>
	ApiService(HANDLER_BASE_URL)
		.post(`${prefixNotifications}/sms-send`, params)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _makeCall = (params: SmsSendParams): Promise<any> =>
	ApiService(HANDLER_BASE_URL)
		.post(`${prefixNotifications}/make-call`, params)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _stringeeCallBack = (): Promise<any> =>
	ApiService(HANDLER_BASE_URL)
		.post(`${prefixNotifications}/stringee-call-back`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const getNewStringeeToken = (): Promise<any> =>
	ApiService(HANDLER_BASE_URL)
		.get(`${prefixNotifications}/refresh-token-click-to-call`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const notificationsService = {
	fbRegister: _fbRegister,
	fbSendNotification: _fbSendNotification,
	fbUnregister: _fbUnregister,
	makeCall: _makeCall,
	smsSend: _smsSend,
	stringeeCallBack: _stringeeCallBack,
	getNewStringeeToken,
};

export default notificationsService;
