import { t } from 'i18next';

export enum LoaiCamBienEnum {
	CAM_BIEN_KHOI = 'CAM_BIEN_KHOI',
	CAM_BIEN_NHIET = 'CAM_BIEN_NHIET',
	CAM_BIEN_KHOI_NHIET = 'CAM_BIEN_KHOI_NHIET',
	CHUONG_DEN = 'CHUONG_DEN',
}

interface LoaiCamBien {
	label: string;
	value: LoaiCamBienEnum;
}

const _fromEnum = (loaiCamBienEnum: LoaiCamBienEnum): LoaiCamBien => {
	switch (loaiCamBienEnum) {
		case LoaiCamBienEnum.CAM_BIEN_KHOI:
			return {
				label: t('enum.loaiCamBien.CAM_BIEN_KHOI'),
				value: LoaiCamBienEnum.CAM_BIEN_KHOI,
			};
		case LoaiCamBienEnum.CAM_BIEN_NHIET:
			return {
				label: t('enum.loaiCamBien.CAM_BIEN_NHIET'),
				value: LoaiCamBienEnum.CAM_BIEN_NHIET,
			};
		case LoaiCamBienEnum.CAM_BIEN_KHOI_NHIET:
			return {
				label: t('enum.loaiCamBien.CAM_BIEN_KHOI_NHIET'),
				value: LoaiCamBienEnum.CAM_BIEN_KHOI_NHIET,
			};
		case LoaiCamBienEnum.CHUONG_DEN:
			return {
				label: t('enum.loaiCamBien.CHUONG_DEN'),
				value: LoaiCamBienEnum.CHUONG_DEN,
			};
	}
};

const _enumFromLabel = (loaiCamBienLabel: string): LoaiCamBienEnum | undefined => {
	switch (loaiCamBienLabel.toUpperCase()) {
		case t('enum.loaiCamBien.CAM_BIEN_KHOI').toUpperCase():
			return LoaiCamBienEnum.CAM_BIEN_KHOI;
		case t('enum.loaiCamBien.CAM_BIEN_NHIET').toUpperCase():
			return LoaiCamBienEnum.CAM_BIEN_NHIET;
		case t('enum.loaiCamBien.CAM_BIEN_KHOI_NHIET').toUpperCase():
			return LoaiCamBienEnum.CAM_BIEN_KHOI_NHIET;
		case t('enum.loaiCamBien.CHUONG_DEN').toUpperCase():
			return LoaiCamBienEnum.CHUONG_DEN;
	}
};

const _getAll = (): LoaiCamBien[] => {
	return Object.values(LoaiCamBienEnum).map((r) => _fromEnum(r as LoaiCamBienEnum));
};

const loaiCamBienEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default loaiCamBienEnum;
