import { EyeOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination, Tooltip } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import DialogThemMoiDiemLayNuoc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDiemLayNuoc';
import DialogThemMoiDonViPccc from 'components/page/QuanLyDonviPhongChay/Dialogs/DialogThemMoiDonViPccc';
import { QuanLyDonViPhongChayTabProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import { QuanLyDonViPhongChayTab } from 'components/page/QuanLyDonviPhongChay/Tabs/QuanLyDonVi/QuanLyDonVi';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import ExportExcelComponent from 'components/shared/ExportExcelComponent/ExportExcelComponent';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import loaiDiemLayNuocEnum, { LoaiDiemLayNuocEnum } from 'enum/loaiDiemLayNuocEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import { downloadExcelFile, showDetailCellTableInfo, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import { default as WaterPointService } from 'services/api/inventoryService/waterPointService';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

const DanhSachDiemLayNuoc: FunctionComponent<QuanLyDonViPhongChayTabProps> = props => {
	const columnsDsDiemLayNuoc = [
		{
			title: 'STT',
			dataIndex: 'key',
			align: 'center' as const,
			width: 45,
			key: 'name',
			ellipsis: {
				showTitle: false,
			},
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên điểm lấy nước',
			dataIndex: 'TenDiemLayNuoc',
			key: 'TenDiemLayNuoc',
			ellipsis: {
				showTitle: false,
			},
			render: (TenDiemLayNuoc: string) => (
				<Tooltip placement="topLeft" title={TenDiemLayNuoc}>
					{TenDiemLayNuoc}
				</Tooltip>
			),
		},
		{
			title: 'Đơn vị quản lý',
			dataIndex: 'PcccUnitName',
			key: 'PcccUnitName',
			ellipsis: {
				showTitle: false,
			},
			render: (TenDonViQuanLy: string) => (
				<Tooltip placement="topLeft" title={TenDonViQuanLy}>
					<span>{TenDonViQuanLy}</span>
				</Tooltip>
			),
		},
		{
			title: 'Loại điểm lấy nước',
			dataIndex: 'Type',
			key: 'Type',
			ellipsis: {
				showTitle: false,
			},
			render: (type: LoaiDiemLayNuocEnum) => {
				const label = loaiDiemLayNuocEnum.fromEnum(type)?.label;
				return (
					<Tooltip placement="topLeft" title={label}>
						{label}
					</Tooltip>
				);
			},
		},
		{
			title: 'Địa chỉ',
			dataIndex: 'LocationDetail',
			key: 'LocationDetail',
			ellipsis: {
				showTitle: false,
			},
			render: (LocationDetail: string) => (
				<Tooltip placement="topLeft" title={LocationDetail}>
					{LocationDetail}
				</Tooltip>
			),
		},
		{
			title: 'Mô tả',
			dataIndex: 'MoTa',
			key: 'MoTa',
			ellipsis: {
				showTitle: false,
			},
			render: (MoTa: string) => (
				<Tooltip placement="topLeft" title={MoTa}>
					{MoTa}
				</Tooltip>
			),
		},
		{
			className: 'nowrap',
			title: 'Chi tiết',
			width: 60,
			key: 'detail',
			align: 'center' as const,
			hidden: !props.isModeView,
			render: (_: any, record: any) => (
				<Button type={'primary'}>
					<Link
						to={{
							pathname: '/chiTietDiemLayNuoc',
							search: `?id=${record.Id}`,
							hash: 'detail',
						}}>
						<EyeOutlined />
					</Link>
				</Button>
			),
		},
		{
			className: 'nowrap',
			title: 'Thao tác',
			key: 'action',
			align: 'center' as const,
			width: 100,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.WATER_POINT_EDIT) ||
				props.permissionList.includes(PermissionEnum.WATER_POINT_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.WATER_POINT_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.WATER_POINT_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					className="dialog-water-supply-add"
					editTitle="Chỉnh sửa thông tin điểm lấy nước"
					EditDialog={
						<DialogThemMoiDiemLayNuoc
							userInfo={props.userInfo}
							id={record.Id}
							mode="edit"
							callback={getDataDiemLayNuocByLocation}
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.TenDiemLayNuoc}
							callback={getDataDiemLayNuocByLocation}
							deleteService={WaterPointService.deleteWaterPoint}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	const [form] = Form.useForm();

	const [tongDiemLayNuoc, setTongDiemLayNuoc] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSourceDsDiemLayNuoc, setDataSourceDsDiemLayNuoc] = useState<any[]>([]);

	useEffect(() => {
		if (props.searchData) {
			getDataDiemLayNuocByLocation(CURRENT_PAGE);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.searchData]);

	useEffect(() => {
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0 && !props.isNavigate) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.WATER_POINT_VIEW])) {
				getDataDiemLayNuocByLocation(CURRENT_PAGE);
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList, props.isNavigate]);

	const handleKeyDown = (event: any) => {
		if (event.nativeEvent.key === 'Enter') {
			getDataDiemLayNuocByLocation(CURRENT_PAGE);
		}
	};

	const getRequestPayload = useCallback(() => {
		const waterCollectionPointName = form.getFieldValue('timKiemLayNuoc');
		const param = {
			...props.searchData,
			...(waterCollectionPointName && { Name: waterCollectionPointName }),
		};

		return param;
	}, [props.searchData, form]);

	const getDataDiemLayNuocByLocation = (page = currentPage, pageSize = pageSizeDefault) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		const param = getRequestPayload();
		WaterPointService.searchWaterPointByLocation(param, pageOption)
			.then(res => {
				setTongDiemLayNuoc(res.TotalItems);
				setDataSourceDsDiemLayNuoc(res.Data);
			})
			.catch();
	};

	function handleChangePage(page: number, pageSize: number) {
		getDataDiemLayNuocByLocation(page, pageSize);
	}

	const onClickRefresh = () => {
		form.resetFields();
		props.onResetFormAction && props.onResetFormAction();
	};

	const handleExportFileExcel = useCallback(() => {
		const exportFilePayload = getRequestPayload();
		WaterPointService.exportFileExcel(exportFilePayload)
			.then(data => {
				const fileName = 'Danh_Sach_Diem_Lay_Nuoc.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	}, [getRequestPayload]);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> điểm lấy nước
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={tongDiemLayNuoc}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(
					<DialogThemMoiDiemLayNuoc
						userInfo={props.userInfo}
						id={record.Id}
						mode="view"
						callback={getDataDiemLayNuocByLocation}
					/>,
					{
						className: 'dialog-water-supply-add',
						title: `${record.TenDiemLayNuoc}`,
						showCloseIcon: true,
						isCanClose: true,
					},
				);
			},
		};
	};

	return (
		<Form
			layout="vertical"
			name="normal_login"
			className="content-container flex--fill p0"
			form={form}
			disabled={props.isDisable}>
			<div id={QuanLyDonViPhongChayTab.DIEM_LAY_NUOC} className={'tab__content'}>
				<div className={'flex--row justify-content--between mb15'}>
					<div className="search-input">
						<FormItem name="timKiemLayNuoc">
							<Input
								placeholder="Nhập tên điểm lấy nước"
								prefix={<SearchOutlined />}
								onKeyPress={handleKeyDown}
								allowClear
							/>
						</FormItem>
					</div>

					<div>
						{/* <Button
							className="ml10"
							style={{ display: props.isModeView ? 'none' : '' }}
							type={'default'}
							onClick={() => {
								onClickRefresh();
							}}>
							<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
						</Button> */}

						<Link to={'/excelTemplate/Điểm lấy nước.xlsx'} target={'_blank'} download>
							<Button type={'default'} className="ml10" style={{ display: props.isModeView ? 'none' : '' }}>
								<img className="padding-image" style={{ width: 12 }} src="icon/downExcel.png" alt="Tải file" />{' '}
								{t('label.taiFileMau')}
							</Button>
						</Link>

						{props.permissionList.includes(PermissionEnum.WATER_POINT_EXPORT) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<ExportExcelComponent
								className="ml10"
								dataSources={dataSourceDsDiemLayNuoc}
								onExportFileExcel={handleExportFileExcel}
							/>
						) : null}

						{props.permissionList.includes(PermissionEnum.FIRE_TRUCK_CREATE) ||
						props.permissionList.includes(PermissionEnum.ADMIN) ? (
							<Button
								className="ml10"
								type={'primary'}
								onClick={async () => {
									await CustomDialog(
										<DialogThemMoiDiemLayNuoc
											userInfo={props.userInfo}
											mode="add"
											location={props.searchData?.Location}
											donviId={props.donviId}
											callback={getDataDiemLayNuocByLocation}
											mapInput = {props.mapInput}
										/>,
										{
											className: 'dialog-water-supply-add',
											title: 'Thêm mới điểm lấy nước',
											isCanClose: true,
											showCloseIcon: true,
										},
									);
								}}>
								<PlusOutlined />
								Thêm mới
							</Button>
						) : null}
					</div>
				</div>

				<TableComponent
					dataSource={dataSourceDsDiemLayNuoc}
					columns={columnsDsDiemLayNuoc}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					paginator={false}
					onRow={handleShowViewDetail}
				/>
			</div>
		</Form>
	);
};

export default withTranslation()(DanhSachDiemLayNuoc);
