import ApiService, { FACT_BASE_URL, PageOption } from '../../index';

export const prefixDeviceType = '/device-type';


export interface CreateDeviceTypeParams {
	Id?: string;
	DeviceTypeCode: string;
	Description?: string;
}

const _getbyCode = (code: any, pageOption?: PageOption): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.get(code ? `${prefixDeviceType}/search-by-code?code=${code}` : `${prefixDeviceType}/search-by-code`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getbyId = (id: string): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.get(`${prefixDeviceType}/get-by-id/${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _createDeviceType = (input: any): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.post(`${prefixDeviceType}/create`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _updateDeviceType = (input: any): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.put(`${prefixDeviceType}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteDeviceType = (Id: string): Promise<any> =>
	ApiService(FACT_BASE_URL)
		.delete(`${prefixDeviceType}/delete/${Id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

export default {
	deleteDeviceType: _deleteDeviceType,
	getbyCode: _getbyCode,
	getbyId: _getbyId,
	updateDeviceType: _updateDeviceType,
	createDeviceType: _createDeviceType,
};
