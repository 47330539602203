import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { Construction } from 'services/api/inventoryService/constructionService';

export const prefixDevice = '/devices';

interface Location {
	CityId?: string;
	DistrictId?: string;
	WardId?: string;
}
export interface DeviceSummaryReportRequest {
	PcccUnitId?: string;
	Location?: Location;
	FromDate?: string;
	ToDate?: string;
}

export interface DeviceSummaryReport {
	Construction: Construction;
	TenDonVi?: string;
	DeviceSum: number;
	DeviceCount: number;
	BellCount: number;
	SmokeSensorCount: number;
	TemperatureSensorCount: number;
	TemperatureSmokeSensorCount: number;
	OrtherSensorCount: number;
	DevicePercent: number;
	BellPercent: number;
	SmokeSensorPercent: number;
	TemperatureSensorPercent: number;
	TemperatureSmokeSensorPercent: number;
	OrtherSensorPercent: number;
}

// const INVENTORY_BASE_URL = 'http://192.168.1.56:8000/api';
const createDevice = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/create`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const getDevice = (imei: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixDevice}/imei/${imei}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const listDevice = (pageOption?: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixDevice}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const searchDevice = (param: any = {}, pageOption?: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/search`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const searchDeviceInstallReport = (param: any, pageOption: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/install-report`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const searchDeviceRatioReport = (param: any): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/device-ratio-report`, param)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const searchDeviceByConstruction = (param: any, pageOption?: PageOption): Promise<string[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/getByConstruction`, param, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const updateDevice = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixDevice}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const searchDeviceSummaryReport = (
	requestBody: DeviceSummaryReportRequest = {},
	pageOption: any = {},
): Promise<PageOption<DeviceSummaryReport>> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/summary-report`, requestBody, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));
};

const exportFileExcel = (requestBody: DeviceSummaryReportRequest = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixDevice}/export-summary-report`, requestBody, {
			responseType: 'blob',
		})
		.then(({ data }) => Promise.resolve(data));
};

const DeviceService = {
	createDevice,
	getDevice,
	listDevice,
	updateDevice,
	searchDevice,
	searchDeviceInstallReport,
	searchDeviceRatioReport,
	searchDeviceByConstruction,
	searchDeviceSummaryReport,
	exportFileExcel,
};

export default DeviceService;
