import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';

export const prefixSenSors = '/sensors';

// const INVENTORY_BASE_URL = 'http://192.168.1.56:8000/api';
const _createSenSors = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSenSors}/create`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteSenSors = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixSenSors}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listSenSors = (pageOption: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixSenSors}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _searchSenSors = (input: any = {}, pageOption?: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSenSors}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateSenSors = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixSenSors}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getSensor = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixSenSors}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _createSenSorsList = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixSenSors}/createList`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

export default {
	createSenSors: _createSenSors,
	deleteSenSors: _deleteSenSors,
	listSensors: _listSenSors,
	searchSenSors: _searchSenSors,
	getSensor: _getSensor,
	updateSenSors: _updateSenSors,
	createSenSorsList: _createSenSorsList,
};
