import { Button } from 'antd';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomDialog } from 'react-st-modal';

interface TableActionProps {
	editTitle: string;
	EditDialog: JSX.Element;
	DeleteDialog: JSX.Element;
	className?: string;
	isViewButtonEdit?: boolean;
	isViewButtonDelete?: boolean;
	isVaitro?: boolean;
}

const TableAction: React.FC<TableActionProps> = (props: TableActionProps) => {
	const navigate = useNavigate();
	return (
		<>
			<Button
				style={{ display: props.isViewButtonEdit ? '' : 'none' }}
				type="text"
				onClick={async (e: any) => {
					if (props.isVaitro) {
						e.stopPropagation();
						navigate({ pathname: `/nguoiDung?tab=phanQuyen&child=AddVaiTro&Id=${props.EditDialog}` });
					} else {
						e.stopPropagation();
						await CustomDialog(props.EditDialog, {
							className: `${props.className ? props.className : 'dialog-add'}`,
							title: `${props.editTitle}`,
							isCanClose: true,
							showCloseIcon: true,
						});
					}
				}}>
				<img src="icon/Pen.png" alt="edit" />
			</Button>
			<Button
				style={{ display: props.isViewButtonDelete ? '' : 'none' }}
				type="text"
				onClick={async (e: any) => {
					e.stopPropagation();
					await CustomDialog(props.DeleteDialog, {
						showCloseIcon: true,
						isCanClose: true,
					});
				}}>
				<img src="icon/Delete.png" alt="delete" />
			</Button>
		</>
	);
};

export default TableAction;
