
export enum PhuongTienChuaChayCoGioiEnum {
	XE_CHUA_CHAY = 'XE_CHUA_CHAY',
	XE_CHUYEN_DUNG = 'XE_CHUYEN_DUNG',
	MAY_BAY_CHUA_CHAY = 'MAY_BAY_CHUA_CHAY',
	PHUONG_TIEN_THUY_CHUA_CHAY = 'PHUONG_TIEN_THUY_CHUA_CHAY',
}

interface PhuongTienChuaChayCoGioi {
	label: string;
	value: PhuongTienChuaChayCoGioiEnum;
}

const _fromEnum = (phuongTienChuaChayCoGioiEnum: PhuongTienChuaChayCoGioiEnum): PhuongTienChuaChayCoGioi => {
	switch (phuongTienChuaChayCoGioiEnum) {
		case PhuongTienChuaChayCoGioiEnum.XE_CHUA_CHAY:
			return {
				label: 'Xe chữa cháy',
				value: PhuongTienChuaChayCoGioiEnum.XE_CHUA_CHAY,
			};
		case PhuongTienChuaChayCoGioiEnum.XE_CHUYEN_DUNG:
			return {
				label: 'Xe chuyên dùng',
				value: PhuongTienChuaChayCoGioiEnum.XE_CHUYEN_DUNG,
			};
		case PhuongTienChuaChayCoGioiEnum.MAY_BAY_CHUA_CHAY:
			return {
				label: 'Máy bay chữa cháy',
				value: PhuongTienChuaChayCoGioiEnum.MAY_BAY_CHUA_CHAY,
			};
		case PhuongTienChuaChayCoGioiEnum.PHUONG_TIEN_THUY_CHUA_CHAY:
			return {
				label: 'Phương tiện thủy chữa cháy',
				value: PhuongTienChuaChayCoGioiEnum.PHUONG_TIEN_THUY_CHUA_CHAY,
			};
	}
};

const _getAll = (): PhuongTienChuaChayCoGioi[] => {
	return Object.values(PhuongTienChuaChayCoGioiEnum).map(r => _fromEnum(r as PhuongTienChuaChayCoGioiEnum));
};

const phuongTienChuaChayCoGioiEnum = {
	fromEnum: _fromEnum,
	getAll: _getAll,
};
export default phuongTienChuaChayCoGioiEnum;
