import store from 'redux/store';

const NavBarSelector = {
	getCollapse: (): boolean => {
		return store.getState().navBar.collapse;
	},
	getTitle: (): string => {
		return store.getState().navBar.title;
	},
};

export default NavBarSelector;