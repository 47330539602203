import { DownloadOutlined } from '@ant-design/icons';
import { Button, Form, Pagination, Tabs } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { PermissionProps, QuanLyBaoCaoTab } from 'components/page/QuanLyBaoCao/QuanLyBaoCao';
import ConstructionSelect from 'components/shared/form-select/ConstructionSelect/ConstructionSelect';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import UnitManagerSelect from 'components/shared/form-select/UnitManagerSelect/UnitManagerSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import NavigateButton from 'components/shared/NavigateButton/NavigateButton';
import PercentComponent from 'components/shared/PercentComponent/PercentComponent';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import AlertTypeEnumVal from 'enum/alertTypeEnum';
import khuVucEnum from 'enum/khuVucEnum';
import loaiHinhHoatDongEnum from 'enum/loaiHinhHoatDongEnum';
import nganhLinhVucEnum from 'enum/nghanhLinhVucEnum';
import nguyenNhanChayEnum from 'enum/nguyenNhanChayEnum';
import { PermissionEnum } from 'enum/permissionEnum';
import thuocThanhPhanKinhTeEnum from 'enum/thuocThanhPhanKinhTeEnum';
import { downloadExcelFile, formatCurrencyByBillionUnit, formatDate, showNotification } from 'helpers';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ProvinceService from 'services/api/provinceService';
import fireProtectionService from 'services/api/reportService/fireProtectionService';
import { systemProperties } from 'systemProperties';

const CURRENT_PAGE = 1;
const PAGE_SIZE = 10;
const TABLE_WIDTH = 600;
const TABLE_HEIGHT = 600;
const SUMMARY_TAB = '1';
const DETAIL_TAB = '2';
const EXPORT_SUMMARY_URL = 'export';
const EXPORT_DETAIL_URL = 'ExportForControlCenter';
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const DiemChay: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();
	const [searchParams] = useSearchParams();
	const getFireProtectionReport = useRef<any>({});

	// state of component
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState<number>(PAGE_SIZE);
	const [dataSources, setDataSources] = useState<any>([]);
	const [totalItems, setTotalItems] = useState(0);
	const [currentTab, setCurrentTab] = useState(SUMMARY_TAB);
	const [tinhThanh, setTinhThanh] = useState();
	const [quanHuyen, setQuanHuyen] = useState();
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);
	const [unitId, setUnitId] = useState('');

	// get list of provinces
	const generalReportColumns: ColumnsType<any> = [
		{
			title: 'Tỉnh thành/Quận huyện/Xã phường',
			key: 'location',
			dataIndex: 'location',
			fixed: 'left',
			width: 300,
		},
		{
			align: 'right' as const,
			title: 'Tổng số vụ cháy',
			key: 'FireProtectionSum',
			dataIndex: 'FireProtectionSum',
			render: (FireProtectionSum: number) => (FireProtectionSum ? FireProtectionSum : 0),
			fixed: 'left',
			width: 100,
		},
		{
			width: 200,
			title: 'Số lượng theo loại cảnh báo',
			children: [
				{
					width: 200,
					align: 'right' as const,
					title: 'Cảnh báo đúng',
					key: 'CorrectAlertCount',
					dataIndex: 'CorrectAlertCount',
					render: (CorrectAlertCount: number, record: any) => {
						return <PercentComponent count={CorrectAlertCount} percent={record.CorrectAlertPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Cảnh báo sai',
					key: 'InCorrectAlertCount',
					dataIndex: 'InCorrectAlertCount',
					render: (InCorrectAlertCount: number, record: any) => {
						return <PercentComponent count={InCorrectAlertCount} percent={record.InCorrectAlertPercent} />;
					},
				},
			],
		},
		{
			width: 200,
			title: 'Số lượng theo nguồn báo cháy',
			children: [
				{
					width: 200,
					align: 'right' as const,
					title: 'Người dân báo cháy',
					key: 'PeopleAlertCount',
					dataIndex: 'PeopleAlertCount',
					render: (PeopleAlertCount: number, record: any) => {
						return <PercentComponent count={PeopleAlertCount} percent={record.PeopleAlertPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Thiết bị báo cháy',
					key: 'DeviceAlertCount',
					dataIndex: 'DeviceAlertCount',
					render: (DeviceAlertCount: number, record: any) => {
						return <PercentComponent count={DeviceAlertCount} percent={record.DeviceAlertPercent} />;
					},
				},
			],
		},
		{
			width: 200,
			title: 'Số lượng theo phân loại lỗi',
			children: [
				{
					width: 200,
					align: 'right' as const,
					title: 'Cố ý',
					key: 'COYReasonCount',
					dataIndex: 'COYReasonCount',
					render: (COYReasonCount: number, record: any) => {
						return <PercentComponent count={COYReasonCount} percent={record.COYReasonPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Vô ý',
					key: 'VOYReasonCount',
					dataIndex: 'VOYReasonCount',
					render: (VOYReasonCount: number, record: any) => {
						return <PercentComponent count={VOYReasonCount} percent={record.VOYReasonPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Khác',
					key: 'OtherReasonCount',
					dataIndex: 'OtherReasonCount',
					render: (OtherReasonCount: number, record: any) => {
						return <PercentComponent count={OtherReasonCount} percent={record.OtherReasonPercent} />;
					},
				},
			],
		},
		{
			width: 200,
			title: 'Số lượng theo khu vực',
			children: [
				{
					width: 200,
					align: 'right' as const,
					title: 'Trong khu dân cư',
					key: 'ResidentialCount',
					dataIndex: 'ResidentialCount',
					render: (ResidentialCount: number, record: any) => {
						return <PercentComponent count={ResidentialCount} percent={record.ResidentialPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Trong cơ quan doanh nghiệp',
					key: 'OfficeCount',
					dataIndex: 'OfficeCount',
					render: (OfficeCount: number, record: any) => {
						return <PercentComponent count={OfficeCount} percent={record.OfficePercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Nơi công cộng',
					key: 'PublicPlaceCount',
					dataIndex: 'PublicPlaceCount',
					render: (PublicPlaceCount: number, record: any) => {
						return <PercentComponent count={PublicPlaceCount} percent={record.PublicPlacePercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Cháy rừng',
					key: 'ForestFiresCount',
					dataIndex: 'ForestFiresCount',
					render: (ForestFiresCount: number, record: any) => {
						return <PercentComponent count={ForestFiresCount} percent={record.ForestFiresPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Kho chứa vũ khí',
					key: 'WarehouseWeaponsCount',
					dataIndex: 'WarehouseWeaponsCount',
					render: (WarehouseWeaponsCount: number, record: any) => {
						return <PercentComponent count={WarehouseWeaponsCount} percent={record.WarehouseWeaponsPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Nhà cao tầng',
					key: 'HightRiseBuildingCount',
					dataIndex: 'HightRiseBuildingCount',
					render: (HightRiseBuildingCount: number, record: any) => {
						return <PercentComponent count={HightRiseBuildingCount} percent={record.HightRiseBuildingPercent} />;
					},
				},
				{
					width: 200,
					align: 'right' as const,
					title: 'Phương tiện giao thông vận tải',
					key: 'TransportCount',
					dataIndex: 'TransportCount',
					render: (TransportCount: number, record: any) => {
						return <PercentComponent count={TransportCount} percent={record.TransportPercent} />;
					},
				},
			],
		},
	];

	const detailReportColumns: ColumnsType<any> = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
			fixed: 'left',
		},
		{
			title: 'Tỉnh thành',
			key: 'TinhThanh',
			dataIndex: 'TinhThanh',
			fixed: 'left',
			width: 200,
		},
		{
			title: 'Quận huyện',
			key: 'QuanHuyen',
			dataIndex: 'QuanHuyen',
			fixed: 'left',
			width: 200,
		},
		{
			title: 'Tên điểm cháy/Cơ sở công trình',
			key: 'Construction',
			dataIndex: 'Construction',
			width: 300,
			render: (_, record: any) => {
				if (record.Construction) {
					return (
						<NavigateButton
							url={`/chiTietCoSoCongTrinh?id=${record.Construction.Id}&mode=view`}
							name={record.Construction.TenCoSoCongTrinh}
						/>
					);
				}
				return record.Name;
			},
		},
		{
			title: 'Địa chỉ',
			key: 'LocationDetail',
			dataIndex: 'LocationDetail',
			width: 300,
		},
		{
			title: 'Loại vụ việc',
			key: 'ExplosionType',
			dataIndex: 'ExplosionType',
			width: 200,
			render: ExplosionType => {
				if (ExplosionType) {
					if (ExplosionType === 'CHAY') {
						return t('enum.chayNo.CHAY');
					}
					return t('enum.chayNo.NO');
				}
			},
		},
		{
			title: 'Ngành/lĩnh vực',
			key: 'Construction',
			dataIndex: 'Construction',
			width: 200,
			render: Construction => {
				if (Construction?.NghanhLinhVuc) {
					return nganhLinhVucEnum.fromEnum(Construction.NghanhLinhVuc).label;
				}
				return null;
			},
		},
		{
			title: 'Khu vực cháy',
			key: 'Area',
			dataIndex: 'Area',
			width: 200,
			render: Area => {
				if (Area) {
					return khuVucEnum.fromEnum(Area).label;
				}
				return null;
			},
		},
		{
			title: 'Thành phần kinh tế',
			key: 'Construction',
			dataIndex: 'Construction',
			width: 200,
			render: Construction => {
				if (Construction?.ThanhPhanKinhTe) {
					return thuocThanhPhanKinhTeEnum.fromEnum(Construction.ThanhPhanKinhTe).label;
				}
				return null;
			},
		},
		{
			title: 'Loại hình hoạt động',
			key: 'Construction',
			dataIndex: 'Construction',
			width: 200,
			render: Construction => {
				if (Construction?.LoaiHinhHoatDong) {
					return loaiHinhHoatDongEnum.fromEnum(Construction.LoaiHinhHoatDong).label;
				}
				return null;
			},
		},
		{
			title: 'Loại cảnh báo',
			key: 'AlertType',
			dataIndex: 'AlertType',
			width: 200,
			render: AlertType => {
				if (AlertType && AlertType.length > 0) {
					return (
						<ul>
							{AlertType.map((type: any, index: number) => (
								<li className="mb5" key={index}>
									{AlertTypeEnumVal.fromEnum(type).label}
								</li>
							))}
						</ul>
					);
				}
			},
		},
		{
			title: 'Thời gian xử lý cháy',
			children: [
				{
					title: 'Thời gian báo cháy',
					key: 'FireAlarmTime',
					dataIndex: 'FireAlarmTime',
					width: 200,
					render: FireAlarmTime => FireAlarmTime && formatDate(FireAlarmTime, systemProperties.dateTimeFormat),
				},
				{
					title: 'Thời gian bắt đầu xử lý',
					key: 'StartTime',
					dataIndex: 'StartTime',
					width: 200,
					render: StartTime => StartTime && formatDate(StartTime, systemProperties.dateTimeFormat),
				},
				{
					title: 'Thời gian kết thúc xử lý',
					key: 'EndTime',
					dataIndex: 'EndTime',
					width: 200,
					render: EndTime => EndTime && formatDate(EndTime, systemProperties.dateTimeFormat),
				},
			],
		},
		{
			title: 'Nguyên nhân',
			key: 'Reason',
			dataIndex: 'Reason',
			width: 200,
			render: Reason => {
				if (Reason) {
					return nguyenNhanChayEnum.fromEnum(Reason).label;
				}
				return null;
			},
		},
		{
			title: 'Thiệt hại',
			children: [
				{
					align: 'right' as const,
					title: 'Tài sản (tỉ đồng)',
					key: 'EstimateMoney',
					dataIndex: 'EstimateMoney',
					render: EstimateMoney => (EstimateMoney ? formatCurrencyByBillionUnit(+EstimateMoney) : null),
					width: 200,
				},
				{
					align: 'right' as const,
					title: 'Số người chết (người)',
					key: 'DeadCount',
					dataIndex: 'DeadCount',
					width: 200,
				},
				{
					align: 'right' as const,
					title: 'Số người bị thương (người)',
					key: 'InjuredCount',
					dataIndex: 'InjuredCount',
					width: 200,
				},
			],
		},
		{
			title: 'Lực lượng tham gia chữa cháy',
			children: [
				{
					align: 'right' as const,
					title: 'Cán bộ chiến sỹ (người)',
					key: 'FiremanCount',
					dataIndex: 'FiremanCount',
					width: 200,
				},
				{
					align: 'right' as const,
					title: 'Phương tiện (xe)',
					key: 'FireTruckCount',
					dataIndex: 'FireTruckCount',
					width: 200,
				},
				{
					align: 'right' as const,
					title: 'Đơn vị hỗ trợ (đơn vị)',
					key: 'SupportUnitCount',
					dataIndex: 'SupportUnitCount',
					width: 200,
				},
			],
		},
	];

	const getSummaryDataChildren = async (children: any, parentIndex: number) => {
		return children.map(async (item: any, index: number) => {
			const {
				Location: { CityId, DistrictId },
			} = item;
			const district = await ProvinceService.getDistrict({ cityId: CityId, districtId: DistrictId });
			return {
				...item,
				Id: DistrictId,
				key: DistrictId,
				numberOrder: `${parentIndex}.${index}`,
				location: district?.Name,
			};
		});
	};

	const convertToSummaryDataSources = async (data: any, index: number, currentPage: number, pageSize: number) => {
		const {
			Children,
			Location: { CityId },
		} = data;
		const parentIndex = (currentPage - 1) * pageSize + index + 1;
		const province = await ProvinceService.getProvince(CityId);
		const children = await Promise.all(await getSummaryDataChildren(Children, parentIndex));
		delete data.Children;
		return {
			...data,
			Id: CityId,
			key: CityId,
			numberOrder: parentIndex,
			location: province?.Name,
			children,
		};
	};

	const convertToDetailDataSource = async (data: any) => {
		let province = '';
		let district = '';
		const location = data.Location;
		if (location) {
			province = (await ProvinceService.getProvince(location.TinhThanh))?.Name!;
			district = (await ProvinceService.getDistrict({ cityId: location.TinhThanh, districtId: location.QuanHuyen }))
				?.Name!;
		}

		return {
			...data,
			TinhThanh: province,
			QuanHuyen: district,
		};
	};

	const getRequestBody = () => {
		return {
			PcccUnitId: form.getFieldValue('PcccUnitId'),
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh') ? form.getFieldValue('TinhThanh') : tinhThanh,
				QuanHuyen: form.getFieldValue('QuanHuyen') ? form.getFieldValue('QuanHuyen') : quanHuyen,
			},
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			ConstructionIds: form.getFieldValue('ConstructionIds') ? [form.getFieldValue('ConstructionIds')] : [],
		};
	};

	getFireProtectionReport.current = async (
		page = currentPage,
		pageSize = pageSizeDefault,
		tab = currentTab,
		resetParam = false,
	) => {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);

		const requestBody = getRequestBody();
		if (resetParam && !form.getFieldValue('TinhThanh')) {
			requestBody.Location.TinhThanh = undefined;
			requestBody.Location.QuanHuyen = undefined;
		}
		const isSummaryTab = tab === SUMMARY_TAB;

		const pageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};

		try {
			const { Data, TotalItems, CurrentPage, PageSize } = isSummaryTab
				? await fireProtectionService.searchSummaryReport(requestBody, pageOption)
				: await fireProtectionService.searchCommandCenter(requestBody, pageOption);

			if (Data && Data.length > 0) {
				const dataTranformed = isSummaryTab
					? Data.map(
							async (item: any, index: number) => await convertToSummaryDataSources(item, index, CurrentPage, PageSize),
					  )
					: Data.map(async (item: any) => await convertToDetailDataSource(item));

				const newDataSources = await Promise.all(dataTranformed);

				setDataSources(newDataSources);
			} else {
				setDataSources([]);
			}
			setTotalItems(TotalItems!);
		} catch (error) {}
	};

	const handleChangeTab = (tab: string) => {
		setCurrentTab(tab);
		getFireProtectionReport.current(CURRENT_PAGE, PAGE_SIZE, tab, true);
	};

	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'PcccUnitId', 'ConstructionIds']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}

		getFireProtectionReport.current(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['PcccUnitId', 'ConstructionIds']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});

		getFireProtectionReport.current(CURRENT_PAGE);
	};

	const handelChangeUnit = (unitId: string) => {
		form.resetFields(['ConstructionIds']);
		setUnitId(unitId);
		getFireProtectionReport.current(CURRENT_PAGE);
	};

	const handleExportFileExcel = () => {
		const isSummaryTab = currentTab === SUMMARY_TAB;
		const exportFilePayload = getRequestBody();
		const url = isSummaryTab ? EXPORT_SUMMARY_URL : EXPORT_DETAIL_URL;
		fireProtectionService
			.exportFileExcel(exportFilePayload, url)
			.then(data => {
				const fileName = isSummaryTab ? 'Fire_Protection_Summary_Report.xlsx' : 'Fire_Protection_Detail_Report.xlsx';
				downloadExcelFile(data, fileName);
			})
			.catch(error => {
				showNotification('error', t('notification.error'), 'Tải tập tin thất bại');
			});
	};

	const handleChangePage = (page: number, pageSize: number) => {
		getFireProtectionReport.current(page, pageSize, currentTab);
	};

	useEffect(() => {
		if (searchParams.get('tab') === QuanLyBaoCaoTab.DIEM_CHAY) {
			document.title = t('quanLyBaoCao.baoCaoDiemChay');
			getFireProtectionReport.current();
		}
	}, [searchParams]);

	const formAction = (
		<>
			<div className="flex--row">
				<ProvinceSelect onChangeProvince={handleChangeProvince} form={form} selectClassName="withMinSelect" />

				<DistrictSelect
					onChangeDistrict={handleChangeDistrict}
					form={form}
					provinceId={provinceId}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
				/>

				<UnitManagerSelect
					name="PcccUnitId"
					form={form}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					location={location}
					onChangeUnitManager={handelChangeUnit}
				/>

				<ConstructionSelect
					name="ConstructionIds"
					form={form}
					formItemClassName="ml10"
					selectClassName="withMinSelect"
					location={location}
					unitId={unitId}
					onChangeConstruction={() => getFireProtectionReport.current(CURRENT_PAGE)}
				/>

				<RangePickerInput
					className="ml10"
					form={form}
					onChangeDatePicker={() => getFireProtectionReport.current(CURRENT_PAGE)}
				/>
			</div>

			{props.permissionList.includes(PermissionEnum.REPORT_CONG_TAC_CHUA_CHAY_EXPORT) ||
			props.permissionList.includes(PermissionEnum.ADMIN) ? (
				<Button
					type="primary"
					icon={<DownloadOutlined />}
					onClick={handleExportFileExcel}
					disabled={dataSources.length === 0}>
					Xuất Excel
				</Button>
			) : null}
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> {currentTab === '1' ? 'tỉnh thành' : 'điểm cháy'}
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={totalItems}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				setCurrentTab(currentTab === SUMMARY_TAB ? DETAIL_TAB : SUMMARY_TAB);
				setTinhThanh(record.Location.CityId);
				setQuanHuyen(record.Location.DistrictId);
				const requestBody = {
					Location: {
						TinhThanh: record.Location.CityId,
						QuanHuyen: record.Location.DistrictId,
					},
				};

				const pageOption = {
					CurrentPage: currentPage,
					PageSize: pageSizeDefault,
					Paging: true,
				};

				try {
					const { Data, TotalItems } = await fireProtectionService.searchCommandCenter(requestBody, pageOption);

					if (Data && Data.length > 0) {
						const dataTranformed = Data.map(async (item: any) => await convertToDetailDataSource(item));

						const newDataSources = await Promise.all(dataTranformed);

						setDataSources(newDataSources);
					} else {
						setDataSources([]);
					}
					setTotalItems(TotalItems!);
				} catch (error) {}
			},
		};
	};

	const formTable = (
		<Tabs defaultActiveKey={SUMMARY_TAB} activeKey={currentTab} onTabClick={handleChangeTab}>
			<Tabs.TabPane tab="Báo cáo tổng hợp" key={SUMMARY_TAB}>
				<TableComponent
					containerClass="mt20"
					dataSource={dataSources}
					columns={generalReportColumns}
					paginator={false}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					onRow={handleShowViewDetail}
					width={TABLE_WIDTH}
					height={TABLE_HEIGHT}
					bordered={true}
				/>
			</Tabs.TabPane>
			<Tabs.TabPane tab="Báo cáo chi tiết" key={DETAIL_TAB}>
				<TableComponent
					containerClass="mt20"
					dataSource={dataSources}
					columns={detailReportColumns}
					paginator={false}
					footer={() => handleFooterTable(currentPage, handleChangePage)}
					width={TABLE_WIDTH}
					height={TABLE_HEIGHT}
					bordered={true}
				/>
			</Tabs.TabPane>
		</Tabs>
	);

	return <PageWrapper titlePage="Báo cáo điểm cháy" formAction={formAction} formTable={formTable} form={form} />;
};

export default DiemChay;
