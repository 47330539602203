import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Pagination } from 'antd';
import DialogCongTacThamDuyet from 'components/page/QuanLyCongTacPhongChay/Dialogs/DialogCongTacThamDuyet';
import DeleteDialog from 'components/shared/Dialog/DeleteDialog/DeleteDialog';
import ConstructionSelect from 'components/shared/form-select/ConstructionSelect/ConstructionSelect';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import PageWrapper from 'components/shared/layouts/PageWrapper/PageWrapper';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import TableAction from 'components/shared/TableAction/TableAction';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { PermissionEnum } from 'enum/permissionEnum';
import showNotification from 'helpers/showNotification';
import { t } from 'i18next';
import moment from 'moment';
import 'moment/locale/vi';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { CustomDialog } from 'react-st-modal';
import { PageOption } from 'services/api';
import { default as ApprovalService, default as approvalService } from 'services/api/businessService/approvalService';
import { PermissionProps } from '../QuanLyCongTacPhongChay';

moment.locale('vi');

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;
const INIT_LOCATION = { Location: { TinhThanh: '', QuanHuyen: '' } };

const ThamDuyetChungNhan: FunctionComponent<PermissionProps> = props => {
	const [form] = Form.useForm();

	const [dataSourceThamDuyetChungNhan, setdataSourceThamDuyetChungNhan] = useState<any[]>([]);
	const [TongCongTacThamDuyet, setTongSoCongTacThamDuyet] = useState<number>(0);
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);

	// get list of provinces
	const [provinceId, setProvinceId] = useState('');
	const [location, setLocation] = useState(INIT_LOCATION);

	const columnsDSThamDinhVaCapGiayPhep = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			key: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên văn bản',
			dataIndex: 'Name',
			key: 'Name',
		},
		{
			title: 'Số văn bản',
			dataIndex: 'DocumentNumber',
			key: 'DocumentNumber',
		},
		{
			title: 'Ngày cấp',
			dataIndex: 'Date',
			key: 'Date',
		},
		{
			title: 'Cơ quan cấp',
			dataIndex: 'OrganName',
			key: 'OrganName',
		},
		{
			title: 'Ghi chú',
			dataIndex: 'Note',
			key: 'Note',
		},
		{
			align: 'center' as const,
			title: 'Thao tác',
			key: 'action',
			width: 100,
			hidden: !(
				props.permissionList.includes(PermissionEnum.ADMIN) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_EDIT) ||
				props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_DELETE)
			),
			render: (_: any, record: any) => (
				<TableAction
					isViewButtonEdit={
						props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_EDIT) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					isViewButtonDelete={
						props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_DELETE) ||
						props.permissionList.includes(PermissionEnum.ADMIN)
					}
					editTitle={`Chỉnh sửa văn bản "${record.Name}"`}
					EditDialog={
						<DialogCongTacThamDuyet
							callback={getDataTimKiemCongTacThamDinh}
							userInfo={props.userInfo}
							mode="edit"
							record={record}
						/>
					}
					DeleteDialog={
						<DeleteDialog
							id={record.Id}
							name={record.Name}
							callback={getDataTimKiemCongTacThamDinh}
							deleteService={ApprovalService.deleteApproval}
						/>
					}
				/>
			),
		},
	].filter(item => !item.hidden);

	function SearchThamDuyetChungNhan(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		let param: any = {
			Name: form.getFieldValue('Name'),
			FromDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[0] : null,
			ToDate: form.getFieldValue('dateRange') ? form.getFieldValue('dateRange')[1] : null,
			ConstructionId: form.getFieldValue('ConstructionId'),
		};
		if (form.getFieldValue('TinhThanh')) {
			param.Location = {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: form.getFieldValue('QuanHuyen'),
			};
		}
		approvalService
			.searchApprovals(param, pageOption)
			.then((res: any) => {
				setTongSoCongTacThamDuyet(res.TotalItems);
				optiondanhsachCongTacThamDinh(res ? res.Data : []);
			})
			.catch();
	}

	function onChangePage(page: number, pageSize: number) {
		SearchThamDuyetChungNhan(page, pageSize);
	}

	// Method get Công tác thẩm định và cấp giấy phép chứng nhận đủ điều kiện về PCCC
	function getDataTimKiemCongTacThamDinh(page = currentPage, pageSize = pageSizeDefault) {
		setCurrentPage(page);
		setPageSizeDefault(pageSize);
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		approvalService
			.listApproval(pageOption)
			.then((res: any) => {
				setTongSoCongTacThamDuyet(res.TotalItems);
				optiondanhsachCongTacThamDinh(res ? res.Data : []);
			})
			.catch();
	}

	const optiondanhsachCongTacThamDinh = (data: any[]) => {
		let array = [];
		if (data.length > 0) {
			data.forEach((item, index) => {
				item.Date = moment(item.Date).format('DD/MM/YYYY');
				array.push(item);
			});
		}
		setdataSourceThamDuyetChungNhan(data);
	};

	// Khởi tạo data table cho bảng Danh sách Công tác thẩm định
	useEffect(() => {
		document.title = t('quanLyCongTacPhongChay.thamDuyetChungNhan');
		const checkOrPermission = (orPermissions: PermissionEnum[]): boolean => {
			let accepted = false;
			orPermissions.forEach(r => {
				if (props.permissionList.includes(r)) {
					accepted = true;
				}
			});
			return accepted;
		};

		if (props.permissionList.length > 0) {
			if (checkOrPermission([PermissionEnum.ADMIN, PermissionEnum.CONSTRUCTION_APPROVAL_VIEW])) {
				getDataTimKiemCongTacThamDinh();
			} else {
				showNotification('error', t('notification.error'), t('message.unauthorizedView'));
			}
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.permissionList]);

	// Event change tỉnh thành danh sách cơ sở công trình
	const handleChangeProvince = (provinceId: string) => {
		form.resetFields(['QuanHuyen', 'ConstructionId']);
		if (provinceId) {
			setProvinceId(provinceId);
		} else {
			setProvinceId('');
			setLocation(INIT_LOCATION);
		}
		SearchThamDuyetChungNhan(CURRENT_PAGE);
	};

	const handleChangeDistrict = (districtId: string) => {
		form.resetFields(['ConstructionId']);
		setLocation({
			Location: {
				TinhThanh: form.getFieldValue('TinhThanh'),
				QuanHuyen: districtId,
			},
		});
		SearchThamDuyetChungNhan(CURRENT_PAGE);
	};

	const handleSearchName = (value: string) => {
		if (!value) {
			form.setFieldsValue({ Name: null });
		}

		SearchThamDuyetChungNhan(CURRENT_PAGE);
	};

	const handleChangeDateRange = () => {
		SearchThamDuyetChungNhan(CURRENT_PAGE);
	};

	const handleShowViewDetail = (record: any) => {
		return {
			onClick: async () => {
				await CustomDialog(<DialogCongTacThamDuyet userInfo={props.userInfo} record={record} mode={'view'} />, {
					className: 'dialog-add',
					title: `${record.Name}`,
					showCloseIcon: true,
					isCanClose: true,
				});
			},
		};
	};

	const handleRefreshPage = () => {
		form.resetFields();
		getDataTimKiemCongTacThamDinh(CURRENT_PAGE);
	};

	const formAction = (
		<>
			<div className="flex--row">
				<div className="flex--row">
					<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />
					<DistrictSelect
						selectClassName="withMinSelect"
						formItemClassName="ml10"
						form={form}
						provinceId={provinceId}
						onChangeDistrict={handleChangeDistrict}
					/>
					<ConstructionSelect
						name="ConstructionId"
						selectClassName="withMinSelect"
						formItemClassName="ml10"
						form={form}
						location={location}
						onChangeConstruction={() => SearchThamDuyetChungNhan(CURRENT_PAGE)}
					/>
				</div>

				<Form.Item name="dateRange" className="ml10">
					<RangePickerInput onChangeDatePicker={handleChangeDateRange} form={form} />
				</Form.Item>

				<Form.Item name="Name" className="ml10">
					<Input.Search
						placeholder={t('placeholder.tenVanBan')}
						allowClear
						onSearch={(value: string) => handleSearchName(value)}
					/>
				</Form.Item>
			</div>

			<div>
				<Button type="default" onClick={handleRefreshPage}>
					<img style={{ width: 17 }} src="icon/Reload.png" alt="Làm mới" />
				</Button>
				{props.permissionList.includes(PermissionEnum.CONSTRUCTION_APPROVAL_CREATE) ||
				props.permissionList.includes(PermissionEnum.ADMIN) ? (
					<Button
						className="ml10"
						type="primary"
						onClick={async () => {
							await CustomDialog(
								<DialogCongTacThamDuyet userInfo={props.userInfo} callback={getDataTimKiemCongTacThamDinh} />,
								{
									className: 'dialog-add',
									title: 'Thêm mới văn bản',
									showCloseIcon: true,
									isCanClose: true,
								},
							);
						}}>
						<PlusOutlined />
						Thêm mới
					</Button>
				) : null}
			</div>
		</>
	);

	const showTotal = (total: number) => {
		return (
			<span>
				Tổng: <strong>{total}</strong> văn bản
			</span>
		);
	};

	const handleFooterTable = (currentPage: number, onChangePage: any) => {
		return (
			<div className="flex--row justify-content--center">
				<Pagination
					defaultPageSize={pageSizeDefault}
					showSizeChanger={true}
					current={currentPage}
					onChange={onChangePage}
					total={TongCongTacThamDuyet}
					showTotal={showTotal}
					pageSizeOptions={TableOption.PAGE_SIZE_OPTION}
				/>
			</div>
		);
	};

	const formTable = (
		<TableComponent
			dataSource={dataSourceThamDuyetChungNhan}
			columns={columnsDSThamDinhVaCapGiayPhep}
			onRow={handleShowViewDetail}
			paginator={false}
			footer={() => handleFooterTable(currentPage, onChangePage)}
		/>
	);

	return (
		<PageWrapper
			titlePage="Danh sách công tác thẩm duyệt chứng nhận"
			formAction={formAction}
			formTable={formTable}
			form={form}
		/>
	);
};

export default withTranslation()(ThamDuyetChungNhan);
