import ApiService, { BUSINESS_BASE_URL, PageOption } from '../../index';

export const prefixViolation = '/violation';

const _createViolation = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixViolation}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteViolation = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).delete(`${prefixViolation}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getViolation = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).get(`${prefixViolation}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchViolations = (param: any, pageOption: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixViolation}/search`, param, {
		headers: {
			'PageOption': JSON.stringify(pageOption)
		}
	}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const _searchViolationsByContruction = (param: string[]): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixViolation}/getByListConstruction`, param)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateViolation = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).put(`${prefixViolation}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const ViolationService = {
	createViolation: _createViolation,
	deleteViolation: _deleteViolation,
	getViolation: _getViolation,
	searchViolations: _searchViolations,
	updateViolation: _updateViolation,
	searchViolationsByContruction: _searchViolationsByContruction
};

export default ViolationService;
