import { Moment } from 'moment';
import ApiService, { COMMON_BASE_URL } from '../index';

export default {
	// Get loại hình đơn vị từ BE
	getloaihinhdvi() {
		return ApiService(COMMON_BASE_URL).get('TypeUnit');
	}, // Get điểm cháy thực tập
	getdiemchaytt() {
		return ApiService(COMMON_BASE_URL).get('DSdiemChayTT');
	}, // Get tỉnh thành từ BE
	gettinhthanh() {
		return ApiService(COMMON_BASE_URL).get('Province');
	}, // get loại cảm biến
	getLoaiCamBien() {
		return ApiService(COMMON_BASE_URL).get('ListLoaiCamBien');
	}, // get trạng thái cảm biến
	getTrangThaiCamBien() {
		return ApiService(COMMON_BASE_URL).get('ListTrangThaiCamBien');
	}, // get trạng thái ghép nối
	getTrangThaiGhepNoi() {
		return ApiService(COMMON_BASE_URL).get('ListTrangThaiGhepNoi');
	}, // get trạng thái bán hàng
	getTrangThaiBanHang() {
		return ApiService(COMMON_BASE_URL).get('ListTrangThaiBanHang');
	}, // Get quận huyện từ BE
	getquanhuyen(ProvindId: number) {
		return ApiService(COMMON_BASE_URL).get('District?ProvindId=' + ProvindId);
	}, // Get cấp bậc từ BE
	getcapbac() {
		return ApiService(COMMON_BASE_URL).get('StaffLevel');
	}, //Get chức vụ từ BE
	getchucvu() {
		return ApiService(COMMON_BASE_URL).get('position');
	}, // Get trực thuộc từ BE
	gettructhuoc() {
		return ApiService(COMMON_BASE_URL).get('Under');
	}, // Get phân loại điểm cháy
	getPhanLoaiDiemChay() {
		return ApiService(COMMON_BASE_URL).get('DSPhanLoaidiemChay');
	}, // Get loại phương tiện từ BE
	getPhuongTien() {
		return ApiService(COMMON_BASE_URL).get('Vehicle');
	}, // Get trạng thái hoạt động từ BE
	getTrangThaihoatDong() {
		return ApiService(COMMON_BASE_URL).get('OperatingStatus');
	}, // Get Loại Camera động từ BE
	getLoaiCamera() {
		return ApiService(COMMON_BASE_URL).get('ListCamera');
	}, // Get Đại lý
	getDaiLy() {
		return ApiService(COMMON_BASE_URL).get('ListDaiLy');
	}, // Get Loại Thiết bị
	getLoaiThietBi() {
		return ApiService(COMMON_BASE_URL).get('ListLoaiThietBi');
	}, // Get trạng thái Thực hiện từ BE
	getTrangThaiThucHien() {
		return ApiService(COMMON_BASE_URL).get('ListTrangThaiThucHien');
	}, // Get loại đơn vị hỗ trợ từ BE
	getloaidonvihotro() {
		return ApiService(COMMON_BASE_URL).get('UnitOfSupport');
	}, // Get đơn vị quản lý nước từ BE
	getdonviquanlynuoc(huyenID: any) {
		return ApiService(COMMON_BASE_URL).get('WaterManagement', { params: { HuyenID: huyenID } });
	}, // Get danh sách Đơn vị phòng cháy chữa cháy từ BE
	getUnitPCCC(tinhid?: any) {
		return ApiService(COMMON_BASE_URL).get('UnitPCCC', { params: { tinhID: tinhid } });
	}, // Get danh sách Đơn vị phòng cháy chữa cháy từ BE
	getListUnitPCCC(madonvi?: any, tinhid?: any) {
		return ApiService(COMMON_BASE_URL).get('GetListUnitPCCC', {
			params: {
				madonvi: madonvi,
				tinhID: tinhid,
			},
		});
	}, // xóa danh sách Đơn vị phòng cháy chữa cháy từ BE
	DeleteListUnitPCCC(id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteUnitPCCC', {
			params: {
				id: id,
			},
		});
	}, // Get danh sách cán bộ trực từ BE
	getDsCanBoTruc(chucvuId: number) {
		return ApiService(COMMON_BASE_URL).get('SoldierManagerCalenda?chucvuId=' + chucvuId);
	}, // Get danh sách cán bộ chiến sỹ từ BackEnd
	getDsCanBoChienSy(
		hoTen: any,
		loaiHinhTruc: any,
		ngayFrom: any,
		ngayTo?: any,
		tenTinh?: any,
		tenHuyen?: any,
		tenPhong?: any,
		tenBan?: any,
	) {
		return ApiService(COMMON_BASE_URL).get('OfficersSoldiers', {
			params: {
				userName: hoTen,
				loaihinhtruc: loaiHinhTruc,
				ngayform: ngayFrom,
				ngayto: ngayTo,
				tentinh: tenTinh,
				tenhuyen: tenHuyen,
				tenphong: tenPhong,
				tenban: tenBan,
			},
		});
	}, // Get danh sách cán bộ chiến sỹ từ đăng ký trực BackEnd
	GetCanBoChienSyDangKyTruc(hoTen?: any, loaiHinhTruc?: any, ngayFrom?: any, ngayTo?: any) {
		return ApiService(COMMON_BASE_URL).get('CanBoCSDangKyTruc', {
			params: {
				userName: hoTen,
				loaihinhtruc: loaiHinhTruc,
				ngayform: ngayFrom,
				ngayto: ngayTo,
			},
		});
	},
	// Get danh sách cán bộ chiến sỹ từ đăng ký trực BackEnd
	GetCanBoChienSyDoiCaTruc(lydo: any, canbochiensyId: any, ngayFrom: any, ngayTo: any) {
		return ApiService(COMMON_BASE_URL).get('CanBoCSDoiCaTruc', {
			params: {
				lydodoica: lydo,
				canbocsId: canbochiensyId,
				ngayform: ngayFrom,
				ngayto: ngayTo,
			},
		});
	},
	// Thêm mới danh sách cán bộ chiến sỹ đổi ca trực
	async AddCBCSDoiCaTruc(cbcsDoiCaId: any, catrucValue: any, cbcsNhanId: any, lydodoicatrucValue: any) {
		return await ApiService(COMMON_BASE_URL).get('AddCBCSDoiCaTruc', {
			params: {
				cbcsDoiCaId: cbcsDoiCaId,
				catruc: catrucValue,
				cbcsNhanId: cbcsNhanId,
				lydodoicatrucValue: lydodoicatrucValue,
			},
		});
	},
	// Thêm mới danh sách cán bộ chiến sỹ đăng ký trực
	async AddCBCSDangKyTruc(cbcsdangkytrucId: any, thoigiandangky: any, loaihinhtrucId: any) {
		return await ApiService(COMMON_BASE_URL).get('AddCBCSDangKyTruc', {
			params: {
				cbcsId: cbcsdangkytrucId,
				thoigian: thoigiandangky,
				loaihinhtrucId: loaihinhtrucId,
			},
		});
	}, // Xóa danh sách CBCS đăng ký trực
	DeleteDSDangKyTruc(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteCBCSDangKyTruc', {
			params: {
				id: Id,
			},
		});
	},
	// Get danh sách loại hình trực từ BackEnd
	getLoaihinhtruc() {
		return ApiService(COMMON_BASE_URL).get('TypeDuty');
	}, // Get loại hình sở hữu
	getLoaihinhsohuu() {
		return ApiService(COMMON_BASE_URL).get('ListTypeOwnerShip');
	}, // Get Nguyên nhân lỗi
	getnguyennhanloi() {
		return ApiService(COMMON_BASE_URL).get('ListNguyenNhanLoi');
	}, // Get Nguyên nhân lỗi
	getChayNo() {
		return ApiService(COMMON_BASE_URL).get('ListChayNo');
	}, // Get Khu vực
	getKhuVuc() {
		return ApiService(COMMON_BASE_URL).get('ListKhuVuc');
	}, // Get danh sách cấp quản lý
	getcapquanly() {
		return ApiService(COMMON_BASE_URL).get('ManagerLevel');
	}, // Get danh sách loại hình hoạt động
	getloaihinhhoatdong() {
		return ApiService(COMMON_BASE_URL).get('TypeOfOperation');
	}, // Get danh sách thành phần kinh tế
	getdanhsachthanhphankinhte() {
		return ApiService(COMMON_BASE_URL).get('EconomicSectors');
	}, // Get danh sách hình thức đầu tư
	getdanhsachhinhthucdautu() {
		return ApiService(COMMON_BASE_URL).get('InvestmentForm');
	}, // Get danh sách hình thức đầu tư
	getdanhsachnghanhlinhvuc() {
		return ApiService(COMMON_BASE_URL).get('BranchesDomain');
	}, // Get danh sách cơ sở công trình
	getdanhsachcosocongtrinh(maCoSo?: string, tinhThanh?: any, donViPccc?: string) {
		return ApiService(COMMON_BASE_URL).get('ConstructionFacilities', {
			params: { macoso: maCoSo, tinhthanh: tinhThanh, donvipccc: donViPccc },
		});
	},
	// Thêm mới danh sách cán bộ chiến sỹ
	async AddCoSoCongTrinh(
		macsctValue: any,
		huongtiepcanValue: any,
		sodienthoaiValue: any,
		chucosocongtrinhValue: any,
		canbokiemtraValue: any,
		loaihinhhdId: any,
		thuoctpkinhteId: any,
		namthanhlapValue: any,
		emailValue: any,
		tencsctValue: any,
		loaihinhshId: any,
		diachiValue: any,
		donvipcccId: any,
		capquanlyId: any,
		hinhthucdautuId: any,
		nghanhlinhvucId: any,
		websiteValue: any,
		kinhdoValue: any,
		vidoValue: any,
		tinhthanhId: any,
		quanhuyenId: any,
		baohiemchayNoValue: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('AddConstructionFacilities', {
			params: {
				macoso: macsctValue,
				tencoso: tencsctValue,
				huongtiepcan: huongtiepcanValue,
				loaihinhsohuuID: loaihinhshId,
				sodienthoai: sodienthoaiValue,
				diachi: diachiValue,
				chucosocongtrinh: chucosocongtrinhValue,
				donviID: donvipcccId,
				canbokiemtra: canbokiemtraValue,
				capquanlyID: capquanlyId,
				hinhthucdautuID: hinhthucdautuId,
				loaihinhhoatdongID: loaihinhhdId,
				nganhlinhvucID: nghanhlinhvucId,
				thanhphanktID: thuoctpkinhteId,
				baohiemchayno: baohiemchayNoValue,
				namthanhlap: namthanhlapValue,
				website: websiteValue,
				email: emailValue,
				teptin: 'quanhuyenID',
				kinhdo: kinhdoValue,
				vido: vidoValue,
				tinhID: tinhthanhId,
				huyenID: quanhuyenId,
			},
		});
	},
	// Cập nhật danh sách cán bộ chiến sỹ
	async UpdateCoSoCongTrinh(
		Id: any,
		macsctValue: any,
		huongtiepcanValue: any,
		sodienthoaiValue: any,
		chucosocongtrinhValue: any,
		canbokiemtraValue: any,
		loaihinhhdId: any,
		thuoctpkinhteId: any,
		namthanhlapValue: any,
		emailValue: any,
		tencsctValue: any,
		loaihinhshId: any,
		diachiValue: any,
		donvipcccId: any,
		capquanlyId: any,
		hinhthucdautuId: any,
		nghanhlinhvucId: any,
		websiteValue: any,
		kinhdoValue: any,
		vidoValue: any,
		tinhthanhId: any,
		quanhuyenId: any,
		baohiemchayNoValue: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('UpdateConstructionFacilities', {
			params: {
				id: Id,
				macoso: macsctValue,
				tencoso: tencsctValue,
				huongtiepcan: huongtiepcanValue,
				loaihinhsohuuID: loaihinhshId,
				sodienthoai: sodienthoaiValue,
				diachi: diachiValue,
				chucosocongtrinh: chucosocongtrinhValue,
				donviID: donvipcccId,
				canbokiemtra: canbokiemtraValue,
				capquanlyID: capquanlyId,
				hinhthucdautuID: hinhthucdautuId,
				loaihinhhoatdongID: loaihinhhdId,
				nganhlinhvucID: nghanhlinhvucId,
				thanhphanktID: thuoctpkinhteId,
				baohiemchayno: baohiemchayNoValue,
				namthanhlap: namthanhlapValue,
				website: websiteValue,
				email: emailValue,
				teptin: 'quanhuyenID',
				kinhdo: kinhdoValue,
				vido: vidoValue,
				tinhID: tinhthanhId,
				huyenID: quanhuyenId,
			},
		});
	},
	// Xóa danh sách cơ sở công trình
	DeleteDSCoSoCongTrinh(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteConstructionFacilities', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách kế hoạch kiểm tra
	getdanhsachkehoachkiemtra() {
		return ApiService(COMMON_BASE_URL).get('CheckPlan');
	}, // Get danh sách đánh giá
	getdanhsachdanhgia() {
		return ApiService(COMMON_BASE_URL).get('Evaluate');
	}, // Get danh sách giới tính
	getgioitinh() {
		return ApiService(COMMON_BASE_URL).get('ListSex');
	}, // Get danh sách phương tiện PCCC
	getdanhsachphuongtienPccc(tenPhuongTien: any, bienSo: any) {
		return ApiService(COMMON_BASE_URL).get('ListVehicle', { params: { tenpt: tenPhuongTien, bienso: bienSo } });
	}, // Get danh sách đơn vị hỗ trợ
	getdanhsachdonvihotro(maDonVi: any, tinhID: any, huyenID: any) {
		return ApiService(COMMON_BASE_URL).get('ListUnitOfSupport', {
			params: { madonvi: maDonVi, tinhthanh: tinhID, quanhuyen: huyenID },
		});
	}, // Get danh sách điểm lấy nước
	getdanhsachdiemlaynuoc(tenDiemLayNuoc: any, tinhID?: any, huyenID?: any) {
		return ApiService(COMMON_BASE_URL).get('ListWaterPoint', {
			params: { tendiemlaynuoc: tenDiemLayNuoc, tinhthanh: tinhID, quanhuyen: huyenID },
		});
	}, // Get danh sách quản lý trực
	GetDanhSachQuanLyTruc(tinhHinhTrongNgay: any, donViId: any, tinhThanhId: any, ngayBatDau: any, ngayKetThuc: any) {
		return ApiService(COMMON_BASE_URL).get('managerDuty', {
			params: {
				tinhhinhtrongngay: tinhHinhTrongNgay,
				donviid: donViId,
				tinhthanhid: tinhThanhId,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	}, // Xóa danh sách cán bộ chiến sỹ
	DeleteDanhSachquanlytruc(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteDuty', {
			params: {
				id: Id,
			},
		});
	},
	// Get danh sách kiểm tra cơ sở công trình
	getdanhsachkiemtracscongtrinh(keHoachLamViec: any, tinhThanh: any, coSoCongTrinh: any, dateFrom?: any, dateTo?: any) {
		return ApiService(COMMON_BASE_URL).get('ListCheckConstruction', {
			params: {
				tencoso: coSoCongTrinh,
				kehoachkiemtra: keHoachLamViec,
				tinhthanh: tinhThanh,
				dateFrom: dateFrom,
				dateTo: dateTo,
			},
		});
	}, // Thêm mới danh sách Kiểm tra cơ sở công trình
	async AddKiemTraCoSoCongTrinh(
		kehoachlamviecId: any,
		tenphieukiemtraValue: any,
		cosoId: any,
		ngaykiemtraValue: any,
		danhgiaId: any,
		trangthaiId: any,
		noidungkiemtraValue: any,
		noidungChiTiet: any,
		canbochiensyId: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('AddCheckConstruction', {
			params: {
				kehoachID: kehoachlamviecId,
				tenphieu: tenphieukiemtraValue,
				cosoId: cosoId,
				ngaykiemtra: ngaykiemtraValue,
				danhgiaId: danhgiaId,
				trangthaiId: trangthaiId,
				noidungkiemtra: noidungkiemtraValue,
				noidungchitiet: noidungChiTiet,
				canbothuchien: canbochiensyId,
				tepdinhkem: 'tepdinhkem',
			},
		});
	}, // Cập nhật danh sách Kiểm tra cơ sở công trình
	UpdateKiemTraCoSoCongTrinh(
		Id: any,
		kehoachlamviecId: any,
		tenphieukiemtraValue: any,
		cosoId: any,
		ngaykiemtraValue: Moment,
		danhgiaId: any,
		trangthaiId: any,
		noidungkiemtraValue: any,
		noidungChiTiet: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateCheckConstruction', {
			params: {
				id: Id,
				kehoachID: kehoachlamviecId,
				tenphieu: tenphieukiemtraValue,
				cosoId: cosoId,
				ngaykiemtra: ngaykiemtraValue.format('YYYY/MM/DD'),
				danhgiaId: danhgiaId,
				trangthaiId: trangthaiId,
				noidungkiemtra: noidungkiemtraValue,
				noidungchitiet: noidungChiTiet,
				canbothuchien: 'canbothuchien',
				tepdinhkem: 'tepdinhkem',
			},
		});
	},
	// Xóa danh sách kiểm tra cơ sở công trình
	DeleteDSKTCoSoCongTrinh(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteCheckConstruction', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách kế hoạch kiểm tra
	getdanhsachkehoachlamviec(tenKeHoach: any, tinhThanh: any, quanHuyen: any) {
		return ApiService(COMMON_BASE_URL).get('ListCheckPlan', {
			params: { tenkehoach: tenKeHoach, tinhthanh: tinhThanh, quanhuyen: quanHuyen },
		});
	}, // Thêm mới danh sách kế hoạch kiểm tra
	AddKeHoachKiemTra(
		tenkehoachkiemtraID: any,
		tinhId: any,
		huyenId: any,
		muctieukiemtra: any,
		yeucauketquadaura: any,
		quyetdinhkiemtra: any,
		phancongcanbokiemtraID: any,
		thoigianbatdauktra: Moment,
		thoigianketthuc: Moment,
		mota: any,
		tepdinhkem: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddCheckPlanWork', {
			params: {
				tenkehoachkiemtraID: tenkehoachkiemtraID,
				tinhId: tinhId,
				huyenId: huyenId,
				muctieukiemtra: muctieukiemtra,
				yeucauketquadaura: yeucauketquadaura,
				quyetdinhkiemtra: quyetdinhkiemtra,
				phancongcanbokiemtraID: phancongcanbokiemtraID,
				thoigianbatdauktra: thoigianbatdauktra.format('DD/MM/yyyy'),
				thoigianketthuc: thoigianketthuc.format('DD/MM/yyyy'),
				mota: mota,
				tepdinhkem: 'tepdinhkem',
			},
		});
	}, // Update danh sách kế hoạch kiểm tra
	UpdatekeHoachKiemTra(
		Id: any,
		tenkehoachkiemtraID: any,
		tinhId: any,
		huyenId: any,
		muctieukiemtra: any,
		yeucauketquadaura: any,
		quyetdinhkiemtra: any,
		phancongcanbokiemtraID: any,
		thoigianbatdauktra: Moment,
		thoigianketthuc: Moment,
		mota: any,
		tepdinhkem: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateCheckPlanWork', {
			params: {
				id: Id,
				tenkehoachkiemtraID: tenkehoachkiemtraID,
				tinhId: tinhId,
				huyenId: huyenId,
				muctieukiemtra: muctieukiemtra,
				yeucauketquadaura: yeucauketquadaura,
				quyetdinhkiemtra: quyetdinhkiemtra,
				phancongcanbokiemtraID: phancongcanbokiemtraID,
				thoigianbatdauktra: thoigianbatdauktra.format('DD/MM/yyyy'),
				thoigianketthuc: thoigianketthuc.format('DD/MM/yyyy'),
				mota: mota,
				tepdinhkem: 'tepdinhkem',
			},
		});
	}, // Xóa danh sách kế hoạch kiểm tra
	DeleteDSKeHoachLamViec(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteCheckPlanWork', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách dân cư
	GetDanhSachDanCu(hoTen: any, tinhThanh: any, donviId: any) {
		return ApiService(COMMON_BASE_URL).get('ListResidential', {
			params: { hoten: hoTen, tinhthanh: tinhThanh, donViID: donviId },
		});
	}, // Thêm mới danh sách dân cư
	AddDanCu(
		hoTen: any,
		soDienThoai: any,
		ngaySinh: any,
		soChungMinhThu: any,
		tinhID: any,
		huyenID: any,
		diaChiThuongTru: any,
		choOHienNay: any,
		doViId: any,
		gioiTinhID: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddResidential', {
			params: {
				hoten: hoTen,
				sodienthoai: soDienThoai,
				ngaysinh: ngaySinh,
				sochungminhthu: soChungMinhThu,
				tinhID: tinhID,
				huyenID: huyenID,
				diachithuongtru: diaChiThuongTru,
				choohiennay: choOHienNay,
				doviId: doViId,
				gioitinhID: gioiTinhID,
			},
		});
	}, // Update danh sách dân cư
	UpdateDanCu(
		Id: any,
		hoTen: any,
		soDienThoai: any,
		ngaySinh: any,
		soChungMinhThu: any,
		tinhID: any,
		huyenID: any,
		diaChiThuongTru: any,
		choOHienNay: any,
		doViId: any,
		gioiTinhID: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateResidential', {
			params: {
				id: Id,
				hoten: hoTen,
				sodienthoai: soDienThoai,
				ngaysinh: ngaySinh,
				sochungminhthu: soChungMinhThu,
				tinhID: tinhID,
				huyenID: huyenID,
				diachithuongtru: diaChiThuongTru,
				choohiennay: choOHienNay,
				doviId: doViId,
				gioitinhID: gioiTinhID,
			},
		});
	}, // Xóa danh sách kế hoạch kiểm tra
	async DeleteDSDancu(Id: any) {
		await ApiService(COMMON_BASE_URL).get('DeleteResidential', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách công tác tuyên truyền huấn luyện
	GetDanhSachCongTacTuyenTruyen(noiDungHuanLuyen: any, tinhThanh: any, ngayBatDau: any, ngayKetThuc: any) {
		return ApiService(COMMON_BASE_URL).get('ListPropagandaTrain', {
			params: {
				noidunghuanluyen: noiDungHuanLuyen,
				tinhthanh: tinhThanh,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	},
	// Thêm mới danh sách Tuyên truyền huấn luyện
	AddCongTacTuyenTruyen(
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		thoiGian: any,
		sogiayduocapValue: any,
		thoiLuong: any,
		noiDungHuanLuyen: any,
		lucLuongPCCCCoSo: any,
		canBoQuanLyLanhDao: any,
		nguoiLaoDong: any,
		doiTuongKhac: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddPropagandaTrain', {
			params: {
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				thoigian: thoiGian,
				sogiayCNHLNVPCCC: sogiayduocapValue,
				thoiluong: thoiLuong,
				noidunghuanluyen: noiDungHuanLuyen,
				lucluongPCCCcoso: lucLuongPCCCCoSo,
				canboquanlylanhdao: canBoQuanLyLanhDao,
				nguoilaodong: nguoiLaoDong,
				doituongkhac: doiTuongKhac,
			},
		});
	},
	// Update danh sách Tuyên truyền huấn luyện
	UpdateCongTacTuyenTruyen(
		Id: any,
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		thoiGian: any,
		sogiayduocapValue: any,
		thoiLuong: any,
		noiDungHuanLuyen: any,
		lucLuongPCCCCoSo: any,
		canBoQuanLyLanhDao: any,
		nguoiLaoDong: any,
		doiTuongKhac: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdatePropagandaTrain', {
			params: {
				id: Id,
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				thoigian: thoiGian,
				sogiayCNHLNVPCCC: sogiayduocapValue,
				thoiluong: thoiLuong,
				noidunghuanluyen: noiDungHuanLuyen,
				lucluongPCCCcoso: lucLuongPCCCCoSo,
				canboquanlylanhdao: canBoQuanLyLanhDao,
				nguoilaodong: nguoiLaoDong,
				doituongkhac: doiTuongKhac,
			},
		});
	}, // Xóa danh sách Tuyên truyền huấn luyện
	DeleteDSTuyenTruyenHuanLuyen(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeletePropagandaTrain', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách Công tác thẩm định và cấp giấy phép chứng nhận đủ điều kiện về PCCC
	GetDanhSachThamDuyetChungNhan(tenVanBan: any, tinhThanh: any, coSoCongTrinh: any, ngayBatDau: any, ngayKetThuc: any) {
		return ApiService(COMMON_BASE_URL).get('ApprovalLlicensing', {
			params: {
				tenvanban: tenVanBan,
				tinhthanh: tinhThanh,
				cosocongtrinh: coSoCongTrinh,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	}, // Thêm mới danh sách thẩm duyệt và chứng nhận
	AddThamDuyetChungNhan(
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		ngayCap: any,
		tenVanBan: any,
		soVanBan: any,
		coQuanCap: any,
		ghiChu: any,
		tepDinhKem: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddApprovalLlicensing', {
			params: {
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				ngaycap: ngayCap,
				tenvanban: tenVanBan,
				sovanban: soVanBan,
				coquancap: coQuanCap,
				ghichu: ghiChu,
				tepdinhkem: tepDinhKem,
			},
		});
	}, // Update danh sách thẩm duyệt và chứng nhận
	UpdateThamDuyetChungNhan(
		Id: any,
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		ngayCap: any,
		tenVanBan: any,
		soVanBan: any,
		coQuanCap: any,
		ghiChu: any,
		tepDinhKem: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateApprovalLlicensing', {
			params: {
				id: Id,
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				ngaycap: ngayCap,
				tenvanban: tenVanBan,
				sovanban: soVanBan,
				coquancap: coQuanCap,
				ghichu: ghiChu,
				tepdinhkem: tepDinhKem,
			},
		});
	}, // Xóa danh sách Thẩm định và cấp giấy phép
	DeleteDSThamDinh(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteApprovalLlicensing', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách Xử lý vi phạm
	getdanhsachcongtacxulyvipham(noiDungHanhVi: any, tinhThanh: any, ngayBatDau: any, ngayKetThuc: any) {
		return ApiService(COMMON_BASE_URL).get('HandlingViolations', {
			params: {
				noidunghanhvi: noiDungHanhVi,
				tinhthanh: tinhThanh,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	}, // Update danh sách Xử lý vi phạm
	Updatecongtacxulyvipham(
		Id: any,
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		doituongviphamValue: any,
		thoigianvalue: any,
		noidunghanhviValue: any,
		nhomhanhviValue: any,
		canhcaoValue: any,
		phattienValue: any,
		dinhchiValue: any,
		tamdinhchiValue: any,
		ngaybatdaudinhchiValue: any,
		ngayketthucdinhchiValue: any,
		bienphapxulykhacvl: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateHandlingViolations', {
			params: {
				id: Id,
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				doituongvipham: doituongviphamValue,
				thoigian: thoigianvalue,
				noidunghanhvi: noidunghanhviValue,
				nhomhanhvi: nhomhanhviValue,
				canhcao: canhcaoValue,
				phattien: phattienValue,
				dinhchi: dinhchiValue,
				tamdinhchi: tamdinhchiValue,
				ngaybatdaudinhchi: ngaybatdaudinhchiValue,
				ngayketthucdinhchi: ngayketthucdinhchiValue,
				bienphapxulykhac: bienphapxulykhacvl,
			},
		});
	}, // Update danh sách Xử lý vi phạm
	Addcongtacxulyvipham(
		tinhthanhId: any,
		quanhuyenId: any,
		cosocongtrinhId: any,
		doituongviphamValue: any,
		thoigianvalue: any,
		noidunghanhviValue: any,
		nhomhanhviValue: any,
		canhcaoValue: any,
		phattienValue: any,
		dinhchiValue: any,
		tamdinhchiValue: any,
		ngaybatdaudinhchiValue: any,
		ngayketthucdinhchiValue: any,
		bienphapxulykhacvl: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddHandlingViolations', {
			params: {
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				cosoId: cosocongtrinhId,
				doituongvipham: doituongviphamValue,
				thoigian: thoigianvalue,
				noidunghanhvi: noidunghanhviValue,
				nhomhanhvi: nhomhanhviValue,
				canhcao: canhcaoValue,
				phattien: phattienValue,
				dinhchi: dinhchiValue,
				tamdinhchi: tamdinhchiValue,
				ngaybatdaudinhchi: ngaybatdaudinhchiValue,
				ngayketthucdinhchi: ngayketthucdinhchiValue,
				bienphapxulykhac: bienphapxulykhacvl,
			},
		});
	}, // Xóa danh sách xử lý vi phạm
	DeleteDSXuLyViPham(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteHandlingViolations', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách Điểm cháy
	getdanhsachDiemChay(
		tenDiemChay: string,
		tinhThanh: string,
		coSoCongTrinh: string,
		ngayBatDau: string,
		ngayKetThuc: string,
	) {
		return ApiService(COMMON_BASE_URL).get('ListBurningPoint', {
			params: {
				tendiemchay: tenDiemChay,
				tinhthanh: tinhThanh,
				cosocongtrinh: coSoCongTrinh,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	},
	// Update danh sách Điểm cháy
	UpdatedanhsachDiemChay(
		Id: any,
		tenDiemChay: any,
		nguonChay: any,
		diaChi: any,
		thoiGianBao: any,
		soDienThoai: any,
		trangThai: any,
		nguyenNhanChay: any,
		chayNo: any,
		nguyenNhanLoi: any,
		tinhThanh: any,
		thoiGianBatDauxl: any,
		thuocKhuVuc: any,
		coSoCongTrinh: any,
		soCBCSThamGia: any,
		soNguoiBiThuong: any,
		uocTinhThietHai: any,
		quanHuyen: any,
		thoiGianKetThuc: any,
		kinhDo: any,
		viDo: any,
		ghiChu: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateBurningPoint', {
			params: {
				id: Id,
				tendiemchay: tenDiemChay,
				nguonchay: nguonChay,
				diachi: diaChi,
				thoigianbao: thoiGianBao,
				sodienthoai: soDienThoai,
				trangthai: trangThai,
				nguyennhanchay: nguyenNhanChay,
				chayno: chayNo,
				nguyennhanloi: nguyenNhanLoi,
				tinhId: tinhThanh,
				thoigianbatdauxl: thoiGianBatDauxl,
				thuockhuvuc: thuocKhuVuc,
				cosocongtrinh: coSoCongTrinh,
				socbcsthamgia: soCBCSThamGia,
				songuoibithuong: soNguoiBiThuong,
				uoctinhthiethai: uocTinhThietHai,
				quanhuyen: quanHuyen,
				thoigianketthuc: thoiGianKetThuc,
				kinhdo: kinhDo,
				vido: viDo,
				ghichu: ghiChu,
			},
		});
	},
	// Get danh sách Thực hiện phương án phòng cháy
	getdanhsachTTPAPhongChay(
		tinhHuongThucTap: string,
		tinhThanh: string,
		coSoCongTrinh: string,
		ngayBatDau: string,
		ngayKetThuc: string,
		id: string,
	) {
		return ApiService(COMMON_BASE_URL).get('ListFirefighTers', {
			params: {
				tinhhuongthuctap: tinhHuongThucTap,
				tinhthanh: tinhThanh,
				cosocongtrinh: coSoCongTrinh,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
				id: id,
			},
		});
	}, // Xóa Công tác xây dựng thực tập phương án chữa cháy
	DeletedanhsachTTPAPhongChay(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteFirefighTers', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách Cứu nạn cứu hộ
	getdanhsachCHCN(tenVuViec: string, tinhThanh: string, quanHuyen: string, ngayBatDau: string, ngayKetThuc: string) {
		return ApiService(COMMON_BASE_URL).get('ListRescue', {
			params: {
				tenvuviec: tenVuViec,
				tinhthanh: tinhThanh,
				quanhuyen: quanHuyen,
				ngaybatdau: ngayBatDau,
				ngayketthuc: ngayKetThuc,
			},
		});
	}, // Get danh sách Thiết bị quay
	getdanhsachCamera(tenmayquay: any) {
		return ApiService(COMMON_BASE_URL).get('ListThietBiQuay', { params: { tenthietbi: tenmayquay } });
	}, // Update danh sách Thiết bị quay
	async UpdateDSThietBiQuay(
		Id: any,
		mathietbiValue: any,
		tenthietbiValue: any,
		loaicameraId: any,
		cameraIdValue: any,
		tendangnhapValue: any,
		matKhauValue: any,
		cameraIpValue: any,
		portValue: any,
		trangthaiId: any,
		diachiValue: any,
		tinhthanhId: any,
		cosocongtrinhId: any,
		ngaytrienkhaiValue: any,
		linkValue: any,
		kinhdoValue: any,
		vidoValue: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('UpdateDSThietBiQuay', {
			params: {
				Id: Id,
				mathietbiValue: mathietbiValue,
				tenthietbiValue: tenthietbiValue,
				loaicameraId: loaicameraId,
				cameraIdValue: cameraIdValue,
				tendangnhapValue: tendangnhapValue,
				matKhauValue: matKhauValue,
				cameraIpValue: cameraIpValue,
				portValue: portValue,
				trangthaiId: trangthaiId,
				diachiValue: diachiValue,
				tinhthanhId: tinhthanhId,
				cosocongtrinhId: cosocongtrinhId,
				ngaytrienkhaiValue: ngaytrienkhaiValue,
				linkValue: linkValue,
				kinhdoValue: kinhdoValue,
				vidoValue: vidoValue,
			},
		});
	}, // Add danh sách Thiết bị quay
	async AddDSThietBiQuay(
		mathietbiValue: any,
		tenthietbiValue: any,
		loaicameraId: any,
		cameraIdValue: any,
		tendangnhapValue: any,
		matKhauValue: any,
		cameraIpValue: any,
		portValue: any,
		trangthaiId: any,
		diachiValue: any,
		tinhthanhId: any,
		cosocongtrinhId: any,
		ngaytrienkhaiValue: Moment,
		linkValue: any,
		kinhdoValue: any,
		vidoValue: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('AddDSThietBiQuay', {
			params: {
				mathietbiValue: mathietbiValue,
				tenthietbiValue: tenthietbiValue,
				loaicameraId: loaicameraId,
				cameraIdValue: cameraIdValue,
				tendangnhapValue: tendangnhapValue,
				matKhauValue: matKhauValue,
				cameraIpValue: cameraIpValue,
				portValue: portValue,
				trangthaiId: trangthaiId,
				diachiValue: diachiValue,
				tinhthanhId: tinhthanhId,
				cosocongtrinhId: cosocongtrinhId,
				ngaytrienkhaiValue: ngaytrienkhaiValue.format('DD/MM/yyyy'),
				linkValue: linkValue,
				kinhdoValue: kinhdoValue,
				vidoValue: vidoValue,
			},
		});
	}, // add danh sách công tác phòng cháy và Cứu nạn cứu hộ
	AddDanhSachCongTacPCvaCHCN(
		tinhthanhId: any,
		quanhuyenId: any,
		canbochiensyId: any,
		ngaythanhlapPAValue: any,
		cosocongtrinhId: any,
		tinhhuongthuctapValue: any,
		thoigianTTPAValue: any,
		khuvucId: any,
		donViPCCCId: any,
		cappheduyetValue: any,
		diemchayId: any,
		ykienValue: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddFirefighTers', {
			params: {
				tinhId: tinhthanhId,
				ngaylapPA: ngaythanhlapPAValue,
				cosoId: cosocongtrinhId,
				tinhhuongTT: tinhhuongthuctapValue,
				thoigianTT: thoigianTTPAValue,
				cappheduyet: cappheduyetValue,
				diemchayId: diemchayId,
				ykiennhanxet: ykienValue,
				donvithamgiaId: donViPCCCId,
				canbochiensyID: canbochiensyId,
				quanhuyenId: quanhuyenId,
				khuvucId: khuvucId,
			},
		});
	}, // Update danh sách công tác phòng cháy và Cứu nạn cứu hộ
	EditDanhSachCongTacPCvaCHCN(
		Id: any,
		tinhthanhId: any,
		quanhuyenId: any,
		canbochiensyId: any,
		ngaythanhlapPAValue: any,
		cosocongtrinhId: any,
		tinhhuongthuctapValue: any,
		thoigianTTPAValue: any,
		khuvucId: any,
		donViPCCCId: any,
		cappheduyetValue: any,
		diemchayId: any,
		ykienValue: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateFirefighTers', {
			params: {
				id: Id,
				tinhId: tinhthanhId,
				ngaylapPA: ngaythanhlapPAValue,
				cosoId: cosocongtrinhId,
				tinhhuongTT: tinhhuongthuctapValue,
				thoigianTT: thoigianTTPAValue,
				cappheduyet: cappheduyetValue,
				diemchayId: diemchayId,
				ykiennhanxet: ykienValue,
				donvithamgiaId: donViPCCCId,
				canbochiensyID: canbochiensyId,
				quanhuyenId: quanhuyenId,
				khuvucId: khuvucId,
			},
		});
	}, // Xóa danh sách Cứu nạn cứu hộ
	DeletedanhsachCHCN(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteRescue', {
			params: {
				id: Id,
			},
		});
	}, // Update danh sách công tác phòng cháy và Cứu nạn cứu hộ
	UpdateCHCN(
		Id: any,
		tencongtacValue: any,
		tennguoibaoValue: any,
		sodienthoainguoibaoValue: any,
		diachiValue: any,
		noixayravuviecId: any,
		nguyennhanValue: any,
		songuoichetValue: any,
		songuoituthoatValue: any,
		thoigianbao: any,
		thoigianstart: any,
		thoigianend: any,
		khuvucId: any,
		khoangcachgannhatValue: any,
		songuoiduoccuuValue: any,
		uoctinhthiethaiValue: any,
		socanbothamgiaValue: any,
		tomtatdienbienValue: any,
		tinhthanhId: any,
		quanhuyenId: any,
		kinhDoValue: any,
		viDoValue: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateRescue', {
			params: {
				id: Id,
				tencongtacchcn: tencongtacValue,
				thoigianbao: thoigianbao,
				tennguoibao: tennguoibaoValue,
				thoigianbatdauxuly: thoigianstart,
				sodienthoainguoibao: sodienthoainguoibaoValue,
				thoigianketthucxuly: thoigianend,
				diachi: diachiValue,
				khuvucID: khuvucId,
				noixayravuviecID: noixayravuviecId,
				khoangcachgannhat: khoangcachgannhatValue,
				nguyennhan: nguyennhanValue,
				songuoiduoccuu: songuoiduoccuuValue,
				songuoichet: songuoichetValue,
				thiethaiuoctinh: uoctinhthiethaiValue,
				songuoituthoat: songuoituthoatValue,
				socbcsthamgiacuuho: socanbothamgiaValue,
				tomtatquatrinh: tomtatdienbienValue,
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				kinhdo: kinhDoValue,
				vido: viDoValue,
			},
		});
	}, // Add danh sách công tác phòng cháy và Cứu nạn cứu hộ
	AddCHCN(
		tencongtacValue: any,
		tennguoibaoValue: any,
		sodienthoainguoibaoValue: any,
		diachiValue: any,
		noixayravuviecId: any,
		nguyennhanValue: any,
		songuoichetValue: any,
		songuoituthoatValue: any,
		thoigianbao: any,
		thoigianstart: any,
		thoigianend: any,
		khuvucId: any,
		khoangcachgannhatValue: any,
		songuoiduoccuuValue: any,
		uoctinhthiethaiValue: any,
		socanbothamgiaValue: any,
		tomtatdienbienValue: any,
		tinhthanhId: any,
		quanhuyenId: any,
		kinhDoValue: any,
		viDoValue: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddRescue', {
			params: {
				tencongtacchcn: tencongtacValue,
				thoigianbao: thoigianbao,
				tennguoibao: tennguoibaoValue,
				thoigianbatdauxuly: thoigianstart,
				sodienthoainguoibao: sodienthoainguoibaoValue,
				thoigianketthucxuly: thoigianend,
				diachi: diachiValue,
				khuvucID: khuvucId,
				noixayravuviecID: noixayravuviecId,
				khoangcachgannhat: khoangcachgannhatValue,
				nguyennhan: nguyennhanValue,
				songuoiduoccuu: songuoiduoccuuValue,
				songuoichet: songuoichetValue,
				thiethaiuoctinh: uoctinhthiethaiValue,
				songuoituthoat: songuoituthoatValue,
				socbcsthamgiacuuho: socanbothamgiaValue,
				tomtatquatrinh: tomtatdienbienValue,
				tinhId: tinhthanhId,
				huyenId: quanhuyenId,
				kinhdo: kinhDoValue,
				vido: viDoValue,
			},
		});
	},
	// Get danh sách Loại điểm lấy nước
	getdanhsachLoaiDiemLayNuoc() {
		return ApiService(COMMON_BASE_URL).get('WaterPoint');
	},
	// Get danh sách độ quan trọng
	getdanhsachDoQuanTrong() {
		return ApiService(COMMON_BASE_URL).get('Importance');
	},
	// Thêm mới danh sách cán bộ chiến sỹ
	async AddDanhSachCanBoChienSy(
		hoTen: any,
		capBacID: any,
		chucVuID: any,
		soDienThoai: any,
		tinhThanhID: any,
		trucThuoc: any,
		ngaySinh: any,
		soCmt: any,
		donViID: any,
		hinhAnh: any,
		quanhuyenID: any,
	) {
		return await ApiService(COMMON_BASE_URL).get('AddSolider', {
			params: {
				hoten: hoTen,
				capbacID: capBacID,
				chucvuID: chucVuID,
				sodienthoai: soDienThoai,
				tinhthanhID: tinhThanhID,
				tructhuoc: trucThuoc,
				ngaysinh: ngaySinh,
				socmt: soCmt,
				donviID: donViID,
				hinhanh: 'hinhAnh',
				quanhuyen: quanhuyenID,
			},
		});
	}, // Chỉnh sửa danh sách cán bộ chiến sỹ
	EditDanhSachCanBoChienSy(
		Id: any,
		hoTen: any,
		capBacID: any,
		chucVuID: any,
		soDienThoai: any,
		tinhThanhID: any,
		trucThuoc: any,
		ngaySinh: any,
		soCmt: any,
		donViID: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateSolider', {
			params: {
				id: Id,
				hoten: hoTen,
				capbacID: capBacID,
				chucvuID: chucVuID,
				sodienthoai: soDienThoai,
				tinhthanhID: tinhThanhID,
				tructhuoc: trucThuoc,
				ngaysinh: ngaySinh,
				socmt: soCmt,
				donviID: donViID,
				hinhanh: 'hinhAnh',
			},
		});
	}, // Xóa danh sách cán bộ chiến sỹ
	DeleteDanhSachCanBoChienSy(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteSolider', {
			params: {
				id: Id,
			},
		});
	},
	// Chỉnh sửa danh sách phương tiện phòng cháy chữa cháy
	EditDanhSachPhuongTienPCCC(
		Id: any,
		tenPt: any,
		loaiPhuongTienID: any,
		soHieu: any,
		trangThaiID: any,
		bienSo: any,
		tinhThanhID: any,
		soDienThoai: any,
		trucThuocID: any,
		Imei: any,
		donViId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateVehicle', {
			params: {
				id: Id,
				tenpt: tenPt,
				loaiphuongtienID: loaiPhuongTienID,
				sohieu: soHieu,
				trangthaiID: trangThaiID,
				bienso: bienSo,
				tinhthanhID: tinhThanhID,
				sodienthoai: soDienThoai,
				tructhuocID: trucThuocID,
				Imei: Imei,
				donviId: donViId,
			},
		});
	},
	// Xóa danh sách phương tiện phòng cháy chữa cháy
	DeleteDanhSachPhuongTienPCCC(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteVehicle', {
			params: {
				id: Id,
			},
		});
	},
	// Thêm mới đơn vị phòng cháy chữa cháy
	addDonViPCCC(
		maDonVi: any,
		tenDonVi: any,
		canBoQuanLy: any,
		loaiHinhDonViID: any,
		soDienThoaiTrucBan: any,
		soDiemLayNuocQuanLy: any,
		soNhanSuQuanLy: any,
		soNhanSuChuaChay: any,
		tinhID: any,
		quanHuyenID: any,
		kinhDo: any,
		viDo: any,
		diaChi: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddUnitPCCC', {
			params: {
				madonvi: maDonVi,
				tendonvi: tenDonVi,
				canboquanly: canBoQuanLy,
				LoaihinhdonviID: loaiHinhDonViID,
				sodienthoaitrucban: soDienThoaiTrucBan,
				sodiemlaynuocquanly: soDiemLayNuocQuanLy,
				sonhansuPCCCquanly: soNhanSuQuanLy,
				sonhansuchuachay: soNhanSuChuaChay,
				tinhthanhID: tinhID,
				quanhuyenID: quanHuyenID,
				kinhdo: kinhDo,
				vido: viDo,
				diachi: diaChi,
			},
		});
	},
	// Cập nhật đơn vị phòng cháy chữa cháy
	UpdateDonViPCCC(
		id: any,
		maDonVi: any,
		tenDonVi: any,
		canBoQuanLy: any,
		loaiHinhDonViID: any,
		soDienThoaiTrucBan: any,
		soDiemLayNuocQuanLy: any,
		soNhanSuQuanLy: any,
		soNhanSuChuaChay: any,
		tinhID: any,
		quanHuyenID: any,
		kinhDo: any,
		viDo: any,
		diaChi: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateUnitPCCC', {
			params: {
				id: id,
				madonvi: maDonVi,
				tendonvi: tenDonVi,
				canboquanly: canBoQuanLy,
				LoaihinhdonviID: loaiHinhDonViID,
				sodienthoaitrucban: soDienThoaiTrucBan,
				sodiemlaynuocquanly: soDiemLayNuocQuanLy,
				sonhansuPCCCquanly: soNhanSuQuanLy,
				sonhansuchuachay: soNhanSuChuaChay,
				tinhthanhID: tinhID,
				quanhuyenID: quanHuyenID,
				kinhdo: kinhDo,
				vido: viDo,
				diachi: diaChi,
			},
		});
	},
	// Thêm mới phương tiện phòng cháy chữa cháy
	AddPhuongTienPCCC(
		tenPt: any,
		loaiPhuongTienID: any,
		soHieu: any,
		trangThaiID: any,
		bienSo: any,
		tinhThanhID: any,
		soDienThoai: any,
		trucThuocID: any,
		Imei: any,
		donViId: any,
		huyenId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddVehicle', {
			params: {
				tenpt: tenPt,
				loaiphuongtienID: loaiPhuongTienID,
				sohieu: soHieu,
				trangthaiID: trangThaiID,
				bienso: bienSo,
				tinhthanhID: tinhThanhID,
				sodienthoai: soDienThoai,
				tructhuocID: trucThuocID,
				Imei: Imei,
				donviId: donViId,
				quanhuyen: huyenId,
			},
		});
	},
	// Thêm mới Đơn vị hỗ trợ
	AddDonViHoTro(
		maDonVi: any,
		tenDonVi: any,
		soDienThoai: any,
		loaiDonViID: any,
		tinhThanhID: any,
		quanHuyenID: any,
		kinhDo: any,
		viDo: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddUnitOfSupport', {
			params: {
				madonvi: maDonVi,
				tendonvi: tenDonVi,
				sodienthoai: soDienThoai,
				loaidonviID: loaiDonViID,
				tinhthanhID: tinhThanhID,
				quanhuyenID: quanHuyenID,
				kinhdo: kinhDo,
				vido: viDo,
			},
		});
	},
	// Chỉnh sửa Đơn vị hỗ trợ
	EditDonViHoTro(
		Id: any,
		maDonVi: any,
		tenDonVi: any,
		soDienThoai: any,
		loaiDonViID: any,
		tinhThanhID: any,
		quanHuyenID: any,
		kinhDo: any,
		viDo: any,
	) {
		return ApiService(COMMON_BASE_URL).get('updateUnitOfSupport', {
			params: {
				id: Id,
				madonvi: maDonVi,
				tendonvi: tenDonVi,
				sodienthoai: soDienThoai,
				loaidonviID: loaiDonViID,
				tinhthanhID: tinhThanhID,
				quanhuyenID: quanHuyenID,
				kinhdo: kinhDo,
				vido: viDo,
			},
		});
	},
	// Thêm mới Đơn vị hỗ trợ
	DeleteDonViHoTro(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteUnitOfSupport', {
			params: {
				id: Id,
			},
		});
	},
	// Thêm mới Điểm lấy nước
	AddDiemLayNuoc(
		maDiem: any,
		tenDiem: any,
		tinhThanhID: any,
		quanHuyenID: any,
		donViQuanLyID: any,
		loaiDiemLayNuocID: any,
		kinhDo: any,
		viDo: any,
		moTa: any,
		doQuanTrongID: any,
		diaChi: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddWaterPoint', {
			params: {
				madiem: maDiem,
				tendiem: tenDiem,
				tinhthanhID: tinhThanhID,
				quanhuyenID: quanHuyenID,
				donviquanlyID: donViQuanLyID,
				loaidiemlaynuocID: loaiDiemLayNuocID,
				kinhdo: kinhDo,
				vido: viDo,
				mota: moTa,
				doquantrongID: doQuanTrongID,
				diachi: diaChi,
			},
		});
	}, // Cập nhật Điểm lấy nước
	EditDiemLayNuoc(
		Id: any,
		maDiem: any,
		tenDiem: any,
		tinhThanhID: any,
		quanHuyenID: any,
		donViQuanLyID: any,
		loaiDiemLayNuocID: any,
		kinhDo: any,
		viDo: any,
		moTa: any,
		doQuanTrongID: any,
		diaChi: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateWaterPoint', {
			params: {
				id: Id,
				madiem: maDiem,
				tendiem: tenDiem,
				tinhthanhID: tinhThanhID,
				quanhuyenID: quanHuyenID,
				donviquanlyID: donViQuanLyID,
				loaidiemlaynuocID: loaiDiemLayNuocID,
				kinhdo: kinhDo,
				vido: viDo,
				mota: moTa,
				doquantrongID: doQuanTrongID,
				diachi: diaChi,
			},
		});
	},
	// Xóa Điểm lấy nước
	DeleteDiemLayNuoc(Id: any) {
		return ApiService(COMMON_BASE_URL).get('DeleteWaterPoint', {
			params: {
				id: Id,
			},
		});
	}, // Get danh sách quận huyện by tinhId
	listQuanHuyenByTinhId(tinhId: any) {
		return ApiService(COMMON_BASE_URL).get('ListQuanHuyenByTinhId', {
			params: {
				tinhId: tinhId,
			},
		});
	}, // Get danh sách đơn vị PCCC by huyenId
	listDonViPCCCByHuyen(huyenId: any) {
		return ApiService(COMMON_BASE_URL).get('ListDonViPCCCByHuyen', {
			params: {
				huyenId: huyenId,
			},
		});
	}, // Get danh sách điểm cháy trực tuyến theo trạng thái
	getListDiemChayTT(trangThaiId?: any) {
		return ApiService(COMMON_BASE_URL).get('GetListDiemChayTT', {
			params: {
				trangThaiId: trangThaiId,
			},
		});
	}, // Update status điểm cháy trực tuyến
	updateStatusDiemChayTT(id: number, trangThaiId: number, donViPcccId?: number) {
		return ApiService(COMMON_BASE_URL).post('UpdateStatusDiemChayTT', {
			diemChayTT: {
				id: id,
				trangThai: trangThaiId,
				donViPccc: {
					id: donViPcccId,
				},
			},
		});
	},
	// Get danh sách thiết bị trung tâm
	GetDSThietBiTrungTam(donviId?: any, tinhthanhId?: any, cosocongtrinhId?: any, trangthaiId?: any) {
		return ApiService(COMMON_BASE_URL).get('ListThietBiTrungTam', {
			params: {
				donviId: donviId,
				tinhthanhId: tinhthanhId,
				cosocongtrinhId: cosocongtrinhId,
				trangthaiId: trangthaiId,
			},
		});
	},
	// add danh sách cảm biến
	AddDanhSachThietBi(
		mathietbi: any,
		imeithietbi: any,
		ngaytrienkhai: any,
		cosocongtrinhId: any,
		dailycungcapId: any,
		goicuoc: any,
		tenthietbi: any,
		loaithietbiId: any,
		ngayhethan: any,
		diachi: any,
		donviphattrien: any,
		trangthaiId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddDSThietBiTT', {
			params: {
				mathietbi: mathietbi,
				imeithietbi: imeithietbi,
				ngaytrienkhai: ngaytrienkhai,
				cosocongtrinhId: cosocongtrinhId,
				dailycungcapId: dailycungcapId,
				goicuoc: goicuoc,
				tenthietbi: tenthietbi,
				loaithietbiId: loaithietbiId,
				ngayhethan: ngayhethan,
				diachi: diachi,
				donviphattrien: donviphattrien,
				trangthaiId: trangthaiId,
			},
		});
	},
	// Update danh sách cảm biến
	EditDanhSachThietBi(
		id: any,
		mathietbi: any,
		imeithietbi: any,
		ngaytrienkhai: any,
		cosocongtrinhId: any,
		dailycungcapId: any,
		goicuoc: any,
		tenthietbi: any,
		loaithietbiId: any,
		ngayhethan: any,
		diachi: any,
		donviphattrien: any,
		trangthaiId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateDSThietBiTT', {
			params: {
				Id: id,
				mathietbi: mathietbi,
				imeithietbi: imeithietbi,
				ngaytrienkhai: ngaytrienkhai,
				cosocongtrinhId: cosocongtrinhId,
				dailycungcapId: dailycungcapId,
				goicuoc: goicuoc,
				tenthietbi: tenthietbi,
				loaithietbiId: loaithietbiId,
				ngayhethan: ngayhethan,
				diachi: diachi,
				donviphattrien: donviphattrien,
				trangthaiId: trangthaiId,
			},
		});
	},
	// Get danh sách đơn vị Pccc
	getListDonViPccc() {
		return ApiService(COMMON_BASE_URL).get('GetListDonViPccc');
	},
	// Thêm mới Lich Truc
	AddLichTruc(
		dateFrom: any,
		dateTo: any,
		tinhthanhId: any,
		quanhuyenId: any,
		tongsocanbochiensy: any,
		socanbochiensycomat: any,
		trucchihuyId: any,
		trucbanId: any,
		phuongtienId: any,
		noidungtinhhinhtruc: any,
		donviId: any,
		truongcaId: any,
		canbotonghopId: any,
		hanhchinh: any,
		socanbochiensyvangmat: any,
		truckiemtraId: any,
		tructhongtinId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddDuty', {
			params: {
				ngaytrucfrom: dateFrom,
				ngaytructo: dateTo,
				tinhID: tinhthanhId,
				huyenID: quanhuyenId,
				donviID: donviId,
				truongcaID: truongcaId,
				canbotonghopID: canbotonghopId,
				tongsocbcs: tongsocanbochiensy,
				socbcscomat: socanbochiensycomat,
				hanhchinh: hanhchinh,
				socbcsvangmat: socanbochiensyvangmat,
				trucchihuyID: trucchihuyId,
				trucbanID: trucbanId,
				truckiemtraID: truckiemtraId,
				tructhongtinID: tructhongtinId,
				phuongtienPCCCID: phuongtienId,
				tinhhinhtrongngay: noidungtinhhinhtruc,
			},
		});
	},
	// Update Lich Truc
	UpdateLichTruc(
		id: any,
		dateFrom: any,
		dateTo: any,
		tinhthanhId: any,
		quanhuyenId: any,
		tongsocanbochiensy: any,
		socanbochiensycomat: any,
		trucchihuyId: any,
		trucbanId: any,
		phuongtienId: any,
		noidungtinhhinhtruc: any,
		donviId: any,
		truongcaId: any,
		canbotonghopId: any,
		hanhchinh: any,
		socanbochiensyvangmat: any,
		truckiemtraId: any,
		tructhongtinId: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateDuty', {
			params: {
				id: id,
				ngaytrucfrom: dateFrom,
				ngaytructo: dateTo,
				tinhID: tinhthanhId,
				huyenID: quanhuyenId,
				donviID: donviId,
				truongcaID: truongcaId,
				canbotonghopID: canbotonghopId,
				tongsocbcs: tongsocanbochiensy,
				socbcscomat: socanbochiensycomat,
				hanhchinh: hanhchinh,
				socbcsvangmat: socanbochiensyvangmat,
				trucchihuyID: trucchihuyId,
				trucbanID: trucbanId,
				truckiemtraID: truckiemtraId,
				tructhongtinID: tructhongtinId,
				phuongtienPCCCID: phuongtienId,
				tinhhinhtrongngay: noidungtinhhinhtruc,
			},
		});
	},
	// Get danh sách Cảm biến
	GetDanhSachCamBien(macambien: any, loaicambienId: any, trangthaicambienId: any, trangthaighepnoiId: any) {
		return ApiService(COMMON_BASE_URL).get('GetDanhSachCamBien', {
			params: {
				ma: macambien,
				loaicambienId: loaicambienId,
				trangthaicambienId: trangthaicambienId,
				trangthaighepnoiId: trangthaighepnoiId,
			},
		});
	},
	// add danh sách cảm biến
	AddDanhSachCamBien(
		loaiCamBienId: any,
		macambien: any,
		imeiCamBien: any,
		trangThaiCambienId: any,
		ngaytrienkhaiValue: any,
		ngayhethanValue: any,
		trangthaibanhangId: any,
		imeiThietBiGhepNoiId: any,
		motacambien: any,
	) {
		return ApiService(COMMON_BASE_URL).get('AddDSCamBien', {
			params: {
				enumFromLabel: loaiCamBienId,
				macambien: macambien,
				imeiCamBien: imeiCamBien,
				trangThaiCambienId: trangThaiCambienId,
				ngaytrienkhaiValue: ngaytrienkhaiValue,
				ngayhethanValue: ngayhethanValue,
				trangthaibanhangId: trangthaibanhangId,
				imeiThietBiGhepNoiId: imeiThietBiGhepNoiId,
				motacambien: motacambien,
			},
		});
	},
	// add danh sách cảm biến
	UpdateDanhSachCamBien(
		Id: any,
		loaiCamBienId: any,
		macambien: any,
		imeiCamBien: any,
		trangThaiCambienId: any,
		ngaytrienkhaiValue: any,
		ngayhethanValue: any,
		trangthaibanhangId: any,
		imeiThietBiGhepNoiId: any,
		motacambien: any,
	) {
		return ApiService(COMMON_BASE_URL).get('UpdateDSCamBien', {
			params: {
				Id: Id,
				enumFromLabel: loaiCamBienId,
				macambien: macambien,
				imeiCamBien: imeiCamBien,
				trangThaiCambienId: trangThaiCambienId,
				ngaytrienkhaiValue: ngaytrienkhaiValue,
				ngayhethanValue: ngayhethanValue,
				trangthaibanhangId: trangthaibanhangId,
				imeiThietBiGhepNoiId: imeiThietBiGhepNoiId,
				motacambien: motacambien,
			},
		});
	},
	// Thêm điểm cháy tt mới
	addDiemChayTT(data: any) {
		return ApiService(COMMON_BASE_URL).post('AddDiemChayTT', {
			diemChayTT: data,
		});
	},
	// Get data chart dsDiemChay
	getChartDataDsDiemChay() {
		return ApiService(COMMON_BASE_URL).get('GetChartDataDsDiemChay');
	},
	getSoThietBiTheoCoSoCongTrinh(tinhId: any, startDate: any, endDate: any) {
		return ApiService(COMMON_BASE_URL).get('GetSoThietBiTheoCoSoCongTrinh', {
			params: {
				tinhId: tinhId,
				startDate: startDate,
				endDate: endDate,
			},
		});
	},
	getSoThietBiTheoPhanLoai(tinhId?: any, startDate?: any, endDate?: any) {
		return ApiService(COMMON_BASE_URL).get('getSoThietBiTheoPhanLoai', {
			params: {
				tinhId: tinhId,
				startDate: startDate,
				endDate: endDate,
			},
		});
	},
	getSoVuChayTheoTrangThai(searchData?: any) {
		return ApiService(COMMON_BASE_URL).get('getSoVuChayTheoTrangThai', {
			params: searchData,
		});
	},
	getSoVuChayTheoNam(searchData?: any) {
		return ApiService(COMMON_BASE_URL).get('getSoVuChayTheoNam', {
			params: searchData,
		});
	},
	getChiTietVuChayTheoNam(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getChiTietVuChayTheoNam', {
			params: searchData,
		});
	},
	getSoPhuongTienTheoTrangThai(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getSoPhuongTienTheoTrangThai', {
			params: searchData,
		});
	},
	getDsPhuongTien(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getDsPhuongTien', {
			params: searchData,
		});
	},
	getSoLuongDanhGia(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getSoLuongDanhGia', {
			params: searchData,
		});
	},
	getDsQuyetDinhKiemTra(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getDsQuyetDinhKiemTra', {
			params: searchData,
		});
	},
	getSoCbcsThamGiaChuaChay(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getSoCbcsThamGiaChuaChay', {
			params: searchData,
		});
	},
	getDsCoSoCongTrinh(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getDsCoSoCongTrinh', {
			params: searchData,
		});
	},
	getDsTongHopKetQuaKiemTraAnToanPccc(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getDsTongHopKetQuaKiemTraAnToanPccc', {
			params: searchData,
		});
	},
	getThongKeSoLieuChayNoTheoKhuVuc(searchData: any) {
		return ApiService(COMMON_BASE_URL).get('getThongKeSoLieuChayNoTheoKhuVuc', {
			params: searchData,
		});
	},
	getChartDataThietBi(noMonth?: any) {
		return ApiService(COMMON_BASE_URL).get('getChartDataThietBi', {
			params: {
				tinhId: noMonth,
			},
		});
	},
	getChartDataThietHai(noMonth?: any) {
		return ApiService(COMMON_BASE_URL).get('getChartDataThietHai', {
			params: {
				tinhId: noMonth,
			},
		});
	},
	getListDonViPcccTheoTinh(tinhId: any, huyenId?: any) {
		return ApiService(COMMON_BASE_URL).get('GetListDonViPcccTheoTinh', {
			params: {
				tinhId: tinhId,
				huyenId: huyenId,
			},
		});
	},
	GetDonViPccc(id: any) {
		return ApiService(COMMON_BASE_URL).get('GetDonViPccc', {
			params: {
				id: id,
			},
		});
	},
	GetChiTietDiemChay(id: any) {
		return ApiService(COMMON_BASE_URL).get('GetChiTietDiemChay', {
			params: {
				id: id,
			},
		});
	},
	GetChiTietCoSoCongTrinh(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietCoSoCongTrinh', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
	GetChiTietDonViHoTro(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietDonViHoTro', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
	GetChiTietDiemLayNuoc(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietDiemLayNuoc', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
	GetChiTietKiemTraCoSoCongTrinh(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietKiemTraCoSoCongTrinh', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
	GetChiTietKeHoachLamViec(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietKeHoachLamViec', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
	GetChiTietThietBiQuay(id: number) {
		return ApiService(COMMON_BASE_URL)
			.get('GetChiTietThietBiQuay', {
				params: {
					id: id,
				},
			})
			.then((res: any) => {
				return Promise.resolve(res?.data);
			});
	},
};

export const CHE_DO_LOC = {
	DON_VI_QUAN_LY: {
		id: 0,
		value: 'Đơn vị quản lý',
	},
	NGANH_NGHE_KINH_DOANH: {
		id: 1,
		value: 'Ngành nghề kinh doanh',
	},
	NGUYEN_NHAN: {
		id: 2,
		value: 'Nguyên nhân',
	},
	MUC_DO_THIET_HAI: {
		id: 3,
		value: 'Mức độ thiệt hại',
	},
	LOAI_HINH_CO_SO: {
		id: 4,
		value: 'Loại hình cơ sở',
	},
	TRANG_THAI_XU_LY: {
		id: 5,
		value: 'Trạng thái xử lý',
	},
	get: (keys: any[]) => {
		const valueList = Object.values(CHE_DO_LOC);
		let result: any[] = [];

		keys.forEach(key => {
			if (valueList[key.id]) {
				result.push(valueList[key.id]);
			}
		});

		return result;
	},
};
