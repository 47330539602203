import { Form, FormInstance, Select } from 'antd';
import danhGiaEnum from 'enum/danhGiaEnum';
import React from 'react';

interface EvaluateProps {
	name: string;
	disabled?: boolean;
	form: FormInstance<any>;
	rules?: any;
	formItemClassName?: string;
	selectClassName?: string;
	label?: string;
	onChangeEvaluate?: (value: string) => void;
}

const EvaluateSelect = (props: EvaluateProps) => {
	const { name, formItemClassName, label, selectClassName, onChangeEvaluate, disabled, rules } = props;

	const evaluateList = danhGiaEnum.getAll();
	return (
		<Form.Item name={name} className={formItemClassName} rules={rules} label={label}>
			<Select
				showSearch
				allowClear
				placeholder="Chọn đánh giá"
				optionFilterProp="children"
				className={selectClassName}
				onChange={onChangeEvaluate}
				disabled={disabled}>
				{evaluateList.map(item => (
					<Select.Option value={item.value} key={item.value}>
						{item.label}
					</Select.Option>
				))}
			</Select>
		</Form.Item>
	);
};

export default EvaluateSelect;
