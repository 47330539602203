import ApiService, { BUSINESS_BASE_URL, PageOption } from '../../index';

export const prefixPropagate = '/propagate';

const _createPropagate = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).post(`${prefixPropagate}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deletePropagate = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).delete(`${prefixPropagate}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _getPropagate = (id: string): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).get(`${prefixPropagate}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchPropagates = (param: any, pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).post(
		`${prefixPropagate}/search`, param, {
		headers: {
			'PageOption': JSON.stringify(pageOption)
		}
	}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const _searchPropagatesByContruction = (param: string[]): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).post(
		`${prefixPropagate}/getByListConstruction`, param).then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updatePropagate = (input: any): Promise<any> =>
	ApiService(BUSINESS_BASE_URL).put(`${prefixPropagate}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listPropagate = (pageOption?: PageOption): Promise<any[]> =>
	ApiService(BUSINESS_BASE_URL).get(`${prefixPropagate}`,
		{
			headers: {
				'PageOption': JSON.stringify(pageOption)
			}
		}).then(({ data }: { data: any[] }) => Promise.resolve(data));

const PropagateService = {
	createPropagate: _createPropagate,
	deletePropagate: _deletePropagate,
	getPropagate: _getPropagate,
	searchPropagates: _searchPropagates,
	updatePropagate: _updatePropagate,
	listPropagate: _listPropagate,
	searchPropagatesByContruction: _searchPropagatesByContruction
};

export default PropagateService;
