import { SET_COLLAPSE, SET_TITLE } from 'redux/NavBar/navBar.type';
import { ReduxAction } from 'redux/store';

interface NavBarState {
	collapse: boolean;
	title: string;
}

const NAV_BAR: NavBarState = {
	collapse: false,
	title: '',
};

const navBarReducer = (state = NAV_BAR, action: ReduxAction): NavBarState => {
	switch (action.type) {
		case SET_TITLE:
			return {
				...state,
				title: action.payload + '',
			};
		case SET_COLLAPSE:
			return {
				...state,
				collapse: !!action.payload,
			};
		default:
			return state;
	}
};

export default navBarReducer;