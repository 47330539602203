import React, { ReactNode } from 'react';

interface DialogLayoutProps {
	children: ReactNode;
	className?: string
}

const DialogLayout = ({ children, className }: DialogLayoutProps) => {
	return <div style={{ padding: '24px' }} className={className}>{children}</div>;
};

export default DialogLayout;
