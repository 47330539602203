import localeVi from './vi_date';

const _isPhone = (mobile: any) => {
	const vnf_regex = /^[\d_-]{9}$|^[\d_-]{12}$/g;
	return vnf_regex.test(mobile);
};

const _isCanCuocCongDan = (mobile: any) => {
	const vnf_regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/g;
	return vnf_regex.test(mobile);
};

const CommonControl = {
	isPhone: _isPhone,
	isCanCuocCongDan: _isCanCuocCongDan,
	localeVi,
};

export default CommonControl;