import { t } from 'i18next';

export enum LoaiDonViEnum {
	DOI_PCCC_VA_CNCH = 'DOI_PCCC_VA_CNCH',
	LUC_LUONG_CHUA_CHAY_TAI_CHO = 'LUC_LUONG_CHUA_CHAY_TAI_CHO',
	DAN_PHONG = 'DAN_PHONG',
	BENH_VIEN = 'BENH_VIEN',
	CONG_AN = 'CONG_AN',
	QUAN_DOI = 'QUAN_DOI',
	DIEN_LUC = 'DIEN_LUC',
}

interface LoaiDonVi {
	label: string;
	value: LoaiDonViEnum;
}

const _fromEnum = (loaiDonViEnum: LoaiDonViEnum): LoaiDonVi => {
	switch (loaiDonViEnum) {
		case LoaiDonViEnum.DOI_PCCC_VA_CNCH:
			return {
				label: t('enum.loaiDonVi.DOI_PCCC_VA_CNCH'),
				value: LoaiDonViEnum.DOI_PCCC_VA_CNCH,
			};
		case LoaiDonViEnum.LUC_LUONG_CHUA_CHAY_TAI_CHO:
			return {
				label: t('enum.loaiDonVi.LUC_LUONG_CHUA_CHAY_TAI_CHO'),
				value: LoaiDonViEnum.LUC_LUONG_CHUA_CHAY_TAI_CHO,
			};
		case LoaiDonViEnum.DAN_PHONG:
			return {
				label: t('enum.loaiDonVi.DAN_PHONG'),
				value: LoaiDonViEnum.DAN_PHONG,
			};
		case LoaiDonViEnum.BENH_VIEN:
			return {
				label: t('enum.loaiDonVi.BENH_VIEN'),
				value: LoaiDonViEnum.BENH_VIEN,
			};
		case LoaiDonViEnum.DIEN_LUC:
			return {
				label: t('enum.loaiDonVi.DIEN_LUC'),
				value: LoaiDonViEnum.DIEN_LUC,
			};
		case LoaiDonViEnum.QUAN_DOI:
			return {
				label: t('enum.loaiDonVi.QUAN_DOI'),
				value: LoaiDonViEnum.QUAN_DOI,
			};

		case LoaiDonViEnum.CONG_AN:
			return {
				label: t('enum.loaiDonVi.CONG_AN'),
				value: LoaiDonViEnum.CONG_AN,
			};
	}
};

const _enumFromLabel = (loaiDonViLabel: string): LoaiDonViEnum | undefined => {
	switch (loaiDonViLabel.toUpperCase()) {
		case t('enum.loaiDonVi.DOI_PCCC_VA_CNCH').toUpperCase():
			return LoaiDonViEnum.DOI_PCCC_VA_CNCH;
		case t('enum.loaiDonVi.BENH_VIEN').toUpperCase():
			return LoaiDonViEnum.BENH_VIEN;
		case t('enum.loaiDonVi.CONG_AN').toUpperCase():
			return LoaiDonViEnum.CONG_AN;
		case t('enum.loaiDonVi.DAN_PHONG').toUpperCase():
			return LoaiDonViEnum.DAN_PHONG;
		case t('enum.loaiDonVi.DIEN_LUC').toUpperCase():
			return LoaiDonViEnum.DIEN_LUC;
		case t('enum.loaiDonVi.LUC_LUONG_CHUA_CHAY_TAI_CHO').toUpperCase():
			return LoaiDonViEnum.LUC_LUONG_CHUA_CHAY_TAI_CHO;
		case t('enum.loaiDonVi.QUAN_DOI').toUpperCase():
			return LoaiDonViEnum.QUAN_DOI;
	}
};

const _getAll = (): LoaiDonVi[] => {
	return Object.values(LoaiDonViEnum).map(r => _fromEnum(r as LoaiDonViEnum));
};

const loaiDonViEnum = {
	enumFromLabel: _enumFromLabel,
	fromEnum: _fromEnum,
	getAll: _getAll,
};

export default loaiDonViEnum;
