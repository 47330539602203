import React, { memo, ReactNode } from 'react';

interface TitleProps {
	title: ReactNode;
}

const TitleComponent: React.FC<TitleProps> = ({ title }: TitleProps) => {
	return <h2 className="heading--6 mb15">{title}</h2>;
};

export default memo(TitleComponent);
