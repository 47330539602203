import { ColumnsType } from 'antd/lib/table/interface';
import TableComponent from 'components/shared/TableComponent/TableComponent';
import { TableOption } from 'constants/tableOption.const';
import { formatDate } from 'helpers';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { PageOption } from 'services/api';
import DeviceService from 'services/api/inventoryService/deviceService';

const CURRENT_PAGE = TableOption.CURRENT_PAGE;
const PAGE_SIZE = TableOption.DEFAULT_SIZE;

interface IDialogDevicesParams {
	Id?: any;
}
const DialogCentralDeviceList: FunctionComponent<IDialogDevicesParams> = (props: IDialogDevicesParams) => {
	const [currentPage, setCurrentPage] = useState<number>(CURRENT_PAGE);
	const [pageSizeDefault, setPageSizeDefault] = useState(PAGE_SIZE);
	const [dataDevices, setDataDevices] = useState<any[]>([]);

	const tableColums: ColumnsType<any> = [
		{
			align: 'center' as const,
			width: 45,
			className: 'min-col',
			title: 'STT',
			fixed: 'left',
			render: (value: any, item: any, index: any) => (currentPage - 1) * pageSizeDefault + index + 1,
		},
		{
			title: 'Tên thiết bị',
			dataIndex: 'Name',
			key: 'Name',
			width: 150,
		},
		{
			title: 'Loại thiết bị',
			dataIndex: 'LoaiThietBi',
			key: 'LoaiThietBi',
			width: 150,
		},
		{
			title: 'Ngày đăng ký',
			dataIndex: 'ActiveTime',
			key: 'ActiveTime',
			width: 150,
			render: (ActiveTime: string) => <>{ActiveTime ? formatDate(ActiveTime) : null}</>,
		},
		{
			width: 150,
			title: 'Ngày hết hạn',
			dataIndex: 'ExpiredTime',
			key: 'ExpiredTime',
			render: (ExpiredTime: string) => <>{ExpiredTime ? formatDate(ExpiredTime) : null}</>,
		},
		{
			width: 150,
			title: 'Trạng thái thiết bị',
			dataIndex: 'UpdateTime',
			key: 'UpdateTime',
			// render: (UpdateTime: string) => <>{UpdateTime ?`online ${moment(UpdateTime).fromNow()}`  : null}</>,
			render: (UpdateTime: string) => (
				<>{moment().isBefore(moment(UpdateTime).add(15, 'minutes')) ? 'online' : 'offline'}</>
			),
		},
	];

	const handleChangePage = (page: number, pageSize: number) => {};

	useEffect(() => {
		if (props.Id) {
			getDataTimKiemDSThietBiTrungTam();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.Id]);

	const getDataTimKiemDSThietBiTrungTam = (page = 1, pageSize = 10, index = 0) => {
		const pageOption: PageOption = {
			CurrentPage: page,
			PageSize: pageSize,
			Paging: true,
		};
		// get danh sách Xử lý vi phạm
		const paramSearch = [props.Id];
		DeviceService.searchDeviceByConstruction(paramSearch, pageOption).then(setDataDevices);
	};

	return (
		<div style={{ padding: '24px' }}>
			<TableComponent
				columns={tableColums}
				dataSource={dataDevices}
				paginator={{
					defaultPageSize: pageSizeDefault,
					showSizeChanger: true,
					onChange: handleChangePage,
					// total: totalItems,
					current: currentPage,
					pageSizeOptions: TableOption.PAGE_SIZE_OPTION,
				}}
			/>
		</div>
	);
};

export default DialogCentralDeviceList;
