import { t } from 'i18next';

export enum TrucThuocEnum {
	PC07 = 'PC07',
	QUAN_HUYEN = 'QUAN_HUYEN'
}

interface TrucThuoc {
	value: TrucThuocEnum,
	label: string
}

const _fromEnum = (trucThuocEnum: TrucThuocEnum): TrucThuoc => {
	switch (trucThuocEnum) {
		case TrucThuocEnum.PC07:
			return {
				label: t('enum.trucThuoc.PC07'),
				value: TrucThuocEnum.PC07,
			};
		case TrucThuocEnum.QUAN_HUYEN:
			return {
				label: t('enum.trucThuoc.QUAN_HUYEN'),
				value: TrucThuocEnum.QUAN_HUYEN,
			};
	}
};

const _enumFromLabel = (trucThuocLabel: string): TrucThuocEnum | undefined => {
	switch (trucThuocLabel.toUpperCase()) {
		case t('enum.trucThuoc.PC07').toUpperCase():
			return TrucThuocEnum.PC07;
		case t('enum.trucThuoc.QUAN_HUYEN').toUpperCase():
			return TrucThuocEnum.QUAN_HUYEN;
	}
};

const _getAll = (): TrucThuoc[] => {
	return Object.values(TrucThuocEnum).map(r => _fromEnum(r as TrucThuocEnum));
};

const trucThuocEnum = {
	fromEnum: _fromEnum,
	enumFromLabel: _enumFromLabel,
	getAll: _getAll,
};

export default trucThuocEnum;
