import { ACTION_TYPE } from 'redux/checkers-selected/checkers-selected.action';
import { ReduxAction } from 'redux/store';

export interface CheckerSelectedState {
	checkers: any;
}

const INITIAL_STATE = { checkers: [] };

const checkerSelectedReducer = (state = INITIAL_STATE, action: ReduxAction): CheckerSelectedState => {
	switch (action.type) {
		case ACTION_TYPE.SELECT_CHECKERS:
			return {
				checkers: [...action.payload],
			};
		case ACTION_TYPE.RESET_DATA:
			return {
				checkers: []
			};
		default:
			return state;
	}
};

export default checkerSelectedReducer;
