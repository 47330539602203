import { CanBoKiemTraModel } from 'models/CanBoKiemTra/CanBoKiemTra.model';
import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { LocationInfo } from 'services/api/inventoryService/locationService';

export const prefixFireFighter = '/fireFighter';

const createFireFighter = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const deleteFireFighter = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixFireFighter}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const findFireFightersByName = (name: string, pageOption?: PageOption): Promise<CanBoKiemTraModel.CanBoKiemTra[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireFighter}/name=${name}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: CanBoKiemTraModel.CanBoKiemTra[] }) => Promise.resolve(data));

const findFireFightersByPosition = (positionId: string): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireFighter}/id=${positionId}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const getFireFighter = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireFighter}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const listFireFighter = (pageOption: PageOption): Promise<CanBoKiemTraModel.CanBoKiemTra[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireFighter}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: CanBoKiemTraModel.CanBoKiemTra[] }) => Promise.resolve(data));

export interface SearchFireFighterParams {
	Location?: LocationInfo;
	PcccUnitId?: string;
	ContructionId?: string;
	UnderType?: string;
	DonVi?: string;
}

const _searchFireFighter = (
	input: SearchFireFighterParams,
	pageOption?: PageOption,
): Promise<CanBoKiemTraModel.CanBoKiemTra[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/getByPcccUnitAndLocation`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: CanBoKiemTraModel.CanBoKiemTra[] }) => Promise.resolve(data));

const _searchComboxboxFireFighter = (input: SearchFireFighterParams): Promise<CanBoKiemTraModel.CanBoKiemTra[]> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/comboboxItem`, input)
		.then(({ data }: { data: CanBoKiemTraModel.CanBoKiemTra[] }) => Promise.resolve(data));

const updateFireFighter = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixFireFighter}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _createListFireFighter = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/insertListFireFighter`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const getFireFighterByListLocation = (payload: LocationInfo[]): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/getByListLocation`, payload)
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const exportFileExcel = (payload = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/ExportPcccUnitAndLocation`, payload, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const getFireFighters = (
	payload: CanBoKiemTraModel.CanBoKiemTraSearchParam = {},
	pageOption?: PageOption,
): Promise<PageOption<CanBoKiemTraModel.CanBoKiemTra>> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/Search`, payload, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));
};

const getFireFightersByIds = (ids: string[], pageOption?: PageOption): Promise<CanBoKiemTraModel.CanBoKiemTra[]> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireFighter}/getByListId`, ids, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }) => Promise.resolve(data));
};

export default {
	createFireFighter,
	createListFireFighter: _createListFireFighter,
	deleteFireFighter,
	findFireFightersByName,
	findFireFightersByPosition,
	getFireFighter,
	listFireFighter,
	searchFireFighter: _searchFireFighter,
	updateFireFighter,
	getFireFighterByListLocation,
	exportFileExcel,
	getFireFighters,
	getFireFightersByIds,
	searchComboxboxFireFighter: _searchComboxboxFireFighter,
};
