import ApiService, { INVENTORY_BASE_URL, PageOption } from 'services/api/index';
import { LoaiPhuongTienEnum } from 'enum/loaiPhuongTienEnum';
import { LocationInfo } from 'services/api/inventoryService/locationService';
import { TrucThuocEnum } from 'enum/trucThuocEnum';
import AvailabilityStatusEnum from 'enum/availabilityStatusEnum';

export const prefixFireTruck = '/fireTruck';

export interface FireTruck {
	Available: AvailabilityStatusEnum;
	BienSo: string;
	DonVi: string;
	Id: string;
	Imei: string;
	IsDieuDong: boolean;
	Latitude: number;
	LoaiPhuongTien: LoaiPhuongTienEnum;
	Location: LocationInfo;
	LocationDetail?: string;
	Longitude: number;
	SoDienThoai?: string;
	SoHieuPhuongTien: string;
	TenPhuongTien: string;
	TinhTrangHoatDong?: string;
	TrucThuoc: TrucThuocEnum;
	FireProtectionId?: string;
	Users?: string[];
	PcccUnitName?: string;
}

const _createFireTruck = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _insertListFireTruck = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/insertListFireTruck`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteFireTruck = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.delete(`${prefixFireTruck}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _findFireTrucksByName = (fireTruckName: string, pageOption?: PageOption): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireTruck}/name=${fireTruckName}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _findFireTrucksByPlate = (nameStr: string, plateNumber: number): Promise<any[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireTruck}/nameStr=${nameStr}/plateNumber=${plateNumber}`)
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _findFireTrucksByPcccUnit = (unitId: string): Promise<FireTruck[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireTruck}/pccc-unit-id=${unitId}`)
		.then(({ data }: { data: FireTruck[] }) => Promise.resolve(data));

const _getFireTruck = (id: string): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireTruck}/id=${id}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _listFireTruck = (pageOption?: PageOption): Promise<FireTruck[]> =>
	ApiService(INVENTORY_BASE_URL)
		.get(`${prefixFireTruck}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any[] }) => Promise.resolve(data));

const _updateFireTruck = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.put(`${prefixFireTruck}`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchFireTruck = (input: any, pageOption?: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/getByPcccUnitAndLocation`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
const _searCountFireTruckStatus = (input: any): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/countFireTruckStatus`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));
const _getDataFireTruckStatus = (input: any, pageOption: PageOption): Promise<any> =>
	ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/getDataFireTruckStatus`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _instanceOf = (object: any): object is FireTruck => {
	return object && 'LoaiPhuongTien' in object;
};

const exportFileExcel = (payload = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/ExportByPcccUnitAndLocation`, payload, {
			responseType: 'blob',
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const getFireTrucksByListId = (ids: string[] = [], pageOption: any = {}): Promise<any> => {
	return ApiService(INVENTORY_BASE_URL)
		.post(`${prefixFireTruck}/getByListId`, ids, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));
};

const fireTruckService = {
	createFireTruck: _createFireTruck,
	deleteFireTruck: _deleteFireTruck,
	findFireTrucksByName: _findFireTrucksByName,
	findFireTrucksByPlate: _findFireTrucksByPlate,
	findFireTrucksByPcccUnit: _findFireTrucksByPcccUnit,
	getFireTruck: _getFireTruck,
	listFireTruck: _listFireTruck,
	updateFireTruck: _updateFireTruck,
	searchFireTruck: _searchFireTruck,
	searCountFireTruckStatus: _searCountFireTruckStatus,
	getDataFireTruckStatus: _getDataFireTruckStatus,
	insertListFireTruck: _insertListFireTruck,
	instanceOf: _instanceOf,
	exportFileExcel,
	getFireTrucksByListId,
};

export default fireTruckService;
