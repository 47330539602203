import { PermissionEnum } from 'enum/permissionEnum';
import { t } from 'i18next';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import PermissionSelector from 'redux/Permission/permission.selector';
import BaoCaoChiTietLoiGSM from './BaoCaoChiTietLoi';
import BaoCaoKetQuaSanXuat from './BaoCaoKetQuaSanXuat';
import BaoCaoKetQuaTestDialog from './BaoCaoKetQuaTestDialog';
import BaoCaoLoiTongHop from './BaoCaoLoiTongHop';
import QuanLyThietBi from './QuanLyLoaiThietBi';
import QuanLyLoi from './QuanLyLoi';

export enum QuanLyChatLuongThietBiTab {
	QUAN_LY_LOI = 'quanLyLoi',
	QUAN_LY_LOAI_THIET_BI = 'quanLyLoaiThietBi',
	BAO_CAO_KET_QUA_SAN_XUAT = 'baoCaoKetQuaSanXuat',
	BAO_CAO_CHI_TIET = 'baoCaoChiTiet',
	TONG_HOP_KET_QUA_TEST = 'tongHopKetQuaTest',
}
export interface PermissionProps {
	permissionList: PermissionEnum[];
}

const mapStateToProps = (): PermissionProps => ({
	permissionList: PermissionSelector.getPermissionList(),
});
const QuanLyChatLuongThietBi: FunctionComponent<PermissionProps> = (props: PermissionProps) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	useEffect(() => {
		NavBarDispatcher.setTitle(`${t('nav.quanLyLoaiThietBi')}`);
		const _tabParam: string | null = searchParams.get('tab');
		if (!_tabParam || !Object.values(QuanLyChatLuongThietBiTab).includes(_tabParam as QuanLyChatLuongThietBiTab)) {
			navigate(
				{
					pathname: '/quanLyChatLuongThietBi',
					search: `?tab=${QuanLyChatLuongThietBiTab.QUAN_LY_LOI}`,
				},
				{ replace: true },
			);
		} else {
			setSelectedTab(_tabParam as QuanLyChatLuongThietBiTab);
		}
	}, [navigate, searchParams]);
	const [selectedTab, setSelectedTab] = useState<QuanLyChatLuongThietBiTab>();

	const renderSelectedTab = (): JSX.Element => {
		switch (selectedTab) {
			case QuanLyChatLuongThietBiTab.QUAN_LY_LOI:
			default:
				return <QuanLyLoi permissionList={props.permissionList} />;
			case QuanLyChatLuongThietBiTab.QUAN_LY_LOAI_THIET_BI:
				return <QuanLyThietBi permissionList={props.permissionList} />;
			case QuanLyChatLuongThietBiTab.BAO_CAO_KET_QUA_SAN_XUAT:
				return <BaoCaoKetQuaSanXuat permissionList={props.permissionList}/>;
			case QuanLyChatLuongThietBiTab.BAO_CAO_CHI_TIET:
				return <BaoCaoChiTietLoiGSM permissionList={props.permissionList} />;
			case QuanLyChatLuongThietBiTab.TONG_HOP_KET_QUA_TEST:
				return <BaoCaoLoiTongHop permissionList={props.permissionList} />;
		}
	};
	return renderSelectedTab();
};

export default connect(mapStateToProps)(QuanLyChatLuongThietBi);
