import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Space } from 'antd';
import { RangePickerProps } from 'antd/lib/date-picker';
import ConstructionLabel from 'components/shared/ConstructionLabel/ConstructionLabel';
import CenterDeviceTypeSelect from 'components/shared/form-select/CenterDeviceType/CenterDeviceType';
import DialogLayout from 'components/shared/layouts/DialogLaypout/DialogLayout';
import RangePickerInput from 'components/shared/RangePickerInput/RangePickerInput';
import { showNotification } from 'helpers';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useDialog } from 'react-st-modal';
import DeviceService from 'services/api/inventoryService/deviceService';
import Utils from 'services/api/utils';

interface AddDeviceDialogProps {
	callback?: () => void;
}

const disabledDate: RangePickerProps['disabledDate'] = current => {
	// Can not select days before today and today
	return current && current.isBefore(new Date(), 'day');
};

const AddDeviceDialog = (props: AddDeviceDialogProps) => {
	const { callback } = props;

	const [form] = Form.useForm();
	const dialog = useDialog();
	const constructionList = Utils.DSCoSoCongTrinh();

	// states
	const [constructionId, setConstructionId] = useState('');

	const handleSuccess = (remember: boolean) => {
		showNotification(
			'success',
			t('notification.success'),
			t('notification.success_create_desc', {
				item: form.getFieldValue('Name'),
			}),
		);
		form.resetFields();
		if (callback) callback();
		if (!remember) {
			dialog.close();
			return;
		}
	};

	const handleError = (errors: any) =>
		showNotification('error', t('notification.error'), errors.response.data || errors.message);

	const handleSubmitForm = (formvalues: any) => {
		const { remember, dateRange, DeviceType, ...remainFormValues } = formvalues;
		const payload = {
			...remainFormValues,
			LoaiThietBi: DeviceType,
			ActiveTime: dateRange[0],
			ExpiredTime: dateRange[1],
		};
		DeviceService.createDevice(payload)
			.then(() => handleSuccess(remember))
			.catch((errors: any) => handleError(errors));
	};

	return (
		<DialogLayout className="add-sensor-dialog-wrapper">
			<Form layout="vertical" form={form} onFinish={handleSubmitForm}>
				<Row gutter={32}>
					<Col span={12}>
						<Form.Item
							name="Imei"
							label="IMEI thiết bị"
							rules={[
								{
									required: true,
									message: 'Imei không được bỏ trống',
								},
							]}>
							<Input allowClear placeholder="Nhập IMEI thiết bị" />
						</Form.Item>

						<Form.Item
							name="Name"
							label="Tên thiết bị"
							rules={[
								{
									required: true,
									whitespace: true,
									message: 'Tên thiết bị không được bỏ trống',
								},
							]}>
							<Input allowClear placeholder="Nhập tên thiết bị" />
						</Form.Item>

						<CenterDeviceTypeSelect
							label="Loại thiết bị"
							form={form}
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn loại thiết bị',
								},
							]}
						/>

						<Form.Item
							name="ConstructionId"
							label={<ConstructionLabel constructionId={constructionId} dialog={dialog} />}
							rules={[
								{
									required: true,
									message: 'Vui lòng chọn cơ sở công trình',
								},
							]}>
							<Select
								showSearch
								optionFilterProp="children"
								placeholder="Chọn cơ sở công trình"
								allowClear
								onChange={value => setConstructionId(value)}>
								{constructionList}
							</Select>
						</Form.Item>

						<Form.Item name="SeriNumber" label="Mã thiết bị">
							<Input allowClear placeholder="Nhập mã thiết bị" />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item name="LocationDetail" label="Vị trí lắp đặt">
							<Input allowClear placeholder="Nhập vị trí lắp đặt" />
						</Form.Item>

						<RangePickerInput
							label="Thời gian hoạt động"
							form={form}
							disabledDate={disabledDate}
							rules={[{ required: true, message: 'Vui lòng chọn thời gian hoạt động' }]}
						/>
						<Form.Item name="SimIMEI" label="IMEI Sim">
							<Input allowClear placeholder="Nhập IMEI Sim" />
						</Form.Item>

						<Form.Item name="Info" label="Ghi chú">
							<Input.TextArea allowClear rows={5} placeholder="Nhập ghi chú" />
						</Form.Item>
					</Col>
				</Row>

				<Divider className="mb15" />

				<div className="flex--row justify-content--between align-items--center">
					<Form.Item name="remember" valuePropName="checked" style={{ marginBottom: 0 }}>
						<Checkbox>Thêm và tiếp tục</Checkbox>
					</Form.Item>

					<Space>
						<Button onClick={() => dialog.close()}>Huỷ</Button>
						<Button type="primary" htmlType="submit">
							Thêm mới
						</Button>
					</Space>
				</div>
			</Form>
		</DialogLayout>
	);
};

export default AddDeviceDialog;
