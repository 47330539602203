import { ReduxAction } from 'redux/store';
import { FirebaseMessage } from '../../App';
import { SET_MESSAGE } from './firebaseMessage.type';

interface FirebaseMessageState {
	message?: FirebaseMessage;
}

const FIREBASE_MESSAGE: FirebaseMessageState = {
	message: undefined,
};

const firebaseMessageReducer = (state = FIREBASE_MESSAGE, action: ReduxAction): FirebaseMessageState => {
	switch (action.type) {
		case SET_MESSAGE:
			return {
				...state,
				message: action.payload,
			};
		default:
			return state;
	}
};

export default firebaseMessageReducer;