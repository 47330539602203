import ApiService, { AUTH_BASE_URL, PageOption } from 'services/api/index';
import { UserInfo } from '../usersService';

const prefixRole = '/role';

const _listRole = (pageOption?: PageOption): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixRole}`, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _searchRole = (input?: any, pageOption?: PageOption): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.post(`${prefixRole}/search`, input, {
			headers: {
				PageOption: JSON.stringify(pageOption),
			},
		})
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _createRole = (input: any): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.post(`${prefixRole}/create-role`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _updateRole = (input: any): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.put(`${prefixRole}/update`, input)
		.then(({ data }: { data: any }) => Promise.resolve(data));
const _getRoleById = (input: any): Promise<UserInfo> =>
	ApiService(AUTH_BASE_URL)
		.get(`${prefixRole}/id/${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const _deleteRole = (input: any): Promise<any> =>
	ApiService(AUTH_BASE_URL)
		.delete(`${prefixRole}?id=${input}`)
		.then(({ data }: { data: any }) => Promise.resolve(data));

const RoleService = {
	listRole: _listRole,
	deleteRole: _deleteRole,
	createRole: _createRole,
	updateRole: _updateRole,
	searchRole: _searchRole,
	getRoleById: _getRoleById,
};

export default RoleService;
