import { Divider, Form, Select } from 'antd';
import DonViPhongChayChuaChay from 'components/page/DonViPhongChayChuaChay/DonViPhongChayChuaChay';
import { PermissionProps } from 'components/page/QuanLyDonviPhongChay/QuanLyDonViPhongChay';
import DistrictSelect from 'components/shared/form-select/DistrictSelect/DistrictSelect';
import ProvinceSelect from 'components/shared/form-select/ProvinceSelect/ProvinceSelect';
import { LoaiHinhDonViEnum } from 'enum/loaiHinhDonViEnum';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import locationSelector from 'redux/location/location.selector';
import navigateFromDashboardDispatcher from 'redux/navigate-from-dashboard/navigate-from-dashboard.dispatcher';
import navigateFromDashboardSelector from 'redux/navigate-from-dashboard/navigate-from-dashboard.selector';
import { SearchFireFighterParams } from 'services/api/inventoryService/fireFighterService';

const DanhSachDonVi = (props: PermissionProps) => {
	const [form] = Form.useForm();
	const { provinceId: provinceIdFromDashboard, districtId: districtIdFromDashboard } = locationSelector.getLocation();
	const isNavigate = navigateFromDashboardSelector.getNavigateStatus();

	// states of component
	const [apiSearchData, setApiSearchData] = useState<SearchFireFighterParams>();
	const [isDistrictsSelect, setIsDistrictsSelect] = useState(false);
	const [title, setTitle] = useState('');
	const [provinceTitle, setProvinceTitle] = useState('');
	const [disabledTrucThuocSelect, setDisabledTrucThuocSelect] = useState(true);
	const [provinceId, setProvinceId] = useState('');

	const handleChangeProvince = async (provinceId: string, option: any) => {
		form.resetFields(['QuanHuyen', 'trucThuoc']);

		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = provinceId;
		setApiSearchData(searchParams);

		const title = option && option.children;
		if (title) {
			setTitle(title);
			setProvinceTitle(title);
		} else {
			setTitle('');
			setProvinceTitle('');
		}

		setProvinceId(provinceId);

		if (provinceId) {
			setDisabledTrucThuocSelect(false);
		} else {
			setIsDistrictsSelect(false);
			setDisabledTrucThuocSelect(true);
		}
	};

	const handleChangeTrucThuoc = async (trucThuocType: LoaiHinhDonViEnum) => {
		form.resetFields(['QuanHuyen']);

		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = form.getFieldValue('TinhThanh');
		searchParams.UnderType = trucThuocType;
		setApiSearchData(searchParams);

		if (trucThuocType) {
			setIsDistrictsSelect(trucThuocType === LoaiHinhDonViEnum.QUAN_HUYEN);
		} else {
			setIsDistrictsSelect(false);
			setTitle(provinceTitle);
		}
	};

	const handleChangeDistrict = async (districtId: string, option: any) => {
		const searchParams: SearchFireFighterParams = { Location: {} };
		searchParams.Location!.TinhThanh = form.getFieldValue('TinhThanh');
		searchParams.Location!.QuanHuyen = districtId;
		searchParams.UnderType = form.getFieldValue('trucThuoc');
		setApiSearchData(searchParams);

		const title = option && option.children;
		if (title) {
			setTitle(title);
		} else {
			setTitle(provinceTitle);
		}
	};

	const handleResetFormAction = () => {
		form.resetFields();
		setIsDistrictsSelect(false);
		setDisabledTrucThuocSelect(true);
		setTitle('');
		setApiSearchData({});
	};

	useEffect(() => {
		document.title = t('quanLyDonViPhongChay.quanLyDonVi');
	}, []);

	useEffect(() => {
		if (isNavigate) {
			setDisabledTrucThuocSelect(false);
			setIsDistrictsSelect(!!districtIdFromDashboard);
			setProvinceId(provinceIdFromDashboard);
			form.setFieldsValue({
				TinhThanh: provinceIdFromDashboard,
				trucThuoc: districtIdFromDashboard ? LoaiHinhDonViEnum.QUAN_HUYEN : null,
				QuanHuyen: districtIdFromDashboard ? districtIdFromDashboard : null,
			});

			setApiSearchData({
				Location: {
					TinhThanh: provinceIdFromDashboard,
					...(districtIdFromDashboard && { QuanHuyen: districtIdFromDashboard }),
				},
				...(districtIdFromDashboard && { UnderType: LoaiHinhDonViEnum.QUAN_HUYEN }),
			});
		}

		return () => {
			navigateFromDashboardDispatcher.resetNavigateStatus();
		};
	}, [isNavigate, form, provinceIdFromDashboard, districtIdFromDashboard]);

	return (
		<div className="FirePreventionUnitWrapper">
			<div className="FirePreventionUnitContainer">
				<Form form={form} className="select-container">
					<ProvinceSelect selectClassName="withMinSelect" form={form} onChangeProvince={handleChangeProvince} />

					<Form.Item name="trucThuoc">
						<Select
							showSearch
							optionFilterProp="children"
							allowClear
							onChange={handleChangeTrucThuoc}
							placeholder="Trực thuộc"
							className="withMinSelect"
							disabled={disabledTrucThuocSelect}>
							<Select.Option key={LoaiHinhDonViEnum.PC07} value={LoaiHinhDonViEnum.PC07}>
								{t('enum.trucThuoc.PC07')}
							</Select.Option>
							<Select.Option key={LoaiHinhDonViEnum.QUAN_HUYEN} value={LoaiHinhDonViEnum.QUAN_HUYEN}>
								{t('enum.trucThuoc.QUAN_HUYEN')}
							</Select.Option>
						</Select>
					</Form.Item>

					{isDistrictsSelect && (
						<DistrictSelect
							selectClassName="withMinSelect"
							form={form}
							provinceId={provinceId}
							onChangeDistrict={handleChangeDistrict}
						/>
					)}
				</Form>

				{title && (
					<div className="info-container">
						<h3 className="heading">{title}</h3>
					</div>
				)}

				<Divider className="mb15" />

				<DonViPhongChayChuaChay
					searchData={apiSearchData}
					userInfo={props.userInfo}
					permissionList={props.permissions}
					onResetFormAction={handleResetFormAction}
					isNavigate={isNavigate}
				/>
			</div>
		</div>
	);
};

export default DanhSachDonVi;
