import { Form, Table } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { t } from 'i18next';
import moment from 'moment';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import NavBarDispatcher from 'redux/NavBar/navBar.dispatcher';
import reportTestService from 'services/api/factService/reportTestService';
import { UserInfo } from 'services/api/authenService/usersService';

interface BaoCaoKetQuaDialogProps {
	gsmIMEI: string;
	Id?: string;
	callback?: () => void;
}

const BaoCaoKetQuaTestDialog: FunctionComponent<BaoCaoKetQuaDialogProps> = (props: BaoCaoKetQuaDialogProps) => {
	const [, setTongSoKetQua] = useState<number>(0);
	const [currentPage] = useState<number>(1);

	const [record, setRecord] = useState<any>(undefined);

	const columns: ColumnsType<UserInfo> = [
		{
			align: 'center',
			className: 'min-col',
			dataIndex: 'key',
			render: (value: any, item: any, index: any) => (currentPage - 1) * 10 + index + 1,
			title: t('label.order'),
		},
		{
			dataIndex: 'ErrorCode',
			title: 'Hạng mục',
		},
		{
			dataIndex: 'DateTest',
			title: 'Thời gian',
		},

		{
			dataIndex: 'TestResult',
			title: 'Kết quả',
		},
	];

	const [ketQuaTestData, setKetQuaTestData] = useState<any[]>();

	useEffect(() => {
		document.title = t('nav.baoCaoKetQua');
		NavBarDispatcher.setTitle(`${t('nav.baoCaoKetQua')}`);
		SearchKetQuaTest();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function SearchKetQuaTest() {
		reportTestService.getbyEmei(props.gsmIMEI).then((res: any) => {
			setTongSoKetQua(res?.ErrorDetails?.length);
			setRecord(res);
			optiondanhsachKetQua(res ? res : []);
		});
	}

	const optiondanhsachKetQua = (data: any, _index = 0) => {
		let promises: PromiseLike<any>[] = [];
		let array: any[] = [];
		if (data?.ErrorDetails?.length > 0) {
			data.ErrorDetails.forEach((item: any) => {
				if (item.DateTest) {
					item.DateTest = moment(item.DateTest).format('DD/MM/YYYY HH:mm:ss');
				}
				if (item.TestResult) {
					item.TestResult = 'PASS';
				} else {
					item.TestResult = 'FAIL';
				}

				array.push(item);
			});
		}
		Promise.all(promises).then(() => setKetQuaTestData(array));
	};

	return (
		<Form layout="vertical" name="normal_login">
			{record && (
				<div>
					<h5>IMEI: {record.GsmIMEI}</h5>
					<h5 className="mt5">JIG: {record.MacJIG}</h5>
					<h5 className="mt5">PASS: {record.CountPass}</h5>
				</div>
			)}
			<Table
				className="table-header-bored mt10"
				bordered
				rowClassName={record => (record.TestResult === 'PASS' ? 'table-row-pass' : 'table-row-fail')}
				locale={{ emptyText: t('label.emptyData') }}
				showSorterTooltip={false}
				dataSource={ketQuaTestData}
				pagination={false}
				columns={columns}
				style={{ whiteSpace: 'pre' }}
			/>
		</Form>
	);
};

export default withTranslation()(BaoCaoKetQuaTestDialog);
