import { CustomDialog } from 'react-st-modal';

const openDialog = async (component: JSX.Element, title: string, className: string) => {
	await CustomDialog(component, {
		className,
		title,
		isCanClose: true,
		showCloseIcon: true,
	});
};

export default openDialog;
